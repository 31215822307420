import { CellContext } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Badge } from "common/guideline";
import { OnlineStatus } from "generated";

const onlineStatus: Record<OnlineStatus, "success" | "error" | "warning"> = {
  CatchingUp: "warning",
  Offline: "error",
  Online: "success",
};

export const StatusBadge = ({ getValue }: CellContext<any, any>) => {
  const { t } = useTranslation();
  const value = getValue() as OnlineStatus | undefined;
  const values = t("machine.status", { returnObjects: true });

  return value ? <Badge variant={onlineStatus[value]}>{values[value]}</Badge> : <span>-</span>;
};
