import { CaretRight } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { NodeStatusBadge } from "base/components";
import { Text, useModal } from "common/guideline";
import { withDefault } from "common/helpers";

import { RowActions } from "./RowActions";
import { RowTenantDataProps } from "./types";

const UserDetailsModal: React.FC<RowTenantDataProps> = ({ row }) => (
  <div>
    <div tw="flex justify-between pr-6 mb-2">{row.original.name}</div>
    <div tw="grid text-xs [grid-template-columns:max-content_1fr] [grid-gap:12px] justify-items-start">
      <Text tKey="tenant.list.status" />
      <NodeStatusBadge value={row.original.nodeStatus || undefined} />
      <Text tKey="tenant.list.changed" />
      <span>
        {withDefault(row.original.nodeChangeDate ? new Date(row.original.nodeChangeDate).toLocaleString() : undefined)}
      </span>
    </div>
  </div>
);

const openUserDetailModal = (row: RowTenantDataProps["row"]) =>
  useModal.actions.open({ Component: <UserDetailsModal row={row} />, closeOnRouteChange: true });

export const TenantVerticalHeader: React.FC<RowTenantDataProps> = (props) => {
  return (
    <div tw="flex justify-between pb-4 items-center">
      <div tw="flex">
        <span>{props.row.original.name}</span>
      </div>
      <div tw="flex justify-end items-center ml-2">
        <RowActions {...props} />
        <CaretRight size={24} weight="bold" tw="text-gray-6" onClick={() => openUserDetailModal(props.row)} />
      </div>
    </div>
  );
};
