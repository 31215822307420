import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "now", label: "common.remoteMgmt.now" },
  { value: "specify", label: "common.remoteMgmt.specifyDateAndHour" },
];

export const getRemoteMgmtTimeOptions = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "common.remoteMgmt.selectWhen",
    options,
    ...values,
  });
