import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as SiteGroupSVG } from "assets/icons/SiteGroup.svg";
import { PageLayout } from "base/components";
import { Button, Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";
import { LocationGroupTable } from "./LocationGroupsTable/LocationGroupsTable";

export const LocationGroups = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.lg.title_other"
    subtitle="administration.lg.desc"
    Icon={SiteGroupSVG}
    actions={
      <Link route="LOCATION_GROUP_CREATE">
        <Button>
          <Text tKey="administration.lg.create" />
        </Button>
      </Link>
    }
  >
    <LocationGroupTable />
  </PageLayout>
);
