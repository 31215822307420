import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const TechnologyOperations = () => {
  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="operations.techOps.title"
      subtitle="operations.techOps.desc"
      withPicker
    ></PageLayout>
  );
};
