import { RouteConfig, RouteValue } from "routing";

import { AlertCreate, AlertEdit, AlertEditParams, Alerts, AlertsHistory } from "./pages";

export type AlertPaths = {
  ALERTS: RouteValue;
  ALERTS_CREATE: RouteValue;
  ALERTS_EDIT: RouteValue<{ params: AlertEditParams }>;
  ALERTS_HISTORY: RouteValue;
};

export const ALERT: RouteConfig<AlertPaths> = {
  ALERTS: {
    path: "/alerts",
    Component: Alerts,
  },
  ALERTS_CREATE: {
    path: "/alerts/create",
    Component: AlertCreate,
  },
  ALERTS_EDIT: {
    path: "/alerts/edit/:nodeId",
    Component: AlertEdit,
  },
  ALERTS_HISTORY: {
    path: "/alerts/history",
    Component: AlertsHistory,
  },
};
