export const fr = {
  location: {
    location_one: "Site",
    location_other: "Sites",
    useLocation: "Utiliser ce site",
    cancel: "Annuler",
    siteDetails: "Détails du site",
    chooseOnMap: "Sélectionner sur la carte",
    longitude: "Longitude",
    latitude: "Latitude",
    customerReference: "Référence client",
    description: "Description",
    geographicalLocation: "Localisation géographique",
    country: "Pays",
    countryCode: "Code pays",
    city: "Ville",
    region: "Département",
    zipCode: "Code postal",
    title: "Sites",
    create: "Créer un site",
    createDescription: "Vous créez un site pour mettre en place un nouveau système de gestion de trésorerie",
    update: "Mise à jour d'un site",
    updateDescription: "Vous mettez à jour un site pour modifier un système de gestion de trésorerie existant",
    delete: "Suppression d'un site",
    deleteDescription:
      "Vous supprimez un site lorsque vous souhaitez supprimer un système de gestion de trésorerie existant",
    deleteClose: "Fermer",
    roleName: "Rôle",
    name: "Nom du site",
    siteId: "Id site",
    nodeStatus: "Statut",
    nodeStatusCount: "Nombre de statuts",
    nodeChangeDate: "Modifié",
    success: "Site créé avec succès",
    error: "La création du site a échoué",
    updateSuccess: "Site mis à jour avec succès",
    updateApiError: "La mise à jour du site a échoué",
    deleteSuccess: "Site supprimé avec succès",
    deleteError: "La suppression du site a échoué",
    goBack: "Retour",
    mustBeNumeric: "Ce champ doit être un nombre",
    filterSites: "Filter les sites",
    unnamedSite: "Site sans nom",
    selectOnMap: "Sélectionner sur la carte",
    list: {
      noData: "Aucun site trouvé",
    },
  },
};
