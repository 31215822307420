import { memo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { OnlineStatusBadge } from "base/components";
import { Badge, CustomSubRows, FullLine, TableTypes, Text, accessors, useToast } from "common/guideline";
import { copyToClipboard } from "common/helpers";
import { MachineOnlineWithHistoryStatusDto } from "generated";

export const OnlineStatusHistory: TableTypes.SubRowsComponent<MachineOnlineWithHistoryStatusDto> = memo(({ row }) => {
  const { t } = useTranslation();

  return (
    <CustomSubRows row={row}>
      {row.original.historyStatuses?.length == 0 ? (
        <Text tKey="common.table.noData" />
      ) : (
        row.original.historyStatuses?.map((status, i) => (
          <div tw="flex flex-col gap-2" key={i}>
            <div tw="flex flex-col gap-1">
              <div tw="flex gap-1">
                <div tw="inline-flex items-center gap-2">
                  <Text variant="labelAlt" tValue={{ colon: true }} tKey="machine.uuid" />
                  <Badge variant={["normalCase", "info"]}>{status?.machineUuid}</Badge>
                  <CopySVG
                    tw="cursor-pointer"
                    width={16}
                    height={16}
                    onClick={() =>
                      copyToClipboard(status?.machineUuid || "", () => useToast.actions.show("common.clipboard"))
                    }
                  />
                </div>
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.lastEvent" />
                {status?.eventDate ? (
                  <Text tKey="dateFormat" tValue={{ date: new Date(status.eventDate), formatString: "PPp" }} />
                ) : null}
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.formattedEventDate" />
                {accessors.zonedDateTime(status?.formattedEventDate, t)}
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.licenseId" />
                <span>{status?.licenseId ?? "-"}</span>
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.machineStatus" />
                <span>{status?.machineStatus}</span>
              </div>
              <FullLine variant="transparent" />
              <div tw="inline-flex items-center gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.onlineStatus" />
                <OnlineStatusBadge value={status?.onlineStatus} />
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.lastReceived" />
                {status?.receivedDate ? (
                  <Text tKey="dateFormat" tValue={{ date: new Date(status.receivedDate), formatString: "PPp" }} />
                ) : null}
              </div>
              <FullLine variant="transparent" />
              <div tw="flex gap-1">
                <Text variant="labelAlt" tValue={{ colon: true }} tKey="mos.formattedReceivedDate" />
                {accessors.zonedDateTime(status?.formattedReceivedDate, t)}
              </div>
              <FullLine variant="transparent" />
            </div>
            <FullLine variant="horizontal" />
            <FullLine variant="transparent" />
          </div>
        ))
      )}
    </CustomSubRows>
  );
});

OnlineStatusHistory.displayName = "OnlineStatusHistory";
