import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getFormTitle, getPaymentIntervalOptions } from "base/fields";
import { CustomRenderFields } from "common/form/renderFields";
import { isEnterpriseSubscription } from "machine/helpers";

import { getLicensingOptions } from ".";

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

export const getLicensingSection: (
  licenseNodeId?: string,
  edition?: string,
  subscriptionActive?: boolean,
  deactivatedDate?: Date,
) => CustomRenderFields[] = (licenseNodeId, edition, subscriptionActive, deactivatedDate) => {
  if (!licenseNodeId) {
    return [
      {
        type: "container",
        Component: getFormTitle("machine.licensing.title"),
      },
      {
        type: "container",
        Component: getFormTitle("machine.licensing.noLicenseId", true),
      },
    ];
  }

  if (isEnterpriseSubscription(licenseNodeId, edition)) {
    if (subscriptionActive && !deactivatedDate) {
      return [
        {
          type: "container",
          Component: getFormTitle("machine.licensing.currentLicense"),
        },
        {
          type: "container",
          Component: TwoColsWrapper,
          fields: [
            {
              type: "text",
              name: "licenseKey",
              label: "machine.licensing.licenseKey",
              disabled: true,
            },
            {
              type: "text",
              name: "comment",
              label: "machine.licensing.discountCode",
            },
            getPaymentIntervalOptions({
              name: "paymentIntervalType",
              defaultValue: "MONTHLY",
              validate: { type: "string", required: true },
            }),
            {
              type: "checkbox",
              name: "subscriptionActive",
              label: "machine.licensing.unclickToActivate",
            },
          ],
        },
      ];
    } else if (!subscriptionActive && deactivatedDate) {
      return [
        {
          type: "container",
          Component: getFormTitle("machine.licensing.currentLicense"),
        },
        {
          type: "container",
          Component: TwoColsWrapper,
          fields: [
            {
              type: "checkbox",
              name: "subscriptionActive",
              label: "machine.licensing.clickToActivate",
            },
          ],
        },
      ];
    }
  }

  return [
    {
      type: "container",
      Component: getFormTitle("machine.licensing.title"),
    },
    {
      type: "container",
      Component: TwoColsWrapper,
      fields: [
        getLicensingOptions({ name: "licensingOption", defaultValue: "keepBasic" }),
        {
          type: "condition",
          when: "licensingOption",
          is: (value) => value === "upgrade",
          fields: [
            {
              type: "text",
              name: "comment",
              label: "machine.licensing.discountCode",
            },
            getPaymentIntervalOptions({
              name: "paymentIntervalType",
              defaultValue: "MONTHLY",
              validate: { type: "string", required: true },
            }),
            {
              type: "checkbox",
              name: "acknowledgement",
              label: "machine.licensing.costAcknowledgement",
              validate: {
                type: "boolean",
                required: true,
                custom: (value, formData) =>
                  value === true && formData.values.licensingOption === "upgrade"
                    ? null
                    : "machine.licensing.agreementNeeded",
              },
            },
          ],
        },
      ],
    },
  ];
};
