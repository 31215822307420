import tw from "twin.macro";
import "styled-components/macro";

import { deleteConfigurationFile } from "administration/helpers";
import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  GenerateMachineViewTabDocument,
  GetAllTemplatesFilteredDocument,
  GetDeploymentsForMachineNodeIdDocument,
  useDeleteTemplateMutation,
} from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  fileName: string;
  onClose?: () => void;
};

export const DeleteConfigTemplate: React.FC<Props> = ({ nodeId, name, fileName, onClose }) => {
  const [deleteTemplate, { loading }] = useDeleteTemplateMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "administration.rc.deleteError" : "administration.rc.deleteSuccess", {
        variant: error ? "error" : "success",
      });
    try {
      const result = await deleteTemplate({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(GenerateMachineViewTabDocument, cache);
          evictQuery(GetDeploymentsForMachineNodeIdDocument, cache);
          evictQuery(GetAllTemplatesFilteredDocument, cache);
        },
      });
      if (result.data?.deleteTemplate) onClose?.();
      deleteConfigurationFile(fileName);
      showToast(!result.data?.deleteTemplate);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="administration.rc.delete" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="administration.rc.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
