import { useCallback } from "react";
import create from "zustand";

import { deepMerge, flattenObject, getDeep, unflattenObject } from "common/helpers";

import { history } from "./history";

type QueryParamsState = {
  search: Record<string, any>;
  searchString: string;
  getParam: (path: string, state?: QueryParamsState) => any;
  getParams: (selector?: <T = any>(search: QueryParamsState["search"]) => T, state?: QueryParamsState) => any;
  updateSearch: (
    params: QueryParamsState["search"],
    merging?: "replace" | "merge" | "deepMerge",
    routeOption?: "replace" | "push",
  ) => void;
};

export const qs = {
  serialize: (obj: any): string =>
    Object.entries(flattenObject(obj))
      .map((a) => a.join("="))
      .join("&"),
  deserialize: <T = any>(qs: string): T =>
    unflattenObject(
      Object.fromEntries(qs.split("&").map((s) => s.split("="))),
      // custom query string decord to handle number, null, undefend and booleans correctly
      (str) => (/^(\d+|\d*\.\d+)$/.test(str) ? parseFloat(str) : keywords[str] || str),
    ),
};

const keywords = {
  true: true,
  false: false,
  null: null,
  undefined,
};

const getQueryStateByRouteSearch = (search: string): Pick<QueryParamsState, "search" | "searchString"> => ({
  search: qs.deserialize(search),
  searchString: search,
});

export const useQueryParams = create<QueryParamsState>((set, get) => ({
  ...getQueryStateByRouteSearch(history.location.search.replace("?", "")),
  getParam: (path, state = get()) => getDeep(state.search, path),
  getParams: (selector, state = get()) => (selector ? selector(state.search) : state.search),
  updateSearch: (params, merging = "merge", routeOption = "push") => {
    const newParams =
      merging === "replace"
        ? params
        : merging === "deepMerge"
        ? deepMerge(useQueryParams.getState().search, params)
        : { ...useQueryParams.getState().search, ...params };

    const searchString = qs.serialize(newParams);

    set({ searchString, search: newParams });
    history[routeOption]({ search: `?${searchString}` });
  },
}));

export const useQueryParamsGetByPath = (path: string): any =>
  useQueryParams(useCallback((s) => s.getParam(path, s), [path]));

export const useQueryParamsSelector = (selector?: Parameters<QueryParamsState["getParams"]>[0]): any =>
  useQueryParams(useCallback((s) => s.getParams(selector, s), [selector]));

history.listen((s) => {
  const search = s.location.search.replace("?", "");
  if (search !== useQueryParams.getState().searchString) {
    useQueryParams.setState(getQueryStateByRouteSearch(search));
  }
});
