import { MinusCircle, PlusCircle } from "@phosphor-icons/react";
import { CellContext } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { Fragment } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Popover, Spinner, TableTypes, Text, accessors } from "common/guideline";
import { comaToFloat } from "common/helpers";
import { AmountDto, NetCashReportRowDto, TotalAmountsDto, useFindBoxTotalsByCurrencyQuery } from "generated";

type LoadBoxContentProps = {
  currency: string | undefined | null;
  transactionsNodeIds: TotalAmountsDto["boxContentNodeIds"];
};

const LoadBoxContent: React.FC<LoadBoxContentProps> = ({ currency, transactionsNodeIds }) => {
  const { data, loading } = useFindBoxTotalsByCurrencyQuery({
    variables: { boxTotalsDtoIn: { reportName: "BoxTotalsByCurrency", transactionsNodeIds, currency } },
    skip: !currency || !transactionsNodeIds?.length,
  });

  const values = data?.findBoxTotalsByCurrency?.boxTotals;

  return loading ? (
    <Spinner />
  ) : (
    <div tw="grid grid-cols-[max-content 1fr] gap-2 text-xs text-gray-8">
      {values?.length ? (
        values.map((v, i) => (
          <Fragment key={v?.key || i}>
            <span>{v?.key}:</span>
            <Text tKey="numberFormat" tValue={{ value: v?.value || 0 }} tw="justify-self-end" />
          </Fragment>
        ))
      ) : (
        <Text tKey="report.netCash.noAmountsData" />
      )}
    </div>
  );
};

type DataWithTotalAmounts = Pick<
  NetCashReportRowDto,
  "openingTotalAmounts" | "closingTotalAmounts" | "transactionsInTotalAmounts" | "transactionsOutTotalAmounts"
>;
type AmountDtoWithBoxNodeIds = AmountDto & Pick<TotalAmountsDto, "boxContentNodeIds">;
type AmountDtoWithBoxNodeIdsTransactions = AmountDto & Pick<TotalAmountsDto, "boxContentNodeIds">;
type AmountDtoWithBoxNodeIdsRecord = Record<string, AmountDtoWithBoxNodeIds>;
type AmountDtoWithBoxNodeIdsInOut = Record<string, AmountDtoWithBoxNodeIdsTransactions>;
type RowsDataResult = {
  currencies: string[];
  opening: AmountDtoWithBoxNodeIdsRecord[];
  closing: AmountDtoWithBoxNodeIdsRecord[];
  transactionIn: AmountDtoWithBoxNodeIdsInOut[];
  transactionOut: AmountDtoWithBoxNodeIdsInOut[];
};

const getRowsData = (rows: DataWithTotalAmounts[]): RowsDataResult => {
  const currencies = new Set<string>();

  const mapRow = (type: "openingTotalAmounts" | "closingTotalAmounts") => (row: DataWithTotalAmounts) =>
    (row[type] ?? [])?.reduce((acc, curr) => {
      if (!curr) return acc;

      curr.totalAmounts?.forEach((amount) => {
        if (amount?.currency) {
          currencies.add(amount.currency);

          acc[amount.currency] = {
            ...amount,
            boxContentNodeIds: curr.boxContentNodeIds,
          };
        }
      });

      return acc;
    }, {} as AmountDtoWithBoxNodeIdsRecord) || {};

  const mapRowTransactions =
    (type: "transactionsInTotalAmounts" | "transactionsOutTotalAmounts") => (row: DataWithTotalAmounts) =>
      (row[type] ?? [])?.reduce((acc, curr) => {
        if (!curr) return acc;

        if (curr?.currency) {
          currencies.add(curr.currency);
          acc[curr.currency] = curr;
        }

        return acc;
      }, {} as AmountDtoWithBoxNodeIdsRecord) || {};

  const result = rows.reduce(
    (acc, curr) => {
      acc.opening.push(mapRow("openingTotalAmounts")(curr));
      acc.closing.push(mapRow("closingTotalAmounts")(curr));
      acc.transactionIn.push(mapRowTransactions("transactionsInTotalAmounts")(curr));
      acc.transactionOut.push(mapRowTransactions("transactionsOutTotalAmounts")(curr));
      return acc;
    },
    { opening: [], closing: [], transactionIn: [], transactionOut: [] } as Omit<RowsDataResult, "currencies">,
  );

  return { currencies: Array.from(currencies), ...result };
};

const getAmountCell = (currency: string, data: AmountDtoWithBoxNodeIdsRecord[]) =>
  function Cell({ getValue, row }: CellContext<any, unknown>) {
    const value = getValue<string>();
    const totals = data[row.index][currency];

    return (
      <div tw="flex space-x-1 items-center">
        <Popover
          overflowContainer
          auto
          possiblePlacements={["bottom-center", "bottom-end", "bottom-start", "top-center", "top-end", "top-start"]}
          content={() => <LoadBoxContent currency={totals?.currency} transactionsNodeIds={totals?.boxContentNodeIds} />}
        >
          {(isOpen) =>
            isOpen ? (
              <MinusCircle size={16} weight="duotone" tw="text-gray-6" />
            ) : (
              <PlusCircle size={16} weight="duotone" tw="text-success-default" />
            )
          }
        </Popover>

        <Text tKey="numberFormat" tValue={{ value }} />
      </div>
    );
  };

export const getTotalAmountsNetCashRows = <T extends DataWithTotalAmounts>(
  rows: T[],
  t: TFunction,
  showTransactions?: boolean,
) => {
  const { currencies, opening, closing, transactionIn, transactionOut } = getRowsData(rows);

  return Object.values(
    currencies.reduce((acc, currency) => {
      acc[`${currency}_opening`] = {
        id: `${currency}_opening`,
        header: t("report.openingTotalAmount", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => opening[i][currency]?.amount || 0,
        cell: getAmountCell(currency, opening),
        meta: {
          csv: {
            accessorFn: (_, i) => comaToFloat(opening[i][currency]?.amount || ""),
          },
        },
      };

      acc[currency] = {
        id: `${currency}_closing`,
        header: t("report.closingTotalAmount", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => closing[i][currency]?.amount || 0,
        cell: getAmountCell(currency, closing),
        meta: {
          csv: {
            accessorFn: (_, i) => comaToFloat(closing[i][currency]?.amount || ""),
          },
        },
      };

      if (showTransactions) {
        acc[`${currency}_transactionIn`] = {
          id: `${currency}_transactionIn`,
          header: t("report.netCash.transactionIn", { currency }),
          enableColumnFilter: false,
          enableHiding: false,
          accessorFn: (_, i) => accessors.number(transactionIn[i][currency]?.amount || 0, t),
          meta: {
            csv: {
              accessorFn: (_, i) => comaToFloat(transactionIn[i][currency]?.amount || ""),
            },
          },
        };

        acc[`${currency}_transactionOut`] = {
          id: `${currency}_transactionOut`,
          header: t("report.netCash.transactionOut", { currency }),
          enableColumnFilter: false,
          enableHiding: false,
          accessorFn: (_, i) => accessors.number(transactionOut[i][currency]?.amount || 0, t),
          meta: {
            csv: {
              accessorFn: (_, i) => comaToFloat(transactionOut[i][currency]?.amount || ""),
            },
          },
        };
      }

      return acc;
    }, {} as Record<string, TableTypes.ColumnWithSubAccessor<T>>),
  );
};
