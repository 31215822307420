import { TKeys } from "i18next";
import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { client } from "client";
import { CONFIG } from "common/config";
import { FormData, OnSubmit, SchemaForm, SchemaFormWizard, TForm, WizardWrapper } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Text } from "common/guideline";
import { omitByKey, omitTypenames } from "common/helpers";
import {
  FindAllMachinesQueryVariables,
  FindAllMachinesWithLocationsDocument,
  FindAllMachinesWithLocationsQuery,
} from "generated";

import { ScheduledReportsFormDTOIn, ScheduledReportsFormDTOOut, ScheduledReportsFormData } from "./types";
import { frequencyStep } from "./wizard/frequencyStep";
import { parametersStep } from "./wizard/parametersStep";
import { recipientsStep } from "./wizard/recipientsStep";
import { reportStep } from "./wizard/reportStep";

const steps = [reportStep.title, frequencyStep.title, parametersStep.title, recipientsStep.title];

const getFields = (updateForm: boolean, initialData: Partial<ScheduledReportsFormDTOIn>): CustomRenderFields[] => [
  reportStep.section(updateForm, initialData),
  frequencyStep.section(updateForm, initialData),
  parametersStep.section(updateForm, initialData),
  recipientsStep.section(updateForm, initialData),
];

const parseFormDataToDto = (data: ScheduledReportsFormData): ScheduledReportsFormDTOOut => ({
  ...data,
  inputPayload: JSON.stringify(data.inputPayload ?? {}),
});

const parseDtoInToFormDataInitial = (data?: Partial<ScheduledReportsFormDTOIn>) =>
  data
    ? ({
        locationNodeIds: data.locationNodeIds ?? [],
        fileType: data.fileType ?? undefined,
        instanceName: data.instanceName ?? undefined,
        nodeId: data.nodeId ?? undefined,
        reportName: data.reportName ?? undefined,
        inputPayload: data.inputPayload ? JSON.parse(data.inputPayload) : {},
        reportEmails:
          (data.reportEmails?.length ?? 0) > 0
            ? data.reportEmails?.flatMap((v) => (v?.subject ? { email: v.email, subject: v.subject } : [])) ?? [{}]
            : [{}],
        metadata: {
          ...omitTypenames(data.metadata),
          executionTime: data.metadata?.executionTime ?? undefined,
          executeFrom: data.metadata?.executeFrom ?? undefined,
          executeUntil: data.metadata?.executeUntil ?? undefined,
          repeat: data.metadata?.repeat ?? undefined,
          zoneName: data.metadata?.zoneName ?? undefined,
          repeatOnDay: data.metadata?.repeatOnDay ?? undefined,
        },
      } as ScheduledReportsFormData)
    : data;

type Props = {
  onSubmit: (value: ScheduledReportsFormDTOOut, form: TForm<ScheduledReportsFormData>) => any;
  initial?: Partial<ScheduledReportsFormDTOIn>;
  submitLabel: TKeys;
};

const onSubmitForm: (
  onSubmit: (value: ScheduledReportsFormDTOOut, form: TForm<ScheduledReportsFormData>) => any,
) => OnSubmit<ScheduledReportsFormData> = (submitFn) => async (values, form) => {
  const hasMachines = Object.hasOwnProperty.call(values, "machineNodeIds");

  if (!hasMachines) {
    const dto = parseFormDataToDto(values);
    return submitFn(dto, form);
  }

  const machineNodeIds = values.machineNodeIds ?? [];
  const skip = !machineNodeIds || !machineNodeIds.length;

  const locationsUsed = skip
    ? []
    : await client
        .query<FindAllMachinesWithLocationsQuery, FindAllMachinesQueryVariables>({
          fetchPolicy: "no-cache",
          query: FindAllMachinesWithLocationsDocument,
          variables: {
            searchRequest: { size: machineNodeIds ? machineNodeIds.length : 0, page: 0 },
            machineFilters: { nodeIds: machineNodeIds },
          },
        })
        .then(
          (res) =>
            res.data.findAllMachines?.result?.flatMap((m) => {
              if (m?.__typename === "MachineDtoOut") {
                return m.locationNodeId ?? [];
              }
              return [];
            }) ?? [],
        );

  const locationNodeIds = Array.from(new Set([...locationsUsed, ...(values.locationNodeIds ?? [])]));

  const dto = parseFormDataToDto({
    ...omitByKey("machinesNodeIds")(values),
    locationNodeIds,
    inputPayload: { ...values.inputPayload, machineNodeIds },
  });

  return submitFn(dto, form);
};

export const ScheduledReportsWizard: React.FC<Props> = ({ onSubmit, initial, submitLabel }) => {
  const [fields] = useState(() => getFields(!!initial?.nodeId, initial ?? {}));

  return (
    <>
      <Text variant="heading" tKey={submitLabel} />
      <SchemaForm<ScheduledReportsFormData>
        fields={fields}
        onSubmit={onSubmitForm(onSubmit)}
        initial={parseDtoInToFormDataInitial(initial)}
        customRender={customRender}
        FormComponent={SchemaFormWizard}
      >
        {
          ((page) => (
            <WizardWrapper steps={steps} submitLabel={submitLabel}>
              {page}
              {!CONFIG.isProd && (
                <FormData type="values">
                  {(values) => (
                    <div tw="flex justify-between">
                      <pre>{JSON.stringify(values ?? "{}", null, 2)}</pre>
                    </div>
                  )}
                </FormData>
              )}
            </WizardWrapper>
          )) as any
        }
      </SchemaForm>
    </>
  );
};
