import { Check, Minus } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, Container, FullLine, Text } from "common/guideline";

import type { ConfigTemplateFormData } from "./ConfigTemplateForm";

type Props = {
  data: Partial<ConfigTemplateFormData> | undefined;
  onClick?: HTMLElement["click"] | undefined;
};

const isIncluded = (parts: ConfigTemplateFormData["parts"] | undefined, part: string) =>
  parts?.includes?.(part) ?? false;

const InclusionIcon = ({ data, partToCheck }: { data: Props["data"]; partToCheck: string }) => (
  <span>
    {isIncluded(data?.parts, partToCheck) ? (
      <Check size={18} weight="bold" tw="text-success-default" />
    ) : (
      <Minus size={18} weight="regular" />
    )}
  </span>
);

export const ConfigTemplateParts: React.FC<Props> = ({ data, onClick }) => (
  <>
    <FullLine />
    <Container variant="card" tw="text-sm my-4">
      <Text variant="heading" tw="block mb-2" tKey="administration.rc.info" />
      <div tw="grid grid-cols-[max-content 1fr] [grid-gap:12px 40px] ">
        <Text tKey="administration.rc.version" />
        <span>{data?.version}</span>

        <Text tKey="administration.rc.configurationFile" />
        <div tw="flex justify-between truncate">
          <span tw="truncate">{data?.fileName}</span>
          {onClick && (
            <Button onClick={onClick} tw="ml-2" variant={["sm", "side"]} data-test="changeFile">
              <Text tKey="administration.rc.changeFile" />
            </Button>
          )}
        </div>
      </div>
    </Container>
    <FullLine />
    <Container variant="card" tw="[font-size:small]">
      <Text variant="heading" tw="block mb-2" tKey="administration.rc.included" />
      <div tw="grid grid-cols-[max-content 1fr max-content 1fr] [grid-gap:8px 40px]">
        <Text tKey="administration.rc.configuration" />
        <InclusionIcon data={data} partToCheck="configuration" />
        <Text tKey="administration.rc.receipts" />
        <InclusionIcon data={data} partToCheck="receipts" />
        <Text tKey="administration.rc.i18n" />
        <InclusionIcon data={data} partToCheck="i18n" />
        <Text tKey="administration.rc.themes" />
        <InclusionIcon data={data} partToCheck="themes" />
        <Text tKey="administration.rc.sdk" />
        <InclusionIcon data={data} partToCheck="sdk" />
        <Text tKey="administration.rc.scripts" />
        <InclusionIcon data={data} partToCheck="scripts" />
        <Text tKey="administration.rc.help" />
        <InclusionIcon data={data} partToCheck="help" />
      </div>
    </Container>
    <FullLine />
  </>
);
