import { getGetterByPath } from "../getGetterByPath";

import { TForm } from "./types";

export type DataPaths = "values" | "errors" | "touched" | "data";
export type FormData = Pick<TForm<any>, DataPaths>;

export const getRef: <T = any>(path: `${DataPaths}.${string}`, format?: (v: any) => T) => (form: FormData) => T =
  (path, format = (a) => a) =>
  (form) =>
    format(getGetterByPath(path)(form));
