import { useMemo } from "react";

import * as Types from "../types";
import { useFormGenerator } from "../useFormGenerator";

type LiveFieldProps<T extends Types.CustomFieldsSchema> = Types.LiveField<T> & {
  customRender?: Types.CustomRenderInput<T>;
};

const FormField = ({ field, customRender, name }: any) => (
  <>
    {useFormGenerator(
      useMemo(() => [{ ...field, name }], [field, name]),
      customRender,
    )}
  </>
);

export function LiveField<T extends Types.CustomFieldsSchema = Types.FieldSchema>(props: LiveFieldProps<T>) {
  const [config, ready] = props.useConfig(props.fieldConfig);

  return ready ? (
    <FormField field={config} name={props.name} customRender={props.customRender} />
  ) : props.Fallback ? (
    <props.Fallback key="fallback" {...(config as any)} />
  ) : null;
}
