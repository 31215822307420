export const uploadFile = <RESPONSE = any>(file: File, url: string, contentType: string) =>
  fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": contentType,
    },
    body: file,
  }).then<RESPONSE>((response) => response.json());

export const deleteFile = <RESPONSE = any>(url: string) =>
  fetch(url, {
    method: "DELETE",
    mode: "cors",
  }).then<RESPONSE>((response) => response.json());

export const getUrlContents = (url: string) => {
  window.location.href = url;
};

export const downloadBlob = (url: string, contentType: string) =>
  fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": contentType,
    },
  }).then((response) => response.blob());
