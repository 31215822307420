import { RmTransactionType } from "generated";

export const transactionTypeOptions: Array<{ label: string; value: RmTransactionType }> = [
  { label: "tranTypes.DISPENSE", value: "DISPENSE" },
  { label: "tranTypes.DEPOSIT", value: "DEPOSIT" },
  { label: "tranTypes.EMPTY_OUT", value: "EMPTY_OUT" },
  { label: "tranTypes.END_OF_SHIFT", value: "END_OF_SHIFT" },
  { label: "tranTypes.MANUAL_REFILL", value: "MANUAL_REFILL" },
  { label: "tranTypes.EXCHANGE", value: "EXCHANGE" },
  { label: "tranTypes.PURGE", value: "PURGE" },
  { label: "tranTypes.POSSIBLE_TAMPERING", value: "POSSIBLE_TAMPERING" },
  { label: "tranTypes.UNFINISHED_TRANSACTION", value: "UNFINISHED_TRANSACTION" },
  { label: "tranTypes.REFILL", value: "REFILL" },
];
