import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "MACHINE_USERS",
    text: "mu.title_other",
  },
  {
    route: "MACHINE_CREATE",
    text: "mu.create",
  },
];
