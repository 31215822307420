import { useMemo } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { FindAllMachinesQuery, useFindAllMachinesQuery, useFindMachinesEligibleToAttachToMgQuery } from "generated";

type AvailableMachineOption = {
  label: string;
  value: string;
};

type FilteredMachineGroupResult = NonNullable<NonNullable<FindAllMachinesQuery["findAllMachines"]>["result"]>[0] & {
  __typename: "MachineDtoOut";
};

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const value = useFieldData<string[] | undefined>(previous.name, "values");

  const [pickedOptions = [], { loading: firstLoading }] = useMappedQuery(
    (data, previousData) => {
      const result = ((data ?? previousData)?.findAllMachines?.result ?? []) as FilteredMachineGroupResult[];

      return result?.flatMap((p) =>
        p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : [],
      ) as AvailableMachineOption[];
    },
    useFindAllMachinesQuery({
      skip: !value?.length,
      variables: {
        machineFilters: {
          nodeIds: value,
        },
        searchRequest: {
          page: 0,
          size: value?.length ?? 0,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
    }),
  );

  const [machineData = previous, { loading }] = useMappedQuery((d) => {
    const options: AvailableMachineOption[] =
      d?.findMachinesEligibleToAttachToMG?.flatMap((p) =>
        p ? [{ label: p.name || "", value: p.nodeId || "" }] : [],
      ) ?? [];

    return {
      ...previous,
      isLoading: false,
      options,
    };
  }, useFindMachinesEligibleToAttachToMgQuery());

  const options = useMemo(
    () =>
      [
        ...pickedOptions,
        ...(machineData?.options?.filter((o) => !pickedOptions.find((p) => p.value === (o as any).value)) ?? []),
      ].sort(makeSorter("label")),
    [machineData, pickedOptions],
  );

  return [{ ...machineData, options, isLoading: firstLoading || loading }, !loading];
};

export const getAvailableMachinesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "administration.mg.availableMachines",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
