import { RouteConfig, RouteValue } from "routing";

import {
  CashInventory,
  CashManagement,
  CashSummary,
  CashTotals,
  ClaimedValues,
  LossPrevention,
  MachineDetails,
  MachineErrors,
  MachineManagement,
  MachineReliability,
  MachineUserBalancing,
  MachineUserManagement,
  MachineUserTransactions,
  ManualDeposits,
  NetCash,
  OnlineStatus,
  OnlineStatusCurrent,
  OpenSifts,
  QueryBuilder,
  RefillReport,
  Reports,
  RollingBalance,
  ScheduledReportsList,
  Transactions,
  Utilization,
} from "./pages";

export type ReportPaths = {
  CASH_MANAGEMENT: RouteValue;
  CASH_INVENTORY: RouteValue;
  CLAIMED_VALUES: RouteValue;
  OPEN_SHIFTS: RouteValue;
  CASH_SUMMARY: RouteValue;
  CASH_TOTALS: RouteValue;
  MACHINE_USER_MANAGEMENT: RouteValue;
  MACHINE_USER_BALANCING: RouteValue;
  MACHINE_USER_TRANSACTIONS: RouteValue;
  MACHINE_DETAILS: RouteValue;
  MACHINE_MANAGEMENT: RouteValue;
  MACHINE_ERRORS: RouteValue;
  MACHINE_RELIABILITY: RouteValue;
  MANUAL_DEPOSITS: RouteValue;
  NET_CASH: RouteValue;
  UTILIZATION: RouteValue;
  REFILL_REPORT: RouteValue;
  ONLINE_STATUS: RouteValue;
  ONLINE_STATUS_CURRENT: RouteValue;
  LOSS_PREVENTION: RouteValue;
  QUERY_BUILDER: RouteValue;
  SCHEDULED_REPORTS: RouteValue;
  TRANSACTIONS: RouteValue;
  REPORTS: RouteValue;
  ROLLING_BALANCE: RouteValue;
};

export const REPORT: RouteConfig<ReportPaths> = {
  REPORTS: {
    path: "/reports",
    Component: Reports,
  },
  QUERY_BUILDER: {
    path: "/reports/query-builder",
    Component: QueryBuilder,
  },
  SCHEDULED_REPORTS: {
    path: "/reports/scheduled-reports",
    Component: ScheduledReportsList,
  },
  MACHINE_MANAGEMENT: {
    path: "/reports/machine-management",
    Component: MachineManagement,
  },
  MACHINE_DETAILS: {
    path: "/reports/machine-management/machine-details",
    Component: MachineDetails,
  },
  MACHINE_ERRORS: {
    path: "/reports/machine-management/machine-errors",
    Component: MachineErrors,
  },
  MACHINE_RELIABILITY: {
    path: "/reports/machine-management/machine-reliability",
    Component: MachineReliability,
  },
  MANUAL_DEPOSITS: {
    path: "/reports/machine-management/manual-deposits",
    Component: ManualDeposits,
  },
  NET_CASH: {
    path: "/reports/machine-management/net-cash",
    Component: NetCash,
  },
  UTILIZATION: {
    path: "/reports/machine-management/utilization",
    Component: Utilization,
  },
  REFILL_REPORT: {
    path: "/reports/machine-management/refill-report",
    Component: RefillReport,
  },
  ONLINE_STATUS: {
    path: "/reports/machine-management/online-status",
    Component: OnlineStatus,
  },
  ONLINE_STATUS_CURRENT: {
    path: "/reports/machine-management/online-status-current",
    Component: OnlineStatusCurrent,
  },
  CASH_MANAGEMENT: {
    path: "/reports/cash-management",
    Component: CashManagement,
  },
  CASH_INVENTORY: {
    path: "/reports/cash-management/cash-inventory",
    Component: CashInventory,
  },
  CASH_TOTALS: {
    path: "/reports/cash-management/cash-totals",
    Component: CashTotals,
  },
  CASH_SUMMARY: {
    path: "/reports/cash-management/cash-summary",
    Component: CashSummary,
  },
  CLAIMED_VALUES: {
    path: "/reports/cash-management/claimed-values",
    Component: ClaimedValues,
  },
  OPEN_SHIFTS: {
    path: "/reports/cash-management/open-shifts",
    Component: OpenSifts,
  },
  MACHINE_USER_MANAGEMENT: {
    path: "/reports/machine-user-management",
    Component: MachineUserManagement,
  },
  MACHINE_USER_BALANCING: {
    path: "/reports/machine-user-management/balancing",
    Component: MachineUserBalancing,
  },
  MACHINE_USER_TRANSACTIONS: {
    path: "/reports/machine-user-management/transactions",
    Component: MachineUserTransactions,
  },
  TRANSACTIONS: {
    path: "/reports/transactions",
    Component: Transactions,
  },
  ROLLING_BALANCE: {
    path: "/reports/rolling-balance",
    Component: RollingBalance,
  },
  LOSS_PREVENTION: {
    path: "/reports/loss-prevention",
    Component: LossPrevention,
  },
};
