import { useMemo, useRef, useState } from "react";

import {
  SelectLiveFieldUseConfig,
  SelectLiveFieldValueArg,
  TransferBoxLiveFieldUseConfig,
  TransferBoxLiveFieldValueArg,
  getSelectLiveField,
  getTransferBoxLiveField,
} from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindAllMachineUserGroupsFilteredCountsQuery,
  FindAllMachineUserGroupsFilteredQuery,
  useFindAllMachineUserGroupsFilteredCountsQuery,
  useFindAllMachineUserGroupsFilteredQuery,
} from "generated";

type Option = {
  label: string;
  value: string;
};

const LIST_SIZE = 1000;

type MachineUserGroupCountResult = NonNullable<
  NonNullable<FindAllMachineUserGroupsFilteredCountsQuery["findAllMachineUserGroupsFiltered"]>["result"]
>[0] & {
  __typename: "MachineUserGroupDtoOut";
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const listSizeRef = useRef(LIST_SIZE);
  const [data = prev, { loading, refetch }] = useMappedQuery(
    (d) => {
      const fullSize = d?.findAllMachineUserGroupsFiltered?.fullSize ?? 0;
      const result = (d?.findAllMachineUserGroupsFiltered?.result ?? []) as MachineUserGroupCountResult[];
      const options: Option[] = result
        .flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : []))
        ?.sort(makeSorter("label"));

      return {
        ...prev,
        isLoading: false,
        options,
        onMenuScrollToBottom: () => {
          if (options.length >= fullSize) return;

          listSizeRef.current += LIST_SIZE;
          refetch({ searchRequest: { page: 0, size: listSizeRef.current } });
        },
      };
    },
    useFindAllMachineUserGroupsFilteredCountsQuery({
      variables: { searchRequest: { page: 0, size: listSizeRef.current }, filters: { showMachineExclusive: false } },
    }),
  );

  return [data, !loading];
};

export const getSelectMachineUserGroupField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machineUserGroup.title",
      isLoading: true,
      ...value,
    },
    useConfig,
  );

type MachineUserGroupResult = NonNullable<
  NonNullable<FindAllMachineUserGroupsFilteredQuery["findAllMachineUserGroupsFiltered"]>["result"]
>[0] & {
  __typename: "MachineUserGroupDtoOut";
};

const LIST_SIZE_BOX = 10;

export const useConfigGetMachineUserGroupsTransferBox: TransferBoxLiveFieldUseConfig = (prev) => {
  const listSizeRef = useRef(LIST_SIZE_BOX);
  const [filterText, setFilterText] = useState("");

  const value = useFieldData<string[] | undefined>(prev.name, "values");

  const [pickedOptions = []] = useMappedQuery(
    (data, previousData) => {
      const result = ((data ?? previousData)?.findAllMachineUserGroupsFiltered?.result ??
        []) as MachineUserGroupResult[];

      return result?.flatMap((p) => (p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : [])) as Option[];
    },
    useFindAllMachineUserGroupsFilteredQuery({
      skip: !value?.length,
      variables: {
        filters: {
          nodeIds: value,
          showMachineExclusive: false,
        },
        searchRequest: {
          page: 0,
          size: value?.length ?? 0,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
    }),
  );

  const [data = prev, { loading, refetch }] = useMappedQuery(
    (data, previousData) => {
      const response = data ?? previousData;
      const result = (response?.findAllMachineUserGroupsFiltered?.result ?? []) as MachineUserGroupResult[];

      const fullSize = response?.findAllMachineUserGroupsFiltered?.fullSize ?? 0;

      const options: Option[] = result.flatMap((p) => (p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : []));

      return {
        ...prev,
        options,
        fullSize,
        onScrollToBottom: () => {
          if (options.length >= fullSize) return;

          listSizeRef.current += LIST_SIZE_BOX;
          refetch({
            searchRequest: {
              page: 0,
              size: listSizeRef.current,
              sort: [{ fieldName: "name", order: "ASC" }],
            },
          });
        },
      };
    },
    useFindAllMachineUserGroupsFilteredQuery({
      variables: {
        filters: {
          name: filterText || undefined,
          showMachineExclusive: false,
        },
        searchRequest: {
          page: 0,
          size: listSizeRef.current,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
      notifyOnNetworkStatusChange: true,
    }),
    true,
  );

  const options = useMemo(
    () =>
      [...pickedOptions, ...data.options.filter((o) => !pickedOptions.find((p) => p.value === o.value))].sort(
        makeSorter("label"),
      ),
    [data, pickedOptions],
  );

  return [
    {
      ...data,
      options,
      isLoading: loading,
      onSearch: setFilterText,
      fullSize: (data.fullSize ?? 0) - (value?.length ?? 0),
    },
    true,
  ];
};

export const getMachineUserGroupsTransferBoxField = (value: TransferBoxLiveFieldValueArg) =>
  getTransferBoxLiveField(
    {
      label: "machineUserGroup.title_other",
      isLoading: true,
      ...value,
    },
    useConfigGetMachineUserGroupsTransferBox,
  );
