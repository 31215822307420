import { CellContext } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Badge } from "common/guideline";
import { RmTransactionType } from "generated";

const transactionType: Record<RmTransactionType, "success" | "error" | "warning" | "neutral" | "info"> = {
  DEPOSIT: "info",
  DISPENSE: "neutral",
  EMPTY_OUT: "warning",
  END_OF_SHIFT: "warning",
  EXCHANGE: "neutral",
  MANUAL_REFILL: "warning",
  POSSIBLE_TAMPERING: "warning",
  PURGE: "warning",
  REFILL: "warning",
  UNFINISHED_TRANSACTION: "warning",
};

export const TransactionType = ({ type }: { type?: RmTransactionType | undefined }) => {
  const { t } = useTranslation();
  const values = t("tranTypes", { returnObjects: true });

  return type ? <Badge variant={transactionType[type]}>{values[type]}</Badge> : <span>-</span>;
};

export const TransactionTypeCell = ({ getValue }: CellContext<any, any>) => {
  const value = getValue<RmTransactionType | undefined>();
  return <TransactionType type={value} />;
};
