import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ManualDepositSVG } from "assets/icons/ManualDeposit.svg";
import {
  PageLayout,
  SelectWrapper,
  useMappedSiteGroupContext,
  useQueryByDateFilter,
  useTimeZoneFilter,
} from "base/components";
import {
  CommonCells,
  DateRangeInfo,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  tableDownloadTitles,
  useDateRangeFilter,
  useDateRangeZoned,
  useSelectFilter,
} from "common/guideline";
import {
  GenerateManualDepositsReportQuery,
  ManualDepositGroupFactor,
  useGenerateManualDepositsReportQuery,
} from "generated";
import { FilterBox } from "report/components";
import { getTotalAmountsPairRows } from "report/helpers";

import { breadcrumbs } from "./breadcrumbs";

type Row = NonNullable<
  NonNullable<NonNullable<GenerateManualDepositsReportQuery["generateManualDepositsReport"]>["rows"]>[0]
>;

type ManualDepositsSubRow = NonNullable<NonNullable<Row["subRows"]>[0]>;
type ManualDepositRow = Row & ManualDepositsSubRow;

const getColumns: TableTypes.TranslatedColumns<ManualDepositRow> = (t) => [
  CommonCells.expander,
  {
    header: t("common.table.groupBy"),
    accessorKey: "groupValue",
    footer: t("report.total"),
  },
  {
    header: t("common.site"),
    accessorKey: "locationName",
  },
  {
    header: t("mu.cashierId"),
    accessorKey: "machineUserId",
  },
  {
    header: t("mu.cashierName"),
    accessorKey: "machineUserName",
  },
  {
    header: t("mu.cashierRole"),
    accessorKey: "roleName",
  },
  {
    header: t("tranReport.workUnit"),
    accessorKey: "workUnitName",
  },
  {
    header: t("machine.machine_one"),
    accessorKey: "machineName",
  },
  {
    header: t("mDepo.tranType"),
    accessorKey: "claimedValueTypes",
  },
  {
    header: t("mDepo.type"),
    accessorKey: "type",
  },
  {
    header: t("mDepo.devices"),
    accessorKey: "devices",
  },
  {
    header: t("mDepo.bagIds"),
    accessorKey: "bagIds",
  },
  {
    header: t("mDepo.date"),
    accessorFn: (v) => accessors.zonedDateTime(v.dateTime, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.dateTime, t),
      },
    },
  },
  {
    header: t("mDepo.machineDate"),
    accessorKey: "timeZoneUtcOffset",
  },
];

const groupFactorOptions: { value: ManualDepositGroupFactor; label: string }[] = [
  { value: "TYPE", label: "report.type" },
  { value: "WORK_UNIT", label: "tranReport.workUnit" },
  { value: "CASHIER", label: "mu.cashierId" },
  { value: "SITE", label: "location.location_one" },
];

export const ManualDeposits = () => {
  const { t, i18n } = useTranslation();
  const [date, DateRangeFilter] = useDateRangeFilter("currentDay", {
    label: "report.period",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });
  const [groupFactor = "SITE", SelectFilter] = useSelectFilter({
    label: "common.table.groupBy",
    initial: "SITE",
    options: groupFactorOptions,
    name: "groupFactor",
  });
  const [zoneId, TimeZone] = useTimeZoneFilter(false);
  const zonedDateRange = useDateRangeZoned(date, zoneId);
  const [queryTransactionBy, QueryByDateSelect] = useQueryByDateFilter("transactions");
  const [{ machine, location, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateManualDepositsReportQuery({
    variables: {
      input: {
        reportName: "ManualDepositsReport",
        fromDate: zonedDateRange.from,
        toDate: zonedDateRange.to,
        groupFactor,
        locationNodeIds: location ? location : siteGroup,
        machineNodeIds: machine,
        queryTransactionBy,
        zoneId,
      },
    },
  });

  const rows = data?.generateManualDepositsReport?.rows as ManualDepositRow[] | undefined;
  const totalAmounts = data?.generateManualDepositsReport?.totalAmounts;
  const columns = useMemo(
    () => [
      ...getColumns(t, i18n.language),
      ...getTotalAmountsPairRows(rows || [], t, {
        footer: (v) => v.column.accessorFn?.({ totalAmounts }, v.header.index),
      }),
    ],
    [rows, totalAmounts, t, i18n.language],
  );

  return (
    <PageLayout
      title="manualDeposits"
      subtitle="report.manualDepositsDescription"
      Icon={ManualDepositSVG}
      breadcrumbs={breadcrumbs}
      withPicker
    >
      <FilterBox>
        <SelectWrapper>{DateRangeFilter}</SelectWrapper>
        <SelectWrapper>{SelectFilter}</SelectWrapper>
        <SelectWrapper>{QueryByDateSelect}</SelectWrapper>
        {TimeZone}
      </FilterBox>
      <DateRangeInfo from={zonedDateRange.from} to={zonedDateRange.to} timeZone={zoneId} />
      <Table
        tableName="manualDeposists"
        data={rows || []}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDateRange(zonedDateRange, "manualDeposits", t),
                page,
              )
            }
            disabled={!rows?.length}
            getCsv="withSubRows"
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
