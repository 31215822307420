import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "common/guideline";
import { withDefault } from "common/helpers";

export const RawTable: React.FC<{
  name?: TKeys;
  headers: TKeys[];
  rows: any[][];
  customCells?: Record<string, React.FC<{ data: any }>>;
}> = ({ name, headers, rows, customCells = {} }) => {
  if (rows.length && headers.length != rows[0]?.length) {
    console.warn(`RawTable: headers.length not match data.length`);
    console.info({ headers, "rows[0]": rows[0] });
  }

  return (
    <>
      <table tw="my-3">
        <thead>
          {name ? (
            <tr tw="border-b border-gray-4">
              <Text as="th" variant="tableName" tKey={name} {...{ colSpan: headers.length }} />
            </tr>
          ) : null}
          <tr>
            {headers.map((tKey, colNr) => (
              <Text as="th" variant="tableCell" tKey={tKey} key={colNr} />
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((cols, rowNr) => (
            <tr tw="odd:bg-gray-3 even:bg-gray-2" key={rowNr}>
              {cols.map((data, colNr) =>
                ((CustomCell) =>
                  CustomCell ? (
                    <td tw="text-center" key={colNr}>
                      <CustomCell data={data} />
                    </td>
                  ) : (
                    <Text as="td" variant="tableCell" key={colNr}>
                      {withDefault(data)}
                    </Text>
                  ))(customCells[headers[colNr]]),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
