import { useBreakpoints } from "common/guideline/theme";

import { TableHorizontal } from "./TableHorizontal";
import { TableSkeleton } from "./TableSkeleton";
import { TableVertical } from "./TableVertical";
import { GenericRecord, TableProps } from "./types";

export function Table<T extends GenericRecord>(props: TableProps<T>): React.ReactElement {
  const isMinSm = useBreakpoints().min.sm;

  return props.loading && props.initialLoading ? (
    <TableSkeleton />
  ) : isMinSm ? (
    <TableHorizontal {...props} />
  ) : (
    <TableVertical {...props} />
  );
}
