import { Breadcrumbs, Container } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const Personalized = () => (
  <>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <Container variant="pageWrapper"></Container>
  </>
);
