import { client } from "client";
import { useToast } from "common/guideline";
import { deleteFile, evictQuery, getUrlContents, uploadFile } from "common/helpers";
import {
  CancelDeploymentDocument,
  CancelDeploymentMutation,
  CancelDeploymentMutationVariables,
  CreateDeploymentsDocument,
  CreateDeploymentsMutation,
  CreateDeploymentsMutationVariables,
  CreateSoftwareInstallationsDocument,
  CreateSoftwareInstallationsMutation,
  CreateSoftwareInstallationsMutationVariables,
  DeleteSoftwareInstallationDocument,
  DeleteSoftwareInstallationMutation,
  DeleteSoftwareInstallationMutationVariables,
  FindSoftwareInstallationsDocument,
  GenerateMachineViewTabDocument,
  GetDeploymentsForMachineNodeIdDocument,
  GetPreSignedDataForConfigurationDocument,
  GetPreSignedDataForConfigurationQuery,
  GetPreSignedDataForConfigurationQueryVariables,
  GetPreSignedDataForSoftwarePackageDocument,
  GetPreSignedDataForSoftwarePackageQuery,
  GetPreSignedDataForSoftwarePackageQueryVariables,
} from "generated";

const whitelistedExceptions: string[] = ["SyntaxError: Unexpected end of JSON input"];

export const downloadConfigurationFile = async (key: string) => {
  const urlForGet = await getPresignedUrlForTemplate(key, "urlForGet");
  if (!urlForGet) {
    showError("administration.rc.missingPreSignedUrl");
  } else {
    getUrlContents(urlForGet);
  }
};

export const downloadSoftwarePackageFile = async (fileName: string) => {
  const urlForGet = await getPresignedUrlForSoftwarePackage(fileName, "urlForGet");
  if (!urlForGet) {
    showError("administration.sp.missingPreSignedUrl");
  } else {
    getUrlContents(urlForGet);
  }
};

export const deleteConfigurationFile = async (key: string) => {
  const urlForDelete = await getPresignedUrlForTemplate(key, "urlForDelete");
  if (!urlForDelete) {
    showError("administration.rc.missingPreSignedUrl");
  } else {
    deleteFile(urlForDelete);
  }
};

export const deleteSoftwarePackageFile = async (fileName: string) => {
  const urlForDelete = await getPresignedUrlForSoftwarePackage(fileName, "urlForDelete");
  if (!urlForDelete) {
    showError("administration.sp.missingPreSignedUrl");
  } else {
    deleteFile(urlForDelete);
  }
};

export const uploadPackageFile = async (fileName: string, file: File) => {
  const urlForPut = await getPresignedUrlForSoftwarePackage(fileName, "urlForPut");

  if (!urlForPut) {
    showError("administration.sp.missingPreSignedUrl");
  } else {
    try {
      await uploadFile(file, urlForPut, file.type);
    } catch (error) {
      if (!whitelistedExceptions.some((we) => we === error)) {
        showError("administration.sp.uploadFailure");
      }
    }
  }
};

export const uploadConfigurationFile = async (nameOfFile: string, file: File) => {
  const urlForPut = await getPresignedUrlForTemplate(nameOfFile, "urlForPut");

  if (!urlForPut) {
    showError("administration.rc.missingPreSignedUrl");
  } else {
    try {
      await uploadFile(file, urlForPut, file.type);
    } catch (error) {
      if (!whitelistedExceptions.some((we) => we === error)) {
        showError("administration.rc.uploadFailure");
      }
    }
  }
};

export const performRemoteMgmtOperation = async (
  remoteMgmtOption: string | undefined,
  machineUuids: (string | null | undefined)[] | null | undefined,
  templateNodeId: string | undefined,
  softwarePackageNodeId: string | undefined,
  scheduleInfo: string | undefined,
  createdBy: string | undefined,
) => {
  if (remoteMgmtOption === "configuration") {
    if (templateNodeId && machineUuids && machineUuids.length > 0) {
      await applyConfiguration(machineUuids as string[], templateNodeId, scheduleInfo);
    }
  } else if (remoteMgmtOption === "software") {
    if (softwarePackageNodeId && machineUuids && machineUuids.length > 0) {
      await upgradeSoftware(machineUuids as string[], softwarePackageNodeId, scheduleInfo, createdBy ?? "");
    }
  }
};

export const cancelSoftwareInstallation = async (installationNodeId: string) =>
  (
    await client.mutate<DeleteSoftwareInstallationMutation, DeleteSoftwareInstallationMutationVariables>({
      mutation: DeleteSoftwareInstallationDocument,
      variables: {
        nodeId: installationNodeId,
      },
      update: (cache) => {
        evictQuery(GenerateMachineViewTabDocument, cache);
        evictQuery(FindSoftwareInstallationsDocument, cache);
      },
    })
  ).data?.deleteSoftwareInstallation?.valueOf;

export const cancelConfigurationDeployment = async (deplomentNodeId: string) =>
  (
    await client.mutate<CancelDeploymentMutation, CancelDeploymentMutationVariables>({
      mutation: CancelDeploymentDocument,
      variables: {
        nodeId: deplomentNodeId,
      },
      update: (cache) => {
        evictQuery(GenerateMachineViewTabDocument, cache);
        evictQuery(GetDeploymentsForMachineNodeIdDocument, cache);
      },
    })
  ).data?.cancelDeployment?.valueOf;

const applyConfiguration = async (machineUuids: string[], tNodeId: string, info = "now") =>
  (
    await client.mutate<CreateDeploymentsMutation, CreateDeploymentsMutationVariables>({
      mutation: CreateDeploymentsDocument,
      variables: {
        deployment: { machineUuids, templateNodeId: tNodeId, scheduleInfo: info },
      },
      update: (cache) => {
        evictQuery(GenerateMachineViewTabDocument, cache);
        evictQuery(GetDeploymentsForMachineNodeIdDocument, cache);
      },
    })
  ).data?.createDeployments?.values;

const upgradeSoftware = async (machineUuids: string[], spNodeId: string, info = "now", createdBy: string) =>
  (
    await client.mutate<CreateSoftwareInstallationsMutation, CreateSoftwareInstallationsMutationVariables>({
      mutation: CreateSoftwareInstallationsDocument,
      variables: {
        softwareInstallation: { createdBy, machineUuids, softwarePackageNodeId: spNodeId, scheduleInfo: info },
      },
      update: (cache) => {
        evictQuery(GenerateMachineViewTabDocument, cache);
        evictQuery(FindSoftwareInstallationsDocument, cache);
      },
    })
  ).data?.createSoftwareInstallations?.values;

const getPresignedUrlForTemplate = async (key: string, url: string): Promise<string | null | undefined> =>
  (
    await client.query<GetPreSignedDataForConfigurationQuery, GetPreSignedDataForConfigurationQueryVariables>({
      query: GetPreSignedDataForConfigurationDocument,
      variables: { fileName: key },
    })
  ).data?.getPreSignedDataForConfiguration?.[url];

const getPresignedUrlForSoftwarePackage = async (fileName: string, url: string): Promise<string | null | undefined> =>
  (
    await client.query<GetPreSignedDataForSoftwarePackageQuery, GetPreSignedDataForSoftwarePackageQueryVariables>({
      query: GetPreSignedDataForSoftwarePackageDocument,
      variables: { fileName },
    })
  ).data?.getPreSignedDataForSoftwarePackage?.[url];

const showError = (error: string) => {
  useToast.actions.show(error, { variant: "error" });
};
