import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { useMappedSiteGroupContext } from "base/components";
import { useFlags } from "base/featureFlags";
import { Flags } from "base/featureFlags/types";
import { Table, TableTypes, accessors, localFilters, usePagination } from "common/guideline";
import { AlertHistoryOrderColumn, FindAlertsByCriteriaQuery, useFindAlertsByCriteriaQuery } from "generated";
import { useTranslation } from "i18n";
import { useColumnFiltersArray } from "report/components";
import { SortByColumnsData, useSorting } from "report/hooks";

import { AlertLevelBadge } from "../../components";

const TABLE_NAME = "alertsHistory";

type AlertViewData = NonNullable<
  NonNullable<NonNullable<NonNullable<FindAlertsByCriteriaQuery["findAlertsByCriteria"]>["rows"]>[0]>
>;

const getColumnsWithFlag = (flags: Partial<Flags>) => {
  const getColumns: TableTypes.TranslatedColumns<AlertViewData, SortByColumnsData<AlertHistoryOrderColumn>> = (t) => [
    {
      header: t("machine.machine_one"),
      id: "MACHINE_NAME",
      accessorKey: "machineName",
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
    {
      header: t("location.location_one"),
      id: "LOCATION_NAME",
      accessorKey: "locationName",
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
    {
      header: t("alerts.date"),
      id: "DATE_TIME",
      accessorFn: (v) => accessors.date(v.dateTime, t),
    },
    {
      header: t("alerts.level"),
      id: "ALERT_LEVEL",
      accessorKey: "alertLevel",
      cell({ getValue }) {
        return <AlertLevelBadge value={getValue<AlertViewData["alertLevel"]>()} />;
      },
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
    {
      header: t("alerts.text"),
      id: "ALERT_TEXT",
      accessorKey: "alertText",
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
    {
      header: t("alerts.type"),
      id: "TRIGGER_TYPE",
      accessorFn: (v) => t(`alerts.triggerType.${v.triggerType || "EMAIL"}`),
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
    {
      header: t("alerts.contact"),
      id: "CONTACT",
      accessorKey: "contact",
      meta: {
        ...(flags.alertsHistory ? localFilters.getTextBaseFilter.meta : {}),
        hideVertical: true,
      },
    },
  ];
  return getColumns;
};

export const AlertsHistoryTable = ({ from, to }: { from: string; to: string }) => {
  const flags = useFlags();
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumnsWithFlag(flags)(t, i18n.language), [t, i18n.language, flags]);
  const [columnFilters, setColumnFilters] = useColumnFiltersArray<AlertHistoryOrderColumn>();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<AlertHistoryOrderColumn>();
  const [{ location, machine }] = useMappedSiteGroupContext(true);

  const alertHistoryFilteringField = useMemo(() => {
    const value = columnFilters[0]; // Safari bug (at() method not handled properly) fixed
    if (value === undefined) return undefined;
    return { filteringField: value.id, value: value.value } as any;
  }, [columnFilters]);

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAlertsByCriteriaQuery({
    variables: {
      input: {
        fromDate: from,
        toDate: to,
        limit: pageSize,
        skip: pageIndex * pageSize,
        locationNodeIds: location,
        machineNodeIds: machine,
        order,
        orderColumn,
        alertHistoryFilteringField,
      },
    },
  });

  const rows = (data?.findAlertsByCriteria?.rows ?? []) as AlertViewData[];
  const fullSize = data?.findAlertsByCriteria?.fullSize ?? 0;

  return (
    <Table
      columns={columns}
      data={rows}
      error={error}
      initialLoading={false}
      loading={loading}
      onlySingleFilter
      columnFilters={columnFilters}
      onFilter={setColumnFilters}
      onPagination={setPagination}
      onSorting={setSorting}
      pageIndex={pageIndex}
      pageSize={pageSize}
      sorting={sorting}
      tableName={TABLE_NAME}
      totalCount={fullSize}
    />
  );
};
