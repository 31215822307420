import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { EntityDelete } from "base/components";
import { ComponentModalProps, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { GetAllCommandsDocument, useDeleteRmCommandMutation } from "generated";
import { Trans } from "i18n";
import { CommandFetched } from "zts/types";

type CommandData = WithoutNullableKeys<Pick<CommandFetched, "nodeId" | "name">>;

type Props = CommandData & ComponentModalProps;

const DeleteCommand: React.FC<Props> = ({ name, nodeId, onClose }) => {
  const [deleteRmCommand, { loading }] = useDeleteRmCommandMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "zts.commands.deleteSuccess" : "zts.commands.deleteError", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deleteRmCommand({
        variables: { commandNodeId: nodeId },
        ignoreResults: true,
        update: (cache) => evictQuery(GetAllCommandsDocument, cache),
      });
      showToast(!!result.data?.deleteRmCommand);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="zts.commands.deleteTitle" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="zts.commands.deleteDesc" values={{ name }}>
        {[<strong key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const useDeleteCommandModal = ({ name, nodeId }: CommandData) =>
  useModalComponent({
    Component: <DeleteCommand name={name} nodeId={nodeId} />,
  });
