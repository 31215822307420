export const de = {
  zts: {
    title: "Fernverwaltung von Geräten",
    commands: {
      title: "Befehle",
      command: "Befehl",
      desc: "Ausführen eines Befehls auf der ausgewählten Maschine",
      executeCommand: "Befehl ausführen",
      execute: "Ausführen",
      commandHistory: "Historie der auf dem ausgewählten Rechner ausgeführten Befehle",
      started: "Gestartet",
      ended: "Beendet",
      status: "Status",
      parameters: "Parameter",
      executedBy: "Ausgeführt von",
      deleteSuccess: "Erfolgreich den Befehl gelöscht",
      deleteError: "Fehler beim Versuch, den Befehl zu löschen.",
      deleteTitle: "Befehl aus der Historie löschen",
      deleteDesc: "Der Befehl wird aus der Historie gelöscht, weiter?",
      close: "Schließen",
      submit: "Löschen",
      selectCommand: "Befehl auswählen",
      restart: "Restart Maschine",
      reboot: "Reboot Maschine",
      temporarilyOffline: "Die Maschine wird vorübergehend offline sein, ok?",
      restartSuccess: "Anforderung zum Neustart des Rechners gesendet, bitte warten Sie ein paar Minuten.",
      rebootSuccess: "Anforderung zum Neustart des Rechners gesendet, bitte warten Sie ein paar Minuten.",
      restartFailure:
        "Anforderung zum Neustart des Computers fehlgeschlagen, bitte versuchen Sie es in ein paar Minuten erneut.",
      rebootFailure:
        "Anforderung zum Neustart des Computers fehlgeschlagen, bitte versuchen Sie es in ein paar Minuten erneut.",
      noTypesSelected: "Keine Arten ausgewählt",
      statuses: {
        InProgress: "Bitte warten",
        Restarting: "Startet neu",
        Rebooting: "Startet neu",
        Failure: "Gescheitert",
        FailedToClaim: "Maschine war beschäftigt",
        Canceled: "Abgebrochen",
        Ok: "OK",
        Timeout: "Timed out",
      },
      logs: {
        title: "Logs",
        logsDesc: "Abrufen und Anzeigen von Protokollen für den ausgewählten Rechner",
        retrieveLogs: "Abrufen von logs",
        retrievedLogs: "Abrufen von log Arcihven",
        retrieveLogsModalTitle: "Abrufen von logs für ausgewählte Maschine",
        retrieveLogsDesc: "Auswahl des Zeitraums und der Protokolltypen.",
        viewLogs: "Siehe logs",
        from: "von",
        to: "bis",
        fromMustBeSmallerThanTo: "Muss vor sein bis",
        toMustBeGreaterThanFrom: "Muss vor sein von",
        retrievalDate: "Datum des Abrufs",
        file: "File",
        fileSize: "Größe",
        noFiles: "Menge an files",
        retrievedBy: "Zurückgeholt von",
        profiling: "Profiling",
        configuration: "Konfiguration",
        reports: "Berichte",
        history: "Historie",
        receipts: "Belege",
        icx: "ICX",
        data: "Data",
        devices: "Geräte",
        online: "Online",
        trace: "Spur",
        logTypes: "Log arten",
        fullSystemDump: "Full system dump?",
        retrieveLogsSuccess:
          "Befehl zum Abrufen von Protokollen gesendet, bitte aktualisieren Sie diese Ansicht in ein paar Minuten.",
        retrieveLogsFailure: "Beim Senden des Befehls zum Abrufen von Protokollen ist ein Fehler aufgetreten.",
        deleteSuccess: "Erfolgreiches Löschen des Protokollarchivs",
        deleteError: "Ein Fehler beim Versuch, das Protokollarchiv zu löschen.",
        deleteTitle: "Protokollarchiv löschen",
        deleteDesc: "Metadaten und das hochgeladene Protokollarchiv werden gelöscht, weiter?",
        missingPreSignedUrl: "Vorgegebene Url konnte nicht abgerufen werden",
        selectLogFile: "Select log file",
        fileTooLarge: "Datei ist zu groß!",
        fileTooLargeDesc: "Die Datei ist zu groß, möchten Sie sie stattdessen herunterladen?",
        noLogsToShow: "Es ist ein Fehler aufgetreten, oder es gibt keine Protokolle, die dies zeigen.",
        err: {
          _1: "Unbekannter Fehler. Bitte versuchen Sie es später noch einmal.",
          _2: "Muss eine Zip-Datei sein.",
        },
      },
    },
    remoteUpgrade: {
      upgradeHistory: "Upgrade history",
      upgradeHistoryDesc: "Historie der Software-Installationen auf dem ausgewählten Rechner",
      timeOfUpgrade: "Upgrade gestartet",
      name: "Name",
      label: "Label",
      softwareVersion: "Software version",
      revision: "Revision",
    },
    remoteConfiguration: {
      configurationHistory: "Konfigurationsverlauf",
      configurationHistoryDesc: "Historie der Konfigurationsbereitstellungen auf dem ausgewählten Rechner",
      scheduledAt: "Geplant am",
      description: "Beschreibung",
    },
    result: "Status",
    name: "Name",
    executedBy: "Ausgeführt von",
    latestStatusChange: "Status geändert",
  },
};
