import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { NodeStatusBadge } from "base/components";
import { CommonCells, Table, TableTypes, Tooltip, accessors, usePagination } from "common/guideline";
import { useFindAllMachineGroupsFilteredQuery } from "generated";
import { useTranslation } from "i18n";
import { MachinesList } from "machine/components";
import { useSorting } from "report/hooks";

import { MachineGroupVerticalHeader } from "./MachineGroupVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineGroupRow } from "./types";

const columns: TableTypes.TranslatedColumns<MachineGroupRow> = (t) => [
  {
    id: "name",
    header: t("administration.mg.name"),
    accessorKey: "name",
    meta: {
      hideVertical: true,
    },
  },
  {
    id: "nodeStatus",
    header: t("administration.mg.nodeStatus"),
    accessorKey: "nodeStatus",
    cell: NodeStatusBadge,
  },
  {
    id: "nodeChangeDate",
    header: t("administration.mg.nodeChangeDate"),
    accessorFn: (d) => accessors.date(d.nodeChangeDate, t),
  },
  {
    id: "machines",
    header: t("administration.mg.machines"),
    accessorKey: "machines",
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<MachineGroupRow["machines"]>() || [];
      const machinesCount = value?.length || 0;

      return (
        <div tw="inline-block">
          <Tooltip content={<MachinesList nodeIds={value.flatMap((m) => m?.nodeId ?? [])} />} data-test="showMachines">
            <div tw="flex items-center text-primary-default">
              <ComputerTowerSVG width={18} height={18} tw="mr-2" />
              {machinesCount}
            </div>
          </Tooltip>
        </div>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "machineGroups";

export const MachineGroupTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllMachineGroupsFilteredQuery({
    variables: {
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });
  const value = (data?.findAllMachineGroupsFiltered?.result || []) as MachineGroupRow[];
  const totalCount = data?.findAllMachineGroupsFiltered?.fullSize ?? 0;

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={value}
      VerticalHeader={MachineGroupVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
      sorting={sorting}
      onSorting={setSorting}
      totalCount={totalCount}
    />
  );
};
