export const omitByKey = (keyToOmit: string | string[]) => {
  const shouldNotOmit = Array.isArray(keyToOmit)
    ? (key: string) => !keyToOmit.includes(key)
    : (key: string) => keyToOmit !== key;

  const omit = <T, K = T>(obj: T): K =>
    Array.isArray(obj)
      ? obj.map(omit)
      : obj && typeof obj === "object"
      ? Object.entries(obj).reduce((acc, [key, value]) => {
          if (shouldNotOmit(key)) acc[key] = omit(value);
          return acc;
        }, {} as any)
      : obj;

  return omit;
};
