import tw, { css } from "twin.macro";
import "styled-components/macro";

import { InfoState, ValueWidget, useSiteGroupContext } from "base/components";
import { defaultNumber } from "common/helpers";
import { useGetSumValueOfTransactionsQuery } from "generated";

import { useTransportFilters } from "./TransportFilters";

export const TransportWidgets: React.FC = () => {
  const { machine } = useSiteGroupContext();
  const withoutMachine = !machine;
  const { date, currency, type, zoneId, transactionDateTime } = useTransportFilters();
  const { data, loading } = useGetSumValueOfTransactionsQuery({
    skip: withoutMachine,
    variables: {
      currency,
      dateTime: date,
      machineNodeId: machine || "",
      transactionType: type,
      transactionDateTime,
      zoneId,
    },
  });

  return withoutMachine ? (
    <InfoState tKey="transport.dashboard.pickMachine" state="info" variant="paragraph" />
  ) : (
    <div
      tw="grid md:grid-cols-2 lg:grid-cols-3 pt-4 [grid-gap:12px] "
      css={
        loading
          ? css`
              ${tw`animate-pulse`}
            `
          : undefined
      }
    >
      <ValueWidget value={defaultNumber(data?.deposit)} currency={currency} title="tranTypes.DEPOSIT" />
      <ValueWidget value={defaultNumber(data?.dispense)} currency={currency} title="tranTypes.DISPENSE" />
      <ValueWidget value={defaultNumber(data?.exchange)} currency={currency} title="tranTypes.EXCHANGE" />
      <ValueWidget value={defaultNumber(data?.emptyOut)} currency={currency} title="tranTypes.EMPTY_OUT" />
      <ValueWidget value={defaultNumber(data?.endOfShift)} currency={currency} title="tranTypes.END_OF_SHIFT" />
      <ValueWidget value={defaultNumber(data?.manualRefill)} currency={currency} title="tranTypes.MANUAL_REFILL" />
      <ValueWidget
        value={defaultNumber(data?.possibleTampering)}
        currency={currency}
        title="tranTypes.POSSIBLE_TAMPERING"
      />
      <ValueWidget value={defaultNumber(data?.purge)} currency={currency} title="tranTypes.PURGE" />
      <ValueWidget value={defaultNumber(data?.refill)} currency={currency} title="tranTypes.REFILL" />
      <ValueWidget
        value={defaultNumber(data?.unfinishedTransaction)}
        currency={currency}
        title="tranTypes.UNFINISHED_TRANSACTION"
      />
    </div>
  );
};
