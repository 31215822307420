import { TKeys } from "i18next";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getActiveOrInActiveStatusField } from "base/fields";
import { OnSubmit, SchemaForm, useField } from "common/form";
import { CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent } from "common/guideline";
import { getRemoteMgmtSection } from "common/remoteMgmt";
import { MachineGroupDtoIn } from "generated";

import { getAvailableMachinesField } from "./AvailableMachinesField";
import { MultipleAdd } from "./MultipleAddDialog";

export type MachineGroupFormData = Pick<MachineGroupDtoIn, "name" | "machineNodeIds" | "nodeStatus"> & {
  timeOption?: string;
  remoteMgmtOption?: string;
  templateNodeId?: string;
  softwarePackageNodeId?: string;
  scheduleInfo?: string;
  createdBy?: string;
};

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const getFields: (updateForm: boolean | undefined) => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "administration.mg.name",
        validate: { type: "string", required: true },
      },
      getAvailableMachinesField({ name: "machineNodeIds" }),
      getActiveOrInActiveStatusField({ name: "nodeStatus", defaultValue: "ACTIVE" }),
      {
        type: "custom",
        name: "machineUuids",
        label: "label",
        Component: ({ name }) => {
          const [t] = useTranslation();
          const { onChange, value } = useField(name);

          const handleDataReady = (data: string[]) => {
            onChange(data);
            showBulkMachineAdd();
          };

          const [showBulkMachineAdd] = useModalComponent({
            Component: (
              <MultipleAdd
                callToAction={t("administration.mg.multipleAdd")}
                info={<span>{t("administration.mg.multipleAddToGroup", { name: "new" })}</span>}
                onDataReady={handleDataReady}
              />
            ),
            modalProps: {
              variant: ["modal", "md"],
            },
          });

          return (
            <label tw="flex flex-col w-fit">
              <TextLabel tKey="administration.mg.multipleAdd" />
              <div tw="flex items-center gap-2">
                <Button tw="h-10 sm:text-sm" onClick={() => showBulkMachineAdd()}>
                  {t("administration.mg.multipleAdd")}
                </Button>
                {value && (
                  <Text
                    tKey="administration.mg.multipleAdd"
                    tValue={{ count: (value as string[]).length }}
                    variant="paragraph"
                  />
                )}
              </div>
            </label>
          );
        },
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<MachineGroupFormData>;
  submitLabel: TKeys;
  initial?: MachineGroupFormData;
  updateForm?: boolean;
};

export const MachineGroupForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const fields = useMemo(() => getFields(updateForm).concat(getRemoteMgmtSection()), [updateForm]);

  return (
    <SchemaForm<MachineGroupFormData>
      fields={fields}
      initial={initial}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_GROUPS" })}
            data-test="goBack"
          >
            <Text tKey="administration.mg.goBack" />
          </Button>
          <Button type="submit" tw="mt-6" data-test="submitForm">
            <Text tKey={submitLabel} />
          </Button>
        </div>
      )}
    />
  );
};
