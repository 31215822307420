import { useSelectFilter } from "common/guideline";
import { MessageDateTime, TransactionDateTime } from "generated";

const messageDateTime: { value: MessageDateTime; label: string }[] = [
  { value: "DATE_TIME", label: "base.queryByDate.dateTime" },
  { value: "RECEIVED_DATE_TIME", label: "base.queryByDate.received" },
];

export const transactionDateTime: { value: TransactionDateTime; label: string }[] = [
  { value: "ACCOUNTING_DATE_TIME", label: "base.queryByDate.accounting" },
  ...messageDateTime,
];

export const useQueryByDateFilter = <T extends "transactions" | "message">(optionsType: T) =>
  useSelectFilter({
    label: "base.queryByDate.label",
    initial: "DATE_TIME",
    options: optionsType === "transactions" ? transactionDateTime : messageDateTime,
  }) as [T extends "transactions" ? TransactionDateTime : MessageDateTime, React.ReactNode];
