import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "common/guideline/components/helpers";

export * from "./BooleanFilter";
export * from "./ColumnFilter";

type Props = WithVariants<typeof variants>;

const variants = {
  row: tw`space-x-4`,
  col: tw`flex-col space-y-4`,
};

export const FilterBox = styled.div<Props>`
  ${tw`flex pb-4 print:hidden`}
  ${applyVariant(variants, "row")}
`;
