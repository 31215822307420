import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindAllDomainNamesQuery } from "generated";

type DomainNameOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const [userRolesData = previous, { loading }] = useMappedQuery((d) => {
    const options: DomainNameOption[] =
      d?.findAllDomainNames
        ?.flatMap((dn) => (dn ? [{ label: dn || "", value: dn || "" }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...previous,
      isLoading: false,
      options,
    };
  }, useFindAllDomainNamesQuery());

  return [userRolesData, !loading];
};

export const getDomainNamesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "user.access.domain",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    useConfig,
  );
