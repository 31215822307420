import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { VariantsByKeys, WithVariants, applyVariant, hasVariant } from "common/guideline/components/helpers";
import { Text } from "common/guideline/components/Text";

type BoxVariantsKeys = "sm" | "md" | "square" | "error";

type BoxVariants = VariantsByKeys<BoxVariantsKeys>;

const variants: BoxVariants = {
  sm: css`
    ${tw`py-0.5 px-1 h-8`}
  `,
  md: css`
    ${tw`p-2 h-10`}
  `,
  square: css<WithVariants<BoxVariants>>`
    ${tw`w-10 text-center truncate`}
    ${({ variant }) => hasVariant(variant, "sm") && tw`w-8`}
  `,
  error: css`
    ${tw`border-error-default`}
  `,
};

export type FieldBoxVariantsProp = WithVariants<typeof variants>;

export const fieldBoxVariants = applyVariant(variants, "md");

export const fieldWrapperStyle = css`
  ${tw`flex flex-col text-gray-6 focus-within:text-primary-default`}
`;

export const fieldBoxStyle = css<{ error?: any }>`
  ${tw`rounded bg-gray-2 border border-gray-3 text-gray-7 outline-none text-sm`}
  ${({ error }) => (error ? tw`border-error-default text-error-default` : tw`focus:border-primary-default`)}
`;

export const TextLabel = styled(Text)<{ error?: any }>`
  ${tw`cursor-text text-xs text-gray-7 font-semibold pb-1.5`}
  ${({ error }) => error && tw`text-error-default`}
`;

export const TextPlaceholder = styled(Text)`
  ${tw`text-gray-5`}
`;
