import { FormEvent } from "react";
import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { clone, getPath, isDeepEqual, isEmpty, setDeep, unset } from "common/helpers";

import { getGetterByPath } from "../getGetterByPath";

import { createCalculate } from "./createCalculate";
import { Calculation, FormArgs, FormErrors, FormStore, Mode, ReinvalidateMode, TForm, TFormContext } from "./types";
import { validation } from "./validation";

export const shouldValidate = (
  mode: Mode,
  reinvalidateMode: ReinvalidateMode,
  eventType: ReinvalidateMode,
  hasError: boolean,
  isTouched: boolean,
): boolean =>
  mode === eventType ||
  (mode === "onTouched" && (eventType === "onBlur" || isTouched)) ||
  (reinvalidateMode === eventType && hasError);

export const createForm = <T>({
  initial = {} as T,
  onSubmit,
  mode = "onSubmit",
  reinvalidateMode = "onChange",
  validationSchema,
  initialErrors = undefined,
  unsetEmpty = true,
}: FormArgs<T>): TFormContext<T> => {
  const fieldsValidation = validation();
  // in case values will be mutated - we store _initial to check isDirty
  let _initial = initial;
  let values = clone(initial || {});
  let errors = clone(initialErrors);
  const calculations: Set<Calculation> = new Set();
  const getCalculations = () => calculations;

  const useStore: FormStore<T> = create(
    subscribeWithSelector<TForm<T>>((set, get) => ({
      values,
      errors,
      touched: {},
      data: {},
      mode,
      reinvalidateMode,
      validationSchema,
      isValid: !initialErrors,
      isDirty: false,
      isSubmittig: false,
      isValidating: false,
      active: null,

      setField: (
        name,
        // error and touched as `null` unset it from states, value as null set value to be null
        { value, touched, error },
      ) => {
        const state = get();
        const path = getPath(name);
        const getter = getGetterByPath(name);
        const updateValue = value !== undefined && value !== getter(state.values);
        const updateError = error !== undefined && error !== getter(state.errors);
        const updateTouched = touched !== undefined && touched !== getter(state.errors);

        if (updateValue || updateError || updateTouched) {
          set({
            ...(updateValue && {
              values:
                unsetEmpty && (value as any) === ""
                  ? unset({ ...state.values }, path) || values
                  : setDeep(state.values, path, value),
            }),
            ...(updateError && {
              errors: error === null ? unset({ ...state.errors }, path) : setDeep(state.errors, path, error),
            }),
            ...(updateTouched && {
              touched: !touched ? unset({ ...state.touched }, path) : setDeep(state.touched, path, touched),
            }),
          } as NonNullable<Pick<TForm<T>, "values" | "errors" | "touched">>);
        }
      },
      validateField: async (name, newValue, updateState) => {
        const state = get();
        const fieldError = fieldsValidation.validateField(name, state, newValue);

        const error = state.validationSchema?.validateField
          ? await Promise.all([
              state.validationSchema?.validateField(
                newValue || getGetterByPath(name)(state.values),
                state.values,
                name,
              ),
            ]).then((res) => {
              const errors = [fieldError, ...res].filter(Boolean).flat();
              // TODO allow user to decide if should get only first error or all
              return errors.length <= 1 ? errors[0] : (errors as any);
            })
          : fieldError;

        if (updateState) state.setField(name, { error });

        return error;
      },
      validateForm: async () => {
        set({ isValidating: true });
        const state = get();

        let formErrors: FormErrors<T> = fieldsValidation.validateAllFields(state);

        if (state.validationSchema) {
          formErrors = await state.validationSchema.validate(state.values, formErrors);
        }

        const withoutErrors = isEmpty(formErrors);

        set({
          errors: withoutErrors ? undefined : formErrors,
          isValidating: false,
        });

        return withoutErrors;
      },
      onFocusField: (name) => set({ active: name }),
      onChangeField: async (name, eventType, value) => {
        const isBlured = eventType === "onBlur";
        if (isBlured) {
          set({ active: null });
        }

        const getter = getGetterByPath(name);
        const state = get();
        const isTouched = Boolean(getter(state.touched));
        const hasError = Boolean(getter(state.errors));

        // update touched and value
        state.setField(name, {
          // when onBlur - just pass value, otherwise - treat null  and undefined as empty string to be correctly cleared
          value: isBlured ? value : value ?? "",
          touched: !isBlured || isTouched ? undefined : true,
        });

        if (shouldValidate(state.mode, state.reinvalidateMode, eventType, hasError, isTouched)) {
          const error = await state.validateField?.(name, value);
          state.setField(name, {
            error: error || null,
          });
        }
      },
      reset: (newInitialValues = {} as T, { errors: newInitialErrors = undefined, touched, validate = false } = {}) => {
        // updates initial values
        _initial = newInitialValues;
        values = clone(newInitialValues);
        errors = clone(newInitialErrors);

        set({
          values,
          errors: errors,
          touched: touched || {},
          isSubmittig: false,
          isValidating: false,
          isDirty: false,
          isValid: isEmpty(errors),
        });

        if (validate) {
          get().validateForm();
        }
      },
      submit: async (e?: FormEvent) => {
        if (e?.preventDefault) {
          e.preventDefault();
          e.persist();
        }

        const state = get();

        if (state.isSubmittig) {
          return;
        }

        const isValid = await state.validateForm();

        if (isValid) {
          set({ isSubmittig: true });
          const submitErrors = await onSubmit(state.values, get());
          set({ isSubmittig: false, errors: submitErrors || undefined });
        }
      },

      registerFieldMeta: (name, { defaultValue, validate, calculation, data }) => {
        const sub: Array<() => void> = [];

        if (validate) sub.push(fieldsValidation.registerValidation(name, validate));

        if (calculation) {
          const calculate = (calculation.field ? calculation : { ...calculation, field: name }) as Calculation;
          calculations.add(calculate);
          sub.push(() => calculations.delete(calculate));
        }

        const setData = data !== undefined;
        const setDefaultValue = defaultValue !== undefined && getGetterByPath(name)(get().values) === undefined;

        if (setData || setDefaultValue) {
          set(
            (p) =>
              ({
                ...(setDefaultValue && {
                  values: setDeep(p.values, getPath(name), defaultValue),
                }),
                ...(setData && {
                  data: {
                    ...p.data,
                    [name]: typeof data === "function" ? data(p.data[name]) : data,
                  },
                }),
              } as NonNullable<Pick<TForm<T>, "values" | "data">>),
          );
        }

        return () => {
          sub.forEach((s) => s());
        };
      },
    })),
  );

  useStore.subscribe<boolean>(
    (s) => !s.errors,
    (isValid) => useStore.setState({ isValid }),
  );

  useStore.subscribe<boolean>(
    (s) => !isDeepEqual(s.values, _initial),
    (isDirty) => useStore.setState({ isDirty }),
  );

  createCalculate(useStore, getCalculations);

  return { useStore };
};
