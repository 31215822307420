import type { loadAsync } from "jszip";

export const fileToBase64 = (file: File): Promise<string | undefined> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target?.result as string | undefined);
    reader.readAsDataURL(file);
  });

export const dataUrlToFile = async (
  dataUrl: string,
  fileName: string,
  type: BlobPropertyBag["type"] = "image/png",
): Promise<File> =>
  fetch(dataUrl)
    .then((r) => r.blob())
    .then((b) => new File([b], fileName, { type }));

export const unZipFile = async (
  file: Parameters<typeof loadAsync>[0],
  loadLibraryError?: string,
  unzipError?: string,
) =>
  import("jszip")
    .then((v) => v.default)
    .catch((e) => {
      throw new Error(loadLibraryError || e);
    })
    .then((jszip) => jszip.loadAsync(file))
    .catch((e) => {
      throw new Error(unzipError || e);
    });

export const jsonToCsv = <T>(
  arr: T[],
  columns: (keyof NonNullable<T> | ((data: T) => string | number | null | undefined))[],
  textColumns: string[],
  delimiter = ";",
) =>
  [
    `sep=${delimiter}`,
    textColumns.join(delimiter),
    ...arr.map((obj) =>
      columns.reduce(
        (acc, key) =>
          `${acc}${acc.length ? delimiter : ""}"${typeof key === "function" ? key(obj) ?? "" : obj?.[key] ?? ""}"`,
        "",
      ),
    ),
  ].join("\n");

// extend on new file types used in application
type FileTypes = "text/csv";

export const downloadFile = (fileName: string, fileType: FileTypes, data: false | string | BlobPart[]) => {
  if (data === false) return;
  const blob = new Blob(typeof data === "string" ? [data] : data, { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  a.click();
};

export const downloadFileInUTF8WithBom = (fileName: string, fileType: FileTypes, data: false | string | BlobPart[]) => {
  if (data === false) return;
  const bom = "\uFEFF";
  let blobData = "";
  if (typeof data === "string") {
    blobData = bom + data;
  } else if (Array.isArray(data)) {
    const uint8Array = Uint8Array.from(data as any as number[]);
    blobData = bom + new TextDecoder("utf-8").decode(uint8Array);
  }
  downloadFile(fileName, fileType, blobData);
};

export const hasExtension = (fileName: string, extensions: string[]) => {
  const withUpperCaseToo = Object.assign([], extensions.concat(extensions.map((ext) => ext.toUpperCase())));

  return withUpperCaseToo.includes(getExtension(fileName));
};

const getExtension = (fileName: string) => {
  const parts: string[] = fileName.split(".");

  return parts[parts.length - 1];
};
