import { useMemo, useRef, useState } from "react";

import {
  SelectLiveFieldUseConfig,
  SelectLiveFieldValueArg,
  TransferBoxLiveFieldUseConfig,
  TransferBoxLiveFieldValueArg,
  getSelectLiveField,
  getTransferBoxLiveField,
} from "base/fields";
import { useFieldData } from "common/form";
import { CustomRenderFieldTypes } from "common/form/renderFields";
import { makeSorter } from "common/helpers";
import { useDebouncedValue, useMappedQuery } from "common/hooks";
import {
  FindAllMachineUsersFilteredQuery,
  MachineUserDtoOut,
  useFindAllMachineUsersFilteredQuery,
  useFindMachineUserPersonalInfoQuery,
} from "generated";

type MachineUserOption = {
  label: string;
  value: string;
};

export type MachineUserData = NonNullable<
  NonNullable<FindAllMachineUsersFilteredQuery["findAllMachineUsersFiltered"]>["result"]
>[0] & {
  __typename: "MachineUserDtoOut";
};

type ConfigOptions = {
  getValue?: (v: MachineUserData) => MachineUserOption["value"];
  configWithOptions?: (options: MachineUserOption[]) => Partial<CustomRenderFieldTypes["select"]>;
};

const LIST_SIZE = 20;

const getUseConfig: (options: ConfigOptions) => SelectLiveFieldUseConfig = ({ getValue, configWithOptions }) =>
  function useConfig(prev) {
    const value = useFieldData<string | undefined>(prev.name, "values");
    const [filterText, setFilterText] = useState("");
    const debouncedFilterText = useDebouncedValue(filterText);

    const [pickedOptions = [], { loading: firstLoading }] = useMappedQuery(
      (data, previousData) => {
        const result = data ?? previousData;
        const { name, userId } = result?.findMachineUserByUserId ?? ({} as MachineUserDtoOut);
        return [{ label: name || userId, value: userId }] as MachineUserOption[];
      },
      useFindMachineUserPersonalInfoQuery({
        skip: !value?.length,
        variables: {
          userId: value || "",
        },
      }),
    );

    const listSizeRef = useRef(LIST_SIZE);
    const [data = prev, { loading, refetch }] = useMappedQuery(
      (data, previousData) => {
        const result = data ?? previousData;

        const fullSize = result?.findAllMachineUsersFiltered?.fullSize ?? 0;

        const options: MachineUserOption[] =
          (result?.findAllMachineUsersFiltered?.result as MachineUserResult[])?.flatMap((p) =>
            p ? [{ label: p.name ?? getValue?.(p) ?? "", value: getValue?.(p) || p.nodeId || "" }] : [],
          ) || [];

        // eslint-disable-next-line
        // @ts-ignore
        return {
          ...prev,
          isLoading: false,
          options,
          ...configWithOptions?.(options),
          onMenuScrollToBottom: () => {
            if (options.length < fullSize) {
              listSizeRef.current += LIST_SIZE;
              refetch({
                searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
              });
            }
          },
        };
      },
      useFindAllMachineUsersFilteredQuery({
        variables: {
          machineUserFilters: debouncedFilterText ? { name: debouncedFilterText } : undefined,
          searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
        },
      }),
    );

    const options = useMemo(
      () =>
        [
          ...pickedOptions,
          ...(data.options as MachineUserOption[]).filter((o) => !pickedOptions.find((p) => p.value === o.value)),
        ].sort(makeSorter("label")),
      [data, pickedOptions],
    );

    return [{ ...data, onInputChange: setFilterText, isLoading: firstLoading || loading, options }, true];
  };

export const getSelectMachineUserField = ({
  getValue,
  configWithOptions,
  ...value
}: SelectLiveFieldValueArg & ConfigOptions) =>
  getSelectLiveField(
    {
      label: "mu.title",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    getUseConfig({ getValue, configWithOptions }),
  );

type MachineUserResult = NonNullable<
  NonNullable<FindAllMachineUsersFilteredQuery["findAllMachineUsersFiltered"]>["result"]
>[0] & {
  __typename: "MachineUserDtoOut";
};

const LIST_SIZE_BOX = 20;

export const useConfigGetMachineUsersTransferBox: TransferBoxLiveFieldUseConfig = (prev) => {
  const listSizeRef = useRef(LIST_SIZE_BOX);
  const [filterText, setFilterText] = useState("");

  const value = useFieldData<string[] | undefined>(prev.name, "values");

  const [pickedOptions = []] = useMappedQuery(
    (data, previousData) => {
      const result = data ?? previousData;

      return ((result?.findAllMachineUsersFiltered?.result as MachineUserResult[])?.flatMap((p) =>
        p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : [],
      ) || []) as MachineUserOption[];
    },
    useFindAllMachineUsersFilteredQuery({
      skip: !value?.length,
      variables: {
        machineUserFilters: {
          nodeIds: value,
        },
        searchRequest: {
          page: 0,
          size: value?.length ?? 0,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
    }),
  );

  const [data = prev, { loading, refetch }] = useMappedQuery(
    (data, previousData) => {
      const result = data ?? previousData;

      const fullSize = result?.findAllMachineUsersFiltered?.fullSize ?? 0;

      const options: MachineUserOption[] =
        (result?.findAllMachineUsersFiltered?.result as MachineUserResult[])?.flatMap((p) =>
          p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : [],
        ) || [];

      return {
        ...prev,
        options,
        fullSize,
        onScrollToBottom: () => {
          if (options.length < fullSize) {
            listSizeRef.current += LIST_SIZE_BOX;
            refetch({
              searchRequest: {
                page: 0,
                size: listSizeRef.current,
                sort: [{ fieldName: "name", order: "ASC" }],
              },
            });
          }
        },
      };
    },
    useFindAllMachineUsersFilteredQuery({
      variables: {
        machineUserFilters: {
          name: filterText || undefined,
        },
        searchRequest: {
          page: 0,
          size: listSizeRef.current,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
      notifyOnNetworkStatusChange: true,
    }),
    true,
  );

  const options = useMemo(
    () =>
      [...pickedOptions, ...data.options.filter((o) => !pickedOptions.find((p) => p.value === o.value))].sort(
        makeSorter("label"),
      ),
    [data, pickedOptions],
  );

  return [
    {
      ...data,
      options,
      onSearch: setFilterText,
      isLoading: loading,
      fullSize: (data.fullSize ?? 0) - (value?.length ?? 0),
    },
    true,
  ];
};

export const getMachineUserTransferBoxField = (value: TransferBoxLiveFieldValueArg) =>
  getTransferBoxLiveField(
    {
      label: "mu.title",
      isLoading: true,
      ...value,
    },
    useConfigGetMachineUsersTransferBox,
  );
