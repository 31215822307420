export const isEmpty = (element: any | any[]) => {
  if (!element) return true;

  if (Array.isArray(element) && !element.join("")) return true;

  for (const key in element) {
    // eslint-disable-next-line
    if (element.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};
