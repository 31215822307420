import { TKeys } from "i18next";
import { forwardRef } from "react";
import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "common/guideline/components/helpers";
import { screen } from "common/guideline/theme";
import { useTranslation } from "i18n";

import { RawFieldData, RawFieldProps, fieldFactory } from "../schema";

import { FieldBoxVariantsProp, TextLabel, fieldBoxStyle, fieldBoxVariants, fieldWrapperStyle } from "./Common";

const StyledInput = styled.input<{ error?: any; rows?: number } & FieldBoxVariantsProp>`
  ${tw`overflow-ellipsis placeholder:text-gray-5 disabled:(cursor-not-allowed opacity-50)`}
  ${fieldBoxStyle}
  ${fieldBoxVariants}
  ${({ rows }) => rows && tw`h-auto`}
`;

const labelVariants = {
  row: css`
    ${tw`flex flex-row items-center gap-x-2`}

    ${TextLabel} {
      ${tw`p-0`}
    }

    ${StyledInput} {
      ${tw`flex-1`}
    }
  `,
  // this variant is a function because it depends on the object it is declared inside
  responsive: () => css`
    ${screen.min("sm")} {
      ${labelVariants.row}
    }
  `,
};

const StyledLabel = styled.label<WithVariants<typeof labelVariants>>`
  ${fieldWrapperStyle}
  ${applyVariant(labelVariants)}
`;

export type InputProps = RawFieldProps<
  FieldBoxVariantsProp &
    WithVariants<typeof labelVariants, "labelVariant"> & {
      type: "text" | "number" | "textarea" | "email" | "hidden" | "password";
      disabled?: boolean;
      label?: string;
      id?: string;
      min?: number | string;
      max?: number | string;
      value: string | number;
      className?: string;
      placeholder?: TKeys;
      placeholderRaw?: string;
      autoComplete?: string;
      onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
      rows?: number;
      inputMode?: "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url";
    }
>;

export const InputRaw = forwardRef<HTMLInputElement, RawFieldData<InputProps>>(
  (
    {
      label,
      onChange,
      onBlur,
      onFocus,
      type,
      error,
      name,
      disabled,
      variant,
      min,
      max,
      value,
      className,
      placeholder,
      placeholderRaw,
      autoComplete,
      onKeyDown,
      labelVariant,
      rows,
      inputMode,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <StyledLabel className={className} variant={labelVariant} data-test={name}>
        {label && <TextLabel error={error}>{label}</TextLabel>}
        <StyledInput
          as={type === "textarea" ? "textarea" : "input"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          name={name}
          disabled={disabled}
          error={error}
          min={min}
          max={max}
          placeholder={placeholder ? (t(placeholder) as string) : placeholderRaw}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
          variant={variant}
          rows={rows}
          inputMode={inputMode}
          ref={ref}
        />
        {error && <TextLabel error={error}>{error}</TextLabel>}
      </StyledLabel>
    );
  },
);

InputRaw.displayName = "Input";

export const InputField = fieldFactory(InputRaw);
