import { Navigate, history, useParams } from "appRouting";
import { useAuth } from "auth/hooks";
import { OTACodeComponent } from "ota/components/OTACodeComponent";
import { TenantSelect } from "tenant/components";

export type OTACodeRouteParams = {
  machineUuid: string;
};

export const OTACode = () => {
  const user = useAuth(useAuth.actions.getUser);
  const { machineUuid } = useParams<OTACodeRouteParams>();

  return !user ? (
    <Navigate route="LOGIN" state={{ previousPath: history.location.pathname }} />
  ) : !machineUuid ? (
    <Navigate route="HOME" replace />
  ) : (
    <OTACodeComponent machineUuid={machineUuid}>
      <TenantSelect withLabel />
    </OTACodeComponent>
  );
};
