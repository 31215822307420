import { Link, Navigate } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";
import { useFlagsWithLoading } from "base/featureFlags";
import { Breadcrumb, Button, Text } from "common/guideline";

import { OTATable } from "./OTATable";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "ota.list",
    route: "OTA_LIST",
  },
];

export const OTAList = () => {
  const canOTA = usePermissionAccess("canOTA");
  const { flags, isLoading } = useFlagsWithLoading();

  if (isLoading) return null;

  return canOTA && flags.otaPages ? (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="ota.list"
      subtitle="ota.listPageDesc"
      actions={
        <Link route="OTA_CREATE">
          <Button>
            <Text tKey="ota.create" />
          </Button>
        </Link>
      }
    >
      <OTATable />
    </PageLayout>
  ) : (
    <Navigate route="HOME" replace />
  );
};
