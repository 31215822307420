import tw from "twin.macro";
import "styled-components/macro";

import { Link, useLocation } from "appRouting";
import { Breadcrumb, Breadcrumbs, Container } from "common/guideline";

const results = [
  {
    id: 0,
    subject: "For those, who are looking for this option",
    phrase: "Access to local assets",
    query: "Lo",
    exclude: "cal assets",
    time: "1d ago",
    datetime: "2021-08-23T16:35",
    preview: "Description goes here...",
  },
  {
    id: 1,
    subject: "Tools for anti-fraud detectors",
    phrase: 'Access to menu "Loss prevention"',
    query: "Lo",
    exclude: "ss prevention",
    time: "5d ago",
    datetime: "2021-08-18T16:35",
    preview:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec tempor ipsum. Cras tellus dolor, volutpat nec condimentum vitae, mollis quis libero...",
  },
];

const SearchQuery = () => (
  <ul tw="divide-y divide-gray-2">
    {results.map((result) => (
      <li
        key={result.id}
        tw="relative bg-gray-1 p-4 hover:bg-gray-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-default"
      >
        <div tw="flex justify-between space-x-3">
          <div tw="min-w-0 flex-1">
            <Link route="HOME" tw="block focus:outline-none">
              <span tw="absolute inset-0" aria-hidden="true" />
              <p tw="text-base text-gray-10 truncate mb-1">{result.phrase}</p>
              <mark tw="bg-primary-alt text-gray-1 p-0.5">{result.query}</mark>
              <span>{result.exclude}</span>
              <p tw="text-sm truncate mt-1 text-primary-default">{result.subject}</p>
            </Link>
          </div>
          <time dateTime={result.datetime} tw="flex-shrink-0 whitespace-nowrap text-sm text-gray-7">
            {result.time}
          </time>
        </div>
        <div tw="mt-1">
          <p tw="line-clamp-2 text-sm text-gray-7">{result.preview}</p>
        </div>
      </li>
    ))}
  </ul>
);

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.searchResults",
    route: "SEARCH",
  },
];

export const SearchResults = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search).get("q");

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container variant="pageWrapper">
        <h2 tw="text-gray-8 font-semibold mb-4 text-xl">
          Results for: <mark tw="bg-primary-default font-semibold p-1 text-gray-1 text-xl">{query}</mark>
        </h2>
        <SearchQuery />
      </Container>
    </>
  );
};
