import { useMemo } from "react";

import { useFindAllLocationsFilteredQuery, useFindAllMachinesQuery } from "generated";

type Props = {
  ids: string | string[];
  entity: "machines" | "locations";
  children?: (names: string[], loading: boolean) => JSX.Element;
};

export const NamesByIds = ({ entity, ids, children }: Props) => {
  const idsArray = Array.isArray(ids) ? ids : [ids];

  const machinesResult = useFindAllMachinesQuery({
    skip: entity !== "machines",
    variables: {
      machineFilters: { nodeIds: idsArray },
      searchRequest: { page: 0, size: idsArray.length, sort: [{ fieldName: "name", order: "ASC" }] },
    },
  });

  const locationsResult = useFindAllLocationsFilteredQuery({
    skip: entity !== "locations",
    variables: {
      locationFilters: { nodeIds: idsArray },
      searchRequest: { page: 0, size: idsArray.length, sort: [{ fieldName: "name", order: "ASC" }] },
    },
  });

  const loading = machinesResult.loading || locationsResult.loading;
  const result = useMemo(() => {
    if (entity === "machines") {
      return machinesResult.data?.findAllMachines?.result?.flatMap((m) => (m ? (m as any).name : [])) || [];
    }

    if (entity === "locations") {
      return locationsResult.data?.findAllLocationsFiltered?.result?.flatMap((l) => (l ? (l as any).name : [])) || [];
    }

    return [];
  }, [entity, machinesResult.data, locationsResult.data]);

  return children ? children(result, loading) : <>{result.join(", ")}</>;
};
