import { RouteConfig, RouteValue } from "routing";

import {
  MachineCreate,
  MachineEdit,
  MachineEditLicenseContent,
  MachineLicenseUpdateParams,
  MachineList,
  MachineUpdateParams,
} from "./pages";

export type MachinePaths = {
  MACHINE_LIST: RouteValue;
  MACHINE_CREATE: RouteValue;
  MACHINE_EDIT: RouteValue<{ params: MachineUpdateParams }>;
  MACHINE_LICENSE_CONTENT_EDIT: RouteValue<{ params: MachineLicenseUpdateParams }>;
};

export const MACHINE: RouteConfig<MachinePaths> = {
  MACHINE_LIST: {
    path: "/machine-list",
    Component: MachineList,
  },
  MACHINE_CREATE: {
    path: "/machine-create",
    Component: MachineCreate,
  },
  MACHINE_EDIT: {
    path: "/machine-edit/:nodeId",
    Component: MachineEdit,
  },
  MACHINE_LICENSE_CONTENT_EDIT: {
    path: "/machine-license-content-edit/:nodeId",
    Component: MachineEditLicenseContent,
  },
};
