import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, TypePolicy } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { useAuth } from "auth/hooks";
import { CONFIG } from "common/config";
import { getStorageTenantId } from "tenant/context/getStorageTenantId";

const errorLink = onError(({ operation }) => {
  const { response } = operation.getContext();
  // handle invalid token or other auth errors
  if (response?.status === 401) {
    useAuth.actions.logOut();
  }
});

const operationsWithoutTenantHeader = [
  "findAllTenantDbsNames",
  "findAllTenants",
  "findTenantByTenantId",
  "findAllMachineTypePatterns",
  "findMyTenantsMetadata",
  "findTenant",
  "activateTenant",
  "deactivateTenant",
  "findTenantFeatures",
  "updateTenantFeatures",
  "createTenantWithExistingUser",
  "createTenantWithNewUser",
  "configureTenant",
  "updatePasswordForTenant",
  "synchronizeMqttCredentials",
  "setTenantFeatures",
];

const authLink = setContext(({ operationName = "" }, { headers }) => {
  const token = useAuth.actions.getToken();

  return {
    headers: {
      [CONFIG.tenantHeaderName]: operationsWithoutTenantHeader.includes(operationName)
        ? ""
        : getStorageTenantId() ?? "",
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = new HttpLink({ uri: CONFIG.gqlPath });
const onlineStatusLink = new HttpLink({ uri: CONFIG.gqlOnlineStatusPath });
const alertsLink = new HttpLink({ uri: CONFIG.gqlAlertsPath });
const scheduledReportsLink = new HttpLink({ uri: CONFIG.gqlScheduledReportsPath });

const onlineStatusOperations = [
  "getMachinesOnlineStatus",
  "getMachinesOnlineStatusHistory",
  "getLatestOnlineStatusForMachine",
];

const alertsOperations = [
  "getSupportedFilters",
  "getAllRules",
  "getRulesByNodeId",
  "findAlertsByCriteria",
  "createRules",
  "deleteRules",
  "updateRules",
];

const scheduledOperations = [
  "getActiveScheduledReports",
  "getAllExecutions",
  "getAllSchedulableReportNameToType",
  "getAllScheduledReports",
  "getUpcomingExecutionDates",
  "activateReportRequest",
  "createScheduledReport",
  "deactivateReportRequest",
  "deleteReportRequest",
  "updateScheduledReport",
];

const defaultTypePolicy: TypePolicy = {
  keyFields: ["nodeId"],
};

export const client = new ApolloClient({
  // use onlineStatusLink if query is for online status service
  link: errorLink.concat(
    authLink.split(
      (o) => onlineStatusOperations.includes(o.operationName),
      onlineStatusLink,
      ApolloLink.split(
        (o) => alertsOperations.includes(o.operationName),
        alertsLink,
        ApolloLink.split((o) => scheduledOperations.includes(o.operationName), scheduledReportsLink, link),
      ),
    ),
  ),
  cache: new InMemoryCache({
    typePolicies: {
      CoDLicenseDtoOut: defaultTypePolicy,
      CorrectionReasonDtoOut: defaultTypePolicy,
      DeploymentDtoOut: defaultTypePolicy,
      ExtendedDeploymentDtoOut: defaultTypePolicy,
      ExtendedSoftwareInstallationDtoOut: defaultTypePolicy,
      SoftwareInstallationDtoOut: defaultTypePolicy,
      LocationDtoOut: defaultTypePolicy,
      MachineDtoOut: defaultTypePolicy,
      MachineGroupDtoOut: defaultTypePolicy,
      MachineUserDtoOut: defaultTypePolicy,
      MachineUserGroupDtoOut: defaultTypePolicy,
      RecipientData: defaultTypePolicy,
      RmDeviceDto: defaultTypePolicy,
      Rule: defaultTypePolicy,
      SmartLocationGroupDtoOut: defaultTypePolicy,
      SoftwarePackageDtoOut: defaultTypePolicy,
      TemplateDtoOut: defaultTypePolicy,
      LaneAllocationDto: defaultTypePolicy,
      ScheduledReportDtoOut: defaultTypePolicy,
      MetadataDtoOut: defaultTypePolicy,
      UserBalanceDtoOut: defaultTypePolicy,
      LicenseContentDtoOut: defaultTypePolicy,
      LicenseFeatureDtoOut: defaultTypePolicy,
      LicenseExpirationDtoOut: defaultTypePolicy,
      OtaAuthorizationDto: defaultTypePolicy,
      TenantDtoOut: { keyFields: ["tenantId"] },
    },
    possibleTypes: {
      AbstractFilter: [
        "BiNodeFilter",
        "CashAmountFilter",
        "CountOperationFilter",
        "DefaultFilter",
        "DurationFilter",
        "MessageFieldFiltering",
        "MessageTypeFilter",
        "NoParamFilter",
        "RatioFilter",
        "TimeFilter",
        "WebhookFilter",
      ],
    },
  }),
});
