import { TKeys } from "i18next";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FormData, OnSubmit, SchemaForm, SchemaFormWizard, WizardWrapper } from "common/form";
import { CustomRenderFields, customRender, isValidPhoneNumber } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";
import { alphaNumericUnderscoreDashDotRegExp } from "common/helpers";

import { getDomainNamesField, getUserPermissionsField, getUserRolesField } from "../fields";
import { UserCreateFormData } from "../types";

type Props = {
  onSubmit: OnSubmit<UserCreateFormData>;
  submitLabel: TKeys;
  initial?: UserCreateFormData;
  updateForm?: boolean;
  onBack?: () => void;
};

const SpacingWrapper = styled.div`
  ${tw`pt-3`}
`;

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 py-3 sm:grid-cols-2`}
`;

const getFields: (updateForm?: boolean) => CustomRenderFields[] = (updateForm) => [
  {
    type: "container",
    Component: SpacingWrapper,
    fields: [
      {
        type: "container",
        Component: TwoColsWrapper,
        fields: [
          {
            type: "text",
            name: "userName",
            label: "user.userName",
            disabled: !!updateForm,
            validate: {
              type: "string",
              required: true,
              pattern: [alphaNumericUnderscoreDashDotRegExp, "common.form.err.alphaNumericUnderscoreDashDot"],
            },
          },
          {
            type: "container",
          },
          {
            type: "password",
            name: "password",
            label: "user.password",
            validate: { type: "string", required: true },
          },
          {
            type: "password",
            name: "confirmPassword",
            label: "user.confirmPassword",
            validate: {
              type: "string",
              required: true,
              custom: (password, formData) =>
                password === formData.values.password ? null : "common.form.err.passwordsDoNotMatch",
            },
          },
          {
            type: "text",
            name: "firstName",
            label: "user.firstName",
            validate: { type: "string", required: true },
          },
          {
            type: "text",
            name: "lastName",
            label: "user.lastName",
            validate: { type: "string", required: true },
          },
          {
            type: "email",
            name: "email",
            label: "user.email",
            validate: { type: "string", required: true },
          },
          {
            name: "phoneNumber",
            type: "phone",
            label: "user.phoneNumber",
            validate: (v) => (isValidPhoneNumber(v ?? "") ? null : "common.form.err.invalidPhone"),
          },
        ],
      },
    ],
  },
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      getDomainNamesField({ name: "domainName" }),
      getUserRolesField({ name: "userRoleNames" }),
      getUserPermissionsField({ name: "userPermissions" }),
    ],
  },
];

const steps = ["user.identity.title", "user.access.accessLevel"];

export const UserForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm, onBack }) => {
  const [fields] = useState(() => getFields(updateForm));

  return (
    <>
      <Text variant="heading" tKey={updateForm ? "user.update" : "user.createUser"} tw="pb-4 block" />
      <SchemaForm<UserCreateFormData>
        fields={fields}
        onSubmit={onSubmit}
        initial={initial}
        customRender={customRender}
        FormComponent={SchemaFormWizard}
        SubmitComponent={() => (
          <FormData type="isSubmittig">
            {(isLoading) => (
              <div tw="flex justify-between">
                <Button tw="mt-6" variant="side" onClick={onBack} data-test="goBack">
                  <Text tKey="machine.goBack" />
                </Button>
                <LoadingButton
                  type="submit"
                  tw="mt-6"
                  isLoading={isLoading}
                  disabled={isLoading}
                  data-test="submitForm"
                >
                  <Text tKey={submitLabel} />
                </LoadingButton>
              </div>
            )}
          </FormData>
        )}
      >
        {
          ((page) => (
            <WizardWrapper steps={steps} submitLabel={submitLabel}>
              {page}
            </WizardWrapper>
          )) as any
        }
      </SchemaForm>
    </>
  );
};
