import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as UserCircleSVG } from "assets/icons/UserCircle.svg";
import { useAuth } from "auth/hooks";
import { DeployedVersionInfo } from "base/components";
import { CONFIG } from "common/config";
import { Breadcrumb, Breadcrumbs, ChangeThemeButton, Container, Line, Text } from "common/guideline";

import { TimeSettings } from "../../components";
import { getIsCarbonUser, useUserDomain } from "../User/helpers";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.myProfile",
    route: "USER_PROFILE",
  },
];

export const UserProfile = () => {
  const user = useAuth(useAuth.actions.getUser);
  const domain = useUserDomain();
  const isCarbonUser = getIsCarbonUser(domain);

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container variant="borderBottom">
        <div tw="flex items-center gap-2 mt-3">
          <UserCircleSVG width={48} height={48} />
          <Text variant="heading" tKey="common.helloProfile" tValue={{ name: user?.sub }} />
        </div>
      </Container>
      <Container variant="pageWrapper">
        <Text variant="heading" tKey="common.myProfile" />
        <Text variant="paragraph" tKey="common.profileDesc" />
        <Text tw="mt-2" variant="subHeading" withoutI18n>
          <Text tKey="user.settings.changeYour" />{" "}
          <a href={CONFIG.wso.changePasswordLink(isCarbonUser ? "" : domain)} target="_blank" rel="noreferrer">
            <Text variant="link" tKey="auth.password" tw="underline" />
          </a>
        </Text>
        <Line tw="my-3" variant="horizontal" />
        <TimeSettings />
        <Line tw="my-3" variant="horizontal" />
        <Text tKey="user.settings.appearance" variant="heading" />
        <ChangeThemeButton />
        <div tw="flex-1 flex items-end justify-end">
          <DeployedVersionInfo />
        </div>
      </Container>
    </>
  );
};
