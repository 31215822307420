import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as BellSVG } from "assets/icons/Bell.svg";
import { ReactComponent as FileXlsSVG } from "assets/icons/FileXls.svg";
import { ReactComponent as GaugeSVG } from "assets/icons/Gauge.svg";
import { ReactComponent as GearSVG } from "assets/icons/Gear.svg";
import { ReactComponent as HouseSVG } from "assets/icons/House.svg";
import { ReactComponent as TransportSVG } from "assets/icons/Transport.svg";
import { useAuth } from "auth/hooks";
import { PageLayout } from "base/components";
import { Breadcrumb, Text } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
];

export const Home = () => {
  const user = useAuth(useAuth.actions.getUser);

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      pageTitle="common.home"
      title="common.helloProfile"
      subtitle="common.actionMessage"
      Icon={HouseSVG}
      tValue={{ name: user?.sub }}
    >
      <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
        <Link route="CASH_OPERATIONS">
          <Box>
            <BoxHeading>
              <Text tKey="operations.title" />
            </BoxHeading>
            <BoxContent variant="iconWrapper">
              <GaugeSVG width={100} height={100} />
            </BoxContent>
          </Box>
        </Link>
        <Link route="REPORTS">
          <Box>
            <BoxHeading>
              <Text tKey="report.title" />
            </BoxHeading>
            <BoxContent variant="iconWrapper">
              <FileXlsSVG width={100} height={100} />
            </BoxContent>
          </Box>
        </Link>
        <Link route="ALERTS">
          <Box>
            <BoxHeading>
              <Text tKey="alerts.title" />
            </BoxHeading>
            <BoxContent variant="iconWrapper">
              <BellSVG width={100} height={100} />
            </BoxContent>
          </Box>
        </Link>
        <Link route="ADMINISTRATION">
          <Box>
            <BoxHeading>
              <Text tKey="administration.title" />
            </BoxHeading>
            <BoxContent variant="iconWrapper">
              <GearSVG width={100} height={100} />
            </BoxContent>
          </Box>
        </Link>
        <Link route="TRANSPORT">
          <Box>
            <BoxHeading>
              <Text tKey="transport.title" />
            </BoxHeading>
            <BoxContent variant="iconWrapper">
              <TransportSVG width={100} height={100} />
            </BoxContent>
          </Box>
        </Link>
      </div>
    </PageLayout>
  );
};
