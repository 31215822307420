import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CorrectionReasonsSVG } from "assets/icons/CorrectionReasons.svg";
import { PageLayout } from "base/components";
import { Button, CommonCells, Table, TableTypes, Text, accessors, localFilters } from "common/guideline";
import { withDefault } from "common/helpers";
import { useFindAllCorrectionReasonsQuery } from "generated";

import { useCreateCorrectionReasonModal } from "../../components";
import { CorrectionReasonFetched } from "../../types";

import { breadcrumbs } from "./breadcrumbs";
import { RowActions } from "./RowActions";

const getColumns: TableTypes.TranslatedColumns<CorrectionReasonFetched> = (t) => [
  {
    header: t("transport.corReasons.id"),
    accessorKey: "nodeId",
    ...localFilters.getSelectBaseFilter(true),
  },
  {
    header: t("transport.corReasons.name"),
    accessorKey: "name",
    ...localFilters.getSelectBaseFilter(true),
  },
  {
    header: t("transport.corReasons.active"),
    accessorKey: "active",
    id: "active",
    sortingFn: "boolean",
    ...localFilters.selectBooleanFilter,
    cell: CommonCells.BooleanCell,
  },
  {
    header: t("transport.corReasons.lastEdited"),
    id: "lastEditedDate",
    accessorFn: (v) => accessors.date(v.lastEditedDate, t),
    sortingFn: "dateString",
    ...localFilters.getDateRangeFilter,
  },
  {
    header: t("transport.corReasons.lastEditedBy"),
    accessorFn: (v) => withDefault(v.lastEditedBy),
    ...localFilters.getSelectBaseFilter(true),
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const CreateModalForm = () => {
  const [show] = useCreateCorrectionReasonModal();

  return (
    <Button onClick={() => show()} data-test="showCreateModal">
      <Text tKey="transport.corReasons.create" />
    </Button>
  );
};

const CorrectionReasonsTable = () => {
  const { previousData, data = previousData, loading, error } = useFindAllCorrectionReasonsQuery();
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.findAllCorrectionReasons || []) as CorrectionReasonFetched[];

  return (
    <Table<CorrectionReasonFetched>
      tableName="correctionReasons"
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};

export const CorrectionReasons = () => (
  <PageLayout
    title="transport.corReasons.title_other"
    subtitle="transport.corReasons.desc"
    breadcrumbs={breadcrumbs}
    Icon={CorrectionReasonsSVG}
    actions={<CreateModalForm />}
  >
    <CorrectionReasonsTable />
  </PageLayout>
);
