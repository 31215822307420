import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

import "styled-components/macro";

import { ReactComponent as OnlineStatusSVG } from "assets/icons/OnlineStatus.svg";
import { PageLayout, SelectWrapper, useSiteGroupContext, useTimeZoneFilter } from "base/components";
import { client } from "client";
import {
  Breadcrumb,
  CommonCells,
  CustomGetCsvFn,
  DateRangeInfo,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  tableDownloadTitles,
  useDateRangeFilter,
  useDateRangeZoned,
  usePagination,
} from "common/guideline";
import {
  GetMachinesOnlineStatusHistoryDocument,
  GetMachinesOnlineStatusHistoryQuery,
  GetMachinesOnlineStatusHistoryQueryVariables,
  MachineOnlineWithHistoryStatusDto,
  useGetMachinesOnlineStatusHistoryQuery,
} from "generated";
import { useSingleMachineByNodeId } from "machine/components/getMachinesFilteredField";
import { FilterBox } from "report/components";
import { useTenant } from "tenant/context";

import { StatusBadge } from "./components";
import { OnlineStatusHistory } from "./OnlineStatusHistory";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "machineManagement",
    route: "MACHINE_MANAGEMENT",
  },
  {
    text: "mos.title",
    route: "ONLINE_STATUS",
  },
];

const getColumns: TableTypes.TranslatedColumns<MachineOnlineWithHistoryStatusDto> = (t) => [
  CommonCells.expander,
  {
    header: t("machine.uuid"),
    id: "machineUuid",
    accessorKey: "machineUuid",
  },
  {
    header: t("mos.machine"),
    id: "machineName",
    accessorKey: "machineName",
  },
  {
    header: t("mos.site"),
    id: "locationName",
    accessorKey: "locationName",
  },
  {
    header: t("mos.onlineStatus"),
    id: "onlineStatus",
    accessorKey: "onlineStatus",
    cell: StatusBadge,
  },
  {
    header: t("mos.timeOfStatusChange"),
    id: "receivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.receivedDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.receivedDate, t),
      },
    },
  },
  {
    header: t("mos.formattedReceivedDate"),
    id: "formattedReceivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.formattedReceivedDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedReceivedDate, t),
      },
    },
  },
  {
    header: t("mos.formattedEventDate"),
    id: "formattedEventDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.formattedEventDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedEventDate, t),
      },
    },
  },
];

const TABLE_NAME = "onlineStatus";

export const OnlineStatus = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [date, DateRange] = useDateRangeFilter("hour", {
    label: "report.period",
    availableOptions: [
      "hour",
      "currentDay",
      "currentWeek",
      "currentMonth",
      "lastDay",
      "lastWeek",
      "lastMonth",
      "custom",
    ],
    useTenantEndOfDay: true,
  });
  const [zoneId, TimeZone] = useTimeZoneFilter();
  const zonedDateRange = useDateRangeZoned(date, zoneId);
  const { machine, location } = useSiteGroupContext();
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const singleMachine = useSingleMachineByNodeId(machine);
  const variables: GetMachinesOnlineStatusHistoryQueryVariables = {
    input: {
      fromDateTime: zonedDateRange.from,
      toDateTime: zonedDateRange.to,
      zoneId,
      tenantId,
      machineUuid: singleMachine?.uuid,
      locationId: location,
      limit: pageSize,
      skip: pageIndex * pageSize,
    },
  };
  const { previousData, data = previousData, error, loading } = useGetMachinesOnlineStatusHistoryQuery({ variables });
  const rows = (data?.getMachinesOnlineStatusHistory?.rows || []) as MachineOnlineWithHistoryStatusDto[];
  const fullSize = data?.getMachinesOnlineStatusHistory?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) return getOptions({ data: rows });

    const { data: { getMachinesOnlineStatusHistory } = {} } = await client.query<
      GetMachinesOnlineStatusHistoryQuery,
      GetMachinesOnlineStatusHistoryQueryVariables
    >({
      query: GetMachinesOnlineStatusHistoryDocument,
      variables: {
        input: {
          ...variables.input,
          limit: fullSize || 0,
          skip: 0,
        },
      },
    });

    return getOptions({ data: (getMachinesOnlineStatusHistory?.rows || []) as MachineOnlineWithHistoryStatusDto[] });
  };

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="mos.title" subtitle="mos.desc" Icon={OnlineStatusSVG} withPicker>
      <FilterBox>
        <SelectWrapper>{DateRange}</SelectWrapper>
        {TimeZone}
      </FilterBox>
      <DateRangeInfo from={zonedDateRange.from} to={zonedDateRange.to} timeZone={zoneId} />
      <Table
        tableName="onlineStatus"
        columns={columns}
        data={rows}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        onPagination={setPagination}
        pageSize={pageSize}
        pageIndex={pageIndex}
        totalCount={fullSize}
        SubRows={OnlineStatusHistory}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDateRange(zonedDateRange, "mos.title", t),
                page,
              )
            }
            disabled={!fullSize}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
