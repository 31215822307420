export const fr = {
  tenant: {
    tenant_one: "Tenant",
    tenant_other: "Tenants",
    goBack: "Retour",
    activeFetchErr: "Impossible de récupérer les données du tenant",
    create: {
      title: "Créer un tenant",
      description: "Saisissez les détails du tenant pour créer un nouveau tenant",
      name: "Nom du tenant",
      contractNumber: "Numéro de contrat",
      billingId: "Id facturation",
      tenantId: "Id tenant",
      userName: "Nom de l’utilisateur",
      mqttPassword: "Mot de passe MQTT",
      submit: "Créer",
      apiError: "Impossible de créer le tenant, veuillez réessayer",
      success: "Tenant créé avec succès",
      createDomain: "Créer un nouveau domaine",
      createUser: "Créer un nouvel utilisateur",
      newUser: "Nouvel utilisateur",
      commonSoftwarePackageUploadEnabled: "Téléchargement de logiciel activé",
      endOfDay: "Fin de journée",
    },
    update: {
      submit: "Mettre à jour",
      title: "Mettre à jour un tenant",
      description: "Veuillez modifier les coordonnées du tenant pour les mettre à jour",
      apiError: "Impossible de mettre à jour le tenant, veuillez réessayer",
      success: "Tenant mis à jour avec succès",
    },
    activate: {
      title: "Activer le tenant",
      description: "Le tenant <0>{{name}}</0> sera activé",
      close: "Annuler",
      submit: "Activer",
      error: "Impossible d’activer le tenant, veuillez réessayer",
      success: "Tenant activé avec succès",
    },
    deactivate: {
      title: "Désactiver le tenant",
      description: "Le tenant <0>{{name}}</0> sera désactivé",
      close: "Annuler",
      submit: "Désactiver",
      error: "Impossible de désactiver le tenant, veuillez réessayer",
      success: "Tenant désactivé avec succès",
    },
    features: {
      titleUpdate: "Mettre à jour les caractéristiques de {{name}} ",
      userBalance: "Solde balance",
      commonSoftwarePackageUploadEnabled: "Chargement de logiciel activé",
      success: "Caractéristiques mise updated",
      mumEnabled: "Gestion des utilisateurs machine activée",
      mumXmlImportEnabled: "Import des utilisateurs machines activée",
      apiError: "Les caractéristiques du tenant n’ont pas pu être mises à jour, veuillez réessayer",
      submit: "Sauvegarder",
    },
    list: {
      tenantId: "Id tenant",
      name: "Nom",
      title: "Tenants",
      desc: "Visualiser et gérer les tenants",
      create: "Créer un tenant",
      status: "Statut",
      changed: "Modifié",
    },
  },
};
