import tw from "twin.macro";
import "styled-components/macro";

import { PieChart } from "components/Charts";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

const data = [
  {
    x: "Balanced",
    y: 22,
  },
  {
    x: "Short",
    y: 80,
  },
  {
    x: "Over",
    y: 22,
  },
];

export const Balancing = () => (
  <Box>
    <div tw="flex flex-col">
      <BoxHeading>Balancing</BoxHeading>
      <div tw="h-52">
        <PieChart data={data} />
      </div>
    </div>
  </Box>
);
