import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PageLayout, useSiteGroupContext } from "base/components";
import { client } from "client";
import {
  Breadcrumb,
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  csvLimitExceeded,
  localFilters,
  tableDownloadTitles,
  usePagination,
} from "common/guideline";
import { useDebouncedValue } from "common/hooks";
import {
  GetMachinesOnlineStatusDocument,
  GetMachinesOnlineStatusQuery,
  GetMachinesOnlineStatusQueryVariables,
  useGetMachinesOnlineStatusQuery,
} from "generated";
import { useSingleMachineByNodeId } from "machine/components/getMachinesFilteredField";
import { useColumnFiltersArray } from "report/components";
import { useTenant } from "tenant/context";

import { StatusBadge } from "./components";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "machineManagement",
    route: "MACHINE_MANAGEMENT",
  },
  {
    text: "mosCurrent.title",
    route: "ONLINE_STATUS_CURRENT",
  },
];

type OnlineStatusType = NonNullable<
  NonNullable<NonNullable<GetMachinesOnlineStatusQuery["getMachinesOnlineStatus"]>["rows"]>[0]
>;

const getColumns: TableTypes.TranslatedColumns<OnlineStatusType> = (t) => [
  {
    header: t("machine.uuid"),
    id: "machineUuid",
    accessorKey: "machineUuid",
  },
  {
    header: t("mos.machine"),
    id: "machineName",
    accessorKey: "machineName",
  },
  {
    header: t("mos.site"),
    id: "locationName",
    accessorKey: "locationName",
  },
  {
    header: t("mos.onlineStatus"),
    id: "onlineStatus",
    accessorKey: "onlineStatus",
    cell: StatusBadge,
    meta: {
      ...localFilters.getSelectBaseFilter(false, [
        { value: "CatchingUp", label: "machine.status.CatchingUp" },
        { value: "Offline", label: "machine.status.Offline" },
        { value: "Online", label: "machine.status.Online" },
      ]).meta,
      hideVertical: true,
    },
  },
  {
    header: t("mos.lastEvent"),
    id: "eventDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.eventDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.eventDate, t),
      },
    },
  },
  {
    header: t("mos.formattedEventDate"),
    id: "formattedEventDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.formattedEventDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedEventDate, t),
      },
    },
  },
  {
    header: t("mos.lastReceived"),
    id: "receivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.receivedDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.receivedDate, t),
      },
    },
  },
  {
    header: t("mos.formattedReceivedDate"),
    id: "formattedReceivedDate",
    sortingFn: "dateString",
    accessorFn: (v) => accessors.zonedDateTime(v.formattedReceivedDate, t),
    meta: {
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedReceivedDate, t),
      },
    },
  },
];

const TABLE_NAME = "currentOnlineStatus";
const onlineStatusOrderColumnMapping = {
  onlineStatus: "onlineStatus",
};
const csvLimit = 30_000;

type OnlineStatusOrderColumn = "onlineStatus";

export const OnlineStatusCurrent = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [columnFilters, setColumnFilters] = useColumnFiltersArray<OnlineStatusOrderColumn>();
  const columnFiltersDebounced = useDebouncedValue(columnFilters);
  const { machine, location } = useSiteGroupContext();
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const singleMachine = useSingleMachineByNodeId(machine);
  const variables: GetMachinesOnlineStatusQueryVariables = {
    input: {
      tenantId,
      machineUuid: singleMachine?.uuid,
      locationId: location,
      limit: pageSize,
      skip: pageIndex * pageSize,
      ...Object.fromEntries(columnFiltersDebounced.map(({ id, value }) => [onlineStatusOrderColumnMapping[id], value])),
    },
  };
  const { previousData, data = previousData, error, loading } = useGetMachinesOnlineStatusQuery({ variables });
  const rows = (data?.getMachinesOnlineStatus?.rows || []) as OnlineStatusType[];
  const fullSize = data?.getMachinesOnlineStatus?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) return getOptions({ data: rows });
    if (csvLimitExceeded(fullSize, csvLimit, t)) return false;

    const { data: { getMachinesOnlineStatus } = {} } = await client.query<
      GetMachinesOnlineStatusQuery,
      GetMachinesOnlineStatusQueryVariables
    >({
      query: GetMachinesOnlineStatusDocument,
      variables: {
        input: {
          ...variables.input,
          limit: fullSize || 0,
          skip: 0,
        },
      },
    });

    return getOptions({ data: (getMachinesOnlineStatus?.rows || []) as OnlineStatusType[] });
  };

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="mosCurrent.title" subtitle="mosCurrent.desc" withPicker>
      <Table
        tableName="currentOnlineStatus"
        columns={columns}
        data={rows}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        onPagination={setPagination}
        pageSize={pageSize}
        pageIndex={pageIndex}
        totalCount={fullSize}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDate(new Date().toUTCString(), "mosCurrent.title", t),
                page,
              )
            }
            disabled={!fullSize}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
