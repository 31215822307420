import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "MONTHLY", label: "common.paymentIntervals.monthly" },
  { value: "QUARTERLY", label: "common.paymentIntervals.quarterly" },
  { value: "ANNUAL", label: "common.paymentIntervals.annually" },
];

export const getPaymentIntervalOptions = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "common.paymentIntervals.selectPaymenInterval",
    options,
    ...values,
  });
