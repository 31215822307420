import { RouteConfig, RouteValue } from "routing";

import { CashOperations, Dashboard, RetailOperations, TechnologyOperations } from "./pages";

export type OperationsPaths = {
  DASHBOARD: RouteValue;
  CASH_OPERATIONS: RouteValue;
  RETAIL_OPERATIONS: RouteValue;
  TECHNOLOGY_OPERATIONS: RouteValue;
};

export const OPERATIONS: RouteConfig<OperationsPaths> = {
  CASH_OPERATIONS: {
    path: "/dashboard/cash-operations",
    Component: CashOperations,
  },
  TECHNOLOGY_OPERATIONS: {
    path: "/dashboard/technology-operations",
    Component: TechnologyOperations,
  },
  RETAIL_OPERATIONS: {
    path: "/dashboard/retail-operations",
    Component: RetailOperations,
  },
  DASHBOARD: {
    path: "/dashboard/",
    Component: Dashboard,
  },
};
