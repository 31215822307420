import {
  ArrayField,
  CommonField,
  ConditionField,
  ContainerField,
  LiveField,
  getCustomField,
  getFieldArrayChildrenFn,
} from "./fields";
import { CustomFieldsSchema, CustomRenderInput, FieldSchema, RenderInput } from "./types";

const getKey = (field: FieldSchema | CustomFieldsSchema, index: number) =>
  `${field.type}-${index}-${"name" in field ? field.name : "noname"}`;

const shouldReturnCustom = (rendered, _field): _field is FieldSchema => rendered === undefined;

export const renderInput = (customRender?: CustomRenderInput<any>): RenderInput => {
  const render: RenderInput = ({ field, index, children = [] }) => {
    const key = getKey(field, index);
    const rendered = customRender?.(field, key, children);

    if (!shouldReturnCustom(rendered, field)) return rendered;

    switch (field.type) {
      case "condition":
        return (
          <ConditionField {...field} key={key}>
            {children}
          </ConditionField>
        );
      case "container":
        return (
          <ContainerField {...field} key={key}>
            {children}
          </ContainerField>
        );
      case "custom":
        return getCustomField(field, children, key);
      case "array":
        return (
          <ArrayField {...field} key={key}>
            {getFieldArrayChildrenFn(field, render)}
          </ArrayField>
        );
      case "live":
        return <LiveField {...field} customRender={customRender} key={key} />;
      case "text":
      case "textarea":
      case "number":
      case "checkbox":
      case "radio":
        return <CommonField {...field} key={key} />;
      default: {
        if (process.env.NODE_ENV === "development") {
          console.error(`Not found field type: ${(field as any).type}`);
        }
        return null;
      }
    }
  };

  return render;
};
