import tw from "twin.macro";
import "styled-components/macro";
// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";

import { Link } from "appRouting";
import { ActionButton } from "base/components";
import { Tooltip, useModalComponent } from "common/guideline";

import { DeleteMachineGroup } from "../components";
import { AddBulkMachinesToGroup } from "../components/AddBulkMachinesToGroup";

import { RowMachineGroupDataProps } from "./types";

export const RowActions: React.FC<RowMachineGroupDataProps> = ({ row }) => {
  const { t } = useTranslation();

  const [show] = useModalComponent({
    Component: <DeleteMachineGroup nodeId={row.original.nodeId as string} name={row.original.name as string} />,
  });

  const [showBulkMachineAdd] = useModalComponent({
    Component: <AddBulkMachinesToGroup nodeId={row.original.nodeId as string} name={row.original.name as string} />,
    modalProps: {
      variant: ["modal", "md"],
    },
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={show} />
      <Link route="MACHINE_GROUP_UPDATE" params={{ nodeId: row.original.nodeId as string }}>
        <ActionButton.Edit />
      </Link>
      <Tooltip content={t("machineUserGroup.addMachines")}>
        <ActionButton.AddMultiple onClick={showBulkMachineAdd} />
      </Tooltip>
    </div>
  );
};
