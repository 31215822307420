export const en = {
  operations: {
    title: "Dashboard",
    cashOps: {
      title: "Cash operations",
      desc: "Get an overview of transactions of different types. You can use the filter to select which group, site, or machine you want to see transaction information for.",
    },
    techOps: {
      title: "Technology operations",
      desc: "Use the Technology operations dashboard to monitor machines",
    },
    retailOps: "Retail operations",
    cashMetrics: "Cash metrics",
    recentTransactions: "Recent transactions",
    recentUsers: "Recent users",
    selectMachine: "Select machine from picker above, to see its details",
    selectSiteMachine: "Select site and machine from picker above, to see its details",
    openMap: "Open map",
    closeMap: "Close map",
  },
};
