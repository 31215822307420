import { format, isBefore, parse, startOfDay } from "date-fns";
import { useMemo } from "react";

import { parseDateDashToSlash } from "common/helpers";

import { UseFieldOptions } from "../field";
import { getGetterByPath } from "../getGetterByPath";
import { RawFieldData, fieldFactory } from "../schema";

import { InputProps, InputRaw } from "./Input";

export type DateInputFormatType = "date" | "datetime" | "datetime_seconds" | "time" | "time_seconds";

export type DateInputProps = Omit<InputProps, "type" | "value"> & {
  type: "date";
  value: string | Date;
  dateFormat?: DateInputFormatType;
  fromDate?: Date;
  toDate?: Date;
};

const formatByType: Record<DateInputFormatType, string> = {
  date: "yyyy-MM-dd",
  datetime: "yyyy-MM-dd'T'HH:mm",
  datetime_seconds: "yyyy-MM-dd'T'HH:mm:ss",
  time: "HH:mm",
  time_seconds: "HH:mm:ss",
};

const fromDateDefault = startOfDay(new Date("1990-01-01"));

export const getDateFieldFormatters = (
  dateFormat: DateInputFormatType = "date",
): Required<Pick<UseFieldOptions<Date | undefined>, "parse" | "format">> => ({
  parse: (v) => (v ? format(v, formatByType[dateFormat]) : v),
  format: (v) => (v ? parse(v, formatByType[dateFormat], new Date()) : v),
});

const parseDateOnChange = (value: string | undefined, isTimeInput: boolean, dateFormat: DateInputFormatType) => {
  if (isTimeInput) return value;
  if (!value) return "";
  // needs parsing because date with dashes is treated as local date by the browser and with slashes as date without timezone information
  // we dont want to date jumps between days when we want only date info without the time
  if (dateFormat === "date") return new Date(parseDateDashToSlash(value));

  return new Date(value);
};

export const getValidateDateRangeTo =
  (dateFromName: string, isRequired = false, errorString = "common.form.err.higherFrom") =>
  (value, formData) =>
    !value
      ? !isRequired
        ? null
        : "common.form.err.required"
      : getGetterByPath(dateFromName)(formData.values) &&
        isBefore(new Date(value), new Date(getGetterByPath(dateFromName)(formData.values)))
      ? errorString
      : null;

export const DateInputRaw: React.FC<RawFieldData<DateInputProps>> = ({
  onChange,
  value,
  dateFormat = "date",
  fromDate = fromDateDefault,
  toDate,
  ...rest
}) => {
  const { inputType, isTimeInput, parse } = useMemo(() => {
    const inputType = dateFormat.startsWith("time")
      ? "time"
      : dateFormat.startsWith("datetime")
      ? "datetime-local"
      : "date";

    return {
      inputType,
      isTimeInput: inputType === "time",
      ...getDateFieldFormatters(dateFormat),
    };
  }, [dateFormat]);
  const min = parse(fromDate);
  const max = parse(toDate);

  return (
    <InputRaw
      {...rest}
      type={inputType as any}
      value={value ? (isTimeInput ? value : parse(value)) : ""}
      min={min}
      max={max}
      onChange={(e) => onChange(parseDateOnChange(e.target.value, isTimeInput, dateFormat))}
    />
  );
};

export const DateField = fieldFactory(DateInputRaw);
