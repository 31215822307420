import { Info, Warning } from "@phosphor-icons/react";
import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, LoadingButton, Text } from "common/guideline";

type Props = React.PropsWithChildren<{
  confirmText: TKeys;
  rejectText: TKeys;
  desc: TKeys;
  loading?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  type?: "info" | "warning";
}>;

export const Confirm: React.FC<Props> = ({
  confirmText,
  rejectText,
  desc,
  onConfirm,
  onClose,
  children,
  loading,
  type = "info",
}) => (
  <div>
    <div tw="flex items-center flex-col pb-8">
      {type === "info" ? (
        <Info weight="duotone" size={48} tw="text-primary-default" />
      ) : (
        <Warning weight="duotone" size={48} tw="text-error-default" />
      )}
      <Text tKey={desc} variant="heading" />
      <div tw="pt-4 text-center">{children}</div>
    </div>
    <div tw="flex justify-between">
      <Button onClick={onClose} variant="ghost" data-test="submitReject" disabled={loading}>
        <Text tKey={rejectText} />
      </Button>
      <LoadingButton
        onClick={onConfirm}
        variant={type === "info" ? "primary" : "error"}
        data-test="submitConfirm"
        isLoading={loading}
        disabled={loading}
      >
        <Text tKey={confirmText} />
      </LoadingButton>
    </div>
  </div>
);
