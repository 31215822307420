export const de = {
  queryBuilder: "Abfrage-Ersteller",
  scheduledReports: "Geplante Berichte",
  lossPrevention: "Prävention von Verlusten",
  machineManagement: "Verwaltung von Maschinen",
  machineManagementOverview: "Übersicht der Maschinenverwaltung",
  machineErrors: "Maschinenfehler",
  machineErrorsSeverity: "Schweregrad",
  machineReliability: "Zuverlässigkeit der Maschine",
  refillReport: "Nachfüllbericht",
  cashTotals: "Bargeldbeträge insgesamt",
  manualDeposits: "Manuelle Einzahlung",
  utilization: "Nutzung",
  cashierManagement: "Kassiererverwaltung",
  cashierManagementOverview: "Überblick über die Kassenführung",
  cashierBalancing: "Kassenabrechnung",
  cashierTransactions: "Kassierertransaktionen",
  report: {
    title: "Berichte",
    overview: "Übersicht der Berichte",
    desc: "Erstellen Sie verschiedene Berichte je nach den Informationen, an denen Sie interessiert sind. Die Berichte sind auf der Grundlage von Industrienormen für die Bargeldbearbeitung vordefiniert.",
    queryBuilderDescription:
      "Verwenden Sie Dynamic Query Builder, um neue, benutzerdefinierte Berichte aus vorhandenen Daten zu erstellen.",
    scheduledReportsDescription:
      "Planen Sie einen Bericht, wenn Sie automatisch bestimmte Informationen in bestimmten Zeitintervallen und für einen bestimmten Zeitraum erhalten möchten.",
    lossPreventionDescription:
      "Der Schadenverhütungsbericht enthält Informationen, die mit dem Schutz der Vermögenswerte des Unternehmens zu tun haben.",
    machineManagementDescription:
      "Der Maschinenverwaltungsbericht enthält Informationen zu Maschinenfehlern, Details und Status.",
    cashTotalsDescription:
      "Der Bericht zeigt die Bargeldsummen nach Standort an. Das heißt, auszahlbares (wiederverwertbares) und bankfähiges Bargeld, das zur Entnahme für die Bankeinzahlung oder die CIT-Abholung zur Verfügung steht. Beanspruchte Werte, offene Schichten und Smart Vault werden auf Automatenebene gemeldet.",
    manualDepositsDescription:
      "Der Bericht enthält Informationen über alle manuell eingegebenen Einlagen oder beanspruchten Werte.",
    netCashDescription:
      "Der Bericht zeigt eine Zusammenfassung der Eröffnungs- und Schlusssaldobeträge im Laufe eines vollen Geschäftstages.",
    utilizationDescription: "Der Bericht zeigt Daten über den Cash-in/Cash-out-Strom für den ausgewählten Zeitraum.",
    cashierManagementDescription: "Der Kassenverwaltungsbericht zeigt Informationen zu den Kassentransaktionen an.",
    cashierBalancingDescription:
      "Der Bericht zeigt die Tagessalden für Benutzer mit einer bestimmten Rolle. Er ermöglicht den Abgleich zwischen Kassierer und POS-Daten.",
    cashierTransactionsDescription:
      "Der Bericht zeigt eine Zusammenfassung der Transaktionen, die von Benutzern mit der Rolle Kassierer durchgeführt wurden. Die Transaktionsarten sind Einzahlung, Ausgabe und Ende der Schicht.",
    machineErrorsDescription:
      "Der Bericht zeigt den Verlauf der Maschinenfehler. Sie können nach Gruppe, Standort, Datum, Gerät, Schweregrad und Art (dem Fehlercode) filtern.",
    machineReliabilityDescription:
      "Der Bericht zeigt den Verlauf der Gesamtzahl der Sitzungen und die Anzahl der erfolgreichen Sitzungen während des ausgewählten Zeitraums. Der Bericht zeigt alle Fehler an, die während einer angemeldeten Sitzung aufgetreten sind. Sie können den Bericht nach Gruppe und Standort filtern.",
    refillReportDescription:
      "Der Bericht zeigt an, wann eine Maschine geleert oder nachgefüllt werden muss. Er zeigt Informationen über die aktuellen Behälterzahlen, benutzerkonfigurierte Grenzwerte (hoch und niedrig) und die Menge, die benötigt wird, um den voreingestellten Höchststand des Behälters zu erreichen (Nachfüllzahl).",
    onlineStatusDescription: "Der Bericht zeigt den Online-Status des Geräts für den ausgewählten Zeitraum an.",
    period: "Berichtszeitraum",
    totalAmount: "{{currency}} Betrag",
    totalCount: "{{currency}} Zählung",
    openingTotalAmount: "{{currency}} Eröffnung",
    closingTotalAmount: "{{currency}} Schließen",
    type: "Art",
    currency: "Währnung",
    container: "Kassette",
    denomination: "Stückelung",
    count: "Zählung",
    total: "Total",
    md: {
      title: "Details der Maschine",
      desc: "Der Bericht zeigt Maschinendetails",
      codVersion: "CoD version",
      machineDate: "Datum der Maschine",
      accountingDate: "Buchungsdatum",
      lastErrorDate: "Das jüngste Fehlerdatum",
      lastError: "Der neueste Fehler",
    },
    claimedValues: {
      title: "Beanspruchte Werte",
      desc: "Verwenden Sie den Bericht 'Beanspruchte Werte', um Informationen über die tatsächlichen Drop-Safe-Gesamtwerte auf der Grundlage der vom Benutzer beanspruchten Werte zu einem bestimmten Zeitpunkt zu erhalten.",
    },
    cashSummary: {
      title: "Kassenmittelübersicht",
      desc: "Verwenden Sie den Kassenbericht, um Informationen über aktuelle und historische Ansichten, entbehrliches (wiederverwertbares) und bankfähiges Bargeld zu erhalten, das zur Entnahme für die Bankeinzahlung oder die Abholung durch den Geldtransport zur Verfügung steht.",
      bankableAmount: "Betrag der Bankeinzahlung",
      bankableCount: "Bank deposit count",
      dispensableAmount: "Entbehrlicher Betrag",
      dispensableCount: "Verfügbare Anzahl",
      machineTotal: "Maschine gesamt",
      dropSafe: "Tresor",
    },
    openShifts: {
      title: "Offene Schichten",
      desc: "Verwenden Sie den Bericht 'Offene Schichten', um Informationen über die letzten Kassierertransaktionen (Ausgaben) zu erhalten, für die es innerhalb des Datumsbereichs keine nachfolgende Transaktion zum Schichtende gibt.",
      cashierId: "Kassierer-ID",
      cashierName: "Name des Kassierers",
      role: "Rolle",
      dateTime: "Datum und Uhrzeit",
      machineDateTime: "Datum und Uhrzeit der Maschine",
      transactionType: "Transaktionsart",
    },
    netCash: {
      title: "Netto-Kassenbestand",
      desc: "Der Bericht zeigt eine Zusammenfassung der Eröffnungs- und Schlusssaldobeträge im Laufe eines vollen Geschäftstages.",
      noAmountsData: "Keine Mengenangaben",
    },
  },
  cashMgmt: {
    title: "Bargeldmanagement",
    overview: "Überblick über das Cash Management",
    desc: "Der Bericht über die Kassenführung zeigt Informationen über den Kassenbestand und die Summen an.",
  },
  tranReport: {
    title: "Transaktionen",
    desc: "Der Transaktionsbericht enthält Informationen zu allen verfügbaren Transaktionen.",
    subtype: "Untertyp",
    type: "Typ",
    tranDate: "Datum der Transaktion",
    accountingDate: "Buchungsdatum",
    machineTime: "Maschinenzeit",
    machine: "Maschine",
    site: "Standort",
    tranId: "Transaktions-ID",
    sequence: "Sequenz",
    reference: "Referenz",
    machineUser: "Maschinenbenutzer",
    account: "Konto",
    roles: "Rollen",
    workUnit: "Arbeitsgruppe",
    workUnitGroup: "Arbeitsgruppen",
    originUser: "Ursprünglicher Benutzer",
    originUserAccount: "Ursprüngliches Benutzerkonto",
    customData: "Benutzerdefinierte Daten",
    valuesByDeno: "Werte nach Stückelungen",
    tranCommissions: "Kommissionen für Transaktionen",
    useAccDate: "Buchungsdatum verwenden",
    datePeriod: "Berichtszeitraum",
    withTranCorr: "Korrekturvorgänge einbeziehen",
    mixEdited: "Mix bearbeitet",
    cardIdentifier: "Maschinennutzerkarte",
    UUID: "Maschinen UUID",
    timestamp: "Zeitstempel",
    timezoneOffset: "Zeitzone UTC-Offset",
    currency: "Währung",
    total: "Total",
    valBag: {
      nodeId: "Node ID",
      totalAmount: "Gesamtsumme",
      type: "Typ",
      rejects: "Rückgabe",
      valNodeId: "Werte Node ID",
      total: "Total",
      count: "Zählen",
      decimals: "Dezimal",
      currency: "Währnung",
      denomination: "Stückelung",
      pieceValue: "Stückwert",
      exchangeRate: "Wechselkurse",
      exchangeRateDecimals: "Wechselkurs-Dezimal",
      reference: "Referenz",
      valType: "Werttyp",
      claimedValueType: "Geforderte Wertart",
      customType: "Benutzerdefinierter Typ",
      c2Count: "C2 Zählung",
      c3Count: "C3 Zählung",
      c4bCount: "C4b Zählung",
      creditCat2: "Credit cat 2",
      creditCat3: "Credit cat 3",
    },
  },
  cashInv: {
    title: "Kassenbestand",
    desc: "Der Bericht zeigt den Bargeldbetrag in jedem Behälter, pro Automat und zu einem bestimmten Zeitpunkt. Der Betrag wird nach Bargeldart, Nennwert und Währung angezeigt.",
    machine: "Maschine",
    machineType: "Maschinen Art",
    container: "Kassetten",
    denomination: "Stückelung",
    type: "Typ",
    site: "Standort",
  },
  mos: {
    title: "Online status",
    desc: "Verwenden Sie den Online-Statusbericht, um Informationen über den Online-Status eines Rechners für den ausgewählten Zeitraum zu erhalten..",
    machine: "Maschine",
    site: "Standort",
    status: "Status",
    lastEvent: "Letztes Ereignis",
    lastReceived: "Zuletzt erhalten",
    timeOfStatusChange: "Zeitpunkt der Statusänderung",
  },
  mosCurrent: {
    title: "Aktueller Online-Status",
    desc: "Verwenden Sie den aktuellen Online-Statusbericht, um den Online-Status des Geräts zum aktuellen Zeitpunkt zu verfolgen.",
  },
  mDepo: {
    devices: "Geräte",
    bagIds: "Beutel IDs",
    date: "Datum/Uhrzeit",
    type: "Typ",
    machineDate: "Datum/Uhrzeit der Maschine",
    tranType: "Transaktionsart",
  },
};
