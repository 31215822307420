import { Info } from "@phosphor-icons/react";
import React, { useState } from "react";
import "twin.macro";
import "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Button, LoadingButton, Text, Tooltip, useModalComponent } from "common/guideline";

import { useField } from "../../../../common/form";
import { CustomRenderFields, TextLabel } from "../../../../common/form/renderFields";

type MultipleAddProps = {
  onDataReady: (data: string[]) => void;
  info: React.ReactElement;
  callToAction: string;
  loading?: boolean;
  onClose?: () => void;
  error?: string;
};

export const MultipleAdd: React.FC<MultipleAddProps> = ({
  info,
  callToAction,
  loading,
  onDataReady,
  error,
  onClose,
}) => {
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [data, setData] = useState<string[] | null>(null);
  const [t] = useTranslation();

  const handleSubmit = () => {
    if (data) {
      onDataReady(data);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    console.log(event);
    const clipboardData = event.nativeEvent?.clipboardData;
    if (clipboardData) {
      event.preventDefault();
      const text = clipboardData.getData("text/plain");
      const dataRows = text.split(/\r?\n/).filter((row) => row.length);
      setData(dataRows);
      setTextAreaValue(dataRows.join("\n"));
    }
  };

  return (
    <div tw="container w-full h-full p-2 pl-0 pr-4">
      <div tw="flex space-x-2 pl-2 items-center">
        <Info size={18} />
        <Text withoutI18n variant="paragraph">
          {info}
        </Text>
      </div>
      <textarea
        disabled={loading}
        value={textAreaValue}
        tw="bg-gray-1 h-64 w-full m-2 p-2 border-dashed border-2 text-sm leading-relaxed max-h-[80vh] min-h-[200px]"
        placeholder={t("administration.mg.multipleAddPrompt")}
        onPaste={handlePaste}
      />
      <div tw="flex justify-between ml-2 w-full items-center ">
        <LoadingButton disabled={textAreaValue == ""} isLoading={loading} onClick={handleSubmit} tw="flex-shrink-0">
          <Text>{callToAction}</Text>
        </LoadingButton>
        {error && (
          <Tooltip content={error} tw="text-error-default text-sm  min-w-0 ml-2 mr-2 flex-grow">
            <div tw="whitespace-nowrap overflow-hidden overflow-ellipsis">{error}</div>
          </Tooltip>
        )}
        {onClose && (
          <Button variant="ghost" onClick={onClose}>
            <Text tKey="common.close" />
          </Button>
        )}
      </div>
    </div>
  );
};

export const custom: CustomRenderFields = {
  type: "custom",
  name: "machineUuids",
  label: "label",
  Component: ({ name }) => {
    const [t] = useTranslation();
    const { onChange, value } = useField(name);

    const handleDataReady = (data: string[]) => {
      onChange(data);
      showBulkMachineAdd();
    };

    const handleAdd = () => {
      showBulkMachineAdd();
    };

    const [showBulkMachineAdd] = useModalComponent({
      Component: (
        <MultipleAdd
          callToAction={t("administration.mg.multipleAdd")}
          info={<span>{t("administration.mg.multipleAddToGroup", { name: "new" })}</span>}
          onDataReady={handleDataReady}
        />
      ),
      modalProps: {
        variant: ["modal", "md"],
      },
    });

    return (
      <label tw="flex flex-col w-fit">
        <TextLabel tKey="administration.mg.multipleAdd" />
        <div tw="flex items-center gap-2">
          <Button tw="h-10 sm:text-sm" onClick={handleAdd}>
            {t("administration.mg.multipleAdd")}
          </Button>
          {value && (
            <Text
              tKey="administration.mg.multipleAdd"
              tValue={{ count: (value as string[]).length }}
              variant="paragraph"
            />
          )}
        </div>
      </label>
    );
  },
};
