import type { JSZipObject } from "jszip";
import { useState } from "react";
import tw from "twin.macro";

import { SelectRaw } from "common/form/renderFields";
import { ComponentModalProps, Container, FullLine, Text, useModal } from "common/guideline";
import { hasExtension, unZipFile } from "common/helpers";

const extensionsToExclude = ["md5", "xsd", "swp", "pem", "tmpl", "MF", "sh", "json", "p12", "list"];

const errors = {
  _1: "zts.commands.logs.err._1",
  _2: "zts.commands.logs.err._2",
};

type Props = any & ComponentModalProps;

type Log = {
  label: string;
  value: string;
};

const extractLogArchive = async (files: File) => {
  const logs: Log[] = [];
  let err: null | { error: string } = null;
  const aggregateLogs = async ([fileName, fileContent]: [string, JSZipObject]) => {
    if (hasExtension(fileName, ["zip"])) {
      unZipFile(fileContent?.async("blob"), errors._1, errors._2).then((innerContents) =>
        Object.entries(innerContents.files).forEach(aggregateLogs),
      );
    } else if (!hasExtension(fileName, extensionsToExclude)) {
      await fileContent?.async("string").then((logData) => {
        logs.push({ label: fileName, value: logData });
      });
    }
  };
  await unZipFile(files, errors._1, errors._2)
    .then((contents) => Object.entries(contents.files).forEach(aggregateLogs))
    .catch((e) => (err = { error: e.message }));
  return err || logs;
};

const LogViewer: React.FC<Props> = ({ logArchive }) => {
  let logs: Log[] = [];
  let hasAnyLogs = false;
  extractLogArchive(logArchive).then((res) => {
    logs = res;
    hasAnyLogs = logs.length > 0;
  });
  const [currentLog, setLog] = useState<any>(logs.length > 0 ? logs[0]?.value : null);
  return (
    <div>
      <Text tKey="zts.commands.logs.viewLogs" />
      <br />
      <FullLine />
      <FullLine />
      <br />
      {hasAnyLogs ? (
        <>
          <SelectRaw
            tw="[max-width: 300]"
            name="logFile"
            label="zts.commands.logs.selectLogFile"
            options={logs}
            value={currentLog}
            onChange={(v) => {
              setLog(v);
            }}
            variant={["sm", "dropdown"]}
            labelVariant="row"
          />
          <br />
          <Container variant="card" tw="text-sm">
            <pre tw="block mb-2">
              <span>{currentLog}</span>
            </pre>
          </Container>
        </>
      ) : (
        <Text tKey="zts.commands.logs.noLogsToShow" />
      )}
    </div>
  );
};

export const useLogViewerModal = (logArchive: any) =>
  useModal.actions.open({
    modalProps: { variant: ["modal", "lg"], backdropClose: false },
    id: "logViewerModal",
    Component: <LogViewer logArchive={logArchive} />,
    closeOnRouteChange: true,
  });
