import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.personalized",
    route: "PERSONALIZED",
  },
  {
    text: "common.insights",
    route: "INSIGHTS",
  },
];
