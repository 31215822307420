import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as MachineErrorsSVG } from "assets/icons/MachineErrors.svg";
import {
  PageLayout,
  SelectWrapper,
  useMappedSiteGroupContext,
  useQueryByDateFilter,
  useTimeZoneFilter,
} from "base/components";
import { client } from "client";
import {
  CustomGetCsvFn,
  DateRangeInfo,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  tableDownloadTitles,
  useDateRangeFilter,
  useDateRangeZoned,
  usePagination,
} from "common/guideline";
import {
  ErrorsReportCriteriaDtoIn,
  GenerateMachineErrorsReportDocument,
  GenerateMachineErrorsReportQuery,
  GenerateMachineErrorsReportQueryVariables,
  MachineErrorsReportOrderColumn,
  useGenerateMachineErrorsReportQuery,
} from "generated";
import { useTranslation } from "i18n";
import { RmSeverityBadge } from "machine/components";
import { FilterBox } from "report/components";
import { SortByColumnsData, useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";

type MachineErrorsReportOrderColumnInternal = MachineErrorsReportOrderColumn | "MACHINE_NAME";

type MachineError = NonNullable<
  NonNullable<NonNullable<GenerateMachineErrorsReportQuery["generateMachineErrorsReport"]>["rows"]>[0]
> & {
  __typename: "ErrorsReportRowDto";
};

type MachineErrorRow = MachineError & { machineName: string };

const getColumns: TableTypes.TranslatedColumns<
  MachineErrorRow,
  SortByColumnsData<MachineErrorsReportOrderColumnInternal>
> = (t) => [
  {
    header: t("machine.uuid"),
    id: "MACHINE",
    accessorKey: "uuid",
  },
  {
    header: t("machine.machine_one"),
    id: "MACHINE_NAME",
    accessorKey: "machineName",
  },
  {
    header: t("machine.deviceId"),
    id: "DEVICE_ID",
    accessorKey: "deviceId",
  },
  {
    header: t("machine.dateTime"),
    id: "DATE",
    accessorFn: (d) => accessors.zonedDateTime(d.dateTime, t),
    meta: {
      hideVertical: true,
      csv: {
        accessorFn: (v) => accessors.zonedDateTimeCsv(v.dateTime, t),
      },
    },
  },
  {
    header: t("machine.kind"),
    id: "KIND",
    accessorKey: "kind",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.severity"),
    id: "SEVERITY",
    accessorKey: "severity",
    cell: RmSeverityBadge,
  },
  {
    header: t("machine.information"),
    id: "INFORMATION",
    accessorKey: "information",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.extendedInfo"),
    id: "EXTENDED_INFORMATION",
    accessorKey: "extendedInfo",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.errorCode"),
    id: "ERROR_CODE",
    accessorKey: "errorCode",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("machine.cleared"),
    id: "CLEARED",
    accessorKey: "cleared",
    meta: {
      hideVertical: true,
    },
  },
];

const VerticalHeader: React.FC<TableTypes.VerticalHeaderProps<MachineErrorRow>> = () => (
  <div tw="pb-4 flex justify-between"></div>
);

const TABLE_NAME = "machineErrors";

export const MachineErrors = () => {
  const { t, i18n } = useTranslation();
  const [{ pageSize, pageIndex }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<MachineErrorsReportOrderColumn>();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const [zoneId, TimeZone] = useTimeZoneFilter();
  const [date, DateRange] = useDateRangeFilter("currentDay", {
    label: "report.period",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });
  const zonedDateRange = useDateRangeZoned(date, zoneId);
  const [queryBy, QueryByDateSelect] = useQueryByDateFilter("message");
  const queryVariables: ErrorsReportCriteriaDtoIn = {
    reportName: "MachineErrorsReport",
    skip: pageSize * pageIndex,
    limit: pageSize,
    order,
    orderColumn,
    machineNodeIds: machine,
    locationNodeIds: location,
    siteGroupIds: siteGroup,
    fromDate: zonedDateRange.from,
    toDate: zonedDateRange.to,
    queryBy,
    zoneId,
  };
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateMachineErrorsReportQuery({ variables: { input: queryVariables } });

  const rows = (data?.generateMachineErrorsReport?.rows || []) as MachineErrorRow[];
  const extendedRows = rows.map((row) => ({ ...row, machineName: row.machineName }));
  const fullSize = data?.generateMachineErrorsReport?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) return getOptions({ data: extendedRows });

    if (!fullSize) return "";

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: { generateMachineErrorsReport } = {} } = await client.query<
      GenerateMachineErrorsReportQuery,
      GenerateMachineErrorsReportQueryVariables
    >({
      query: GenerateMachineErrorsReportDocument,
      variables: { input: { ...queryVariables, skip: 0, limit: fullSize || 0 } },
    });

    const rowsWithMachineNamesAdded = ((generateMachineErrorsReport?.rows || []) as MachineErrorRow[]).map((row) => ({
      ...row,
      machineName: row.machineName,
    }));

    return getOptions({ data: rowsWithMachineNamesAdded });
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="machineErrors"
      subtitle="report.machineErrorsDescription"
      Icon={MachineErrorsSVG}
      withPicker
    >
      <FilterBox>
        <SelectWrapper>{DateRange}</SelectWrapper>
        <SelectWrapper>{QueryByDateSelect}</SelectWrapper>
        {TimeZone}
      </FilterBox>
      <DateRangeInfo from={zonedDateRange.from} to={zonedDateRange.to} timeZone={zoneId} />
      <Table
        tableName={TABLE_NAME}
        data={extendedRows as MachineErrorRow[]}
        columns={columns}
        VerticalHeader={VerticalHeader}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        sorting={sorting}
        onSorting={setSorting}
        totalCount={fullSize}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDateRange(zonedDateRange, "machineErrors", t),
                page,
              )
            }
            disabled={!fullSize}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
