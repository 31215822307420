import { de } from "./de";
import { en } from "./en";
import { es } from "./es";
import { fr } from "./fr";

export default {
  de,
  en,
  es,
  fr,
};
