import { Arrow, UseLayerOptions, useHover, useLayer } from "react-laag";
import twM, { css, styled, theme } from "twin.macro";
import "styled-components/macro";

type Props = Omit<UseLayerOptions, "isOpen"> &
  React.PropsWithChildren<{
    tw?: string;
    className?: string;
    content?: React.ReactNode | string | number | null | React.FC;
    hideOnScroll?: boolean;
    "data-test"?: string;
  }>;

const StyledArrow = styled(Arrow)`
  & > path:first-of-type {
    fill: ${theme<string>`colors.primary.alt`};
  }
`;

export const Tooltip: React.FC<Props> = ({
  children,
  content: Content,
  placement = "top-center",
  triggerOffset = 8,
  tw,
  className,
  hideOnScroll,
  "data-test": dataTest,
  ...rest
}) => {
  const [isOver, hoverProps] = useHover({ delayLeave: 50, hideOnScroll });
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement,
    triggerOffset,
    ...rest,
  });

  return (
    <>
      <div {...{ tw }} {...triggerProps} {...hoverProps} className={className} data-test={dataTest}>
        {children}
      </div>

      {isOver &&
        renderLayer(
          <div
            css={css`
              ${twM`bg-primary-alt p-3 rounded text-white text-xs z-100 flex items-center justify-center`}
              ${Content ? "" : twM`invisible`}
            `}
            {...layerProps}
            {...hoverProps}
          >
            {typeof Content === "function" ? <Content /> : Content}
            <StyledArrow {...arrowProps} size={5} roundness={1} />
          </div>,
        )}
    </>
  );
};
