import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as MachineDetailsSVG } from "assets/icons/MachineDetails.svg";
import { PageLayout, useMappedSiteGroupContext } from "base/components";
import { client } from "client";
import {
  Badge,
  CommonCells,
  CustomGetCsvFn,
  Table,
  TableDownload,
  TableTypes,
  csvLimitExceeded,
  usePagination,
} from "common/guideline";
import {
  GenerateMachineDetailsReportDocument,
  GenerateMachineDetailsReportQuery,
  GenerateMachineDetailsReportQueryVariables,
  useGenerateMachineDetailsReportQuery,
} from "generated";
import { useTranslation } from "i18n";

import { breadcrumbs } from "./breadcrumbs";
import { MachineDetailsSubRow } from "./MachineDetailsSubRow";

export type MachineRows = NonNullable<
  NonNullable<NonNullable<GenerateMachineDetailsReportQuery["generateMachineDetailsReport"]>["rows"]>[0]
>;

const getColumns: TableTypes.TranslatedColumns<MachineRows> = (t) => [
  CommonCells.expander,
  {
    header: t("machine.uuid"),
    accessorKey: "machineUuid",
    enableSorting: false,
  },
  {
    header: t("machine.machine_one"),
    accessorKey: "machineAlias",
    enableSorting: false,
  },
  {
    header: t("location.location_one"),
    id: "location",
    accessorKey: "location",
    cell({ getValue }) {
      return <Badge variant="info">{getValue<string>()}</Badge>;
    },
    enableSorting: false,
  },
  {
    header: t("machine.machineType_one"),
    id: "machineType",
    accessorKey: "machineType",
    cell({ getValue }) {
      return getValue() ? <Badge variant="neutral">{getValue<string>()}</Badge> : <span />;
    },
    enableSorting: false,
  },
  {
    header: t("report.md.codVersion"),
    accessorKey: "codVersion",
    enableSorting: false,
  },
  // not used for now, leave it here for future use
  // {
  //   header: t("report.md.machineDate"),
  //   accessorFn: (d) => accessors.zonedDateTime(d.machineDate, t),
  //   enableSorting: false,
  //   meta: {
  //     csv: {
  //       accessorFn: (v) => accessors.zonedDateTimeCsv(v.machineDate, t),
  //     },
  //   },
  // },
  // {
  //   header: t("report.md.accountingDate"),
  //   accessorFn: (d) => accessors.zonedDateTime(d.accountingDate, t),
  //   enableSorting: false,
  //   meta: {
  //     csv: {
  //       accessorFn: (v) => accessors.zonedDateTimeCsv(v.accountingDate, t),
  //     },
  //   },
  // },
  // {
  //   header: t("report.md.lastErrorDate"),
  //   accessorFn: (d) => accessors.zonedDateTime(d.lastErrorDate, t),
  //   enableSorting: false,
  //   meta: {
  //     csv: {
  //       accessorFn: (v) => accessors.zonedDateTimeCsv(v.lastErrorDate, t),
  //     },
  //   },
  // },
  // {
  //   header: t("report.md.lastError"),
  //   accessorKey: "lastError",
  //   enableSorting: false,
  // },
];

const TABLE_NAME = "machineDetails";
const csvLimit = 30_000;

export const MachineDetails = () => {
  const { t, i18n } = useTranslation();
  const [{ pageSize, pageIndex }, setPagination] = usePagination(TABLE_NAME);
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ machine, location, siteGroup }] = useMappedSiteGroupContext(true);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateMachineDetailsReportQuery({
    variables: {
      criteriaDto: {
        reportName: "MachineDetailsReport",
        skip: pageSize * pageIndex,
        limit: pageSize,
        siteGroupNodeIds: siteGroup,
        locationNodeIds: location,
        machineNodeIds: machine,
      },
    },
  });

  const rows = data?.generateMachineDetailsReport?.rows as MachineRows[] | undefined;
  const fullSize = data?.generateMachineDetailsReport?.fullSize || 0;

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) return getOptions({ data: rows ?? [] });
    if (!fullSize) return "";
    if (csvLimitExceeded(fullSize, csvLimit, t)) return false;

    const { data: { generateMachineDetailsReport } = {} } = await client.query<
      GenerateMachineDetailsReportQuery,
      GenerateMachineDetailsReportQueryVariables
    >({
      query: GenerateMachineDetailsReportDocument,
      variables: {
        criteriaDto: {
          reportName: "MachineDetailsReport",
          skip: 0,
          limit: fullSize || 0,
          locationNodeIds: location,
          siteGroupNodeIds: siteGroup,
          machineNodeIds: machine,
        },
      },
    });

    return getOptions({ data: (generateMachineDetailsReport?.rows || []) as MachineRows[] });
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="report.md.title"
      subtitle="report.md.desc"
      Icon={MachineDetailsSVG}
      withPicker
    >
      <Table<MachineRows>
        tableName={TABLE_NAME}
        data={rows || []}
        columns={columns}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        totalCount={fullSize}
        SubRows={MachineDetailsSubRow}
        actions={<TableDownload title="report.md.title" disabled={!fullSize} getCsv={getCsv} getCsvCurrentPage />}
      />
    </PageLayout>
  );
};
