export const fr = {
  zts: {
    title: "Gestion à distance des machines",
    commands: {
      title: "Commandes",
      command: "Commande",
      desc: "Execute a command on the selected machine",
      executeCommand: "Exécuter une commande sur la machine sélectionnée",
      execute: "Exécuter",
      commandHistory: "Historique des commandes exécutées sur la machine sélectionnée",
      started: "Démarrée",
      ended: "Terminée",
      status: "Statut",
      parameters: "Paramètres",
      executedBy: "Executée par",
      deleteSuccess: "Commande supprimée avec succès",
      deleteError: "Une erreur est survenue lors de la tentative de suppression de la commande",
      deleteTitle: "Supprimer une commande de l'historique",
      deleteDesc: "La commande sera supprimée de l’historique, poursuivre ?",
      close: "Fermer",
      submit: "Supprimer",
      selectCommand: "Sélectionner la commande",
      restart: "Redémarrer la machine",
      reboot: "Rebooter la machine",
      temporarilyOffline: "La machine sera temporairement hors ligne, poursuivre ?",
      restartSuccess: "La demande de redémarrage de la machine est envoyée, veuillez attendre quelques minutes",
      rebootSuccess: "La demande de reboot de la machine est envoyée, veuillez attendre quelques minutes",
      restartFailure: "La demande de redémarrage de la machine a échoué, veuillez réessayer dans quelques minutes",
      rebootFailure: "La demande de reboot de la machine a échoué, veuillez réessayer dans quelques minutes",
      noTypesSelected: "Aucun type sélectionné",
      statuses: {
        InProgress: "En cours",
        Restarting: "Redémarrage",
        Rebooting: "Rebooting",
        Failure: "Echec",
        FailedToClaim: "La machine était occupée",
        Canceled: "Annulé",
        Ok: "OK",
        Timeout: "Délai d’attente expiré",
      },
      logs: {
        title: "Journaux",
        logsDesc: "Récupérer et visualiser les journaux de la machine sélectionnée",
        retrieveLogs: "Récupérer les journaux",
        retrievedLogs: "Récupérer l’archive du journal",
        retrieveLogsModalTitle: "Récupérer les journaux pour la machine sélectionnée",
        retrieveLogsDesc: "Sélectionnez la période et les types de journaux",
        viewLogs: "Voir les journaux",
        from: "De",
        to: "A",
        fromMustBeSmallerThanTo: "Doit être avant le De",
        toMustBeGreaterThanFrom: "Doit être après le A",
        retrievalDate: "Date de récupération",
        file: "Fichier",
        fileSize: "Taille",
        noFiles: "Nombre de fichiers",
        retrievedBy: "Récupérer par",
        profiling: "Profilage",
        configuration: "Configuration",
        reports: "Rapports",
        history: "Historique",
        receipts: "Reçus",
        icx: "ICX",
        data: "Données",
        devices: "Machines",
        online: "En ligne",
        trace: "Trace",
        logTypes: "Types de journaux",
        fullSystemDump: "Dump du système complet ?",
        retrieveLogsSuccess:
          "La commande de récupération des journaux a été envoyée, veuillez rafraîchir cette vue dans quelques minutes",
        retrieveLogsFailure: "Une erreur s'est produite lors de l'envoi de la commande de récupération des journaux",
        deleteSuccess: "L’archive du journal a été supprimée avec succès",
        deleteError: "Une erreur est survenue durant la suppression de l’archive du journal",
        deleteTitle: "Supprimer l’archive du journal",
        deleteDesc: "Les métadonnées et l'archive du journal téléchargé seront supprimées, poursuivre ?",
        missingPreSignedUrl: "Impossible d'obtenir l'URL présignée",
        selectLogFile: "Sélectionner le fichier journal",
        fileTooLarge: "Le fichier est trop volumineux!",
        fileTooLargeDesc: "Le fichier est trop volumineux, souhaitez-vous le télécharger à la place ?",
        noLogsToShow: "Une erreur est survenue, ou il n’y a pas de journal à afficher",
        err: {
          _1: "Erreur inconnue, réessayer ultérieurement",
          _2: "Doit être un fichier .zip",
        },
      },
    },
    remoteUpgrade: {
      upgradeHistory: "Historique des mises à jour",
      upgradeHistoryDesc: "Historique des installations de logiciels sur la machine sélectionnée",
      timeOfUpgrade: "Mise à jour démarrée",
      name: "Nom",
      label: "Libellé",
      softwareVersion: "Version logicielle",
      revision: "Révision",
    },
    remoteConfiguration: {
      configurationHistory: "Historique des configurations",
      configurationHistoryDesc: "Historique des déploiements de la configuration sur la machine sélectionnée",
      scheduledAt: "Planifié à",
      description: "Description",
    },
    result: "Statut",
    executedBy: "Exécuté par",
    latestStatusChange: "Statut modifié",
    machineNodeId: "Id machine",
    current: "Currant",
    scheduleInfo: "Information planifiée",
    template: {
      name: "Nom",
      createdBy: "Créée par",
      description: "Description",
      fileName: "Nom de fichier",
      parts: "Contenu",
      version: "Version",
    },
  },
};
