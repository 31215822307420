import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as SquaresFourSVG } from "assets/icons/SquaresFour.svg";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";
import { Breadcrumb, Button, Text } from "common/guideline";

import { TenantTable } from "./TenantTable";

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "TENANT_LIST",
    text: "tenant.list.title",
  },
];

export const TenantList = () => (
  <PageLayout
    title="tenant.list.title"
    subtitle="tenant.list.desc"
    breadcrumbs={breadcrumbs}
    Icon={SquaresFourSVG}
    actions={
      usePermissionAccess("canCreateTenant") && (
        <Link route="TENANT_CREATE">
          <Button>
            <Text tKey="tenant.list.create" />
          </Button>
        </Link>
      )
    }
  >
    <TenantTable />
  </PageLayout>
);
