import { LatLngTuple } from "leaflet";

// some maths below to determine middle point
export const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // radius of Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // distance in km
  return d;
};

export const getCenterPoint = (points: LatLngTuple[]): LatLngTuple => {
  let x = 0;
  let y = 0;
  let z = 0;

  for (const point of points) {
    const latitude = (point[0] * Math.PI) / 180;
    const longitude = (point[1] * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const totalPoints = points.length;

  x /= totalPoints;
  y /= totalPoints;
  z /= totalPoints;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return [(centralLatitude * 180) / Math.PI, (centralLongitude * 180) / Math.PI];
};

export const logarithmNumber = (base: number, x: number): number => Math.log(x) / Math.log(base);
