import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as MachineUserSVG } from "assets/icons/MachineUser.svg";
import { PageLayout } from "base/components";
import { Button, Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";
import { MachineUserTable } from "./MachineUserTable/MachineUserTable";
import { UploadUsersForm } from "./UploadUsersForm/UploadUsersForm";

export const MachineUser = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="mu.title_other"
    subtitle="mu.description"
    Icon={MachineUserSVG}
    actions={
      <div tw="flex justify-end">
        <Link route="MACHINE_USER_CREATE" tw="mx-3">
          <Button>
            <Text tKey="mu.create" />
          </Button>
        </Link>
        <div>
          <UploadUsersForm />
        </div>
      </div>
    }
  >
    <MachineUserTable />
  </PageLayout>
);
