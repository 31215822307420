import { Row, RowModel } from "@tanstack/react-table";
import { TFunction, TKeys } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as FileCsvSVG } from "assets/icons/FileCsv.svg";
import { CONFIG } from "common/config";
import { LoadingButton, Tooltip, accessors } from "common/guideline";
import { downloadFileInUTF8WithBom, jsonToCsv, strictString } from "common/helpers";

import { CommonCells } from "./common";
import { useTableDataContext } from "./TableContext";
import { ColumnExtenedDef } from "./types";

type GetCSVFromColumnsArgs<T extends Record<string, any>> =
  | { data: T[]; mapColumn?: (column: ColumnExtenedDef<T>) => ColumnExtenedDef<T> }
  | {
      additionalData?: Partial<T>[];
      mapRow?: (row: T, subRow?: any) => any;
      withSubRows?: boolean;
      mapColumn?: (column: ColumnExtenedDef<T>) => ColumnExtenedDef<T>;
    };

export type CustomGetCsvFn<T extends Record<string, any> = any> = (
  getOptions: (options: GetCSVFromColumnsArgs<T>) => string,
  currentPage?: boolean,
  currentPageRows?: Row<T>[],
) => string | Promise<string | false>;

const DATE_CSV_FORMAT = "ddMMMyyyy'T'HHmm";
const getFileNameDate = (date: string, t: TFunction) => accessors.date(date, t, DATE_CSV_FORMAT).replace(".", "");

const getFileNameStart = (preffix: TKeys, t: TFunction) =>
  `${t(preffix)}_${getFileNameDate(new Date().toISOString(), t)}`;

export const tableDownloadTitles = {
  withPageInfo: (t: TFunction, titleStart: string, page: number | null) =>
    page === null ? titleStart : `${titleStart}__${t("common.table.pagination.page")}_${page}`,
  withoutRequestedDate: getFileNameStart,
  withRequestedDate: (date: string, preffix: TKeys, t: TFunction) =>
    `${getFileNameStart(preffix, t)}__${getFileNameDate(date, t)}`,
  withRequestedDateRange: ({ from, to }: { from: string; to: string }, preffix: TKeys, t: TFunction) =>
    `${getFileNameStart(preffix, t)}__${getFileNameDate(from, t)}-${getFileNameDate(to, t)}`,
};

type Props = {
  title: TKeys | ((t: TFunction, page: number | null) => string);
  disabled?: boolean;
  getCsv?: true | "withSubRows" | CustomGetCsvFn<any>;
  getCsvCurrentPage?: boolean;
};

type DataWithRow<T> = T & { __cellRow: Row<T> };

const withRow = <T extends Record<any, any>>(data: T, __cellRow: Row<T>): DataWithRow<T> => ({
  ...data,
  __cellRow,
});

const getCsvFromTableColumns =
  <T extends Record<string, any>>(getRowModel: () => RowModel<any>) =>
  (options: GetCSVFromColumnsArgs<T> = {}) => {
    const rows = getRowModel().rows;
    const columns = rows[0]
      .getVisibleCells()
      .map((c) => c.column.columnDef)
      .filter((c) => !c.meta?.csv?.hide) as ColumnExtenedDef<T>[];
    const columnsHiddenToGenerate = rows[0]
      .getAllCells()
      .filter((c) => c.column.columnDef.meta?.csv?.generateIfHidden)
      .map((c) => c.column.columnDef) as ColumnExtenedDef<T>[];

    const [headers, values] = [...columns, ...columnsHiddenToGenerate].reduce(
      (acc, current) => {
        const curr = options.mapColumn?.(current) || current;

        if (curr.id === CommonCells.expander.id) {
          acc[0].push("");
          acc[1].push("");
        } else {
          acc[0].push(strictString(curr.meta?.csv?.header || curr.meta?.headerName || curr.header));
          acc[1].push(
            curr.meta?.csv?.accessorFn
              ? // eslint-disable-next-line
                // @ts-ignore
                (d) => curr.meta?.csv?.accessorFn(d, d.__cellRow?.index ?? 0)
              : curr.meta?.csv?.accessorKey ||
                  ("subAccessor" in curr
                    ? curr.subAccessor
                      ? (d) => (curr.subAccessor as any)(d, d.__cellRow?.index ?? 0)
                      : strictString(curr.id)
                    : "accessorFn" in curr
                    ? curr.accessorFn
                      ? (d) => curr.accessorFn(d, d.__cellRow?.index ?? 0)
                      : strictString(curr.id)
                    : "accessorKey" in curr
                    ? (curr.accessorKey as any) || strictString(curr.id)
                    : strictString(curr.id)),
          );
        }

        return acc;
      },
      [[], []] as [string[], (string | keyof T | ((d: DataWithRow<T>) => any))[]],
    );

    const data: DataWithRow<T>[] =
      "data" in options
        ? options.data
        : [
            ...(options.withSubRows
              ? rows.flatMap((r) =>
                  r.subRows?.map((s) =>
                    withRow(
                      options.mapRow ? options.mapRow(r.original, s.original) : { ...r.original, ...s.original },
                      r,
                    ),
                  ),
                )
              : rows.map((r) => withRow(options.mapRow ? options.mapRow(r.original) : r.original, r))),
            ...(options.additionalData || []),
          ];

    return jsonToCsv(data, values, headers);
  };

export const TableDownload: React.FC<Props> = ({ title, getCsv, getCsvCurrentPage, disabled }) => {
  const { t } = useTranslation();
  const { getPrePaginationRowModel, getPaginationRowModel, getState } = useTableDataContext();

  const [loading, setLoading] = useState(false);
  const withLoadingState = (fn: (() => Promise<void>) | (() => null)) => async () => {
    setLoading(true);
    try {
      await fn();
    } catch (error) {
      if (!CONFIG.isProd) console.log("table download error:", error);
    }
    setLoading(false);
  };

  const currentPageCsvGenerator = getCsvCurrentPage
    ? async () => {
        downloadFileInUTF8WithBom(
          typeof title === "function"
            ? title(t, getState().pagination.pageIndex + 1)
            : tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withoutRequestedDate(title, t),
                getState().pagination.pageIndex + 1,
              ),
          "text/csv",
          await (typeof getCsv === "function"
            ? getCsv(getCsvFromTableColumns(getPaginationRowModel), true, getPaginationRowModel().rows)
            : getCsvFromTableColumns(getPaginationRowModel)({ withSubRows: getCsv === "withSubRows" })),
        );
      }
    : () => null;

  const csvGenerator = getCsv
    ? async () =>
        downloadFileInUTF8WithBom(
          typeof title === "function" ? title(t, null) : tableDownloadTitles.withoutRequestedDate(title, t),
          "text/csv",
          await (typeof getCsv === "function"
            ? getCsv(getCsvFromTableColumns(getPrePaginationRowModel))
            : getCsvFromTableColumns(getPrePaginationRowModel)({ withSubRows: getCsv === "withSubRows" })),
        )
    : () => null;

  return (
    <div>
      {getCsvCurrentPage && (
        <Tooltip content={t("common.table.downloadCsvPage")} data-test="downloadAsCsvPageInfo">
          <LoadingButton
            disabled={disabled || loading}
            variant={["mdSquare", "ghost", "withIcon"]}
            data-test="downloadAsCsvPage"
            isLoading={loading}
            onClick={withLoadingState(currentPageCsvGenerator)}
          >
            <FileCsvSVG width={36} height={36} />
            <span tw="text-primary-default">{getState().pagination.pageIndex + 1}</span>
          </LoadingButton>
        </Tooltip>
      )}
      {getCsv && (
        <Tooltip content={t("common.table.downloadCsv")} data-test="downloadAsCsvInfo">
          <LoadingButton
            disabled={disabled || loading}
            variant={["mdSquare", "ghost"]}
            data-test="downloadAsCsv"
            isLoading={loading}
            onClick={withLoadingState(csvGenerator)}
          >
            <FileCsvSVG width={36} height={36} />
          </LoadingButton>
        </Tooltip>
      )}
    </div>
  );
};
