import { Location } from "history";
import { memo } from "react";

import { PreviousRoute, history } from "./history";

export type RouteMiddlewareFN = (location: Location, previousRoute: PreviousRoute) => React.ReactElement | null | void;

type Props = {
  Component: React.ComponentType;
  middlewares: RouteMiddlewareFN[];
  // Route compoennt must have path prop for react-router library to infer paths when NOT declaring in `useRoutes` hook
  path?: string;
};

const applyRouteMiddleware = (middleware: RouteMiddlewareFN[], location: Location, previousRoute: PreviousRoute) => {
  // for loop because we want to break on navigate
  for (let i = 0; i < middleware.length; i++) {
    const navigate = middleware[i](location, previousRoute);
    if (navigate) return navigate;
  }
};

const _RouteMiddleware: React.FC<Props> = ({ Component, middlewares }) =>
  applyRouteMiddleware(middlewares, history.location, history.getPreviousRoute()) || <Component />;

// apply middleware function to route component - can check something vefore route enter and then return `Navigate` which will redirect an user
export const RouteMiddleware = memo(_RouteMiddleware);
