import { SmileyXEyes, Spinner, Tray } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "../Text";

import { TableProps } from "./types";

type Props = Pick<TableProps<any>, "error" | "loading"> & { dataLength: number };

const TableNoData: React.FC<Pick<Props, "error">> = ({ error }) => (
  <div tw="absolute inset-0 top-[10%] flex flex-col justify-center items-center [z-index:-1]">
    {error ? (
      <>
        <SmileyXEyes size={96} weight="duotone" tw="text-error-default" />
        <Text tKey="common.table.error" variant="heading" />
        <Text variant="paragraph">
          {typeof error === "object" ? error.message : typeof error === "string" ? error : "common.table.failedToFetch"}
        </Text>
      </>
    ) : (
      <>
        <Tray size={96} weight="duotone" tw="text-gray-6" />
        <Text tKey="common.table.noData" variant="heading" />
        <Text tKey="common.table.nothingShow" variant="paragraph" />
      </>
    )}
  </div>
);

export const TableMeta: React.FC<Props> = ({ error, loading, dataLength }) =>
  loading ? (
    <Spinner size={42} tw="absolute bottom-1/2 left-[calc(50% - 21px)] animate-spin z-10 [animation-duration:800ms]" />
  ) : error || dataLength === 0 ? (
    <TableNoData error={error} />
  ) : null;
