import { Children, cloneElement, createContext, useCallback, useContext, useState } from "react";
import tw, { css, styled } from "twin.macro";
import "styled-components/macro";
import { OneOf } from "types";

type TabsContext = {
  activeTab: number;
  changeTab: (index: number) => void;
};

const TabsContext = createContext({} as TabsContext);

const Panel = <T,>({
  active,
  children,
  as: Comp = "div",
  ...rest
}: { active?: boolean; children?: React.ReactNode; as?: React.ComponentType<T> | string } & T) =>
  active ? <Comp {...(rest as any)}>{children}</Comp> : null;

const Panels: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { activeTab } = useContext(TabsContext);
  return <>{Children.map(children, (child, index) => cloneElement(child as any, { active: index === activeTab }))}</>;
};

// visited is used to indicate user - that tab was visited, but is not current active one
type TabProps = React.PropsWithChildren<{ active?: boolean; visited?: boolean; blocked?: boolean }>;

const StyledTab = styled.div<TabProps>`
  ${tw`inline-flex items-center py-2 px-1 text-sm relative`}
  ${({ blocked }) => !blocked && tw`cursor-pointer`}

  & > svg {
    ${tw`h-5 w-5`}
  }

  &:after {
    ${tw`h-1 rounded left-0 right-0 bottom-0 absolute content-[""]`}
  }

  ${({ active, visited, blocked }) =>
    active
      ? css`
          ${tw`text-primary-default`}
          &:after {
            ${tw`bg-primary-default`}
          }
        `
      : visited
      ? css`
          ${tw`text-primary-default`}
          filter: contrast(0.6);
          &:after {
            ${tw`bg-primary-default`}
          }
        `
      : css`
          ${tw`text-gray-5 transition-colors duration-200`}

          & > svg {
            ${tw`text-gray-5`}
          }

          &:after {
            ${tw`bg-gray-3 transition-colors duration-200`}
          }

          ${blocked
            ? ""
            : css`
                ${tw`hover:text-gray-6`}
                &:hover {
                  & > svg {
                    ${tw`text-gray-6`}
                  }

                  &:after {
                    ${tw`bg-gray-6`}
                  }
                }
              `}
        `}
`;

const TabList = <T,>({
  children,
  element: Comp = "div",
  disabled = false,
  ...rest
}: {
  children: React.ReactNode;
  element?: React.ComponentType<T> | string;
  disabled?: boolean;
} & T) => {
  const { changeTab, activeTab } = useContext(TabsContext);

  return (
    <Comp {...(rest as any)}>
      {Children.map(children, (child, index) =>
        cloneElement(child as any, {
          active: index === activeTab,
          onClick: () => !disabled && changeTab(index),
        }),
      )}
    </Comp>
  );
};

type TabsProps = {
  children:
    | React.ReactNode
    | ((data: { activeTab: number; setActiveTab: React.Dispatch<React.SetStateAction<number>> }) => React.ReactNode);
} & OneOf<
  {
    initialActive?: number;
  },
  {
    value: number;
    onChange: (index: number) => void;
  }
>;

export const Tabs: React.FC<TabsProps> & {
  TabList: typeof TabList;
  Tab: React.FC<TabProps>;
  Panels: typeof Panels;
  Panel: typeof Panel;
} = ({ children, initialActive = 0, value, onChange }) => {
  const [activeTab, setActiveTab] = useState(initialActive);
  const changeTab = useCallback(
    (index: number) => (typeof onChange === "function" ? onChange(index) : setActiveTab(index)),
    [onChange],
  );
  const context: TabsContext = {
    changeTab,
    activeTab: typeof value === "undefined" ? activeTab : value,
  };

  return (
    <TabsContext.Provider value={context}>
      {typeof children === "function" ? children({ activeTab, setActiveTab }) : children}
    </TabsContext.Provider>
  );
};

Tabs.TabList = TabList;
Tabs.Tab = StyledTab;
Tabs.Panels = Panels;
Tabs.Panel = Panel;
