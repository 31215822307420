import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: boolean; label: string }[] = [
  { value: false, label: "base.nodeStatus.ACTIVE" },
  { value: true, label: "base.nodeStatus.INACTIVE" },
];

export const getActiveStatuses = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "base.nodeStatus.label",
    options,
    ...values,
  });
