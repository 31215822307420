export const de = {
  base: {
    pageTitle: "Connect - $t({{title}})",
    notApplicable: "N/A",
    country: "Land",
    printPage: "Seite drucken",
    nodeStatus: {
      label: "Status",
      DELETED: "Gelöscht",
      DETACHED: "Freistehend",
      INACTIVE: "Inaktiv",
      ACTIVE: "Aktiv",
      UNKNOWN: "Unbekannt",
    },
    onlineStatus: {
      Offline: "Offline",
      Online: "Online",
      CatchingUp: "Aufholen",
      Unknown: "Unbekannt",
    },
    terms: {
      definitions: "1. Begriffserklärungen",
      useRights: "2. Nutzungsrechte",
      useRightsUnordered: "Nutzungsrechte",
      support: "3. Support",
      supportUnordered: "Support",
      financialTerms: "4. Finanzielle Bestimmungen",
      termAndTermination: "5. Laufzeit und Beendigung",
      confidentiality: "6. Vertraulichkeit",
      indemnification: "7. Entschädigungsleistungen",
      serviceLevel: "8. Service-Level-Verpflichtungen, Haftungsausschlüsse und Beschränkungen",
      general: "9. Allgemein",
      saas: "PayComplete™ Software as a Service (SaaS') Agreement",
      disclaimer:
        "WICHTIG - BITTE SORGFÄLTIG LESEN: MIT DER NUTZUNG DES PAYCOMPLETE-SERVICES ERKLÄREN SIE SICH MIT DEN VORLIEGENDEN BEDINGUNGEN EINVERSTANDEN.",
      agreement:
        "Dieser Software-als-Service-Abonnementvertrag (der 'Vertrag') regelt Ihre Nutzung des Systems (wie unten definiert) und aller damit verbundenen Dienstleistungen, die von der PayComplete™ AMERICAS LLC. in Delaware, USA, mit Hauptgeschäftssitz in 1475 E Woodfield Rd Suite 104, Schaumburg, IL 60173, USA ('Unternehmen'), bereitgestellt werden. Sie werden in dieser Vereinbarung als 'Kunde' bezeichnet. Wenn Sie das System nutzen oder wenn Sie auf 'Ich stimme zu' klicken oder eine andere bestätigende Handlung vornehmen, die Ihre Zustimmung zu dieser Vereinbarung anzeigt, dann haben Sie diesen Bedingungen zugestimmt. Wenn Sie ein Vertreter oder Angestellter des vorgesehenen Abonnenten oder Benutzers sind, sichern Sie dem Unternehmen zu, dass Sie befugt sind, diese Partei an diese Vereinbarung zu binden. Wenn Sie dieser Vereinbarung nicht zustimmen, sind Sie nicht berechtigt, das System zu nutzen.",
      components: "Bestandteile",
      componentsDesc:
        "'Komponenten' sind die einzelnen Module oder Produkte, aus denen das System besteht. Von Zeit zu Zeit werden neue Komponenten oder Funktionen in das System aufgenommen, und diese Komponenten oder Funktionen können auf bestimmte Editionen beschränkt sein.",
      customerData: "Kundendaten",
      customerDataDesc:
        "'Kundendaten' bezeichnet alle Informationen, Dokumente oder elektronischen Dateien des Kunden, die dem Unternehmen im Rahmen dieser Vereinbarung zur Verfügung gestellt werden.",
      documentation: "Dokumentation",
      documentationDesc: "'Dokumentation' bezeichnet die Online-Dokumentation, die unter ",
      error: "Fehler",
      errorDesc:
        "'Fehler' bedeutet ein reproduzierbares, wesentliches Versagen des Systems, in Übereinstimmung mit seiner Dokumentation zu funktionieren.",
      maintenance: "Wartungsfenster",
      maintenanceDesc:
        "Der Begriff 'Wartungsfenster' umfasst sowohl die Standardwartung als auch die Notfallwartung. Standard-Wartungsfenster werden auf der Website des Unternehmens mindestens 72 Stunden vor Beginn des Standard-Wartungsfensters veröffentlicht. Notfallwartungen werden nach Bedarf durchgeführt. Das Unternehmen unternimmt angemessene Anstrengungen, um Notfall-Wartungsfenster auf der Website des Unternehmens vor dem Notfall-Wartungsfenster zu veröffentlichen, aber es ist möglich, dass eine Vorankündigung eines Notfallfensters nicht erfolgt.",
      system: "System",
      systemDesc:
        "'System' bezeichnet den Software-Service, für den der Kunde bezahlt hat, einschließlich aller Updates, die im Rahmen dieses Vertrages zur Verfügung gestellt werden, sowie alle davon abgeleiteten Werke. Ein System setzt sich aus einzelnen Komponenten zusammen.",
      serviceAdministrator: "Service Administrator",
      serviceAdministratorDesc:
        "'Service-Administrator' bezeichnet die Person(en), die der Kunde bestimmt, um im Namen des Kunden einzukaufen, Benutzer im Rahmen des Vertrags zu autorisieren, Konten für zusätzliche Benutzer zu erstellen und die Nutzung des Systems durch den Kunden anderweitig zu verwalten.",
      supportDesc:
        "'Support' bezeichnet die laufenden Dienstleistungen des Unternehmens zur Unterstützung des Systems, wie in Abschnitt 3 unten definiert.",
      update: "Update",
      updateDesc:
        "'Update' bezeichnet alle Patches, Fehlerkorrekturen, Releases, Versionen, Änderungen oder Nachfolger des Systems.",
      user: "Benutzer",
      userDesc:
        "'Benutzer' bezeichnet eine namentlich genannte Person, der der Kunde Zugang zur Nutzung des Systems im Namen des Kunden gewährt hat, unabhängig davon, ob der Benutzer tatsächlich auf die Software zugreift oder nicht. Bei den Benutzern kann es sich um Mitarbeiter, Berater, Auftragnehmer oder Vertreter des Kunden handeln.",
      edition: "Version",
      editionDesc:
        "'Version' bezeichnet die benannte Konfiguration des Systems, für die der Kunde eine Lizenz erhalten hat. Eine Edition definiert, welche Komponenten, Funktionen, Beschränkungen und/oder Nutzungseinschränkungen für das an den Kunden lizenzierte System gelten. Von Zeit zu Zeit werden neue Komponenten oder Funktionen in das System eingeführt, und diese Komponenten oder Funktionen können auf bestimmte Editionen beschränkt sein. Es können auch von Zeit zu Zeit neue benannte Versionen eingeführt werden.",
      effectiveDate: "Datum des Inkrafttretens",
      effectiveDateDesc:
        "'Datum des Inkrafttretens' ist das Datum, an dem das Abonnement des Kunden für die lizenzierte Edition des Systems beginnt. Der Beginn der Vertragslaufzeit.",
      useRightsDesc:
        "Während der Laufzeit und vorbehaltlich der Bedingungen dieses Vertrags gewährt das Unternehmen dem Kunden hiermit ein nicht ausschließliches, nicht übertragbares, nicht unterlizenzierbares Recht, den Benutzern des Kunden die Nutzung der lizenzierten Edition des Systems für die geschäftlichen Zwecke des Kunden zu gestatten. Das im vorstehenden Satz genannte Nutzungsrecht ist auf die Nutzung durch die Anzahl der Benutzer beschränkt, für die der Kunde bezahlt hat. Diese Nutzungsrechte sind nicht übertragbar, es sei denn, der Kunde überträgt freiwillig im Wesentlichen sein gesamtes Vermögen auf einen Erwerber, der das Vertragsformular des Unternehmens ausfüllt und sich damit einverstanden erklärt, an alle Bestimmungen und Bedingungen dieses Vertrags gebunden zu sein. Alle Rechte an dem System, die hier nicht ausdrücklich gewährt werden, sind dem Unternehmen vorbehalten.",
      license: "Lizenz",
      licenseDesc:
        "Lizenz- und Nutzungsbeschränkungen. Sofern nicht durch zwingendes, anwendbares Recht gestattet, ist es dem Kunden nicht gestattet, direkt, indirekt, allein oder zusammen mit einer anderen Partei (i) das System zu kopieren, zu disassemblieren, zurückzuentwickeln oder zu dekompilieren; (ii) das System zu modifizieren, davon abgeleitete Werke zu erstellen oder zu übersetzen; (iii) das System in irgendeiner Form an eine andere Partei zu lizenzieren, zu verkaufen, zu vermieten, zu verleasen, zu übertragen, Rechte daran zu gewähren oder anderweitig kommerziell zu verwerten, noch darf der Kunde versuchen, eines der vorgenannten Dinge zu tun oder einen Dritten dazu zu veranlassen oder zu gestatten, eines der vorgenannten Dinge zu tun oder zu versuchen, es sei denn, dies ist in diesem Vertrag ausdrücklich gestattet. Sie erkennen an und erklären sich damit einverstanden, dass das Unternehmen alle Rechte, Titel und Anteile an allen geistigen Eigentumsrechten (einschließlich aller Ableitungen oder Verbesserungen davon) am System und an allen Vorschlägen, Verbesserungswünschen, Rückmeldungen, Empfehlungen oder anderen Informationen besitzt, die vom Kunden oder einem seiner Benutzer in Bezug auf das System bereitgestellt werden.",
      blank: "ABSICHTLICH LEER GELASSEN",
      systemUser: "Systemadministrator; Benutzerzugang",
      systemUserDesc:
        "Der Kunde ernennt einen oder mehrere Systemadministratoren. Die Systemadministratoren sind für die Verwaltung des Benutzerzugangs verantwortlich, einschließlich des Hinzufügens und Entfernens von Benutzern. Der Systemadministrator stellt sicher, dass nicht mehrere Benutzer ein Passwort oder einen Benutzernamen gemeinsam nutzen. Der Kunde erkennt an und erklärt sich damit einverstanden, dass es ihm untersagt ist, Passwörter und/oder Benutzernamen mit nicht autorisierten Benutzern zu teilen.",
      customerDataUseRightsDesc:
        "Der Kunde ist Eigentümer aller Rechte, Titel und Interessen an den Kundendaten. Der Kunde gewährt dem Unternehmen hiermit ein nicht ausschließliches, nicht übertragbares (außer wie in Abschnitt 9(d) unten dargelegt), nicht unterlizenzierbares Recht und eine Lizenz, die Kundendaten ausschließlich für die Zwecke der Nutzung des Systems durch den Kunden zu verwenden, zu kopieren, zu übertragen, zu ändern und anzuzeigen.",
      dataCustomer: "Keine sensiblen Daten; Verantwortlichkeiten des Kunden",
      dataCustomerDesc:
        "Der Kunde erkennt an, dass das System nicht für die Verwendung geschützter Kundeninformationen, einschließlich, aber nicht beschränkt auf Gesundheitsdaten, Kreditkartennummern, Finanzkontonummern oder andere ähnlich sensible persönliche Informationen, vorgesehen ist und dass der Kunde alle Risiken übernimmt, die sich aus der Verwendung solcher sensiblen Informationen mit dem System ergeben, einschließlich des Risikos einer versehentlichen Offenlegung oder eines unbefugten Zugriffs darauf. Der Kunde ist dafür verantwortlich, dass die Nutzung des Systems durch ihn und seine Benutzer mit allen geltenden Gesetzen und behördlichen Vorschriften übereinstimmt, und der Kunde erkennt an, dass er alle Risiken übernimmt, die sich aus einer solchen Nutzung ergeben, die nicht mit den geltenden Gesetzen und Vorschriften übereinstimmt.",
      security: "Sicherheit",
      securityDesc:
        "Der Kunde ist allein verantwortlich für die Aufrechterhaltung der Sicherheit aller ihm gewährten Benutzernamen und Passwörter, für die Sicherheit seiner für den Zugriff auf das System verwendeten Informationssysteme und für die Einhaltung der Bedingungen dieser Vereinbarung durch seine Benutzer. Das Unternehmen wird so tun, als ob alle elektronischen Mitteilungen, die es unter den Benutzernamen des Kunden erhält, vom Kunden gesendet wurden. Der Kunde wird das Unternehmen unverzüglich benachrichtigen, wenn er von einem Verlust oder Diebstahl oder einer unbefugten Nutzung eines der Passwörter oder Benutzernamen des Kunden Kenntnis erhält. Das Unternehmen hat jederzeit das Recht, den Zugang zu einem Benutzer oder zum Kunden zu beenden oder auszusetzen, wenn das Unternehmen in gutem Glauben davon ausgeht, dass eine solche Beendigung oder Aussetzung notwendig ist, um die Sicherheit, Integrität oder Zugänglichkeit des Systems oder des Netzwerks des Unternehmens zu wahren.",
      servicesGenerally: "Dienstleistungen im Allgemeinen",
      servicesGenerallyDesc:
        "Vorbehaltlich der Bestimmungen dieses Vertrags unternimmt das Unternehmen wirtschaftlich angemessene Anstrengungen, um dem Kunden das System zur Verfügung zu stellen.",
      updates: "Updates",
      updatesDesc:
        "Das Unternehmen liefert Updates für das System, die für die derzeit lizenzierte Edition des Kunden gelten, ohne zusätzliche Kosten. Von Zeit zu Zeit können neue Komponenten oder Funktionen veröffentlicht werden, die selektiv auf verschiedene Editionen des Systems angewendet werden. Nur die Updates, die für die aktuell lizenzierte Edition des Kunden gelten, werden dem Kunden automatisch und ohne zusätzliche Kosten zur Verfügung gestellt.",
      supportOptions: "Unterstützungsoptionen und Verfahren",
      supportOptionsDesc:
        "Das Unternehmen stellt dem Kunden den allgemeinen Support zur Verfügung, wie er im Support-Bereich der Website des Unternehmens für die aktuell lizenzierte Edition des Kunden angegeben ist. Verschiedene Editionen des Systems haben Anspruch auf unterschiedliche Supportstufen. Darüber hinaus kann das Unternehmen dem Kunden Premium-Support-Optionen gegen eine zusätzliche Gebühr anbieten.",
      errorCorrection: "Fehlerbehebung",
      errorCorrectionDesc:
        "Das Unternehmen unternimmt wirtschaftlich vertretbare Anstrengungen, um alle Fehler zu beheben oder eine angemessene Umgehung bereitzustellen, sobald dies während der normalen Geschäftszeiten des Unternehmens möglich ist. Der Kunde gewährt den Zugang, die Informationen und die Unterstützung, die das Unternehmen bei der Behebung eines Fehlers angemessenerweise benötigt. Dieser Absatz ist das einzige und ausschließliche Rechtsmittel des Kunden bei Fehlern.",
      supportExclusions: "Support ausnahmen",
      supportExclusionsDesc:
        "Das Unternehmen ist nicht verpflichtet, Fehler zu beheben oder sonstigen Support zu leisten, soweit diese Fehler oder der Bedarf an Support ganz oder teilweise durch das Unternehmen verursacht wurden:",
      seNestOneDesc:
        "Handlungen, Unterlassungen, Fahrlässigkeit oder vorsätzliches Fehlverhalten des Kunden, einschließlich unbefugter Änderungen des Systems oder seiner Betriebsumgebung;",
      seNestTwoDesc:
        "Ausfälle oder Defekte der Geräte, Software, Einrichtungen, Anwendungen Dritter oder der Internetverbindung des Kunden oder eines Dritten (oder andere Ursachen außerhalb der Firewall des Unternehmens);",
      seNestThreeDesc: "die Nutzung des Systems durch den Kunden in Abweichung von der Systemdokumentation; oder",
      seNestFourDesc: "ein Ereignis höherer Gewalt.",
      supportFees: "Support Gebühren",
      supportFeesDesc:
        "Das Unternehmen hat das Recht, dem Kunden alle durch Abschnitt 3(e) ausgeschlossenen Support-Angelegenheiten, die vom Kunden vorab schriftlich (auch per E-Mail) genehmigt wurden, zu seinen Standard-Service-Tarifen in Rechnung zu stellen.",
      limitationOfRemedies: "Begrenzung der Rechtsmittel",
      limitationOfRemediesDesc:
        "Die Behebung von Fehlern, wie sie in diesem Vertrag definiert sind, sind die einzigen Rechtsmittel des Kunden für Fehler im System.",
      fees: "Gebühren",
      feesDesc:
        "Als Gegenleistung für die Produkte, Dienstleistungen und Nutzungsrechte, die das Unternehmen dem Kunden im Rahmen dieser Vereinbarung zur Verfügung stellt, zahlt der Kunde dem Unternehmen die Gebühren in der angegebenen Höhe. Alle Dollarbeträge beziehen sich auf US Dollar.",
      paymentTerms: "Zahlungsbedingungen",
      paymentTermsDesc:
        "Das Unternehmen stellt dem Kunden alle wiederkehrenden Kosten monatlich oder jährlich im Voraus in Rechnung, wobei die Rechnungen auch alle einmaligen Kosten und Ausgaben enthalten, die seit der letzten Rechnung angefallen sind. Der Kunde hat alle Rechnungen des Unternehmens innerhalb von 30 Tagen nach Rechnungsdatum zu bezahlen. Ist der Kunde mit der Zahlung eines Teils einer Rechnung im Verzug, kann das Unternehmen zusätzlich zu allen anderen Rechtsmitteln, die ihm zur Verfügung stehen, einschließlich der Kündigung, den Zugang zum",
      systemProvision: "System und/oder Erbringung aller Dienstleistungen für den Kunden",
      systemProvisionDesc:
        "Der Kunde erklärt sich damit einverstanden, für überfällige Beträge Verzugszinsen in Höhe von 1½ % pro Monat (oder, falls niedriger, den gesetzlich zulässigen Höchstbetrag) zu zahlen.",
      taxes: "Steuern",
      taxesDesc:
        "Der Kunde zahlt oder erstattet dem Unternehmen alle Umsatzsteuern und sonstigen Steuern, die von den Steuerbehörden auf der Grundlage der Lizenzgebühren oder sonstiger Gebühren im Rahmen dieses Vertrags oder aufgrund der Nutzung des Systems durch den Kunden erhoben werden, mit Ausnahme von Steuern, die auf dem Nettoeinkommen oder den Bruttoeinnahmen des Unternehmens beruhen, oder von Franchise- oder Verbrauchssteuern, die das Unternehmen schuldet. Wenn der Kunde eine steuerbefreite Organisation ist, wird das Unternehmen dem Kunden keine Steuern in Rechnung stellen, von denen der Kunde befreit ist, sobald es einen Nachweis über diesen Status erhält..",
      pricingChanges: "Änderungen der Preisgestaltung",
      pricingChangesDesc:
        "Kunden, die sich für eine jährliche Preisgestaltung entscheiden, werden mindestens 45 Tage vor jedem Jahrestag des Inkrafttretens über Preisänderungen informiert. Kunden, die sich für eine monatliche Preisgestaltung entscheiden, erhalten eine Mitteilung über Preisänderungen mindestens 45 Tage vor dem Monat, in dem die Preisänderung in Kraft tritt.",
      term: "Bezeichnung",
      termDesc:
        "Die Laufzeit dieser Vereinbarung beginnt mit dem Datum des Inkrafttretens dieser Vereinbarung. Wenn der Kunde einen jährlichen Preisplan gewählt hat, läuft die Laufzeit bis zum einjährigen Jahrestag des Datums des Inkrafttretens und verlängert sich automatisch um jeweils ein weiteres Jahr, es sei denn, eine der Parteien teilt der anderen Partei mindestens 30 Tage vor Ablauf der aktuellen Laufzeit schriftlich mit, dass sie keine Verlängerung wünscht. Wenn der Kunde einen monatlichen Preisplan gewählt hat, läuft dieser Vertrag weiter, bis eine Partei die andere Partei mindestens 30 Tage im Voraus über ihre Absicht informiert, ihn zu kündigen.",
      terminationforCause: "Kündigung aus wichtigem Grund",
      terminationforCauseDesc:
        "Jede Vertragspartei kann diese Vereinbarung aus wichtigem Grund durch schriftliche Mitteilung an die andere Vertragspartei kündigen:",
      tfcNestOneDesc:
        "wenn eine Partei der anderen Partei nicht innerhalb von 10 Tagen nach schriftlicher Aufforderung durch die andere Partei, in der die geschuldeten Beträge aufgeführt sind, die ausstehenden Beträge zahlt; im Falle des Unternehmens unverzüglich nach einem Verstoß des Kunden gegen Abschnitt 2(b) und/oder Abschnitt 2(e) oben;",
      tfcNestTwoDesc:
        "unverzüglich zu unterrichten, wenn die andere Partei die ihr gegenüber bestehenden Geheimhaltungspflichten verletzt;",
      tfcNestThreeDesc:
        "wenn die andere Partei eine andere wesentliche Verletzung ihrer Verpflichtungen aus diesem Vertrag begangen hat und diese Verletzung nicht innerhalb von 30 Tagen nach einer schriftlichen Mitteilung der nicht verletzenden Partei, in der die Art der Verletzung in angemessener Weise dargelegt wird, behoben hat (oder, falls eine solche Verletzung nicht in angemessener Weise innerhalb von 30 Tagen behoben werden kann, nicht begonnen und fortgesetzt hat, gewissenhaft und in gutem Glauben an der Behebung dieser Verletzung zu arbeiten); oder",
      tfcNestFourDesc:
        "wenn gegen die andere Partei ein Konkursverfahren oder ein Insolvenzverfahren nach staatlichem Recht eröffnet wird, sofern dieses Verfahren nicht innerhalb von 30 Tagen nach seiner Eröffnung eingestellt wird.",
      obligationsUponTermination: "Verpflichtungen bei Beendigung",
      obligationsUponTerminationDesc: "Verpflichtungen bei Beendigung. Bei Beendigung dieser Vereinbarung:",
      outNestOneDesc:
        "vorausgesetzt, der Kunde hat alle dem Unternehmen nach diesem Vertrag geschuldeten Beträge gezahlt, gewährt das Unternehmen auf schriftlichen Antrag, der innerhalb von 30 Tagen nach der Kündigung eingeht, jedem Kunden, der Zugangsrechte zu einer gebührenpflichtigen Edition erworben hat, für einen Zeitraum von 24 Stunden Zugang zum System, und zwar ausschließlich zum Zweck des Exports von Kundendaten;",
      outNestTwoDesc: "wird das Unternehmen den Zugang des Kunden zum System sofort beenden; und",
      outNestThreeDesc:
        "Der Kunde ist verpflichtet, dem Unternehmen unverzüglich alle fälligen oder aufgelaufenen, aber noch nicht fälligen Beträge zu zahlen, einschließlich aller gestundeten oder ursprünglich im Laufe der Zeit zu leistenden Zahlungen.",
      confidentialInformation: "Vertrauliche Informationen",
      confidentialInformationDesc:
        "'Vertrauliche Informationen' sind alle materiellen und immateriellen (schriftlichen oder anderweitig aufgezeichneten oder mündlichen) Informationen einer Partei, die: (A) einen unabhängigen, tatsächlichen oder potenziellen wirtschaftlichen Wert haben, der sich daraus ergibt, dass sie anderen Personen, die aus ihrer Offenlegung oder Nutzung einen wirtschaftlichen Nutzen ziehen können, nicht allgemein bekannt sind und von ihnen nicht ohne weiteres mit angemessenen Mitteln ermittelt werden können, und die Gegenstand von unter den gegebenen Umständen angemessenen Bemühungen um ihre Geheimhaltung sind; oder (B) von der offenlegenden Partei als vertraulich bezeichnet werden oder angesichts der Art der Informationen oder der Umstände ihrer Offenlegung vernünftigerweise als vertraulich angesehen werden sollten. Zu den vertraulichen Informationen gehören unter anderem: (i) nichtöffentliche Informationen in Bezug auf die Technologie, Kunden, Geschäftspläne, Werbe- und Marketingaktivitäten, Finanzen und andere geschäftliche Angelegenheiten einer Partei; (ii) Informationen Dritter, zu deren Geheimhaltung der Kunde oder das Unternehmen verpflichtet ist; (iii) die wesentlichen Bedingungen dieser Vereinbarung; und (iv) alle nichtöffentlichen Informationen in Bezug auf die im Rahmen dieser Vereinbarung durchgeführten Aktivitäten.",
      exclusions: "Ausschlüsse",
      exclusionsDesc:
        "Ungeachtet des Vorstehenden umfasst der Begriff 'vertrauliche Informationen' keine Informationen, die entweder: aus öffentlich zugänglichen Produkten oder Unterlagen ohne weiteres erkennbar sind oder deren Offenlegung durch eine vorherige schriftliche Genehmigung eines leitenden Angestellten der offenlegenden Partei genehmigt wurde.",
      useOfCi: "Verwendung von vertraulichen Informationen",
      useOfCiDesc:
        "Jede Partei darf vertrauliche Informationen, die ihr im Rahmen dieser Vereinbarung zur Verfügung gestellt werden, nur zur Förderung der in dieser Vereinbarung vorgesehenen Aktivitäten verwenden,",
      useOfCiExceptionDesc:
        "außer in den Fällen, in denen sie in dieser Vereinbarung dazu ermächtigt ist, die vertraulichen Informationen ohne ausdrückliche schriftliche Genehmigung der offenlegenden Partei nicht an andere Personen weiterzugeben.",
      requiredDisclosures: "Erforderliche Angaben",
      requiredDisclosuresDesc:
        "Eine empfangende Partei darf vertrauliche Informationen der offenlegenden Partei offenlegen, wenn dies erforderlich ist, um verbindlichen Anordnungen von staatlichen Stellen, die für sie zuständig sind, nachzukommen, oder wenn dies anderweitig gesetzlich vorgeschrieben ist, vorausgesetzt, dass die empfangende Partei (i) die offenlegende Partei in angemessener Weise schriftlich benachrichtigt, um ihr die Möglichkeit zu geben, eine Schutzanordnung oder andere geeignete Abhilfemaßnahmen zu erwirken (es sei denn, die Einhaltung des Vorstehenden würde dazu führen, dass die empfangende Partei eine gerichtliche Anordnung oder andere gesetzliche Vorschriften verletzt), (ii) nur solche Informationen offenlegt, die von der staatlichen Stelle verlangt werden oder anderweitig gesetzlich vorgeschrieben sind, und (iii) sich nach besten Kräften bemüht, eine vertrauliche Behandlung für alle auf diese Weise offengelegten vertraulichen Informationen zu erreichen.",
      returnOfInformation: "Rückgabe von Informationen",
      returnOfInformationDesc:
        "Sofern in den besonderen Bestimmungen zu Kundendaten in Abschnitt 5(c) oben nichts anderes festgelegt ist, hat die empfangende Partei auf Verlangen der offenlegenden Partei alle Kopien, Auszüge oder sonstigen Vervielfältigungen der in ihrem Besitz befindlichen vertraulichen Informationen, ganz oder teilweise, unverzüglich zurückzugeben.",
      survival: "Überleben",
      survivalDesc:
        "Die Vertragsparteien vereinbaren, dass dieser Abschnitt 6 den Ablauf, die Beendigung oder die Kündigung dieser Vereinbarung für einen Zeitraum von drei Jahren überdauert, mit Ausnahme von vertraulichen Informationen, die ein Geschäftsgeheimnis darstellen, für die dieser Abschnitt den Ablauf, die Beendigung oder die Kündigung dieser Vereinbarung so lange überdauert, wie diese vertraulichen Informationen ein Geschäftsgeheimnis bleiben.",
      indemnificationByCompany: "Entschädigung durch das Unternehmen",
      indemnificationByCompanyDesc:
        "FÜR KUNDEN, DIE NUR EINE KOSTENLOSE AUSGABE DES SYSTEMS NUTZEN, GILT DIESER ABSCHNITT 7(A) NICHT UND SIE ERKENNEN AN, DASS SIE KEINEN ANSPRUCH AUF EINE ENTSCHÄDIGUNG DURCH DAS UNTERNEHMEN HABEN. Für Kunden, die eine Edition des Systems im Rahmen eines monatlichen oder jährlichen Zahlungsplans nutzen, verteidigt, entschädigt und hält das Unternehmen den Kunden schadlos gegen alle Schäden, Haftungen, Verluste und Ausgaben, einschließlich angemessener Anwaltsgebühren und -kosten, die sich aus Ansprüchen Dritter, Klagen oder Verfahren ergeben, die sich aus der Nutzung des Systems durch den Kunden und/oder die Benutzer des Kunden gemäß diesem Vertrag ergeben und die nach Wissen des Unternehmens ein US-Geschäftsgeheimnis, eine Marke oder ein Urheberrecht verletzen oder missbrauchen. Das Unternehmen hat keine Entschädigungsverpflichtung gegenüber dem Kunden, wenn die angebliche Verletzung oder widerrechtliche Aneignung auf (i) einer Kombination, einem Betrieb oder einer Nutzung des Systems mit Produkten, Dienstleistungen, Informationen, Materialien, Technologien, Geschäftsmethoden oder Prozessen beruht, die nicht vom Unternehmen bereitgestellt wurden, soweit die Verletzung oder widerrechtliche Aneignung auf einer solchen Kombination, einem Betrieb oder einer solchen Nutzung beruht; (ii) jede Änderung (außer durch das Unternehmen) des Systems, soweit die Verletzung oder widerrechtliche Aneignung auf einer solchen Änderung beruht; oder (iii) das Versäumnis des Kunden, ein vom Unternehmen bereitgestelltes Update, das die tatsächliche oder angebliche Verletzung oder widerrechtliche Aneignung beseitigt hätte, unverzüglich zu installieren.",
      indemnificationByCustomer: "Entschädigung durch den Kunden",
      indemnificationByCustomerDesc:
        "Der Kunde verpflichtet sich, das Unternehmen zu verteidigen, freizustellen und schadlos zu halten von und gegen alle Schäden, Haftungen, Verluste und Ausgaben, einschließlich angemessener Anwaltsgebühren und -ausgaben, die sich aus Ansprüchen Dritter, Klagen oder Verfahren ergeben, die sich aus der Nutzung des Systems durch den Kunden und/oder die Benutzer des Kunden ergeben (außer in dem Umfang, in dem das Unternehmen gemäß Abschnitt 7(a) entschädigt wird, oder, im Falle eines Kunden, der nur eine kostenlose Version des Systems nutzt, außer in dem Umfang, in dem der Anspruch Dritter der Entschädigung durch das Unternehmen gemäß Abschnitt 7(a) unterliegen würde, wenn Abschnitt 7(a) auf den Kunden anwendbar wäre).",
      indemnificationProcess: "Entschädigungsverfahren",
      indemnificationProcessDesc:
        "Die entschädigte Partei hat die entschädigende Partei unverzüglich schriftlich über jeden Anspruch eines Dritten zu unterrichten und dabei die Art und die Grundlage des Anspruchs des Dritten, soweit bekannt, anzugeben. Die entschädigende Partei hat die alleinige Kontrolle über die Verteidigung und Beilegung von Ansprüchen Dritter, vorausgesetzt, die entschädigende Partei teilt der entschädigten Partei innerhalb von fünfzehn (15) Tagen nach Erhalt der oben beschriebenen Mitteilung mit, dass sie die volle Kontrolle übernehmen will. Ungeachtet des Vorstehenden ist die freigestellte Partei berechtigt, sich an der Verteidigung eines solchen Anspruchs eines Dritten zu beteiligen und auf eigene Kosten einen Rechtsbeistand zu beauftragen, der sie bei der Bearbeitung eines solchen Anspruchs unterstützt, wobei die Rechtskosten der freigestellten Partei bei der Ausübung dieses Rechts als Rechtskosten gelten, die nach diesem Vertrag entschädigungspflichtig sind, sofern (x) die freistellende Partei es versäumt oder sich weigert, die Kontrolle über die Verteidigung des Anspruchs eines Dritten innerhalb der oben genannten Frist zu übernehmen; (y) die entschädigte Partei es für angemessen hält, eine Antwort einzureichen oder ähnliche Maßnahmen zu ergreifen, um den Erlass eines Versäumnisurteils, einer einstweiligen Verfügung oder einer einstweiligen Verfügung gegen sie zu verhindern; oder (z) die Vertretung beider Parteien durch denselben Rechtsbeistand nach Ansicht dieses Rechtsbeistands einen Interessenkonflikt darstellen würde. Die entschädigende Partei darf solche Ansprüche Dritter nicht ohne die schriftliche Zustimmung der entschädigten Partei regeln, es sei denn, es handelt sich um eine vollständige Regelung, die nur die Zahlung von Schadensersatz durch die entschädigende Partei vorsieht.",
      soleRemedy: "Einzige Abhilfe",
      soleRemedyDesc:
        "Die Entschädigung gemäß diesem Abschnitt ist der einzige Rechtsbehelf der Parteien bei Ansprüchen Dritter gegen die andere Partei wegen Verletzung oder Veruntreuung von geistigem Eigentum.",
      serviceLevelCommitmentsCredits: "Dienstleistungsverpflichtungen; Kredite",
      serviceLevelCommitmentsCreditsDesc:
        "Das Unternehmen garantiert, dass das System und alle auf dem System bereitgestellten Dienste für die autorisierten Benutzer des Kunden 99,5 % der Zeit in einem bestimmten Kalendermonat zugänglich sind, mit Ausnahme von Wartungsfenstern für alle bezahlten Editionen. Ungeachtet des Vorstehenden garantiert das Unternehmen nicht die Netzwerkverfügbarkeit zwischen dem Kunden und den Hosting-Servern des Unternehmens, da an dieser Verfügbarkeit zahlreiche Dritte beteiligt sein können und sie außerhalb der Kontrolle des Unternehmens liegt. Das Unternehmen haftet weder für Ausfallzeiten, die ganz oder teilweise von einem Drittanbieter von Rechenzentren verursacht werden, noch für Ausfallzeiten, die der Kunde aufgrund von Problemen mit der Netzwerkkonnektivität des Kunden oder der Benutzer des Kunden erleidet, und gewährt auch keine Servicegutschriften im Rahmen dieser Vereinbarung. Wenn der Kunde von einem System- oder Serviceausfall betroffen ist und nicht auf das System oder einen Service zugreifen kann, muss sich der Kunde unverzüglich mit dem Helpdesk des Unternehmens in Verbindung setzen und alle erforderlichen Informationen bereitstellen, die dem Unternehmen bei der Ermittlung der Ursache des Ausfalls helfen können. Das Unternehmen wird nach bestem Wissen und Gewissen prüfen, ob der Ausfall im Einflussbereich des Unternehmens lag. Wenn das Unternehmen feststellt, dass ein rechtzeitig gemeldeter Ausfall dem Unternehmen zuzuschreiben ist, dann wird das Unternehmen",
      creditCustomerDesc:
        "dem Kunden für jede 2 Stunden Ausfallzeit, die der Kunde erlebt hat, 1 Tag der Servicegebühren gutschreiben, bis zu einem Maximum von der Hälfte der Servicegebühren für diesen Monat. Dies ist das einzige Rechtsmittel des Kunden und die einzige Haftung des Unternehmens für das Versäumnis des Unternehmens, die in diesem Abschnitt 8(a) festgelegte garantierte Verfügbarkeit zu gewährleisten.",
      disclaimerOfWarranties: "Ausschluss von Garantien",
      disclaimerOfWarrantiesDesc:
        "MIT AUSNAHME DER IN ABSCHNITT 8(A) DARGELEGTEN BEGRENZTEN SERVICELEVEL-ZUSAGEN UND SOWEIT GESETZLICH ZULÄSSIG, GIBT DAS UNTERNEHMEN KEINE AUSDRÜCKLICHEN ODER STILLSCHWEIGENDEN ZUSICHERUNGEN ODER GARANTIEN JEGLICHER ART IN BEZUG AUF DAS SYSTEM, DIE BEREITGESTELLTEN DIENSTE ODER DIE VERFÜGBARKEIT, FUNKTIONALITÄT, LEISTUNG ODER ERGEBNISSE DER NUTZUNG DES SYSTEMS UND LEHNT DIESE HIERMIT AB. OHNE EINSCHRÄNKUNG DES VORSTEHENDEN, MIT AUSNAHME DER IN ABSCHNITT 8(A) AUFGEFÜHRTEN BESCHRÄNKTEN DIENSTLEISTUNGSVERPFLICHTUNGEN, LEHNT DAS UNTERNEHMEN JEGLICHE GARANTIE AB, DASS DAS SYSTEM, DIE VOM UNTERNEHMEN ERBRACHTEN DIENSTLEISTUNGEN ODER DER BETRIEB DES SYSTEMS GENAU, FEHLERFREI ODER UNUNTERBROCHEN SIND ODER SEIN WERDEN. DAS UNTERNEHMEN ÜBERNIMMT KEINE STILLSCHWEIGENDE GARANTIE UND LEHNT HIERMIT JEGLICHE STILLSCHWEIGENDE GARANTIE AB, EINSCHLIESSLICH UND OHNE EINSCHRÄNKUNG JEGLICHER STILLSCHWEIGENDEN GARANTIE DER NICHTVERLETZUNG VON RECHTEN, DER HANDELSÜBLICHEN QUALITÄT, DER EIGNUNG FÜR EINEN BESTIMMTEN ZWECK ODER EINER GARANTIE, DIE SICH AUS DEM HANDELSBRAUCH, DEM HANDELSVERLAUF ODER DEM LEISTUNGSVERLAUF ERGIBT.",
      disclaimerOfConsequentialDamages: "Ausschluss von Folgeschäden",
      disclaimerOfConsequentialDamagesDesc:
        "SOWEIT NACH GELTENDEM RECHT ZULÄSSIG, HAFTET DAS UNTERNEHMEN IN BEZUG AUF DAS SYSTEM, DIE DIENSTLEISTUNGEN ODER SEINE SONSTIGEN VERPFLICHTUNGEN IM RAHMEN DIESES VERTRAGS ODER ANDERWEITIG NICHT FÜR FOLGESCHÄDEN, EXEMPLARISCHE SCHÄDEN, BESONDERE SCHÄDEN, BEILÄUFIG ENTSTANDENE SCHÄDEN ODER SCHADENSERSATZ MIT STRAFCHARAKTER (EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF ENTGANGENE GEWINNE UND DECKUNGSKOSTEN), SELBST WENN DAS UNTERNEHMEN AUF DIE MÖGLICHKEIT SOLCHER SCHÄDEN HINGEWIESEN WURDE.",
      limitationsOfRemediesAndLiability: "Beschränkungen von Rechtsmitteln und Haftung",
      limitationsOfRemediesAndLiabilityDesc:
        "SOWEIT NACH GELTENDEM RECHT ZULÄSSIG, IST DIE GESAMTE HAFTUNG DES UNTERNEHMENS GEGENÜBER DEM KUNDEN AUS JEGLICHEM GRUND UND JEGLICHEM KLAGEGRUND, EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF VERTRAGSBRUCH, FAHRLÄSSIGKEIT, VERSCHULDENSUNABHÄNGIGE HAFTUNG, FALSCHE DARSTELLUNGEN UND ANDERE UNERLAUBTE HANDLUNGEN, BESCHRÄNKT AUF (1) IM FALLE EINES KUNDEN MIT EINEM MONATLICHEN ZAHLUNGSPLAN AUF ALLE GEBÜHREN, DIE DER KUNDE DEM UNTERNEHMEN IN BEZUG AUF BENUTZERLIZENZEN FÜR DAS SYSTEM WÄHREND DER DREI MONATE, DIE DEN EREIGNISSEN, DIE ZU DIESER HAFTUNG FÜHREN, UNMITTELBAR VORAUSGEHEN, GEZAHLT HAT; (2) IM FALLE EINES KUNDEN MIT EINEM JÄHRLICHEN ZAHLUNGSPLAN ALLE GEBÜHREN, DIE DER KUNDE AN DAS UNTERNEHMEN IN BEZUG AUF BENUTZERLIZENZEN FÜR DAS SYSTEM WÄHREND DER ZWÖLF MONATE UNMITTELBAR VOR DEN EREIGNISSEN, DIE DIE HAFTUNG BEGRÜNDEN, GEZAHLT HAT; ODER (3) IM FALLE EINES KUNDEN, DER NUR EINE KOSTENLOSE VERSION DES SYSTEMS VERWENDET, EINHUNDERT US-DOLLAR ($100. 00).",
      notices: "Notizen",
      noticesDesc:
        "Mitteilungen zu diesem Vertrag an das Unternehmen müssen schriftlich erfolgen und per First-Class-Post oder Overnight-Kurier an die zu diesem Zeitpunkt auf der Website des Unternehmens angegebene Adresse geschickt werden.",
      noticesDisclaimerDesc:
        "Das Unternehmen kann Mitteilungen durch Veröffentlichung im System, per E-Mail an die beim Unternehmen hinterlegte E-Mail-Adresse des Kunden oder durch schriftliche Mitteilung per First-Class-Post oder Übernacht-Kurier an die in den Kontodaten des Unternehmens hinterlegte Adresse des Kunden übermitteln. Alle Mitteilungen gelten drei Tage nach dem Versand oder der Aufgabe zur Post (bei Versand per First-Class-Post), bei Zustellung durch einen Kurierdienst oder 12 Stunden nach dem Versand per E-Mail oder der Veröffentlichung im System als zugegangen.",
      forceMajeure: "Höhere Gewalt",
      forceMajeureDesc:
        "'Höhere Gewalt' bedeutet jede Handlung oder jedes Ereignis, das (a) eine Partei (die 'nicht erfüllende Partei') daran hindert, ihren Verpflichtungen nachzukommen oder eine Bedingung für die Verpflichtungen der anderen Partei (der 'erfüllenden Partei') im Rahmen dieser Vereinbarung zu erfüllen, (b) außerhalb der zumutbaren Kontrolle der nicht erfüllenden Partei liegt und nicht von ihr verschuldet wurde und (c) das die nicht erfüllende Partei nicht durch wirtschaftlich angemessene Anstrengungen vermeiden oder überwinden konnte. Der Begriff 'Höhere Gewalt' umfasst keine wirtschaftlichen Härten, Änderungen der Marktbedingungen und unzureichende Mittel. Tritt ein Ereignis höherer Gewalt ein, so ist die leistungsstörende Partei von der dadurch verhinderten Leistung und von der Erfüllung der aufschiebenden Bedingungen für die Leistung der anderen Partei, die nicht erfüllt werden kann, befreit, und zwar jeweils in dem Umfang, in dem das Ereignis höherer Gewalt die Leistung beschränkt oder verhindert. Sobald die nicht erfüllende Partei in der Lage ist, ihre Leistungen wieder aufzunehmen oder die aufschiebenden Bedingungen für die Verpflichtungen der anderen Partei zu erfüllen, muss die nicht erfüllende Partei die Leistungen im Rahmen dieses Abkommens unverzüglich wieder aufnehmen. Die in diesem Absatz vorgesehene Abhilfe ist der einzige Rechtsbehelf, der der erfüllenden Partei in Bezug auf ein Ereignis höherer Gewalt zur Verfügung steht.",
      assignment: "Zuordnung",
      assignmentDesc:
        "Das Unternehmen ist berechtigt, seine Rechte und Pflichten aus diesem Vertrag jederzeit abzutreten, jedoch mit der Maßgabe, dass das Unternehmen die in Abschnitt 2(d) gewährten Rechte an den Kundendaten nicht abtreten darf, es sei denn, dies geschieht im Zusammenhang mit dem Verkauf (sei es durch Fusion, Veräußerung von Vermögenswerten, Verkauf von Aktien oder auf andere Weise) von (i) dem Unternehmen, (ii) dem System oder (iii) einem Teil des Unternehmens oder des Systems, der es vernünftigerweise erforderlich machen würde, dass der Erwerber des genannten Teils die Rechte an den Kundendaten übertragen bekommt. Der Kunde darf seine Rechte aus diesem Vertrag nur mit vorheriger schriftlicher Zustimmung des Unternehmens abtreten, die nicht unbillig verweigert werden darf. Der vorstehende Satz gilt für alle Abtretungen von Rechten, es sei denn, es handelt sich um eine freiwillige Übertragung von im Wesentlichen allen Vermögenswerten durch den Kunden an einen Erwerber, der das Vertragsformular des Unternehmens ausfüllt und sich damit bereit erklärt, an alle Bestimmungen und Bedingungen dieses Vertrags gebunden zu sein. Jeder Kontrollwechsel gilt als Abtretung im Sinne dieser Vereinbarung. Jede vermeintliche Abtretung von Rechten, die gegen diesen Abschnitt verstößt, ist nichtig.",
      governingLawVenue: "Geltendes Recht; Gerichtsstand",
      governingLawVenueDesc:
        "Die Gesetze des Staates Illinois in den Vereinigten Staaten von Amerika (unter Ausschluss der Grundsätze des Kollisionsrechts) regeln alle Angelegenheiten, die sich aus dieser Vereinbarung und den darin vorgesehenen Transaktionen ergeben oder damit in Zusammenhang stehen, einschließlich, aber nicht beschränkt auf ihre Auslegung, Konstruktion, Erfüllung und Durchsetzung. Jegliche Ansprüche oder Klagen, die sich aus dieser Vereinbarung ergeben, müssen ausschließlich vor einem zuständigen Gericht mit Sitz in Delaware erhoben werden, und jede Partei dieser Vereinbarung unterwirft sich der Zuständigkeit dieser Gerichte für alle Klagen und Verfahren, die sich aus dieser Vereinbarung ergeben oder mit ihr in Zusammenhang stehen. Jede Partei verzichtet im größtmöglichen gesetzlich zulässigen Umfang auf alle Einwände, die sie jetzt oder später gegen (i) die Festlegung des Gerichtsstandes für alle Klagen oder Verfahren, die sich aus dieser Vereinbarung ergeben oder mit ihr in Zusammenhang stehen und vor einem einzelstaatlichen oder bundesstaatlichen Gericht mit Sitz in Delaware erhoben werden; und (ii) alle",
      claimThatDesc:
        "die Behauptung, dass eine Klage oder ein Verfahren vor einem solchen Gericht an einem ungünstigen Gerichtsstand erhoben wurde.",
      recoveryOfLitigationCosts: "Rückforderung von Prozesskosten",
      recoveryOfLitigationCostsDesc:
        "Wird ein gerichtliches oder sonstiges Verfahren zur Durchsetzung dieser Vereinbarung oder wegen eines angeblichen Streits, Verstoßes, einer Nichterfüllung oder einer Falschdarstellung im Zusammenhang mit einer der Bestimmungen dieser Vereinbarung eingeleitet, so hat die unterlegene Partei der obsiegenden Partei ihre angemessenen Anwaltsgebühren und sonstigen Kosten zu erstatten, die ihr im Zusammenhang mit dieser Klage oder diesem Verfahren entstanden sind, und zwar zusätzlich zu allen anderen Entschädigungen, auf die die obsiegende Partei möglicherweise Anspruch hat.",
      entireAgreement: "Gesamte Vereinbarung",
      entireAgreementDesc:
        "Die vorliegende Vereinbarung stellt die endgültige Vereinbarung zwischen den Parteien dar. Sie ist der vollständige und ausschließliche Ausdruck der Vereinbarung der Parteien über die in dieser Vereinbarung enthaltenen Angelegenheiten. Alle früheren und gleichzeitigen Verhandlungen und Vereinbarungen zwischen den Parteien über die in dieser Vereinbarung enthaltenen Angelegenheiten werden ausdrücklich in diese Vereinbarung aufgenommen und durch sie ersetzt. Die Bestimmungen dieser Vereinbarung können nicht durch den Nachweis von Handelsbräuchen oder früheren Geschäftsvorgängen erklärt, ergänzt oder eingeschränkt werden. Beim Abschluss dieser Vereinbarung hat sich keine Partei auf Erklärungen, Zusicherungen, Garantien oder Vereinbarungen einer anderen Partei verlassen, mit Ausnahme derjenigen, die ausdrücklich in dieser Vereinbarung enthalten sind. Es gibt keine aufschiebenden Bedingungen für die Wirksamkeit dieser Vereinbarung, außer denen, die ausdrücklich in dieser Vereinbarung genannt sind.",
      amendments: "Änderungsanträge",
      amendmentsDesc:
        "Die Parteien können diese Vereinbarung nur durch eine schriftliche Vereinbarung der Parteien ändern, die sich als Änderung dieser Vereinbarung ausweist.",
      survivalOfCertainProvisions: "Fortbestehen bestimmter Bestimmungen",
      survivalOfCertainProvisionsDesc:
        "Die Vertragsparteien verpflichten sich und erklären sich damit einverstanden, dass die Bestimmungen in den Abschnitten 1, 2(b), 5(c), 6, 7, 8 und 9 zusätzlich zu jeder anderen Bestimmung, die nach ihrem Wortlaut den Ablauf oder die Beendigung dieses Abkommens überdauern soll, den Ablauf oder die Beendigung dieses Abkommens überdauern sollen.",
    },
  },
};
