import { useEffect, useState } from "react";

export const getLocalStorageData = <T = any>(key: string) => {
  const currentValue = window.localStorage.getItem(key);
  return currentValue !== null ? (JSON.parse(currentValue) as T) : undefined;
};

export const setLocalStorageData = <T>(key: string, value: T) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const useLocalStorage = <T = any>(
  defaultValue: T,
  key: string,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState(() => getLocalStorageData(key) ?? defaultValue);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const newValue = getLocalStorageData(key);
    setValue(newValue);
    window.localStorage.setItem(key, JSON.stringify(newValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [value, setValue];
};
