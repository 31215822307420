import { FormContext } from "./FormContext";
import { FormArgs, TFormContext } from "./types";
import { useForm } from "./useForm";

export const FormProvider: React.FC<React.PropsWithChildren<{ form: TFormContext<any> }>> = ({ children, form }) => (
  <FormContext.Provider value={form}>{children}</FormContext.Provider>
);

export const Form: React.FC<FormArgs<any>> = (props) => {
  const form = useForm(props);
  return <FormProvider form={form}>{props.children}</FormProvider>;
};
