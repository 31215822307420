import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const LossPrevention = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="lossPrevention" subtitle="report.lossPreventionDescription" />
);
