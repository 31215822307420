import { getPath, isEmpty, setDeep, unset } from "common/helpers";

import { getGetterByPath } from "../getGetterByPath";

import { FormData } from "./getRef";

type CustomError = string | ((value: any) => string);
type MessageTuple<T> = NonNullable<T> | [NonNullable<T>] | [NonNullable<T>, CustomError];
type WithMessageTuple<T> = { [K in keyof T]: MessageTuple<T[K]> };

type EnrichValidator<T extends Validator> = Omit<
  T extends TupleValidator
    ? WithMessageTuple<ValidatorWithReference<Omit<T, "of">>> & { of?: WithReference<T["of"]> }
    : T extends TupleValidator
    ? WithMessageTuple<ValidatorWithReference<Omit<T, "of">>> & { of?: WithReference<T["of"]> }
    : T extends ObjectValidator
    ? WithMessageTuple<ValidatorWithReference<Omit<T, "shape">>> & { shape?: WithReference<T["shape"]> }
    : WithMessageTuple<ValidatorWithReference<T>>,
  "type" | "typeErr"
> &
  Pick<T, "type" | "typeErr">;

type WithReference<T> = T | ((form: FormData) => T);
type WithReferenceObject<T> = { [K in keyof T]: WithReference<T[K]> };

type Validator =
  | StringValidator
  | NumberValidator
  | BooleanValidator
  | ArrayValidator
  | TupleValidator
  | ObjectValidator;

export type LazyValidator = (value: any, formData: FormData, fieldName?: string) => ValidatorRich | null | string;

export type ValidatorRich =
  | EnrichValidator<StringValidator>
  | EnrichValidator<NumberValidator>
  | EnrichValidator<BooleanValidator>
  | EnrichValidator<ArrayValidator>
  | EnrichValidator<TupleValidator>
  | EnrichValidator<ObjectValidator>;

export type ValidateField = ValidatorRich | LazyValidator;

type ValidatorWithReference<T extends Validator> = WithReferenceObject<Omit<T, "type" | "custom">> &
  Pick<T, "type" | "custom">;

type CommonValidators = {
  required?: true;
  custom?: (value: any, formData: FormData) => null | boolean | string;
  typeErr?: CustomError;
};

type StringValidator = CommonValidators & {
  type: "string";
  length?: number;
  minLength?: number;
  maxLength?: number;
  equalTo?: string | string[];
  notEqualTo?: string | string[];
  pattern?: RegExp | string;
};

type NumberValidator = CommonValidators & {
  type: "number";
  min?: number;
  max?: number;
  equalTo?: number | number[];
  notEqualTo?: number | number[];
};

type BooleanValidator = CommonValidators & {
  type: "boolean";
  equalTo?: boolean;
  notEqualTo?: boolean;
};

type ArrayValidator = CommonValidators & {
  type: "array";
  empty?: false;
  length?: number;
  minLength?: number;
  maxLength?: number;
  of?: Validator;
};

type TupleValidator = CommonValidators & {
  type: "tuple";
  empty?: false;
  of?: Validator[];
};

type ObjectValidator = CommonValidators & {
  type: "object";
  shape?: Record<string, Validator>;
};

type WithTypeGen = {
  type: (value: any) => boolean;
};

type WithRequired = {
  required: (value: any) => boolean;
};

type ValidatorFns<T> = {
  [K in keyof Omit<Required<T>, "type" | "custom" | "typeErr">]: (a: NonNullable<T[K]>, value: any) => boolean;
} & WithTypeGen &
  WithRequired;

type ErrorFns<T> = {
  [K in keyof Omit<Required<T>, "custom" | "typeErr">]: (a: NonNullable<T[K]>) => string;
};

const required: WithRequired["required"] = (value) => value !== undefined && value !== null;

const withDefaultMessage = <T>(a: MessageTuple<T>, value: any): string | false =>
  Array.isArray(a) && a.length === 2 ? (typeof a[1] === "function" ? a[1](value) : a[1] || false) : false;

const errorOrNull = (v: string | true) => (v === true ? null : v);

const requiredError = () => "common.form.err.required";

const stringValidator: ValidatorFns<StringValidator> = {
  type: (value) => typeof value === "string",
  equalTo: (checker, value) => (Array.isArray(checker) ? checker.includes(value) : checker === value),
  notEqualTo: (checker, value) => !stringValidator.equalTo(checker, value),
  length: (length, value) => value?.length === length,
  maxLength: (length, value) => value?.length <= length,
  minLength: (length, value) => value?.length >= length,
  pattern: (pattern, value) => (pattern instanceof RegExp ? pattern : new RegExp(pattern)).test(value),
  required,
};

const stringErrors: ErrorFns<WithMessageTuple<StringValidator>> = {
  type: () => "must be string",
  equalTo: (checker) => `must be equal to ${checker}`,
  notEqualTo: (checker) => `must not be equal to ${checker}`,
  length: (checker) => `length must be equal to ${checker}`,
  maxLength: (checker) => `max length must be equal to ${checker}`,
  minLength: (checker) => `min length must be equal to ${checker}`,
  pattern: (checker) => `pattern not match ${checker}`,
  required: requiredError,
};

const getValidate =
  <T extends Validator>(
    validators: T extends TupleValidator | ArrayValidator
      ? ValidatorFns<Omit<T, "of">> & { of: any }
      : T extends ObjectValidator
      ? ValidatorFns<Omit<T, "shape">> & { shape: any }
      : ValidatorFns<T>,
    errors: ErrorFns<WithMessageTuple<T>>,
  ) =>
  (validator: EnrichValidator<T>, value: any, formData: FormData): string | null => {
    if (!required(value)) {
      if (
        "required" in validator &&
        (typeof validator.required === "function" ? validator.required(formData) : validator.required)
      ) {
        return errorOrNull(
          withDefaultMessage(validator.required as any, value) || errors.required(validator.required as any),
        );
      }

      return null;
    } else if (!validators.type(value)) {
      return typeof validator.typeErr === "function"
        ? validator.typeErr(value)
        : validator.typeErr || errors.type(value);
    } else if (validator.custom) {
      const [ch, err] = Array.isArray(validator.custom) ? validator.custom : [validator.custom];
      const error = ch(value, formData);
      if (error !== null && error !== true) return error || (typeof err === "function" ? err(value) : err) || null;
    } else {
      for (const key in validator) {
        const val = validator[key];

        if (key === "shape" || key === "of") {
          return errorOrNull(validators[key](val, value, formData));
        } else if (!["type", "required", "custom", "typeErr"].includes(key)) {
          const ch = Array.isArray(val) ? val[0] : val;

          const toCheck = typeof ch === "function" ? (ch as (form: FormData) => any)(formData) : ch;
          const res = validators[key](toCheck, value);

          if (!res) return errorOrNull(withDefaultMessage(val as any, value) || errors[key](toCheck));
        }
      }
    }

    return null;
  };

const validateString = getValidate(stringValidator, stringErrors);

const numberValidator: ValidatorFns<NumberValidator> = {
  type: (value) => typeof value === "number",
  equalTo: (checker, value) => (Array.isArray(checker) ? checker.includes(value) : checker === value),
  notEqualTo: (checker, value) => !numberValidator.equalTo(checker, value),
  min: (length, value) => value >= length,
  max: (length, value) => value <= length,
  required,
};

const numberErrors: ErrorFns<WithMessageTuple<NumberValidator>> = {
  type: () => "must be a number",
  equalTo: (checker) => `must be equal to ${checker}`,
  notEqualTo: (checker) => `must not be equal to ${checker}`,
  min: (checker) => `must be higher than ${checker}`,
  max: (checker) => `must be lower than ${checker}`,
  required: requiredError,
};

const validateNumber = getValidate(numberValidator, numberErrors);

const booleanValidator: ValidatorFns<BooleanValidator> = {
  type: (value) => typeof value === "boolean",
  equalTo: (checker, value) => checker === value,
  notEqualTo: (checker, value) => !booleanValidator.equalTo(checker, value),
  required,
};

const booleanErrors: ErrorFns<WithMessageTuple<BooleanValidator>> = {
  type: () => "must be a boolean",
  equalTo: (checker) => `must be equal to ${checker}`,
  notEqualTo: (checker) => `must not be equal to ${checker}`,
  required: requiredError,
};

const validateBoolean = getValidate(booleanValidator, booleanErrors);

const tupleValidator: ValidatorFns<Omit<TupleValidator, "of">> & {
  of: (c: Validator[], value: any, formData: FormData, fieldName: string) => (string | null)[] | null;
} = {
  type: (value) => Array.isArray(value),
  empty: (checker, value) => !checker && value?.length > 0,
  of: (checkers, value, formData, fieldName) => {
    const checks = checkers.map((checker, i) => validate(checker, value[i], formData, fieldName));
    return checks.filter((v) => v !== null).length === 0 ? null : checks;
  },

  required,
};

const tupleErrors: ErrorFns<WithMessageTuple<TupleValidator>> = {
  type: () => "must be a tuple",
  empty: () => "cannot be empty",
  of: (checker) => `must be of shape ${checker}`,
  required: requiredError,
};

const validateTuple = getValidate(tupleValidator, tupleErrors);

const arrayValidator: ValidatorFns<Omit<ArrayValidator, "of">> & {
  of: (c: Validator, value: any, formData: FormData, fieldName: string) => (string | null)[] | null;
} = {
  type: (value) => Array.isArray(value),
  empty: (checker, value) => !checker && value?.length > 0,
  length: (length, value) => value?.length === length,
  minLength: (length, value) => value?.length >= length,
  maxLength: (length, value) => value?.length <= length,
  of: (checker, value, formData, fieldName) => {
    const checks = value.map((v) => validate(checker, v, formData, fieldName));
    return checks.filter((v) => v !== null).length === 0 ? null : checks;
  },
  required,
};

const arrayErrors: ErrorFns<WithMessageTuple<ArrayValidator>> = {
  type: () => "must be an array",
  empty: () => "cannot be empty",
  length: (checker) => `length must be equal to ${checker}`,
  maxLength: (checker) => `max length must be equal to ${checker}`,
  minLength: (checker) => `min length must be equal to ${checker}`,
  of: (checker) => `must be of shape ${checker}`,
  required: requiredError,
};

const validateArray = getValidate(arrayValidator, arrayErrors);

const objectValidator: ValidatorFns<Omit<ObjectValidator, "shape">> & {
  shape: (c: Validator, value: any, formData: FormData, fieldName: string) => Record<string, boolean | string> | null;
} = {
  type: (value) => value && typeof value === "object",
  shape: (checker, value, formData, fieldName) => {
    const checks = Object.fromEntries(
      Object.entries(checker).flatMap(([k, v]) => {
        const res = validate(v, value[k], formData, fieldName);

        if (res === null) return [];
        return [[k, res]];
      }),
    );

    return isEmpty(checks) ? null : checks;
  },
  required,
};

const objectErrors: ErrorFns<WithMessageTuple<ObjectValidator>> = {
  type: () => "must be an object",
  shape: (checker) => `must be of shape ${checker}`,
  required: requiredError,
};

const validateObject = getValidate(objectValidator, objectErrors);

const validate = (_validator: ValidateField, value: any, formData: FormData, fieldName: string) => {
  const validator = typeof _validator === "function" ? _validator(value, formData, fieldName) : _validator;

  if (validator === null || typeof validator === "string") return validator;

  switch (validator.type) {
    case "string":
      return validateString(validator, value, formData);
    case "number":
      return validateNumber(validator, value, formData);
    case "boolean":
      return validateBoolean(validator, value, formData);
    case "tuple":
      return validateTuple(validator, value, formData);
    case "array":
      return validateArray(validator, value, formData);
    case "object":
      return validateObject(validator, value, formData);
  }
};

const validation = () => {
  const validationByField: Record<string, ValidateField> = {};

  const validateField = (field: string, state: any, nextValue?: any) =>
    validationByField[field]
      ? validate(validationByField[field] as any, nextValue ?? getGetterByPath(field)(state.values), state, field)
      : null;

  const validateAllFields = (state: any): Record<string, any> | undefined => {
    const flatErrors = Object.entries(validationByField).map(
      ([field, v]) =>
        [field, validate(v, getGetterByPath(field)(state.values), state, field)] as [string, string | null],
    );

    return flatErrors.length > 0
      ? flatErrors.reduce((acc, [path, e]) => {
          e === null ? unset(acc, getPath(path)) : (acc = setDeep(acc, getPath(path), e));
          return acc;
        }, {})
      : undefined;
  };

  const registerValidation = (field: string, validator: ValidateField) => {
    validationByField[field] = validator;
    return () => {
      delete validationByField[field];
    };
  };

  return {
    registerValidation,
    validateAllFields,
    validateField,
  };
};

export {
  validateString,
  validateNumber,
  validateBoolean,
  validateTuple,
  validateArray,
  validateObject,
  validate,
  validation,
};
