import { useCallback, useEffect, useRef, useState } from "react";
import create, { StoreApi, UseBoundStore } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

import { history } from "appRouting";
import { uniqueID } from "common/helpers";

import { ModalComponentProps } from "./ModalComponent";

type ModalID = string;

export type ComponentModalProps = { onClose?: () => void };

export type WrapperProps<T = unknown> = T & {
  id: ModalID;
  backdropClose?: boolean;
  requestClose: () => void;
};

type CommonModalData<T = unknown> = {
  Component: React.FC<ComponentModalProps> | React.ReactElement;
  Wrapper?: React.FC<WrapperProps<T>>;
};

type OpenModalData<T = unknown> = CommonModalData<T> & {
  id?: ModalID;
  closeOnRouteChange?: boolean;
  requestClose?: (cb: () => void) => void;
  modalProps?: Omit<WrapperProps<T>, "id" | "requestClose">;
};

export type StateModalData<T = unknown> = CommonModalData<T> & {
  modalProps: WrapperProps<T>;
};

type ModalState = {
  modals: [
    modalId: ModalID,
    props: StateModalData<any>,
    closeOnRouteChange: boolean,
    onCloseCb: ((cb: () => void) => void) | undefined,
  ][];
  closeSignal: Record<ModalID, boolean>;
};

type StoreSubscribeWithSelector<T> = {
  subscribe: {
    (listener: (selectedState: T, previousSelectedState: T) => void): () => void;
    <U>(
      selector: (state: T) => U,
      listener: (selectedState: U, previousSelectedState: U) => void,
      options?: {
        equalityFn?: (a: U, b: U) => boolean;
        fireImmediately?: boolean;
      },
    ): () => void;
  };
};

type UseModal = UseBoundStore<StoreApi<ModalState>> &
  StoreSubscribeWithSelector<ModalState> & {
    actions: {
      close: (id: ModalID | ModalID[]) => void;
      delete: (id: ModalID | ModalID[]) => void;
      open: <T = ModalComponentProps>(data: OpenModalData<T>) => ModalID;
    };
  };

export const useModal: UseModal = create(
  subscribeWithSelector<ModalState>(
    () =>
      ({
        modals: [],
        closeSignal: {},
      } as ModalState),
  ),
) as UseModal;

useModal.actions = {
  close: (id) => {
    const signals = (Array.isArray(id) ? id : [id]).reduce((acc, curr) => Object.assign(acc, { [curr]: true }), {});
    useModal.setState((p) => ({
      closeSignal: { ...p.closeSignal, ...signals },
    }));
  },
  delete: (id) => {
    const { modals } = useModal.getState();
    const isDeleting = Array.isArray(id) ? (v: ModalID) => id.includes(v) : (v: ModalID) => v === id;

    // run onClose callback from deleted modals
    modals.forEach((m) => {
      if (isDeleting(m[0]) && m[3]) {
        m[3](() => null);
      }
    });

    useModal.setState({
      modals: modals.filter((m) => !isDeleting(m[0])),
    });
  },
  open: ({ Component, Wrapper, modalProps, requestClose, closeOnRouteChange = true, id = uniqueID() }) => {
    const { modals } = useModal.getState();
    const create = (): ModalState["modals"][0] => [
      id,
      {
        Component,
        Wrapper,
        modalProps: {
          ...modalProps,
          id,
          requestClose: () =>
            requestClose ? requestClose(() => useModal.actions.close(id)) : useModal.actions.close(id),
        },
      },
      closeOnRouteChange,
      requestClose,
    ];

    let alreadyExisitng = false;
    const mappedModals = modals.map((m) => {
      if (m[0] === id) {
        alreadyExisitng = true;
        return create();
      }

      return m;
    });

    useModal.setState({ modals: alreadyExisitng ? mappedModals : [...mappedModals, create()] });

    return id;
  },
};

history.listen(() => {
  const modalsToClose = useModal.getState().modals.flatMap((m) => (m[2] ? [m[0]] : []));

  if (modalsToClose.length) {
    useModal.actions.close(modalsToClose);
  }
});

export const useModalWillClose = (id: ModalID, timeout = 200, onClose?: () => void) => {
  const willClose = useModal((state) => state.closeSignal[id]);

  useEffect(() => {
    if (willClose) {
      const timer = setTimeout(() => {
        useModal.actions.delete(id);
        onClose?.();
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [willClose, id, timeout, onClose]);

  return willClose;
};

type UseModalComponent<T = ModalComponentProps> = Omit<OpenModalData<T>, "id"> & {
  closeOnUnmount?: boolean;
};

// hook used to local modal display - its handy when childrens have dynamic values that passed from parent component, while modal is shown
export const useModalState = (initialOpen = false, timeout = 200): [boolean, string, () => void] => {
  const [id, setId] = useState(uniqueID);
  const [open, setOpen] = useState(initialOpen);
  const onClose = useCallback(() => setOpen(false), []);

  useModalWillClose(id, timeout, onClose);

  const toggle = () => {
    if (open) {
      useModal.actions.close(id);
    } else {
      setId(uniqueID());
      setOpen(true);
    }
  };

  return [open, id, toggle];
};

export const useModalComponent = <T = ModalComponentProps>(props: UseModalComponent<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useRef<ModalID | null>(null);
  const propsRef = useRef(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const toggle = useCallback(
    (reopen?: boolean): ModalID | null => {
      const setOpen = () => setIsOpen(!!id.current);

      if (reopen || !id.current || !useModal.getState().modals.find((m) => m[0] === id.current)) {
        if (id.current) {
          useModal.actions.delete(id.current);
          id.current = null;
        }

        const onClose = (cb: () => void) => {
          cb();
          id.current = null;
          setOpen();
        };

        id.current = useModal.actions.open({
          ...propsRef.current,
          requestClose: (cb) =>
            propsRef.current.requestClose ? propsRef.current.requestClose(() => onClose(cb)) : onClose(cb),
        });
      } else if (id.current) {
        useModal.actions.close(id.current);
        id.current = null;
      }

      setOpen();

      return id.current;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      // must explicit set closeOnUnmount to false - otherwise treat it as true
      if (propsRef.current.closeOnUnmount !== false && id.current) {
        toggle();
      }
    },
    // only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [toggle, isOpen] as [typeof toggle, boolean];
};

export const useModalCloseOnUnmount = (modalIds: string | string[]) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => useModal.actions.close(modalIds), []);
};
