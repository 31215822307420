import { Breadcrumb, Breadcrumbs, EmptyState } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "operations.title",
    route: "DASHBOARD",
  },
];

export const Dashboard = () => (
  <>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <EmptyState title="common.emptyTitle" description="common.emptyDescription" />
  </>
);
