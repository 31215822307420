import { TwStyle, css } from "twin.macro";

const mapVariant = <T extends Record<string, any>>(variants: T, variant?: WithVariants<T>["variant"]) =>
  variant
    ? typeof variant === "string"
      ? variants[variant]
      : (variant as Array<keyof T>).map((k) => variants[k])
    : "";

export const applyVariant =
  <T extends Record<string, any>>(variants: T, defaultVariant?: keyof T | Array<keyof T>) =>
  (props: WithVariants<T>) =>
    mapVariant(variants, props.variant || defaultVariant);

export const hasVariant = <T extends Record<string, any>>(
  variantsProp: keyof T | Array<keyof T> | undefined,
  variant: keyof T,
) => (variantsProp ? (Array.isArray(variantsProp) ? variantsProp.includes(variant) : variantsProp === variant) : false);

export type WithVariants<T extends Record<string, any>, KEY extends string = "variant"> = {
  [P in KEY]?: keyof T | Array<keyof T>;
};

type CSSType = ReturnType<typeof css> | TwStyle;

export type VariantsByKeys<KEYS extends string, PROPS = any> = Record<
  KEYS,
  CSSType | ((p: PROPS) => CSSType | false | undefined)
>;
