import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "./helpers";

type Props = WithVariants<typeof variants>;

const variants = {
  pageWrapper: tw`py-4 h-full flex flex-col overflow-y-auto`,
  borderBottom: tw`border-b border-gray-2`,
  shadow: tw`shadow-blue`,
  card: tw`bg-gray-3 shadow-blue rounded-md py-2 px-4`,
};

export const Container = styled.div<Pick<Props, "variant">>`
  ${tw`px-2 sm:px-4 md:px-5 py-2.5`}
  ${applyVariant(variants)}
`;
