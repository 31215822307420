import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GlobalStyles } from "twin.macro";

import { useLocalStorage } from "common/hooks";

import { BaseStyle } from "./BaseStyle";
import { BreakpointsProvider } from "./Breakpoints";

type ThemeName = "light" | "dark";
export type ThemeKind = ThemeName | "system";

type ThemeContext = {
  theme: ThemeName;
  themeKind: ThemeKind;
  setTheme: React.Dispatch<React.SetStateAction<ThemeKind>>;
};

const ThemeContext = createContext<ThemeContext>({} as ThemeContext);

const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

const useThemeDetector = (): ThemeName => {
  const [isDarkTheme, setIsDarkTheme] = useState(matchMedia.matches);

  useEffect(() => {
    const mqListener = (e: MediaQueryListEvent) => setIsDarkTheme(e.matches);
    matchMedia.addEventListener("change", mqListener);
    return () => matchMedia.removeEventListener("change", mqListener);
  }, []);

  return isDarkTheme ? "dark" : "light";
};

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [storageTheme, setStorageTheme] = useLocalStorage<ThemeKind>("system", "colorTheme");
  const systemTheme = useThemeDetector();

  const theme: ThemeName = storageTheme === "system" ? systemTheme : storageTheme;

  useEffect(() => {
    const classList = window.document.documentElement.classList;
    classList.remove(theme === "dark" ? "light" : "dark");
    classList.add(theme);
  }, [theme]);

  const context: ThemeContext = useMemo(
    () => ({
      theme,
      themeKind: storageTheme,
      setTheme: setStorageTheme,
    }),
    [storageTheme, theme, setStorageTheme],
  );

  return (
    <ThemeContext.Provider value={context}>
      <GlobalStyles />
      <BaseStyle />
      <BreakpointsProvider>{children}</BreakpointsProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
