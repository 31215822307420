import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { GenerateMachineViewTabDocument, useDeleteMachineMutation } from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const DeleteMachine: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteMachine, { loading, client }] = useDeleteMachineMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "machine.deleteSuccess" : "machine.deleteError", {
        variant: error ? "success" : "error",
      });

    try {
      const result = await deleteMachine({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(GenerateMachineViewTabDocument, cache);
          // reset cache completely, deleted machine has too many dependents on it
          client.resetStore();
        },
      });
      if (result.data?.deleteMachine?.nodeId) onClose?.();
      showToast(!!result.data?.deleteMachine?.nodeId);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="machine.deleteMachine" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="machine.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
