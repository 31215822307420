export const es = {
  administration: {
    title: "Administracion",
    overview: "Administracion general",
    description:
      "Realizar tareas administrativas, como gestionar usuarios, cambiar su codigo pin, acceder a la vista de operador y editar unidades de trabajo.",
    allocations: "Asignaciones",
    allocationsDescription: "Asociar una caja a una unidad de trabajo, como una caja registradora",
    machines: "Maquinas",
    machinesDescription: "Ver, gestionar y crear maquinas",
    locations: "Sitios",
    locationsDescription: "Ver, gestionar y crear sitios",
    roles: "Roles",
    rolesDescription: "Ver, gestionar y crear roles",
    permissions: "Permisos",
    permissionsDescription: "Ver, gestionar y crear permisos",
    dispenseLimits: "Limites de dispensacion",
    dispenseLimitsDescription: "Limitar la cantidad de dinero que se puede dispensar en una maquina",
    posTransactions: "Transacciones POS",
    posTransactionsDescription:
      "El punto de venta (POS) o punto de compra (POP) es el momento y el lugar donde se completa una transacción al por menor.",
    configTemplates: "Plantillas de configuracion",
    configTemplatesDescription:
      "Exporte paquetes desde Connect on-Device Studio al sistema de archivos de su ordenador e importelos como plantillas de configuracion a Connect",
    countries: "Paises",
    country: "Pais",
    selectCountries: "Seleccionar Pais",
    cities: "Ciudades",
    city: "Ciudad",
    selectCities: "Seleccionar ciudades",
    mg: {
      title: "Grupos de maquinas",
      name: "Nombre",
      description: "Ver, gestionar y crear grupos de maquinas inteligentes",
      create: "Crear grupo de máquinas",
      createDescription: "Crear un grupo de maquinas para reunirlas en una entidad logica",
      update: "Actualizar grupo de maquinas",
      updateDescription:
        "Se actualiza un grupo de maquinas cuando se desea actualizar un grupo de maquinas inteligente existente",
      delete: "Borrar grupo de maquinas",
      deleteDescription:
        "Se elimina un grupo de maquinas cuando se desea eliminar un grupo de maquinas inteligente existente",
      deleteClose: "Cerrar",
      nodeChangeDate: "Cambiado",
      nodeStatus: "Estado",
      nodeStatusCount: "Recuento de estados",
      machines: "Maquinas",
      availableMachines: "Maquinas disponibles",
      nodeId: "ID del Nodo",
      machineNodeIds: "ID de los nodos de maquina",
      machineNodeId: "ID del nodo de la maquina",
      success: "Grupo de maquinas creado con exito",
      error: "Fallo en la creacion del grupo de maquinas",
      updateSuccess: "Grupo de maquinas actualizado correctamente",
      updateApiError: "Actualizacion de grupo de maquinas fallida",
      deleteSuccess: "Grupo de maquinas eliminado con exito",
      deleteError: "Fallo en la eliminacion del grupo de maquinas",
      goBack: "Volver",
      namesCount: "Recuento de nombres",
    },
    lg: {
      title_one: "Grupo de sitios",
      title_other: "Grupos de sitios",
      desc: "Visión general de los grupos de sitios - puede ver, crear, actualizar y eliminar grupos de sitios.",
      create: "Crear grupo de sitios",
      createDescription: "Crear un grupo de sitios para gestionar sitios relacionados",
      update: "Actualizar grupo de sitios",
      updateDescription: "Actualizar un grupo de sitios existente",
      delete: "Eliminar grupo de sitios",
      deleteDescription: "Se eliminara el grupo de sitios. ¿Desea continuar?",
      deleteClose: "Cerrar",
      name: "Nombre del grupo de sitios",
      siteGroupId: "ID del grupo de sitios",
      sites: "Sitios",
      includedSites: "Sitios incluidos",
      nodeStatus: "Estado",
      nodeStatusCount: "Recuento de estados",
      nodeChangeDate: "Cambiado",
      success: "Grupo de sitios creado con exito",
      error: "Fallo la creacion del grupo de sitios",
      updateSuccess: "Grupo de sitios actualizado con exito",
      updateApiError: "Error en la actualizacion del grupo de sitios",
      deleteSuccess: "Grupo de sitios eliminado con exito",
      deleteError: "Fallo en la eliminacion del grupo de sitios",
      goBack: "Volver",
      list: {
        noData: "No se han encontrado grupos de sitios",
      },
      namesCount: "Recuento de nombres",
      authorizedUsers: "Usuarios autorizados",
      count: "Recuento",
      noCountriesFound: "No se han encontrado paises",
      noCitiesFound: "No se han encontrado ciudades",
    },
    sp: {
      title: "Paquetes de software",
      selectSoftwarePackage: "Seleccionar paquete de software",
      desc: "Vision general de los paquetes de software disponibles en Connect y para iniciar el asistente de creacion de paquetes de software desde el que cargar nuevos paquetes",
      name: "Nombre",
      placeholder: "Introduzca el nombre del paquete de software",
      tooltip:
        "Utilice nombre para dar al archivo .zip del paquete de software un nombre facil de usar. El nombre puede tener un maximo de 30 caracteres, incluidos los espacios en blanco.",
      info: "Informacion",
      version: "Version",
      revision: "Revision",
      file: "Fichero",
      release: "Version",
      createdBy: "Creado por",
      createdDate: "Fecha de creacion",
      description: "Descripcion",
      releaseInfo: "Información de la version",
      official: "Hacer oficial este paquete de software",
      custom: "Personalizado",
      label: "Etiqueta",
      fileRequired: "Debe proporcionar un archivo",
      addSp: "Crear paquete de software",
      submit: "Guardar y cargar",
      update: "Actualizar paquete",
      import: "Seleccionar archivo",
      changeFile: "Cambiar archivo",
      uploadSuccess: "Subido con exito el paquete de software",
      uploadFailure: "Se ha producido un error al subir el archivo",
      addSuccess: "Paquete de software creado con exito",
      addApiError: "Error en la creacion del paquete de software",
      err: {
        _1: "Error desconocido. Por favor, intentelo de nuevo más tarde.",
        _2: "Debe ser un archivo zip.",
        _3: "El archivo seleccionado no parece ser un paquete de software.",
        _4: "Falta informacion o informacion no valida en el archivo seleccionado.",
      },
      missingPreSignedUrl: "No se ha podido obtener la url prefirmada",
      delete: "Eliminar paquete de software",
      deleteDescription: "Se eliminaran tanto la plantilla del paquete de software como el archivo cargado",
      deleteError: "Error al eliminar el paquete de software",
      deleteSuccess: "Eliminado con exito el paquete de software",
      deleteClose: "Cerrar",
    },
    rc: {
      title: "Plantillas de configuracion",
      selectTemplate: "Seleccionar plantilla",
      desc: "Visión general de las plantillas de configuracion. Puede ver, crear, cargar y actualizar plantillas de configuracion.",
      name: "Nombre",
      info: "Informacion",
      version: "Version",
      createdBy: "Creado por",
      createdDate: "Fecha de creacion",
      description: "Descripcion",
      file: "Fichero",
      fileRequired: "Debe proporcionar un archivo",
      configurationFile: "Archivo de configuracion",
      addRc: "Crear plantilla de configuracion",
      submit: "Guardar y carga",
      update: "Actualizar plantilla",
      import: "Importar archivo",
      changeFile: "Cambiar archivo",
      included: "Incluido",
      addSuccess: "Plantilla de configuracion creada con exito",
      addApiError: "Fallo al crear la plantilla de configuracion",
      saveTameplateFailure: "Se ha producido un error al guardar la plantilla",
      uploadSuccess: "Subido con exito el archivo de configuracion",
      uploadFailure: "Se ha producido un error al subir el archivo",
      missingPreSignedUrl: "No se pudo obtener la url prefirmada",
      updateSuccess: "Plantilla de configuracion actualizada con exito",
      updateError: "Se ha producido un error al actualizar la plantilla de configuracion",
      configuration: "Configuracion",
      delete: "Borrar plantilla de configuracion",
      deleteDescription: "Se borraran tanto la plantilla de configuracion como el fichero subido",
      deleteError: "Error al borrar plantilla de configuracion",
      deleteSuccess: "Eliminada con exito la plantilla de configuracion",
      deleteClose: "Cerrar",
      receipts: "Recibos",
      i18n: "i18n",
      themes: "Temas",
      sdk: "Sdk",
      scripts: "Scripts",
      help: "Ayuda",
      err: {
        _1: "Error desconocido. Por favor, intentelo mas tarde.",
        _2: "Debe ser un archivo zip.",
        _3: "El archivo seleccionado no parece ser un archivo de plantilla de configuracion.",
        _4: "Plantilla de configuracion actualizada con exito.",
      },
    },
  },
  machineUserGroup: {
    title_one: "Grupo de usuarios de maquina",
    title_other: "Grupos de usuarios de maquinaria",
    desc: "Proporcionar una forma dinamica de gestionar los usuarios, basada en el acceso y la funcionalidad.",
    name: "Nombre",
    authorizedMachines: "Maquinas autorizadas",
    deleteGroup: "Borrar grupo",
    deleteDesc: "Grupo <0>{{name}}</0> se borrara sin poder deshacerse.",
    addMachineUserGroups: "Crear grupo de usuarios de maquina",
    addSuccess: "Grupo de usuarios de maquinas creado correctamente",
    addError: "Falló en creacion del grupo de usuarios de la maquina",
    updateSuccess: "Grupo de usuarios de máquina actualizado correctamente",
    updateError: "Error en la actualizacion del grupo de usuarios de la maquina",
    create: "Crear",
    update: "Actualizacion",
    updateName: "Actualizar nombre de grupo",
    addMachineUsers: "Añadir usuarios de maquinas",
    removeMachineUsers: "Borrar usuarios de maquinas",
    addMachines: "Añadir maquinas",
    removeMachines: "Borrar maquinas",
  },
};
