import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const DispenseLimits = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.dispenseLimits"
    subtitle="administration.dispenseLimitsDescription"
  />
);
