import { GRAPHQL_MAX_INT } from "graphql";
import { TKeys } from "i18next";

import { transactionDateTime } from "base/components";
import { CustomRenderFields } from "common/form/renderFields";
import { currencyOptions } from "common/helpers";
import { TransactionType, TransactionsReportCriteriaDtoIn } from "generated";
import { getSelectMachineUserField } from "machineUser/components";
import { transactionTypeOptions } from "report/helpers";
import { transactionSubTypesOptions } from "report/helpers/transactionSubTypesOptions";

import { getCommonFields } from "./parametersCommon";

export const transactionsReportDefaultParams: Partial<TransactionsReportCriteriaDtoIn> = {
  reportName: "TransactionsReport",
  queryTransactionBy: "DATE_TIME",
  messageType: "ALL",
  skip: 0,
  // max value to be sure all reports are taken
  limit: GRAPHQL_MAX_INT,
};

const messageTypeOptions: Array<{ value: TransactionType; label: TKeys }> = [
  {
    value: "ALL",
    label: "tranReport.messageTypes.ALL",
  },
  {
    value: "CORRECTION_TRANSACTIONS",
    label: "tranReport.messageTypes.CORRECTION_TRANSACTIONS",
  },
  {
    value: "MACHINE_TRANSACTIONS",
    label: "tranReport.messageTypes.MACHINE_TRANSACTIONS",
  },
];

export const transactionsReportFields: CustomRenderFields[] = [
  ...getCommonFields(),
  getSelectMachineUserField({ name: "inputPayload.machineUserIds" }),
  {
    type: "select",
    name: "inputPayload.queryTransactionBy",
    label: "base.queryByDate.label",
    options: transactionDateTime,
    isClearable: true,
  },
  {
    type: "select",
    name: "inputPayload.messageType",
    label: "tranReport.messageType",
    options: messageTypeOptions,
    isClearable: true,
  },
  {
    type: "select",
    name: "inputPayload.currency",
    label: "tranCorr.currency",
    options: currencyOptions,
    isClearable: true,
  },
  {
    type: "select",
    name: "inputPayload.transactionType",
    label: "tranReport.type",
    options: transactionTypeOptions,
    isClearable: true,
  },
  {
    type: "select",
    name: "inputPayload.transactionSubType",
    label: "tranReport.subtype",
    options: transactionSubTypesOptions,
    isClearable: true,
  },
  {
    type: "number",
    name: "inputPayload.sequence",
    label: "tranReport.sequence",
  },
];
