export const de = {
  transport: {
    title: "ÖPNV",
    overview: "Übersicht",
    desc: "Transport ist eine Plattform für die Verwaltung und Überwachung von ÖPNV Transaktionen.",
    corReasons: {
      title_other: "Gründe für die Berichtigungen",
      title_one: "Gründe für die Berichtigung",
      desc: "Korrekturgründe anzeigen, verwalten und erstellen",
      id: "Korrekturgrund ID",
      name: "Name",
      active: "Aktiv",
      lastEdited: "Letzte Änderung",
      lastEditedBy: "Letzte Änderung durch",
      create: "Korrektur erstellen",
      remove: {
        title: "Korrekturgrund entfernen",
        success: "Berichtigungsgrund entfernt",
        error: "Korrekturgrund konnte nicht entfernt werden, bitte versuchen Sie es erneut",
        desc: "Korrektur <0>{{name}}</0> wird dauerhaft entfernt.",
        close: "Verwerfen",
        submit: "Entfernen",
      },
      form: {
        name: "Name",
        active: "Aktiviert",
        create: "Korrekturgrund anlegen",
        createSubmit: "Erstellen",
        update: "Korrekturgrund aktualisieren",
        updateSubmit: "Update",
      },
      createSuccess: "Berichtigungsgrund erfolgreich erstellt",
      createError: "Korrektur Grund Erstellung fehlgeschlagen",
      updateSuccess: "Berichtigungsgrund erfolgreich aktualisiert",
      updateError: "Berichtigungsgrund aktualisierung fehlgeschlagen",
    },
    rBalance: {
      title: "Saldo",
      desc: "Bericht zur Visualisierung des Saldo vom Fahrer",
      date: "Datum",
      currencyCode: "Währung",
      balance: "Saldo",
      machineUser: "Maschinenbenutzer",
    },
    dashboard: {
      pickMachine: "Wählen Sie eine Maschine aus, um eine Transportübersicht zu sehen",
    },
    transactionType: {
      all: "Alle Transaktionen",
      correction: "Berichtigungstransaktionen",
      machine: "Maschinelle Transaktionen",
    },
  },
  tranCorr: {
    title: "Berichtigungstransaktionen",
    desc: "Verwenden Sie die Ansicht Korrekturtransaktionen, um Korrekturtransaktionen anzuzeigen und die Werte bestehender Transaktionen zu ändern, um die Berichterstattung zu korrigieren.",
    datePeriod: "Berichtigungszeitraum",
    machine: "Maschine",
    sequence: "Sequenz",
    type: "Typ",
    time: "Zeit",
    date: "Datum",
    utcOffset: "UTC-Abweichung",
    machineUser: "Maschinenbenutzer",
    corrReason: "Grund der Berichtigung",
    comment: "Kommentar",
    createdBy: "Erstellt von",
    value: "Wert",
    currency: "Währung",
    create: "Korrektur erstellen",
    submit: "Einreichen",
    offsetPatternErr: "Muss ein Muster sein -xx.00 oder +xx.00",
    createSuccess: "Korrekturtransaktion erfolgreich erstellt",
    createError: "Erstellung der Korrekturtransaktion fehlgeschlagen",
  },
  tranTypes: {
    DISPENSE: "Ausgabe",
    DEPOSIT: "Einzahlung",
    EMPTY_OUT: "Leerung",
    END_OF_SHIFT: "Schichtende",
    MANUAL_REFILL: "Manuelles Nachfüllen",
    EXCHANGE: "Wechsel",
    PURGE: "Entleeren",
    POSSIBLE_TAMPERING: "Mögliche Manipulationen",
    UNFINISHED_TRANSACTION: "Nicht abgeschlossene Transaktion",
    REFILL: "Nachfüllen",
  },
};
