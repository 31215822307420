import { CellContext } from "@tanstack/react-table";

import { Badge, Text } from "common/guideline";
import { NodeStatus } from "generated";

type Props = {
  children?: React.ReactNode;
  value?: NodeStatus | undefined;
  getValue?: CellContext<any, any>["getValue"];
};

const statusToBadgeVariant: Record<NodeStatus, "success" | "error" | "warning" | "neutral"> = {
  ACTIVE: "success",
  INACTIVE: "error",
  DELETED: "error",
  DETACHED: "warning",
  UNKNOWN: "neutral",
};

export const NodeStatusBadge = ({ value: _value, getValue, children }: Props) => {
  const value = _value || (getValue?.() as NodeStatus | undefined);

  return (
    <Badge variant={statusToBadgeVariant[value || "UNKNOWN"]}>
      <Text tKey={`base.nodeStatus.${value || "UNKNOWN"}`} />
      {children}
    </Badge>
  );
};
