import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindAllMachineUsersFilteredDocument,
  FindUserBalancesFilteredDocument,
  useDeleteMachineUserMutation,
} from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const DeleteMachineUser: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteMachineUser, { loading }] = useDeleteMachineUserMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "mu.deleteError" : "mu.deleteSuccess", {
        variant: error ? "error" : "success",
      });

    try {
      const result = await deleteMachineUser({
        variables: { input: nodeId },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(FindUserBalancesFilteredDocument, cache);
          evictQuery(FindAllMachineUsersFilteredDocument, cache);
          evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, { getInnerOperationName: true });
        },
      });
      if (result.data?.deleteMachineUser?.nodeId) onClose?.();
      showToast(!result.data?.deleteMachineUser?.nodeId);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="mu.delete" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="mu.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
