import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { LoadingButton, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindTenantFeaturesDocument,
  FindTenantFeaturesQuery,
  SetTenantFeaturesMutation,
  useFindTenantFeaturesQuery,
  useSetTenantFeaturesMutation,
} from "generated";

type FeaturesFormData = Omit<NonNullable<SetTenantFeaturesMutation["setTenantFeatures"]>, "__typename">;

const Wrapper = styled.div`
  ${tw`grid gap-4 sm:grid-cols-2`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "checkbox",
        name: "userBalanceEnabled",
        label: "tenant.features.userBalance",
      },
      {
        type: "checkbox",
        name: "commonSoftwarePackageUploadEnabled",
        label: "tenant.features.commonSoftwarePackageUploadEnabled",
      },
      {
        type: "checkbox",
        name: "mumEnabled",
        label: "tenant.features.mumEnabled",
      },
      {
        type: "checkbox",
        name: "mumXmlImportEnabled",
        label: "tenant.features.mumXmlImportEnabled",
      },
    ],
  },
];

const getInitialData = (data: FindTenantFeaturesQuery | undefined): FeaturesFormData => ({
  userBalanceEnabled: data?.findTenantFeatures?.userBalanceEnabled,
  commonSoftwarePackageUploadEnabled: data?.findTenantFeatures?.commonSoftwarePackageUploadEnabled,
  mumEnabled: data?.findTenantFeatures?.mumEnabled,
  mumXmlImportEnabled: data?.findTenantFeatures?.mumXmlImportEnabled,
});

const TenantFeaturesForm: React.FC<{ tenantId: string; onSubmit: OnSubmit<FeaturesFormData> }> = ({
  tenantId,
  onSubmit,
}) => {
  const [initialData, { loading }] = useMappedQuery(
    getInitialData,
    useFindTenantFeaturesQuery({ variables: { tenantId } }),
  );

  return (
    <>
      <Text variant="heading" tw="pb-4 block pr-4" tKey="tenant.features.titleUpdate" tValue={{ name: tenantId }} />
      {loading ? null : (
        <SchemaForm<FeaturesFormData>
          fields={fields}
          onSubmit={onSubmit}
          customRender={customRender}
          initial={initialData}
          SubmitComponent={() => (
            <div tw="flex justify-end mt-6">
              <FormData type="isSubmittig">
                {(isLoading) => (
                  <LoadingButton type="submit" isLoading={isLoading} disabled={isLoading} data-test="submitForm">
                    <Text tKey="tenant.features.submit" />
                  </LoadingButton>
                )}
              </FormData>
            </div>
          )}
        />
      )}
    </>
  );
};

export const useTenantFeaturesFormModal = (tenantId: string) => {
  const [setFeatures] = useSetTenantFeaturesMutation();

  const modalData = useModalComponent({
    Component: (
      <TenantFeaturesForm
        tenantId={tenantId}
        onSubmit={(features) =>
          setFeatures({
            variables: { features, tenantId },
            ignoreResults: true,
            onCompleted: () => modalData[0](),
            update: (cache) => evictQuery(FindTenantFeaturesDocument, cache),
          })
            .then(() => useToast.actions.show("tenant.features.success", { variant: "success" }))
            .catch(() => useToast.actions.show("tenant.features.apiError", { variant: "error" }))
        }
      />
    ),
  });

  return modalData;
};
