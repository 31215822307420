import { useState } from "react";

import { CheckboxRaw } from "common/form/renderFields";

export const useBooleanFilter = (name: string, label?: string, defaultValue = false): [boolean, JSX.Element] => {
  const [state, setState] = useState<boolean>(defaultValue);

  return [
    state,
    <CheckboxRaw
      key={name}
      name={name}
      type="checkbox"
      label={label}
      checked={state}
      onChange={() => setState((p) => !p)}
    />,
  ];
};
