export const es = {
  machine: {
    machine_one: "Maquina",
    machine_other: "Maquinas",
    machineStatus: "Estado de maquina",
    deviceInfo: "Detalles del dispositivo",
    sensorInfo: "Detalles del sensor",
    name: "Nombre",
    noName: "Maquina sin nombre",
    contents: "Contenido",
    machineContents: "Contenido de la maquina",
    machineContentsDescription: "Muestra informacion sobre el contenido de la maquina",
    usage: "Uso",
    machineUsage: "Uso de la maquina",
    machineUsageDescription:
      "Muestra informacion sobre el valor de las transacciones de deposito y dispensacion en la maquina.",
    health: "Salud",
    machineHealth: "Salud dela maquina",
    machineHealthDescription:
      "Muestra los ultimos 10 errores activos en la maquina, es decir, los errores que aun no se han borrado.",
    information: "Informacion",
    extendedInfo: "Informacion ampliada",
    errorCode: "Codigo de error",
    cleared: "Borrado",
    refillStatus: "Estado de recarga",
    refillStatusTooltip:
      "'$t(machine.refillStatuses.REFILL)' si alguna denominacion del reciclador cae por debajo de la cantidad minima configurada. '$t(machine.refillStatuses.APPROACHING)' si alguna denominacion cae por debajo del nivel por defecto configurado. '$t(machine.refillStatuses.OK)' si no se requiere ninguna accion",
    machineAlias: "Alias de la maquina",
    machineSn: "S/N de la máquina:",
    machineName: "Nombre de la maquina",
    machineType_one: "Tipo de maquina",
    machineType_other: "Tipos de maquina",
    machinesCount: "Recuento de maquinas",
    nodeStatus: "Estado",
    nodeChangeDate: "Fecha de cambio de nodo",
    changed: "Cambiado",
    createMachine: "Crear maquina",
    createMachineDescription: "Creas una maquina cuando quieres crear un nuevo dispositivo para Conectar",
    createMachineSuccess: "Maquina creada con exito",
    createMachineError: "Fallo en la creacion de la maquina",
    editMachine: "Editar maquina",
    updateMachine: "Actualizar maquina",
    editMachineDescription: "Cambia los detalles de la maquina para editarla",
    editMachineSuccess: "Maquina guardada con exito",
    editMachineError: "Fallo en la actualización de la maquina",
    delete: "Borrar",
    deleteMachine: "Borrar maquina",
    deleteDescription: "La maquina <0>{{name}}</0> se eliminara de forma permanente.",
    deleteSuccess: "Maquina eliminada con exito",
    deleteError: "Error en la eliminacion de la maquina",
    deleteClose: "Cerrar",
    nodeId: "ID de nodo",
    dateTime: "Fecha / hora de la maquina",
    version: "Version",
    receiver: "Receptor",
    sequence: "Secuencia",
    messageSequence: "Secuencia de mensajes",
    messageType: "Tipo de mensaje",
    uuid: "UUID de la maquina",
    comment: "Comentario",
    deviceId: "ID de dispositivo",
    boxId: "ID de caja",
    containerId: "ID de contenedor",
    rejects: "Rechazos",
    totalAmount: "Importe total",
    movementType: "Tipo de movimiento",
    resultingContentRejects: "Rechazos de contenido resultante",
    resultingContentTotalAmount: "Importe total del contenido resultante",
    resultingContentType: "Tipo de contenido resultante",
    deleted: "Eliminado",
    detached: "Separado",
    inactive: "Inactivo",
    active: "Activo",
    unknown: "Desconocido",
    online: "En linea",
    offline: "Desconectado",
    refill: "Recarga",
    ok: "OK",
    goBack: "Volver",
    approaching: "Acercandose",
    notAvailable: "N/A",
    kind: "Tipo",
    resolved: "Resuelto",
    device: "Dispositivo",
    filterMachines: "Filtrado de maquinas",
    preview: "Vista previa",
    severity: "Severidad",
    severityType: {
      Error: "Error",
      Warning: "Advertencia",
    },
    status: {
      CatchingUp: "Ponerse al dia",
      Offline: "Desconectado",
      Online: "En linea",
      Unknown: "Desconocido",
    },
    availableForDispense: "Disponible para dispensar",
    availableForDispenseTooltip: "Muestra la cantidad que se puede dispensar desde la máquina",
    notDispensable: "No dispensable",
    notDispensableTooltip: "Muestra la cantidad no dispensable en la maquina",
    availabilityRatio: "Ratio de disponibilidad de dispensacion",
    availabilityRatioTooltip:
      "Relacion entre el efectivo disponible para dispensar y el efectivo total, para la moneda seleccionada",
    openShifts: "Turnos abiertos",
    cashInCirculation: "Efectivo en circulación",
    netCashChange: "Variacion del efectivo neto",
    availableCashChange: "Variacion del efectivo disponible",
    notes: "Billetes",
    notesTooltip: "Valor de todos los billetes actualmente en el dispositivo para la moneda seleccionada",
    counterfeitNotes: "Billetes falsos",
    counterfeitNotesTooltip: "Valor de todos los billetes detectados como falsos para la divisa seleccionada",
    checks: "Cheques",
    checksTooltip: "Valor total de todos los cheques para la moneda seleccionada",
    coins: "Monedas",
    coinsTooltip: "Valor de todas las monedas actualmente en el dispositivo para la moneda seleccionada",
    suspectNotes: "Billetes sospechosos",
    suspectNotesTooltip:
      "Valor de todos los billetes que fueron detectados como sospechosos o que no alcanzaron el nivel de aptitud configurado, para la moneda seleccionada",
    vouchers: "Comprobantes",
    vouchersTooltip: "Valor total de todos los vales para la moneda seleccionada",
    claimedValue: "Valor reclamado",
    claimedValueTooltip:
      "Valor de los artículos introducidos manualmente para la divisa seleccionada (generalmente colocados en el buzon o la bolsa)",
    timelineExplorer: "Explorador de la línea de tiempo",
    timelineExplorerDescription:
      "Ordena las transacciones (se muestran las ultimas 1.000 transacciones) por fecha de transaccion",
    noData: "No se han encontrado máquinas",
    specName: "Nombre",
    licenseLabel: "Etiqueta de licencia",
    licenseLevel: "Nivel de licencia",
    noErrorsFound: "No se han encontrado errores para esta maquina",
    showBy: "Mostrar por",
    type: "Tipo",
    aboveDefault: "Por encima de los niveles por defecto",
    aboveMinimum: "Por encima del minimo, pero por debajo del predeterminado",
    belowMinimum: "Por debajo de los niveles minimos",
    totalDeposits: "Depositos totales",
    totalDepositsTooltip: "Valor total de todos los depositos desde la hora de inicio del dia.",
    totalDispenses: "Total de dispensaciones",
    totalDispensesTooltip: "Valor total de todas las dispensaciones desde el inicio del dia.",
    changeNetCash: "Cambio en el efectivo neto",
    changeNetCashTooltip:
      "Diferencia entre el efectivo disponible para dispensar al inicio del día y el total acumulado de todos los depositos de efectivo.  Un valor positivo significa que se esta generando efectivo y un valor negativo significa que se esta consumiendo efectivo.",
    changeAvailableCash: "Cambio disponible",
    changeAvailableCashTooltip:
      "Diferencia entre el efectivo disponible para dispensar al inicio del día y el efectivo actual disponible para dispensar.",
    usageSinceMidnight: "Mostrar el uso desde hoy a las 00:00",
    devInfoMissing: "No hay maquinas conectadas",
    valueBagsMissing: "No hay bolsa",
    deviceMovementsMissing: "No hay movimientos de dispositivos",
    values: {
      denomination: "Denominacion",
      exchangeRate: "Tipo de cambio",
      exchangeRateDecimals: "Decimales del tipo de cambio",
      pieceValue: "Valor de la pieza",
      reference: "Referencia",
      total: "Total",
      type: "Tipo",
      c2Count: "Contado C2",
      c3Count: "Contado C3",
      c4bCount: "Contado C4b",
      claimedValueType: "Tipo de valor reclamado",
      count: "Recuento",
      creditCat2: "Credito Cat2",
      creditCat3: "Credito Cat3",
      currency: "Moneda",
      customType: "Tipo personalizado",
      decimals: "Decimales",
    },
    info: {
      deviceId: "ID del dispositivo",
      desc: "Muestra informacion diversa sobre la maquina. Por ejemplo, el tipo de maquina y la fecha de activacion",
      devInfo: "Informacion de la maquina",
      devInfoMissing: "No hay dispositivos conectados",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Tipo de acceso",
      protocol: "Protocolo",
      name: "Nombre",
      nodeId: "ID del dispositivo",
      serialNumber: "Numero de serie",
      version: "Version",
      capabilities: "Capacidades",
    },
    licensing: {
      title: "Licencia (opcional)",
      title_singular: "Licencia",
      currentLicense: "Licencia actual",
      noLicenseId: "Para actualizar la licencia, la maquina debe estar en linea.",
      keepBasic: "Mantener licencia basica",
      upgrade: "Actualizar licencia",
      option: "Opcion",
      discountCode: "Codigo de descuento (opcional)",
      costAcknowledgement: "Reconozco que puede haber un coste asociado a esta actualizacion de licencia.",
      agreementNeeded: "Se necesita un acuerdo.",
      currentlyActive: "Actualmente activo",
      currentlyInactive: "Actualmente inactiva",
      licenseKey: "Clave de licencia",
      active: "Activo",
      unclickToActivate: "La suscripcion a la licencia esta activa, haga click para desactivarla.",
      clickToActivate: "La suscripcion de licencia esta desactivada, haga clic para activarla.",
      expirationDate: "Fecha de caducidad de la licencia",
      edition: {
        basic: "Licencia basica",
        enterpriseSubscription: "Suscripcion empresarial",
        unknown: "Desconocido",
      },
    },
    sensor: {
      deviceId: "ID del dispositivo",
      desc: "Muestra informacion diversa sobre la maquina. Por ejemplo, el tipo de maquina y la fecha de activacion",
      devInfo: "Informacion de la maquina",
      devInfoMissing: "No hay dispositivos conectados",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Tipo de acceso",
      protocol: "Protocolo",
      name: "Nombre",
      nodeId: "ID de informacion del sensor",
      serialNumber: "Numero de serie",
      version: "Version",
      capabilities: "Capacidades",
    },
    refillStatuses: {
      APPROACHING: "Acercandose a",
      OK: "OK",
      REFILL: "Recarga",
    },
    remoteOperationMgmt: {
      deployedConfiguration: "Configuracion desplegada",
      configuration: "Configuracion",
      software: "Software",
      version: "Version",
      installing: "Instalando",
      status: "Estado",
      cancel: "Cancelar",
      unknown: "Desconocido",
      statuses: {
        CANCELED: "Cancelado",
        CANCELING: "Cancelando",
        DEPLOYING: "Enviado",
        DONE: "Hecho",
        FAILEd: "Fallido",
        INSTALLING: "Instalando",
        PENDING: "Pendiente",
        SENT: "Enviado",
        TIMEOUT: "Tiempo de espera",
        WAITING: "Esperando",
        UNKNOWN: "Desconocido",
        undefined: "Desconocido",
      },
    },
  },
};
