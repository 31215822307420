import { ColumnOrderState, PaginationState, VisibilityState } from "@tanstack/react-table";
import { useEffect, useLayoutEffect } from "react";

import { getLocalStorageData, useLocalStorage } from "common/hooks";
import { useTenant } from "tenant/context";

import type { TableContextType } from "./TableContext";

type StorageState = {
  columnOrder: ColumnOrderState;
  columnVisibility: VisibilityState;
  pagination: PaginationState;
};

const getStorageName = (tableName: string, tenantId: string) => `__t_${tableName}_${tenantId}__`;

export const getTableStorageData = (tableName: string) =>
  getLocalStorageData<StorageState>(getStorageName(tableName, useTenant.actions.getTenantId() ?? ""));

export const TableStorageConfig: React.FC<{ tableContext: TableContextType }> = ({ tableContext }) => {
  const { getState, setState, tableName, getAllLeafColumns, onPagination } = tableContext;
  const {
    columnFilters,
    columnOrder,
    columnVisibility,
    pagination: { pageSize },
  } = getState();

  const tenantId = useTenant.actions.getTenantId() ?? "";
  const storageName = getStorageName(tableName, tenantId);

  const [, setStorage] = useLocalStorage<StorageState>(
    { columnOrder, columnVisibility, pagination: { pageSize, pageIndex: 0 } },
    storageName,
  );

  useEffect(() => {
    setStorage({ columnOrder, columnVisibility, pagination: { pageSize, pageIndex: 0 } });
  }, [columnOrder, columnVisibility, pageSize, setStorage]);

  useLayoutEffect(() => {
    const columns = getAllLeafColumns();
    const left = columns.flatMap((c) => (c.columnDef.meta?.pin === "left" ? c.id : []));
    const right = columns.flatMap((c) => (c.columnDef.meta?.pin === "right" ? c.id : []));
    const initialHidden = columns.reduce((acc, curr) => {
      if (curr.columnDef.meta?.initialHidden) acc[curr.id] = false;
      return acc;
    }, {});

    const storageValue = getLocalStorageData<StorageState>(storageName);

    setState((p) => ({
      ...p,
      columnPinning: { left, right },
      ...storageValue,
      columnVisibility: { ...initialHidden, ...p.columnVisibility, ...storageValue?.columnVisibility },
    }));

    // only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageName]);

  useEffect(() => {
    // reset page to the first on filters change
    if (columnFilters.length) {
      if (onPagination) {
        onPagination((p) => ({ ...p, pageIndex: 0 }));
      } else {
        setState((p) => ({ ...p, pagination: { ...p.pagination, pageIndex: 0 } }));
      }
    }
  }, [columnFilters, setState, onPagination]);

  return null;
};
