import { createContext, useContext, useEffect, useMemo, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getSiteGroupsFilterField } from "administration/pages/LocationGroups/components/getSmartLocationsGroup";
import { FormProvider, useFieldData, useForm, useFormGenerator } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Container } from "common/guideline";
import { SmartLocationGroupDtoOut, useFindAllLocationGroupsFilteredQuery } from "generated";
import { UseCustomLocationsFilters, getSelectLocationsFieldWithFilters } from "location/components";
import {
  UseCustomMachinesFilters,
  getMachinesFilterFieldWithFilters,
} from "machine/components/getMachinesFilteredField";

type SiteGroupData = {
  siteGroup: string | null;
  location: string | null;
  machine: string | null;
};

type TSiteGroupContext = SiteGroupData & {
  setSiteGroup: (data: Partial<SiteGroupData>) => void;
};

const getEmptyContext = (): SiteGroupData => ({
  siteGroup: null,
  location: null,
  machine: null,
});

const SiteGroupContext = createContext<TSiteGroupContext>({} as TSiteGroupContext);
const NOT_GROUPPED_VALUE = "*/not_group";

const useLocationsFilter: UseCustomLocationsFilters = () => {
  const value = useFieldData<string | undefined>("siteGroup", "values");

  return {
    locationGroupNodeId: value,
  };
};

const useMachinesFilter: UseCustomMachinesFilters = () => {
  const siteGroupValue = useFieldData<string | undefined>("siteGroup", "values");
  const locationNodeId = useFieldData<string | undefined>("location", "values");

  return {
    locationGroupNodeId: siteGroupValue === NOT_GROUPPED_VALUE ? undefined : siteGroupValue,
    locationNodeId,
  };
};

const fields: CustomRenderFields[] = [
  getSiteGroupsFilterField({
    name: "siteGroup",
    label: "administration.lg.title_one",
    variant: ["sm", "dropdown"],
    labelVariant: "responsive",
    isClearable: true,
    calculation: {
      // clear all values when site group change
      updates: (siteGroup) => ({ ...getEmptyContext(), siteGroup }),
    },
  }),
  getSelectLocationsFieldWithFilters(
    {
      name: "location",
      label: "location.location_one",
      variant: ["sm", "dropdown"],
      labelVariant: "responsive",
      isClearable: true,
      isMulti: false,
      calculation: {
        // clear machine value when location change
        updates: (location, _, { siteGroup }) => ({ ...getEmptyContext(), siteGroup, location }),
      },
    },
    useLocationsFilter,
  ),
  getMachinesFilterFieldWithFilters(
    {
      name: "machine",
      label: "machine.machine_one",
      variant: ["sm", "dropdown"],
      labelVariant: "responsive",
      isClearable: true,
    },
    useMachinesFilter,
  ),
];

export const SiteGroupContextProvider = ({ children }) => {
  const [data, setData] = useState<SiteGroupData>(getEmptyContext);
  const value = useMemo(() => ({ ...data, setSiteGroup: (d) => setData((p) => ({ ...p, ...d })) }), [data, setData]);
  return <SiteGroupContext.Provider value={value}>{children}</SiteGroupContext.Provider>;
};

export const useSiteGroupContext = () => useContext(SiteGroupContext);

const getValue = (v: string | string[] | null, asList: boolean) =>
  !v ? undefined : asList ? (Array.isArray(v) ? v : [v]) : Array.isArray(v) ? v[0] : v;

export const useMappedSiteGroupContext = <T extends boolean>(
  asList: T,
): [
  {
    location: (true extends T ? string[] : string) | undefined;
    machine: (true extends T ? string[] : string) | undefined;
    siteGroup: (true extends T ? string[] : string) | undefined;
  },
  TSiteGroupContext,
] => {
  const context = useSiteGroupContext();
  const mapped = useMemo(
    () => ({
      location: getValue(context.location, asList),
      machine: getValue(context.machine, asList),
      siteGroup: getValue(context.siteGroup, asList),
    }),
    [context.location, context.machine, context.siteGroup, asList],
  );

  return [mapped as any, context];
};

const StyledContainer = styled(Container)`
  ${tw`grid grid-cols-3 gap-1 sm:gap-3 z-10 print:hidden`}
  > * {
    ${tw`sm:(max-w-xs)`}
  }
`;

export const useFilteredLocationNodeIds = () => {
  const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const { data } = useFindAllLocationGroupsFilteredQuery({
    variables: {
      searchRequest: {
        page: 0,
        size: 1,
      },
      locationGroupFilters: {
        nodeIds: siteGroup ?? [],
      },
    },
  });
  return useMemo(
    () =>
      location
        ? location
        : ((((data?.findAllLocationGroupsFiltered?.result ?? []) as SmartLocationGroupDtoOut[]).at(0)?.locations ?? [])
            .map((l) => l?.nodeId)
            .filter((id) => id != null) as string[]),
    [data, location],
  );
};

export const SiteGroupPicker: React.FC = () => {
  const { setSiteGroup, location, machine, siteGroup } = useSiteGroupContext();
  const form = useForm<SiteGroupData>({ onSubmit: () => null, initial: { location, machine, siteGroup } });
  const dom = useFormGenerator(fields, customRender);

  useEffect(
    () =>
      form.useStore.subscribe(
        (s) => s.values,
        (s) =>
          setSiteGroup({
            ...getEmptyContext(),
            ...s,
            siteGroup: s.siteGroup === NOT_GROUPPED_VALUE ? null : s.siteGroup,
          }),
      ),
    [form.useStore, setSiteGroup],
  );

  useEffect(() => {
    machine && form.useStore.getState().onChangeField("machine", "onChange", machine);
  }, [machine, form.useStore]);

  return (
    <FormProvider form={form}>
      <StyledContainer variant="shadow">{dom}</StyledContainer>
    </FormProvider>
  );
};
