import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "LOCATION_GROUPS",
    text: "administration.lg.title_other",
  },
  {
    route: "LOCATION_GROUP_UPDATE",
    text: "administration.lg.create",
  },
];
