import {
  addDays,
  endOfDay,
  endOfMonth,
  isAfter,
  parse,
  parseISO,
  set,
  setHours,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
  subMonths,
  subWeeks,
  toDate,
} from "date-fns";

import { dateToISOLocal } from "common/helpers";
import { timeSettings } from "user/helpers/timeSettings";

export type Duration =
  | "hour"
  | "day"
  | "7days"
  | "30days"
  | "lastDay"
  | "lastWeek"
  | "lastMonth"
  | "currentDay"
  | "currentWeek"
  | "currentMonth"
  | "custom";

export type DateRangeDuration = Duration;

export type DateRangeFormatted = {
  from: string;
  to: string;
  duration: Duration;
};

const noon = setHours(new Date(0), 12);

const createDayBreak = (dayBreakString: string) => parse(dayBreakString, "HH:mm", new Date(0));
const setDayBreakTime = (date: Date, dayBreak: Date) =>
  set(date, {
    hours: dayBreak.getHours(),
    minutes: dayBreak.getMinutes(),
    seconds: 0,
    milliseconds: 0,
  });

const isDayBreakPM = (dayBreak: Date) => isAfter(dayBreak, noon);

const applyDayBreak = (period: DateRangeFormatted, dayBreak: string): DateRangeFormatted => {
  if (period.duration === "hour") {
    return period;
  }

  const dayBreakDate = createDayBreak(dayBreak);
  const periodStart = parseISO(period.from);
  const periodEnd = parseISO(period.to);

  const interval = {
    start: setDayBreakTime(isDayBreakPM(dayBreakDate) ? subDays(periodStart, 1) : periodStart, dayBreakDate),
    end: setDayBreakTime(isDayBreakPM(dayBreakDate) ? periodEnd : addDays(periodEnd, 1), dayBreakDate),
  };

  return {
    from: dateToISOLocal(toDate(interval.start)),
    to: dateToISOLocal(toDate(interval.end)),
    duration: period.duration,
  };
};

const getDatesByDuration: Record<Exclude<Duration, "custom">, (date?: Date) => DateRangeFormatted> = {
  hour: (date = new Date()) => ({
    from: dateToISOLocal(subHours(date, 1)),
    to: dateToISOLocal(date),
    duration: "hour",
  }),
  day: (date = new Date()) => ({ from: dateToISOLocal(subDays(date, 1)), to: dateToISOLocal(date), duration: "day" }),
  "7days": (date = new Date()) => ({
    from: dateToISOLocal(subDays(date, 7)),
    to: dateToISOLocal(date),
    duration: "7days",
  }),
  "30days": (date = new Date()) => ({
    from: dateToISOLocal(subDays(date, 30)),
    to: dateToISOLocal(date),
    duration: "30days",
  }),
  lastDay: (date = new Date()) => ({
    from: dateToISOLocal(subDays(startOfDay(date), 1)),
    to: dateToISOLocal(subDays(endOfDay(date), 1)),
    duration: "lastDay",
  }),
  lastWeek: (date = new Date()) => ({
    from: dateToISOLocal(timeSettings.get.startOfWeekByDate(startOfDay(subWeeks(date, 1)))),
    to: dateToISOLocal(timeSettings.get.endOfWeekByDate(startOfDay(subWeeks(date, 1)))),
    duration: "lastWeek",
  }),
  lastMonth: (date = new Date()) => ({
    from: dateToISOLocal(startOfMonth(subMonths(date, 1))),
    to: dateToISOLocal(endOfMonth(subMonths(date, 1))),
    duration: "lastMonth",
  }),
  currentDay: (date = new Date()) => ({
    from: dateToISOLocal(startOfDay(date)),
    to: dateToISOLocal(endOfDay(date)),
    duration: "currentDay",
  }),
  currentWeek: (date = new Date()) => ({
    from: dateToISOLocal(timeSettings.get.startOfWeekByDate(date)),
    to: dateToISOLocal(timeSettings.get.endOfWeekByDate(date)),
    duration: "currentWeek",
  }),
  currentMonth: (date = new Date()) => ({
    from: dateToISOLocal(startOfMonth(date)),
    to: dateToISOLocal(endOfMonth(date)),
    duration: "currentMonth",
  }),
};

export { createDayBreak, getDatesByDuration, applyDayBreak };
