import tw, { styled } from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { uploadConfigurationFile } from "administration/helpers";
import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  GenerateMachineViewTabDocument,
  GetAllTemplatesFilteredDocument,
  GetDeploymentsForMachineNodeIdDocument,
  TemplateDtoIn,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} from "generated";

import { ConfigTemplateFetched } from "../types";

import { ConfigTemplateFileSelection } from "./ConfigTemplateFileSelection";
import { ConfigTemplateParts } from "./ConfigTemplateParts";

export type ConfigTemplateFormData = Omit<TemplateDtoIn, "nodeId"> & { file: File };

const Wrapper = styled.div`
  ${tw`grid gap-4 grid-flow-row pt-4`}
`;

const getFields: (
  updateForm: boolean | undefined,
  initialData?: Partial<ConfigTemplateFormData>,
) => CustomRenderFields[] = (updateForm, initialData) => [
  {
    type: "container",
    fields: [
      {
        type: "text",
        name: "name",
        label: "administration.rc.name",
        validate: { type: "string", required: true },
      },
      {
        name: "fileSelection",
        type: "custom",
        Component: !updateForm ? ConfigTemplateFileSelection : () => <ConfigTemplateParts data={initialData} />,
      },
      {
        type: "hidden",
        name: "fileName",
        validate: { type: "string", required: [true, "administration.rc.fileRequired"] },
      },
      {
        type: "condition",
        when: "fileName",
        is: Boolean,
        fields: [
          {
            type: "container",
            Component: Wrapper,
            fields: [
              {
                type: "textarea",
                name: "description",
                label: "administration.rc.description",
                rows: 8,
              },
            ],
          },
        ],
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<ConfigTemplateFormData>;
  initialData?: Partial<ConfigTemplateFormData>;
  updateForm?: boolean;
};

const ConfigTemplateForm: React.FC<Props> = ({ onSubmit, initialData, updateForm }) => (
  <>
    <Text variant="heading" tKey="administration.rc.addRc" tw="pb-4 block" />
    <div tw="h-full overflow-auto">
      <SchemaForm<ConfigTemplateFormData>
        fields={getFields(updateForm, initialData)}
        onSubmit={onSubmit}
        initial={initialData}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end">
            <Button type="submit" tw="mt-6" data-test="submitForm">
              <Text tKey={updateForm ? "administration.rc.update" : "administration.rc.submit"} />
            </Button>
          </div>
        )}
      />
    </div>
  </>
);

export const UpdateConfigTemplate = ({
  nodeId,
  name,
  description,
  version,
  fileName,
  parts,
}: WithoutNullableKeys<ConfigTemplateFetched>) => {
  const [updateTemplate] = useUpdateTemplateMutation();

  return useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <ConfigTemplateForm
        updateForm
        initialData={{ name, description, version, fileName, parts }}
        onSubmit={(template) =>
          updateTemplate({
            variables: { nodeId, template },
            ignoreResults: true,
          })
            .then(() => useToast.actions.show("administration.rc.updateSuccess", { variant: "success" }))
            .catch(() => useToast.actions.show("administration.rc.updateError", { variant: "error" }))
        }
      />
    ),
  });
};

export const CreateConfigTemplate = () => {
  const [createTemplate] = useCreateTemplateMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <ConfigTemplateForm
        onSubmit={({ file, ...template }) =>
          createTemplate({
            variables: { template },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(GenerateMachineViewTabDocument, cache);
              evictQuery(GetDeploymentsForMachineNodeIdDocument, cache);
              evictQuery(GetAllTemplatesFilteredDocument, cache);
            },
          })
            .then((v) => {
              if (!v.data?.createTemplate?.nodeId) throw new Error();

              useToast.actions.show("administration.rc.addSuccess", { variant: "success" });

              return uploadConfigurationFile(v.data?.createTemplate.fileName, file);
            })
            .then(() => toggle())
            .catch(() => useToast.actions.show("administration.rc.addApiError", { variant: "error" }))
        }
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showFormModal">
      <Text tKey="administration.rc.addRc" />
    </Button>
  );
};
