import { CustomFieldsSchema, CustomRenderInput, SchemaField } from "../schema";

import { CheckboxField, CheckboxProps } from "./Checkbox";
import { DateField, DateInputProps } from "./DateInput";
import { InputField, InputProps } from "./Input";
import { NumberRangeField, NumberRangeProps } from "./NumberRange";
import { PhoneNumberField, PhoneNumberFieldProps } from "./PhoneNumberField";
import { RadioField, RadioProps } from "./Radio";
import { SelectField, SelectProps } from "./Select";
import { TransferBoxField, TransferBoxProps } from "./TransferBox";

export type CustomRenderFieldTypes = {
  input: SchemaField<InputProps>;
  select: SchemaField<SelectProps>;
  checkbox: SchemaField<CheckboxProps>;
  date: SchemaField<DateInputProps>;
  radio: SchemaField<RadioProps>;
  transferBox: SchemaField<TransferBoxProps>;
  phone: SchemaField<PhoneNumberFieldProps>;
  numberRange: SchemaField<NumberRangeProps>;
};

export type CustomRenderFields = CustomFieldsSchema<CustomRenderFieldTypes[keyof CustomRenderFieldTypes]>;

export const customRender: CustomRenderInput<CustomRenderFields> = (field, key) => {
  switch (field.type) {
    case "number":
    case "text":
    case "textarea":
    case "email":
    case "hidden":
    case "password":
      return <InputField {...field} key={key} errorSub />;
    case "checkbox":
      return <CheckboxField {...field} key={key} errorSub />;
    case "radio":
      return <RadioField {...field} key={key} errorSub />;
    case "select":
      return <SelectField {...field} key={key} errorSub />;
    case "date":
      return <DateField {...field} key={key} errorSub />;
    case "transferBox":
      return <TransferBoxField {...field} key={key} errorSub />;
    case "phone":
      return <PhoneNumberField {...field} key={key} errorSub />;
    case "numberRange":
      return <NumberRangeField {...field} key={key} errorSub />;
  }
};
