import { baseApiUrl } from "mapbox-gl";

import { CONFIG } from "common/config";

export const getLocationFromCoordinates = async (latitude: number | string, longitude: number | string) => {
  try {
    const response = await fetch(
      `${baseApiUrl}/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=${CONFIG.mapboxToken}`,
    );
    const data = await response.json();
    const getValue = (key: string) => data.features[0].context.find((context) => context.id.startsWith(key));

    const country = getValue("country");

    return {
      city: getValue("place")?.text,
      country: country?.text,
      countryShortCode: country?.short_code,
    };
  } catch (error) {
    return {
      city: undefined,
      country: undefined,
      countryShortCode: undefined,
    };
  }
};
