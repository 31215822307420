export const en = {
  alerts: {
    title: "Alerts",
    description: "Configure, create, and delete alerts",
    history: "Alerts history",
    historyDescription:
      "Get the history of sent alerts and their recipients. You must configure alerts before building Alerts history reports or the report shows up empty.",
    date: "Date",
    level: "Level",
    text: "Alert text",
    type: "Type",
    contact: "Contact",
    name: "Name",
    notifications: "Notifications",
    filtering: "Filtering",
    timeBetween: "is between {{from}} and {{to}}",
    recipients: "Recipients",
    goBack: "Back",
    create: "Create Alert",
    update: "Update Alert",
    createSuccess: "Alert created successfully",
    createError: "Alert creation failed",
    updateSuccess: "Alert updated successfully",
    updateError: "Alert update failed",
    deleteSuccess: "Alert deleted successfully",
    deleteError: "Alert deletion failed",
    delete: "Delete",
    close: "Close",
    deleteAlert: "Delete alert",
    deleteDescription: "Alert <0>{{name}}</0> will be deleted permanently.",
    fromTime: "From",
    toTime: "To",
    currency: "Currency",
    amount: "Amount",
    selectToShowFilters: "Select triggering event to show filters",
    all: "All",
    filtered: "Filtered",
    notFiltered: "Not filtered",
    filters: "Filters",
    alertLevel: {
      ERROR: "Error",
      INFO: "Info",
      UNKNOWN: "Unknown",
      WARNING: "Warning",
    },
    triggerType: {
      EMAIL: "Email",
      SMS: "Sms",
      WEBHOOK: "Webhook",
      WEBHOOK_SNS: "Webhook SNS",
      WEBHOOK_URL: "Webhook URL",
      UNKNOWN: "Unknown",
    },
    filterType: {
      MESSAGE_TYPE: "Message type",
      MACHINE_DISCONNECTED: "Machine disconnected",
      MACHINE_CONNECTED: "Machine connected",
      ERROR_CLEARED: "Error cleared",
      ERROR_INFORMATION: "Error information",
      ERROR_LOCATION: "Error location",
      ERROR_KIND: "Error kind",
      MACHINE_UUID_FILTER: "Machines",
      MESSAGE_OLD_STATUS: "Message old status",
      LOCATION_FILTER: "Sites",
      ERROR_SEVERITY: "Error severity",
      DOOR_OPEN: "Door open",
      MESSAGE_STATUS: "Message status",
      TIME_OF_DAY: "Time of day",
      TRANSACTION_SUB_TYPE: "Transaction sub type",
      ERROR_UNCLEARED: "Error uncleared",
      DOOR_CLOSED: "Door closed",
      BOX_GOES_ABOVE_COUNT: "Box goes above count",
      BOX_GOES_BELOW_COUNT: "Box goes below count",
      TRANSACTION_TYPE: "Transaction type",
      DEVICE_ID: "Device ID",
      TRANSACTION_DEVICE_ID: "Transaction device ID",
      ANY_BOX_EXCEEDS_COUNT_RATIO: "Any box exceeds count ratio",
      TRANSACTION_BOX_ID: "Transaction box ID",
      ANY_BOX_EXCEEDS: "Any box exceeds",
      CONTENT_EXCEEDS: "Content exceeds",
      USER_BALANCE_CHANGE_BELOW_THRESHOLD: "User balance change below threshold",
      USER_BALANCE_CHANGE_ABOVE_THRESHOLD: "User balance change above threshold",
      WEBHOOK: "Webhook",
    },
    messageType: "Triggering event",
    messageTypes: {
      machines: "Machines",
      UNKNOWN: "Unknown",
      ERROR: "Error are received",
      MACHINE_CONTENT_CHANGE: "Contents are updated",
      MACHINE_ONLINE_STATUS_CHANGE: "Online status changed",
      SYSTEM_STATUS: "Status is updated",
      TRANSACTION: "Transactions",
      TRANSACTION_WITH_BOX_CONTENT: "Transaction with box content",
    },
  },
};
