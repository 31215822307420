import { useCallback, useEffect } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Toast } from "./Toast";
import { ToastTuple, useToast } from "./useToast";

const ToastController: React.FC<{ id: ToastTuple[0]; content: ToastTuple[1]; data: ToastTuple[2] }> = ({
  id,
  content,
  data,
}) => {
  const willClose = useToast(useCallback((s) => s.closeSignal[id], [id]));

  useEffect(() => {
    if (willClose) {
      const timeout = setTimeout(() => useToast.actions.delete(id), 200);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [willClose, id]);

  useEffect(() => {
    if (!data.timeout) return;

    const timeout = setTimeout(() => useToast.actions.close(id), data.timeout);

    return () => {
      clearTimeout(timeout);
    };
    // should run only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Toast willClose={willClose} variant={data.variant} onClose={() => useToast.actions.close(id)}>
      {content}
    </Toast>
  );
};

export const ToastProvider: React.FC = () => {
  const toasts = useToast((s) => s.toasts);

  return toasts.length ? (
    <div tw="grid gap-2 fixed right-0 bottom-0 w-96 px-2 py-2 z-100">
      {toasts.map(([id, content, data]) => (
        <ToastController id={id} key={id} content={content} data={data} />
      ))}
    </div>
  ) : null;
};
