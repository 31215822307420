import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { EntityDelete } from "base/components";
import { ComponentModalProps, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { GetAllLogsDocument, useDeleteLogMutation } from "generated";
import { Trans } from "i18n";
import { deleteLogArchive } from "zts/helpers/fileAccess";
import { LogFetched } from "zts/types";

type LogData = WithoutNullableKeys<Pick<LogFetched, "nodeId" | "zipFileName">>;

type Props = LogData & ComponentModalProps;

const DeleteLog: React.FC<Props> = ({ nodeId, zipFileName, onClose }) => {
  const [deleteLog, { loading }] = useDeleteLogMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "zts.commands.logs.deleteSuccess" : "zts.commands.logs.deleteError", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deleteLog({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => evictQuery(GetAllLogsDocument, cache),
      });
      const deleted = !!result.data?.deleteLog;
      if (deleted) onClose?.();
      deleteLogArchive(zipFileName);
      showToast(deleted);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete
      actionText="zts.commands.logs.deleteTitle"
      loading={loading}
      onDelete={handleSubmit}
      onClose={onClose}
    >
      <Trans i18nKey="zts.commands.logs.deleteDesc" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const useDeleteLogModal = ({ nodeId, zipFileName }: LogData) =>
  useModalComponent({
    Component: <DeleteLog nodeId={nodeId} zipFileName={zipFileName} />,
  });
