import { RouteConfig, RouteValue } from "routing";

import { MachineUser, MachineUserCreate, MachineUserUpdateParams, UpdateMachineUserScreen } from "./pages";

export type MachineUserPaths = {
  MACHINE_USERS: RouteValue;
  MACHINE_USER_CREATE: RouteValue;
  MACHINE_USER_UPDATE: RouteValue<{ params: MachineUserUpdateParams }>;
};

export const MACHINE_USER: RouteConfig<MachineUserPaths> = {
  MACHINE_USERS: {
    path: "/administration/machine-user",
    Component: MachineUser,
  },
  MACHINE_USER_CREATE: {
    path: "/administration/machine-user/create",
    Component: MachineUserCreate,
  },
  MACHINE_USER_UPDATE: {
    path: "/administration/machine-user/update/:userId",
    Component: UpdateMachineUserScreen,
  },
};
