import { ColumnFiltersState, HeaderContext } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { SelectRaw } from "common/form/renderFields";
import { Maybe, ReportPairDtoOut } from "generated";

type ColumnFilter = Maybe<Array<Maybe<ReportPairDtoOut>>> | undefined;

export const getColumnFilter = (
  filters: ColumnFilter,
  filterName: string,
  isMulti?: boolean,
): ((props: HeaderContext<any, any>) => any) => {
  const filter = filters?.find((f) => f?.key === filterName);
  const options = filter?.values?.length
    ? ([...filter.values].filter(Boolean) as string[])
        .sort((a, b) => a.localeCompare(b))
        .map((v) => ({ value: v, label: v }))
    : [];

  return !options.length
    ? () => null
    : ({ column: { getFilterValue, setFilterValue } }) => (
        <SelectRaw
          variant="sm"
          name={filterName}
          options={options}
          onChange={setFilterValue}
          value={getFilterValue() as any}
          maxMenuHeight={200}
          isMulti={isMulti}
          isClearable
          closeMenuOnSelect
        />
      );
};

const initialFilters = [];
export const useColumnFilters = <K extends string>(
  filterMap: ReadonlyArray<readonly [filter: K, columnId: string, mapFn?: (filter: string) => any]> | [K, string][],
): [Record<K, any>, ColumnFiltersState, React.Dispatch<React.SetStateAction<ColumnFiltersState>>] => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialFilters);

  const filters = useMemo(() => {
    return (filterMap as any).reduce((acc, [k, v, mapFn]) => {
      const value = columnFilters.find((f) => f.id === v)?.value;
      if (value) acc[k] = mapFn ? mapFn(value) : value;
      return acc;
    }, {} as Record<K, any>);
  }, [columnFilters, filterMap]);

  return [filters, columnFilters, setColumnFilters];
};

export const useColumnFiltersArray = <K extends string>() =>
  useState<{ id: K; value: unknown }[]>(initialFilters) as [
    { id: K; value: unknown }[],
    React.Dispatch<React.SetStateAction<ColumnFiltersState>>,
  ];
