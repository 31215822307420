import { ZonedDateTime, floatToComa, splitDateAndZone } from "common/helpers";
import { TFunction } from "i18n";

const getZoneAndOffsetDateString = (dateString: ZonedDateTime, t: TFunction, formatString: string) => {
  const [date, offset, zone] = splitDateAndZone(dateString);
  const dateFormatted = t("dateFormat", { date: new Date(date), formatString })?.trim();

  if (offset || zone) {
    return `${dateFormatted}${offset ? `\n(UTC ${offset})` : ""}${zone ? `\n${zone}` : ""}`;
  }

  return dateFormatted;
};

export const accessors = {
  date: (dateString: string | Date | undefined | null, t: TFunction, formatString = "PPpp") =>
    dateString ? t("dateFormat", { date: new Date(dateString), formatString })?.trim() : "",
  dateCsv: (dateString: string | Date | undefined | null, t: TFunction) =>
    accessors.date(dateString, t, "dd-MMM-yyyy hh:mm"),
  zonedDateTime: (dateString: string | undefined | null, t: TFunction, formatString = "PPpp") =>
    dateString ? getZoneAndOffsetDateString(dateString, t, formatString) : "",
  zonedDateTimeCsv: (dateString: string | undefined | null, t: TFunction) =>
    accessors.zonedDateTime(dateString, t, "dd-MMM-yyyy hh:mm"),
  number: (value: number | string, t: TFunction) => t("numberFormat", { value }),
  numberCsv: (value: string | null | undefined) => (value ? floatToComa(value) : ""),
  duration: (seconds: number, t: TFunction) => t("dateFormat", { value: seconds, formatString: "duration" }),
};
