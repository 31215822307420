import { client } from "client";
import { numberWithDecimalCount } from "common/helpers";
import { FindRmValuesForRmBoxDocument, FindRmValuesForRmBoxQuery, FindRmValuesForRmBoxQueryVariables } from "generated";

import { CashInventoryType } from "./types";

export const getCashInventoryCsvData = async (cashInventoryRows: CashInventoryType[]) => {
  const boxNodeIds = Array.from(new Set(cashInventoryRows?.flatMap((d) => d?.boxNodeIds ?? []) ?? []));
  const subrows = await client.query<FindRmValuesForRmBoxQuery, FindRmValuesForRmBoxQueryVariables>({
    query: FindRmValuesForRmBoxDocument,
    variables: { nodeIds: boxNodeIds },
  });

  const boxNodeRows = ((subrows as any)?.data?.findRmValuesForRmBox ?? []).flatMap((d) =>
    d?.values
      // we generate data with empty counts for csv
      // ?.filter((v) => !!v?.count)
      ?.map((v) => ({
        boxNodeId: d?.nodeId,
        ...d,
        ...v,
        actualDenomination: numberWithDecimalCount.merge(v?.denomination || 0, v?.decimals || 0),
        actualTotal: numberWithDecimalCount.merge(v?.total || 0, v?.decimals || 0),
      })),
  );

  const cashInventoryRowsByBoxNodeId = cashInventoryRows.flatMap((r) =>
    r.boxNodeIds ? r.boxNodeIds.map((b) => ({ ...r, boxNodeId: b })) : [],
  );
  const csvDataRows = boxNodeRows.map((b) => ({
    ...b,
    ...cashInventoryRowsByBoxNodeId.find((r) => r.boxNodeId === b.boxNodeId),
  }));

  return csvDataRows;
};
