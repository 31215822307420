export const fr = {
  machine: {
    machine_one: "Machine",
    machine_other: "Machines",
    machineStatus: "Statut Machine",
    deviceInfo: "Détails de la machine",
    sensorInfo: "Détails des capteurs",
    name: "Nom",
    noName: "Machine sans nom",
    contents: "Contenu",
    machineContents: "Contenu de la machine",
    machineContentsDescription: "Affiche les informations sur le contenu de la machine",
    usage: "Utilisation",
    machineUsage: "Utilisation de la machine",
    machineUsageDescription:
      "Affiche les informations sur la valeur des transactions de dépôt et de distribution sur la machine",
    health: "Statut",
    machineHealth: "Statut de la machine",
    machineHealthDescription:
      " Affiche les 10 dernières erreurs actives sur la machine, c'est-à-dire les erreurs qui n'ont pas encore été effacées",
    information: "Information",
    extendedInfo: "Information étendue",
    errorCode: "Code d’erreur",
    cleared: "Résolue",
    refillStatus: "Statut du remplissage",
    refillStatusTooltip:
      "'$t(machine.refillStatuses.REFILL)' si l'une des dénominations du recycleur passe en dessous du montant minimum configuré. '$t(machine.refillStatuses.APPROACHING)' si une dénomination tombe en dessous du niveau configuré par défaut '$t(machine.refillStatuses.OK)' s’il n’y a pas d’action nécessaire",
    machineAlias: "Alias de la machine",
    machineSn: "Numéro de série de la machine:",
    machineName: "Nom de la machine",
    machineType_one: "Type de la machine",
    machineType_other: "Types de machine",
    machinesCount: "Nombre de machines",
    nodeStatus: "Statut",
    nodeChangeDate: "Node change date",
    changed: "Modifié",
    createMachine: "Créer une machine",
    createMachineDescription:
      " Vous créez une machine lorsque vous souhaitez créer un nouveau périphérique pour Connect",
    createMachineSuccess: "Machine créée avec succès",
    createMachineError: "La création de la machine a échoué",
    editMachine: "Editer la machine",
    updateMachine: "Mettre à jour la machine",
    editMachineDescription: "Veuillez changer les détails de la machine pour les modifier",
    editMachineSuccess: "Modification machine sauvegardée avec succès",
    editMachineError: "La modification de la machine a échoué",
    delete: "Supprimer",
    deleteMachine: "Supprimer la machine",
    deleteDescription: "La machine <0>{{name}}</0> sera supprimée définitivement.",
    deleteSuccess: "Machine supprimée avec succès",
    deleteError: "La suppression de la machine a échoué",
    deleteClose: "Fermer",
    nodeId: "Id noeud",
    dateTime: "Date et heure de la machine",
    version: "Version",
    receiver: "Récepteur",
    sequence: "Séquence",
    messageSequence: "Numéro de séquence du message",
    messageType: "Type de message",
    uuid: " UUID machine",
    comment: "Commentaire",
    deviceId: "Id Périphérique",
    boxId: "Id boite",
    containerId: "Id conteneur",
    rejects: "Rejets",
    totalAmount: "Montant total",
    movementType: "Type de mouvement",
    resultingContentRejects: "Rejet",
    resultingContentTotalAmount: "Montant total",
    resultingContentType: "Type",
    deleted: "Supprimé",
    detached: "Déconnecté",
    inactive: "Inactif",
    active: "Actif",
    unknown: "Inconnue",
    online: "En ligne",
    offline: "Hors ligne",
    refill: "Remplissage",
    ok: "OK",
    goBack: "Retour",
    approaching: "Bientôt au niveau défini",
    notAvailable: "Non disponible",
    kind: "Type",
    resolved: "Résolu",
    device: "Machine",
    filterMachines: "Filtrer les machines",
    preview: "Aperçu",
    severity: "Severité",
    severityType: {
      Error: "Erreur",
      Warning: "Notification",
    },
    status: {
      CatchingUp: " Synchronisation ",
      Offline: "Hors ligne",
      Online: "En ligne",
      Unknown: "Inconnu",
    },
    availableForDispense: "Disponible pour la distribution",
    availableForDispenseTooltip: "Affiche le montant qui peut être distribué par la machine",
    notDispensable: "Non distribuable",
    notDispensableTooltip: "Affiche le montant qui peut être remis en banque dans la machine",
    availabilityRatio: "Ratio disponible pour la distribution",
    availabilityRatioTooltip:
      " Le rapport entre l'argent disponible pour la distribution et l'argent total, pour la devise sélectionnée",
    openShifts: "Services en cours",
    cashInCirculation: "Argent en circulation",
    netCashChange: "Variation de la trésorerie nette",
    availableCashChange: "Variation de la trésorerie disponible",
    notes: "Billets",
    notesTooltip: "Valeur de tous les billets de banque actuellement dans l'appareil pour la devise sélectionnée",
    counterfeitNotes: "Faux billets",
    counterfeitNotesTooltip: "Valeur de tous les billets qui ont été détectés comme faux pour la devise sélectionnée",
    checks: "Chèques",
    checksTooltip: "Valeur totale de tous les chèques pour la devise sélectionnée",
    coins: "Pièces",
    coinsTooltip: "Valeur de toutes les pièces actuellement dans l'appareil pour la devise sélectionnée",
    suspectNotes: "Billets suspects",
    suspectNotesTooltip:
      "Valeur de tous les billets qui ont été détectés comme suspects ou qui n'ont pas atteint le niveau d’acceptation tel que configuré, pour la devise sélectionnée",
    vouchers: "Coupons",
    vouchersTooltip: "Valeur totale de tous les coupons pour la devise sélectionnée",
    claimedValue: "Valeur déclarée",
    claimedValueTooltip:
      "Valeur des articles saisis manuellement pour la devise sélectionnée (généralement placés dans une boîte de dépôt ou un sac)",
    timelineExplorer: "Explorateur de transactions",
    timelineExplorerDescription:
      "Trie les transactions (seules les 1 000 dernières transactions sont affichées) par date de transaction",
    noData: "Pas de machine trouvée",
    specName: "Nom",
    licenseLabel: "Etiquette de licence",
    licenseLevel: "Niveau de licence",
    noErrorsFound: "Aucune erreur trouvée pour cette machine",
    showBy: "Montrer par",
    type: "Type",
    aboveDefault: "Au-dessus des niveaux par défaut",
    aboveMinimum: "Supérieur au minimum, mais inférieur au défaut",
    belowMinimum: "En dessous des niveaux minimaux",
    totalDeposits: "Montant total des dépôts",
    totalDepositsTooltip: "Valeur totale de tous les dépôts depuis le début de la journée",
    totalDispenses: "Montant total distribué",
    totalDispensesTooltip: "Valeur totale de toutes les distributions depuis le début de la journée",
    changeNetCash: "Variation de la trésorerie nette",
    changeNetCashTooltip:
      " Différence entre l'argent disponible pour la distribution au début de la journée et le total cumulé de tous les dépôts d'argent. Une valeur positive signifie que vous générez de l'argent liquide et une valeur négative que vous en consommez",
    changeAvailableCash: "Variation de la trésorerie disponible",
    changeAvailableCashTooltip:
      "Différence entre l'argent disponible pour la distribution au début de la journée et l'argent actuellement disponible pour la distribution",
    usageSinceMidnight: "Affiche l'utilisation depuis aujourd'hui à 00:00",
    devInfoMissing: "Il n'y a pas de périphérique connecté",
    valueBagsMissing: "Il n'y a pas de valeur",
    deviceMovementsMissing: "Il n'y a pas de mouvement",
    movingBetweenSites: "Déplacement de la machine d'un site à l'autre",
    movingBetweenSitesDesc:
      "Toutes les données historiques seront déplacées avec l'appareil. Pour conserver les données historiques, l'appareil doit être configuré comme un nouvel appareil et réenregistré avec la plateforme.",
    lockedDueToFlipFlopping: "est verrouillée en raison de la bascule",
    locked: "Vérouillé",
    values: {
      denomination: "Dénomination",
      exchangeRate: "Taux de change",
      exchangeRateDecimals: "Nombre de décimales",
      pieceValue: "Valeur",
      reference: "Référence",
      total: "Total",
      type: "Type",
      c2Count: "C2Count",
      c3Count: "C3Count",
      c4bCount: "C4bCount",
      claimedValueType: "Type de valeur déclarée",
      count: "Quantité",
      creditCat2: "CreditCat2",
      creditCat3: "CreditCat3",
      currency: "Devise",
      customType: "Type personnalisé",
      decimals: "Decimales",
    },
    info: {
      deviceId: "Id machine",
      desc: "Affiche des informations diverses sur la machine. Par exemple, le type de machine et la date d'activation",
      devInfo: "Information machines",
      devInfoMissing: " Il n'y a pas de périphérique connecté ",
      firmware: "Firmware",
      hardware: "Matériel",
      accType: "Type d’accès",
      protocol: "Protocole",
      name: "Nom",
      nodeId: "Id périphérique",
      serialNumber: "Numéro de série",
      version: "Version",
      capabilities: "Capacité",
      codVersion: "Version de COD",
    },
    licensing: {
      title: "Licence (optionnel)",
      title_singular: "Licence",
      currentLicense: "License actuelle",
      noLicenseId: "Pour mettre à jour la licence, la machine doit être en ligne",
      keepBasic: "Conserver la licence basique",
      upgrade: "Mettre à niveau la licence",
      option: "Option",
      discountCode: "Code de réduction (optionnel)",
      costAcknowledgement: "Je reconnais qu'il peut y avoir un coût associé à la mise à niveau de cette licence",
      agreementNeeded: " Un accord est nécessaire",
      currentlyActive: " Actuellement active",
      currentlyInactive: " Actuellement inactive",
      licenseKey: "Clé de licence",
      active: "Actif",
      unclickToActivate: "L'abonnement à la licence est actif, décliquez pour le désactiver",
      clickToActivate: "L'abonnement à la licence est actuellement désactivé, cliquez pour l'activer",
      subscriptionRenewalDate: "Date de renouvellement de l'abonnement (UTC)",
      expiration: "Expiration",
      edition: {
        basic: "License basique",
        enterpriseSubscription: "Abonnement Entreprise",
        unknown: "Inconnu",
      },
    },
    sensor: {
      deviceId: "Id périphérique",
      desc: " Affiche des informations sur la machine. Par exemple, le type de machine et la date d'activation",
      devInfo: "Informations machine",
      devInfoMissing: "Il n’y a pas de périphérique connecté",
      firmware: "Firmware",
      hardware: "Matériel",
      accType: "Type d'ACC",
      protocol: "Protocole",
      name: "Nom",
      nodeId: "Id capteur",
      serialNumber: "Numéro de série",
      version: "Version",
      capabilities: "Capacité",
    },
    refillStatuses: {
      APPROACHING: " Bientôt au niveau défini",
      OK: "OK",
      REFILL: "Refill",
    },
    remoteOperationMgmt: {
      deployedConfiguration: " Configuration déployée",
      configuration: "Configuration",
      software: "Logiciel",
      version: "Version",
      installing: "Installation",
      status: "Status",
      cancel: "Annulation",
      unknown: "Inconnu",
      statuses: {
        CANCELED: "Annulé",
        CANCELING: "Annulation en cours",
        DEPLOYING: "Envoyé",
        DONE: "Fait",
        FAILEd: "Echoué",
        INSTALLING: "Installation",
        PENDING: "En attente",
        SENT: "Envoyé",
        TIMEOUT: "Délai d’attente expiré",
        WAITING: "En attente",
        UNKNOWN: "Inconnu",
        undefined: "Inconnu",
      },
    },
    inventory: {
      denomination: "Dénomination",
      dispensable: "Distribuable",
      container: "Conteneur",
      bankable: "Disponible pour la banque",
    },
  },
};
