import { Table } from "@tanstack/react-table";
import { createContext, useContext, useMemo } from "react";

import { PaginationSetFunction } from "./helpers";
import { TableStorageConfig } from "./TableStorageConfig";

export type TableContextType<TData = any> = Pick<
  Table<TData>,
  "getPrePaginationRowModel" | "getPaginationRowModel" | "getState" | "setState" | "getAllLeafColumns"
> & {
  tableName: string;
  onPagination?: PaginationSetFunction;
};

const TableContext = createContext({} as TableContextType);

export const useTableDataContext = <TData,>() => useContext(TableContext) as TableContextType<TData>;

export const TableProvider = ({
  getPrePaginationRowModel,
  getPaginationRowModel,
  getState,
  setState,
  tableName,
  children,
  getAllLeafColumns,
  onPagination,
}: React.PropsWithChildren<
  TableContextType & {
    tableName: string;
  }
>) => {
  const value = useMemo<TableContextType<any>>(
    () => ({
      getPrePaginationRowModel,
      getPaginationRowModel,
      getState,
      setState,
      tableName,
      getAllLeafColumns,
      onPagination,
    }),
    [getPrePaginationRowModel, getPaginationRowModel, getState, setState, tableName, getAllLeafColumns, onPagination],
  );

  return (
    <TableContext.Provider value={value}>
      <TableStorageConfig tableContext={value} />
      {children}
    </TableContext.Provider>
  );
};
