import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { NavLink } from "appRouting";

export const SidebarLink = styled(NavLink)`
  ${tw`flex flex-row transition-colors duration-300 items-center h-10 text-gray-5 font-semibold text-xs tracking-wide truncate focus:outline-none hover:(text-gray-3) dark:text-gray-6 dark:hover:text-gray-8`}

  &.active {
    ${tw`text-primary-accent dark:text-primary-default`}
  }
`;
