export const fr = {
  transport: {
    title: "Transport",
    overview: "Aperçu du transport",
    desc: "Transport est une plateforme de gestion et de suivi des services de transport",
    corReasons: {
      title_other: "Motifs des transactions de correction",
      title_one: "Motif de la transaction de correction",
      desc: "Visualiser, gérer et créer des motifs de transaction de correction ",
      id: "Id motif ",
      name: "Nom",
      active: "Active",
      lastEdited: "Dernière édition",
      lastEditedBy: "Dernière édition par",
      create: "Créer un motif",
      remove: {
        title: "Supprimer un motif",
        success: "Motif de transaction de correction supprimé",
        error: "Échec de la suppression du motif la transaction de correction, veuillez réessayer",
        desc: "Le motif <0>{{name}}</0> sera supprimer définitivement",
        close: "Annuler",
        submit: "Supprimer",
      },
      form: {
        name: "Nom",
        active: "Activer",
        create: "Créer unmotif",
        createSubmit: "Créer",
        update: "Mettre à jour le motif",
        updateSubmit: "Mettre à jour",
      },
      createSuccess: "Motif créé avec succès",
      createError: "La création du motif a échoué",
      updateSuccess: "Motif mis à jour avec succès",
      updateError: "La mise à jour du motif a échoué",
    },
    rBalance: {
      title: "Solde",
      desc: "Rapport permettant de visualiser les soldes des utilisateurs machine",
      date: "Date",
      currencyCode: "Code devise",
      balance: "Solde",
      machineUser: "Utilisateur machine",
    },
    dashboard: {
      pickMachine: "Sélectionnez une machine pour voir le résumé du transport",
    },
    transactionType: {
      all: "Toutes les transactions",
      correction: "Transactions de correction",
      machine: "Transactions de la machine",
    },
  },
  tranCorr: {
    title: "Transactions de correction",
    desc: "La vue des transactions de correction permet de visualiser les transactions de correction et de modifier les valeurs des transactions existantes afin de corriger les rapports",
    datePeriod: "Période de correction",
    machine: "Machine",
    sequence: "Numéro de séquence",
    type: "Type",
    time: "Heure",
    date: "Date",
    utcOffset: "Décalage UTC",
    machineUser: "Utilisateur machine",
    corrReason: "Motif",
    comment: "Commentaire",
    createdBy: "Créée par",
    value: "Valeur",
    currency: "Devise",
    create: "Créer une transaction de correction",
    submit: "Envoyer",
    offsetPatternErr: "Doit être de la forme -xx.00 ou +xx.00",
    createSuccess: "Transaction de correction créée avec succès",
    createError: "La création de la transaction de correction a échoué",
  },
  tranTypes: {
    DISPENSE: "Distribution",
    DEPOSIT: "Dépôt",
    EMPTY_OUT: "Vidage",
    END_OF_SHIFT: "Fin de service",
    MANUAL_REFILL: "Remplissage manuel",
    EXCHANGE: "Echange",
    PURGE: "Vidage",
    POSSIBLE_TAMPERING: "Possible altération",
    UNFINISHED_TRANSACTION: "Transaction non terminée",
    REFILL: "Remplissage",
  },
  tranSubTypes: {
    CHANGE: "Monnaie",
    DISPENSE_SURPLUS: "Distribution du surplus",
    EXPECTED_AMOUNT: "Montant attendu",
    IN: "In",
    OUT: "Out",
    REQUESTED_AMOUNT: "Montant demandé",
    SALE: "Sale",
    DISPENSE_RETURN: "Retour de la distribution",
  },
};
