import { CheckboxRaw } from "common/form/renderFields";
import { dateFormatsStoreActions, useDateFormatsStore } from "i18n/dateFormatsStore";

export const HourFormatSetting = () => {
  const { hour12 } = useDateFormatsStore();

  return (
    <CheckboxRaw
      name="timeFormat"
      type="checkbox"
      label="user.settings.use12HourFormat"
      checked={hour12}
      onChange={() => dateFormatsStoreActions.updateFormatStringByHourFormat(!hour12)}
    />
  );
};
