export const de = {
  tenant: {
    tenant_one: "Mandant",
    tenant_other: "Mandanten",
    goBack: "Zurück",
    activeFetchErr: "Mandantendaten konnten nicht abgerufen werden",
    create: {
      title: "Mandant erstellen",
      description: "Geben Sie die Mandantendaten ein, um einen neuen Mandanten anzulegen.",
      name: "Mandantenname",
      contractNumber: "Vertragsnummer",
      billingId: "Rechnungsnummer",
      tenantId: "Mandanten ID",
      userName: "Username",
      mqttPassword: "MQTT Passwort",
      submit: "Erstellen",
      apiError: "Kann Mandanten nicht erstellen, bitte versuchen Sie es erneut",
      success: "Mandant erfolgreich erstellt",
      createDomain: "Neue Domaine erstellen",
      createUser: "Neuen User erstellen",
      newUser: "Neuer User",
      endOfDay: "EOD",
    },
    update: {
      submit: "Update",
      title: "Update Mandant",
      description: "Bitte ändern Sie die Mandantendaten, um sie zu aktualisieren.",
      apiError: "Mandant konnte nicht aktualisiert werden, bitte versuchen Sie es erneut",
      success: "Mandant erfolgreich aktualisiert",
    },
    activate: {
      title: "Aktiviere Mandant",
      description: "Mandant <0>{{name}}</0> wurde aktiviert.",
      close: "Verwerfen",
      submit: "Aktivieren",
      error: "Mandant konnte nicht aktiviert werden, bitte versuchen Sie es erneut",
      success: "Mandant erfolgreich aktiviert",
    },
    deactivate: {
      title: "Mandant deaktivieren",
      description: "Mandant <0>{{name}}</0> wird deaktiviert.",
      close: "Verwerfen",
      submit: "Deaktivieren",
      error: "Der Mandant konnte nicht deaktiviert werden, bitte versuchen Sie es erneut",
      success: "Mandant erfolgreich deaktiviert",
    },
    features: {
      titleUpdate: "Update {{name}} Eigenschaften",
      userBalance: "Benutzer-Guthaben",
      commonSoftwarePackageUploadEnabled: "Hochladen von Softwarepaketen aktiviert",
      success: "Funktionen erfolgreich aktualisiert",
      apiError:
        "Die Aktualisierung der Merkmale des Mandanten konnte nicht durchgeführt werden, bitte versuchen Sie es erneut",
      submit: "Speichern",
    },
    list: {
      tenantId: "Mandanten ID",
      name: "Name",
      title: "Mandanten",
      desc: "Ansicht und Verwaltung von Mandanten",
      create: "Mandanten erstellen",
      status: "Status",
      changed: "Geändert",
    },
  },
};
