import { ApolloCache, DocumentNode } from "@apollo/client";
import { getOperationName as getName } from "@apollo/client/utilities";
import { Kind } from "graphql";

import { omitByKey } from "./object";

type Options = {
  getInnerOperationName?: boolean;
};

const getInnerOperationName = (doc: DocumentNode) => {
  const operation = doc.definitions[0];

  if (operation.kind === Kind.OPERATION_DEFINITION) {
    const innerOperation = operation.selectionSet.selections[0];

    if (innerOperation.kind === Kind.FIELD) {
      return innerOperation.name.value;
    }
  }
};

export const getOperationName = (doc: DocumentNode, options?: Options) =>
  (options?.getInnerOperationName && getInnerOperationName(doc)) || getName(doc) || undefined;

export const evictQuery = (query: DocumentNode, cache: ApolloCache<any>, options?: Options) => {
  const fieldName = getOperationName(query, options);
  if (fieldName) {
    cache.evict({ id: "ROOT_QUERY", fieldName });
    cache.gc();
  }
};

export const omitTypenames = omitByKey("__typename");
