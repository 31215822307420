import { useMemo } from "react";

import { Badge, CommonCells, Table, TableTypes, localFilters, usePagination } from "common/guideline";
import { BooleanCell } from "common/guideline/components/Table/BooleanCell";
import { useRowSelection } from "common/guideline/components/Table/useRowSelection";
import { withDefault } from "common/helpers";
import { useFindAllMachineUsersFilteredQuery } from "generated";
import { useTranslation } from "i18n";
import { showDeleteMachineUsersBulkModal } from "machineUser/components/DeleteMachineUsersBulk";
import { useColumnFilters } from "report/components";
import { useSorting } from "report/hooks";

import { MachineUserVerticalHeader } from "./MachineUserVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineUserData } from "./types";

const columns: TableTypes.TranslatedColumns<MachineUserData> = (t) => [
  CommonCells.selector,
  {
    header: t("mu.userId"),
    id: "userId",
    accessorKey: "userId",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    header: t("mu.cardLogin"),
    id: "cardIdentifier",
    accessorKey: "cardIdentifier",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    header: t("mu.name"),
    id: "name",
    accessorKey: "name",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    header: t("mu.roleName"),
    id: "roleName",
    accessorKey: "roleName",
    cell({ getValue }) {
      return withDefault(getValue() ? <Badge>{getValue<string>()}</Badge> : undefined);
    },
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    header: t("mu.deactivated"),
    id: "deactivated",
    accessorKey: "deactivated",
    cell: BooleanCell,
    meta: {
      ...localFilters.selectBooleanFilter.meta,
    },
  },
  {
    header: t("mu.validFrom"),
    id: "validFrom",
    accessorKey: "validFrom",
    size: 200,
    meta: {
      filter: localFilters.getDateFilter.meta.filter("datetime"),
    },
  },
  {
    header: t("mu.validUntil"),
    id: "validUntil",
    accessorKey: "validUntil",
    size: 200,
    meta: {
      filter: localFilters.getDateFilter.meta.filter("datetime"),
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "machineUsers";

const columnFiltersData = [
  ["userId", "userId"],
  ["name", "name"],
  ["roleNames", "roleName", (v) => (v ? [v] : v)],
  ["cardIdentifier", "cardIdentifier"],
  ["deactivated", "deactivated"],
  ["validFrom", "validFrom"],
  ["validUntil", "validUntil"],
] as const;

const getRowId = (row: MachineUserData) => row.nodeId ?? "";

export const MachineUserTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const [filters, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllMachineUsersFilteredQuery({
    variables: {
      machineUserFilters: filters,
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });

  const rows = (data?.findAllMachineUsersFiltered?.result ?? []) as MachineUserData[];
  const fullSize = data?.findAllMachineUsersFiltered?.fullSize ?? 0;

  const selection = useRowSelection(showDeleteMachineUsersBulkModal, getRowId);

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={rows}
      VerticalHeader={MachineUserVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      totalCount={fullSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
      sorting={sorting}
      columnFilters={columnFilters}
      onFilter={setColumnFilters}
      onSorting={setSorting}
      {...selection}
    />
  );
};
