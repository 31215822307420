import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const MachineReliability = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="machineReliability" subtitle="report.machineReliabilityDescription" />
);
