import { LatLngExpression } from "leaflet";
import tw from "twin.macro";
import "styled-components/macro";

import { LeafletMap } from "common/guideline";
import { CashMetrics } from "operations/components/CashMetrics";
const mapCenter: LatLngExpression = [52.247793, 21.011321];

export const MapOverlay = () => (
  <div tw="flex w-full">
    <LeafletMap tw="opacity-40" ready center={mapCenter} />
    <div tw="absolute top-16 right-8 bottom-auto left-8">
      <CashMetrics />
    </div>
  </div>
);
