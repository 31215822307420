import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as PlaceholderSVG } from "assets/icons/Placeholder.svg";

import { Button } from "./Button";
import { Container } from "./Container";
import { Text } from "./Text";

type Props = React.PropsWithChildren<{
  description: string | React.ReactNode;
  title: string | React.ReactNode;
}>;

export const EmptyState: React.FC<Props> = ({ children, description, title }) => (
  <Container variant="pageWrapper">
    <div tw="flex flex-col items-center">
      <PlaceholderSVG width={96} height={96} />
      <Text variant="heading">{title}</Text>
      <Text tw="flex justify-center" variant="description">
        {description}
      </Text>
      <Button tw="my-3" variant="primary">
        <Link route="HOME">
          <Text tKey="common.btnHome" />
        </Link>
      </Button>
    </div>
    {children}
  </Container>
);
