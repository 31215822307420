// preserveNesting - falt values but alsa preserve each nesting as not flattened
export const flattenObject = (ob: any, preserveNesting = false) => {
  const toReturn: any = {};

  for (const i in ob) {
    const v = ob[i];

    if (v && typeof v == "object") {
      if (preserveNesting) {
        toReturn[i] = v;
      }

      const flatObject = flattenObject(v, preserveNesting);
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = v;
    }
  }

  return toReturn;
};
