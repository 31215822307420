import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import {
  PageLayout,
  SelectWrapper,
  useMappedSiteGroupContext,
  useQueryByDateFilter,
  useTimeZoneFilter,
} from "base/components";
import {
  CommonCells,
  CustomGetCsvFn,
  DateRangeInfo,
  SubRows,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  tableDownloadTitles,
  useDateRangeFilter,
  useDateRangeZoned,
} from "common/guideline";
import {
  GenerateMachineUserTransactionsReportQuery,
  MachineUserTransactionsReportCriteriaDto,
  RmTransactionType,
  useGenerateMachineUserTransactionsReportQuery,
} from "generated";
import { FilterBox, getColumnFilter, useColumnFilters } from "report/components";
import { TransactionType } from "transport/components";

import { breadcrumbs } from "./breadcrumbs";
import { totalAmountsMachineUserTranRows } from "./totalAmountsMachineUserTranRows";

type MachineUserTransactionsReport = NonNullable<
  NonNullable<GenerateMachineUserTransactionsReportQuery["generateMachineUserTransactionsReport"]>
>;

export type MachineUserTransactionsTotals = Pick<MachineUserTransactionsReport, "totalIn" | "totalOut" | "totalDiff">;

type MachineUserTransactionsRow = NonNullable<NonNullable<MachineUserTransactionsReport["rows"]>[0]>;

type MachineUserTransactionsSubRow = NonNullable<NonNullable<MachineUserTransactionsRow["transactionRows"]>[0]>;

type Filters = MachineUserTransactionsReport["columns"];

const getColumns: (
  filters: Filters,
) => TableTypes.TranslatedColumns<MachineUserTransactionsRow & Partial<MachineUserTransactionsSubRow>, { _?: never }> =
  (filters) => (t) =>
    [
      CommonCells.expander,
      {
        header: t("mu.cashierName"),
        id: "USER_NAME",
        accessorKey: "userName",
        footer: t("report.total"),
        meta: {
          filter: getColumnFilter(filters, "machineUserNames"),
        },
      },
      {
        header: t("mu.userId"),
        id: "USER_ID",
        accessorKey: "userId",
        meta: {
          filter: getColumnFilter(filters, "machineUserIds"),
        },
      },
      {
        header: t("mu.roleName"),
        id: "ROLE",
        accessorKey: "roleName",
        subAccessor: (v) => v?.roleName,
        meta: {
          filter: getColumnFilter(filters, "roles"),
        },
      },
      {
        header: t("machine.machine_one"),
        id: "MACHINE",
        subAccessor: (v) => v?.machineName,
      },
      {
        header: t("location.location_one"),
        id: "LOCATION",
        subAccessor: (v) => v?.locationName,
      },
      {
        header: t("tranReport.formattedDateTime"),
        id: "DATE_TIME",
        subAccessor: (v) => accessors.zonedDateTime(v.formattedDateTime, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedDateTime, t),
          },
        },
      },
      {
        header: t("tranReport.accountingDate"),
        id: "ACCOUNTING_DATE",
        subAccessor: (v) => accessors.zonedDateTime(v?.accountingDate, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v?.accountingDate, t),
          },
        },
      },
      {
        header: t("mDepo.tranType"),
        id: "TYPE",
        subAccessor: (v) => v?.type,
        subCell: ({ getSubAccesorValue }) => {
          const type = getSubAccesorValue?.<RmTransactionType>();
          return <TransactionType type={type} />;
        },
      },
      {
        header: t("tranReport.workUnit"),
        id: "WORK_UNIT_NAME",
        subAccessor: (v) => v?.workUnitName,
      },
    ];

const SubRowsWrapper: TableTypes.SubRowsComponent<MachineUserTransactionsRow> = ({ row }) => (
  <SubRows<MachineUserTransactionsRow, MachineUserTransactionsSubRow>
    row={row}
    loading={false}
    data={
      row.original.transactionRows?.flatMap((r) =>
        r?.totalAmounts?.map((t) => ({
          ...r,
          ...(t?.value ? (Number(t.value) > 0 ? { totalIn: t, totalDiff: t } : { totalOut: t, totalDiff: t }) : {}),
        })),
      ) as MachineUserTransactionsSubRow[]
    }
  />
);

const columnFiltersData = [
  ["machineUserNames", "USER_NAME"],
  ["machineUserIds", "USER_ID"],
  ["roles", "ROLE"],
] as const;

const TABLE_NAME = "machineUserTransactions";

const MachineUserTransactionsTable = () => {
  const { t, i18n } = useTranslation();
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const [zoneId, TimeZone] = useTimeZoneFilter(false);
  const [date, DateRange] = useDateRangeFilter("currentDay", {
    label: "report.period",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });
  const [filter, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const zonedDateRange = useDateRangeZoned(date, zoneId);
  const [queryTransactionBy, QueryByDateSelect] = useQueryByDateFilter("transactions");
  const criteriaDto: MachineUserTransactionsReportCriteriaDto = {
    reportName: "MachineUserTransactionsReport",
    queryTransactionBy,
    zoneId,
    fromDate: zonedDateRange.from,
    toDate: zonedDateRange.to,
    locationNodeIds: location ? location : siteGroup,
    machineNodeIds: machine,
    ...filter,
  };
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateMachineUserTransactionsReportQuery({ variables: { criteriaDto } });

  const filters = data?.generateMachineUserTransactionsReport?.columns;
  const rows = data?.generateMachineUserTransactionsReport?.rows as
    | (MachineUserTransactionsRow & Partial<MachineUserTransactionsSubRow>)[]
    | undefined;
  const totalAmounts = data?.generateMachineUserTransactionsReport as MachineUserTransactionsTotals | undefined;

  const columns = useMemo(
    () => [
      ...getColumns(filters)(t, i18n.language),
      ...totalAmountsMachineUserTranRows(rows ?? [], t, totalAmounts ?? {}),
    ],
    [t, i18n.language, rows, filters, totalAmounts],
  );

  const getCsv: CustomGetCsvFn<MachineUserTransactionsRow & Partial<MachineUserTransactionsSubRow>> = async (
    getOptions,
    currentPage,
    currentPageRows,
  ) => {
    const _rows = currentPage && currentPageRows ? currentPageRows.map((r) => r.original) : rows;

    return getOptions({
      data: (_rows ?? []).flatMap((r) =>
        (r.transactionRows as any)?.flatMap((s) =>
          s.totalAmounts.map((t) => ({
            ...r,
            ...s,
            ...(t?.value ? (Number(t.value) > 0 ? { totalIn: t, totalDiff: t } : { totalOut: t, totalDiff: t }) : {}),
          })),
        ),
      ),
    });
  };

  return (
    <>
      <FilterBox>
        <SelectWrapper>{DateRange}</SelectWrapper>
        <SelectWrapper>{QueryByDateSelect}</SelectWrapper>
        {TimeZone}
      </FilterBox>
      <DateRangeInfo from={zonedDateRange.from} to={zonedDateRange.to} timeZone={zoneId} />
      <Table
        tableName={TABLE_NAME}
        data={rows ?? []}
        columns={columns}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        SubRows={SubRowsWrapper as any}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDateRange(zonedDateRange, "mum.transactions.title", t),
                page,
              )
            }
            disabled={!rows?.length}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </>
  );
};

export const MachineUserTransactions = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="mum.transactions.title" subtitle="mum.transactions.desc" withPicker>
    <MachineUserTransactionsTable />
  </PageLayout>
);
