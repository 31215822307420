import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "common/guideline";

export const getFormTitle: (title: TKeys, isSubtitle?: boolean) => React.FC = (title, isSubtitle = false) =>
  function FormTitle() {
    return <div tw="pt-9 mb-3">{isSubtitle ? <Text variant="paragraph" tKey={title} /> : <Text tKey={title} />}</div>;
  };
