import { TableTypes } from "common/guideline";
import { DenominationValueDto } from "generated";
import { TFunction } from "i18n";

type DataWithDenomination = { valuesByDenomination?: (DenominationValueDto | null)[] | null };

export const getDenominationRows = <T extends DataWithDenomination>(rows: T[], t: TFunction) =>
  Object.values(
    (rows ?? [])
      .flatMap((r) => r.valuesByDenomination)
      .reduce((acc, curr) => {
        if (!curr) return acc;

        const key = `${curr.denomination} ${curr.currency} ${curr.type}`;
        if (!acc[key])
          acc[key] = {
            header: t("report.denominationCount", {
              denomination: curr.denomination,
              currency: curr.currency,
              type: curr.type,
            }),
            id: key,
            enableColumnFilter: false,
            enableSorting: false,
            enableHiding: false,
            meta: {
              // hide columns from UI but show them in generated CSV
              // enableHiding is disabled so initialHidden makes this column always hidden in UI
              initialHidden: true,
              csv: {
                generateIfHidden: true,
              },
            },
            accessorFn: (data) =>
              data.valuesByDenomination?.find?.(
                (t) => t?.denomination === curr.denomination && t?.currency === curr.currency && t?.type === curr.type,
              )?.count,
          };
        return acc;
      }, {} as Record<string, TableTypes.ColumnDef<T>>),
  );
