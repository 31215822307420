import { TKeys } from "i18next";
import { Fragment, memo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { Badge, CustomSubRows, FullLine, RawTable, Spinner, TableTypes, Text, useToast } from "common/guideline";
import { copyToClipboard, numberWithDecimalCount, withDefault } from "common/helpers";
import {
  RmBoxMovementDto,
  RmDeviceMovementDto,
  RmValueBagDto,
  RmValueDto,
  useFindDeviceMovementsForRmTransactionQuery,
  useFindValueBagsForRmTransactionQuery,
} from "generated";

import type { TransactionRows } from "./Transactions";

export const CopyToClipboardSVG: React.FC<{ data: any }> = ({ data }) => {
  return (
    <CopySVG
      tw="cursor-pointer"
      width={20}
      height={20}
      onClick={() => copyToClipboard(data || "", () => useToast.actions.show("common.clipboard"))}
    />
  );
};

const applyDecimal = (value: number | undefined | null, decimals: number | undefined | null) =>
  numberWithDecimalCount.merge(value ?? 0, decimals ?? 0);

type ValueBagsProps = {
  row: TableTypes.Row<TransactionRows>;
  isCorrectionTransaction: boolean;
};

const miniBadge: React.FC<{ data: any }> = ({ data }) => {
  return <Badge variant={["sm", "info"]}>{data}</Badge>;
};

const miniDecimal: React.FC<{ data: { calculated?: number; decimals?: number } }> = ({ data }) => {
  const zeros = (0).toFixed(Math.max(0, data.decimals ?? 0)).split(".")[1];
  return (
    <>
      <span className="group">
        <span tw="text-gray-9">{data.calculated}</span>
        <span tw="opacity-50 group-hover:opacity-100 transition-all duration-300">
          <span tw="text-gray-9">.</span>
          <span tw="text-gray-9">{zeros}</span>
        </span>
      </span>
    </>
  );
};

const UniformTable: React.FC<{ name: TKeys; valueDto: Array<RmValueDto | null> }> = ({ name, valueDto }) => {
  const layout = {
    "tranReport.valBag.valType": (v: RmValueDto | null) => v?.type,
    "tranReport.valBag.denomination": (v: RmValueDto | null) => v?.denomination,
    "tranReport.valBag.decimals": (v: RmValueDto | null) => v?.decimals,
    "tranReport.valBag.calculated": (v: RmValueDto | null) => ({
      calculated: applyDecimal(v?.denomination, v?.decimals),
      decimals: v?.decimals,
    }),
    "tranReport.valBag.currency": (v: RmValueDto | null) => v?.currency,
    "tranReport.valBag.count": (v: RmValueDto | null) => v?.count,
    "tranReport.valBag.total": (v: RmValueDto | null) => applyDecimal(v?.total, v?.decimals),
    "tranReport.valBag.reference": (v: RmValueDto | null) => v?.reference,
    "tranReport.valBag.pieceValue": (v: RmValueDto | null) => applyDecimal(v?.pieceValue, v?.decimals),
    "tranReport.valBag.exchangeRate": (v: RmValueDto | null) => applyDecimal(v?.exchangeRate, v?.exchangeRateDecimals),
    "tranReport.valBag.exchangeRateDecimals": (v: RmValueDto | null) => v?.exchangeRateDecimals,
    "tranReport.valBag.claimedValueType": (v: RmValueDto | null) => v?.claimedValueType,
    "tranReport.valBag.customType": (v: RmValueDto | null) => v?.customType,
  };
  const headers = Object.keys(layout);
  const columns = Object.values(layout);
  return valueDto.length == 0 ? (
    <Text tKey="machine.valueBagsMissing" />
  ) : (
    <RawTable
      name={name}
      headers={headers as any}
      rows={valueDto.map((value) => columns.map((f) => f(value)))}
      customCells={{
        "tranReport.valBag.valType": miniBadge,
        "tranReport.valBag.currency": miniBadge,
        "tranReport.valBag.calculated": miniDecimal,
      }}
    />
  );
};

const ValueBagsHeader: React.FC<{
  row: TableTypes.Row<TransactionRows>;
  valueBag: RmValueBagDto | null;
  isCorrectionTransaction: boolean;
}> = ({ row, valueBag, isCorrectionTransaction }) => {
  return (
    <div tw="flex flex-col gap-2">
      <div tw="flex gap-2 items-center">
        <Text tw="p-1 text-base" variant="labelAlt" tKey="tranReport.UUID" />
        <Badge variant={["info", "normalCase"]}>{withDefault(row.original.uuid)}</Badge>
        <CopyToClipboardSVG data={row.original.uuid} />
      </div>
      <FullLine />
      <div tw="grid grid-cols-2">
        <Text tw="p-1" variant="labelAlt" tKey="tranReport.timestamp" />
        <Text
          tw="p-1"
          tKey="dateFormat"
          tValue={{
            date: row.original.dateTime ? new Date(row.original.dateTime) : new Date(),
            formatString: "PPp",
          }}
        />
        <Text tw="p-1" variant="labelAlt" tKey="tranReport.timezoneOffset" />
        <Text tw="p-1">{row.original.timeZoneUtcOffset}</Text>
        <Text tw="p-1" variant="labelAlt" tKey="tranReport.valBag.totalAmount" />
        <Text tw="p-1">{valueBag?.totalAmount}</Text>
        <Text tw="p-1" variant="labelAlt" tKey="tranReport.valBag.type" />
        <div>
          <Badge variant={["sm", "info"]}>{valueBag?.type}</Badge>
        </div>
        <Text tw="p-1" variant="labelAlt" tKey="tranReport.valBag.rejects" />
        <Text tw="p-1">{valueBag?.rejects}</Text>
        {isCorrectionTransaction ? (
          <>
            <Text tw="p-1" variant="labelAlt" tKey="tranCorr.corrReason" />
            <Text tw="p-1">{withDefault(row.original.correctionReason)}</Text>
            <Text tw="p-1" variant="labelAlt" tKey="tranCorr.createdBy" />
            <Text tw="p-1">{withDefault(row.original.createdBy)}</Text>
            <Text tw="p-1" variant="labelAlt" tKey="tranCorr.machineUser" />
            <Text tw="p-1">{withDefault(row.original.machineUserName)}</Text>
          </>
        ) : (
          <>
            <Text tw="p-1" variant="labelAlt" tKey="tranReport.cardIdentifier" />
            <Text tw="p-1">{withDefault(row.original.cardIdentifier)}</Text>
            <Text tw="p-1" variant="labelAlt" tKey="tranReport.workUnitGroup" />
            <Text tw="p-1">{withDefault(row.original.workUnitName)} </Text>
            <Text tw="p-1" variant="labelAlt" tKey="tranReport.mixEdited" />
            <Text tw="p-1">{withDefault(row.original.mixEdited)}</Text>
          </>
        )}
      </div>
    </div>
  );
};

const ValueBagsContent: React.FC<ValueBagsProps> = ({ row, isCorrectionTransaction }) => {
  const { data, loading } = useFindValueBagsForRmTransactionQuery({
    variables: { nodeId: row.original.nodeId },
  });
  const valueBags = data?.findValueBagsForRmTransaction || [];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : valueBags.length == 0 ? (
        <Text tKey="machine.valueBagsMissing" />
      ) : (
        valueBags.map((valueBag, i) => (
          <div key={i}>
            <ValueBagsHeader row={row} valueBag={valueBag} isCorrectionTransaction={isCorrectionTransaction} />
            <UniformTable name="tranReport.valBag.title" valueDto={valueBag?.values ?? []} />
          </div>
        ))
      )}
    </div>
  );
};

type DeviceMovementsProps = {
  row: TableTypes.Row<TransactionRows>;
};

const DeviceMovementsHeader: React.FC<{ deviceMovementsHeader: RmDeviceMovementDto | null }> = ({
  deviceMovementsHeader,
}) => {
  return (
    <div tw="flex gap-2 items-center">
      <Text tw="py-1 min-w-[6rem] text-base" variant="labelAlt" tKey="machine.deviceId" />
      <Badge variant={["info", "normalCase"]}>{withDefault(deviceMovementsHeader?.deviceId)}</Badge>
      <CopyToClipboardSVG data={deviceMovementsHeader?.deviceId || ""} />
    </div>
  );
};

const DeviceBoxMovementsHeader: React.FC<{ deviceBoxMovementsHeader: RmBoxMovementDto | null }> = ({
  deviceBoxMovementsHeader,
}) => {
  return (
    <div tw="flex flex-col gap-2">
      <div tw="flex gap-2 items-center">
        <Text tw="min-w-[6rem]" variant="labelAlt" tKey="machine.boxId" />
        <Badge variant={["info", "normalCase"]}>{withDefault(deviceBoxMovementsHeader?.boxId)}</Badge>
        <CopyToClipboardSVG data={deviceBoxMovementsHeader?.boxId || ""} />
      </div>
      <div tw="flex gap-2 items-center">
        <Text tw="min-w-[6rem]" variant="labelAlt" tKey="machine.containerId" />
        <Badge variant={["info", "normalCase"]}>{withDefault(deviceBoxMovementsHeader?.containerId)}</Badge>
        <CopyToClipboardSVG data={deviceBoxMovementsHeader?.containerId || ""} />
      </div>
    </div>
  );
};

const DeviceMovementsContent: React.FC<DeviceMovementsProps> = ({ row }) => {
  const { data, loading } = useFindDeviceMovementsForRmTransactionQuery({
    variables: { nodeId: row.original.nodeId },
  });
  const valueDeviceMovements = data?.findDeviceMovementsForRmTransaction || [];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : valueDeviceMovements.length == 0 ? (
        <Text tKey="machine.deviceMovementsMissing" />
      ) : (
        valueDeviceMovements.map((valueDeviceMovement, i) => (
          <div key={i} tw="flex flex-col gap-2">
            <DeviceMovementsHeader deviceMovementsHeader={valueDeviceMovement} />
            <FullLine />
            {valueDeviceMovement?.boxMovements?.length == 0 ? (
              <Text tKey="machine.deviceMovementsMissing" />
            ) : (
              valueDeviceMovement?.boxMovements?.map((boxMovement, j) => (
                <div key={j} tw="flex flex-col gap-2">
                  <DeviceBoxMovementsHeader deviceBoxMovementsHeader={boxMovement} />
                  <FullLine />
                  <div tw="flex flex-row gap-2">
                    {boxMovement?.movement?.length == 0 ? (
                      <Text tKey="machine.deviceMovementsMissing" />
                    ) : (
                      boxMovement?.movement?.map((movement, k) => (
                        <div key={k}>
                          <RawTable
                            name="tranReport.valBag.boxMovement"
                            headers={["machine.rejects", "machine.totalAmount", "machine.movementType"]}
                            rows={[[movement?.rejects, movement?.totalAmount, movement?.type]]}
                          />
                          <UniformTable name="tranReport.valBag.deviceMovement" valueDto={movement?.values ?? []} />
                        </div>
                      ))
                    )}
                    {boxMovement?.resultingContent?.length == 0 ? (
                      <Text tKey="machine.deviceMovementsMissing" />
                    ) : (
                      boxMovement?.resultingContent?.map((resultingContent, n) => (
                        <div key={n}>
                          <RawTable
                            name="tranReport.valBag.resultingContent"
                            headers={[
                              "machine.resultingContentType",
                              "machine.resultingContentTotalAmount",
                              "machine.resultingContentRejects",
                            ]}
                            rows={[[resultingContent?.type, resultingContent?.totalAmount, resultingContent?.rejects]]}
                          />
                          <UniformTable
                            name="tranReport.valBag.deviceMovementsResultingContent"
                            valueDto={resultingContent?.values ?? []}
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        ))
      )}
    </div>
  );
};

export const TransactionsSubRow: TableTypes.SubRowsComponent<TransactionRows> = memo(({ row }) => {
  const isCorrectionTransaction = row.original.__typename === "CorrectionTransactionReportRowDto";

  return (
    <CustomSubRows row={row}>
      <div tw="flex gap-2 p-1 rounded-lg border border-gray-4">
        <ValueBagsContent row={row} isCorrectionTransaction={isCorrectionTransaction} />
        <DeviceMovementsContent row={row} />
        {isCorrectionTransaction && row.original.totalAmounts && (
          <div tw="grid gap-2">
            <Text tw="p-1" variant="labelAlt" tKey="tranReport.currency" />
            <Text tw="p-1" variant="labelAlt" tKey="tranReport.total" />
            {row.original.totalAmounts.map((a, i) => (
              <Fragment key={i}>
                <FullLine />
                <span>{a?.key}</span>
                <span>{a?.value}</span>
              </Fragment>
            ))}
            <FullLine />
          </div>
        )}
      </div>
    </CustomSubRows>
  );
});

TransactionsSubRow.displayName = "TransactionsSubRow";
