export const de = {
  location: {
    location_one: "Standort",
    location_other: "Standorte",
    useLocation: "Benutze diesen Standort",
    cancel: "Abbrechen",
    siteDetails: "Standortdetails",
    selectOnMap: "Auf der Karte auswählen",
    longitude: "Längengrad",
    latitude: "Breitengrad",
    customerReference: "Kundenreferenz",
    description: "Beschreibung",
    geographicalLocation: "Geografische Lage",
    country: "Land",
    countryCode: "Ländercode",
    city: "Stadt",
    region: "Bundesland | Provinz | Landkreis",
    zipCode: "Postleitzahl",
    title: "Standorte",
    create: "Standort erstellen",
    createDescription: "Sie erstellen einen Standort, um ein neues Cash-Management-System einzurichten",
    update: "Standort aktualisieren",
    updateDescription: "Sie aktualisieren einen Standort",
    delete: "Standort löschen",
    deleteDescription: "Sie löschen einen bestehenden Standort",
    deleteClose: "Schließen",
    roleName: "Rolle",
    name: "Standortname",
    siteId: "Standort ID",
    nodeStatus: "Status",
    nodeStatusCount: "Status zählen",
    nodeChangeDate: "Geändert",
    success: "Standort erfolgreich erstellt",
    error: "Erstellung des Standortes fehlgeschlagen",
    updateSuccess: "Standort erfolgreich upgedated",
    updateApiError: "Aktualisierung des Standortes fehlgeschlagen",
    deleteSuccess: "Standort erfolgreich gelöscht",
    deleteError: "Löschen des Standortes fehlgeschlagen",
    goBack: "Zurück",
    mustBeNumeric: "Wert muss numerisch sein",
    filterSites: "Standorte filtern",
    list: {
      noData: "Keine Standorte gefunden",
    },
  },
};
