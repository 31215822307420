import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as InfoSVG } from "assets/icons/Info.svg";
import { CheckboxRaw, DateInputRaw, SelectRaw, TextLabel } from "common/form/renderFields";
import { Text, Tooltip, useToast } from "common/guideline";
import { useLocalStorage } from "common/hooks";

import { timeSettings } from "../helpers/timeSettings";

import { HourFormatSetting } from "./HourFormatSetting";

const weekStartOptions = [
  { value: 1, label: "user.settings.weekStartDay1" },
  { value: 0, label: "user.settings.weekStartDay0" },
] as const;

export const TimeSettings = () => {
  const [userDayBreakIsEnabled, setUserDayBreakEnable] = useLocalStorage(
    timeSettings.default.userDayBreakEnabled,
    timeSettings.keys.USER_DAY_BREAK_ENABLED,
  );
  const [weekStart, setWeekStart] = useLocalStorage(timeSettings.default.week, timeSettings.keys.WEEK_START);
  const [dayEnd, setDayEnd] = useLocalStorage(timeSettings.default.dayEnd, timeSettings.keys.DAY_END);

  return (
    <div tw="flex flex-col gap-2">
      <Text tKey="user.settings.timeSettings" variant="heading" />
      <div tw="flex gap-4">
        <SelectRaw
          tw="w-[100px]"
          name="weekStart"
          value={weekStart}
          onChange={setWeekStart}
          label="user.settings.weekStart"
          options={weekStartOptions}
        />
        <CheckboxRaw
          key="userDayBreak"
          name="userDayBreak"
          type="checkbox"
          label="user.settings.setDaybreak"
          checked={userDayBreakIsEnabled}
          onChange={(value) => setUserDayBreakEnable(value.target.checked)}
        />
        <HourFormatSetting />
        {userDayBreakIsEnabled ? (
          <div tw="flex flex-col">
            <div tw="flex">
              <TextLabel variant="label" tKey="user.settings.dayEnd" />
              <Tooltip
                content={
                  <span tw="max-w-xs">
                    <Text tKey="user.settings.dayEndDescription" />
                  </span>
                }
              >
                <span tw="flex ml-1 mb-1.5 cursor-pointer">
                  <InfoSVG width={16} height={16} />
                </span>
              </Tooltip>
            </div>
            <DateInputRaw
              name="dayEnd"
              type="date"
              dateFormat="time"
              value={dayEnd}
              onChange={(v) => {
                setDayEnd(v);
                useToast.actions.show(`DayBreak changed to: ${v}`, { variant: "info" });
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
