import { TKeys } from "i18next";
import { Trans } from "react-i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { ActionButton, EntityDelete } from "base/components";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, TransferBoxField, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text, Tooltip, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindAllMachineUserGroupsFilteredDocument,
  FindAllMachineUsersFilteredDocument,
  GenerateMachineViewTabDocument,
  MachineUserGroupDtoIn,
  useAddMachineUsersToMachineUserGroupMutation,
  useAddMachinesToMachineUserGroupMutation,
  useCreateMachineUserGroupMutation,
  useDeleteMachineUserGroupByNodeIdMutation,
  useFindMachineUserGroupWithMAchinesAndMachineUsersByNodeIdQuery,
  useFindMachineUserGroupWithMachineUsersByNodeIdQuery,
  useFindMachineUserGroupWithMachinesByNodeIdQuery,
  useRemoveMachineUsersFromMachineUserGroupMutation,
  useRemoveMachinesFromMachineUserGroupMutation,
  useUpdateMachineUserGroupMutation,
} from "generated";
import { getMachinesTransferBoxField, useConfigGetMachinesTransferBox } from "machine/components";
import { getMachineUserTransferBoxField, useConfigGetMachineUsersTransferBox } from "machineUser/components";
type FormData = MachineUserGroupDtoIn & { nodeId: string };

const Wrapper = styled.div`
  ${tw`flex flex-col gap-4`}
`;

const fieldsForm: CustomRenderFields[] = [
  {
    type: "text",
    name: "name",
    label: "machineUserGroup.name",
    validate: { type: "string", required: true },
  },
  getMachineUserTransferBoxField({ name: "machineUserNodeIds" }),
  getMachinesTransferBoxField({ name: "machineNodeIds" }),
];

const fieldsAll: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: fieldsForm,
  },
];

type Props = {
  onSubmit: OnSubmit<FormData>;
};

type UpdateProps = {
  title: TKeys;
  onSubmit: OnSubmit<FormData>;
  initialData: Partial<FormData>;
  fields: CustomRenderFields[];
};

const MachineUserGroupUpdateForm: React.FC<UpdateProps> = ({ onSubmit, fields, initialData, title }) => (
  <>
    <Text variant="heading" tKey={title} tw="pb-4 block" />
    <div tw="h-full overflow-auto">
      <SchemaForm<FormData>
        fields={fields}
        onSubmit={onSubmit}
        initial={initialData}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end mt-6">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                  <Text tKey="machineUserGroup.update" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        )}
      />
    </div>
  </>
);

const MachineUserGroupForm: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text variant="heading" tKey="machineUserGroup.addMachineUserGroups" tw="pb-4 block" />
    <div tw="h-full overflow-auto">
      <SchemaForm<FormData>
        fields={fieldsAll}
        onSubmit={onSubmit}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end mt-6">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                  <Text tKey="machineUserGroup.create" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        )}
      />
    </div>
  </>
);

export const UpdateName: React.FC<Pick<FormData, "name" | "nodeId">> = (data) => {
  const [updateName] = useUpdateMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <MachineUserGroupUpdateForm
        title="machineUserGroup.updateName"
        fields={[fieldsForm[0]]}
        initialData={data}
        onSubmit={({ name }) =>
          updateName({
            variables: { input: { name }, nodeId: data.nodeId },
            ignoreResults: true,
          })
            .then(() => {
              useToast.actions.show("machineUserGroup.updateSuccess", { variant: "success" });
              toggle();
            })
            .catch(() => useToast.actions.show("machineUserGroup.updateError", { variant: "error" }))
        }
      />
    ),
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.updateName" />}>
      <ActionButton.Edit onClick={() => toggle()} />
    </Tooltip>
  );
};

type DeleteProps = Pick<FormData, "name" | "nodeId"> & { onClose?: () => void };

export const DeleteGroupComponent: React.FC<DeleteProps> = ({ nodeId, name, onClose }) => {
  const [deleteGroup, { loading }] = useDeleteMachineUserGroupByNodeIdMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "machineUserGroup.deleteError" : "machineUserGroup.deleteSuccess", {
        variant: error ? "error" : "success",
      });
    try {
      const result = await deleteGroup({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(GenerateMachineViewTabDocument, cache);
          evictQuery(FindAllMachineUsersFilteredDocument, cache);
          evictQuery(FindAllMachineUserGroupsFilteredDocument, cache);
          evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, {
            getInnerOperationName: true,
          });
        },
      });
      if (result.data?.deleteMachineUserGroupByNodeId) onClose?.();
      showToast(!result.data?.deleteMachineUserGroupByNodeId);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="machineUserGroup.deleteGroup" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="machineUserGroup.deleteDesc" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const DeleteGroup: React.FC<Pick<FormData, "name" | "nodeId">> = (data) => {
  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: <DeleteGroupComponent {...data} />,
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.deleteGroup" />}>
      <ActionButton.Delete onClick={() => toggle()} />
    </Tooltip>
  );
};

const RemoveEntitiesField: React.FC<{ nodeId: string; type: "machine" | "user" }> = ({ nodeId, type }) => {
  // we are fetching current entities attached to machine user group by nodeId
  // then they become options to choose from, what entites we can remove from this group
  const [options = []] = useMappedQuery(
    (d) =>
      (type === "machine"
        ? d?.findMachineUserGroupByNodeId?.machines
        : d?.findMachineUserGroupByNodeId?.machineUsers
      )?.map((v) => ({
        value: v?.nodeId as string,
        label: v?.name as string,
      })) ?? [],
    useFindMachineUserGroupWithMAchinesAndMachineUsersByNodeIdQuery({
      variables: { nodeId },
      fetchPolicy: "no-cache",
    }),
  );

  return <TransferBoxField name={type === "machine" ? "machineNodeIds" : "machineUserNodeIds"} options={options} />;
};

const AddMachineUsersField: React.FC<{ nodeId: string }> = ({ nodeId }) => {
  const [config] = useConfigGetMachineUsersTransferBox({
    name: "machineUserNodeIds",
    type: "transferBox",
    options: [],
  });
  const [currentData = []] = useMappedQuery(
    (d) => d?.findMachineUserGroupByNodeId?.machineUsers?.map((v) => v?.nodeId as string) ?? [],
    useFindMachineUserGroupWithMachineUsersByNodeIdQuery({ variables: { nodeId }, fetchPolicy: "no-cache" }),
  );

  const options = config.options.filter((o) => !currentData.includes(o.value));

  return <TransferBoxField {...config} options={options} />;
};

const AddMachinesField: React.FC<{ nodeId: string }> = ({ nodeId }) => {
  const [config] = useConfigGetMachinesTransferBox({
    name: "machineNodeIds",
    type: "transferBox",
    options: [],
  });
  const [currentData = []] = useMappedQuery(
    (d) => d?.findMachineUserGroupByNodeId?.machines?.map((v) => v?.nodeId as string) ?? [],
    useFindMachineUserGroupWithMachinesByNodeIdQuery({ variables: { nodeId }, fetchPolicy: "no-cache" }),
  );

  const options = config.options.filter((o) => !currentData.includes(o.value));

  return <TransferBoxField {...config} fullSize={(config.fullSize ?? 0) - currentData.length} options={options} />;
};

const SmartMachineUserGroupUpdateForm: React.FC<
  UpdateProps & { nodeId: string; type: "machine" | "user"; action: "add" | "remove" }
> = ({ onSubmit, fields, title, nodeId, type, action }) => (
  <>
    <Text variant="heading" tKey={title} tw="pb-4 block" />
    <SchemaForm<FormData>
      fields={fields}
      onSubmit={onSubmit}
      customRender={customRender}
      SubmitComponent={() => (
        <div>
          {action === "remove" ? (
            <RemoveEntitiesField nodeId={nodeId} type={type} />
          ) : type === "user" ? (
            <AddMachineUsersField nodeId={nodeId} />
          ) : (
            <AddMachinesField nodeId={nodeId} />
          )}
          <div tw="flex justify-end mt-6 items-end">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton type="submit" data-test="submitForm" isLoading={isLoading} disabled={isLoading}>
                  <Text tKey="machineUserGroup.update" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        </div>
      )}
    />
  </>
);

export const AddMachineUsers: React.FC<Pick<FormData, "machineUserNodeIds" | "nodeId">> = ({ nodeId }) => {
  const [mutation] = useAddMachineUsersToMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <SmartMachineUserGroupUpdateForm
        title="machineUserGroup.addMachineUsers"
        fields={[]}
        nodeId={nodeId}
        initialData={{}}
        type="user"
        action="add"
        onSubmit={({ machineUserNodeIds = [] }) =>
          mutation({ variables: { machineUserNodeIds, nodeId } }).then(() => toggle())
        }
      />
    ),
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.addMachineUsers" />}>
      <ActionButton.Edit onClick={() => toggle()} />
    </Tooltip>
  );
};

export const RemoveMachineUsers: React.FC<Pick<FormData, "machineUserNodeIds" | "nodeId">> = ({ nodeId }) => {
  const [mutation] = useRemoveMachineUsersFromMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <SmartMachineUserGroupUpdateForm
        title="machineUserGroup.removeMachineUsers"
        fields={[]}
        nodeId={nodeId}
        initialData={{}}
        type="user"
        action="remove"
        onSubmit={({ machineUserNodeIds = [] }) =>
          mutation({ variables: { machineUserNodeIds, nodeId } }).then(() => toggle())
        }
      />
    ),
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.removeMachineUsers" />}>
      <ActionButton.Delete onClick={() => toggle()} />
    </Tooltip>
  );
};

export const AddMachines: React.FC<Pick<FormData, "machineNodeIds" | "nodeId">> = ({ nodeId }) => {
  const [mutation] = useAddMachinesToMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <SmartMachineUserGroupUpdateForm
        title="machineUserGroup.addMachines"
        fields={[]}
        nodeId={nodeId}
        initialData={{}}
        type="machine"
        action="add"
        onSubmit={({ machineNodeIds = [] }) => mutation({ variables: { machineNodeIds, nodeId } }).then(() => toggle())}
      />
    ),
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.addMachines" />}>
      <ActionButton.Edit onClick={() => toggle()} />
    </Tooltip>
  );
};

export const RemoveMachines: React.FC<Pick<FormData, "machineNodeIds" | "nodeId">> = ({ nodeId }) => {
  const [mutation] = useRemoveMachinesFromMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <SmartMachineUserGroupUpdateForm
        title="machineUserGroup.removeMachines"
        fields={[]}
        nodeId={nodeId}
        initialData={{}}
        type="machine"
        action="remove"
        onSubmit={({ machineNodeIds = [] }) => mutation({ variables: { machineNodeIds, nodeId } }).then(() => toggle())}
      />
    ),
  });

  return (
    <Tooltip content={<Text tKey="machineUserGroup.removeMachines" />}>
      <ActionButton.Delete onClick={() => toggle()} />
    </Tooltip>
  );
};

export const CreateMachineUserGroup = () => {
  const [createGroup] = useCreateMachineUserGroupMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <MachineUserGroupForm
        onSubmit={(input) =>
          createGroup({
            variables: { input },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindAllMachineUserGroupsFilteredDocument, cache);
              evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, {
                getInnerOperationName: true,
              });
            },
          })
            .then(() => {
              useToast.actions.show("machineUserGroup.addSuccess", { variant: "success" });
              toggle();
            })
            .catch(() => useToast.actions.show("machineUserGroup.addError", { variant: "error" }))
        }
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showFormModal">
      <Text tKey="machineUserGroup.addMachineUserGroups" />
    </Button>
  );
};
