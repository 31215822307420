import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "alerts.title",
    route: "ALERTS",
  },
  {
    text: "alerts.history",
    route: "ALERTS_HISTORY",
  },
];
