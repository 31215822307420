export default {
  // consumer of this format string can pass to and from translations keys if date is range with one empty date - `[date, null] | [null, date]`
  dateFormat: "{{to}} {{from}} {{ date, dateFormat }}",
  numberFormat: "{{ value, numberFormat }}",
  countries: {
    AF: "Afghanistan",
    AL: "Albanie",
    DZ: "Algérie",
    AS: "Samoa américaines",
    AD: "Andorre",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctique",
    AG: "Antigua-et-Barbuda",
    AR: "Argentine",
    AM: "Arménie",
    AW: "Aruba",
    AU: "Australie",
    AT: "Autriche",
    AZ: "Azerbaïdjan",
    BS: "Bahamas",
    BH: "Bahreïn",
    BD: "Bangladesh",
    BB: "Barbade",
    BY: "Biélorussie",
    BE: "Belgique",
    BZ: "Belize",
    BJ: "Bénin",
    BM: "Bermudes",
    BT: "Bhoutan",
    BO: "Bolivie",
    BA: "Bosnie-Herzégovine",
    BW: "Botswana",
    BV: "Île Bouvet",
    BR: "Brésil",
    IO: "Océan Indien Britannique",
    BN: "Brunei Darussalam",
    BG: "Bulgarie",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodge",
    CM: "Cameroun",
    CA: "Canada",
    CV: "Cap-Vert",
    KY: "Îles Caïmans",
    CF: "République Centrafricaine",
    TD: "Tchad",
    CL: "Chili",
    CN: "Chine",
    CX: "Île Christmas",
    CC: "Îles Cocos",
    CO: "Colombie",
    KM: "Comores",
    CG: "République du Congo",
    CD: "République démocratique du Congo",
    CK: "Îles Cook",
    CR: "Costa Rica",
    CI: "Côte-d'Ivoire",
    HR: "Croatie",
    CU: "Cuba",
    CY: "Chypre",
    CZ: "République Tchèque",
    DK: "Danemark",
    DJ: "Djibouti",
    DM: "Dominique",
    DO: "République Dominicaine",
    EC: "Équateur",
    EG: "Égypte",
    SV: "El Salvador",
    GQ: "Guinée équatoriale",
    ER: "Érythrée",
    EE: "Estonie",
    ET: "Éthiopie",
    FK: "Îles Malouines",
    FO: "Îles Féroé",
    FJ: "Fidji",
    FI: "Finlande",
    FR: "France",
    GF: "Guyane française",
    PF: "Polynésie française",
    TF: "Terres australes françaises",
    GA: "Gabon",
    GM: "Gambie",
    GE: "Géorgie",
    DE: "Allemagne",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Grèce",
    GL: "Groenland",
    GD: "Grenade",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GN: "Guinée",
    GW: "Guinée-Bissau",
    GY: "Guyana",
    HT: "Haïti",
    HM: "Îles Heard-et-MacDonald",
    VA: "Saint-Siège (Vatican)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hongrie",
    IS: "Islande",
    IN: "Inde",
    ID: "Indonésie",
    IR: "Iran",
    IQ: "Irak",
    IE: "Irlande",
    IL: "Israël",
    IT: "Italie",
    JM: "Jamaïque",
    JP: "Japon",
    JO: "Jordanie",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Corée du Nord",
    KR: "Corée du Sud",
    KW: "Koweït",
    KG: "Kirghizistan",
    LA: "Laos",
    LV: "Lettonie",
    LB: "Liban",
    LS: "Lesotho",
    LR: "Libéria",
    LY: "Libye",
    LI: "Liechtenstein",
    LT: "Lituanie",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macédoine du Nord",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaisie",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malte",
    MH: "Îles Marshall",
    MQ: "Martinique",
    MR: "Mauritanie",
    MU: "Maurice",
    YT: "Mayotte",
    MX: "Mexique",
    FM: "Micronésie",
    MD: "Moldavie",
    MC: "Monaco",
    MN: "Mongolie",
    MS: "Montserrat",
    MA: "Maroc",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibie",
    NR: "Nauru",
    NP: "Népal",
    NL: "Pays-Bas",
    NC: "Nouvelle-Calédonie",
    NZ: "Nouvelle-Zélande",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigéria",
    NU: "Niué",
    NF: "Île Norfolk",
    MP: "Îles Mariannes du Nord",
    NO: "Norvège",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palaos",
    PS: "Palestine",
    PA: "Panama",
    PG: "Papouasie-Nouvelle-Guinée",
    PY: "Paraguay",
    PE: "Pérou",
    PH: "Philippines",
    PN: "Îles Pitcairn",
    PL: "Pologne",
    PT: "Portugal",
    PR: "Porto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Roumanie",
    RU: "Russie",
    RW: "Rwanda",
    SH: "Sainte-Hélène",
    KN: "Saint-Christophe-et-Niévès",
    LC: "Sainte-Lucie",
    PM: "Saint-Pierre-et-Miquelon",
    VC: "Saint-Vincent-et-les-Grenadines",
    WS: "Samoa",
    SM: "Saint-Marin",
    ST: "São Tomé-et-Principe",
    SA: "Arabie Saoudite",
    SN: "Sénégal",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapour",
    SK: "Slovaquie",
    SI: "Slovénie",
    SB: "Îles Salomon",
    SO: "Somalie",
    ZA: "Afrique du Sud",
    GS: "Géorgie du Sud-et-les Îles Sandwich du Sud",
    ES: "Espagne",
    LK: "Sri Lanka",
    SD: "Soudan",
    SR: "Suriname",
    SJ: "Svalbard et Île Jan Mayen",
    SZ: "Royaume d'Eswatini",
    SE: "Suède",
    CH: "Suisse",
    SY: "Syrie",
    TW: "Taïwan",
    TJ: "Tadjikistan",
    TZ: "République unie de Tanzanie",
    TH: "Thaïlande",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinité-et-Tobago",
    TN: "Tunisie",
    TR: "Turquie",
    TM: "Turkménistan",
    TC: "Îles Turques-et-Caïques",
    TV: "Tuvalu",
    UG: "Ouganda",
    UA: "Ukraine",
    AE: "Émirats Arabes Unis",
    GB: "Royaume-Uni",
    US: "États-Unis d'Amérique",
    UM: "Îles mineures éloignées des États-Unis",
    UY: "Uruguay",
    UZ: "Ouzbékistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Vietnam",
    VG: "Îles vierges britanniques",
    VI: "Îles vierges américaines",
    WF: "Wallis-et-Futuna",
    EH: "Sahara occidental",
    YE: "Yémen",
    ZM: "Zambie",
    ZW: "Zimbabwe",
    AX: "Åland",
    BQ: "Bonaire, Saint-Eustache et Saba",
    CW: "Curaçao",
    GG: "Guernesey",
    IM: "Île de Man",
    JE: "Jersey",
    ME: "Monténégro",
    BL: "Saint-Barthélemy",
    MF: "Saint-Martin (partie française)",
    RS: "Serbie",
    SX: "Saint-Martin (partie néerlandaise)",
    SS: "Soudan du Sud",
    XK: "Kosovo",
  },
};
