import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "common/guideline";

export const BoxHeading = ({ children }) => (
  <div tw="h-10 flex justify-center items-center">
    <Text tw="flex items-center" variant="heading">
      {children}
    </Text>
  </div>
);
