export const es = {
  transport: {
    title: "Transporte",
    overview: "Visión general del transporte",
    desc: "Transporte es una plataforma para gestionar y supervisar los servicios de transporte.",
    corReasons: {
      title_other: "Motivo de correccion",
      title_one: "Motivo de correccion",
      desc: "Ver, gestionar y crear motivos de correccion",
      id: "ID del motivo de corrección",
      name: "Nombre",
      active: "Activo",
      lastEdited: "Ultima edicion",
      lastEditedBy: "Ultimo editado por",
      create: "Crear correccion",
      remove: {
        title: "Eliminar motivo de correccion",
        success: "Motivo de corrección eliminado",
        error: "Error al eliminar motivo de correccion, intentelo de nuevo",
        desc: "La Correccion <0>{{name}}</0> se eliminara de forma permanente.",
        close: "Descartar",
        submit: "Eliminar",
      },
      form: {
        name: "Nombre",
        active: "Activar",
        create: "Crear motivo de correccion",
        createSubmit: "Crear",
        update: "Actualizar motivo de correccion",
        updateSubmit: "Actualizar",
      },
      createSuccess: "Razon de correccion creada con exito",
      createError: "Motivo de correccion creación fallida",
      updateSuccess: "Motivo de corrección actualizado con exito",
      updateError: "Error en la actualizacion del motivo de correccion",
    },
    rBalance: {
      title: "Balance",
      desc: "Informe que visualiza un balance para los conductores",
      date: "Fecha",
      currencyCode: "Moneda",
      balance: "Saldo",
      machineUser: "Usuario de la maquina",
    },
    dashboard: {
      pickMachine: "Seleccione una maquina para ver el resumen de transporte",
    },
    transactionType: {
      all: "Todas las transacciones",
      correction: "Correccion de transacciones",
      machine: "Transacciones de maquina",
    },
  },
  tranCorr: {
    title: "Correccion de transacciones",
    desc: "Utilice Correccion de transacciones para ver las transacciones y modificar los valores existentes con el fin de corregir los informes.",
    datePeriod: "Periodo de corrección",
    machine: "Maquina",
    sequence: "Secuencia",
    type: "Tipo",
    time: "Hora",
    date: "Fecha",
    utcOffset: "Desplazamiento UTC",
    machineUser: "Usuario de la maquina",
    corrReason: "Motivo de correccion",
    comment: "Comentario",
    createdBy: "Creado por",
    value: "Valor",
    currency: "Moneda",
    create: "Crear correccion",
    submit: "Enviar",
    offsetPatternErr: "Debe ser del patron -xx.00 o +xx.00",
    createSuccess: "Correccion de transaccion creada con exito",
    createError: "Error en la creación de la Corrección",
  },
  tranTypes: {
    DISPENSE: "Dispensar",
    DEPOSIT: "Deposito",
    EMPTY_OUT: "Vaciar",
    END_OF_SHIFT: "Fin de turno",
    MANUAL_REFILL: "Recarga manual",
    EXCHANGE: "Intercambio",
    PURGE: "Purga",
    POSSIBLE_TAMPERING: "Posible manipulacion",
    UNFINISHED_TRANSACTION: "Transaccion no finalizada",
    REFILL: "Recarga",
  },
  tranSubTypes: {
    CHANGE: "Cambio",
    DISPENSE_SURPLUS: "Dispensar excedente",
    EXPECTED_AMOUNT: "Importe previsto",
    IN: "Entrada",
    OUT: "Salida",
    REQUESTED_AMOUNT: "Importe solicitado",
    SALE: "Venta",
    DISPENSE_RETURN: "Devolucion de dipensacion",
  },
};
