export const fr = {
  common: {
    dark: "Thème sombre",
    light: "Thème clair",
    system: "Thème système",
    submit: "Envoyer",
    next: "Suivant",
    previous: "Précédent",
    dismiss: "Annuler",
    pin: " Épingler ce widget",
    unpin: "Désépingler ce widget",
    nodeId: "Id Noeud",
    status: "Statut",
    default: "Defaut",
    home: "Accueil",
    btnHome: "Retour à l’accueil",
    emptyTitle: "Aucun résultat trouvé",
    emptyDescription: "Essayez d'ajuster votre recherche ou votre filtre pour trouver ce que vous cherchez",
    pageError: "Oups",
    returnHome:
      "Comment vous êtes arrivé ici est un mystère. Mais vous pouvez cliquer sur le bouton ci-dessous pour retourner à la page d'accueil",
    all: "Tous",
    starting: "Démarrage",
    currency: "Devise",
    site: "Site",
    siteGroup: "Groupe de sites",
    searchResults: "Résultats de la recherche",
    personalized: "Personnalisé",
    insights: "Aperçus",
    machines: "Machines",
    edit: "Editer",
    viewDetails: "Voir les détails",
    title: "Titre",
    department: "Département",
    role: "Rôle",
    myProfile: "Mon profil",
    alerts: "Alertes",
    language: "Langue",
    signOut: "Déconnexion",
    helloPopover: "Bonjour <0>{{name}}</0>",
    helloProfile: "Bonjour, {{name}}",
    actionMessage: "Que voudriez-vous faire ?",
    profileDesc: "Ici vous pouvez éditer vos paramètres",
    verified: "Vérifié",
    terms: "Termes et conditions",
    termsDesc: "Voici les conditions générales d'utilisation des produits PayComplete™.",
    releaseNotes: "Notes de version",
    releaseNotesDesc: "Caractéristiques, améliorations, corrections et autres mises à jour de PayComplete™ Connect",
    help: "Aide",
    to: "A:",
    from: "De:",
    yes: "Oui",
    no: "Non",
    confirm: "Confirmer",
    select: "Sélectionner",
    rPeriod: "Période de référence",
    rDate: "Date du rapport",
    notGrouped: "Non groupé",
    overview: "Vue d’ensemble",
    "404": "404",
    clipboard: "Texte copié dans le presse-papiers",
    moreTypes: "{{count}} de plus",
    close: "Fermer",
    notAvailable: "Non disponible",
    countSelected: "{{count}} sélectionné(s)",
    popular: "Populaire",
    delete: "Supprimer",
    table: {
      pagination: {
        previous: "Précédent",
        next: "Suivant",
        results_zero: "Aucun résultat trouvé",
        results: "{{from}} - {{to}} de {{count}} résultats",
        perPage: "Résultats par page:",
        outOf: "sur",
        toggleAll: "Tout afficher",
        page: "Page",
      },
      filter: {
        filter: "Filtre",
        filters: "Filtres",
        reset: "Remise à zéro",
        pastHour: "Heure précédente",
        pastDay: "Jour précédent",
        past7Days: "7 jours précédents",
        past30Days: "30 jours précédents",
        customRange: "Plage personnalisée",
        equal: "Egale",
        between: "Entre",
        equalValue: "Egale <0>{{value}}</0>",
        fromValue: "De <0>{{from}}</0>",
        toValue: "A <0>{{to}}</0>",
        betweenValue: "Entre <0>{{from}}</0> et <0>{{to}}</0>",
        lowerTo: "Doit être inférieur à",
        higherFrom: "Doit être supérieur à",
        cannotBeEmpty: "Ne peut pas être vide",
        currentDay: "Journée en cours",
        currentWeek: "Semaine en cours",
        currentMonth: "Mois en cours",
        previousDay: "Jour précédent",
        previousWeek: "Semaine précédente",
        previousMonth: "Mois précédent",
      },
      sort: {
        sort: "Trier",
        sortBy: "Trier par",
        done: "Fait",
        reset: "Remise à zéro",
      },
      aggr: {
        sum: "{{value}} (total)",
        avg: "{{value}} (avg)",
      },
      selectedDays: "Jour(s) sélectionné(s)",
      noData: "pas de donnée",
      nothingShow: "Rien à afficher",
      error: "Erreur",
      failedToFetch: "Échec de la récupération des données",
      groupBy: "Grouper par",
      downloadCsv: "Télécharger en CSV",
      printReport: "Imprimer le rapport",
      export: "Exporter",
      inTimeZone: "dans le fuseau horaire {{value}}",
      inLocalTime: "dans l'heure locale de la machine",
      dayStartsAt: "Le jour commence à : {{value}}",
      dayEndsAt: "Fin de journée : {{value}}",
      weekStartsAt: "La semaine commence le : {{value}}",
      recentTransactions: "Transactions récentes",
      laneAllocations: "Allocation des lignes",
      downloadCsvPage: "Télécharger la page actuelle au format CSV",
      csvLimitExceeded:
        "Il n'est pas permis de télécharger plus de $t(numberFormat) enregistrements. Veuillez affiner votre filtre pour inclure moins d'enregistrements et essayez de télécharger à nouveau.",
    },
    form: {
      noOptions: "Pas de donnée",
      allSelected: "Tout(s) {{count}} sélectionné(s)",
      selectedItems: "{{count}} sélectionné(s)",
      selectAll: "Tout sélectionner",
      deselectAll: "Tout désélectionner",
      enterPhoneNo: "Saississer le numéro de téléphone",
      daysWarning: "Certains mois comptent moins de 29 jours",
      daysWarningExecute: "Envoyez plutôt le dernier jour du mois",
      lastMonthDay: "Dernier jour du mois",
      equals: "Egale",
      contains: "Contient",
      err: {
        required: "Champ nécessaire",
        invalidDate: "Date invalide",
        invalidEmail: "Courriel invalide",
        invalidPhone: "Numéro de téléphone invalide",
        invalidDomain: "Nom de domaine (par ex. domain.com)",
        passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
        alphaNumericUnderscoreDashDot:
          "Doit être composé de caractères alphanumériques ou de points, de tirets et de traits de soulignement.",
        higherFrom: "Doit être supérieur à",
      },
      upload: {
        image_one: "Télécharger l’image",
        image_other: "Télécharger les images",
        dropHere: "Déposez vos fichiers ici ou",
        browse: "Naviguer",
        removeAll: "Tout supprimer",
        maxNumber: "Vous ne pouvez pas ajouter plus de fichiers",
        maxFileSize: "La taille du fichier sélectionné dépasse la taille maximale du fichier",
        acceptType: "Le type de fichier sélectionné n'est resolution",
        resolution: "Le fichier sélectionné ne correspond pas à la résolution souhaitée",
      },
      transferBox: {
        included: "Inclus",
        notIncluded: "Non inclus",
        emptyList: "Liste vide",
        showFiltered: "Filtre de {{size}} sur {{fullSize}}",
        showAll: "Affichage de {{size}} sur {{fullSize}}",
        loading: "Chargement",
      },
    },
    remoteMgmt: {
      title: "Gestion à distance (optionnel)",
      selectOperation: "Sélectionner l’opération",
      skip: "Passez cette étape",
      applyConfiguration: "Appliquer la configuration",
      upgradeSoftware: "Mettre à jour le logiciel",
      now: "Maintenant",
      specifyDateAndHour: "Spécifier la date et l'heure",
      selectWhen: "Sélectionnez quand",
      dateAndTime: "Date et heure",
    },
    paymentIntervals: {
      monthly: "Mensuel",
      quarterly: "Trimestrielle",
      annually: "Annuel",
      selectPaymenInterval: "Sélectionnez l'intervalle de paiement",
    },
  },
};
