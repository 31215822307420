export const fr = {
  auth: {
    login: "Connexion",
    resetPassword: "Réinitialiser le mot de passe",
    forgot: "Oublié",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    or: "ou",
    signIn: "Se connecter",
    err: {
      invalid_grant: "Nom d'utilisateur ou mot de passe invalide",
    },
  },
};
