import { SortingState } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { Order } from "generated";

export type SortByColumnsData<ID extends string> = { id: ID } | { enableSorting: false; id?: string };

type Sorting<T> = { order: Order; orderColumn: T | undefined };
const defaultInitialSort: SortingState = [];

export const useSorting = <T extends string>(
  initialSort: SortingState = defaultInitialSort,
): [Sorting<T>, SortingState, React.Dispatch<React.SetStateAction<SortingState>>] => {
  const [sortingState, setSortingState] = useState<SortingState>(initialSort);

  const sorting = useMemo(() => {
    const first = sortingState[0];
    return (
      first ? { order: first.desc ? "DESC" : "ASC", orderColumn: first.id } : { order: "DESC", orderColumn: undefined }
    ) as Sorting<T>;
  }, [sortingState]);

  return [sorting, sortingState, setSortingState];
};
