import { useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { changeFormData, useFormContext } from "common/form";
import { TextLabel } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { unZipFile } from "common/helpers";

import { SoftwarePackageParts } from "./SoftwarePackageParts";
import type { SoftwarePackageFormData } from "./SoftwarePackagesForm";

const errors = {
  _1: "administration.sp.err._1",
  _2: "administration.sp.err._2",
  _3: "administration.sp.err._3",
  _4: "administration.sp.err._4",
};

type Data = { error: string } | Omit<SoftwarePackageFormData, "name">;

const truncateToMilliseconds = (timestamp: string) => timestamp.substr(0, 19) + ".000Z";

const readPackageJsonFile = (files: File) =>
  new Promise<Data>((res) =>
    unZipFile(files, errors._1, errors._2)
      .then(async (file) => {
        const data = await file
          .file("META-INF/package.json")
          ?.async("string")
          .then(JSON.parse)
          .then((v) =>
            v.kind === "software"
              ? ({
                  fileName: files.name,
                  version: v.version,
                  id: v.id || "",
                  parts: v.parts,
                  revision: v.application.revision,
                  label: v.application.label,
                  createdDate: truncateToMilliseconds(v["create-date"]),
                  description: v.description,
                  productName: v.application.name || "",
                  common: !!v.common,
                  createdBy: v["created-by"] ?? "",
                } as Data)
              : errors._3,
          )
          .catch(() => errors._4);

        if (typeof data === "string" || !data) throw new Error(data || errors._4);

        const releaseInfo = await file
          .file("META-INF/release-notes.txt")
          ?.async("string")
          // we dont care about this error, just return empty string
          .catch(() => "");

        res({ ...data, releaseInfo: releaseInfo || "", file: files });
      })
      .catch((e) => res({ error: e.message })),
  );

export const SoftwarePackageZipInput: React.FC = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [data, setData] = useState<Data | null>(null);
  const form = useFormContext().useStore;

  const readFile = (files: FileList | null) => {
    if (files) {
      readPackageJsonFile(files[0]).then((v) => {
        setData(v);

        if ("error" in v) {
          form.getState().reset({ name: form.getState().values.name });
        } else {
          changeFormData(form, v, { revalidate: true });
        }
      });
    }
  };

  return (
    <div tw="pt-4 pb-1">
      <input
        ref={ref}
        tw="h-0 w-0 opacity-0 fixed top-[-100px] left-[-100px]"
        type="file"
        onChange={(e) => readFile(e.target.files)}
        accept="application/zip"
      />

      {data && "error" in data ? <TextLabel error>{data.error}</TextLabel> : null}

      {data && "fileName" in data ? (
        <SoftwarePackageParts data={data} onClick={() => ref.current?.click()} />
      ) : (
        <Button onClick={() => ref.current?.click()} variant={["sm", "side"]} data-test="importByFile">
          <Text tKey="administration.sp.import" />
        </Button>
      )}
    </div>
  );
};
