import {
  Activity,
  ClockCounterClockwise,
  Database,
  Files,
  Gear,
  Info,
  Lightning,
  Percent,
} from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { InfoState, NodeStatusBadge, OnlineStatusBadge, useSiteGroupContext } from "base/components";
import { Badge, Container, FullLine, Spinner, Tabs, Text, Tooltip, useToast } from "common/guideline";
import { copyToClipboard } from "common/helpers";
import { FindMachineByNodeIdQuery, useFindMachineByNodeIdQuery, useGetMachinesOnlineStatusQuery } from "generated";
import { useTenant } from "tenant/context";
import { Commands } from "zts/commands/Commands";
import { Logs } from "zts/commands/logs/Logs";
import { ConfigurationDeployments } from "zts/remoteConfiguration/ConfigurationDeployments";
import { SoftwareInstallations } from "zts/remoteUpgrade/SoftwareInstallations";

import { MachineContents } from "./MachineContents";
import { MachineHealth } from "./MachineHealth";
import { MachineInformation } from "./MachineInformation";
import { MachineUsage } from "./MachineUsage";

export type Machine = NonNullable<FindMachineByNodeIdQuery["findMachineByNodeId"]>;

type MachineHeaderProps = {
  machine: Machine;
};

const MachineHeader: React.FC<MachineHeaderProps> = ({ machine }) => {
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";

  const { data } = useGetMachinesOnlineStatusQuery({
    skip: !machine.uuid || !tenantId,
    returnPartialData: true,
    variables: { input: { tenantId, machineUuid: machine.uuid, limit: 1 } },
  });

  const statusDate = data?.getMachinesOnlineStatus?.rows?.[0]?.receivedDate;
  const onlineStatus = data?.getMachinesOnlineStatus?.rows?.[0]?.onlineStatus;

  return (
    <div tw="flex flex-row gap-2">
      <div tw="flex items-center gap-2">
        <div tw="flex items-center gap-2"></div>
        <div tw="w-auto inline-flex items-center gap-2">
          <Text tKey="machine.uuid" variant="labelAlt" tValue={{ colon: true }} />
          <Badge variant={["normalCase", "neutral"]}>{machine.uuid}</Badge>
          <CopySVG
            tw="cursor-pointer"
            width={20}
            height={20}
            onClick={() => copyToClipboard(machine.uuid || "", () => useToast.actions.show("common.clipboard"))}
          />
        </div>
        <NodeStatusBadge value={machine.nodeStatus as any}>
          {statusDate && (
            <Tooltip
              content={<Text tKey="machine.machineStatus" tValue={{ date: statusDate }} />}
              data-test="statusDate"
            >
              <Info tw="ml-1" />
            </Tooltip>
          )}
        </NodeStatusBadge>
        <OnlineStatusBadge value={onlineStatus} />
      </div>
    </div>
  );
};

export const MachineView = () => {
  const { machine: machineId } = useSiteGroupContext();

  const { data, loading, error } = useFindMachineByNodeIdQuery({
    skip: !machineId,
    returnPartialData: true,
    variables: { nodeId: machineId || "" },
  });

  const machine = data?.findMachineByNodeId as Machine | null;

  return loading ? (
    <Container tw="flex justify-center">
      <Spinner />
    </Container>
  ) : error ? (
    <InfoState state="error">{error.message}</InfoState>
  ) : machine ? (
    <div tw="-mt-4">
      <Tabs>
        <div tw="sticky -top-4 z-[1] bg-gray-1 pt-4">
          <MachineHeader machine={machine} />
          <FullLine tw="mt-3" />
          <Tabs.TabList element="nav" tw="flex overflow-x-auto">
            <Tabs.Tab tw="flex-1 p-3" data-test="machineContents">
              <Database tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="machine.contents" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="machineUsage">
              <Percent tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="machine.usage" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="machineHealth">
              <Activity tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="machine.health" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="machineInformation">
              <Info tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="machine.information" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="machineLogs">
              <Files tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="zts.commands.logs.title" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="commands">
              <Lightning tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="zts.commands.title" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="upgradeHistory">
              <ClockCounterClockwise tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="zts.remoteUpgrade.upgradeHistory" />
            </Tabs.Tab>
            <Tabs.Tab tw="flex-1 p-3" data-test="configurationHistory">
              <Gear tw="transition-all duration-300 mr-2" size={16} weight="duotone" />
              <Text tKey="zts.remoteConfiguration.configurationHistory" />
            </Tabs.Tab>
          </Tabs.TabList>
        </div>
        <Tabs.Panels>
          <Tabs.Panel as={MachineContents} nodeId={machine.nodeId} />
          <Tabs.Panel as={MachineUsage} nodeId={machine.nodeId} />
          <Tabs.Panel as={MachineHealth} nodeId={machine.nodeId} />
          <Tabs.Panel as={MachineInformation} machine={machine} />
          <Tabs.Panel as={Logs} machine={machine} />
          <Tabs.Panel as={Commands} machine={machine} />
          <Tabs.Panel as={SoftwareInstallations} machine={machine} />
          <Tabs.Panel as={ConfigurationDeployments} machine={machine} />
        </Tabs.Panels>
      </Tabs>
    </div>
  ) : null;
};
