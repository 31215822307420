import { createContext, useContext, useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { useQueryByDateFilter, useTimeZoneFilter } from "base/components";
import { SelectRaw } from "common/form/renderFields";
import { useDateFilter } from "common/guideline";
import { CurrencyCodes, currencyOptions } from "common/helpers";
import { Scalars, TransactionDateTime, TransactionType } from "generated";

type TTransportFilterContext = {
  date: Date;
  currency: CurrencyCodes;
  type: TransactionType;
  transactionDateTime: TransactionDateTime;
  zoneId: Scalars["ZoneId"] | undefined;
};

const TransportFilterContext = createContext({} as TTransportFilterContext);

export const useTransportFilters = () => useContext(TransportFilterContext);

const toDate = new Date();

// We are using `render props` pattern here, to allow consumer of this component - place filters wherever needed
type Props = {
  children: (filters: React.ReactNode) => React.ReactNode;
};

const transactionTypes: { value: TransactionType; label: string }[] = [
  {
    label: "transport.transactionType.all",
    value: "ALL",
  },
  {
    label: "transport.transactionType.correction",
    value: "CORRECTION_TRANSACTIONS",
  },
  {
    label: "transport.transactionType.machine",
    value: "MACHINE_TRANSACTIONS",
  },
];

const Wrapper = styled.div`
  ${tw`flex flex-wrap gap-2`}
`;

export const TransportFilters = ({ children }: Props) => {
  const [date, DateFilter] = useDateFilter({ variant: "sm", toDate });
  const [currency, setCurrency] = useState<CurrencyCodes>("USD");
  const [type, setType] = useState<TransactionType>("ALL");
  const [transactionDateTime] = useQueryByDateFilter("transactions");
  const [zoneId] = useTimeZoneFilter();

  return (
    <TransportFilterContext.Provider value={{ date, currency, type, zoneId, transactionDateTime }}>
      {children(
        <Wrapper>
          <SelectRaw
            tw="min-w-[200px]"
            name="type"
            options={transactionTypes}
            value={type}
            onChange={setType}
            variant="sm"
            labelVariant="row"
          />
          {DateFilter}
          <SelectRaw
            tw="min-w-[65px]"
            name="currency"
            options={currencyOptions}
            value={currency}
            onChange={setCurrency}
            variant="sm"
            labelVariant="row"
          />
        </Wrapper>,
      )}
    </TransportFilterContext.Provider>
  );
};
