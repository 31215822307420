import tw from "twin.macro";
import "styled-components/macro";

export const TableSkeleton: React.FC = () => (
  <div tw="animate-pulse border-gray-3 border rounded-md flex-1 flex flex-col overflow-hidden">
    <div tw="h-12 border-b border-gray-3 flex justify-between items-center px-4">
      <div tw="h-4 bg-gray-3 w-24" />
      <div tw="flex justify-between items-center">
        <div tw="h-4 bg-gray-3 w-20 mr-2 hidden sm:(block)" />
        <div tw="h-8 bg-gray-3 w-18" />
      </div>
    </div>
    <div tw="flex-1 h-0">
      <div tw="h-10" />
      <div tw="h-16 bg-gray-2" />
      <div tw="h-16" />
      <div tw="h-16 bg-gray-2" />
      <div tw="h-16" />
      <div tw="h-16 bg-gray-2" />
      <div tw="h-16" />
      <div tw="h-16 bg-gray-2" />
      <div tw="h-16" />
      <div tw="h-16 bg-gray-2" />
      <div tw="h-16" />
      <div tw="h-16 bg-gray-2" />
    </div>
    <div tw="h-12 bg-gray-1 border-t border-gray-3 flex items-center gap-x-1 px-4 justify-center sm:(justify-end)">
      <div tw="h-4 bg-gray-3 w-4 sm:w-20" />
      <div tw="h-6 bg-gray-3 w-[1px]" />
      <div tw="h-6 bg-gray-3 w-6" />
      <div tw="h-4 bg-gray-3 w-16" />
      <div tw="h-6 bg-gray-3 w-[1px]" />
      <div tw="h-4 bg-gray-3 w-4 sm:w-20" />
    </div>
  </div>
);
