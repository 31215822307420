import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "PROFILING", label: "zts.commands.logs.profiling" },
  { value: "CONFIGURATION", label: "zts.commands.logs.configuration" },
  { value: "REPORTS", label: "zts.commands.logs.reports" },
  { value: "HISTORY", label: "zts.commands.logs.history" },
  { value: "RECEIPTS", label: "zts.commands.logs.receipts" },
  { value: "ICX", label: "zts.commands.logs.icx" },
  { value: "DATA", label: "zts.commands.logs.data" },
  { value: "DEVICES", label: "zts.commands.logs.devices" },
  { value: "ONLINE", label: "zts.commands.logs.online" },
  { value: "TRACE", label: "zts.commands.logs.trace" },
];

export const getLogTypesField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "zts.commands.logs.logTypes",
    options,
    isMulti: true,
    ...values,
  });
