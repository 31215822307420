export const es = {
  location: {
    location_one: "Sitio",
    location_other: "Sitios",
    useLocation: "Utilizar este sitio",
    cancel: "Cancelar",
    siteDetails: "Detalles del sitio",
    selectOnMap: "Seleccionar en mapa",
    longitude: "Longitud",
    latitude: "Latitud",
    customerReference: "Referencia del cliente",
    description: "Descripcion",
    geographicalLocation: "Ubicacion geografica",
    country: "Pais",
    countryCode: "Codigo del pais",
    city: "Ciudad",
    region: "Estado | Provincia | Condado",
    zipCode: "Codigo postal",
    title: "Sitios",
    create: "Crear sitio",
    createDescription: "Se crea un sitio para configurar un nuevo sistema de gestion de efectivo",
    update: "Actualizar sitio",
    updateDescription: "Actualiza un sitio para editar un sistema de gestión de efectivo existente",
    delete: "Borrar sitio",
    deleteDescription: "Se borra un sitio cuando se desea eliminar un sistema de gestion de efectivo existente",
    deleteClose: "Cerrar",
    roleName: "Rol",
    name: "Nombre del sitio",
    siteId: "ID del sitio",
    nodeStatus: "Estado",
    nodeStatusCount: "Recuento de estado",
    nodeChangeDate: "Cambiado",
    success: "Sitio creado con exito",
    error: "Fallo la creacion del sitio",
    updateSuccess: "Sitio actualizado con exito",
    updateApiError: "Actualizacion del sitio fallida",
    deleteSuccess: "Sitio eliminado con exito",
    deleteError: "Error en la eliminacion del sitio",
    goBack: "Volver",
    mustBeNumeric: "El valor debe ser de tipo numerico",
    filterSites: "Filtrar sitios",
    list: {
      noData: "No se han encontrado sitios",
    },
  },
};
