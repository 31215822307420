import { useMemo } from "react";

import { CommonCells, Table, TableTypes, accessors, usePagination } from "common/guideline";
import { useFindAllLaneAllocationsQuery } from "generated";
import { useTranslation } from "i18n";

import { RowActions } from "./RowActions";
import { LaneAllocationData, LaneAllocationProps } from "./types";

const adminColumns: TableTypes.TranslatedColumns<LaneAllocationData> = (t) => [
  {
    header: t("mu.title_one"),
    accessorKey: "userName",
  },
  {
    header: t("machine.machine_one"),
    accessorKey: "machineName",
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const columns: TableTypes.TranslatedColumns<LaneAllocationData> = (t) => [
  {
    header: t("administration.la.allocationId"),
    accessorKey: "allocationId",
  },
  {
    header: t("common.site"),
    accessorKey: "locationName",
  },
  {
    header: t("administration.la.workUnit"),
    accessorKey: "workUnitName",
  },
  {
    header: t("administration.la.allocationTime"),
    id: "allocationDateTime",
    accessorFn: (v) => accessors.date(v.allocationDateTime, t),
    sortingFn: "dateString",
  },
  {
    header: t("administration.la.expiration"),
    id: "expirationDateTime",
    accessorFn: (v) => accessors.date(v.expirationDateTime, t),
    sortingFn: "dateString",
  },
];

const TABLE_NAME = "laneAllocations";

export const LaneAllocationsTable: React.FC<LaneAllocationProps> = ({ isAdminView }) => {
  const { t, i18n } = useTranslation();
  // We will add filtering on site(s) when there is filtering on locationNodeId implemented on the backend.
  // See https://paycomplete.atlassian.net/browse/C2-137.
  // const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const tColumns = useMemo(
    () => columns(t, i18n.language).concat(isAdminView ? adminColumns(t, i18n.language) : []),
    [t, i18n.language, isAdminView],
  );
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllLaneAllocationsQuery({
    variables: {
      laneAllocationFilters: {
        // We will add filtering on site(s) when there is filtering on locationNodeId implemented on the backend.
        // See https://paycomplete.atlassian.net/browse/C2-137.
        // locationNames: location ? location : siteGroup,
        active: true,
      },
      searchRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });

  const result = (data?.findAllLaneAllocations?.result ?? []) as LaneAllocationData[];
  const fullSize = data?.findAllLaneAllocations?.fullSize ?? 0;

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={result}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      totalCount={fullSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPagination={setPagination}
    />
  );
};
