export const de = {
  administration: {
    title: "Administration",
    overview: "Administrations Übersicht",
    description:
      "Durchführung von Verwaltungsaufgaben, z. B. Verwaltung von Benutzern, Änderung des Pincodes, Zugriff auf die Bedieneransicht und Bearbeitung von Arbeitseinheiten",
    allocations: "Zuteilungen",
    allocationsDescription: "Bearbeiten von Workunits und Kassen",
    machines: "Maschinen",
    machinesDescription: "Anzeigen, Verwalten und Erstellen von Maschinen",
    locations: "Standorte",
    locationsDescription: "Anzeigen, Verwalten und Erstellen von Standorten",
    roles: "Rollen",
    rolesDescription: "Anzeigen, Verwalten und Erstellen von Rollen",
    permissions: "Berechtigungsn",
    permissionsDescription: "Anzeigen, Verwalten und Erstellen von Berechtigungen",
    dispenseLimits: "Auszahlungslimits",
    dispenseLimitsDescription: "Begrenzt die menge an Geld die Ausgezahlt werden kann",
    posTransactions: "POS Transaktionen",
    posTransactionsDescription:
      "Der Point of Sale (POS) oder Point of Purchase (POP) ist der Zeitpunkt und der Ort, an dem eine Transaktion im Einzelhandel abgeschlossen wird.",
    configTemplates: "Templates konfigurieren",
    configTemplatesDescription:
      "Exportieren Sie Pakete aus Connect on-Device™ Studio in das Dateisystem Ihres Computers, und importieren Sie sie als Konfigurationsvorlagen in Connect.",
    countries: "Länder",
    country: "Land",
    selectCountries: "Länder auswählen",
    cities: "Städte",
    city: "Stadt",
    selectCities: "Stadt auswählen",
    mg: {
      title: "Maschinengruppe",
      name: "Name",
      description: "Anzeigen, Verwalten und Erstellen von Maschinengruppen",
      create: "Erstelle Maschinengruppe",
      createDescription:
        "Erstellen Sie eine Maschinengruppe, um Maschinen zu einer logischen Einheit zusammenzufassen.",
      update: "Update Maschinengruppe",
      updateDescription:
        "Sie aktualisieren eine Maschinengruppe, wenn Sie eine bestehende intelligente Maschinengruppe aktualisieren möchten",
      delete: "Delete machine group",
      deleteDescription:
        "Sie löschen eine Maschinengruppe, wenn Sie eine bestehende intelligente Maschinengruppe löschen möchten",
      deleteClose: "Schließen",
      nodeChangeDate: "Bearbeiten",
      nodeStatus: "Status",
      nodeStatusCount: "Status zählen",
      machines: "Maschinen",
      availableMachines: "Verfügbare Maschinen",
      nodeId: "Node ID",
      machineNodeIds: "Node IDs der Maschinen",
      machineNodeId: "Node IDs der Maschine",
      success: "Maschinengruppe erfolgreich erstellt",
      error: "Erstellung einer Maschinengruppe fehlgeschlagen",
      updateSuccess: "Maschinengruppe erfolgreich aktualisiert",
      updateApiError: "Aktualisierung der Maschinengruppe fehlgeschlagen",
      deleteSuccess: "Maschinengruppe erfolgreich gelöscht",
      deleteError: "Löschen der Maschinengruppe fehlgeschlagen",
      goBack: "Zurück",
      namesCount: "Namen zählen",
    },
    lg: {
      title_one: "Standortgruppe",
      title_other: "Standortgruppen",
      desc: "Übersicht der Standortgruppen - Sie können Standortgruppen anzeigen, erstellen, aktualisieren und löschen.",
      create: "Standortgruppe erstellen",
      createDescription: "Erstellen Sie eine Standortgruppe, um verwandte Standorte zu verwalten",
      update: "Standortgruppe aktualisieren",
      updateDescription: "Aktualisieren einer bestehenden Standortgruppe",
      delete: "Standortgruppe löschen",
      deleteDescription: "Die Standortgruppe wird gelöscht. Möchten Sie fortfahren?",
      deleteClose: "Schließen",
      name: "Name der Standortgruppe",
      siteGroupId: "Standortgruppen ID",
      sites: "Standort",
      includedSites: "Enthaltene Standorte",
      nodeStatus: "Status",
      nodeStatusCount: "Status zählen",
      nodeChangeDate: "Geändert",
      success: "Standortgruppe erfolgreich erstellt",
      error: "Erstellung einer Standortgruppe fehlgeschlagen",
      updateSuccess: "Standortgruppe erfolgreich aktualisiert",
      updateApiError: "Aktualisierung der Standortgruppe fehlgeschlagen",
      deleteSuccess: "Standortgruppe erfolgreich gelöscht",
      deleteError: "Löschen der Standortgruppe fehlgeschlagen",
      goBack: "Zurück",
      list: {
        noData: "Keine Standortgruppen gefunden",
      },
      namesCount: "Namenszählung",
      authorizedUsers: "Autorisierte Benutzer",
      count: "Zählen",
      noCountriesFound: "Keine Länder gefunden",
      noCitiesFound: "Keine Städte gefunden",
    },
    sp: {
      title: "Software Paket",
      selectSoftwarePackage: "Softwarepaket auswählen",
      desc: "Überblick über die in Connect verfügbaren Softwarepakete und zum Starten des Assistenten zum Erstellen von Softwarepaketen, mit dem Sie neue Softwarepakete hochladen können",
      name: "Name",
      placeholder: "Name des Softwarepakets eingeben",
      tooltip:
        "Verwenden Sie name, um der .zip-Datei des Softwarepakets einen benutzerfreundlichen Namen zu geben. Der Name darf maximal 30 Zeichen einschließlich Leerzeichen enthalten.",
      info: "Info",
      version: "Version",
      revision: "Revision",
      file: "Datei",
      release: "Release",
      createdBy: "Erstellt von",
      createdDate: "Erstellungsdatum",
      description: "Beschreibung",
      releaseInfo: "Release info",
      official: "Dieses Softwarepaket offiziell machen",
      custom: "Benutzerdefiniert",
      label: "Bezeichnung",
      fileRequired: "Sie müssen eine Datei bereitstellen",
      addSp: "Softwarepaket erstellen",
      submit: "Speichern und hochladen",
      update: "Paket aktualisieren",
      import: "Datei auswählen",
      changeFile: "Datei ändern",
      uploadSuccess: "Das Softwarepaket wurde erfolgreich hochgeladen",
      uploadFailure: "Beim Hochladen der Datei ist ein Fehler aufgetreten",
      addSuccess: "Softwarepaket erfolgreich erstellt",
      addApiError: "Erstellung eines Softwarepakets fehlgeschlagen",
      err: {
        _1: "Unbekannter Fehler. Bitte versuchen Sie es später noch einmal.",
        _2: "Muss eine Zip-Datei sein.",
        _3: "Die ausgewählte Datei scheint kein Softwarepaket zu sein.",
        _4: "Fehlende oder ungültige Informationen in der ausgewählten Datei.",
      },
      missingPreSignedUrl: "Vorgegebene Url konnte nicht abgerufen werden",
      delete: "Softwarepaket löschen",
      deleteDescription: "Sowohl die Softwarepaketvorlage als auch die hochgeladene Datei werden gelöscht.",
      deleteError: "Softwarepaket konnte nicht gelöscht werden",
      deleteSuccess: "Softwarepaket erfolgreich gelöscht",
      deleteClose: "Schließen",
    },
    rc: {
      title: "Konfigurationsvorlagen",
      selectTemplate: "Vorlage auswählen",
      desc: "Übersicht über die Konfigurationsvorlagen. Sie können Konfigurationsvorlagen anzeigen, erstellen, hochladen und aktualisieren.",
      name: "Name",
      info: "Info",
      version: "Version",
      createdBy: "Erstellt von",
      createdDate: "Erstellungsdatum",
      description: "Beschreibung",
      file: "Paket",
      fileRequired: "Sie müssen eine Datei bereitstellen",
      configurationFile: "Konfigurationsdatei",
      addRc: "Konfigurationsvorlage erstellen",
      submit: "Speichern und hochladen",
      update: "Vorlage aktualisieren",
      import: "Konfigurationsdatei importieren",
      changeFile: "Datei ändern",
      included: "Inklusive",
      addSuccess: "Konfigurationsvorlage erfolgreich erstellt",
      addApiError: "Erstellung der Konfigurationsvorlage fehlgeschlagen",
      saveTameplateFailure: "Beim Speichern der Vorlage ist ein Fehler aufgetreten",
      uploadSuccess: "Die Konfigurationsdatei wurde erfolgreich hochgeladen",
      uploadFailure: "Beim Hochladen der Datei ist ein Fehler aufgetreten",
      missingPreSignedUrl: "Vorgegebene Url konnte nicht abgerufen werden",
      updateSuccess: "Konfigurationsvorlage erfolgreich aktualisiert",
      updateError: "Beim Aktualisieren der Konfigurationsvorlage ist ein Fehler aufgetreten",
      configuration: "Konfiguration .",
      delete: "Konfigurationsvorlagen löschen",
      deleteDescription: "Sowohl die Konfigurationsvorlage als auch die hochgeladene Datei werden gelöscht",
      deleteError: "Konfigurationsvorlage konnte nicht gelöscht werden",
      deleteSuccess: "Konfigurationsvorlage erfolgreich gelöscht",
      deleteClose: "Schließen",
      receipts: "Beleg",
      i18n: "i18n",
      themes: "Themes",
      sdk: "Sdk",
      scripts: "Scripts",
      help: "Help",
      err: {
        _1: "Unbekannter Fehler. Bitte versuchen Sie es später noch einmal.",
        _2: "Muss eine Zip-Datei sein.",
        _3: "Die ausgewählte Datei scheint keine Konfigurationsvorlagendatei zu sein.",
        _4: "Fehlende oder ungültige Informationen in der ausgewählten Datei.",
      },
    },
  },
  machineUserGroup: {
    title_one: "Benutzergruppe Maschine",
    title_other: "Benutzergruppen Maschine",
    desc: "Dynamische Verwaltung von Benutzern auf der Grundlage von Zugriff und Funktionalität",
    name: "Name",
    authorizedMachines: "Zulässige Maschinen",
    deleteGroup: "Gruppe löschen",
    deleteDesc: "Gruppe <0>{{name}}</0> wird gelöscht werden, diese Aktion kann nicht rückgängig gemacht werden.",
    addMachineUserGroups: "Maschinenbenutzergruppe erstellen",
    addSuccess: "Maschinenbenutzergruppe erfolgreich erstellt",
    addError: "Erstellung einer Maschinenbenutzergruppe fehlgeschlagen",
    updateSuccess: "Benutzergruppe der Maschine erfolgreich aktualisiert",
    updateError: "Aktualisierung der Maschinenbenutzergruppe fehlgeschlagen",
    create: "Erstellen",
    update: "Update",
    updateName: "Gruppenname aktualisieren",
  },
};
