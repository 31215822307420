import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { evictQuery, omitTypenames } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  GetAllRulesDocument,
  GetRulesByNodeIdQuery,
  useGetRulesByNodeIdQuery,
  useUpdateRulesMutation,
} from "generated";

import { AlertForm, AlertFormData } from "./AlertForm";

export type AlertEditParams = { nodeId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "alerts.title",
    route: "ALERTS",
  },
  {
    text: "alerts.update",
    route: "ALERTS_EDIT",
  },
];

const getInitialValues = ({ getRulesByNodeId: rule }: GetRulesByNodeIdQuery | undefined = {}):
  | AlertFormData
  | undefined =>
  rule
    ? {
        name: rule.name as string,
        nodeId: rule.nodeId as string,
        recipients: omitTypenames(rule.recipients),
        filters: omitTypenames(rule.filters),
      }
    : undefined;

export const AlertEdit = () => {
  const { nodeId = "" } = useParams<AlertEditParams>();
  const [updateRules] = useUpdateRulesMutation();
  const [initial, { loading, error }] = useMappedQuery(
    getInitialValues,
    useGetRulesByNodeIdQuery({
      variables: { nodeId },
      skip: !nodeId,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="alerts.update">
      {loading ? null : (
        <AlertForm
          submitLabel="alerts.update"
          initial={initial}
          onSubmit={(input) =>
            updateRules({
              variables: { input },
              ignoreResults: true,
              update: (cache) => evictQuery(GetAllRulesDocument, cache),
            })
              .then(() => {
                useToast.actions.show("alerts.updateSuccess", { variant: "success" });
                navigateTo({ route: "ALERTS" });
              })
              .catch(() => useToast.actions.show("alerts.updateError", { variant: "error" }))
          }
        />
      )}
    </PageLayout>
  );
};
