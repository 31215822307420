import { TKeys } from "i18next";
import { useMemo, useState } from "react";
import tw, { styled } from "twin.macro";

import "styled-components/macro";

import { getMachineUserGroupsTransferBoxField } from "administration/components/getSelectMachineUserGroupField";
import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { getActiveStatuses } from "base/fields/activeStatus";
import { OnSubmit, SchemaForm, UseLiveConfig, useFieldData } from "common/form";
import { CustomRenderFieldTypes, CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { omitByKey } from "common/helpers";
import { MachineUserDtoIn } from "generated";
import { getMachineUserRoleField } from "machineUser/fields";

export type MachineUserFormData = Pick<
  MachineUserDtoIn,
  | "name"
  | "roleName"
  | "userId"
  | "deactivated"
  | "noPin"
  | "pin"
  | "noPinCard"
  | "cardIdentifier"
  | "noPinBio"
  | "noPinDoor"
  | "noPinLogin"
  | "doors"
  | "changePinOnLogin"
  | "validFrom"
  | "validUntil"
  | "nbrOfLogins"
  | "encodePin"
  | "machineUserGroupNodeIds"
> & {
  nodeId: string;
  confirmPin?: string;
  unlimitedNoLogins?: boolean;
  noCardIdentifierLogin?: boolean;
  noDoorsLogin?: boolean;
};

const Wrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const OptionsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div tw="flex flex-col justify-center">
    <TextLabel tKey="mu.loginWith" />
    <div tw="grid gap-1 sm:grid-cols-4">{children}</div>
  </div>
);

const getCustomConfig = (field: string, mapValue = (v) => !!v): UseLiveConfig<CustomRenderFieldTypes["input"]> =>
  function useCustomConfig(prev) {
    const customConfig = mapValue(useFieldData(field, "values"));
    return [useMemo(() => ({ ...prev, disabled: customConfig }), [prev, customConfig]), true];
  };

const getFields: (updateForm: boolean | undefined) => CustomRenderFields[] = (updateForm) => [
  {
    type: "container",
    Component: getFormTitle("mu.userSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "mu.name",
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "userId",
        label: "mu.userId",
        validate: { type: "string", required: true },
        disabled: updateForm,
      },
      getMachineUserRoleField({ name: "roleName", label: "mu.role", defaultValue: false }),
      getActiveStatuses({ name: "deactivated", label: "mu.status", defaultValue: false }),
      {
        type: "live",
        fieldConfig: {
          type: "text",
          name: "cardIdentifier",
          label: "mu.cardLogin",
          validate: (value, formData) =>
            formData.values.noCardIdentifierLogin && !value ? "common.form.err.required" : null,
        },
        name: "cardIdentifier",
        useConfig: getCustomConfig("noCardIdentifierLogin", (v) => !v) as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noCardIdentifierLogin",
        label: "mu.cardLogin",
      },
      {
        type: "live",
        fieldConfig: {
          type: "text",
          name: "doors",
          label: "mu.doorLogin",
          validate: (value, formData) => (formData.values.noDoorsLogin && !value ? "common.form.err.required" : null),
        },
        name: "doors",
        useConfig: getCustomConfig("noDoorsLogin", (v) => !v) as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noDoorsLogin",
        label: "mu.doorLogin",
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("mu.loginSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "live",
        fieldConfig: {
          type: "password",
          name: "pin",
          label: "user.password",
          validate: {
            type: "string",
          },
        },
        name: "pin",
        useConfig: getCustomConfig("noPin") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "noPin",
        label: "mu.noPasswordRequired",
      },
      {
        type: "live",
        fieldConfig: {
          type: "password",
          name: "confirmPin",
          label: "mu.confirmPassword",
          validate: {
            type: "string",
            custom: (value, formData) =>
              value === formData.values.pin || formData.values.noPin === true ? null : "mu.passwordsDoNotMatch",
          },
        },
        name: "confirmPin",
        useConfig: getCustomConfig("noPin") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        name: "changePinOnLogin",
        label: "mu.changePinOnFirstLogin",
        defaultValue: true,
      },
      {
        type: "container",
        Component: OptionsWrapper,
        fields: [
          {
            type: "checkbox",
            name: "noPinLogin",
            label: "mu.noPinLogin",
          },
          {
            type: "checkbox",
            name: "noPinCard",
            label: "mu.noPinCard",
          },
          {
            type: "checkbox",
            name: "noPinBio",
            label: "mu.noPinBio",
            defaultValue: true,
          },
          {
            type: "checkbox",
            name: "noPinDoor",
            label: "mu.noPinDoor",
          },
        ],
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("mu.accountSettings"),
  },
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "date",
        name: "validFrom",
        label: "mu.validFrom",
        validate: {
          type: "object",
          custom: (value, formData) => (value < formData.values.validUntil ? null : "mu.incorrectTimespan"),
        },
      },
      {
        type: "date",
        name: "validUntil",
        label: "mu.validUntil",
        validate: {
          type: "object",
          custom: (value, formData) => (value > formData.values.validFrom ? null : "mu.incorrectTimespan"),
        },
      },
      {
        type: "live",
        fieldConfig: {
          type: "number",
          name: "nbrOfLogins",
          label: "mu.noAllowedLogins",
        },
        name: "nbrOfLogins",
        useConfig: getCustomConfig("unlimitedNoLogins") as UseLiveConfig<CustomRenderFields>,
      },
      {
        type: "checkbox",
        defaultValue: true,
        name: "unlimitedNoLogins",
        label: "mu.unlimited",
        calculation: {
          updates: {
            nbrOfLogins: (noLogins) => (noLogins === true ? undefined : 3),
          },
        },
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("mu.group_other"),
  },
  getMachineUserGroupsTransferBoxField({ name: "machineUserGroupNodeIds" }),
];

type Props = {
  onSubmit: OnSubmit<MachineUserFormData>;
  submitLabel: TKeys;
  initial?: MachineUserFormData;
  updateForm?: boolean;
};

export const MachineUserForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const [fields] = useState(() => getFields(updateForm));

  return (
    <SchemaForm<MachineUserFormData>
      fields={fields}
      initial={{
        ...(initial || {}),
        encodePin: true,
        noDoorsLogin: Boolean(initial?.doors),
        noCardIdentifierLogin: Boolean(initial?.cardIdentifier),
      }}
      onSubmit={(v, form) =>
        onSubmit(omitByKey(["noDoorsLogin", "noCardIdentifierLogin"])(v) as MachineUserFormData, form)
      }
      customRender={customRender}
      SubmitComponent={() => (
        <div tw="flex justify-between">
          <Button
            tw="mt-6"
            variant="side"
            onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_USERS" })}
            data-test="goBack"
          >
            <Text tKey="mu.goBack" />
          </Button>
          <Button type="submit" tw="mt-6" data-test="submitForm">
            <Text tKey={submitLabel} />
          </Button>
        </div>
      )}
    />
  );
};
