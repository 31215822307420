export const de = {
  user: {
    title_one: "Benutzer",
    title_other: "Benutzer",
    desc: "Benutzerverwaltung - Erstellen, Bearbeiten und Löschen von Admin-Benutzern",
    userId: "Benutzer ID",
    name: "Name",
    lastLogin: "Letztes Login",
    created: "Erstellt",
    createdBy: "Erstellt von",
    userType: "Benutzerart",
    groups: "Gruppen",
    roles: "Rollen",
    noRoles: "Keine Rollen",
    permissions: "Berechtigung",
    createUser: "Benutzer erstellen",
    createUserDesc: "Einen neuen Benutzer anlegen - einen Benutzernamen, einen Namen und Rollen zuweisen.",
    deleteUser: "Benutzer löschen",
    deleteUserDesc: 'Der Benutzer wird gelöscht, klicken Sie auf "Löschen", um fortzufahren.',
    createdDate: "Erstellt",
    lastModified: "Zuletzt geändert",
    firstName: "Vornamme",
    lastName: "Nachname",
    createSuccess: "Benutzer erfolgreich gespeichert",
    createError: "Beim Speichern des Benutzers ist ein Fehler aufgetreten",
    updateSuccess: "Benutzer erfolgreich aktualisiert",
    updatedPasswordSuccess: "Erfolgreich aktualisiertes Benutzerpasswort",
    updatedPasswordError: "Beim Aktualisieren des Benutzerpassworts ist ein Fehler aufgetreten",
    updateError: "Beim Aktualisieren des Benutzers ist ein Fehler aufgetreten",
    deleteSuccess: "Benutzer erfolgreich gelöscht",
    deleteError: "Beim Löschen des Benutzers ist ein Fehler aufgetreten",
    update: "Benutzer aktualisieren",
    userName: "Benutzername",
    password: "Passwort",
    updatePassword: "Update Passwort",
    passwordRequired: "Password benötigt",
    delete: "Löschen",
    allMachines: "Alle Maschinen",
    allSites: "Alle Standorte",
    allSiteGroups: "Alle Standortgruppen",
    allTenants: "Alle Mandanten",
    email: "Email",
    settings: {
      appearance: "Erscheinung",
    },
    identity: {
      title: "Identität",
    },
    access: {
      accessLevel: "Zugriffsstufe",
      admin: "Admin",
      domain: "Domain",
      permissions: "Berechtigung",
      Application_admin: "Admin",
      Application_nonadmin: "nicht Admins",
      UserRole_global_admin: "Global admin",
      UserRole_global_support: "Global support",
      UserRole_admin: "Benutzeradmin",
      UserRole_user: "Benutzer",
      Permission_tenant_ALL: "Alle Mandanten",
      Permission_machine_ALL: "Alle Maschinen",
      // Some misspelling on the backend or in the database, so we need both Permission_machine_ALL and Permission_machine_AL
      Permission_machine_AL: "Alle Maschinen",
      Permission_site_ALL: "Alle Standorte",
      Permission_siteGroup_ALL: "Alle Standortgruppen",
      everyone: "jeder",
    },
  },
};
