import tw from "twin.macro";

import { PageLayout } from "base/components";
import { Breadcrumb } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.releaseNotes",
    route: "RELEASE_NOTES",
  },
];

export const ReleaseNotes = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    pageTitle="common.releaseNotes"
    title="common.releaseNotes"
    subtitle="common.releaseNotesDesc"
  ></PageLayout>
);
