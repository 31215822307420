// IMPORTANT - code inspired by https://github.com/final-form/final-form-calculate
import { flattenObject, getPath, isDeepEqual, setDeep } from "common/helpers";

import { AnyObject, Calculation, FormStore, TForm } from "./types";

type CalculationStringField = Omit<Calculation, "field"> & { field: string };

export const createCalculate = <FormValues = AnyObject>(
  store: FormStore<FormValues>,
  getCalculations: () => Set<Calculation>,
) => {
  let previousActive: null | string = null;
  let previousValues: AnyObject = {};
  let previousValuesBeforeBlur: AnyObject = {};

  const subscriber = async ({ active, values }: Pick<TForm<FormValues>, "active" | "values">) => {
    const calculations = getCalculations();
    if (calculations.size === 0) return;

    let shouldUpdate = false;
    const flatValues = flattenObject(values, true);

    const updateValues = (fieldName: string, value: any) => {
      if (!Object.is(value, flatValues[fieldName])) {
        shouldUpdate = true;
        values = setDeep(values, getPath(fieldName), value);
      }
    };

    const runUpdates = async ({
      field,
      updates,
      updatesOnBlur,
      isEqual = Object.is,
    }: CalculationStringField): Promise<void> => {
      if (
        (typeof updatesOnBlur === "function" ? updatesOnBlur(field) : updatesOnBlur)
          ? // if updates on blur and not blurred - return
            !!active || !previousActive
          : // otherwise run only on field value change, not active field change
            previousActive !== active
      ) {
        return;
      }

      const next = flatValues?.[field];
      const previous = ((updatesOnBlur ? previousValuesBeforeBlur : previousValues) as AnyObject)?.[field];

      if (updates && !isEqual(next, previous)) {
        if (typeof updates === "function") {
          const results = await updates(next, field, values, previousValues);
          Object.keys(results).forEach((destField) => updateValues(destField, results[destField]));
        } else {
          await Promise.all(
            Object.keys(updates).map(async (destField) => {
              const result = await updates[destField](next, values, previousValues);
              updateValues(destField, result);
            }),
          );
        }
      }
    };

    await Promise.all(
      Array.from(calculations).flatMap((calculation) =>
        typeof calculation.field === "string"
          ? runUpdates(calculation as CalculationStringField)
          : Array.isArray(calculation.field)
          ? (calculation.field as string[]).map((name) => runUpdates({ ...calculation, field: name }))
          : Object.keys(flatValues)
              .filter((name) => (calculation.field as RegExp).test(name))
              .map((name) => runUpdates({ ...calculation, field: name })),
      ),
    );

    previousActive = active;
    previousValues = flatValues;

    if (!active) {
      previousValuesBeforeBlur = flatValues;
    }

    if (shouldUpdate) {
      store.setState({ values });
    }
  };

  return store.subscribe((s) => ({ values: s.values, active: s.active }), subscriber, {
    equalityFn: (p, c) => p.active === c.active && isDeepEqual(p.values, c.values),
  });
};
