export const es = {
  mu: {
    title_one: "Usuario de la maquina",
    title_other: "Usuarios de la maquina",
    group_one: "Grupo de usuario",
    group_other: "Grupos de usurios",
    name: "Nombre",
    uploadUsers: "Subir usuarios",
    description: "Ver, gestionar y crear usuarios de maquinas",
    create: "Crear usuario de maquina",
    createDescription: "Se crea un usuario de máquina cuando se desea crear un nuevo usuario de dispositivo Connect",
    update: "Actualizar usuario de maquina",
    updateDescription:
      "Actualizas un usuario de máquina cuando quieres actualizar un usuario de tipo máquina existente",
    delete: "Eliminar usuario de maquina",
    deleteDescription: "Borras un usuario maquina cuando quieres borrar un usuario tipo maquina existente",
    deleteClose: "Cerrar",
    roleName: "Rol",
    userId: "ID de usuario",
    success: "Usuario de maquina creado con exito",
    error: "Fallo al crear usuario de máquina",
    updateSuccess: "Usuario de maquina actualizado con exito",
    updateApiError: "Fallo en actualizacion del usuario de maquina",
    deleteSuccess: "Usuario de máquina eliminado con éxito",
    deleteError: "Fallo en la eliminacion del usuario de la maquina",
    goBack: "Volver",
    machine: "Maquina",
    updateMachineUsers: "Actualizar usuarios de maquina",
    usersXml: "usuarios.xml",
    selectFile: "Seleccionar archivo",
    noFileSelected: "Debe proporcionar un archivo xml valido",
    replaceUsers: "Forzar actualización de propiedades de usuario",
    uploadSuccess: "Cargados con exito los usuarios de la maquina desde el archivo",
    uploadInProgress: "Cargando usuarios de maquina desde archivo",
    uploadFailure: "Se ha producido un error al intentar subir usuarios desde el fichero",
    additionalLogins: "Accesos adicionales",
    noCardLogin: "No inicio de sesion con tarjeta",
    noDoorLogin: "No inicio de sesion con tarjeta",
    cardLogin: "Inicio de sesión con tarjeta",
    doorLogin: "Inicio de sesión de puerta",
    status: "Estado",
    validFrom: "Valido desde",
    validUntil: "Valido hasta",
    userSettings: "Configuracion del usuario",
    loginSettings: "Configuracion de inicio de sesion",
    accountSettings: "Configuracion de la cuenta",
    role: "Rol",
    confirmPassword: "Confirmar contraseña",
    noPasswordRequired: "No se requiere contraseña",
    changePinOnFirstLogin: "¿Cambiar contraseña en el primer inicio de sesión?",
    noAllowedLogins: "Numero de inicios de sesion permitidos",
    deactivated: "¿Desactivado?",
    passwordsDoNotMatch: "La contraseña y su confirmacion no coinciden",
    unlimited: "Ilimitado",
    incorrectTimespan: "Valido hasta debe ser mayor que Valido desde",
    cashier: "Usuario de la maquina",
    cashierId: "ID de usuario de maquina",
    cashierName: "Nombre de usuario de maquina",
    cashierRole: "Rol del usuario de la maquina",
    cit: "ETF",
    administrator: "Administrador",
    dts: "DTS",
    manager: "Gestor",
    noPinLogin: "ID de usuario",
    noPinCard: "Tarjeta",
    noPinBio: "Biometrícos",
    noPinDoor: "Puerta",
    loginWith: "Sin contraseña para:",
  },
};
