export const es = {
  alerts: {
    title: "Alertas",
    description: "Configurar, crear y eliminar alertas",
    history: "Historial de alertas",
    historyDescription:
      "Obtenga el historial de alertas enviadas y sus destinatarios. Debe configurar las alertas antes de crear los informes del historial de alertas o el informe aparecerá vacio.",
    date: "Fecha",
    level: "Nivel",
    text: "Texto de alerta",
    type: "Tipo",
    contact: "Contacto",
    name: "Nombre",
    notifications: "Notificaciones",
    filtering: "Filtrado",
    timeBetween: "está entre {{from}} y {{to}}",
    recipients: "Destinatarios",
    goBack: "Volver",
    create: "Crear Alerta",
    update: "Actualizar Alerta",
    createSuccess: "Alerta creada correctamente",
    createError: "Fallo al crear Alerta",
    updateSuccess: "Alerta actualizada correctamente",
    updateError: "Error actualizando alerta",
    deleteSuccess: "Alerta borrada correctamente",
    deleteError: "Error eliminando alerta",
    delete: "Borrar",
    close: "Cerrar",
    deleteAlert: "Borrar alerta",
    deleteDescription: "Alerta <0>{{name}}</0> se borrara permanentemente.",
    fromTime: "Desde",
    toTime: "a",
    currency: "Divisa",
    amount: "Importe",
    selectToShowFilters: "Seleccione el evento para mostrar filtros",
    all: "Todo",
    filtered: "Filtrado",
    notFiltered: "No",
    filters: "Filtros",
    alertLevel: {
      ERROR: "Error",
      INFO: "Informacion",
      UNKNOWN: "Desconocido",
      WARNING: "Advertencia",
    },
    triggerType: {
      EMAIL: "Email",
      SMS: "Sms",
      WEBHOOK: "Webhook",
      UNKNOWN: "Desconocido",
    },
    filterType: {
      MESSAGE_TYPE: "Tipo de mensaje",
      MACHINE_DISCONNECTED: "Máquina desconectada",
      MACHINE_CONNECTED: "Máquina conectada",
      ERROR_CLEARED: "Error borrado",
      ERROR_INFORMATION: "Informacion de errores",
      ERROR_LOCATION: "Localización de errores",
      ERROR_KIND: "Tipo de error",
      MACHINE_UUID_FILTER: "Maquinas",
      MESSAGE_OLD_STATUS: "Mensaje estado antiguo",
      LOCATION_FILTER: "Sitios",
      ERROR_SEVERITY: "Gravedad del error",
      DOOR_OPEN: "Puerta abierta",
      MESSAGE_STATUS: "Mensaje de estado",
      TIME_OF_DAY: "Hora del dia",
      TRANSACTION_SUB_TYPE: "Subtipo de transaccion",
      ERROR_UNCLEARED: "Error no aclarado",
      DOOR_CLOSED: "Puerta cerrada",
      BOX_GOES_ABOVE_COUNT: "La caja supera el recuento",
      BOX_GOES_BELOW_COUNT: "Caja por debajo del recuento",
      TRANSACTION_TYPE: "Tipo de transaccion",
      DEVICE_ID: "ID de dispositivo",
      TRANSACTION_DEVICE_ID: "ID de dispositivo de transaccion",
      ANY_BOX_EXCEEDS_COUNT_RATIO: "Cualquier casilla excede el ratio de recuento",
      TRANSACTION_BOX_ID: "ID del buzón de transaccion",
      ANY_BOX_EXCEEDS: "Cualquier caja supera",
      CONTENT_EXCEEDS: "El contenido supera",
      USER_BALANCE_CHANGE_BELOW_THRESHOLD: "Cambio de saldo del usuario por debajo del umbral",
      USER_BALANCE_CHANGE_ABOVE_THRESHOLD: "Cambio de saldo del usuario por debajo del umbral",
      WEBHOOK: "Webhook",
    },
    messageType: "Evento",
    messageTypes: {
      machines: "Maquinas",
      UNKNOWN: "Desconocida",
      ERROR: "Error recibido",
      MACHINE_CONTENT_CHANGE: "Contenidos actualizados",
      MACHINE_ONLINE_STATUS_CHANGE: "Estado en línea cambiado",
      SYSTEM_STATUS: "Estado actualizado",
      TRANSACTION: "Transacciones",
      TRANSACTION_WITH_BOX_CONTENT: "Transaccion con contenido de caja",
    },
  },
};
