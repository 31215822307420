import tw, { styled } from "twin.macro";

import "styled-components/macro";
import { CustomRenderFields } from "common/form/renderFields";

const OneColumnWrapper = styled.div`
  ${tw`flex flex-col gap-2`}
`;

const TwoColumnWrapper = styled.div`
  ${tw`grid md:grid-cols-2 gap-2 items-start`}
`;

export const getOneColumnWrapper = (fields: CustomRenderFields[]): CustomRenderFields => ({
  type: "container",
  Component: OneColumnWrapper,
  fields,
});

export const getTwoColumnWrapper = (fields: CustomRenderFields[]): CustomRenderFields => ({
  type: "container",
  Component: TwoColumnWrapper,
  fields,
});
