import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as MachineGroupSVG } from "assets/icons/MachineGroup.svg";
import { PageLayout } from "base/components";
import { Button, Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";
import { MachineGroupTable } from "./MachineGroupTable/MachineGroupTable";

export const MachineGroup = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.mg.title"
    subtitle="administration.mg.description"
    Icon={MachineGroupSVG}
    actions={
      <Link route="MACHINE_GROUP_CREATE">
        <Button>
          <Text tKey="administration.mg.create" />
        </Button>
      </Link>
    }
  >
    <MachineGroupTable />
  </PageLayout>
);
