import { RouteConfig, RouteValue } from "routing";

import { CorrectionReasons, CorrectionTransactions, Transport } from "./pages";

export type TransportPaths = {
  TRANSPORT: RouteValue;
  CORRECTION_REASONS: RouteValue;
  CORRECTION_TRANSACTIONS: RouteValue;
};

export const TRANSPORT: RouteConfig<TransportPaths> = {
  TRANSPORT: {
    path: "/transport",
    Component: Transport,
  },
  CORRECTION_REASONS: {
    path: "/transport/correction-reasons",
    Component: CorrectionReasons,
  },
  CORRECTION_TRANSACTIONS: {
    path: "/transport/correction-transactions",
    Component: CorrectionTransactions,
  },
};
