import { useMemo } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindAllMachineTypePatternsQuery } from "generated";
import { useTenant } from "tenant/context";

const useConfigByTenant: SelectLiveFieldUseConfig = (prev) => {
  const machineTypes = useTenant(useTenant.actions.getTenant)?.machineTypes;
  const data = useMemo(
    () => ({
      ...prev,
      options: machineTypes?.map((m) => ({ label: m?.name, value: m?.nodeId })).sort(makeSorter("label")) || [],
    }),
    [machineTypes, prev],
  );

  return [data, true];
};

export const getActiveTenantMachineTypeField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machine.machineType",
      isMulti: false,
      ...value,
    },
    useConfigByTenant,
  );

type MachineTypeOption = {
  label: string;
  value: string;
};

const useConfigAll: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: MachineTypeOption[] =
      d?.findAllMachineTypePatterns
        ?.flatMap((p) => (p ? [{ label: p.name || "", value: p.name || "", data: p }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllMachineTypePatternsQuery());

  return [data, !loading];
};

export const getAllMachineTypesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machine.machineType",
      isMulti: true,
      getByValue: false,
      ...value,
    },
    useConfigAll,
  );
