import { Navigate, navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { useFlagsWithLoading } from "base/featureFlags";
import { Breadcrumb, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllOtaAuthorizationsDocument, useCreateOtaAuthorizationMutation } from "generated";
import { OTAForm, omitInternalFields } from "ota/components/OTAForm";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "ota.list",
    route: "OTA_LIST",
  },
  {
    text: "ota.create",
    route: "OTA_CREATE",
  },
];

export const OTACreate = () => {
  const [createOtaAuthorization] = useCreateOtaAuthorizationMutation();
  const { flags, isLoading } = useFlagsWithLoading();

  if (isLoading) return null;

  return flags.otaPages ? (
    <PageLayout breadcrumbs={breadcrumbs} title="ota.create" subtitle="ota.createDesc">
      <OTAForm
        submitLabel="ota.create"
        onSubmit={(data) => {
          createOtaAuthorization({
            variables: { otaAuthorizationIn: omitInternalFields(data) },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindAllOtaAuthorizationsDocument, cache);
            },
          })
            .then((v) => {
              if (!v.data?.createOtaAuthorization?.nodeId) throw new Error();
              useToast.actions.show("ota.createSuccess", { variant: "success" });
              navigateTo({ route: "OTA_LIST" });
            })
            .catch(() => useToast.actions.show("ota.createError", { variant: "error" }));
        }}
      />
    </PageLayout>
  ) : (
    <Navigate route="HOME" replace />
  );
};
