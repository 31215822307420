import { Backspace, CaretDoubleRight, CaretRight } from "@phosphor-icons/react";
import { useVirtual } from "@tanstack/react-virtual";
import { TKeys } from "i18next";
import { useMemo, useRef, useState } from "react";
import tw, { css } from "twin.macro";

import "styled-components/macro";
import { Button, Line, Spinner, Text } from "common/guideline/components";
import { rafThrottle } from "common/helpers";
import { useDebouncedCallback } from "common/hooks";

import { RawFieldData, RawFieldProps, fieldFactory } from "../schema";

import { TextLabel } from "./Common";
import { InputRaw } from "./Input";

type TransferBoxItemsListProps = Pick<TransferBoxProps, "isLoading" | "onScrollToBottom"> & {
  list: Option[];
  selected: Map<string, boolean>;
  setSelected: (selected: Map<string, boolean>) => void;
};

const ITEM_HEIGHT = 20;

const estimateSize = () => ITEM_HEIGHT;

const TransferBoxItemsList: React.FC<TransferBoxItemsListProps> = ({
  list,
  isLoading,
  selected,
  setSelected,
  onScrollToBottom,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const { virtualItems, totalSize } = useVirtual({
    parentRef: listRef,
    size: list.length,
    overscan: 100,
    estimateSize,
  });

  const paddingTop = virtualItems?.[0]?.start;
  const paddingBottom = virtualItems.length > 0 ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0) : 0;

  const onClick = (value: string) => setSelected(new Map(selected.set(value, !selected.get(value))));

  const onScroll = useMemo(
    () =>
      onScrollToBottom && !isLoading
        ? rafThrottle(() => {
            if (!listRef.current) return;
            const bottom = listRef.current.scrollHeight - listRef.current.scrollTop === listRef.current.clientHeight;

            if (bottom) onScrollToBottom();
          })
        : undefined,
    [onScrollToBottom, isLoading],
  );

  return (
    <div tw="overflow-y-auto border-gray-4 rounded-md border h-full flex-1 p-1" ref={listRef} onScroll={onScroll}>
      <div style={{ height: paddingTop }} />

      {virtualItems.map((item) => {
        const o = list[item.index];

        return (
          <div
            key={o.value}
            onClick={() => onClick(o.value)}
            css={css`
              height: ${ITEM_HEIGHT}px;
              ${tw`rounded-sm px-2 text-sm cursor-pointer text-gray-6 line-clamp-1`}
              ${selected.get(o.value) ? tw`bg-primary-accent` : ""}
            `}
          >
            {o.label || "-"}
          </div>
        );
      })}
      <div style={{ height: paddingBottom }} />
    </div>
  );
};

type TransferBoxItemProps = Pick<
  TransferBoxProps,
  "isLoading" | "onScrollToBottom" | "options" | "onSearch" | "fullSize"
> & {
  direction: "left" | "right";
  onMove: (optionsToMove: Option[]) => void;
};

const TransferBoxItem: React.FC<TransferBoxItemProps> = ({
  options,
  direction,
  onMove,
  isLoading,
  onScrollToBottom,
  onSearch,
  fullSize,
}) => {
  const hasSearch = !!onSearch;
  const [selected, setSelected] = useState(new Map());
  const [filterText, setFilterText] = useState("");
  const onSearchText = useDebouncedCallback(onSearch ?? (() => null), 200);
  const onSearchChange = (search: string) => {
    if (selected.size > 0) setSelected(new Map());
    setFilterText(search);
    onSearchText?.(search);
  };

  const filteredOptions = useMemo(
    () =>
      filterText && !hasSearch
        ? options?.filter((o) => String(o.label).toLowerCase().includes(filterText.toLowerCase()))
        : [],
    [filterText, options, hasSearch],
  );
  const filterLength = filteredOptions.length;
  const list = filterText && !hasSearch ? filteredOptions : options;

  const moveAll = () => {
    onMove(list);
    setSelected(new Map());
    onSearchChange("");
  };

  const moveSelected = () => {
    onMove(Array.from(selected.entries()).flatMap(([k, v]) => (v ? options.find((o) => o.value === k) ?? [] : [])));
    setSelected(new Map());
  };

  return (
    <div tw="flex flex-col w-[200px]">
      <Text
        variant="description"
        tKey={direction === "left" ? "common.form.transferBox.included" : "common.form.transferBox.notIncluded"}
      />
      <Line tw="my-1" />
      <div tw="flex justify-between items-center py-1">
        <Text
          variant="subtitle"
          tKey={
            isLoading
              ? "common.form.transferBox.loading"
              : options.length === 0
              ? "common.form.transferBox.emptyList"
              : filterText
              ? "common.form.transferBox.showFiltered"
              : "common.form.transferBox.showAll"
          }
          tValue={{
            size: hasSearch || !filterText ? options.length : filterLength,
            fullSize: fullSize ?? options.length,
          }}
        />

        <Button
          css={css`
            ${filterText ? "" : tw`invisible`}
          `}
          onClick={() => onSearchChange("")}
          variant={["ghost", "sm"]}
          title="clear filter"
        >
          <Backspace weight="duotone" size={14} tw="text-error-default" />
        </Button>
      </div>
      <InputRaw
        name="filter"
        type="text"
        placeholder="common.table.filter.filter"
        variant="sm"
        value={filterText}
        onChange={(e) => onSearchChange(e.target.value)}
      />
      <div tw="flex-1 flex flex-col overflow-hidden">
        <div
          css={css`
            ${tw`flex py-1 gap-2 text-primary-default`}
            ${direction === "left" ? tw`rotate-180` : ""}
          `}
        >
          <Button tw="flex-1" variant={["ghost", "sm"]} onClick={moveAll} disabled={list.length === 0} title="move all">
            <CaretDoubleRight weight="duotone" size={16} />
          </Button>
          <Button
            tw="flex-1"
            variant={["ghost", "sm"]}
            onClick={moveSelected}
            disabled={selected.size === 0}
            title="move selected"
          >
            <CaretRight weight="duotone" size={16} />
          </Button>
        </div>

        <div tw="relative overflow-hidden h-full">
          <TransferBoxItemsList
            list={list}
            selected={selected}
            setSelected={setSelected}
            isLoading={isLoading}
            onScrollToBottom={onScrollToBottom}
          />
          {isLoading && (
            <div tw="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

type Option = { value: string; label: string };

export type TransferBoxProps = RawFieldProps<{
  type: "transferBox";
  label?: TKeys;
  disabled?: boolean;
  value: string[] | undefined;
  options: Option[];
  isLoading?: boolean;
  onScrollToBottom?: () => void;
  labelCtx?: any;
  onSearch?: (searchText: string) => void;
  fullSize?: number;
}>;

export const TransferBoxRaw: React.FC<RawFieldData<Omit<TransferBoxProps, "type">>> = ({
  label,
  options,
  value,
  onChange,
  error,
  isLoading,
  labelCtx,
  onScrollToBottom,
  onSearch,
  fullSize,
}) => {
  const { optionsMapped, onMoveLeft, onMoveRight } = useMemo(() => {
    const optionsMapped = options.reduce(
      (acc, curr) => {
        value?.includes(curr.value) ? acc[0].push(curr) : acc[1].push(curr);
        return acc;
      },
      [[], []] as [Option[], Option[]],
    );

    return {
      optionsMapped,
      onMoveRight: (options) => onChange([...optionsMapped[0], ...options].map((o) => o.value)),
      onMoveLeft: (options) =>
        onChange(optionsMapped[0].filter((s) => !options.some((o) => o.value === s.value)).map((o) => o.value)),
    };
  }, [options, value, onChange]);

  return (
    <div>
      {label && <TextLabel tKey={label} tValue={labelCtx} error={error} />}

      <div tw="h-[250px] flex gap-2 py-2">
        <TransferBoxItem
          options={optionsMapped[1]}
          direction="right"
          onMove={onMoveRight}
          isLoading={isLoading}
          fullSize={fullSize}
          onSearch={onSearch}
          onScrollToBottom={onScrollToBottom}
        />
        <TransferBoxItem options={optionsMapped[0]} direction="left" onMove={onMoveLeft} />
      </div>

      {error && <TextLabel error={error}>{error}</TextLabel>}
    </div>
  );
};

export const TransferBoxField = fieldFactory(TransferBoxRaw);
