import { RouteConfig, RouteValue } from "routing";

import { LocationCreate, LocationUpdateParams, Locations, UpdateLocationScreen } from "./pages";

export type LocationPaths = {
  LOCATIONS: RouteValue;
  LOCATION_CREATE: RouteValue;
  LOCATION_UPDATE: RouteValue<{ params: LocationUpdateParams }>;
};

export const LOCATION: RouteConfig<LocationPaths> = {
  LOCATIONS: {
    path: "/administration/sites",
    Component: Locations,
  },
  LOCATION_CREATE: {
    path: "/administration/create-site",
    Component: LocationCreate,
  },
  LOCATION_UPDATE: {
    path: "/administration/update-site/:nodeId",
    Component: UpdateLocationScreen,
  },
};
