// import { Navigate } from "appRouting";
import { ReactComponent as PaperPlaneTiltSVG } from "assets/icons/PaperPlaneTilt.svg";
import { PageLayout } from "base/components";
// import { useFlags } from "base/featureFlags";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
// import { evictQuery, isEmpty } from "common/helpers";
import { evictQuery } from "common/helpers";
import { GetAllScheduledReportsDocument, useCreateScheduledReportMutation } from "generated";

import { breadcrumbs } from "./breadcrumbs";
import { ScheduledReportsWizard } from "./ScheduledReportsForm/WizardForm";
import { ScheduledReportsTable } from "./ScheduledReportsTable/ScheduledReportsTable";

const WizardModal: React.FC = () => {
  const [mutation] = useCreateScheduledReportMutation();

  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "flex", "md"], backdropClose: false },
    Component: (
      <ScheduledReportsWizard
        submitLabel="report.sr.create"
        onSubmit={(value) =>
          mutation({
            variables: { input: value },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(GetAllScheduledReportsDocument, cache);
            },
          })
            .then(() => {
              toggle();
              useToast.actions.show("report.sr.createSuccess", { variant: "success" });
            })
            .catch(() => useToast.actions.show("report.sr.createError", { variant: "error" }))
        }
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showModalForm">
      <Text tKey="report.sr.create" />
    </Button>
  );
};

export const ScheduledReportsList = () => {
  // disable falgs check for now, untill we will have QA env flags scope
  // const flags = useFlags();

  // if (isEmpty(flags)) return null;

  // if (!flags.scheduledReportsPages) return <Navigate route="HOME" />;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="scheduledReports"
      subtitle="report.scheduledReportsDescription"
      Icon={PaperPlaneTiltSVG}
      actions={<WizardModal />}
    >
      <ScheduledReportsTable />
    </PageLayout>
  );
};
