import administration from "administration/translations";
import alert from "alert/translations";
import auth from "auth/translations";
import base from "base/translations";
import common from "common/translations";
import location from "location/translations";
import machine from "machine/translations";
import machineUser from "machineUser/translations";
import operations from "operations/translations";
import ota from "ota/translations";
import report from "report/translations";
import tenant from "tenant/translations";
import transport from "transport/translations";
import user from "user/translations";
import zts from "zts/translations";

import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";

export const translations = {
  "en-US": {
    translation: {
      ...en,
      ...administration.en,
      ...auth.en,
      ...alert.en,
      ...base.en,
      ...common.en,
      ...location.en,
      ...machine.en,
      ...machineUser.en,
      ...operations.en,
      ...report.en,
      ...tenant.en,
      ...transport.en,
      ...user.en,
      ...zts.en,
      ...ota.en,
    },
  },
  "de-DE": {
    translation: {
      ...de,
      ...administration.de,
      ...auth.de,
      ...alert.de,
      ...base.de,
      ...common.de,
      ...location.de,
      ...machine.de,
      ...machineUser.de,
      ...operations.de,
      ...report.de,
      ...tenant.de,
      ...transport.de,
      ...user.de,
      ...zts.de,
      ...ota.de,
    },
  },
  "es-ES": {
    translation: {
      ...es,
      ...administration.es,
      ...auth.es,
      ...alert.es,
      ...base.es,
      ...common.es,
      ...location.es,
      ...machine.es,
      ...machineUser.es,
      ...operations.es,
      ...report.es,
      ...tenant.es,
      ...transport.es,
      ...user.es,
      ...zts.es,
      ...ota.es,
    },
  },
  "fr-FR": {
    translation: {
      ...fr,
      ...administration.fr,
      ...auth.fr,
      ...alert.fr,
      ...base.fr,
      ...common.fr,
      ...location.fr,
      ...machine.fr,
      ...machineUser.fr,
      ...operations.fr,
      ...report.fr,
      ...tenant.fr,
      ...transport.fr,
      ...user.fr,
      ...zts.fr,
      ...ota.fr,
    },
  },
} as const;
