import { useEffect, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { LaneAllocationsTable } from "administration/pages/LaneAllocations/LaneAllocationsTable/LaneAllocationsTable";
import { PageLayout, useMappedSiteGroupContext, useSiteGroupContext } from "base/components";
import { Button, FullLine, Text } from "common/guideline";
import { MachineView } from "machine/components";
import { MapOverlay } from "operations/components/MapOverlay";
import { OperationsMap } from "operations/components/OperationsMap";
import { TransactionsTable } from "report/pages";

import { breadcrumbs } from "./breadcrumbs";

const ViewWithMap = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [{ location, siteGroup }] = useMappedSiteGroupContext(true);
  const [showLaneAllocations, setShowLaneAllocations] = useState(location === undefined && siteGroup === undefined);

  useEffect(() => {
    setShowLaneAllocations(location === undefined && siteGroup === undefined);
  }, [location, siteGroup]);

  return (
    <>
      <div tw="flex h-full min-h-[80rem] sm:min-h-[30rem] relative">
        <Button tw="absolute top-2.5 right-2.5 z-[1]" onClick={() => setIsMapOpen(!isMapOpen)}>
          <Text tKey={isMapOpen ? "operations.closeMap" : "operations.openMap"} />
        </Button>
        {isMapOpen ? <OperationsMap /> : <MapOverlay />}
      </div>
      {isMapOpen ? null : (
        <>
          {/* For now, we will show Lane allocations only if neither site group nor site is selected. */}
          {/* When https://paycomplete.atlassian.net/browse/C2-137 is implemented, we will remove this check. */}
          {showLaneAllocations && (
            <div tw="my-4">
              <Text variant="heading" tKey="common.table.laneAllocations" />
              <FullLine variant="transparent" tw="my-1.5" />
              <LaneAllocationsTable isAdminView={false} />
            </div>
          )}
          <div tw="my-4">
            <Text variant="heading" tKey="common.table.recentTransactions" />
            <FullLine variant="transparent" tw="my-1.5" />
            <TransactionsTable
              showOptions={false}
              tableName="cashOpsRecentTransactions"
              defaultReportingPeriod={"currentDay"}
            />
          </div>
        </>
      )}
    </>
  );
};

export const CashOperations = () => {
  const context = useSiteGroupContext();

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="operations.cashOps.title"
      subtitle="operations.cashOps.desc"
      withPicker
      slim
    >
      {context.machine ? <MachineView /> : <ViewWithMap />}
    </PageLayout>
  );
};
