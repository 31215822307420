import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { FindListOfUsersQuery, FindListOfUsersQueryVariables, useFindListOfUsersQuery } from "generated";

type UserRoleOption = {
  label: string;
  value: string;
};

type UserResult = NonNullable<NonNullable<NonNullable<FindListOfUsersQuery["findListOfUsers"]>["isUserDtoOut"]>["0"]>;

const LIMIT = 1000;

export type UseUsersFieldFilters = () => Pick<FindListOfUsersQueryVariables, "isUserFilterDtoIn">;
type GetOptionValue = (value: UserResult) => string;

const getUseConfig: (getOptionValue?: GetOptionValue, useFilters?: UseUsersFieldFilters) => SelectLiveFieldUseConfig = (
  getOptionValue,
  useFilters = () => ({}),
) =>
  function useConfig(previous) {
    const domainName = (useFieldData("domainName", "values") || "") as string;
    const [users = previous, { loading }] = useMappedQuery(
      (d) => {
        const options: UserRoleOption[] =
          d?.findListOfUsers?.isUserDtoOut
            ?.flatMap((r) => (r?.userName ? [{ label: r.userName, value: getOptionValue?.(r) ?? r.userName }] : []))
            .sort(makeSorter("label")) || [];

        return {
          ...previous,
          isLoading: false,
          options,
        };
      },
      useFindListOfUsersQuery({
        variables: {
          domainName,
          limit: LIMIT,
          offset: 0,
          ...useFilters(),
        },
      }),
    );

    return [users, !loading];
  };

export const getUsersField = (
  value: SelectLiveFieldValueArg,
  getOptionValue?: GetOptionValue,
  useFilters?: UseUsersFieldFilters,
) =>
  getSelectLiveField(
    {
      label: "user.title_one",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    getUseConfig(getOptionValue, useFilters),
  );
