export const en = {
  administration: {
    title: "Administration",
    overview: "Administration overview",
    description:
      "Perform administrative tasks, such as managing users, changing your pin code, accessing the operator view, and editing work units",
    allocations: "Allocations",
    allocationsDescription: "Associate a till to a work unit, such as a cash register",
    machines: "Machines",
    machinesDescription: "View, manage, and create machines",
    locations: "Sites",
    locationsDescription: "View, manage, and create sites",
    roles: "Roles",
    rolesDescription: "View, manage, and create roles",
    permissions: "Access",
    permissionsDescription: "View, manage, and create access levels.",
    dispenseLimits: "Dispense limits",
    dispenseLimitsDescription: "Limit the amount of money that can be dispensed from a machine",
    posTransactions: "POS transactions",
    posTransactionsDescription:
      "The point of sale (POS) or point of purchase (POP) is the time and place where a retail transaction is completed",
    configTemplates: "Config templates",
    configTemplatesDescription:
      "Export packages from Connect on-Device™ Studio to your computer file system, and import them as configuration templates to Connect",
    countries: "Countries",
    country: "Country",
    selectCountries: "Select countries",
    cities: "Cities",
    city: "City",
    selectCities: "Select cities",
    mg: {
      title: "Machine groups",
      name: "Name",
      description: "View, manage, and create smart machine groups",
      create: "Create machine group",
      createDescription: "Create a machine group to gather machines into an logical entity",
      update: "Update machine group",
      updateDescription: "You update a machine group when you want to update an existing smart machine group",
      delete: "Delete machine group",
      deleteDescription: "You delete a machine group when you want to delete an existing smart machine group",
      deleteClose: "Close",
      nodeChangeDate: "Changed",
      nodeStatus: "Status",
      nodeStatusCount: "Status count",
      machines: "Machines",
      availableMachines: "Available machines",
      nodeId: "Node ID",
      machineNodeIds: "Machine node IDs",
      machineNodeId: "Machine node ID",
      success: "Machine group created successfully",
      error: "Machine group creation failed",
      updateSuccess: "Machine group updated successfully",
      updateApiError: "Machine group update failed",
      deleteSuccess: "Machine group deleted successfully",
      deleteError: "Machine group deletion failed",
      goBack: "Back",
      namesCount: "Names count",
      multipleAdd: "Add multiple machines",
      multipleAddToGroup: "Add multiple machines to {{name}} group",
      multipleAddPrompt: "paste list of machine uuids",
      multipleAdd_one: "{{count}} machine is scheduled to add",
      multipleAdd_other: "{{count}} machines are scheduled to add",
    },
    lg: {
      title_one: "Site group",
      title_other: "Site groups",
      desc: "Overview of site groups - you can view, create, update and delete site groups.",
      create: "Create site group",
      createDescription: "Create a site group to manage related sites",
      update: "Update site group",
      updateDescription: "Update an existing site group",
      delete: "Delete site group",
      deleteDescription: "Site group will be deleted. Do you want to proceed?",
      deleteClose: "Close",
      name: "Site group name",
      siteGroupId: "Site group ID",
      sites: "Sites",
      includedSites: "Included sites",
      nodeStatus: "Status",
      nodeStatusCount: "Status count",
      nodeChangeDate: "Changed",
      success: "Site group created successfully",
      error: "Site group creation failed",
      updateSuccess: "Site group updated successfully",
      updateApiError: "Site group update failed",
      deleteSuccess: "Site group deleted successfully",
      deleteError: "Site group deletion failed",
      goBack: "Back",
      list: {
        noData: "No site groups found",
      },
      namesCount: "Names count",
      authorizedUsers: "Authorized users",
      count: "Count",
      noCountriesFound: "No countries found",
      noCitiesFound: "No cities found",
    },
    la: {
      title: "Lane allocations",
      description:
        "Provides a way to block work units to dispense more than one MoneyMix at a time across a selection of machines",
      allocationId: "Allocation ID",
      workUnit: "Work unit",
      allocationTime: "Allocation time",
      expiration: "Expiration",
      deactivate: "Deactivate",
      deactivateDescription: "deactivate lane allocation?",
      deactivateSuccess: "Lane allocation successfully deactivated",
      deactivateError: "Failed to deactivate Lane allocation",
    },
    sp: {
      title: "Software packages",
      selectSoftwarePackage: "Select software package",
      desc: "Overview of the software packages available in Connect and to start the create software package wizard from which you upload new software packages",
      name: "Name",
      placeholder: "Enter software package name",
      tooltip:
        "Use name to give the software package .zip file a user-friendly name. The name can have maximum 30 characters including blank spaces.",
      info: "Info",
      version: "Version",
      revision: "Revision",
      file: "File",
      release: "Release",
      createdBy: "Created by",
      createdDate: "Created date",
      description: "Description",
      releaseInfo: "Release information",
      official: "Make this software package official",
      custom: "Custom",
      label: "Label",
      fileRequired: "Must provide a file",
      addSp: "Create software package",
      submit: "Save and upload",
      update: "Update package",
      import: "Select file",
      changeFile: "Change file",
      uploadSuccess: "Successfully uploaded the software package",
      uploadFailure: "An error occurred when uploading the file",
      addSuccess: "Software package created successfully",
      addApiError: "Software package creation failed",
      err: {
        _1: "Unknown error. Please try again later.",
        _2: "Must be a zip file.",
        _3: "Selected file does not appear to be a software package.",
        _4: "Missing or invalid information in the selected file.",
      },
      missingPreSignedUrl: "Could not obtain presigned url",
      delete: "Delete software package",
      deleteDescription: "Both the software package template and the uploaded file will be deleted",
      deleteError: "Failed to delete software package",
      deleteSuccess: "Successfully deleted software package",
      deleteClose: "Close",
    },
    rc: {
      title: "Configuration templates",
      selectTemplate: "Select template",
      desc: "Overview of configuration templates. You can view, create and upload, and update configuration templates.",
      name: "Name",
      info: "Info",
      version: "Version",
      createdBy: "Created by",
      createdDate: "Created date",
      description: "Description",
      file: "File",
      fileRequired: "Must provide a file",
      configurationFile: "Configuration file",
      addRc: "Create configuration template",
      submit: "Save and upload",
      update: "Update template",
      import: "Import file",
      changeFile: "Change file",
      included: "Included",
      addSuccess: "Configuration template created successfully",
      addApiError: "Configuration template creation failed",
      saveTameplateFailure: "An error occurred when saving the template",
      uploadSuccess: "Successfully uploaded the configuration file",
      uploadFailure: "An error occurred when uploading the file",
      missingPreSignedUrl: "Could not obtain presigned url",
      updateSuccess: "Configuration template updated successfully",
      updateError: "An error occurred when updating configuration template",
      configuration: "Configuration",
      delete: "Delete configuration template",
      deleteDescription: "Both the configuration template and the uploaded file will be deleted",
      deleteError: "Failed to delete configuration template",
      deleteSuccess: "Successfully deleted configuration template",
      deleteClose: "Close",
      receipts: "Receipts",
      i18n: "i18n",
      themes: "Themes",
      sdk: "Sdk",
      scripts: "Scripts",
      help: "Help",
      err: {
        _1: "Unknown error. Please try again later.",
        _2: "Must be a zip file.",
        _3: "Selected file does not appear to be a configuration template file.",
        _4: "Missing or invalid information in the selected file.",
      },
    },
  },
  machineUserGroup: {
    title_one: "Machine user group",
    title_other: "Machine user groups",
    desc: "Provide a dynamic way to manage users, based on access and functionality",
    name: "Name",
    authorizedMachines: "Authorized machines",
    deleteGroup: "Delete group",
    deleteDesc:
      "Group <0>{{name}}</0> will be deleted, this action cannot be undone.<br/><br/><0>All users in the group will be disconnected from the machines in the group.</0>",
    deleteSuccess: "Machine user group deleted successfully",
    deleteError: "Machine user group deletion failed",
    addMachineUserGroups: "Create machine user group",
    addSuccess: "Machine user group created successfully",
    addError: "Machine user group creation failed",
    updateSuccess: "Machine user group updated successfully",
    updateError: "Machine user group update failed",
    create: "Create",
    update: "Update",
    updateName: "Update group name",
    addMachineUsers: "Add machine users",
    removeMachineUsers: "Remove machine users",
    addMachines: "Add machines",
    removeMachines: "Remove machines",
    showMachineExclusive: "Show machine exclusive groups",
  },
};
