import create from "zustand";
import { persist } from "zustand/middleware";

import { is12HourTimeFormat } from "common/helpers";

const formats = {
  localDate: "PP",
  // date-fns PPp but with custom time format
  localDateTime_24h: "PP',' HH:mm",
  localDateTime_12h: "PP',' h:mm a",
  // date-fns PPpp but with custom time format
  localDateTimeWithSeconds_24h: "PP',' HH:mm:ss",
  localDateTimeWithSeconds_12h: "PP',' h:mm:ss a",
};

type DateFormatsStore = {
  hour12: boolean;
  localDate: string;
  localDateTime: string;
  localDateTimeWithSeconds: string;
};

const getFormatStrings = (hour12: boolean): DateFormatsStore => ({
  hour12,
  localDate: formats.localDate,
  localDateTime: hour12 ? formats.localDateTime_12h : formats.localDateTime_24h,
  localDateTimeWithSeconds: hour12 ? formats.localDateTimeWithSeconds_12h : formats.localDateTimeWithSeconds_24h,
});

export const useDateFormatsStore = create(
  persist<DateFormatsStore>(() => getFormatStrings(is12HourTimeFormat()), {
    name: "timeFormats",
    partialize: (s) => getFormatStrings(s.hour12 ?? is12HourTimeFormat()),
  }),
);

export const dateFormatsStoreActions = {
  updateFormatStringByHourFormat: (hour12: boolean) => useDateFormatsStore.setState(getFormatStrings(hour12)),
};
