export const de = {
  machine: {
    machine_one: "Maschine",
    machine_other: "Maschinen",
    machineStatus: "Maschinen Status",
    deviceInfo: "Gerätedetails",
    sensorInfo: "Sensor-Details",
    name: "Name",
    noName: "Unbenannte Maschine",
    contents: "Inhalt",
    machineContents: "Maschineninhalt",
    machineContentsDescription: "Zeigt Informationen zum Inhalt der Maschine an",
    usage: "Verwendung",
    machineUsage: "Einsatz der Maschine",
    machineUsageDescription:
      "Zeigt Informationen über den Wert der Einzahlungs- und Auszahlungstransaktionen am Automaten an.",
    health: "Zustand",
    machineHealth: "Zustand der Maschine",
    machineHealthDescription:
      "Zeigt die letzten 10 aktiven Fehler auf dem Gerät an, d. h. die Fehler, die noch nicht gelöscht wurden.",
    information: "Information",
    extendedInfo: "Erweiterte Informationen",
    errorCode: "Error code",
    cleared: "Gelöscht",
    refillStatus: "Nachfüllstatus",
    refillStatusTooltip:
      "'$t(machine.refillStatuses.REFILL)' if any recycler denomination drops below the configured minimum amount. '$t(machine.refillStatuses.APPROACHING)' if any denomination drops below the configured default level. '$t(machine.refillStatuses.OK)' if there is no action required",
    machineAlias: "Maschinenname",
    machineSn: "Maschinen S/N:",
    machineName: "Maschinenname",
    machineType_one: "Maschinentyp",
    machineType_other: "Maschinentypen",
    machinesCount: "Maschinen zählung",
    nodeStatus: "Status",
    nodeChangeDate: "Änderungsdatum der Node",
    changed: "Geändert",
    createMachine: "Maschine erstellen",
    createMachineDescription: "Sie erstellen eine Maschine, wenn Sie ein neues Gerät für Connect",
    createMachineSuccess: "Maschine erfolgreich erstellt",
    createMachineError: "Maschinenerstellung fehlgeschlagen",
    editMachine: "Maschine bearbeiten",
    updateMachine: "Maschine aktualisieren",
    editMachineDescription: "Bitte ändern Sie die Maschinendaten, um sie zu bearbeiten",
    editMachineSuccess: "Maschine erfolgreich gespeichert",
    editMachineError: "Maschinenaktualisierung fehlgeschlagen",
    delete: "Löschen",
    deleteMachine: "Maschine löschen",
    deleteDescription: "Maschine <0>{{name}}</0> wird permanent gelöscht.",
    deleteSuccess: "Maschine erfolgreich gelöscht",
    deleteError: "Löschen der Maschine fehlgeschlagen",
    deleteClose: "Schließen",
    nodeId: "Node ID",
    dateTime: "Datum/Uhrzeit der Maschine",
    version: "Version",
    receiver: "Empfänger",
    sequence: "Sequenz",
    messageSequence: "Sequenz der Nachricht",
    messageType: "Nachrichtenart",
    uuid: "Maschinen UUID",
    comment: "Kommentar",
    deviceId: "Gerät",
    deleted: "Gelöscht",
    detached: "Freistehend",
    inactive: "Inaktiv",
    active: "Aktiv",
    unknown: "Unbekannt",
    online: "Online",
    offline: "Offline",
    refill: "Nachfüllen",
    ok: "OK",
    goBack: "Zurück",
    approaching: "Annäherung an",
    notAvailable: "N/A",
    kind: "Art",
    resolved: "Aufgelöst",
    device: "Gerät",
    filterMachines: "Filter Maschinen",
    preview: "Vorschau",
    severity: "Schweregrad",
    severityType: {
      Error: "Error",
      Warning: "Warnung",
    },
    status: {
      CatchingUp: "Aufholen",
      Offline: "Offline",
      Online: "Online",
      Unknown: "Unbekannt",
    },
    availableForDispense: "Für die Ausgabe verfügbar",
    availableForDispenseTooltip: "Zeigt die Menge an, die aus dem Automaten ausgegeben werden kann",
    notDispensable: "Nicht entbehrlich",
    notDispensableTooltip: "Zeigt den bankfähigen Betrag auf dem Automaten an",
    availabilityRatio: "Verfügbarkeitsquote der Abgabe",
    availabilityRatioTooltip:
      "Das Verhältnis zwischen verfügbarem Bargeld und Gesamtbargeld für die ausgewählte Währung",
    openShifts: "Offene Schichten",
    cashInCirculation: "Bargeldumlauf",
    netCashChange: "Veränderung der Nettoliquidität",
    availableCashChange: "Veränderung der verfügbaren Barmittel",
    notes: "Noten",
    notesTooltip: "Wert aller derzeit im Gerät befindlichen Banknoten für die ausgewählte Währung",
    counterfeitNotes: "Gefälschte Banknoten",
    counterfeitNotesTooltip: "Wert aller Banknoten, die für die ausgewählte Währung als gefälscht erkannt wurden",
    checks: "Schecks",
    checksTooltip: "Gesamtwert aller Schecks für die ausgewählte Währung",
    coins: "Münzen",
    coinsTooltip: "Der Wert aller derzeit im Gerät befindlichen Münzen für die ausgewählte Währung",
    suspectNotes: "Verdächtige Notizen",
    suspectNotesTooltip:
      "Wert aller Banknoten, die als verdächtig erkannt wurden oder den konfigurierten Fitness-Level nicht erreicht haben, für die ausgewählte Währung",
    vouchers: "Belege",
    vouchersTooltip: "Gesamtwert aller Belege für die ausgewählte Währung",
    claimedValue: "Beanspruchter Wert",
    claimedValueTooltip:
      "Wert der manuell eingegebenen Artikel für die gewählte Währung (in der Regel in Dropbox oder Tasche)",
    timelineExplorer: "Zeitleisten-Explorer",
    timelineExplorerDescription:
      "Sortiert die Transaktionen (die letzten 1.000 Transaktionen werden angezeigt) nach Transaktionsdatum",
    noData: "Keine Maschinen gefunden",
    specName: "Name",
    licenseLabel: "Lizenzkennzeichen",
    licenseLevel: "Lizenzstufe",
    noErrorsFound: "Keine Fehler für diese Maschine gefunden",
    showBy: "Zeigen von",
    type: "Type",
    aboveDefault: "Über den Standardwerten",
    aboveMinimum: "Über dem Minimum, aber unter dem Standard",
    belowMinimum: "Unterhalb des Mindestniveaus",
    totalDeposits: "Einlagen gesamt",
    totalDepositsTooltip: "Gesamtwert aller Einzahlungen seit Beginn der Tageszeit.",
    totalDispenses: "Ausgaben insgesamt",
    totalDispensesTooltip: "Gesamtwert aller Abgaben seit Beginn der Tageszeit.",
    changeNetCash: "Veränderung der Nettoliquidität",
    changeNetCashTooltip:
      "Differenz zwischen dem zu Beginn des Tages zur Ausgabe verfügbaren Bargeld und der kumulierten Summe aller Bareinzahlungen.  Ein positiver Wert bedeutet, dass Sie Bargeld erzeugen, ein negativer Wert bedeutet, dass Sie Bargeld verbrauchen.",
    changeAvailableCash: "Veränderung der verfügbaren Barmittel",
    changeAvailableCashTooltip:
      "Differenz zwischen dem verfügbaren Bargeld zu Beginn des Tages und dem aktuellen verfügbaren Bargeld.",
    usageSinceMidnight: "Nutzung seit heute um 00:00 Uhr anzeigen",
    devInfoMissing: "Es sind keine Maschinen angeschlossen",
    info: {
      deviceId: "Geräte-ID",
      desc: "Zeigt verschiedene Informationen über das Gerät an. Zum Beispiel den Gerätetyp und das Aktivierungsdatum",
      devInfo: "Informationen zur Maschine",
      devInfoMissing: "Es sind keine Geräte angeschlossen",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Zugangstyp",
      protocol: "Protokol",
      name: "Name",
      nodeId: "Geräte-Info-ID",
      serialNumber: "Seriennummer",
      version: "Version",
      capabilities: "Fähigkeiten",
    },
    licensing: {
      title: "Lizenzierung: (optional)",
      title_singular: "Lizenz",
      currentLicense: "Aktuelle Lizenz",
      noLicenseId: "Um die Lizenz zu aktualisieren, muss der Rechner online sein.",
      keepBasic: "Basislizenz behalten",
      upgrade: "Upgrade-Lizenz",
      option: "Option",
      discountCode: "Rabattcode (optional)",
      costAcknowledgement: "Ich nehme zur Kenntnis, dass dieses Lizenz-Upgrade mit Kosten verbunden sein kann.",
      agreementNeeded: "Einverständnis ist erforderlich.",
      currentlyActive: "Derzeit aktiv",
      currentlyInactive: "Derzeit inaktiv",
      licenseKey: "Lizenzschlüssel",
      active: "Aktiv",
      unclickToActivate: "Lizenzabonnement ist aktiv, zum Deaktivieren nochmal anklicken.",
      clickToActivate: "Das Lizenzabonnement ist derzeit deaktiviert, klicken Sie nochmal zum Aktivieren.",
      edition: {
        basic: "Basislizenz",
        enterpriseSubscription: "Enterprise-Abonnement",
        unknown: "Unknown",
      },
    },
    sensor: {
      deviceId: "Geräte-ID",
      desc: "Zeigt verschiedene Informationen über das Gerät an. Zum Beispiel den Gerätetyp und das Aktivierungsdatum",
      devInfo: "Informationen zur Maschine",
      devInfoMissing: "Es gibt keine angeschlossenen Geräte",
      firmware: "Firmware",
      hardware: "Hardware",
      accType: "Zugangstyp",
      protocol: "Protokol",
      name: "Name",
      nodeId: "Sensor info ID",
      serialNumber: "Seriennummer",
      version: "Version",
      capabilities: "Fähigkeiten",
    },
    refillStatuses: {
      APPROACHING: "Annäherung an",
      OK: "OK",
      REFILL: "Nachfüllen",
    },
    remoteOperationMgmt: {
      deployedConfiguration: "Bereitgestellte Konfiguration",
      configuration: "Konfiguration",
      software: "Software",
      version: "Version",
      installing: "Installation von",
      status: "Status",
      cancel: "Abbrechen",
      unknown: "Unbekannt",
      statuses: {
        CANCELED: "Abgebrochen",
        CANCELING: "Abbrechen",
        DEPLOYING: "Gesendet",
        DONE: "Erledigt",
        FAILEd: "Gescheitert",
        INSTALLING: "Installiert",
        PENDING: "Ausstehend",
        SENT: "Gesendet",
        TIMEOUT: "Zeitüberschreitung",
        WAITING: "Bitte Warten",
        UNKNOWN: "Unbekannt",
        undefined: "Unbekannt",
      },
    },
  },
};
