import { getPath } from "./getPath";

export const getDeep = (object: any, _path: string[] | string) => {
  const path = typeof _path === "string" ? getPath(_path) : _path;
  const length = path.length;
  let index = 0;

  while (object && index < length) {
    object = object[path[index++]];
  }

  return index && index === length ? object : undefined;
};
