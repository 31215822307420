import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const MachineUserBalancing = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="mum.balancing.title" subtitle="mum.balancing.desc" />
);
