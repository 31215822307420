import { useMemo } from "react";

import { navigateTo, useParams } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindMachineByNodeIdQuery,
  GetMachineLicenseContentDocument,
  GetMachineLicenseContentQuery,
  useCreateMachineLicenseContentMutation,
  useFindMachineByNodeIdQuery,
  useGetMachineLicenseContentQuery,
} from "generated";
import { useTenant } from "tenant/context";

import { MachineLicenseContentForm, MachineLicenseFormContentData } from "../../components/MachineLicenseContentForm";

import { breadcrumbs } from "./breadcrumbs";

export type MachineLicenseUpdateParams = { nodeId: string };

type InitialValues = MachineLicenseFormContentData | undefined;

const getInitialValues = (
  { findMachineByNodeId: machine }: FindMachineByNodeIdQuery | undefined = {},
  { getMachineLicenseContent: license }: GetMachineLicenseContentQuery | undefined = {},
): InitialValues =>
  machine
    ? {
        uuid: machine?.uuid as string,
        features: license?.features?.flatMap((feature) =>
          feature
            ? {
                key: feature.key ?? "",
                disableFeatureWhenExpired: feature?.expiration?.disableFeatureWhenExpired ?? false,
                warningBeforeExpirationDays: feature?.expiration?.warningBeforeExpirationDays ?? 0,
              }
            : [],
        ),
      }
    : undefined;

export const MachineEditLicenseContent = () => {
  const { nodeId = "" } = useParams<MachineLicenseUpdateParams>();
  const tenantId = useTenant(useTenant.actions.getTenantId) || "";
  const [createMachineLicense] = useCreateMachineLicenseContentMutation();

  const machineResult = useFindMachineByNodeIdQuery({
    variables: { nodeId },
    skip: !nodeId,
    returnPartialData: true,
  });

  const machineUuid = machineResult.data?.findMachineByNodeId?.uuid ?? "";

  const licenseResult = useGetMachineLicenseContentQuery({
    variables: { machineUuid },
    skip: !machineUuid,
    returnPartialData: false,
  });

  const initialValues = useMemo(
    () => getInitialValues(machineResult?.data, licenseResult?.data),
    [machineResult?.data, licenseResult?.data],
  );

  const loading = machineResult.loading || licenseResult.loading;

  if (!usePermissionAccess("canMachineLicenseContentChange")) {
    navigateTo({ route: "MACHINE_LIST", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="machine.licensing.editMachineLicenseContent">
      {loading ? null : (
        <MachineLicenseContentForm
          submitLabel="machine.licensing.updateMachineLicenseContent"
          initial={initialValues}
          onSubmit={(data) =>
            createMachineLicense({
              variables: {
                tenantId,
                licenseContentDtoIn: data,
              },
              update: (cache) => {
                evictQuery(GetMachineLicenseContentDocument, cache);
              },
            })
              .then((res) => {
                if (!res.data?.createMachineLicenseContent?.nodeId) throw new Error();

                navigateTo({ route: "MACHINE_LIST" });
                useToast.actions.show("machine.licensing.machineLicenseContentUpdated", { variant: "success" });
              })
              .catch(() =>
                useToast.actions.show("machine.licensing.machineLicenseContentUpdateError", { variant: "error" }),
              )
          }
        />
      )}
    </PageLayout>
  );
};
