import create, { StoreApi, UseBoundStore } from "zustand";

import { client } from "client";
import { useToast } from "common/guideline";
import {
  FindMyTenantsMetadataDocument,
  FindMyTenantsMetadataQuery,
  FindMyTenantsMetadataQueryVariables,
  FindTenantByTenantIdDocument,
  FindTenantByTenantIdQuery,
  FindTenantByTenantIdQueryVariables,
  TenantDtoOut,
} from "generated";

import { STORAGE_KEY, getStorageTenantId } from "./getStorageTenantId";

type TenantId = TenantDtoOut["tenantId"];

export type TenantStore = {
  tenantId: TenantId | null;
  tenant: FindTenantByTenantIdQuery["findTenantByTenantId"];
  domainName: string | undefined;
  unsubTenantWatcher: () => void;
};

type TenantStoreActions = {
  getTenant: () => TenantStore["tenant"];
  getTenantId: () => TenantStore["tenantId"];
  setTenantId: (tenantId: TenantId) => Promise<void>;
};

export const useTenant = create(() => ({
  tenantId: getStorageTenantId(),
  tenant: null,
  domainName: undefined,
  unsubTenantWatcher: () => undefined,
})) as UseBoundStore<StoreApi<TenantStore>> & { actions: TenantStoreActions };

useTenant.actions = {
  getTenant: (store = useTenant.getState()) => store.tenant,
  getTenantId: (store = useTenant.getState()) => store.tenantId,
  setTenantId: async (tenantId) => {
    const previousState = useTenant.getState();
    useTenant.setState({ tenantId, tenant: !tenantId ? null : previousState.tenant });

    if (!tenantId) {
      previousState.unsubTenantWatcher();
      window.localStorage.removeItem(STORAGE_KEY);
      return;
    }

    const onFetchError = () => {
      useTenant.setState(previousState);
      window.localStorage.setItem(STORAGE_KEY, previousState.tenantId || "");
      useToast.actions.show("tenant.activeFetchErr", { variant: "error" });
    };

    try {
      window.localStorage.setItem(STORAGE_KEY, tenantId);
      const { data } = await client.query<FindTenantByTenantIdQuery, FindTenantByTenantIdQueryVariables>({
        query: FindTenantByTenantIdDocument,
        variables: { tenantId },
      });
      const { data: metadata } = await client.query<FindMyTenantsMetadataQuery, FindMyTenantsMetadataQueryVariables>({
        query: FindMyTenantsMetadataDocument,
      });

      if (data.findTenantByTenantId) {
        previousState.unsubTenantWatcher();
        window.localStorage.setItem(STORAGE_KEY, tenantId);
        await client.resetStore();
        const unsubTenantWatcher = client.cache.watch<FindTenantByTenantIdQuery, FindTenantByTenantIdQueryVariables>({
          query: FindTenantByTenantIdDocument,
          variables: { tenantId },
          optimistic: false,
          callback: ({ complete, result }) =>
            complete && result?.findTenantByTenantId && useTenant.setState({ tenant: result?.findTenantByTenantId }),
        });

        const tenantMetadata = metadata.findMyTenantsMetadata?.find((t) => t?.tenantId === tenantId);
        useTenant.setState({
          tenant: data.findTenantByTenantId,
          domainName: tenantMetadata?.domainName ?? undefined,
          unsubTenantWatcher,
        });
      } else {
        onFetchError();
      }
    } catch (error) {
      onFetchError();
    }
  },
};
