import { format, parse } from "date-fns";
import { TFunction } from "i18next";

import { TIME_FORMAT, numberWithDecimalCount } from "common/helpers";
import {
  CashAmountFilter,
  CountOperationFilter,
  DurationFilter,
  GetAllRulesQuery,
  MessageFieldFiltering,
  NoParamFilter,
  RatioFilter,
  TimeFilter,
} from "generated";

type Filters = NonNullable<NonNullable<NonNullable<GetAllRulesQuery["getAllRules"]>[0]>["filters"]>;

type FormatResult = [string, string, ("machines" | "locations")?];

const MESSAGE_FILTER_SEPARATOR = ", ";
export const messageFilter = {
  join: (filter: MessageFieldFiltering["filter"]) => filter?.join(MESSAGE_FILTER_SEPARATOR) || "",
  split: (filter: string) => filter.split(MESSAGE_FILTER_SEPARATOR),
};

export const findTriggeringEvent = (trigger: Filters) => {
  if ("messageType" in trigger) return trigger.messageType;

  let currentlyProcessing = trigger;

  while (currentlyProcessing.child) {
    if ("messageType" in currentlyProcessing.child) return currentlyProcessing.child.messageType;
    if (
      "otherChild" in currentlyProcessing &&
      currentlyProcessing.otherChild &&
      "messageType" in currentlyProcessing.otherChild
    )
      return currentlyProcessing.otherChild.messageType;

    currentlyProcessing = currentlyProcessing.child as Filters;
  }
};

const getFilterType = (t, filterType) => t("alerts.filterType", { returnObjects: true })[filterType];

const formatByTypename = {
  MessageFieldFiltering: (filter: MessageFieldFiltering, t: TFunction): FormatResult => [
    getFilterType(t, filter.filterType),
    messageFilter.join(filter.filter),
    filter.filterType === "MACHINE_UUID_FILTER"
      ? "machines"
      : filter.filterType === "LOCATION_FILTER"
      ? "locations"
      : undefined,
  ],
  CountOperationFilter: (filter: CountOperationFilter, t: TFunction): [string, number] => [
    getFilterType(t, filter.filterType),
    filter.countValue ?? 0,
  ],
  DurationFilter: (filter: DurationFilter, t: TFunction): [string, string] => [
    getFilterType(t, filter.filterType),
    filter.duration ?? "",
  ],
  NoParamFilter: (filter: NoParamFilter, t: TFunction): [string, string] => [
    getFilterType(t, filter.filterType),
    t("alerts.filtered"),
  ],
  RatioFilter: (filter: RatioFilter, t: TFunction): [string, string] => [
    getFilterType(t, filter.filterType),
    t("numberFormat", {
      value: (filter.ratio ?? 0) / 100,
      style: "percent",
    }),
  ],
  TimeFilter: (filter: TimeFilter, t: TFunction): [string, string] => [
    getFilterType(t, filter.filterType),
    t("alerts.timeBetween", {
      from: filter.fromTime ? format(parse(filter.fromTime, TIME_FORMAT, new Date()), "p") : "",
      to: filter.toTime ? format(parse(filter.toTime, TIME_FORMAT, new Date()), "p") : "",
    }),
  ],
  CashAmountFilter: (filter: CashAmountFilter, t: TFunction): [string, string] => {
    const totalAmount = filter.cashAmountIn?.length ? filter.cashAmountIn[0] : null;
    const amount = totalAmount?.amount || 0;
    const decimals = totalAmount?.decimals || 0;
    const currency = totalAmount?.currency || "";

    const displayAmount = t("numberFormat", {
      value: numberWithDecimalCount.merge(amount || 0, decimals || 0),
      currency,
      minimumFractionDigits: 2,
    });

    return [getFilterType(t, filter.filterType), displayAmount];
  },
};

export const getFilteringFormatted = (trigger: Filters, t: TFunction) => {
  const formatted: FormatResult[] = [];
  let currentlyProcessing = trigger;

  while (currentlyProcessing.child) {
    const child = currentlyProcessing.child;

    if (child.__typename) {
      const data = formatByTypename[child.__typename]?.(child, t);
      if (data) formatted.push(data);
    }

    const otherChild = ("otherChild" in currentlyProcessing ? currentlyProcessing.otherChild : undefined) as
      | Filters
      | undefined;

    if (otherChild?.__typename) {
      const data = formatByTypename[otherChild.__typename]?.(otherChild, t);
      if (data) formatted.push(data);
    }

    currentlyProcessing = child as Filters;
  }

  return formatted;
};
