import { createContext, useContext, useState } from "react";

const MockContext = createContext({} as any);

export const useMockContext = () => useContext(MockContext);

export const MockProvider = (props) => {
  const [siteGroup, setSiteGroup] = useState("");
  const [site, setSite] = useState("");
  const [machine, setMachine] = useState("");

  return (
    <MockContext.Provider
      value={{
        siteGroup,
        setSiteGroup,
        site,
        setSite,
        machine,
        setMachine,
      }}
    >
      {props.children}
    </MockContext.Provider>
  );
};
