import { Languages } from "i18next";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { ReactComponent as FlagDE } from "assets/img/flags/de.svg";
import { ReactComponent as FlagES } from "assets/img/flags/es.svg";
import { ReactComponent as FlagFR } from "assets/img/flags/fr.svg";
import { ReactComponent as FlagUS } from "assets/img/flags/us.svg";
import { useFlags } from "base/featureFlags";
import { Popover, Text } from "common/guideline";
import { useTranslation } from "i18n";

import { translations } from "./translations";

const Wrapper = styled.div`
  svg {
    ${tw`w-5 h-5 inline-flex m-1 opacity-70 transition-opacity duration-300 rounded-full`}
  }

  &:hover svg {
    ${tw`opacity-100`}
  }
`;

const flagByLanguage: Record<Languages, React.ReactNode> = {
  "de-DE": <FlagDE />,
  "en-US": <FlagUS />,
  "es-ES": <FlagES />,
  "fr-FR": <FlagFR />,
};

export const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const flags = useFlags();

  return (
    <Popover
      auto
      placement="top-center"
      possiblePlacements={["bottom-center"]}
      content={(close) => (
        <>
          {Object.keys(translations)
            .filter((t) => (flags.spanishLangEnabled ? true : t != "es-ES"))
            .map((l) => (
              <Wrapper
                key={l}
                onClick={() => {
                  i18n.changeLanguage(l);
                  close();
                }}
              >
                <div tw="flex items-center cursor-pointer">
                  {flagByLanguage[l]}{" "}
                  <Text variant="link" tw="m-1 text-xs">
                    {l.toUpperCase()}
                  </Text>
                </div>
              </Wrapper>
            ))}
        </>
      )}
    >
      <Wrapper>{flagByLanguage[i18n.language]}</Wrapper>
    </Popover>
  );
};
