import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { evictQuery, isDeepEqual } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import {
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindMachineUserByUserIdQuery,
  useFindMachineUserByUserIdQuery,
  useUpdateMachineUserMutation,
} from "generated";

import { MachineUserForm, MachineUserFormData } from "./MachineUserCreate/MachineUserForm";

export type MachineUserUpdateParams = { userId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "MACHINE_USERS",
    text: "mu.title_other",
  },
  {
    route: "MACHINE_USER_UPDATE",
    text: "mu.update",
  },
];

type InitialValues = MachineUserFormData | undefined;

const getInitialValues = ({
  findMachineUserByUserId: machineUser,
}: FindMachineUserByUserIdQuery | undefined = {}): InitialValues => {
  return machineUser
    ? {
        name: machineUser.name as string,
        userId: machineUser.userId as string,
        roleName: machineUser.roleName as string,
        nodeId: machineUser.nodeId as string,
        validFrom: machineUser.validFrom as string,
        validUntil: machineUser.validUntil as string,
        cardIdentifier: machineUser.cardIdentifier as string,
        nbrOfLogins: machineUser.nbrOfLogins as number,
        machineUserGroupNodeIds: machineUser.machineUserGroupNodeIds as string[],
        deactivated: machineUser.deactivated as boolean,
        noPin: machineUser.noPin as boolean,
        // !IMPORTANT dont fill pin, backend returns hashed pin, so we should not fill it here, otherwise it could be sended to the backend and double hashed
        pin: null,
        noPinCard: machineUser.noPinCard as boolean,
        noPinBio: machineUser.noPinBio as boolean,
        noPinDoor: machineUser.noPinDoor as boolean,
        noPinLogin: machineUser.noPinLogin as boolean,
        doors: machineUser.doors as string[],
        changePinOnLogin: machineUser.changePinOnLogin as boolean,
      }
    : undefined;
};

export const UpdateMachineUserScreen = () => {
  const { userId = "" } = useParams<MachineUserUpdateParams>();
  const [updateMachineUser] = useUpdateMachineUserMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindMachineUserByUserIdQuery({
      variables: { userId },
      skip: !userId,
      returnPartialData: true,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="mu.update" subtitle="mu.updateDescription">
      {loading ? null : (
        <MachineUserForm
          updateForm
          submitLabel="mu.update"
          initial={mappedData}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSubmit={({ confirmPin, unlimitedNoLogins, nodeId, ...input }) =>
            updateMachineUser({
              variables: { nodeId, input },
              ignoreResults: true,
              update: (cache) =>
                isDeepEqual(input.machineUserGroupNodeIds, mappedData?.machineUserGroupNodeIds)
                  ? null
                  : evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, {
                      getInnerOperationName: true,
                    }),
            })
              .then(() => {
                useToast.actions.show("mu.updateSuccess", { variant: "success" });
                navigateTo({ route: "MACHINE_USERS" });
              })
              .catch(() => useToast.actions.show("mu.updateApiError", { variant: "error" }))
          }
        />
      )}
    </PageLayout>
  );
};
