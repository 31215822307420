import { useMemo } from "react";

import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllLocationsByNodeIdsDocument,
  FindAllLocationsFilteredDocument,
  FindLatestCoDLicenseByMachineUuidQuery,
  FindMachineByNodeIdQuery,
  GenerateMachineViewTabDocument,
  GetMachinesOnlineStatusDocument,
  GetMachinesOnlineStatusHistoryDocument,
  PaymentIntervalType,
  useFindLatestCoDLicenseByMachineUuidQuery,
  useFindMachineByNodeIdQuery,
  useUpdateMachineMutation,
} from "generated";
import { manageLicenseSubscription } from "machine/helpers";

import { MachineForm, MachineFormData } from "../../components";

import { breadcrumbs } from "./breadcrumbs";

export type MachineUpdateParams = { nodeId: string };

type InitialValues = MachineFormData | undefined;

const isWhiteListedException = (error: string) => {
  return error.includes("with machineUuid") && error.includes("does not exist");
};

const getInitialValues = (
  { findMachineByNodeId: machine }: FindMachineByNodeIdQuery | undefined = {},
  { findLatestCoDLicenseByMachineUuid: license }: FindLatestCoDLicenseByMachineUuidQuery | undefined = {},
): InitialValues =>
  machine
    ? {
        name: machine?.name as string,
        uuid: machine?.uuid as string,
        machineTypeNodeId: machine?.machineType?.nodeId as string,
        locationNodeId: machine?.locationNodeId as string,
        // CoD License
        licenseNodeId: license?.nodeId as string,
        comment: license?.comment as string,
        edition: license?.edition as string,
        licenseKey: license?.licenseKey as string,
        paymentIntervalType:
          license?.paymentIntervalType === "NONE" ? "MONTHLY" : (license?.paymentIntervalType as string),
        subscriptionActive: license?.subscriptionActive as boolean,
        deactivatedDate: license?.deactivatedDate as Date,
        // TODO when backend will return this within machine - add here
        // machineUserGroupNodeIds: [],
      }
    : undefined;

export const MachineEdit = () => {
  const { nodeId = "" } = useParams<MachineUpdateParams>();
  const [updateMachine] = useUpdateMachineMutation();

  const machineResult = useFindMachineByNodeIdQuery({
    variables: { nodeId },
    skip: !nodeId,
    returnPartialData: true,
  });

  const machineUuid = machineResult.data?.findMachineByNodeId?.uuid ?? "";

  const licenseResult = useFindLatestCoDLicenseByMachineUuidQuery({
    variables: { machineUuid },
    skip: !machineUuid,
    returnPartialData: false,
  });

  const initialValues = useMemo(
    () => getInitialValues(machineResult?.data, licenseResult?.data),
    [machineResult?.data, licenseResult?.data],
  );

  const loading = machineResult.loading || licenseResult.loading;
  const machineError = machineResult.error;
  const licenseError = licenseResult.error;

  // Make an exception if Cod license is not found.
  if (machineError || (licenseError && !isWhiteListedException(licenseError.message))) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="machine.editMachine" subtitle="machine.editMachineDescription">
      {loading ? null : (
        <MachineForm
          submitLabel="machine.updateMachine"
          initial={initialValues}
          updateForm
          onSubmit={({
            // ---- Configuration & software installation -----
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            timeOption,
            remoteMgmtOption,
            templateNodeId,
            softwarePackageNodeId,
            scheduleInfo,
            createdBy,
            // ----- Licensing -----
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            licensingOption,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            acknowledgement,
            licenseNodeId,
            comment,
            subscriptionActive,
            paymentIntervalType,
            licenseKey,
            deactivatedDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            edition,
            ...input
          }) =>
            updateMachine({
              variables: { nodeId, input },
              ignoreResults: true,
              update: (cache) => {
                evictQuery(GenerateMachineViewTabDocument, cache);
                evictQuery(FindAllLocationsByNodeIdsDocument, cache);
                evictQuery(FindAllLocationsFilteredDocument, cache);
                evictQuery(GetMachinesOnlineStatusDocument, cache);
                evictQuery(GetMachinesOnlineStatusHistoryDocument, cache);
              },
            })
              .then(async (v) => {
                const machineNodeId = v.data?.updateMachine?.nodeId;
                if (!machineNodeId) throw new Error();
                useToast.actions.show("machine.createMachineSuccess", { variant: "success" });

                await performRemoteMgmtOperation(
                  remoteMgmtOption,
                  [input.uuid],
                  templateNodeId,
                  softwarePackageNodeId,
                  scheduleInfo,
                  createdBy,
                );

                await manageLicenseSubscription(
                  licensingOption,
                  licenseNodeId,
                  input.uuid,
                  paymentIntervalType as PaymentIntervalType,
                  subscriptionActive,
                  licenseKey,
                  comment,
                  null,
                  deactivatedDate,
                );

                navigateTo({ route: "MACHINE_LIST" });
              })
              .catch(() => useToast.actions.show("machine.editMachineError", { variant: "error" }))
          }
        />
      )}
    </PageLayout>
  );
};
