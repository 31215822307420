import { StyledComponent } from "styled-components";
import tw, { css, styled, theme } from "twin.macro";
import "styled-components/macro";

import { ReactComponent as XCircleSVG } from "assets/icons/XCircle.svg";

import { WithVariants, applyVariant } from "../helpers";

import { WrapperProps, useModalWillClose } from "./useModal";

const variants = {
  drawerBottom: css<any>`
    ${tw`bottom-0 left-0 right-0`}
    ${({ willClose }) => (willClose ? tw`animate-slideOutBottom` : tw`animate-slideInBottom`)}
  `,
  drawerLeft: css<any>`
    ${tw`top-0 bottom-0 left-0`}
    ${({ willClose }) => (willClose ? tw`animate-slideOutLeft` : tw`animate-slideInLeft`)}
  `,
  wizard: css<any>`
    ${tw`flex min-w-[80vw] min-h-[100vh] max-h-[100vh] items-center justify-center`}
    ${({ willClose }) => (willClose ? tw`animate-slideOutCenter` : tw`animate-slideInCenter`)}
  `,
  wizardDelete: css<any>`
    ${tw`flex p-16 items-center justify-center`}
    ${({ willClose }) => (willClose ? tw`animate-slideOutCenter` : tw`animate-slideInCenter`)}
  `,
  modal: css<any>`
    ${tw`top-1/2 left-1/2 px-3 py-4 rounded-lg`}
    ${({ willClose }) => (willClose ? tw`animate-slideOutCenter` : tw`animate-slideInCenter`)}
  `,
  sm: tw`w-96 max-w-[95%]`,
  md: tw`w-[42rem] max-w-[95%]`,
  lg: tw`w-[72rem] max-w-[95%] min-h-screen`,
  flex: tw`flex flex-col`,
};

type VariantType = WithVariants<typeof variants>;

type CommonProps = VariantType & { willClose: boolean };

export type ModalComponentProps = VariantType & {
  Modal?: StyledComponent<"div", any, CommonProps, never>;
  showCloseIcon?: boolean;
  willClose?: boolean;
};

export const ModalContent = styled.div<CommonProps>`
  ${tw`absolute bg-gray-1 overflow-auto cursor-default shadow-blue max-h-[90vh]`}
  ${applyVariant(variants, ["modal", "sm"])}
  animation-fill-mode: forwards;
`;

export const DrawerBottom = styled(ModalContent)`
  ${tw`rounded-xl p-4 bottom-2 left-2 right-2`}
`;

const StyledModal = styled.div<CommonProps>`
  ${tw`fixed inset-0 z-50`}
  ${({ onClick }) => onClick && tw`cursor-pointer`}
  ${({ willClose }) => (willClose ? tw`animate-fadeOut` : tw`animate-fadeIn`)}
  animation-fill-mode: forwards;
  background: rgba(${theme`colors.contrast.dark`}, 0.3);
  -webkit-tap-highlight-color: transparent;
`;

export const ModalComponent: React.FC<React.PropsWithChildren<WrapperProps<ModalComponentProps>>> = ({
  id,
  children,
  requestClose,
  backdropClose = true,
  showCloseIcon = true,
  Modal = ModalContent,
  ...rest
}) => {
  const willClose = useModalWillClose(id);

  return (
    <StyledModal
      willClose={willClose}
      variant={rest.variant}
      onClick={
        backdropClose
          ? (e) => {
              if (e.target === e.currentTarget) {
                requestClose();
              }
            }
          : undefined
      }
    >
      <Modal {...rest} willClose={willClose}>
        {showCloseIcon && (
          <XCircleSVG tw="absolute right-4 top-4 cursor-pointer" width={24} height={24} onClick={requestClose as any} />
        )}
        {children}
      </Modal>
    </StyledModal>
  );
};
