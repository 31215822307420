import { RouteConfig, RouteValue } from "routing";

import { OTACode, OTACodeRouteParams, OTACreate, OTAList } from "./pages";

export type OTAPaths = {
  OTA_CODE: RouteValue<{ params: OTACodeRouteParams }>;
  OTA_LIST: RouteValue;
  OTA_CREATE: RouteValue;
};

export const OTA: RouteConfig<OTAPaths> = {
  OTA_CODE: {
    path: "/ota/:machineUuid",
    Component: OTACode,
  },
  OTA_LIST: {
    path: "/ota-list",
    Component: OTAList,
  },
  OTA_CREATE: {
    path: "/ota-create",
    Component: OTACreate,
  },
};
