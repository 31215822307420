import { useEffect, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";
import { MockProvider } from "personalized/pages/Insights/MockContext";
import { useTenant } from "tenant/context";

import { Balancing } from "../../../components/Box/Balancing";
import { Capacity } from "../../../components/Box/Capacity";
import { CashInCirculation } from "../../../components/Box/CashInCirculation";
import { CashInventory } from "../../../components/Box/CashInventory";
import { ConnectionStatus } from "../../../components/Box/ConnectionStatus";
import { NetCash } from "../../../components/Box/NetCash";
import { ShiftStatus } from "../../../components/Box/ShiftStatus";

import { breadcrumbs } from "./breadcrumbs";

const InsightsRaw = () => {
  const [showBalancing, setShowBalancing] = useState(true);
  const tenantId = useTenant(useTenant.actions.getTenantId);

  // mocked - on tenant change - replace insights panels
  useEffect(() => {
    setShowBalancing((p) => !p);
  }, [tenantId]);

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="common.insights" withPicker>
      <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
        <CashInventory />
        <NetCash />
        {showBalancing ? <Balancing /> : <Capacity />}
        <ShiftStatus />
        <CashInCirculation />
        <ConnectionStatus />
      </div>
    </PageLayout>
  );
};

export const Insights = () => (
  <MockProvider>
    <InsightsRaw />
  </MockProvider>
);
