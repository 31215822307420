import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "LOCATIONS",
    text: "location.location_other",
  },
  {
    route: "LOCATION_CREATE",
    text: "location.create",
  },
];
