import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllLocationsFilteredDocument,
  GenerateMachineViewTabDocument,
  GetReportHeaderDocument,
  useDeleteLocationMutation,
} from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const DeleteLocation: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteLocation, { loading }] = useDeleteLocationMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "location.deleteSuccess" : "location.deleteError", {
        variant: error ? "success" : "error",
      });

    try {
      const result = await deleteLocation({
        variables: {
          input: nodeId,
        },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(FindAllLocationsFilteredDocument, cache);
          evictQuery(GetReportHeaderDocument, cache);
          evictQuery(GenerateMachineViewTabDocument, cache);
        },
      });
      if (result.data?.deleteLocation) onClose?.();
      showToast(!!result.data?.deleteLocation);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="location.delete" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="location.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
