import { TKeys } from "i18next";

import { CustomRenderFields } from "common/form/renderFields";
import { ErrorsReportCriteriaDtoIn, MessageDateTime } from "generated";
import { getMachinesTransferBoxField } from "machine/components";

export const machineErrorsReportDefaultParams: Partial<ErrorsReportCriteriaDtoIn> = {
  reportName: "MachineErrorsReport",
  queryBy: "DATE_TIME",
};

const messageDateTimeOptions: Array<{ value: MessageDateTime; label: TKeys }> = [
  {
    value: "DATE_TIME",
    label: "report.sr.dateTime",
  },
  {
    value: "RECEIVED_DATE_TIME",
    label: "report.sr.receivedDateTime",
  },
];

// const getDateFormatters = (formatString = "yyyy-MM-dd") => ({
//   parse: (v) => (v ? format(v, formatString) : v),
//   format: (v) => (v ? parse(v, formatString, new Date()) : v),
// });

// siteGroupIds,
// machineNodeIds,
// locationNodeIds,
// errorCodeFilter,
// errorKindFilter,
// errorSeverityFilter,

export const machineErrorsReportFields: CustomRenderFields[] = [
  // {
  //   type: "date",
  //   dateFormat: "date",
  //   label: "report.sr.referenceDate",
  //   name: "inputPayload.fromDate",
  //   ...getDateFormatters(),
  // },
  // {
  //   type: "date",
  //   dateFormat: "date",
  //   label: "report.sr.toDate",
  //   name: "inputPayload.toDate",
  //   ...getDateFormatters(),
  // },
  {
    type: "select",
    name: "inputPayload.queryBy",
    label: "report.sr.messageDateTime",
    options: messageDateTimeOptions,
  },
  getMachinesTransferBoxField({ name: "inputPayload.machineNodeIds" }),
];
