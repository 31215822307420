import { isValid, parse } from "date-fns";

import { DURATION_FORMAT, TIME_FORMAT, numberWithDecimalCount, omitTypenames } from "common/helpers";
import {
  BiNodeFilter,
  CashAmountFilter,
  CountOperationFilter,
  DurationFilter,
  MessageFieldFiltering,
  NoParamFilter,
  OperationType,
  RatioFilter,
  TimeFilter,
} from "generated";

export type Filters =
  | CashAmountFilter
  | CountOperationFilter
  | DurationFilter
  | MessageFieldFiltering
  | NoParamFilter
  | RatioFilter
  | TimeFilter;

const getFilterData = <T>(filter: Filters, data?: T) => ({
  __typename: filter.__typename,
  filterType: filter.filterType,
  operationType: filter.operationType,
  ...data,
});

const getFilterByOperationType: Omit<
  Record<OperationType, (filter: Filters, value: any) => Filters | null>,
  "BI_NODE" | "LIST_FILTER" | "MESSAGE_TYPE_FILTER" | "NONE" | "WEBHOOK"
> = {
  COUNT: (filter, value) => (value === "" || isNaN(value) ? null : getFilterData(filter, { countValue: value })),
  DURATION: (filter, value) =>
    !value || !isValid(parse(value, DURATION_FORMAT, new Date())) ? null : getFilterData(filter, { duration: value }),
  RATIO: (filter, value) => (value === "" || isNaN(value) ? null : getFilterData(filter, { ratio: value })),
  NO_PARAM: (filter, value) => (value === true ? getFilterData(filter) : null),
  MESSAGE_FIELD_FILTER: (filter, value) => (value?.length > 0 ? getFilterData(filter, { filter: value }) : null),
  TIME_FILTER: (filter, value) =>
    !value?.fromTime && !value?.toTime
      ? null
      : getFilterData(filter, {
          fromTime: value?.fromTime || undefined,
          toTime: value.toTime || undefined,
        }),
  TOTAL_AMOUNT_IN: (filter, value) => {
    if (value?.amount === "" || isNaN(value?.amount)) return null;

    const [amount, decimals] = numberWithDecimalCount.split(value.amount);

    return getFilterData(filter, {
      cashAmountIn: [{ ...value, decimals, amount }],
    });
  },
};

const machinesAndLocationsToFilter = (
  machineIds: string[],
  locationIds: string[],
): MessageFieldFiltering | BiNodeFilter | null => {
  const machinesFilter: MessageFieldFiltering | null = machineIds.length
    ? {
        filterType: "MACHINE_UUID_FILTER",
        operationType: "MESSAGE_FIELD_FILTER",
        filter: machineIds,
      }
    : null;
  const locationsFilter: MessageFieldFiltering | null = locationIds.length
    ? {
        filterType: "LOCATION_FILTER",
        operationType: "MESSAGE_FIELD_FILTER",
        filter: locationIds,
      }
    : null;

  return machinesFilter && locationsFilter
    ? {
        filterType: null,
        operationType: "BI_NODE",
        child: locationsFilter,
        otherChild: machinesFilter,
      }
    : machinesFilter || locationsFilter;
};

export const getFilterWithValueByAbstractFilter = (data: Filters, value: any): Filters | null =>
  data?.operationType && getFilterByOperationType[data.operationType]
    ? getFilterByOperationType[data.operationType](data, value)
    : null;

export const getFiltersToSend = (
  filters: Record<string, any>,
  machines: string[],
  locations: string[],
  fieldsData: Record<string, Filters>,
) => {
  const filterValues = Object.entries(filters).flatMap(
    ([key, value]) => omitTypenames(getFilterWithValueByAbstractFilter(fieldsData[`filters.${key}`], value)) || [],
  );
  const machinesAndLocationsFilter = machinesAndLocationsToFilter(machines, locations);

  return filterValues.reduceRight((acc, curr) => {
    curr.child = acc;
    return curr;
  }, machinesAndLocationsFilter as Filters | BiNodeFilter | null);
};

const getFilterValueByOperationType = {
  COUNT: (filter: CountOperationFilter) => filter.countValue,
  DURATION: (filter: DurationFilter) => filter.duration,
  RATIO: (filter: RatioFilter) => filter.ratio,
  NO_PARAM: () => true,
  MESSAGE_FIELD_FILTER: (filter: MessageFieldFiltering) => filter.filter,
  TIME_FILTER: (filter: TimeFilter) =>
    filter?.fromTime || filter?.toTime
      ? {
          fromTime: filter?.fromTime ? parse(filter.fromTime, TIME_FORMAT, new Date()) : undefined,
          toTime: filter?.toTime ? parse(filter.toTime, TIME_FORMAT, new Date()) : undefined,
        }
      : null,
  TOTAL_AMOUNT_IN: (filter: CashAmountFilter) =>
    filter.cashAmountIn?.[0]
      ? {
          currency: filter.cashAmountIn[0].currency,
          amount: Number(`${filter.cashAmountIn[0].amount || 0}.${filter.cashAmountIn[0].decimals || 0}`),
          ...(filter.cashAmountIn[0].nodeId && { nodeId: filter.cashAmountIn[0].nodeId }),
        }
      : null,
};

type FiltersPart = {
  machines?: string[];
  locations?: string[];
  filters: Record<string, any>;
};

export const getInitialFormFilters = (filter: Filters | BiNodeFilter | null): FiltersPart => {
  const filtersFormData: FiltersPart = {
    filters: {},
  };

  const getValue = (f: Filters | BiNodeFilter | null) => {
    if (!f) return;

    if (f.filterType === "MACHINE_UUID_FILTER" && (f as MessageFieldFiltering).filter?.length) {
      filtersFormData.machines = (f as MessageFieldFiltering).filter as string[];
    } else if (f.filterType === "LOCATION_FILTER" && (f as MessageFieldFiltering).filter?.length) {
      filtersFormData.locations = (f as MessageFieldFiltering).filter as string[];
    } else if (f.filterType && f.operationType) {
      const data = getFilterValueByOperationType[f.operationType]?.(f) ?? null;
      if (data !== null) filtersFormData.filters[f.filterType] = data;
    }

    const child = f.child || ("otherChild" in f && f.otherChild);
    if (child) getValue(child);
  };

  getValue(filter);

  return filtersFormData;
};
