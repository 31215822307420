import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllLaneAllocationsDocument, useDeactivateLaneAllocationMutation } from "generated";
import { Trans } from "i18n";

type Props = {
  allocationNodeId: string;
  onClose?: () => void;
};

export const DeactivateLaneAllocation: React.FC<Props> = ({ allocationNodeId, onClose }) => {
  const [deactivateLaneAllocation, { loading }] = useDeactivateLaneAllocationMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "administration.la.deactivateSuccess" : "administration.la.deactivateError", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deactivateLaneAllocation({
        variables: { allocationNodeId },
        ignoreResults: false,
        update: (cache) => {
          evictQuery(FindAllLaneAllocationsDocument, cache);
        },
      });
      if (result.data?.deactivateLaneAllocation) onClose?.();
      showToast(!!result.data?.deactivateLaneAllocation);
    } catch (error) {
      showToast(false);
    }
  };

  return (
    <EntityDelete actionText="administration.la.deactivate" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="administration.la.deactivateDescription" values={{ allocationNodeId }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
