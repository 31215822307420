import { MinusCircle, PlusCircle, UserGear } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { ActionButton } from "base/components";
import { Text, Tooltip, useModalComponent } from "common/guideline";

import { ChangeTenantStatus, useTenantFeaturesFormModal } from "../../../components";

import { RowTenantDataProps } from "./types";

export const RowActions: React.FC<RowTenantDataProps> = ({ row }) => {
  const canConfigureTenant = usePermissionAccess("canConfigureTenant");
  const canTenantStatusChange = usePermissionAccess("canTenantStatusChange");
  const canTenantFeaturesChange = usePermissionAccess("canTenantFeaturesChange");
  const isCurrentlyActive = row.original.nodeStatus === "ACTIVE";
  const [show] = useModalComponent({
    Component: (
      <ChangeTenantStatus
        tenantId={row.original.tenantId as string}
        name={row.original.name as string}
        status={row.original.nodeStatus}
      />
    ),
  });
  const [showFeaturesModal] = useTenantFeaturesFormModal(row.original.tenantId as string);

  return (
    <div tw="flex">
      {!canTenantStatusChange ? null : isCurrentlyActive ? (
        <Tooltip content={<Text tKey="tenant.deactivate.title" />}>
          <ActionButton.Delete Icon={MinusCircle} onClick={() => show()} />
        </Tooltip>
      ) : (
        <Tooltip content={<Text tKey="tenant.activate.title" />}>
          <ActionButton.Edit Icon={PlusCircle} onClick={() => show()} />
        </Tooltip>
      )}
      {canTenantFeaturesChange ? (
        <Tooltip content={<Text tKey="tenant.features.titleUpdate" />}>
          <ActionButton.Edit Icon={UserGear} onClick={() => showFeaturesModal()} />
        </Tooltip>
      ) : null}
      <Link
        route={canConfigureTenant ? "TENANT_CONFIGURE" : "TENANT_UPDATE"}
        params={{ tenantId: row.original.tenantId as string }}
      >
        <ActionButton.Edit />
      </Link>
    </div>
  );
};
