import { memo } from "react";

import { FieldDataProps } from "./types";
import { useFieldData } from "./useFieldData";

const FieldDataRaw = <T, R>({
  name,
  children,
  parse,
  type = "values",
}: FieldDataProps<T, R>): ReturnType<typeof children> => children(useFieldData<R, T>(name, type as any, parse as any));

export const FieldData = memo(FieldDataRaw);
