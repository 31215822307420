import { isBefore, startOfDay } from "date-fns";
import { TKeys } from "i18next";

import { defaultTimeZone, timeZonesOptions } from "base/helpers";
import { getDateFieldFormatters, getValidateDateRangeTo } from "common/form/renderFields";
import { Repeat } from "generated";

import { getOneColumnWrapper, getTwoColumnWrapper } from "./common";
import { FieldMonthly } from "./FieldMonthly";
import { FieldWeekly } from "./FieldWeekly";
import { NextReportDatesDetails } from "./NextReportDatesDetails";
import { StepType } from "./types";

export const repeatOptions: Record<Repeat, { value: Repeat; label: TKeys }> = {
  DAILY: { value: "DAILY", label: "report.sr.daily" },
  WEEKLY: { value: "WEEKLY", label: "report.sr.weekly" },
  MONTHLY: { value: "MONTHLY", label: "report.sr.monthly" },
};

const dateFormatters = getDateFieldFormatters();
const today = new Date();
const todayParsed = dateFormatters.parse(today);

const isUpdateDateTouchedAndInPast = (updateForm: boolean, value?: string, initialValue?: string | Date) =>
  updateForm && value && value !== initialValue && !isBefore(startOfDay(new Date()), new Date(value));

export const frequencyStep: StepType = {
  title: "report.sr.frequency",
  section: (updateForm, initialData) =>
    getOneColumnWrapper([
      {
        type: "select",
        name: "metadata.repeat",
        label: "report.sr.repeat",
        options: Object.values(repeatOptions),
        defaultValue: repeatOptions.WEEKLY.value,
        calculation: {
          // clear fields when changing repeat option
          updates: () => ({
            repeatOnDay: undefined,
          }),
        },
      },
      {
        type: "condition",
        when: "metadata.repeat",
        is: repeatOptions.WEEKLY.value,
        fields: [
          {
            type: "custom",
            name: "metadata.repeatOnDay",
            Component: FieldWeekly,
            validate: { type: "number", required: true, min: 1, max: 7 },
          },
        ],
      },
      {
        type: "condition",
        when: "metadata.repeat",
        is: repeatOptions.MONTHLY.value,
        fields: [
          {
            type: "custom",
            name: "metadata.repeatOnDay",
            Component: FieldMonthly,
            validate: { type: "number", required: true, min: 1, max: 31 },
          },
        ],
      },
      getTwoColumnWrapper([
        {
          type: "date",
          dateFormat: "time",
          name: "metadata.executionTime",
          label: "report.sr.at",
          defaultValue: "09:00",
        },
        {
          type: "select",
          name: "metadata.zoneName",
          placeholder: "base.timeZone",
          label: "report.sr.timeZone",
          options: timeZonesOptions,
          defaultValue: defaultTimeZone,
          validate: { type: "string", required: true },
        },
      ]),
      getTwoColumnWrapper([
        {
          type: "date",
          dateFormat: "date",
          name: "metadata.executeFrom",
          label: "report.sr.executeFrom",
          fromDate: updateForm ? undefined : today,
          defaultValue: todayParsed,
          validate: (v) => {
            if (isUpdateDateTouchedAndInPast(updateForm, v, initialData.metadata?.executeFrom ?? ""))
              return "report.sr.inFutureOrNotTouched";

            return v ? null : "common.form.err.required";
          },
          ...dateFormatters,
        },
        {
          type: "date",
          dateFormat: "date",
          name: "metadata.executeUntil",
          label: "report.sr.executeUntil",
          fromDate: updateForm ? undefined : today,
          validate: (value, formData) => {
            if (isUpdateDateTouchedAndInPast(updateForm, value, initialData.metadata?.executeUntil ?? ""))
              return "report.sr.inFutureOrNotTouched";

            return getValidateDateRangeTo(
              "metadata.executeFrom",
              true,
              "report.sr.higherThanExecuteFrom",
            )(value, formData);
          },
          ...dateFormatters,
        },
      ]),
      {
        type: "custom",
        name: "metadata.repeatOnDay",
        Component: NextReportDatesDetails,
      },
    ]),
};
