import { useMemo, useRef, useState } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useDebouncedValue, useMappedQuery } from "common/hooks";
import { GetAllTemplatesFilteredQuery, useGetAllTemplatesFilteredQuery } from "generated";

type AvailableConfigTemplateOption = {
  label: string;
  value: string;
};

type TemplatesResult = NonNullable<
  NonNullable<GetAllTemplatesFilteredQuery["getAllTemplatesFiltered"]>["result"]
>[0] & {
  __typename: "TemplateDtoOut";
};

const LIST_SIZE = 20;

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const value = useFieldData<string | string[] | undefined>(prev.name, "values");
  const [filterText, setFilterText] = useState("");
  const debouncedFilterText = useDebouncedValue(filterText);
  const currentValueNodeIds = Array.isArray(value) ? value : value ? [value] : [];

  const [pickedOptions = [], { loading: pickedLoading }] = useMappedQuery(
    (data, previousData) => {
      const result = data ?? previousData;

      return ((result?.getAllTemplatesFiltered?.result as TemplatesResult[])?.flatMap((p) =>
        p ? [{ label: p.name ?? "", value: p.nodeId ?? "", data: p }] : [],
      ) || []) as AvailableConfigTemplateOption[];
    },
    useGetAllTemplatesFilteredQuery({
      skip: !value || !value?.length,
      variables: {
        templateFilters: {
          nodeIds: currentValueNodeIds,
        },
        searchRequest: {
          page: 0,
          size: currentValueNodeIds.length,
        },
      },
    }),
  );

  const listSizeRef = useRef(LIST_SIZE);
  const [data = prev, { loading, refetch }] = useMappedQuery(
    (data, previousData) => {
      const response = data ?? previousData;
      const result = (response?.getAllTemplatesFiltered?.result ?? []) as TemplatesResult[];

      const fullSize = response?.getAllTemplatesFiltered?.fullSize ?? 0;

      const options: AvailableConfigTemplateOption[] = result
        .flatMap((p) => (p ? [{ label: p.name ?? "", value: p.nodeId ?? "", data: p }] : []))
        .sort(makeSorter("label"));

      return {
        ...prev,
        isLoading: false,
        options,
        onMenuScrollToBottom: () => {
          if (options.length >= fullSize) return;

          listSizeRef.current += LIST_SIZE;
          refetch({ searchRequest: { page: 0, size: listSizeRef.current } });
        },
      };
    },
    useGetAllTemplatesFilteredQuery({
      variables: {
        templateFilters: debouncedFilterText ? { name: debouncedFilterText } : undefined,
        searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
      },
    }),
    true,
  );

  const options = useMemo(
    () => [
      ...pickedOptions,
      ...(data.options ?? []).filter(
        (o) => !pickedOptions.find((p) => p.value === (o as AvailableConfigTemplateOption).value),
      ),
    ],
    [data, pickedOptions],
  );

  return [
    {
      ...data,
      options,
      onInputChange: setFilterText,
      isLoading: loading || pickedLoading,
    },
    true,
  ];
};

export const getAvailableConfigTemplatesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField({ label: "administration.rc.selectTemplate", isLoading: true, ...value }, useConfig);
