import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "machineManagement",
    route: "MACHINE_MANAGEMENT",
  },
  {
    text: "refillReport",
    route: "REFILL_REPORT",
  },
];
