import { QueryResult } from "@apollo/client";
import { useMemo } from "react";

// helper to map data returned by graphql query to a custom object
export const useMappedQuery = <MapResult, RES extends QueryResult<any, any>>(
  mapFn: (d: RES["data"], p: RES["previousData"]) => MapResult,
  res: RES,
  mapWhileLoading = false,
): [MapResult | undefined, RES] => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapped = useMemo(
    () => (!mapWhileLoading && res.loading ? undefined : mapFn(res.data, res.previousData)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res.data, res.previousData, res.loading, mapWhileLoading],
  );

  return [mapped, res];
};
