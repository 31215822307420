import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const Allocations = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.allocations"
    subtitle="administration.allocationsDescription"
  />
);
