import { CaretDown } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { NamesByIds } from "base/components";
import { SelectLiveFieldUseConfig, getSelectLiveField } from "base/fields";
import { CustomField, changeFormData, useFieldData, useFormContext, useFormData, useFormGenerator } from "common/form";
import { CustomRenderFields, TextLabel, customRender } from "common/form/renderFields";
import { Text } from "common/guideline";
import { currencyOptions, stringToPossibleFloat, stringToPossibleInt } from "common/helpers";
import {
  CashAmountFilter,
  CountOperationFilter,
  DurationFilter,
  FilterType,
  MessageFieldFiltering,
  MessageType,
  NoParamFilter,
  RatioFilter,
  TimeFilter,
  useGetReportHeaderQuery,
  useGetSupportedFiltersQuery,
} from "generated";
import { getSelectLocationsField } from "location/components";

import { getFilteringFormatted, messageFilter } from "../../../helpers";

import { Filters, getFilterWithValueByAbstractFilter } from "./helpers";

const DisplayValue = ({ name }: { name: string }) => {
  const { t } = useTranslation();
  const value = useFieldData(name, "values");
  const formData = useFormData("data");
  const data = formData[name];
  const toDisplay = useMemo(() => {
    const trigger = getFilterWithValueByAbstractFilter(data, value);

    return trigger ? (
      getFilteringFormatted({ child: trigger as any }, t).map(([label, value, machineOrLocation]) => (
        <div key={label}>
          <span tw="text-gray-6">{label}</span>:{" "}
          <span tw="text-primary-default">
            {machineOrLocation ? <NamesByIds entity={machineOrLocation} ids={messageFilter.split(value)} /> : value}
          </span>
        </div>
      ))
    ) : (
      <>
        <Text
          tw="text-gray-6"
          tValue={{ colon: true }}
          tKey={
            name === "machines"
              ? "machine.machine_other"
              : name === "locations"
              ? "location.location_other"
              : `alerts.filterType.${(data?.filterType as FilterType) || "UNKNOWN"}`
          }
        />{" "}
        <Text tKey={`alerts.${data?.__typename === "NoParamFilter" ? "notFiltered" : "all"}`} />
      </>
    );
  }, [data, value, t, name]);

  return <TextLabel>{toDisplay}</TextLabel>;
};

const getValueContainer = (field: CustomRenderFields & { name: string }): CustomRenderFields => ({
  type: "container",
  fields: [field],
  Component({ children }) {
    const [open, setOpen] = useState(false);

    return (
      <div>
        <div className="group" tw="flex justify-between cursor-pointer" onClick={() => setOpen((p) => !p)}>
          <DisplayValue name={field.name} />
          <CaretDown
            size={16}
            weight="bold"
            css={css`
              ${open ? tw`rotate-180` : tw`rotate-0`}
              ${tw`transition-all duration-300 text-gray-6 group-hover:text-gray-8 dark:text-gray-7 dark:group-hover:text-gray-9`}
            `}
          />
        </div>
        <div style={open ? undefined : { visibility: "hidden", height: 0, userSelect: "none", clipPath: "circle(0)" }}>
          {children}
        </div>
      </div>
    );
  },
});

const getComplexFilter = (v: CashAmountFilter | TimeFilter, fields: CustomRenderFields[]): CustomRenderFields => ({
  type: "custom",
  name: `filters.${v.filterType}`,
  fields,
  Component({ children }) {
    const form = useFormContext().useStore;

    useEffect(() => {
      form.getState().registerFieldMeta(`filters.${v.filterType}`, { data: v });
      // do it once
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div tw="flex space-x-2">{children}</div>;
  },
});

const filterByTypename = {
  CountOperationFilter: (v: CountOperationFilter): CustomRenderFields => ({
    type: "number",
    name: `filters.${v.filterType}`,
    inputMode: "decimal",
    data: v,
    parse: stringToPossibleFloat,
  }),
  DurationFilter: (v: DurationFilter): CustomRenderFields => ({
    type: "date",
    name: `filters.${v.filterType}`,
    dateFormat: "time_seconds",
    data: v,
  }),
  MessageFieldFiltering: (v: MessageFieldFiltering): CustomRenderFields => ({
    type: "select",
    name: `filters.${v.filterType}`,
    options: [...(v.filter || [])].sort().map((value) => ({ value, label: value })),
    isMulti: true,
    data: v,
  }),
  NoParamFilter: (v: NoParamFilter): CustomRenderFields => ({
    type: "checkbox",
    name: `filters.${v.filterType}`,
    data: v,
  }),
  RatioFilter: (v: RatioFilter): CustomRenderFields => ({
    type: "number",
    name: `filters.${v.filterType}`,
    inputMode: "numeric",
    data: v,
    parse: stringToPossibleInt,
  }),
  TimeFilter: (v: TimeFilter) =>
    getComplexFilter(v, [
      {
        type: "date",
        name: `filters.${v.filterType}.fromTime`,
        label: "alerts.fromTime",
        dateFormat: "time",
      },
      {
        type: "date",
        name: `filters.${v.filterType}.toTime`,
        label: "alerts.toTime",
        dateFormat: "time",
      },
    ]),
  CashAmountFilter: (v: CashAmountFilter) =>
    getComplexFilter(v, [
      {
        type: "select",
        name: `filters.${v.filterType}.currency`,
        label: "alerts.currency",
        options: currencyOptions,
      },
      {
        type: "number",
        name: `filters.${v.filterType}.amount`,
        label: "alerts.amount",
        inputMode: "decimal",
        parse: stringToPossibleFloat,
      },
    ]),
};

const machinesFilter = "MACHINE_UUID_FILTER";
const locationsFilter = "LOCATION_FILTER";

const useMachines: SelectLiveFieldUseConfig = (p) => {
  const form = useFormContext().useStore;
  const pickedLocations = useFieldData<string[] | undefined>("locations", "values");
  const { data, loading } = useGetReportHeaderQuery();

  // calculate machine to exclude (taht are connected to choosen location) and options with the rest of machines
  const { machinesToExclude, options } = useMemo(() => {
    const _pickedLocations = pickedLocations || [];
    const locations = data?.getReportHeader?.locations || [];
    const machines = data?.getReportHeader?.machines || [];

    const machinesToExclude = _pickedLocations.flatMap(
      (p) => locations.find((l) => l?.nodeId === p)?.machineNodeIds || [],
    );

    const options = machines.flatMap((m) =>
      machinesToExclude.includes(m?.nodeId as any) ? [] : { value: m?.nodeId, label: m?.name },
    );

    return { machinesToExclude, options };
  }, [data, pickedLocations]);

  // we need to unset machines which are included on location that user choose
  useEffect(() => {
    const { values } = form.getState();

    const machinesUpdated = values.machines?.filter?.((v) => !machinesToExclude.includes(v));

    if (machinesUpdated) {
      changeFormData(form, { machines: machinesUpdated });
    }
  }, [machinesToExclude, form]);

  return [{ ...p, isLoading: loading, options }, true];
};

const getMachinesFilter = (filters: Filters[]) => {
  const found = filters.find((f) => f.filterType === machinesFilter);

  return found
    ? [
        getSelectLiveField(
          { name: "machines", label: undefined, data: found, isLoading: true, isMulti: true },
          useMachines,
        ),
      ]
    : [];
};

const getLocationsFilter = (filters: Filters[]) => {
  const found = filters.find((f) => f.filterType === locationsFilter);
  return found ? [getSelectLocationsField({ name: "locations", label: undefined, data: found })] : [];
};

const getFilterFields = (filters: Filters[]): CustomRenderFields[] =>
  [
    ...filters.flatMap((f) =>
      [machinesFilter, locationsFilter].includes(f.filterType || "")
        ? []
        : filterByTypename[f.__typename || ""]?.(f) || [],
    ),
    ...getMachinesFilter(filters),
    ...getLocationsFilter(filters),
  ].map(getValueContainer);

export const filtersField: Omit<CustomField, "fields"> = {
  type: "custom",
  name: "filters",
  Component() {
    const form = useFormContext().useStore;
    const messageType = useFieldData("messageType", "values") as MessageType | undefined;
    const { previousData, data = previousData } = useGetSupportedFiltersQuery({
      skip: !messageType,
      variables: {
        input: {
          messageType,
          operationType: "MESSAGE_TYPE_FILTER",
        },
      },
      onCompleted: (res) => {
        // update filters value to contain only values for new supported filters
        const supported = (res?.getSupportedFilters?.[0] || []).flatMap((f) => f?.filterType || []);
        const filters = form.getState().values.filters || {};
        const filtersToUpdate = Object.fromEntries(
          Object.entries(filters).filter(([name]) => supported.includes(name as any)),
        );
        form.getState().setField("filters", { value: filtersToUpdate, touched: false, error: null });
      },
    });

    const supportedFilters = data?.getSupportedFilters?.[0] || null;

    const fields = useFormGenerator(
      useMemo(() => (supportedFilters ? getFilterFields(supportedFilters as Filters[]) : []), [supportedFilters]),
      customRender,
    );

    return (
      <div>
        <Text tKey="alerts.filters" tw="block mb-2" />
        {supportedFilters ? <div tw="space-y-3">{fields}</div> : <Text tKey="alerts.selectToShowFilters" />}
      </div>
    );
  },
};
