import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { GetAllRulesDocument, useCreateRulesMutation } from "generated";

import { AlertForm } from "./AlertForm";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "alerts.title",
    route: "ALERTS",
  },
  {
    text: "alerts.create",
    route: "ALERTS_CREATE",
  },
];

export const AlertCreate = () => {
  const [createRules] = useCreateRulesMutation();

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="alerts.create">
      <AlertForm
        submitLabel="alerts.create"
        onSubmit={(input) =>
          createRules({
            variables: { input },
            ignoreResults: true,
            update: (cache) => evictQuery(GetAllRulesDocument, cache),
          })
            .then(() => {
              useToast.actions.show("alerts.createSuccess", { variant: "success" });
              navigateTo({ route: "ALERTS" });
            })
            .catch(() => useToast.actions.show("alerts.createError", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
