import { Badge, Text } from "common/guideline";
import { AlertLevel } from "generated";

type Props = React.PropsWithChildren<{
  value: AlertLevel | undefined | null;
}>;

const statusToBadgeVariant: Record<AlertLevel, "error" | "warning" | "neutral" | "info"> = {
  ERROR: "error",
  INFO: "info",
  UNKNOWN: "neutral",
  WARNING: "warning",
};

export const AlertLevelBadge: React.FC<Props> = ({ value, children }) => (
  <Badge variant={statusToBadgeVariant[value || "UNKNOWN"]}>
    <Text tKey={`alerts.alertLevel.${value || "UNKNOWN"}`} />
    {children}
  </Badge>
);
