import { RouteConfig, RouteValue } from "routing";

import {
  Administration,
  Allocations,
  ConfigTemplates,
  DispenseLimits,
  MachineGroup,
  MachineGroupCreate,
  MachineGroupUpdateParams,
  MachineUserGroups,
  Permissions,
  PosTransactions,
  Roles,
  SoftwarePackages,
  UpdateMachineGroupScreen,
} from "./pages";
import { LaneAllocations } from "./pages/LaneAllocations/LaneAllocations";
import { LocationGroups } from "./pages/LocationGroups/LocationGroups";
import { LocationGroupCreate } from "./pages/LocationGroups/LocationGroupUpsert/LocationGroupCreate";
import {
  LocationGroupUpdate,
  LocationGroupUpdateParams,
} from "./pages/LocationGroups/LocationGroupUpsert/LocationGroupUpdate";

export type AdministrationPaths = {
  ADMINISTRATION: RouteValue;
  MACHINE_GROUPS: RouteValue;
  MACHINE_GROUP_CREATE: RouteValue;
  MACHINE_GROUP_UPDATE: RouteValue<{ params: MachineGroupUpdateParams }>;
  ROLES: RouteValue;
  PERMISSIONS: RouteValue;
  ALLOCATIONS: RouteValue;
  LOCATION_GROUPS: RouteValue;
  LOCATION_GROUP_CREATE: RouteValue;
  LOCATION_GROUP_UPDATE: RouteValue<{ params: LocationGroupUpdateParams }>;
  CONFIG_TEMPLATES: RouteValue;
  DISPENSE_LIMITS: RouteValue;
  LANE_ALLOCATIONS: RouteValue;
  POS_TRANSACTIONS: RouteValue;
  MACHINE_USER_GROUP: RouteValue;
  SOFTWARE_PACKAGES: RouteValue;
};

export const ADMINISTRATION: RouteConfig<AdministrationPaths> = {
  ADMINISTRATION: {
    path: "/administration",
    Component: Administration,
  },
  LOCATION_GROUPS: {
    path: "/administration/location-groups",
    Component: LocationGroups,
  },
  LOCATION_GROUP_CREATE: {
    path: "/administration/location-groups/location-group-create",
    Component: LocationGroupCreate,
  },
  LOCATION_GROUP_UPDATE: {
    path: "/administration/location-groups/location-group-update/:nodeId",
    Component: LocationGroupUpdate,
  },
  MACHINE_GROUPS: {
    path: "/administration/machine-group",
    Component: MachineGroup,
  },
  MACHINE_GROUP_CREATE: {
    path: "/administration/machine-group/machine-group-create",
    Component: MachineGroupCreate,
  },
  MACHINE_GROUP_UPDATE: {
    path: "/administration/machine-group/machine-group-update/:nodeId",
    Component: UpdateMachineGroupScreen,
  },
  ROLES: {
    path: "/administration/roles",
    Component: Roles,
  },
  PERMISSIONS: {
    path: "/administration/permissions",
    Component: Permissions,
  },
  ALLOCATIONS: {
    path: "/administration/allocations",
    Component: Allocations,
  },
  MACHINE_USER_GROUP: {
    path: "/administration/machine-user-groups",
    Component: MachineUserGroups,
  },
  POS_TRANSACTIONS: {
    path: "/administration/pos-transactions",
    Component: PosTransactions,
  },
  CONFIG_TEMPLATES: {
    path: "/administration/config-templates",
    Component: ConfigTemplates,
  },
  DISPENSE_LIMITS: {
    path: "/administration/dispense-limits",
    Component: DispenseLimits,
  },
  LANE_ALLOCATIONS: {
    path: "/administration/lane-allocations",
    Component: LaneAllocations,
  },
  SOFTWARE_PACKAGES: {
    path: "/administration/software-packages",
    Component: SoftwarePackages,
  },
};
