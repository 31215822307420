import { Plus, Trash } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { useFieldArray } from "common/form";
import { Button } from "common/guideline";

export const Recipients: React.FC<React.PropsWithChildren> = ({ children }) => <div>{children}</div>;

export const RecipientsRow: React.FC<React.PropsWithChildren<{ fieldArrayName: string; index: number }>> = ({
  children,
  fieldArrayName,
  index,
}) => {
  const {
    fields: { length },
    remove,
    append,
  } = useFieldArray(fieldArrayName);

  return (
    <>
      <div tw="flex gap-2 items-end py-2">
        <div tw="flex-1 flex [&>*]:flex-1 gap-2">{children}</div>
        <div tw="flex gap-2 pb-1">
          {length > 1 ? (
            <Button variant={["error", "smSquare"]} onClick={() => remove(index)}>
              <Trash weight="duotone" />
            </Button>
          ) : (
            <Button tw="invisible" variant="smSquare" />
          )}
          {index + 1 === length || length === 0 ? (
            <Button variant={["primary", "smSquare"]} onClick={() => append({})}>
              <Plus weight="duotone" />
            </Button>
          ) : (
            <Button tw="invisible" variant="smSquare" />
          )}
        </div>
      </div>
    </>
  );
};
