export const de = {
  alerts: {
    title: "Alarmierung",
    description: "Konfigurieren, Erstellen und Löschen von Warnmeldungen",
    history: "Zurückliegende Alarmierungen",
    historyDescription:
      "Zeigt die Historie der gesendeten Alarmierungen und ihrer Empfänger an. Sie müssen Alarme konfigurieren, bevor Sie Berichte über den Verlauf von Alarmen erstellen, sonst wird der Bericht leer angezeigt..",
    date: "Datum",
    level: "Level",
    text: "Alarmierungstext",
    type: "Art",
    contact: "Kontakt",
    name: "Name",
    notifications: "Bemerkung",
    filtering: "Filter",
    timeBetween: "von {{from}} bis {{to}}",
    recipients: "Empfänger",
    goBack: "Zurück",
    create: "Erstelle Alarmierung",
    update: "Update Alarmierung",
    createSuccess: "Alarmierung erfolgreich erstellt",
    createError: "Alarmierung fehlgeschlagen",
    updateSuccess: "Alarmierung erfolgreich upgedated",
    updateError: "Alarmierungs update gescheitert",
    deleteSuccess: "Alarmierungerfolgreich gelöscht",
    deleteError: "Löschen der Alarmierung gescheitert",
    delete: "Löschen",
    close: "Schließen",
    deleteAlert: "Alarmierung löschen",
    deleteDescription: "Alarmierung <0>{{name}}</0> wird permanent gelöscht.",
    fromTime: "von",
    toTime: "bis",
    currency: "Währung",
    amount: "Menge",
    selectToShowFilters: "Wählen Sie das auslösende Ereignis, um Filter anzuzeigen",
    all: "alle",
    filtered: "Gefiltert",
    notFiltered: "Ungefiltert",
    filters: "Filter",
    alertLevel: {
      ERROR: "Error",
      INFO: "Info",
      UNKNOWN: "Unbekannt",
      WARNING: "Warnung",
    },
    triggerType: {
      EMAIL: "Email",
      SMS: "Sms",
      WEBHOOK: "Webhook",
      UNKNOWN: "Unbekannt",
    },
    filterType: {
      MESSAGE_TYPE: "Art der Nachricht",
      MACHINE_DISCONNECTED: "Maschine Offline",
      MACHINE_CONNECTED: "Maschine Online",
      ERROR_CLEARED: "Error gelöst",
      ERROR_INFORMATION: "Error information",
      ERROR_LOCATION: "Error Ort",
      ERROR_KIND: "Art des Fehlers",
      MACHINE_UUID_FILTER: "Maschine",
      MESSAGE_OLD_STATUS: "Nachrichtenstatus Alt",
      LOCATION_FILTER: "Standort",
      ERROR_SEVERITY: "Schwere des Fehlers",
      DOOR_OPEN: "Tür offen",
      MESSAGE_STATUS: "Status der Nachricht",
      TIME_OF_DAY: "Tageszeit",
      TRANSACTION_SUB_TYPE: "Unterart der Transaktion",
      ERROR_UNCLEARED: "Error ungeklärt",
      DOOR_CLOSED: "Tür geschlossen",
      BOX_GOES_ABOVE_COUNT: "Box geht über den Zählerstand hinaus",
      BOX_GOES_BELOW_COUNT: "Box geht unter den Zählerstand",
      TRANSACTION_TYPE: "Transaktionsart",
      DEVICE_ID: "Geräte-ID",
      TRANSACTION_DEVICE_ID: "Transaktionsgeräte-ID",
      ANY_BOX_EXCEEDS_COUNT_RATIO: "Jede Box überschreitet das Zählverhältnis",
      TRANSACTION_BOX_ID: "Transaktionsbox-ID",
      ANY_BOX_EXCEEDS: "Jede Box überschreitet",
      CONTENT_EXCEEDS: "Inhalt übersteigt",
      USER_BALANCE_CHANGE_BELOW_THRESHOLD: "Änderung des Benutzersaldos unterhalb der Schwelle",
      USER_BALANCE_CHANGE_ABOVE_THRESHOLD: "Änderung des Benutzersaldos über dem Schwellenwert",
      WEBHOOK: "Webhook",
    },
    messageType: "Triggering event",
    messageTypes: {
      machines: "Maschinen",
      UNKNOWN: "Unbekannt",
      ERROR: "Error erhalten",
      MACHINE_CONTENT_CHANGE: "Inhalt wurde ungedated",
      MACHINE_ONLINE_STATUS_CHANGE: "Online status hat sich geändert",
      SYSTEM_STATUS: "Status upgedated",
      TRANSACTION: "Transaktion",
      TRANSACTION_WITH_BOX_CONTENT: "Transaktion mit Boxinhalt",
    },
  },
};
