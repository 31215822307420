import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useMappedQuery } from "common/hooks";
import { useFindAllCorrectionReasonsQuery } from "generated";

type CorReasonOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: CorReasonOption[] =
      d?.findAllCorrectionReasons?.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : [])) || [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllCorrectionReasonsQuery());

  return [data, !loading];
};

export const getSelectCorrectionReasonsField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "transport.corReasons.title_one",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
