import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as AlertsHistorySVG } from "assets/icons/AlertsHistory.svg";
import { PageLayout, SelectWrapper } from "base/components";
import { DateRangeInfo, useDateRangeFilter } from "common/guideline";
import { FilterBox } from "report/components";

import { AlertsHistoryTable } from "./AlertsHistoryTable";
import { breadcrumbs } from "./breadcrumbs";

export const AlertsHistory = () => {
  const [{ from, to }, DateRange] = useDateRangeFilter("currentDay", {
    label: "report.period",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="alerts.history"
      subtitle="alerts.historyDescription"
      Icon={AlertsHistorySVG}
      withPicker
    >
      <FilterBox>
        <SelectWrapper>{DateRange}</SelectWrapper>
      </FilterBox>
      <DateRangeInfo from={from} to={to} />
      <AlertsHistoryTable from={from} to={to} />
    </PageLayout>
  );
};
