import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindUserPermissionListQuery } from "generated";

type UserPermissionOption = {
  label: string;
  value: string;
};

const translatePermissionName = (name: string | null | undefined) => {
  if (name?.includes("tenant")) {
    return "user.allTenants";
  } else if (name?.includes("siteGroup")) {
    return "user.allSiteGroups";
  } else if (name?.includes("site")) {
    return "user.allSites";
  } else if (name?.includes("machine")) {
    return "user.allMachines";
  }

  return name || "";
};

const useConfig: SelectLiveFieldUseConfig = (previous) => {
  const [userPermissionsData = previous, { loading }] = useMappedQuery((d) => {
    const options: UserPermissionOption[] =
      d?.findUserPermissionList?.userRoles
        ?.flatMap((r) => (r ? [{ label: translatePermissionName(r.name), value: r.name || "" }] : []))
        .sort(makeSorter("label")) || [];

    return {
      ...previous,
      isLoading: false,
      options,
    };
  }, useFindUserPermissionListQuery());

  return [userPermissionsData, !loading];
};

export const getUserPermissionsField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "user.permissions",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
