export const fr = {
  alerts: {
    title: "Alertes",
    description: "Configurer, créer, et supprimer des aletes",
    history: "Historique des alertes",
    historyDescription:
      "Obtenez l'historique des alertes envoyées et de leurs destinataires. Vous devez configurer les alertes avant de créer des rapports sur l'historique des alertes, sinon le rapport sera vide",
    date: "Date",
    level: "Niveau",
    text: "Texte de l’alerte",
    type: "Type",
    contact: "Contact",
    name: "Nom",
    notifications: "Notifications",
    filtering: "Filtrer",
    timeBetween: "est entre {{from}} et {{to}}",
    recipients: "Destinataires",
    goBack: "Retour",
    create: "Créer une alerte",
    update: "Mettre à jour une alerte",
    createSuccess: "Alerte créée avec succès",
    createError: "La création de l’alerte a échoué",
    updateSuccess: "Alerte mise à jour avec succès",
    updateError: "La mise à jour de l’alerte a échoué",
    deleteSuccess: "Alerte supprimée avec succès",
    deleteError: "La suppression de l’alerte a échoué",
    delete: "Suppression",
    close: "Fermer",
    deleteAlert: "Supprimer l’alerte",
    deleteDescription: "L’alerte <0>{{name}}</0> sera supprimée définitivement.",
    fromTime: "De",
    toTime: "A",
    currency: "Devise",
    amount: "Montant",
    chooseToShowFilters: "Choisissez l'événement déclencheur pour afficher les filtres",
    all: "Tous",
    filtered: "Filtrés",
    notFiltered: "Non filtrés",
    filters: "Filtres",
    selectToShowFilters: "Sélectionner l'événement déclencheur pour afficher les filtres",
    alertLevel: {
      ERROR: "Erreur",
      INFO: "Information",
      UNKNOWN: "Inconnu",
      WARNING: "Notification",
    },
    triggerType: {
      EMAIL: "Courriel",
      SMS: "SMS",
      WEBHOOK: "Webhook",
      WEBHOOK_SNS: "SNS du Webhook",
      WEBHOOK_URL: "URL du Webhook",
      UNKNOWN: "Inconnu",
    },
    filterType: {
      MESSAGE_TYPE: "Type de message",
      MACHINE_DISCONNECTED: "Machine déconnectée",
      MACHINE_CONNECTED: "Machine connectée",
      ERROR_CLEARED: "Erreur corrigée",
      ERROR_INFORMATION: "Informations sur l’erreur",
      ERROR_LOCATION: "Localisation de l’erreur",
      ERROR_KIND: "Type d’erreur",
      MACHINE_UUID_FILTER: "Machines",
      MESSAGE_OLD_STATUS: "Ancien statut du message",
      LOCATION_FILTER: "Sites",
      ERROR_SEVERITY: "Sévérité de l’erreur",
      DOOR_OPEN: "Porte ouverte",
      MESSAGE_STATUS: "Statut du message",
      TIME_OF_DAY: "Heure",
      TRANSACTION_SUB_TYPE: "Sous-type de la transaction",
      ERROR_UNCLEARED: "Erreur non corrigée",
      DOOR_CLOSED: "Porte fermée",
      BOX_GOES_ABOVE_COUNT: "Contenu au-dessus du niveau défini",
      BOX_GOES_BELOW_COUNT: "Contenu en dessous du niveau défini",
      TRANSACTION_TYPE: "Type de transaction",
      DEVICE_ID: "Identifiant de la machine",
      TRANSACTION_DEVICE_ID: "Identifiant de la transaction machine",
      ANY_BOX_EXCEEDS_COUNT_RATIO: "Any box exceeds count ratio",
      TRANSACTION_BOX_ID: "Identifiant de la transaction du conteneur",
      ANY_BOX_EXCEEDS: "Tous les conteneurs sont au-dessus du niveau défini",
      CONTENT_EXCEEDS: "Le contenu est au-dessus du niveau défini",
      USER_BALANCE_CHANGE_BELOW_THRESHOLD: "Changement du solde de l'utilisateur en dessous du seuil",
      USER_BALANCE_CHANGE_ABOVE_THRESHOLD: "Changement du solde de l'utilisateur au dessus du seuil",
      WEBHOOK: "Webhook",
    },
    messageType: "Evénement déclencheur",
    messageTypes: {
      machines: "Machines",
      UNKNOWN: "Inconnu",
      ERROR: "Des erreurs sont reçues",
      MACHINE_CONTENT_CHANGE: "Les contenus sont mis à jour",
      MACHINE_ONLINE_STATUS_CHANGE: "Le statut état en ligne a été modifié",
      SYSTEM_STATUS: "Le statuts est mis à jour",
      TRANSACTION: "Transactions",
      TRANSACTION_WITH_BOX_CONTENT: "Transaction du conteneur",
    },
  },
};
