import { BaseSyntheticEvent } from "react";
import { OneOf } from "types";

import { getGetterByPath } from "../getGetterByPath";

import { FieldState } from "./types";

type ValueKey = "checked" | "value";

export type TypedInputTypes = "checkbox" | "radio" | "select" | "select-multiple";

const getSelectMultipleValues = (options?: any[]) =>
  Array.from(options || []).flatMap((el) => (el.selected ? [el.value] : []));

type CustomEventType = BaseSyntheticEvent<
  { text?: string } | null,
  any,
  { value: any; type: TypedInputTypes | string } & OneOf<
    {
      options: string[];
      multiple: true;
    },
    {
      checked?: any;
    }
  >
>;

const IS_REACT_NATIVE = window?.navigator?.product === "ReactNative";

const getElementValue = (event: CustomEventType) => {
  if (event?.target) {
    if (event.nativeEvent && (IS_REACT_NATIVE || event.nativeEvent.text !== undefined)) {
      return event.nativeEvent.text;
    }

    const { value, checked, options, type, multiple } = event.target;
    return type === "checkbox" ? Boolean(checked) : multiple ? getSelectMultipleValues(options) : value;
  }

  return event;
};

const getFormatFn = (format?: (v: any) => any, type?: string, value?: string) =>
  format ||
  (type === "radio"
    ? (v: string) => v !== undefined && v === value
    : type === "checkbox"
    ? (v: boolean) => v || false
    : null);

const getValueHelper = (format, type, value): [((v: any) => any) | null, ValueKey] => {
  const _format = getFormatFn(format, type, value);
  const valueKey: ValueKey = type && ["checkbox", "radio"].includes(type) ? "checked" : "value";

  return [_format, valueKey];
};

const getDefaultValue = (valueKey: ValueKey, multiple?: boolean) =>
  multiple ? [] : valueKey === "checked" ? false : "";

export const getInitial = (
  name,
  format,
  type,
  value,
  defaultValue,
  multiple,
  dataSub,
  errorSub,
  touchSub,
  activeSub,
  valueSub,
  parse,
  parseOnBlur,
  useStore,
) => {
  const state = useStore.getState();
  const toAdd: any = {
    onFocus: () => state.onFocusField(name),
    onBlur: () => {
      state.onChangeField(
        name,
        "onBlur",
        parseOnBlur ? parseOnBlur(getGetterByPath(name)(useStore.getState().values)) : undefined,
      );
    },
    onChange: async (event: any) => {
      const value = getElementValue(event);
      state.onChangeField(name, "onChange", parse ? parse(value) : value);
    },
  };
  const defaultValueIsDefined = defaultValue !== undefined;
  const getter = getGetterByPath(name);

  if (valueSub || defaultValueIsDefined) {
    const [_format, valueKey] = getValueHelper(format, type, value);
    const valueFromStore = getter(state.values);

    if (defaultValueIsDefined && valueFromStore === undefined) {
      toAdd[valueKey] = _format ? _format(defaultValue) : defaultValue;
    } else {
      toAdd[valueKey] = _format
        ? _format(valueFromStore)
        : valueFromStore === undefined
        ? getDefaultValue(valueKey, multiple)
        : valueFromStore;
    }
  }
  if (errorSub) toAdd.error = getter(state.errors);
  if (touchSub) toAdd.touched = getter(state.touched);
  if (activeSub) toAdd.active = state.active === name;
  if (dataSub) toAdd.data = state.data[name];

  return toAdd;
};

export const getSubscriberFns = (
  name,
  format,
  type,
  value,
  dataSub,
  errorSub,
  touchSub,
  activeSub,
  valueSub,
  multiple,
) => {
  const [_format, valueKey] = getValueHelper(format, type, value);
  const getter = getGetterByPath(name);

  const getWith = new Function(
    "getIn",
    `return state => ({
      ${dataSub ? `data: state.data.${name},` : ""}
      ${errorSub ? "error: getIn(state.errors)," : ""}
      ${touchSub ? "touched: getIn(state.touched)," : ""}
      ${valueSub ? `${valueKey}: getIn(state.values),` : ""}
      ${activeSub ? `active: state.active === "${name}",` : ""}
    })`,
  ) as (getIn: (v: any) => any) => (state: any) => FieldState;
  const get = getWith(getter);

  const check = new Function(
    "p",
    "c",
    `return (
        ${
          [
            valueSub ? `Object.is(p.${valueKey}, c.${valueKey})` : "",
            activeSub ? "p.active === c.active" : "",
            touchSub ? "p.touched === c.touched" : "",
            errorSub ? "Object.is(p.error, c.error)" : "",
            dataSub ? "Object.is(p.data, c.data)" : "",
          ]
            .filter(Boolean)
            .join(" && ") || "false"
        }
      )`,
  ) as (p: FieldState, c: FieldState) => boolean;

  const set = new Function(
    "_format",
    "defaultValue",
    `return (setState) => (v) =>
        setState(({ onChange, onFocus, onBlur }) => ({
          ...v,
          onChange,
          onFocus,
          onBlur,
          ${valueKey}: ${
      _format ? `_format(v.${valueKey}),` : `v.${valueKey} === undefined ? defaultValue : v.${valueKey}`
    }
      }))`,
  ) as (format: any, defaultValue: any) => (v: any) => (v: Partial<FieldState>) => void;

  return [get, check, set(_format, getDefaultValue(valueKey, multiple))] as [
    (state: any) => FieldState,
    (p: FieldState, c: FieldState) => boolean,
    (v: any) => (v: Partial<FieldState>) => void,
  ];
};
