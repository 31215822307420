import { startOfDay } from "date-fns";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout, TooltipValueWitdget, useQueryByDateFilter } from "base/components";
import { SelectRaw } from "common/form/renderFields";
import { useGetSumValueOfTransactionsQuery } from "generated";

import { useCashOperationsData } from "../../hooks";

import type { Machine } from "./MachineView";

type Props = {
  nodeId: Machine["nodeId"];
};

type WidgetsProps = {
  currency: string | undefined;
  deposit: number;
  dispense: number;
  changeNetCash: number;
  changeAvailableCash: number;
};

const Widgets: React.FC<WidgetsProps> = ({ currency, deposit, dispense, changeNetCash, changeAvailableCash }) => (
  <div tw="grid gap-4 grid-cols-2 xl:grid-cols-4">
    <TooltipValueWitdget
      tooltipText="machine.totalDepositsTooltip"
      title="machine.totalDeposits"
      currency={currency}
      value={deposit}
    />
    <TooltipValueWitdget
      tooltipText="machine.totalDispensesTooltip"
      title="machine.totalDispenses"
      currency={currency}
      value={dispense}
    />
    <TooltipValueWitdget
      tooltipText="machine.changeNetCashTooltip"
      title="machine.changeNetCash"
      currency={currency}
      value={changeNetCash}
    />
    <TooltipValueWitdget
      tooltipText="machine.changeAvailableCashTooltip"
      title="machine.changeAvailableCash"
      value={changeAvailableCash || 0}
      intlStyle="percent"
    />
  </div>
);

const startOfToday = startOfDay(new Date()).toISOString();

export const MachineUsage: React.FC<Props> = ({ nodeId }) => {
  const { currency, setCurrency, currencies = [], summary, zoneId } = useCashOperationsData(nodeId || "");
  const [transactionDateTime] = useQueryByDateFilter("transactions");
  const { summary: historicSummary } = useCashOperationsData(nodeId || "", startOfToday);
  const changeNetCash = historicSummary.availableForDispense + historicSummary.availableToBank;
  const difference = summary.availableForDispense - historicSummary.availableForDispense;
  const changeAvailableCash = difference === 0 ? 0 : historicSummary.availableForDispense / difference;
  const { data } = useGetSumValueOfTransactionsQuery({
    skip: !currency || !nodeId,
    variables: {
      currency: currency || "",
      dateTime: startOfToday,
      machineNodeId: nodeId,
      transactionDateTime,
      zoneId,
    },
  });

  return (
    <PageLayout title="machine.usage" subtitle="machine.machineUsageDescription">
      <div tw="flex justify-end mb-4 gap-2">
        <SelectRaw
          tw="min-w-[65px]"
          name="currency"
          label="common.currency"
          options={currencies}
          value={currency}
          onChange={setCurrency}
          variant="dropdown"
        />
      </div>
      <Widgets
        currency={currency}
        deposit={data?.deposit ?? 0}
        dispense={data?.dispense ?? 0}
        changeNetCash={changeNetCash}
        changeAvailableCash={changeAvailableCash}
      />
    </PageLayout>
  );
};
