import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "report.title",
    route: "REPORTS",
  },
  {
    text: "cashMgmt.title",
    route: "CASH_MANAGEMENT",
  },
  {
    text: "cashTotals",
    route: "CASH_TOTALS",
  },
];
