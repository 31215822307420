import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindAllDistinctRolesQuery } from "generated";

type RolesOption = { value: string; label: string };

const defaultRoles: RolesOption[] = [
  { value: "administrator", label: "mu.administrator" },
  { value: "cashier", label: "mu.cashier" },
  { value: "cit", label: "mu.cit" },
];

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const fetchedRoles: RolesOption[] =
      d?.findAllDistinctRoles?.flatMap((role) => (role ? { label: role, value: role } : [])) ?? [];
    const rolesWithoutDefault = fetchedRoles.filter((r) => !defaultRoles.some((d) => d.value === r.value));

    const options = [...defaultRoles, ...rolesWithoutDefault].sort(makeSorter("label"));

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllDistinctRolesQuery());

  return [data, !loading];
};

export const getMachineUserRoleField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "mu.role",
      isLoading: true,
      ...value,
    },
    useConfig,
  );
