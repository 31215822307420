import countryList from "country-list/data.json";
import { useMemo } from "react";

import { NodeStatusBadge } from "base/components";
import { CommonCells, Table, TableTypes, accessors, localFilters, usePagination } from "common/guideline";
import { countryCodes } from "common/helpers/countryCodes";
import { useFindAllLocationsFilteredQuery } from "generated";
import { useTranslation } from "i18n";
import { useColumnFilters } from "report/components";
import { useSorting } from "report/hooks";

import { LocationVerticalHeader } from "./LocationVerticalHeader";
import { RowActions } from "./RowActions";
import { LocationData } from "./types";

const columns: TableTypes.TranslatedColumns<LocationData> = (t) => [
  {
    id: "siteId",
    header: t("location.siteId"),
    accessorKey: "nodeId",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "name",
    header: t("location.name"),
    accessorKey: "name",
    meta: {
      hideVertical: true,
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "description",
    header: t("location.description"),
    accessorKey: "description",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "country",
    header: t("location.country"),
    accessorFn: (a) => countryList.find((c) => c.code === a.country)?.name,
    ...localFilters.getSelectBaseFilter(
      false,
      countryCodes.map((c) => ({ value: c.countryCode, label: t(`countries.${c.countryCode}`) })),
    ),
  },
  {
    id: "countryCode",
    header: t("location.countryCode"),
    accessorFn: (a) => a.country,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "city",
    header: t("location.city"),
    accessorFn: (a) => a.city,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "longitude",
    header: t("location.longitude"),
    accessorKey: "longitude",
  },
  {
    id: "latitude",
    header: t("location.latitude"),
    accessorKey: "latitude",
  },
  {
    id: "customerReference",
    header: t("location.customerReference"),
    accessorKey: "customerReference",
  },
  {
    id: "nodeChangeDate",
    header: t("location.nodeChangeDate"),
    accessorFn: (d) => accessors.date(d.nodeChangeDate, t),
  },
  {
    id: "nodeStatus",
    header: t("location.nodeStatus"),
    accessorKey: "nodeStatus",
    cell: NodeStatusBadge,
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "sites";

const valueToArray = (v) => (v ? [v] : v);

const columnFiltersData = [
  ["nodeIds", "siteId", valueToArray],
  ["name", "name"],
  ["description", "description"],
  ["countryCodes", "country", valueToArray],
  ["countryCodes", "countryCode", valueToArray],
  ["cities", "city", valueToArray],
] as const;

export const LocationsTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const [filters, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllLocationsFilteredQuery({
    variables: {
      locationFilters: filters,
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });

  const result = (data?.findAllLocationsFiltered?.result ?? []) as LocationData[];
  const fullSize = data?.findAllLocationsFiltered?.fullSize ?? 0;

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={result}
      VerticalHeader={LocationVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPagination={setPagination}
      totalCount={fullSize}
      columnFilters={columnFilters}
      onFilter={setColumnFilters}
      sorting={sorting}
      onSorting={setSorting}
    />
  );
};
