export const en = {
  ota: {
    title: "OTA",
    list: "OTA list",
    listPageDesc: "The purpose of OTA codes is to limit the access to a device to when it is needed, and only once.",
    deactivateTitle: "Deactivate OTA authorization",
    deactivateDescription:
      "Are you sure you want to deactivate this OTA? Deactivating OTA authorization will prevent Connect users from generating new OTA codes. OTA codes that were generated below will remain valid until they expire or until they expire or until they are used for machine-side authentication",
    connectUser: "Connect user",
    machineUserId: "Machine user ID",
    machineUserName: "Machine user name",
    machineUserRole: "Machine user role",
    customRole: "Custom role",
    machineName: "Machine name",
    authorization: "Authorization",
    authorizedMachines: "Authorized machines",
    authorizedSites: "Authorized sites",
    authorizedMachineGroups: "Authorized machine groups",
    expiration: "Expiration",
    validityTime: "Valid for",
    validityTimeInterval: "Valid for (dd)d:hh:mm",
    nodeStatus: "Node status",
    customData: "Custom data",
    create: "Create OTA authorization",
    createDesc: "Create a new OTA authorization",
    deleteAt: "Delete at",
    deleteAfterFirstUse: "Delete after first use",
    key: "Key",
    value: "Value",
    failedToGetCode: "Failed to get code",
    authorizationTypes: {
      allMachines: "All machines",
      oneMachine: "Machine",
      oneSite: "Site",
      oneSiteGroup: "Site group",
    },
    createSuccess: "OTA authorization created successfully",
    createError: "Failed to create OTA authorization",
    deactivateSuccess: "OTA authorization deactivated successfully",
    deactivateError: "Failed to deactivate OTA authorization",
  },
};
