import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "administration.title",
    route: "ADMINISTRATION",
  },
  {
    text: "administration.posTransactions",
    route: "POS_TRANSACTIONS",
  },
];
