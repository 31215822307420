import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { GetAllRulesDocument, useDeleteRulesMutation } from "generated";
import { Trans } from "i18n";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const DeleteAlert: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteRule, { loading }] = useDeleteRulesMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "alerts.deleteSuccess" : "alerts.deleteError", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deleteRule({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => evictQuery(GetAllRulesDocument, cache),
      });
      if (result.data?.deleteRules) onClose?.();
      showToast(!!result.data?.deleteRules);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="alerts.deleteAlert" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="alerts.deleteDescription" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};
