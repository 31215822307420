import { Plus, Trash } from "@phosphor-icons/react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getRef, useFieldArray } from "common/form";
import { CustomRenderFields, isValidPhoneNumber } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { getAllNumbersBetweenDotsFromString, isEmail } from "common/helpers";

const Wrapper = styled.div`
  ${tw`flex-1`}
`;

const RecipientsComponent: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div>
    <Text tKey="alerts.recipients" tw="block pb-2" />
    <div tw="grid [grid-template-columns:max-content_1fr_max-content] gap-2 items-end">{children}</div>
  </div>
);

const Row: React.FC<React.PropsWithChildren<{ fieldArrayName: string; index: number }>> = ({
  children,
  fieldArrayName,
  index,
}) => {
  const {
    fields: { length },
    remove,
    append,
  } = useFieldArray(fieldArrayName);

  return (
    <>
      {children}
      <div tw="flex gap-2">
        {length > 1 && (
          <Button variant={["error", "smSquare"]} onClick={() => remove(index)} data-test={`removeRecipient-${index}`}>
            <Trash weight="duotone" />
          </Button>
        )}
        {index + 1 === length ? (
          <Button variant={["primary", "smSquare"]} onClick={() => append({})} data-test={`addRecipient-${index}`}>
            <Plus weight="duotone" />
          </Button>
        ) : null}
      </div>
    </>
  );
};

const triggerTypeOptions = [
  {
    label: "alerts.triggerType.EMAIL",
    value: "EMAIL",
  },
  {
    label: "alerts.triggerType.SMS",
    value: "SMS",
  },
  {
    label: "alerts.triggerType.WEBHOOK",
    value: "WEBHOOK",
  },
  {
    label: "alerts.triggerType.WEBHOOK_SNS",
    value: "WEBHOOK_SNS",
  },
  {
    label: "alerts.triggerType.WEBHOOK_URL",
    value: "WEBHOOK_URL",
  },
] as const;

type TriggerTypeOption = typeof triggerTypeOptions[number]["value"];

export const recipientsField: CustomRenderFields = {
  type: "array",
  name: "recipients",
  Component: RecipientsComponent,
  Row,
  defaultValue: [{}],
  validate: {
    type: "array",
    minLength: 1,
    of: { type: "object", shape: { contact: { type: "string", required: true } } },
  },
  fields: [
    {
      type: "select",
      name: "triggerType",
      label: "alerts.type",
      defaultValue: "EMAIL",
      options: triggerTypeOptions,
      calculation: {
        updates: (_, fieldName) => ({
          [`recipients.${getAllNumbersBetweenDotsFromString(fieldName ?? "")[0]}.contact`]: undefined,
        }),
      },
    },
    {
      type: "container",
      Component: Wrapper,
      fields: [
        {
          type: "condition",
          when: (arrayIndex = 0) => `recipients.${arrayIndex}.triggerType`,
          is: "SMS",
          fields: [
            {
              type: "phone",
              name: "contact",
              label: "alerts.contact",
              validate: (v) => (isValidPhoneNumber(v ?? "") ? null : "common.form.err.invalidPhone"),
            },
          ],
        },
        {
          type: "condition",
          when: (arrayIndex = 0) => `recipients.${arrayIndex}.triggerType`,
          is: (v) => v !== "SMS",
          fields: [
            {
              type: "text",
              name: "contact",
              label: "alerts.contact",
              validate: (value, formData, fieldName) => {
                if (!value) return "common.form.err.required";

                const recipientIndex = getAllNumbersBetweenDotsFromString(fieldName ?? "")[0];

                return getRef<TriggerTypeOption>(`values.recipients.${recipientIndex}.triggerType`)(formData) ===
                  "EMAIL" && !isEmail(value)
                  ? "common.form.err.invalidEmail"
                  : null;
              },
            },
          ],
        },
      ],
    },
  ],
};
