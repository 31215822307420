import { useEffect, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Badge, Text } from "common/guideline";
import { LineChart } from "components/Charts";
import { useMockContext } from "personalized/pages/Insights/MockContext";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

export const CashInCirculation = () => {
  const [mockedValue, setMockedValue] = useState(true);
  const { site } = useMockContext();

  const linePrimary = [
    { x: "Dec", y: 24, helper: "Chicago" },
    { x: "Jan", y: mockedValue ? 39 : 30, helper: "Malmö" },
    { x: "Feb", y: 26, helper: "Failed sessions" },
  ];

  const lineSide = [
    { x: "Dec", y: 33, helper: "Chicago" },
    { x: "Jan", y: mockedValue ? 35 : 42, helper: "Malmö" },
    { x: "Feb", y: 64, helper: "Failed sessions" },
  ];

  useEffect(() => {
    setMockedValue((p) => !p);
  }, [site]);

  return (
    <Box>
      <div tw="flex flex-col">
        <BoxHeading>Cash in circulation</BoxHeading>
        <div tw="h-12 flex justify-center items-center">
          <Text variant="md">8,925</Text>
          <Badge tw="ml-1.5">USD</Badge>
        </div>
        <div tw="h-40">
          <LineChart linePrimary={linePrimary} lineSide={lineSide} />
        </div>
      </div>
    </Box>
  );
};
