import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as MapPinSVG } from "assets/icons/MapPin.svg";
import { PageLayout } from "base/components";
import { Button, Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";
import { LocationsTable } from "./LocationsTable/LocationsTable";

export const Locations = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="location.location_other"
    subtitle="administration.locationsDescription"
    Icon={MapPinSVG}
    actions={
      <Link route="LOCATION_CREATE">
        <Button>
          <Text tKey="location.create" />
        </Button>
      </Link>
    }
  >
    <LocationsTable />
  </PageLayout>
);
