import { CustomRenderFields } from "common/form/renderFields";
import { getSelectLocationsField } from "location/components";
import { getMachinesFilterField } from "machine/components";

export const getCommonFields = (withMachinesField = true): CustomRenderFields[] => [
  getSelectLocationsField({
    name: "locationNodeIds",
    label: "location.location",
    isMulti: true,
    isClearable: true,
    defaultValue: [],
  }),
  ...(withMachinesField
    ? [
        getMachinesFilterField({
          name: "machineNodeIds",
          label: "machine.machine",
          isClearable: true,
          isMulti: true,
        }),
      ]
    : []),
];
