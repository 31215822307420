import { useCallback } from "react";

import { AnyObject, FormProvider, useForm } from "../form";

import { FormProps, FormPropsRaw } from "./types";
import { useFormGenerator } from "./useFormGenerator";

function FormComponent<FORM_DATA = AnyObject>({
  dom,
  SubmitComponent,
  children,
  formProps,
  ...formData
}: FormPropsRaw<FORM_DATA>) {
  const form = useForm<FORM_DATA>(formData);
  const submit = form.useStore(useCallback((s) => s.submit, []));

  return (
    <FormProvider form={form}>
      {typeof children === "function" ? (
        children(dom, form)
      ) : (
        <form {...formProps} onSubmit={submit}>
          {dom}
          {children}
          {SubmitComponent ? <SubmitComponent /> : <button>Submit</button>}
        </form>
      )}
    </FormProvider>
  );
}

export function SchemaForm<FORM_DATA = AnyObject>({
  fields,
  customRender,
  FormComponent: FormComponentRaw = FormComponent,
  ...rest
}: FormProps<FORM_DATA>) {
  const dom = useFormGenerator(fields, customRender);
  return <FormComponentRaw {...rest} dom={dom} />;
}
