import { Trans } from "react-i18next";

import { EntityDelete } from "base/components";
import { useModal, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindAllMachineUsersFilteredDocument,
  FindUserBalancesFilteredDocument,
  useDeleteMachineUsersMutation,
} from "generated";

type Props = {
  nodeIds: string[];
  count: number;
  onClose?: () => void;
};

export const DeleteMachineUsersBulk: React.FC<Props> = ({ nodeIds, count, onClose }) => {
  const [deleteMachineUsers, { loading }] = useDeleteMachineUsersMutation();

  const handleSubmit = async () => {
    const showToast = (error: boolean) =>
      useToast.actions.show(error ? "mu.deleteErrorBulk" : "mu.deleteSuccessBulk", {
        variant: error ? "error" : "success",
      });
    try {
      const result = await deleteMachineUsers({
        variables: { nodeIds },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(FindUserBalancesFilteredDocument, cache);
          evictQuery(FindAllMachineUsersFilteredDocument, cache);
          evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, { getInnerOperationName: true });
        },
      });
      const isSuccessResult = typeof result.data?.deleteMachineUsers === "number";
      if (isSuccessResult) {
        onClose?.();
      }
      showToast(!isSuccessResult);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete actionText="mu.deleteBulk" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Trans i18nKey="mu.deleteDescBulk" values={{ count }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const showDeleteMachineUsersBulkModal = (nodeIds: string[]) =>
  useModal.actions.open({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: <DeleteMachineUsersBulk nodeIds={nodeIds} count={nodeIds.length} />,
  });
