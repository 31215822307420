import { cloneElement } from "react";

import { StateModalData, WrapperProps, useModal } from "./useModal";

type ModalProps = {
  modal: StateModalData<unknown>;
  DefaultWrapper: React.FC<WrapperProps<any>>;
};

const Modal: React.FC<ModalProps> = ({
  DefaultWrapper,
  modal: { modalProps, Component, Wrapper = DefaultWrapper },
}) => (
  <Wrapper {...modalProps}>
    {typeof Component === "object" ? (
      // we are passing onClose prop component writter alredy as jsx
      cloneElement(Component, { onClose: modalProps.requestClose })
    ) : Component ? (
      <Component onClose={modalProps.requestClose} />
    ) : null}
  </Wrapper>
);

export function ModalProvider<T>({ DefaultWrapper }: { DefaultWrapper: React.FC<WrapperProps<T>> }) {
  const modals = useModal((state) => state.modals);

  return (
    <>
      {modals.map(([id, modal]) => (
        <Modal key={id} modal={modal} DefaultWrapper={DefaultWrapper} />
      ))}
    </>
  );
}
