import { RouteConfig, RouteValue } from "routing";

import { Login } from "./pages";

export type AuthPaths = {
  LOGIN: RouteValue;
};

export const AUTH: RouteConfig<AuthPaths> = {
  LOGIN: {
    path: "/login",
    Component: Login,
  },
};
