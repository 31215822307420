import countryList from "country-list/data.json";

import { SelectLiveFieldValueArg, getSelectField } from "base/fields";
import { makeSorter } from "common/helpers";

const options = countryList.map((v) => ({ value: v.code, label: v.name })).sort(makeSorter("label"));

export const getSelectCountryField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "base.country",
    options,
    ...values,
  });
