import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { SelectRaw } from "common/form/renderFields";
import { useBooleanFilter } from "report/components";

import { defaultTimeZone, timeZonesOptions } from "../helpers";

export const useTimeZoneFilter = (defaultValue = true): [timeZone: string | undefined, filter: React.ReactNode] => {
  const [timeZone, setTimeZone] = useState<string | undefined>(defaultTimeZone);
  const [showTimeZone, ShowTimeZoneSelect] = useBooleanFilter("showTimeZone", "base.selectTimeZone", defaultValue);

  return [
    showTimeZone ? timeZone : undefined,
    <div tw="flex gap-2" key="zoneId">
      {ShowTimeZoneSelect}
      {showTimeZone ? (
        <SelectRaw
          tw="w-40"
          label="base.timeZone"
          name="zoneId"
          value={timeZone}
          options={timeZonesOptions}
          onChange={setTimeZone}
        />
      ) : null}
    </div>,
  ];
};
