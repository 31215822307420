import { LiveField, UseLiveConfig } from "common/form";
import { CustomRenderFieldTypes, CustomRenderFields, SelectRaw } from "common/form/renderFields";

export type SelectLiveFieldValueArg = Partial<CustomRenderFieldTypes["select"]> & { name: string };

// eslint-disable-next-line
// @ts-ignore
export const getSelectField = (value: SelectLiveFieldValueArg): CustomRenderFieldTypes["select"] => ({
  type: "select",
  placeholder: "common.select",
  options: [],
  selectAllOption: value.isMulti,
  labelCtx: { count: value.isMulti ? 0 : 1 },
  ...value,
});

export type SelectLiveFieldUseConfig = UseLiveConfig<CustomRenderFieldTypes["select"]>;

export const getSelectLiveField = (
  value: SelectLiveFieldValueArg,
  useConfig: SelectLiveFieldUseConfig,
): LiveField<CustomRenderFields> => ({
  type: "live",
  useConfig: useConfig as UseLiveConfig<CustomRenderFields>,
  name: value.name,
  Fallback: SelectRaw as React.FC,
  fieldConfig: getSelectField(value),
});
