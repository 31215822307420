// extend history to allow get previous route
import { BrowserHistory } from "history";

import { ExtendedBroweserHistoryState } from "./types";

export type PreviousRoute = null | {
  location: BrowserHistory["location"];
  action: BrowserHistory["action"];
};
export type PreviousRouteState = { previousPath?: string };

export const withPreviousRoute = <T extends BrowserHistory>(
  history: T,
): ExtendedBroweserHistoryState<T, PreviousRouteState> & {
  getPreviousRoute: () => PreviousRoute;
  clearPreviousRoute: () => void;
} => {
  let currentRoute: PreviousRoute = {
    action: history.action,
    location: history.location,
  };
  let previousRoute: PreviousRoute = null;

  history.listen((previous) => {
    previousRoute = currentRoute;
    currentRoute = previous;
  });

  // IMPORTANT Object.assign instead of spread - for history to get reference instead of new object
  return Object.assign(history, {
    getPreviousRoute: (): PreviousRoute => previousRoute,
    clearPreviousRoute: () => (previousRoute = null),
  });
};
