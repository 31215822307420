import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const Permissions = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.permissions"
    subtitle="administration.permissionsDescription"
  />
);
