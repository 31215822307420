import { CaretDown, Trash } from "@phosphor-icons/react";
import { TableState } from "@tanstack/react-table";
import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Badge } from "../Badge";
import { Button } from "../Button";
import { Popover } from "../Popover";
import { Text } from "../Text";

const getSelectedRowsManipulate = (onDelete: (rows: string[]) => void) => {
  const SelectedRowsManipulate: React.FC<{ getState: () => TableState }> = ({ getState }) => {
    const rowSelection = getState().rowSelection;
    const selectedRowsCount = Object.keys(rowSelection).length;

    return selectedRowsCount === 0 ? null : (
      <Popover
        content={(close) => (
          <Button
            variant={["ghost", "sm", "withIcon"]}
            tw="text-error-default"
            onClick={() => {
              close();
              onDelete(Object.entries(rowSelection).flatMap(([k, v]) => (v ? k : [])));
            }}
          >
            <Text tKey="common.delete" />
            <Trash size={18} />
          </Button>
        )}
      >
        <Badge tw="text-xs" variant={["info", "text"]}>
          <Text tKey="common.countSelected" tValue={{ count: selectedRowsCount }} />
          <CaretDown tw="text-primary-default w-4 ml-2" size={18} />
        </Badge>
      </Popover>
    );
  };

  return SelectedRowsManipulate;
};

export const useRowSelection = <T,>(onDelete: (rows: string[]) => void, getRowId: (row: T) => string) => {
  const [rowSelection, onRowSelectionChange] = useState({} as Record<string, boolean>);

  return { rowSelection, onRowSelectionChange, getRowId, SelectedRowsManipulate: getSelectedRowsManipulate(onDelete) };
};
