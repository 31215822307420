import { Suspense, SuspenseProps, forwardRef, lazy } from "react";

export const getLazyComponent = <T extends { [x in string]: React.ComponentType<any> }>(
  importer: () => Promise<T>,
  key: keyof T,
) => lazy(() => importer().then((mod) => ({ default: mod[key] })));

export const createSuspenseComponent = <T, REF = any>(
  Component: React.ComponentType<T>,
  fallback: SuspenseProps["fallback"] = null,
) =>
  // eslint-disable-next-line react/display-name
  forwardRef<REF, T>((props, ref) => (
    <Suspense fallback={fallback}>
      <Component {...props} ref={ref} />
    </Suspense>
  ));
