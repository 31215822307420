import { useApolloClient } from "@apollo/client";
import { useMemo, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { SelectRaw } from "common/form/renderFields";
import { Table, usePagination } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindListOfUsersDocument, useFindAllDomainNamesQuery, useFindListOfUsersQuery } from "generated";
import { useTranslation } from "i18n";

import { getColumns } from "./columns";
import { IsUserData } from "./types";

const TABLE_NAME = "domainUsers";

export const DomainUsersTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => getColumns(true)(t, i18n.language), [t, i18n.language]);

  const domainNames = useFindAllDomainNamesQuery().data?.findAllDomainNames;

  const domainName = domainNames?.[0] || "";

  const domains: any[] = [];
  domainNames?.forEach((dn) => {
    domains.push({ label: dn, value: dn });
  });

  const [currentDomain, setDomain] = useState<any>(domains && domains.length > 0 ? domains[0].value : null);

  const domainNameQuery = currentDomain ? currentDomain : domainName;
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useFindListOfUsersQuery({
    skip: !domainNameQuery,
    variables: {
      domainName: domainNameQuery,
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
  });

  const { cache } = useApolloClient();

  return (
    <>
      <div style={{ width: 300, paddingBottom: 8, paddingLeft: 2 }}>
        <SelectRaw
          name="domain"
          label="user.selectDomain"
          options={domains}
          value={currentDomain}
          onChange={(v) => {
            setDomain(v);
            evictQuery(FindListOfUsersDocument, cache);
          }}
          variant={["sm", "dropdown"]}
          labelVariant="row"
        />
      </div>
      <Table
        tableName={TABLE_NAME}
        columns={tColumns}
        data={(data?.findListOfUsers?.isUserDtoOut || []) as IsUserData[]}
        initialLoading={previousData === undefined}
        error={error}
        loading={loading}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPagination={setPagination}
      />
    </>
  );
};
