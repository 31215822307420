import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";
import { Breadcrumb } from "common/guideline";

import { CreateTenantForm } from "../components/CreateTenantForm";

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "TENANT_LIST",
    text: "tenant.list.title",
  },
  {
    route: "TENANT_CREATE",
    text: "tenant.create.title",
  },
];

export const CreateTenantScreen = () => {
  if (!usePermissionAccess("canCreateTenant")) {
    navigateTo({ route: "TENANT_LIST", replace: true });
  }

  return (
    <PageLayout title="tenant.create.title" subtitle="tenant.create.description" breadcrumbs={breadcrumbs}>
      <CreateTenantForm />
    </PageLayout>
  );
};
