import { Fragment, createElement, memo } from "react";

import { FieldProps } from "./types";
import { useField } from "./useField";

const FieldRaw: <T, K>(props: FieldProps<T, K>) => React.ReactElement = ({
  name,
  validate,
  errorSub,
  touchSub,
  dataSub,
  parse,
  parseOnBlur,
  as,
  children,
  format,
  defaultValue,
  calculation,
  activeSub,
  valueSub,
  data,
  ...rest
}) => {
  const field = useField(name, {
    valueSub,
    validate,
    errorSub,
    touchSub,
    dataSub,
    activeSub,
    parse,
    parseOnBlur,
    format,
    defaultValue,
    calculation,
    data,
    type: rest.type,
    value: rest.value,
    multiple: rest.multiple,
  });
  const props = { ...rest, ...field, name };

  return as
    ? createElement(as as string | React.FC<typeof props>, props, children as any)
    : typeof children === "function"
    ? (children?.(props) as React.ReactElement)
    : createElement(Fragment);
};

export const Field = memo(FieldRaw);
