import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { Text } from "../components/Text";

import { ThemeKind, useThemeContext } from "./ThemeContext";

const List = styled.ul<{ theme: ThemeKind }>`
  ${tw`flex gap-x-4 py-4`}

  li {
    ${tw`py-1 cursor-pointer`}
  }

  [data-theme="light"],
  [data-theme="dark"],
  [data-theme="system"] {
    ${tw`p-4 pt-3 rounded-lg hover:bg-gray-3 hover:dark:bg-gray-2 cursor-pointer`}

    &  > div {
      ${tw`mt-2 w-60 rounded border border-gray-3 overflow-hidden`}
    }
  }

  ${({ theme }) => css`
    [data-theme="${theme}"] {
      ${tw`text-primary-default !bg-primary-accent`}
    }
  `}
`;

const Skeleton: React.FC<{ type: Exclude<ThemeKind, "system"> }> = ({ type }) => {
  const [bg, fill, fill2, sidebar, logo] =
    type === "light"
      ? ["#f8fafc", "#cbd5e1", "#e2e8f0", "#334155", "#1a76c7"]
      : ["#121826", "#515861", "#3b434f", "#121826", "#cbd5e1"];

  return (
    <svg width="100%" viewBox="0 0 800 600" style={{ background: bg }} xmlns="http://www.w3.org/2000/svg">
      <rect rx="4" height="31" width="168" y="14" x="9" fill={logo} />
      <rect height="540.99998" width="179" y="59.31034" x="-0.31034" fill={sidebar} />
      <rect rx="4" height="18" width="93" y="72.96554" x="200" fill={fill} />
      <rect rx="8" height="92" width="184" y="104" x="198" fill={fill2} />
      <rect rx="8" stroke={fill} height="359.00001" width="584" y="221.99999" x="199.99999" fill={bg} />
      <rect rx="4" height="20" width="75" y="21" x="667" fill={fill2} />
      <rect rx="8" height="92" width="184" y="104" x="400" fill={fill2} />
      <rect rx="8" height="92" width="184" y="104" x="602" fill={fill2} />
      <ellipse strokeWidth="2" ry="16" rx="16" cy="31" cx="774" stroke="#1a76c7" fill={fill2} />
      <rect height="15" width="582.77547" y="248" x="200.63267" fill={fill2} />
      <rect height="41" width="583.18367" y="306" x="200.18366" fill={fill2} />
      <rect rx="4" height="13" width="105" y="277" x="214" fill={fill} />
      <rect rx="4" height="13" width="105" y="320" x="214" fill={fill} />
      <rect rx="4" height="13" width="105" y="364" x="214" fill={fill} />
      <rect rx="4" height="13" width="63" y="277" x="353" fill={fill} />
      <rect rx="4" height="13" width="63" y="321" x="353" fill={fill} />
      <rect rx="4" height="13" width="63" y="363" x="353" fill={fill} />
      <line y2="59.99935" x2="800.60425" y1="59.99935" x1="177.57474" strokeWidth="1" stroke={fill} fill="none" />
      <rect rx="4" height="13" width="105" y="75.6201" x="11.93046" fill={fill} />
      <rect rx="4" height="13" width="105" y="105.96502" x="11.2408" fill={fill} />
      <rect rx="4" height="13" width="105" y="165.27553" x="11.93046" fill={fill} />
      <rect rx="4" height="13" width="105" y="136.30993" x="33.99949" fill={fill} />
    </svg>
  );
};

const SystemTheme = () => (
  <div tw="w-full relative">
    <div tw="absolute w-[55%] left-0 top-0 bottom-0 overflow-hidden">
      <div tw="w-60">
        <Skeleton type="light" />
      </div>
    </div>
    <div>
      <Skeleton type="dark" />
    </div>
  </div>
);

export const ChangeThemeButton = () => {
  const { themeKind, setTheme } = useThemeContext();

  return (
    <List theme={themeKind}>
      <div data-theme="light" onClick={() => setTheme("light")}>
        <Text tKey="common.light" />
        <div>
          <Skeleton type="light" />
        </div>
      </div>
      <div data-theme="dark" onClick={() => setTheme("dark")}>
        <Text tKey="common.dark" />
        <div>
          <Skeleton type="dark" />
        </div>
      </div>
      <div data-theme="system" onClick={() => setTheme("system")}>
        <Text tKey="common.system" />
        <div>
          <SystemTheme />
        </div>
      </div>
    </List>
  );
};
