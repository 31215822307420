import { useMemo } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindAllAvailableLicenseFeatureKeysQuery } from "generated";
import { useTenant } from "tenant/context";

const useConfigByTenant: SelectLiveFieldUseConfig = (prev) => {
  const allowedLicenseFeatureKeys = useTenant(useTenant.actions.getTenant)?.allowedLicenseFeatureKeys;
  const data = useMemo(
    () => ({
      ...prev,
      options:
        allowedLicenseFeatureKeys?.flatMap((f) => (f ? { label: f, value: f } : [])).sort(makeSorter("label")) || [],
    }),
    [allowedLicenseFeatureKeys, prev],
  );

  return [data, true];
};

export const getAllowedLicenseFeatureKeysByTenant = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machine.licensing.key",
      isMulti: false,
      ...value,
    },
    useConfigByTenant,
  );

type LicenseKeyOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: LicenseKeyOption[] =
      d?.findAllAvailableLicenseFeatureKeys
        ?.flatMap((key) => (key ? [{ label: key, value: key }] : []))
        .sort(makeSorter("label")) ?? [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllAvailableLicenseFeatureKeysQuery());

  return [data, !loading];
};

export const getAvailabeLicenseFeatureKeysField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "machine.licensing.allowedLicenseFeatureKeys",
      isMulti: true,
      ...value,
    },
    useConfig,
  );
