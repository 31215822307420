import { SortingState } from "@tanstack/react-table";
import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";
import { Table, TableTypes, accessors } from "common/guideline";
import { useGetDeploymentsForMachineNodeIdQuery } from "generated";
import { useTranslation } from "i18n";
import type { Machine } from "machine/components/MachineView";
import { DeploymentFetched } from "zts/types";

type Props = {
  machine: Machine;
};

const getColumns: TableTypes.TranslatedColumns<DeploymentFetched> = (t) => [
  {
    header: t("zts.template.name"),
    minSize: 200,
    accessorKey: "template.name",
  },
  {
    header: t("zts.machineNodeId"),
    accessorKey: "machineNodeId",
  },
  {
    header: t("zts.template.createdBy"),
    accessorKey: "template.createdBy",
  },
  {
    header: t("zts.template.description"),
    accessorKey: "template.description",
  },
  {
    header: t("zts.template.fileName"),
    minSize: 200,
    accessorKey: "template.fileName",
  },
  {
    header: t("zts.template.parts"),
    accessorKey: "template.parts",
  },
  {
    header: t("zts.template.version"),
    accessorKey: "template.version",
  },
  {
    header: t("zts.current"),
    accessorKey: "current",
  },
  {
    header: t("zts.latestStatusChange"),
    accessorFn: (v) => accessors.date(v.statusChangedDate, t),
    id: "statusChangedDate",
    sortingFn: "dateString",
  },
  {
    header: t("zts.scheduleInfo"),
    accessorKey: "scheduleInfo",
  },
  {
    header: t("zts.executedBy"),
    accessorKey: "createdBy",
  },
  {
    header: t("zts.result"),
    accessorKey: "state",
  },
];

const initialSorting: SortingState = [{ id: "statusChangedDate", desc: true }];

const ConfigurationDeploymentsTable: React.FC<Props> = ({ machine }) => {
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGetDeploymentsForMachineNodeIdQuery({
    skip: !machine.uuid,
    variables: {
      machineUuid: machine.uuid as string,
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.getDeploymentsForMachineNodeId || []) as DeploymentFetched[];

  return (
    <Table<DeploymentFetched>
      tableName="configurationDeployments"
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      initialSorting={initialSorting}
    />
  );
};

export const ConfigurationDeployments: React.FC<Props> = ({ machine }) => (
  <PageLayout
    title="zts.remoteConfiguration.configurationHistory"
    subtitle="zts.remoteConfiguration.configurationHistoryDesc"
  >
    <ConfigurationDeploymentsTable machine={machine} />
  </PageLayout>
);
