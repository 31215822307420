import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as TransportSVG } from "assets/icons/Transport.svg";
import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";
import { TransportFilters } from "./TransportFilters";
import { TransportWidgets } from "./TransportWidgets";

export const Transport = () => (
  <TransportFilters>
    {(filters) => (
      <PageLayout
        breadcrumbs={breadcrumbs}
        withPicker
        title="transport.title"
        subtitle="transport.desc"
        Icon={TransportSVG}
        actions={filters}
      >
        <TransportWidgets />
      </PageLayout>
    )}
  </TransportFilters>
);
