import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as LogoSVG } from "assets/img/PayComplete_Logo.svg";

export const Logo = () => (
  <Link route="HOME" tw="flex justify-center items-center w-32 md:w-64 text-primary-default">
    <LogoSVG />
  </Link>
);
