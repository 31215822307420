import { format, isValid } from "date-fns";

import { isBetween } from "./number";

export const TIME_FORMAT = "HH:mm";
export const DURATION_FORMAT = "HH:mm:ss";

// parsing date as yyyy-MM-dd to yyyy/MM/dd
export const parseDateDashToSlash = (date: string) => date.replace(/-/g, "/");

export const getDate = <T extends Date | undefined>(dateParsed: string | number | undefined, defaultDate?: T): T => {
  const date = dateParsed ? new Date(dateParsed) : dateParsed;
  return (isValid(date) ? date : defaultDate) as T;
};

export const getMinMaxDates = (times: number[]): [] | [Date] | [Date, Date] =>
  times.length === 0
    ? []
    : times.length === 1
    ? [new Date(times[0])]
    : [new Date(Math.min(...times)), new Date(Math.max(...times))];

// round minutes to whole hour, if without direction `Math.round` is used, if direction - hour is always rounded to ceil or floor
export const roundHours = (date: Date | string | number, direction?: "ceil" | "floor"): Date => {
  const _date = new Date(date);

  if (direction !== "floor") {
    _date.setHours(_date.getHours() + (direction === "ceil" ? 1 : Math.round(_date.getMinutes() / 60)));
  }

  _date.setMinutes(0, 0, 0);
  return _date;
};

export const isoDateWithoutTime = (date: Date): string => dateToISOLocal(date).split("T")[0];

// sv locale is close to iso format, and this function create date time in format the same as for `date.toISOString()` but for local user timezone (without Z at the and and time for current browser time)
export const dateToISOLocal = (date: Date | string, timeZone?: string) =>
  // fixed Safari parsing error by replacing space with 'T'
  format(new Date(new Date(date).toLocaleString("sv", { timeZone }).replace(" ", "T")), "yyyy-MM-dd'T'HH:mm:ss.SSS");

// local month and day
const PPnotYear = (date: Date, locale: Locale, options?: Intl.DateTimeFormatOptions) =>
  date.toLocaleDateString(locale.code, {
    month: "short",
    day: "numeric",
    ...options,
  });

export const customFormatters = {
  // local month and day period - if dates same year - then first is shown without a year
  PPperiod: (dates: Date[], locale: Locale, hour12?: boolean) => {
    const options = locale ? { locale } : undefined;

    return dates.length === 0
      ? "-"
      : dates.length === 1 || !dates[1] || !dates[0]
      ? format(!dates[0] ? dates[1] : dates[0], "PP", options)
      : `${
          dates[0].getFullYear() === dates[1].getFullYear()
            ? PPnotYear(dates[0], locale, { hour12 })
            : format(dates[0], "PP", options)
        } - ${format(dates[1], "PP", options)}`;
  },
  // same as PPperiod, but with time
  PPp_period: (dates: Date[], locale: Locale, hour12?: boolean, formatString = "PPp") => {
    const options = locale ? { locale } : undefined;

    return dates.length === 0
      ? "-"
      : dates.length === 1 || !dates[1] || !dates[0]
      ? format(!dates[0] ? dates[1] : dates[0], formatString, options)
      : `${
          dates[0].getFullYear() === dates[1].getFullYear()
            ? PPnotYear(dates[0], locale, { hour: "numeric", minute: "2-digit", hour12 })
            : format(dates[0], formatString, options)
        } - ${format(dates[1], formatString, options)}`;
  },
};

const timeZoneUtcOffsetRegExp = /^(-|\+)?(\d{1,2})(\.)?/;

export const getTimeZoneUtcOffset = (val: string | undefined | Date = "") => {
  const v = val instanceof Date ? (val.getTimezoneOffset() / 60).toString() : val;
  const [, sign = "+", _offset] = v.match(timeZoneUtcOffsetRegExp) || [];

  if (!_offset || !isBetween(-23, 23, Number(_offset))) return "+00:00";

  const offset = _offset.length === 2 ? _offset : `0${_offset}`;

  return `${sign}${offset}:00`;
};

export const TIME_ZONE_UTC_OFFSET_REG_EXP = /^(-|\+)?(\d{2})(:00)/;

// represents java ZonedDateTime object: https://docs.oracle.com/javase/8/docs/api/java/time/ZonedDateTime.html
// example: "2023-01-01T11:11:11.111+01:00", "2023-01-01T11:11:11.111Z"
export type ZonedDateTime = string;

const ZONE_REGEXP = /(?<zone>\[(.*?)\])/;
const ZONE_AND_OFFSET_REGEXP = /(?<offset>(\+|-)[0-9]{2}:[0-9]{2})(?<zone>\[(.*?)\])?/;
const UTC_ZONE_REGEXP = /Z\[?/;
const ETC_TIME_REGEXP = /[0-9]Z\[?/;
export const splitDateAndZone = (dateString: ZonedDateTime): [dateTime: string, offset?: string, zone?: string] => {
  const datePart = dateString.split(/(\+|-[0-9]{2}:)/)[0];
  const result = ZONE_AND_OFFSET_REGEXP.exec(dateString);

  if (result) {
    const offset = result.groups?.offset;
    const zone = result.groups?.zone;

    return [datePart, offset, zone];
  } else if (ETC_TIME_REGEXP.exec(dateString)) {
    const result2 = ZONE_REGEXP.exec(dateString);
    const zone = result2?.groups?.zone;
    return [datePart.split(UTC_ZONE_REGEXP)[0], "+00:00", zone];
  }

  return [datePart];
};

export function getLocalTime(): string {
  const now = new Date();
  const hh = now.getHours().toString().padStart(2, "0");
  const mm = now.getMinutes().toString().padStart(2, "0");
  const ss = now.getSeconds().toString().padStart(2, "0");
  return `${hh}:${mm}:${ss}`;
}

export const is12HourTimeFormat = () => {
  const testDate = new Date();
  testDate.setHours(20, 0, 0);
  const formattedTime = testDate.toLocaleTimeString();
  return formattedTime.includes("AM") || formattedTime.includes("PM");
};

type DateRange = {
  days: number;
  hours: number;
  minutes: number;
};

export const daysHoursAndMinutesToMinutes = (dateRange: DateRange) => {
  const daysInMinutes = dateRange.days * 24 * 60;
  const hoursInMinutes = dateRange.hours * 60;
  return daysInMinutes + hoursInMinutes + dateRange.minutes;
};

export const minutesToDaysHoursAndMinutes = (minutes: number): DateRange => {
  const days = Math.floor(minutes / (24 * 60));
  const hours = Math.floor((minutes - days * 24 * 60) / 60);
  const remainingMinutes = minutes - days * 24 * 60 - hours * 60;
  return { days, hours, minutes: remainingMinutes };
};
