import { ActionButton } from "base/components";
import { useModalComponent } from "common/guideline";

import { DeactivateLaneAllocation } from "../components/DeactivateLaneAllocation";

import { RowLaneAllocationDataProps } from "./types";

export const RowActions: React.FC<RowLaneAllocationDataProps> = ({ row }) => {
  const [show] = useModalComponent({
    Component: <DeactivateLaneAllocation allocationNodeId={row.original.allocationId as string} />,
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
    </div>
  );
};
