import tw from "twin.macro";
import "styled-components/macro";

import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllMachineGroupsFilteredDocument,
  FindMachinesEligibleToAttachToMgDocument,
  useCreateMachineGroupMutation,
} from "generated";

import { MachineGroupForm } from "../components/MachineGroupForm";

import { breadcrumbs } from "./breadcrumbs";

export const MachineGroupCreate = () => {
  const [createMachineGroup] = useCreateMachineGroupMutation();

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="administration.mg.create"
      subtitle="administration.mg.createDescription"
    >
      <MachineGroupForm
        submitLabel="administration.mg.create"
        onSubmit={({
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          timeOption,
          remoteMgmtOption,
          templateNodeId,
          softwarePackageNodeId,
          scheduleInfo,
          createdBy,
          ...input
        }) =>
          createMachineGroup({
            variables: { input },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindAllMachineGroupsFilteredDocument, cache);
              evictQuery(FindMachinesEligibleToAttachToMgDocument, cache);
            },
          })
            .then(async (v) => {
              if (!v.data?.createMachineGroup?.nodeId) throw new Error();

              useToast.actions.show("administration.mg.success", { variant: "success" });

              performRemoteMgmtOperation(
                remoteMgmtOption,
                v.data?.createMachineGroup?.machines?.map((m) => m?.uuid),
                templateNodeId,
                softwarePackageNodeId,
                scheduleInfo,
                createdBy,
              );

              navigateTo({ route: "MACHINE_GROUPS" });
            })
            .catch(() => useToast.actions.show("administration.mg.error", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
