export const identity = <T>(a: T): T => a;

export type ThrottledFunction<T extends (...args: any) => any> = (...args: Parameters<T>) => ReturnType<T>;
export function throttle<T extends (...args: any) => any>(func: T, limit: number): ThrottledFunction<T> {
  let inThrottle: boolean;
  let lastResult: ReturnType<T>;

  return function (this: any): ReturnType<T> {
    // eslint-disable-next-line
    const args = arguments;
    // eslint-disable-next-line
    const context = this;

    if (!inThrottle) {
      inThrottle = true;

      setTimeout(() => (inThrottle = false), limit);

      // eslint-disable-next-line
      // @ts-ignore
      lastResult = func.apply(context, args);
    }

    return lastResult;
  };
}

export const rafThrottle = <T extends (...args: any[]) => void>(callback: T) => {
  let requestId: number | null = null;

  let lastArgs;

  const later = (context) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (this: any, ...args) {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    if (requestId) {
      cancelAnimationFrame(requestId);
      requestId = null;
    }
  };

  return throttled;
};
