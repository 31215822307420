import { isEmail } from "common/helpers";

import { Recipients, RecipientsRow } from "./Recipients";
import { StepType } from "./types";

export const recipientsStep: StepType = {
  title: "report.sr.recipients",
  section: () => ({
    type: "container",
    fields: [
      {
        type: "array",
        name: "reportEmails",
        Component: Recipients,
        Row: RecipientsRow,
        defaultValue: [{}],
        validate: {
          type: "array",
          minLength: 1,
          of: {
            type: "object",
            shape: {
              email: {
                type: "string",
                required: true,
                custom: (v) => (isEmail(v) ? null : "common.form.err.invalidEmail"),
              },
              subject: { type: "string", required: true },
            },
          },
        },
        fields: [
          {
            type: "email",
            name: "email",
            label: "report.sr.email",
          },
          {
            type: "text",
            name: "subject",
            label: "report.sr.subject",
          },
        ],
      },
    ],
  }),
};
