import { Trans } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ActionButton, EntityDelete } from "base/components";
import { TableTypes, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  GetAllScheduledReportsDocument,
  useDeleteReportRequestMutation,
  useUpdateScheduledReportMutation,
} from "generated";

import { ScheduledReportsWizard } from "../ScheduledReportsForm/WizardForm";

import { ScheduledReportsDataOut } from "./types";

export type RowScheduledReportsDataProps = TableTypes.VerticalHeaderProps<ScheduledReportsDataOut>;

const DeleteScheduledReport: React.FC<{ row: ScheduledReportsDataOut; onClose?: () => void }> = ({ row, onClose }) => {
  const [onDelete, { loading }] = useDeleteReportRequestMutation();

  return (
    <EntityDelete
      actionText="report.sr.delete"
      loading={loading}
      onClose={onClose}
      onDelete={() =>
        onDelete({
          variables: { nodeId: row.nodeId ?? "" },
          ignoreResults: true,
          update: (cache) => {
            evictQuery(GetAllScheduledReportsDocument, cache);
          },
        })
          .then((res) => {
            if (res.data?.deleteReportRequest) {
              useToast.actions.show("report.sr.deleteSuccess", { variant: "success" });
              onClose?.();
            } else {
              useToast.actions.show("report.sr.deleteError", { variant: "error" });
            }
          })
          .catch(() => useToast.actions.show("report.sr.deleteError", { variant: "error" }))
      }
    >
      <Trans i18nKey="report.sr.deleteDesc" values={{ name: row.instanceName }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const RowActions: React.FC<RowScheduledReportsDataProps> = ({ row }) => {
  const [showDelete] = useModalComponent({
    Component: <DeleteScheduledReport row={row.original} />,
  });

  const [onUpdate] = useUpdateScheduledReportMutation();
  const [showUpdate] = useModalComponent({
    modalProps: { variant: ["modal", "flex", "md"], backdropClose: false },
    Component: (
      <ScheduledReportsWizard
        submitLabel="report.sr.update"
        initial={row.original}
        onSubmit={(value) => {
          onUpdate({
            variables: { input: value },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(GetAllScheduledReportsDocument, cache);
            },
          })
            .then((res) => {
              if (res.data?.updateScheduledReport) {
                showUpdate();
                useToast.actions.show("report.sr.updateSuccess", { variant: "success" });
              } else {
                useToast.actions.show("report.sr.updateError", { variant: "error" });
              }
            })
            .catch(() => useToast.actions.show("report.sr.updateError", { variant: "error" }));
        }}
      />
    ),
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => showDelete()} />
      <ActionButton.Edit onClick={() => showUpdate()} />
    </div>
  );
};
