export const es = {
  base: {
    pageTitle: "Connect - $t({{title}})",
    notApplicable: "N/A",
    country: "País",
    printPage: "Imprimir página",
    selectTimeZone: "Seleccionar zona horaria",
    timeZone: "Huso horario",
    queryByDate: {
      label: "Consultar por fecha",
      dateTime: "Fecha y hora",
      received: "Recibido",
      accounting: "Contabilidad",
    },
    nodeStatus: {
      label: "Estado",
      DELETED: "Borrado",
      DETACHED: "Eliminado",
      INACTIVE: "Inactivo",
      ACTIVE: "Activo",
      UNKNOWN: "Desconocido",
    },
    onlineStatus: {
      Offline: "Fuera de linea",
      Online: "En linea",
      CatchingUp: "Poniendose al dia",
      Unknown: "Desconocido",
    },
    terms: {
      definitions: "1. Definiciones",
      useRights: "2. Condiciones de uso",
      useRightsUnordered: "Derechos de uso",
      support: "3. Soporte",
      supportUnordered: "Soporte",
      financialTerms: "4. Condiciones financieras",
      termAndTermination: "5. Plazo y terminacion",
      confidentiality: "6. Confidencialidad",
      indemnification: "7. Indemnizacion",
      serviceLevel: "8. Compromisos, renuncias y limitaciones del nivel de servicio",
      general: "9. General",
      saas: "PayCompleteâ„¢ Software as a Service ('SaaS') Agreement",
      disclaimer:
        "IMPORTANTE - LEA ATENTAMENTE: AL UTILIZAR EL SERVICIO PAYCOMPLETE USTED ACEPTA ESTOS TERMINOS Y CONDICIONES.",
      acuerdo:
        "Este acuerdo de suscripcion de software como servicio PayCompleteâ„¢ AMERICAS LLC., Delaware, USA Empresa con domicilio social en 1475 E Woodfield Rd Suite 104, Schaumburg, IL 60173, USA ('Company'). En el presente contrato se le denominara 'Cliente'. Si utiliza el sistema, o si hace clic en 'Acepto' o realiza cualquier otra acción afirmativa que indique su aceptación del presentetake Acuerdo, entonces ha aceptado estas condiciones. Si usted es un agente o empleado del abonado o usuario previsto, usted declara y garantiza individualmente a la Empresa que está autorizado a vincular a dicha parte al presente Acuerdo. Si no está de acuerdo con este Acuerdo, no está autorizado a utilizar el Sistema.",
      components: "Componentes",
      componentsDesc:
        "'Componentes'hece referencia a los modulos o productos individuales que componen el Sistema. De vez en cuando, se introduciran nuevos componentes o funciones en el sistema, y dichos componentes o funciones pueden estar restringidos a ediciones especificas..",
      customerData: "Datos del cliente",
      customerDataDesc:
        "'Datos del cliente' hace referencia a cualquier informacion, documento, o archivo electronico que se proporcione a la empresa en virtud del presente documento.",
      documentation: "Documentacion",
      documentationDesc: "'Documentacion' hace referencia a la documentacion en linea disponible",
      error: "Error",
      errorDesc:
        "'Error' hace referencia a cualquier fallo material reproducible del Sistema que le impide funcionar de acuerdo con su Documentación.",
      maintenance: "Ventanas de mantenimiento",
      maintenanceDesc:
        "'Ventanas de mantenimiento' hace referencia a mantenimiento estandar o de emergencia. Las ventanas de mantenimiento estandar se publicaran con antelacion en el sitio web de la Empresa al menos 72 horas antes del inicio de la ventana de mantenimiento estandar. El mantenimiento de emergencia se realizara cuando sea necesario. La Empresa hara todo lo posible por publicar los periodos de mantenimiento de emergencia en su sitio web con antelacion, pero es posible que no se notifique con antelacion un periodo de mantenimiento de emergencia.",
      system: "Sistema",
      systemDesc:
        "'Sistema' se refiere al servicio de software por el que el Cliente ha pagado, incluidas las Actualizaciones relacionadas con el mismo que puedan proporcionarse en virtud del presente contrato o cualquier otra derivada de lo anterior. Un sistema esta formado por componentes individuales.",
      serviceAdministrator: "Administrador de servicios",
      serviceAdministratorDesc:
        "'Administrador de servicios' hace referencia a la persona (s) que designe el Cliente para comprar en nombre del Cliente, autorizar a los Usuarios en virtud del Acuerdo, crear cuentas para Usuarios adicionales y administrar de otro modo el uso del Sistema por parte del Cliente.",
      supportDesc:
        "'Support' hace referencia a los servicios continuados prestados por la Empresa en apoyo del Sistema, tal como se definen en la Sección 3 siguiente.",
      update: "Actualizacion",
      updateDesc:
        "'Actualizacion' hace referencia a cualquier parche, corrección de errores, versión, modificación o sucesor del Sistema.",
      user: "Usuario",
      userDesc:
        "'Usuario' se refiere a una persona a quien el Cliente ha concedido acceso para utilizar el Sistema en nombre del Cliente, independientemente de si el Usuario realmente accede o no al Software. Los Usuarios pueden ser empleados, consultores, contratistas o agentes del Cliente.",
      edition: "Edicion",
      editionDesc:
        "'Edicion' hace referencia a la configuración del Sistema cuya licencia se ha concedido al Cliente. Una Edición define los Componentes, características, límites y/o restricciones de uso del Sistema licenciado al Cliente. De vez en cuando, se introducirán nuevos Componentes o características en el Sistema, y dichos Componentes o características pueden estar restringidos a Ediciones específicas. Asimismo, es posible que se introduzcan nuevas Ediciones.",
      effectiveDate: "Fecha entrada en vigor",
      effectiveDateDesc:
        "'Fecha entrada en vigor' es la fecha en la que comienza la suscripción del Cliente a la Edición con licencia del Sistema. El inicio de la vigencia del contrato.",
      useRightsDesc:
        "Durante la vigencia y con sujeción a las condiciones del presente Contrato, la Empresa concede al Cliente el derecho no exclusivo, intransferible y no sublicenciable de permitir que los Usuarios del Cliente utilicen la Edición con licencia del Sistema para los fines comerciales del Cliente. El derecho de uso de la frase anterior se limita al uso por parte del número de Usuarios por el que el Cliente haya pagado. Dichos derechos de uso son intransferibles, excepto en el caso de una transferencia voluntaria de prácticamente todos los activos por parte del Cliente a un cesionario que ejecute el formulario de acuerdo de la Empresa aceptando quedar vinculado por todos los términos y condiciones de este Acuerdo. Todos los derechos sobre el Sistema no concedidos expresamente en el presente documento quedan reservados a la Empresa.",
      license: "Licencia",
      licenseDesc:
        "Licencia y restricciones de uso. El Cliente no podra, a menos que lo permita la legislacion aplicable obligatoria, directa o indirectamente, solo o con otra parte, (i) copiar, desensamblar, aplicar ingenieria inversa o descompilar el Sistema; (ii) modificar, crear trabajos derivados basados en el Sistema o traducirlo; (iii) conceder licencias, vender, alquilar, arrendar, transferir, otorgar derechos sobre el Sistema o explotarlo comercialmente de cualquier otra forma a terceros, ni intentará hacer nada de lo anterior ni hará ni permitirá que terceros hagan o intenten hacer nada de lo anterior, salvo en los casos expresamente permitidos en el presente documento. El Cliente reconoce y acepta que la Empresa poseerá todos los derechos, títulos e intereses sobre todos los derechos de propiedad intelectual (incluidos todos los derivados o mejoras de los mismos) del Sistema y de cualquier sugerencia, solicitud de mejora, comentario, recomendación u otra información proporcionada por el Cliente o cualquiera de los Usuarios del Cliente en relación con el Sistema.",
      blank: "DEJADO INTENCIONADAMENTE EN BLANCO",
      systemUser: "Administrador del sistema; Acceso de usuarios",
      systemUserDesc:
        "El Cliente designará a uno o más Administradores del Sistema. Los Administradores del Sistema serán responsables de gestionar el acceso de los Usuarios, incluyendo la adición y sustracción de Usuarios. El Administrador del Sistema se asegurará de que varios Usuarios no compartan contraseña o nombre de usuario. El Cliente reconoce y acepta que tiene prohibido compartir contraseñas y/o nombres de usuario con usuarios no autorizados.",
      customerDataUseRightsDesc:
        "El Cliente posee todos los derechos, títulos e intereses sobre los Datos del Cliente. Por la presente, el Cliente concede a la Empresa un derecho y una licencia no exclusivos, intransferibles (salvo lo dispuesto en la Sección 9(d) que figura a continuación) y no sublicenciables para utilizar, copiar, transmitir, modificar y mostrar los Datos del cliente únicamente a efectos del uso del Sistema por parte del Cliente.",
      dataCustomer: "Sin datos sensibles; responsabilidades del cliente",
      dataCustomerDesc:
        "El Cliente reconoce que el Sistema no está diseñado para su uso con información protegida de clientes, incluidos, entre otros, historiales médicos, números de tarjetas de crédito, números de cuentas financieras u otra información personal sensible similar, y que el Cliente asume todos los riesgos derivados del uso de dicha información sensible con el Sistema, incluido el riesgo de cualquier divulgación inadvertida o acceso no autorizado a la misma. El Cliente es responsable de garantizar que el uso del Sistema por parte del Cliente y de los Usuarios del Cliente cumple todas las leyes y normativas gubernamentales aplicables, y el Cliente reconoce que asume todos los riesgos derivados de cualquier uso que no cumpla las leyes y normativas aplicables.",
      security: "Seguridad",
      securityDesc:
        "El Cliente es el único responsable de mantener la seguridad de todos los nombres de usuario y contraseñas que se le concedan, de la seguridad de sus sistemas de información utilizados para acceder al Sistema y del cumplimiento por parte de sus Usuarios de los terminos del presente Acuerdo. La Empresa actuara como si cualquier comunicacion electronica que reciba bajo los nombres de usuario del Cliente hubiera sido enviada por este. El Cliente notificará inmediatamente a la Empresa si tiene conocimiento de cualquier pérdida, robo o uso no autorizado de cualquiera de las contraseñas o nombres de usuario del Cliente. La Empresa tendrá derecho a cancelar o suspender en cualquier momento el acceso a cualquier Usuario o Cliente si considera de buena fe que dicha cancelación o suspensión es necesaria para preservar la seguridad, integridad o accesibilidad del Sistema o de la red de la Empresa.",
      servicesGenerally: "Servicios en general",
      servicesGenerallyDesc:
        "Sujeto a los términos de este acuerdo, la Empresa hará todos los esfuerzos comercialmente razonables para poner el Sistema a disposición del Cliente.",
      updates: "Actualizaciones",
      updatesDesc:
        "La Empresa entregará las Actualizaciones del Sistema que se apliquen a la Edición con licencia actual del Cliente sin cargo adicional. De vez en cuando, pueden lanzarse nuevos Componentes o funciones que se apliquen selectivamente a diferentes Ediciones del Sistema. Sólo aquellas Actualizaciones que se apliquen a la Edición con licencia actual del Cliente se entregarán automáticamente al Cliente sin cargo adicional.",
      supportOptions: "Opciones y soporte",
      supportOptionsDesc:
        "La Empresa proporcionará soporte general al Cliente según lo establecido en la Sección de Soporte del sitio web de la Empresa para la Edición actualmente licenciada del Cliente. Las diferentes Ediciones del Sistema tendrán derecho a diferentes niveles de soporte. Además, la Empresa podrá ofrecer al Cliente opciones de soporte premium por un coste adicional.",
      errorCorrection: "Corrección de errores",
      errorCorrectionDesc:
        "La Empresa hará todos los esfuerzos comercialmente razonables para corregir todos los Errores o proporcionar una solución razonable tan pronto como sea posible, haciendo todos los esfuerzos razonables durante el horario laboral normal de la Empresa. El Cliente proporcionará el acceso, la información y la asistencia que la Empresa pueda requerir razonablemente en el proceso de resolución de cualquier Error. Este párrafo es el único y exclusivo recurso del Cliente para los Errores.",
      supportExclusions: "Exclusiones del soporte",
      supportExclusionsDesc:
        "La Empresa no está obligada a corregir ningún Error ni a proporcionar ningún otro tipo de asistencia en la medida en que dichos Errores o necesidad de asistencia hayan sido creados total o parcialmente por:",
      seNestOneDesc:
        "los actos, omisiones, negligencia o dolo del Cliente, incluida cualquier modificación no autorizada del Sistema o de su entorno operativo;",
      seNestTwoDesc:
        "cualquier fallo o defecto del equipo, software, instalaciones, aplicaciones de terceros o conectividad a Internet del Cliente o de un tercero (u otras causas ajenas al cortafuegos de la Empresa);",
      seNestThreeDesc:
        "El uso del Sistema por parte del Cliente de forma distinta a la prevista en la documentación del Sistema; o",
      seNestFourDesc: "un Caso de Fuerza Mayor.",
      supportFees: "Cuotas",
      supportFeesDesc:
        "La Empresa tiene derecho a facturar al Cliente según sus tarifas de servicios estándar por cualquier problema de asistencia excluido por la Sección 3(e) anterior que haya sido previamente aprobado por escrito (incluso en un correo electrónico) por el Cliente.",
      limitationOfRemedies: "Limitación de recursos",
      limitationOfRemediesDesc:
        "La Corrección de Errores, tal y como se define en el presente Acuerdo, es el único recurso del Cliente en caso de Errores en el Sistema.",
      fees: "Tasas",
      feesDesc:
        "A cambio de los productos, servicios y derechos de uso proporcionados por la Empresa al Cliente en virtud del presente, el Cliente pagara a la Empresa las tarifas por el importe establecido. Todos los importes en dolares se refieren a dolares estadounidenses..",
      paymentTerms: "Condiciones de pago",
      paymentTermsDesc:
        "La Empresa facturara al Cliente mensual o anualmente por adelantado todos los cargos recurrentes, facturas que tambien incluiran todos los cargos y gastos no recurrentes incurridos desde la factura anterior. El Cliente deberá pagar todas las facturas de la Empresa en un plazo de 30 días a partir de la fecha de la factura. Si el Cliente se retrasa en el pago de cualquier parte de una factura, la Empresa podra, además de cualquier otro recurso que pueda tener, incluida la rescision, suspender el acceso al",
      systemProvision: "Sistema y/o prestacion de todos los servicios al Cliente",
      systemProvisionDesc:
        "El cliente se compromete a pagar intereses de demora al tipo del 1Â½% mensual (o, si es inferior, la cantidad máxima permitida por la ley) que se demore el pago..",
      taxes: "Impuestos",
      taxesDesc:
        "El Cliente pagara o reembolsara a la Empresa todos los impuestos sobre las ventas y otros impuestos, independientemente de cómo los califique la autoridad tributaria, basados en las tasas de licencia u otros cargos en virtud del presente Contrato o incurridos de otro modo a cuenta del uso del Sistema por parte del Cliente, excepto cualquier impuesto basado en los ingresos netos o brutos de la Empresa o cualquier impuesto de franquicia o sobre consumos específicos adeudado por la Empresa. Si el Cliente es una organización exenta de impuestos, la Empresa no cobrará al Cliente ningún impuesto del que está exento..",
      pricingChanges: "Cambios en los precios",
      pricingChangesDesc:
        "Los clientes que seleccionen precios anuales recibirán notificación de los cambios en los precios al menos 45 días antes de cada aniversario de la Fecha de entrada en vigor. Los clientes que seleccionen precios mensuales recibirán un aviso de los cambios en los precios al menos 45 días antes del mes en el que el cambio de precios entrara en vigor.",
      term: "Plazo",
      termDesc:
        "La vigencia del presente Contrato comienza en la Fecha de entrada en vigor del mismo. Si el Cliente ha elegido un plan de precios anual, la vigencia continuará hasta el primer aniversario de la Fecha de entrada en vigor, y se renovará automáticamente por períodos adicionales de un año cada uno, a menos que una de las partes notifique por escrito a la otra su intencion de no renovarlo con al menos 30 días de antelacion a la fecha de vigencia en ese momento. Si el Cliente ha elegido un plan de precios mensual, este acuerdo continuara hasta que una de las partes notifique a la otra, con al menos 30 dias de antelación, su intencion de rescindirlo.",
      terminationforCause: "Cese por causa justificada",
      terminationforCauseDesc:
        "Cualquiera de las partes podra rescindir el presente Acuerdo por causa justificada, previa notificacion por escrito a la otra parte:",
      tfcNestOneDesc:
        "si una de las partes no paga a la otra las cantidades adeudadas en virtud del presente contrato en un plazo de 10 días a partir de la notificacion por escrito de la otra parte especificando las cantidades adeudadas; en el caso de la Empresa, inmediatamente después de que el Cliente incumpla la Sección 2(b) y/o la Sección 2(e) anteriores;",
      tfcNestTwoDesc:
        "inmediatamente en caso de incumplimiento por la otra parte de las obligaciones de confidencialidad que le incumban;",
      tfcNestThreeDesc:
        "si la otra parte ha cometido cualquier otro incumplimiento sustancial de sus obligaciones en virtud del presente Acuerdo y no ha subsanado dicho incumplimiento en el plazo de 30 días a partir de la notificación por escrito de la parte que no ha incumplido, especificando con detalle razonable la naturaleza del incumplimiento (o, si dicho incumplimiento no es subsanable razonablemente en el plazo de 30 días, no ha comenzado y continuado trabajando diligentemente y de buena fe para subsanar dicho incumplimiento); o",
      tfcNestFourDesc:
        "cuando se incoe un procedimiento de quiebra o insolvencia contra la otra parte, si dicho procedimiento no se desestima en un plazo de 30 días a partir de su incoación.",
      obligationsUponTermination: "Obligaciones en caso de cese",
      obligationsUponTerminationDesc: "Obligaciones en caso de rescisión. A la terminación del presente Acuerdo:",
      outNestOneDesc:
        "siempre que el Cliente haya abonado todas las cantidades adeudadas a la Empresa en virtud del presente documento, la Empresa, previa solicitud por escrito recibida en un plazo de 30 días a partir de la rescisión, proporcionará a cualquier Cliente que haya adquirido derechos de acceso a una Edición que requiera el pago de una cuota, acceso al Sistema durante un periodo de 24 horas con el fin limitado de exportar Datos del Cliente;",
      outNestTwoDesc: "La Empresa cancelará inmediatamente el acceso del Cliente al Sistema; y",
      outNestThreeDesc:
        "El Cliente abonará inmediatamente a la Empresa cualquier cantidad pagadera o devengada pero aún no pagadera a la Empresa, incluidos los pagos aplazados o los pagos que originalmente deban efectuarse a lo largo del tiempo.",
      confidentialInformation: "Informacion confidencial",
      confidentialInformationDesc:
        "Por 'información confidencial' se entenderá toda la información tangible e intangible (ya sea escrita, registrada de otro modo u oral) de una parte que: (A) obtenga un valor económico independiente, real o potencial, por no ser generalmente conocida y no ser fácilmente determinable por medios adecuados por otras personas que puedan obtener un valor económico de su divulgación o uso, y sea objeto de esfuerzos razonables, dadas las circunstancias, para mantener su confidencialidad; o (B) la parte que la divulga la designe como confidencial o, dada la naturaleza de la información o las circunstancias que rodean su divulgación, deba considerarse razonablemente como confidencial. La información confidencial incluye, sin limitación: (i) información no pública relativa a la tecnología, clientes, planes de negocio, actividades promocionales y de marketing, finanzas y otros asuntos comerciales de una parte; (ii) información de terceros que el Cliente o la Empresa están obligados a mantener confidencial; (iii) los términos y condiciones materiales de este Contrato; y (iv) cualquier información no pública relativa a cualquier actividad realizada en virtud del presente Contrato.",
      exclusions: "Exclusiones",
      exclusionsDesc:
        "Notwithstanding the above, the term 'Confidential Information' does not include any information that is either: readily discernible from publicly-available products or literature; or approved for disclosure by prior written permission of an executive officer of the disclosing party.",
      useOfCi: "Uso de información confidencial",
      useOfCiDesc:
        "Cada una de las partes sólo utilizará la Informacion Confidencial que se le facilite en virtud del presente Acuerdo para el desarrollo de las actividades contempladas en el mismo y,",
      useOfCiExceptionDesc:
        "salvo lo autorizado en el presente Acuerdo, no revelará la Información Confidencial a ninguna otra persona sin la autorización expresa y por escrito de la parte reveladora.",
      requiredDisclosures: "Información obligatoria",
      requiredDisclosuresDesc:
        "Una parte receptora podrá revelar Informacion Confidencial de la parte reveladora según sea necesario para cumplir ordenes vinculantes de entidades gubernamentales que tengan jurisdiccion sobre ella o según lo exija la ley, siempre que la parte receptora (i) notifique por escrito a la parte reveladora con una antelacion razonable para permitirle solicitar una orden de proteccion u otro recurso adecuado (excepto en la medida en que el cumplimiento de lo anterior hiciera que la parte receptora incumpliera una orden judicial u otro requisito legal), (ii) revele unicamente la informacion requerida por la entidad gubernamental o exigida por la ley, y (iii) haga todo lo posible por obtener un tratamiento confidencial para cualquier Informacion Confidencial revelada de este modo.",
      returnOfInformation: "Devolución de informacion",
      returnOfInformationDesc:
        "Salvo que se establezca lo contrario en las disposiciones específicas relativas a los Datos del cliente establecidas en la Sección 5(c) anterior, si una parte divulgadora así lo solicita en cualquier momento, la parte receptora devolverá sin demora todas las copias, extractos u otras reproducciones totales o parciales de la Información confidencial que obren en su poder.",
      survival: "Supervivencia",
      survivalDesc:
        "Las partes acuerdan y convienen en que esta Sección 6 sobrevivirá a la expiración, rescisión o cancelación de este Acuerdo durante un periodo de 3 años, excepto en el caso de la Información Confidencial que constituya un secreto comercial, con respecto a la cual esta Sección sobrevivirá a la expiración, rescisión o cancelación de este Acuerdo mientras dicha Información Confidencial siga siendo un secreto comercial.",
      indemnificationByCompany: "Indemnización por parte de la empresa",
      indemnificationByCompanyDesc:
        "PARA LOS CLIENTES QUE UTILICEN UNICAMENTE UNA EDICIÓN GRATUITA DEL SISTEMA, ESTA SECCION 7(A) NO ES DE APLICACIÓN Y EL CLIENTE RECONOCE QUE NO TIENE DERECHO A INDEMNIZACION ALGUNA POR PARTE DE LA EMPRESA. Para los Clientes que utilicen una Edición del Sistema en un plan de pago mensual o anual, la Empresa defenderá, indemnizará y mantendrá indemne al Cliente frente a todos los daños, responsabilidades, pérdidas y gastos, incluidos los honorarios y gastos razonables de abogados, derivados de cualquier reclamación, demanda o procedimiento de terceros que surja del uso del Sistema por parte del Cliente y/o de los Usuarios del Cliente de conformidad con el presente Contrato que, a entender de la Empresa, infrinja o se apropie indebidamente de cualquier secreto comercial, marca comercial o derecho de autor de los Estados Unidos. La Empresa no tendrá obligación alguna de indemnizar al Cliente si la supuesta infracción o apropiación indebida se basa en (i) cualquier combinación, operación o uso del Sistema con productos, servicios, información, materiales, tecnologías, métodos o procesos empresariales no suministrados por la Empresa en la medida en que la infracción o apropiación indebida se base en dicha combinación, operaciones o uso; (ii) cualquier modificación (que no haya sido realizada por la Empresa) del Sistema en la medida en que la infracción o apropiación indebida se base en dicha modificación; o (iii) el hecho de que el Cliente no instale de inmediato cualquier Actualización proporcionada por la Empresa que hubiera eliminado la infracción o apropiación indebida real o supuesta.",
      indemnificationByCustomer: "Indemnizacion por parte del cliente",
      indemnificationByCustomerDesc:
        "El Cliente defendera, indemnizara y mantendra indemne a la Empresa frente a todos los datos, responsabilidades, pérdidas y gastos, incluidos los honorarios y gastos razonables de abogados, derivados de cualquier reclamación, litigio o procedimiento de terceros que se derive del uso del Sistema por parte del Cliente y/o de los Usuarios del Cliente (salvo en la medida en que la Empresa haya sido indemnizada en virtud de la Seccion 7(a) o, en el caso de un Cliente que utilice unicamente una Edicion gratuita del Sistema, salvo en la medida en que la reclamacion de terceros hubiera estado sujeta a indemnizacion por parte de la Empresa en virtud de la Seccion 7(a) si la Seccion 7(a) se hubiera aplicado al Cliente)..",
      indemnificationProcess: "Proceso de indemnizacion",
      indemnificationProcessDesc:
        "La parte indemnizada notificará sin demora y por escrito a la parte indemnizadora cualquier reclamación de terceros, indicando la naturaleza y fundamento de la misma, en la medida en que se conozca. La parte indemnizadora tendrá el control exclusivo de la defensa y resolución de cualquier reclamación de terceros, siempre que, en el plazo de quince (15) días desde la recepción de la notificación anteriormente descrita, la parte indemnizadora notifique a la parte indemnizada su elección de asumir el control total. No obstante lo anterior, la parte indemnizada tendra derecho a participar en la defensa de dicha reclamacion de terceros y a contratar a un abogado a su costa para que le asista en la tramitacion de dicha reclamacion, con la salvedad de que los gastos legales de la parte indemnizada en el ejercicio de este derecho se consideraran gastos legales sujetos a indemnizacion en virtud del presente en la medida en que (x) la parte indemnizadora no asuma o se niegue a asumir el control de la defensa de la reclamación de terceros dentro del plazo establecido anteriormente; (y) la parte indemnizada considere razonablemente necesario presentar una contestacion o emprender acciones similares para evitar que se dicte una sentencia en rebeldia, una orden de restricción temporal o una medida cautelar en su contra; o (z) la representacion de ambas partes por el mismo abogado constituya, en opinion de dicho abogado, un conflicto de intereses. La parte indemnizadora no resolvera ninguna reclamacion de terceros sin el consentimiento por escrito de la parte indemnizada, excepto en el caso de un acuerdo completo que requiera únicamente el pago de daños y perjuicios en metalico a cargo de la parte indemnizadora.",
      soleRemedy: "Solución",
      soleRemedyDesc:
        "La indemnizacion conforme a esta seccion es el unico recurso de las partes para cualquier reclamacion de terceros contra la otra parte por infraccion o apropiacion indebida de la propiedad intelectual.",
      serviceLevelCommitmentsCredits: "Compromisos de nivel de servicio; creditos",
      serviceLevelCommitmentsCreditsDesc:
        "La Empresa garantiza que el Sistema y todos los Servicios proporcionados en el Sistema seran accesibles a los Usuarios autorizados del Cliente el 99,5% del tiempo en cualquier mes natural, excluyendo las Ventanas de Mantenimiento para todas las Ediciones de pago. No obstante, la Empresa no garantiza la disponibilidad de la red entre el Cliente y los servidores de alojamiento de la Empresa, ya que dicha disponibilidad puede implicar a numerosos terceros y escapa al control de la Empresa. La Empresa no será responsable ni proporcionará ningún crédito de servicio en virtud del presente documento por ningún tiempo de inactividad causado en su totalidad o en parte por un proveedor de centro de datos de terceros ni por ningún tiempo de inactividad que el Cliente experimente como resultado de problemas de conectividad de red propios del Cliente o de los Usuarios del Cliente. Si el Cliente experimenta una interrupción del Sistema o del Servicio y no puede acceder al Sistema o a cualquier Servicio, deberá ponerse en contacto inmediatamente con el servicio de asistencia de la Empresa, facilitando toda la información necesaria que pueda ayudar a la Empresa a determinar la causa de la interrupción. La Empresa determinará de buena fe si la interrupción estaba dentro del control razonable de la Empresa. Si la Empresa determina que una interrupción notificada a tiempo es atribuible a la Empresa, ésta",
      creditCustomerDesc:
        "abonará al Cliente 1 dia de cuota de Servicio por cada 2 horas de inactividad del Cliente, hasta un maximo de la mitad de la cuota de Servicio de ese mes. Este sera el único recurso del Cliente, y la unica responsabilidad de la Empresa, en caso de que la Empresa no proporcione la disponibilidad garantizada establecida en esta Seccion 8(a).",
      disclaimerOfWarranties: "Exclusion de garantías",
      disclaimerOfWarrantiesDesc:
        "A EXCEPCION LOS COMPROMISOS,LIMITADOS DE NIVEL DE SERVICIO, ESTABLECIDOS EN LA SECCION 8(A) Y EN LA MEDIDA EN QUE LO PERMITA LA LEGISLACION APLICABLE; LA EMPRESA NO REALIZA, Y POR LA PRESENTE RENUNCIA A REALIZAR, MANIFESTACIONES O GARANTIAS DE NINGUN TIPO, EXPRESAS O IMPLICITAS, CON RESPECTO AL SISTEMA, LOS SERVICIOS PRESTADOS O LA DISPONIBILIDAD, FUNCIONALIDAD, RENDIMIENTO O RESULTADOS DEL USO DEL SISTEMA. SIN PERJUICIO DE LO ANTERIOR, SALVO LO DISPUESTO ESPECÍFICAMENTE EN LOS COMPROMISOS LIMITADOS DE NIVEL DE SERVICIO DE LA SECCION 8(A), LA EMPRESA RECHAZA CUALQUIER GARANTIA DE QUE EL SISTEMA, LOS SERVICIOS PRESTADOS POR LA EMPRESA O EL FUNCIONAMIENTO DEL SISTEMA SEAN O VAYAN A SER PRECISOS, ESTEN LIBRES DE ERRORES O SEAN ININTERRUMPIDOS. LA EMPRESA NO OFRECE, Y POR LA PRESENTE RENUNCIA A CUALQUIER GARANTIA IMPLICITA, INCLUYENDO, SIN LIMITACION, CUALQUIER GARANTIA IMPLICITA DE NO INFRACCION, COMERCIABILIDAD, IDONEIDAD PARA CUALQUIER PROPÓSITO PARTICULAR O QUE SURJA POR EL USO DEL COMERCIO, EL CURSO DE LAS NEGOCIACIONES O EL CURSO DE LA EJECUCION.",
      disclaimerOfConsequentialDamages: "Descargo de responsabilidad por daños indirectos",
      disclaimerOfConsequentialDamagesDesc:
        "EN LA MEDIDA EN QUE LO PERMITA LA LEGISLACION APLICABLE, LA EMPRESA NO SERA RESPONSABLE, EN RELACION CON EL SISTEMA, LOS SERVICIOS U OTRAS OBLIGACIONES CONTRAIDAS EN VIRTUD DEL PRESENTE CONTRATO O DE CUALQUIER OTRO MODO, DE LOS DAÑOS CONSECUENTES, EJEMPLARES, ESPECIALES, ACCIDENTALES O PUNITIVOS (INCLUIDOS, ENTRE OTROS, LA PERDIDA DE BENEFICIOS Y EL COSTE DE LA COBERTURA), AUN CUANDO LA EMPRESA HAYA SIDO ADVERTIDA DE LA POSIBILIDAD DE QUE SE PRODUZCAN DICHOS DAÑOS.",
      limitationsOfRemediesAndLiability: "Limitaciones de recursos y responsabilidad",
      limitationsOfRemediesAndLiabilityDesc:
        "EN LA MEDIDA QUE LO PERMITA LA LEGISLACION APLICABLE, LA RESPONSABILIDAD TOTAL DE LA EMPRESA FRENTE AL CLIENTE POR CUALQUIER MOTIVO Y POR CUALQUIER CAUSA DE ACCIÓN, INCLUIDOS, ENTRE OTROS, INCUMPLIMIENTO DE CONTRATO, NEGLIGENCIA, RESPONSABILIDAD OBJETIVA, DECLARACIONES FALSAS Y OTROS AGRAVIOS, SE LIMITA A (1) EN EL CASO DE UN CLIENTE CON UN PLAN DE PAGO MENSUAL, TODAS LAS CUOTAS ABONADAS A LA EMPRESA POR EL CLIENTE EN CONCEPTO DE LICENCIAS DE USUARIO DEL SISTEMA DURANTE LOS TRES MESES INMEDIATAMENTE ANTERIORES A LOS HECHOS QUE DIERON LUGAR A LA RESPONSABILIDAD; (2) EN EL CASO DE UN CLIENTE CON UN PLAN DE PAGO ANUAL, TODAS LAS CUOTAS ABONADAS A LA EMPRESA POR EL CLIENTE EN CONCEPTO DE LICENCIAS DE USUARIO DEL SISTEMA DURANTE LOS DOCE MESES INMEDIATAMENTE ANTERIORES A LOS HECHOS QUE DIERON LUGAR A LA RESPONSABILIDAD; O (3) EN EL CASO DE UN CLIENTE QUE UTILICE UNICAMENTE UNA EDICION GRATUITA DEL SISTEMA, CIEN DOLARES ESTADOUNIDENSES (100.00$).",
      notices: "Noticias",
      noticesDesc:
        "Las notificaciones relativas al presente Acuerdo dirigidas a la Empresa se realizarán por escrito y se enviarán por correo de primera clase o mensajería urgente a la dirección facilitada en ese momento en el sitio web de la Empresa.",
      noticesDisclaimerDesc:
        "La Empresa podrá enviar notificaciones mediante su publicación en el Sistema, por correo electrónico a la dirección de correo electrónico del Cliente que conste en los registros de la Empresa, o mediante comunicación escrita enviada por correo de primera clase o mensajería urgente a la dirección del Cliente que conste en los datos de la cuenta de la Empresa. Todas las notificaciones se considerarán efectuadas tres días después de su envío por correo postal o postal (en caso de envío por correo de primera clase), de su entrega en caso de envío por mensajero, o 12 horas después de su envío por correo electrónico o de su publicación en el Sistema.",
      forceMajeure: "Fuerza Mayor",
      forceMajeureDesc:
        "'Caso de fuerza mayor' significa cualquier acto o acontecimento que (a) impida a una parte (la 'Parte Incumplidora') cumplir con sus obligaciones o satisfacer una condición de las obligaciones de la otra parte (la 'Parte ejecutora') en virtud del presente acuerdo, (b) fuera del control razonable y no se cumpla de la parte incumplidora, y (c) la parte incumplidora no haya podido evitar o superar mediante esfuerzo comercialmente razonables.'Acontecimientos de fuerza mayor' no incluyen las dificultades económicas, los cambios en las condiciones del mercado y la insuficiencia de fondos. Si se produce un Evento de Fuerza Mayor, la Parte Incumplidora queda eximida del cumplimiento impedido por el mismo y de satisfacer cualquier condicion previa al cumplimiento de la otra parte que no pueda ser satisfecha, en cada caso en la medida limitada o impedida por el Evento de Fuerza Mayor. Cuando la Parte Incumplidora pueda reanudar su ejecucion o satisfacer las condiciones suspensivas de las obligaciones de la otra Parte, la Parte Incumplidora reanudara¡ inmediatamente la ejecucion del presente Acuerdo. La compensacion ofrecida por este parrafo es el recurso exclusivo de que dispone la Parte Ejecutante con respecto a un Evento de Fuerza Mayor.",
      assignment: "Asignación",
      assignmentDesc:
        "La Empresa podrá ceder cualquiera de sus derechos u obligaciones en virtud del presente Contrato en cualquier momento; no obstante, la Empresa no cederá los derechos concedidos a los Datos del cliente en la Sección 2(d) salvo en relación con la venta (ya sea por fusión, venta de activos, venta de capital o de otro modo) de (i) la Empresa, (ii) el Sistema o (iii) una parte de la Empresa o del Sistema que razonablemente requiera que al adquirente de dicha parte se le cedan dichos derechos sobre los Datos del cliente. El Cliente no cederá ninguno de sus derechos en virtud del presente Acuerdo, salvo con la aprobación previa por escrito de la Empresa, que no se denegará injustificadamente. La frase anterior se aplica a todas las cesiones de derechos, excepto en el caso de una transferencia voluntaria de sustancialmente todos los activos por parte del Cliente a un cesionario que ejecute el formulario de acuerdo de la Empresa aceptando estar obligado a todos los tÃ©rminos y condiciones de este Acuerdo. Cualquier transacción de cambio de control se considerará una cesión en virtud del presente Contrato. Cualquier pretendida cesión de derechos en violación de esta Sección será nula.",
      governingLawVenue: "Ley aplicable; jurisdicción",
      governingLawVenueDesc:
        "Las leyes del Estado de Illinois en los Estados Unidos de America (sin dar efecto a su conflicto de principios legales) rigen todos los asuntos que surjan o esten relacionados con este Acuerdo y las transacciones que contempla, incluyendo, sin limitacion, su interpretación, construcción, ejecución y cumplimiento. Cualquier reclamación o acción relativa o derivada del presente Acuerdo debera interponerse exclusivamente ante un tribunal de la jurisdiccion competente con sede en Delaware, y cada una de las partes del presente Acuerdo se somete a la jurisdiccion de dichos tribunales a efectos de todas las acciones y procedimientos legales derivados o relacionados con el presente Acuerdo. Cada una de las partes renuncia, en la maxima medida permitida por la ley, a cualquier objecion que pueda tener ahora o en el futuro a (i) el establecimiento de la jurisdiccion de cualquier accion o procedimiento legal que surja de o este relacionado con este Acuerdo presentado en cualquier tribunal estatal o federal con sede en Delaware; y (ii) cualquier",
      claimThatDesc:
        "alegar que cualquier acción o procedimiento entablado ante cualquiera de dichos tribunales se ha entablado en un foro inconveniente.",
      recoveryOfLitigationCosts: "Recuperación de las costas procesales",
      recoveryOfLitigationCostsDesc:
        "Si se inicia cualquier accion legal u otro procedimiento para la ejecucion de este Acuerdo, o debido a una supuesta disputa, incumplimiento, falta o tergiversacion en relacion con cualquiera de las disposiciones de este Acuerdo, la parte perdedora debera pagar a la parte ganadora sus honorarios razonables de abogados y otros gastos incurridos en dicha accion o procedimiento, ademas de cualquier otra compensacion a la que la parte ganadora pueda tener derecho.",
      entireAgreement: "Acuerdo completo",
      entireAgreementDesc:
        "El presente Acuerdo constituye el acuerdo final entre las partes. Es la expresion completa y exclusiva sobre los asuntos contenidos en este Acuerdo. Todas las negociaciones y acuerdos anteriores y contemporaneos entre las partes sobre los asuntos contenidos en el presente Acuerdo se fusionan expresamente y son sustituidos por el presente Acuerdo. Las disposiciones de este Acuerdo no pueden ser explicadas, complementadas o matizadas mediante pruebas de uso comercial o un curso previo de tratos. A la hora de suscribir el presente Acuerdo, ninguna de las partes se ha basado en ninguna declaración, manifestación, garantía o acuerdo de la otra parte, salvo los expresamente contenidos en el presente Acuerdo. No existen condiciones suspensivas para la eficacia del presente Acuerdo, salvo las que se indican expresamente en el mismo.",
      amendments: "Enmiendas",
      amendmentsDesc:
        "Las partes sólo podran modificar el presente Acuerdo mediante acuerdo escrito, que se identifique como una modificacion del presente Acuerdo.",
      survivalOfCertainProvisions: "Vigencia de determinadas disposiciones",
      survivalOfCertainProvisionsDesc:
        "Cada una de las partes acuerda y conviene en que las disposiciones de las Secciones 1, 2(b), 5(c), 6, 7, 8 y 9 ademas de cualquier otra disposicion que, por sus terminos, este destinada a sobrevivir a la expiracion o terminación de este Acuerdo.",
    },
  },
};
