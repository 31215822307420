import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { useField } from "common/form";
import { TextLabel } from "common/form/renderFields";
import { Button, Text } from "common/guideline";

type FieldWeeklyProps = React.PropsWithChildren<{
  name: string;
}>;

export const tDays: TKeys[] = [
  "report.sr.mon",
  "report.sr.tue",
  "report.sr.wed",
  "report.sr.thu",
  "report.sr.fri",
  "report.sr.sat",
  "report.sr.sun",
];

export const FieldWeekly: React.FC<FieldWeeklyProps> = ({ name }) => {
  const { value, onChange, error } = useField<number | undefined>(name, { errorSub: true });

  return (
    <div>
      <div tw="grid grid-cols-7 gap-2">
        {tDays.map((day, index) => {
          const selected = value === index + 1;
          return (
            <Button
              key={day}
              aria-selected={selected}
              variant={selected ? "primary" : "side"}
              onClick={() => onChange(index + 1)}
            >
              <Text tKey={day} />
            </Button>
          );
        })}
      </div>
      {error && <TextLabel error variant="label" tKey="common.form.err.required" />}
    </div>
  );
};
