import { useCallback } from "react";

import { FieldDataKey, useFieldData } from "../field";
import { ArrayNestedFieldsProps } from "../schema";

export type ConditionProps = React.PropsWithChildren<
  {
    when: string | ((arrayIndex?: number) => string);
    is: string | number | ((v: any) => boolean);
    scope?: FieldDataKey;
  } & Partial<ArrayNestedFieldsProps>
>;

export const Condition: React.FC<ConditionProps> = ({ when, is, children, scope = "values", arrayIndex }) => {
  const checker = useCallback((value) => (typeof is === "function" ? is(value) : value === is), [is]);
  const fieldName = typeof when === "string" ? when : when(arrayIndex);
  const condition = useFieldData(fieldName, scope as any, checker);
  return condition ? <>{children}</> : null;
};
