import { TForm } from "./types";
import { FormDataTypes, useFormData } from "./useFormData";

type FormDataProps<T extends FormDataTypes> = {
  type: T;
  children: (value: TForm<any>[T]) => JSX.Element | null;
};

export const FormData = <T extends FormDataTypes>({ type, children }: FormDataProps<T>): ReturnType<typeof children> =>
  children(useFormData(type));
