import { useSelectFilter } from "common/guideline";

const transactionTypes: { value: string; label: string }[] = [
  { value: "ALL", label: "tranReport.messageTypes.ALL" },
  { value: "MACHINE_TRANSACTIONS", label: "tranReport.messageTypes.MACHINE_TRANSACTIONS" },
  { value: "CORRECTION_TRANSACTIONS", label: "tranReport.messageTypes.CORRECTION_TRANSACTIONS" },
];

export const useQueryByTransactionTypeFilter = () =>
  useSelectFilter({
    label: "tranReport.transactionType",
    initial: "ALL",
    options: transactionTypes,
  });
