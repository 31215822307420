import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as LaneAllocationsSVG } from "assets/icons/LaneAllocations.svg";
import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";
import { LaneAllocationsTable } from "./LaneAllocationsTable/LaneAllocationsTable";

export const LaneAllocations = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.la.title"
    subtitle="administration.la.description"
    Icon={LaneAllocationsSVG}
  >
    <LaneAllocationsTable isAdminView={true} />
  </PageLayout>
);
