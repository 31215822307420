import { FormStore, TForm } from "./types";
import { FormDataTypes } from "./useFormData";

export const changeFormData = <KEY extends FormDataTypes = "values">(
  form: FormStore<any>,
  values: TForm<any>[KEY],
  {
    key = "values" as KEY,
    merger = Object.assign,
    revalidate = false,
  }: {
    key?: KEY;
    revalidate?: boolean;
    merger?: (current: TForm<any>[KEY], next: Partial<TForm<any>[KEY]>) => TForm<any>[KEY];
  } = {},
) => {
  const state = form.getState();
  form.setState({ [key]: merger(state[key], values) } as Pick<TForm<any>, KEY>);
  if (revalidate) state.validateForm();
};
