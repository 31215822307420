import { useFieldRegister } from "../../field";
import { useFormContext } from "../../form";
import { CustomField as CustomFieldType } from "../types";

function CustomField({ field, children }: React.PropsWithChildren<{ field: CustomFieldType }>) {
  const form = useFormContext();
  useFieldRegister(field.name, form.useStore, field);
  return <>{children}</>;
}

export const getCustomField = (field: CustomFieldType, children: React.ReactNode[], key: string) => {
  if (field.validate || field.defaultValue || field.data || field.calculation) {
    return (
      <CustomField field={field} key={key}>
        <field.Component {...field} key={key}>
          {children}
        </field.Component>
      </CustomField>
    );
  }

  return (
    <field.Component {...field} key={key}>
      {children}
    </field.Component>
  );
};
