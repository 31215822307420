import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";
import { Table, TableTypes, accessors } from "common/guideline";
import { useFindSoftwareInstallationsQuery } from "generated";
import { useTranslation } from "i18n";
import { SoftwareInstallationFetched } from "zts/types";

const getColumns: TableTypes.TranslatedColumns<SoftwareInstallationFetched> = (t) => [
  {
    header: t("zts.remoteUpgrade.timeOfUpgrade"),
    accessorFn: (v) => accessors.date(v.createdDate, t),
    minSize: 170,
  },
  {
    header: t("zts.latestStatusChange"),
    accessorFn: (v) => accessors.date(v.stateChangedDate, t),
    minSize: 170,
  },
  {
    header: t("zts.remoteUpgrade.name"),
    width: 100,
  },
  {
    header: t("zts.remoteUpgrade.label"),
  },
  {
    header: t("zts.remoteUpgrade.softwareVersion"),
    width: 120,
  },
  {
    header: t("zts.executedBy"),
    accessorKey: "createdBy",
    minSize: 170,
  },
  {
    header: t("zts.remoteUpgrade.revision"),
    minSize: 170,
  },
  {
    header: t("zts.result"),
    minSize: 170,
  },
];

const SoftwareInstallationsTable = ({ machine }) => {
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindSoftwareInstallationsQuery({
    variables: {
      machineNodeId: machine.nodeId,
      current: false,
      ongoing: false,
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.findSoftwareInstallations || []) as SoftwareInstallationFetched[];

  return (
    <Table<SoftwareInstallationFetched>
      tableName="softwareInstallations"
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};

export const SoftwareInstallations = ({ machine }) => (
  <PageLayout title="zts.remoteUpgrade.upgradeHistory" subtitle="zts.remoteUpgrade.upgradeHistoryDesc">
    <SoftwareInstallationsTable machine={machine} />
  </PageLayout>
);
