import {
  Column,
  ColumnFiltersState,
  Header,
  PaginationState,
  TableOptions,
  Updater,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Dispatch, SetStateAction, useState } from "react";

import { strictString } from "common/helpers";

import { DEFAULT_PAGE_SIZE } from "./Pagination";
import { sortingFns } from "./sortingFns";
import { getTableStorageData } from "./TableStorageConfig";
import { TableProps } from "./types";

export const getColumnName = (column: Column<any, unknown>) =>
  column.columnDef.meta?.headerName || strictString(column.columnDef.header, column.id);

export type PaginationSetFunction = Dispatch<SetStateAction<PaginationState>>;
export const usePagination = (tableName: string) =>
  useState<PaginationState>(() => ({
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 0,
    ...getTableStorageData(tableName)?.pagination,
  }));

export const getCellStyle = (header: Column<any, unknown> | Header<any, unknown>) => {
  const width = header.getSize();
  return { width, flexGrow: width };
};

const onlySingleFilterCallback =
  (onFilter: NonNullable<TableProps<any>["onFilter"]>) => (updaterOrValue: Updater<ColumnFiltersState>) =>
    onFilter((oldState) => {
      if (typeof updaterOrValue !== "function") return updaterOrValue;

      const nextState = updaterOrValue(oldState);

      if (nextState.length < 2) return nextState;

      const firstFilterId = nextState[0]?.id;
      const currColumnFilter = nextState.filter((item) => item.id != firstFilterId);
      return currColumnFilter;
    });

export const getTableConfig = ({
  onSorting,
  onPagination,
  onFilter,
  sorting,
  columnFilters,
  totalCount = 0,
  pageSize = 0,
  pageIndex = 0,
  hasSubRows,
  defaultColumn,
  onlySingleFilter = false,
  getRowId = undefined,
  rowSelection,
  onRowSelectionChange,
  initialSorting,
  columnVisibility,
  onColumnVisibilityChange,
}: Pick<
  TableProps<any>,
  | "onSorting"
  | "onPagination"
  | "onFilter"
  | "sorting"
  | "columnFilters"
  | "totalCount"
  | "pageIndex"
  | "pageSize"
  | "onlySingleFilter"
  | "getRowId"
  | "rowSelection"
  | "onRowSelectionChange"
  | "initialSorting"
  | "columnVisibility"
  | "onColumnVisibilityChange"
> & { hasSubRows: boolean; defaultColumn?: TableOptions<any>["defaultColumn"] }) => {
  const result: Omit<TableOptions<any>, "data" | "columns"> = {
    defaultColumn,
    sortingFns,
    paginateExpandedRows: false,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (r) => Boolean(r.subRows?.length || hasSubRows),
    getSubRows: (row: any) => row.subRows,
    autoResetPageIndex: false,
    enableMultiRowSelection: true,
    getRowId,
    initialState: {
      pagination: {
        pageSize: DEFAULT_PAGE_SIZE,
      },
    },
  };

  if (initialSorting) {
    if (!result.initialState) result.initialState = {};
    result.initialState.sorting = initialSorting;
  }

  if (onRowSelectionChange) {
    if (!result.state) result.state = {};
    result.state.rowSelection = rowSelection;
    result.onRowSelectionChange = onRowSelectionChange;
  }

  if (onSorting) {
    if (!result.state) result.state = {};

    result.state.sorting = sorting;
    result.manualSorting = true;
    result.onSortingChange = onSorting as any;
  } else {
    result.getSortedRowModel = getSortedRowModel();
  }

  if (onPagination) {
    if (!result.state) result.state = {};

    const pagination = { pageIndex, pageSize };
    result.state.pagination = pagination;
    result.manualPagination = true;
    result.onPaginationChange = onPagination as any;
    result.pageCount = Math.ceil(totalCount / (pagination.pageSize || 1));
  } else {
    result.getPaginationRowModel = getPaginationRowModel();
  }

  if (onFilter) {
    if (!result.state) result.state = {};

    result.state.columnFilters = columnFilters;
    result.manualFiltering = true;
    result.onColumnFiltersChange = onlySingleFilter ? onlySingleFilterCallback(onFilter) : (onFilter as any);
  } else {
    result.getFilteredRowModel = getFilteredRowModel();
  }

  if (columnVisibility) {
    if (!result.state) result.state = {};
    result.state.columnVisibility = columnVisibility;
    result.onColumnVisibilityChange = onColumnVisibilityChange;
  }

  return result;
};
