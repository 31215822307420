import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { NodeStatusBadge, useTimeZoneFilter } from "base/components";
import {
  CommonCells,
  Table,
  TableTypes,
  accessors,
  localFilters,
  useDateRangeFilter,
  usePagination,
} from "common/guideline";
import { useFindAllOtaAuthorizationsQuery } from "generated";
import { useTranslation } from "i18n";
import { FilterBox, useColumnFilters } from "report/components";
import { useSorting } from "report/hooks";

import { OTATableRowActions } from "./OTATableRowActions";
import { OTAData } from "./types";

const columns: TableTypes.TranslatedColumns<OTAData> = (t) => [
  {
    id: "machineUserId",
    header: t("ota.machineUserId"),
    enableSorting: false,
    accessorKey: "userId",
    meta: localFilters.getTextBaseFilter.meta,
  },
  {
    id: "machineUserName",
    header: t("ota.machineUserName"),
    enableSorting: false,
    accessorKey: "userName",
    meta: localFilters.getTextBaseFilter.meta,
  },
  {
    id: "machineUserRole",
    header: t("ota.machineUserRole"),
    enableSorting: false,
    accessorKey: "userRole",
    meta: localFilters.getTextBaseFilter.meta,
  },
  {
    id: "connectUserName",
    header: t("ota.connectUser"),
    enableSorting: false,
    accessorKey: "connectUserName",
    meta: localFilters.getTextBaseFilter.meta,
  },
  {
    id: "authorizedMachines",
    header: t("ota.authorizedMachines"),
    enableSorting: false,
    accessorKey: "authorization",
    cell: (c) => {
      const machineValue = c.getValue<OTAData["machineUuid"]>();
      const siteValue = c.getValue<OTAData["siteNodeId"]>();
      const siteGroupValue = c.getValue<OTAData["siteGroupNodeId"]>();
      const allMachines = !machineValue && !siteValue && !siteGroupValue;

      return (
        <>
          {allMachines
            ? t("ota.authorizationTypes.allMachines")
            : machineValue
            ? `${t(`ota.authorizationTypes.oneMachine`, { colon: true })} ${machineValue}`
            : "-"}
        </>
      );
    },
  },
  {
    id: "authorizedSites",
    header: t("ota.authorizedSites"),
    enableSorting: false,
    accessorKey: "authorization",
    cell: (c) => {
      const siteValue = c.getValue<OTAData["siteNodeId"]>();
      return siteValue ? (
        <>
          {t(`ota.authorizationTypes.oneSite`, { colon: true })} {siteValue}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    id: "authorizedMachineGroups",
    header: t("ota.authorizedMachineGroups"),
    enableSorting: false,
    accessorKey: "authorization",
    cell: (c) => {
      const value = c.getValue<OTAData["siteGroupNodeId"]>();
      return value ? (
        <>
          {t(`ota.authorizationTypes.oneSiteGroup`, { colon: true })} {value}
        </>
      ) : (
        "-"
      );
    },
  },
  {
    id: "expirationDateTime",
    header: t("ota.expiration"),
    accessorKey: "expirationDateTime",
    cell: (c) => {
      const value = c.getValue<OTAData["expirationDateTime"]>();
      return <>{value ? accessors.date(value, t) : "-"}</>;
    },
  },
  {
    id: "deleteAfterFirstUse",
    header: t("ota.deleteAfterFirstUse"),
    accessorKey: "deleteAfterFirstUse",
    cell: (c) => {
      const value = c.getValue<OTAData["deleteAfterFirstUse"]>();
      return <>{value ? "Yes" : "No"}</>;
    },
    meta: localFilters.selectBooleanFilter.meta,
  },
  {
    id: "validityTime",
    header: t("ota.validityTime"),
    accessorKey: "codeValidityIntervalMinutes",
    cell: (c) => {
      const value = c.getValue<OTAData["codeValidityIntervalMinutes"]>() ?? 0;
      return <>{value ? accessors.duration(value * 60, t) : "-"}</>;
    },
  },
  {
    id: "nodeStatus",
    header: t("ota.nodeStatus"),
    accessorKey: "nodeStatus",
    cell: NodeStatusBadge,
  },
  {
    id: "customData",
    header: t("ota.customData"),
    enableSorting: false,
    accessorKey: "customData",
    cell: (c) => {
      const value = c.getValue<OTAData["customData"]>() ?? [];
      return (
        <div tw="grid grid-cols-[max-content 1fr] gap-1">
          {value.length > 0
            ? value.map((v) =>
                v.key ? (
                  <>
                    <span>{v.key}:</span>
                    <span>{v.value}</span>
                  </>
                ) : null,
              )
            : "-"}
        </div>
      );
    },
  },

  CommonCells.getActionsCell({ cell: (c) => <OTATableRowActions row={c.row} /> }),
];

const TABLE_NAME = "ota";

const columnFiltersData = [
  ["connectUserName", "connectUserName"],
  ["userId", "machineUserId"],
  ["userName", "machineUserName"],
  ["userRole", "machineUserRole"],
  ["deleteAfterFirstUse", "deleteAfterFirstUse", (v) => v === "true"],
] as const;

export const OTATable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const [filters, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const [zoneId, TimeZone] = useTimeZoneFilter(false);
  const [dateFilter, DateRange] = useDateRangeFilter("empty", {
    label: "ota.expiration",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useFindAllOtaAuthorizationsQuery({
    variables: {
      otaAuthorizationFiltersDto: {
        expiringFrom: dateFilter.from || undefined,
        expiringTo: dateFilter.to || undefined,
        userId: filters.userId,
        connectUserName: filters.connectUserName,
        userName: filters.userName,
        userRole: filters.userRole,
        deleteAfterFirstUse: filters.deleteAfterFirstUse,
        // machineSiteGroupNodeId: filters.machineSiteGroupNodeId,
        // machineSiteNodeId: filters.machineSiteNodeId,
        // machineUuid: filters.machineUuid,
        zoneId,
      },
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });

  const result = (data?.findAllOtaAuthorizations?.result ?? []) as OTAData[];
  const fullSize = data?.findAllOtaAuthorizations?.fullSize ?? 0;

  return (
    <>
      <FilterBox>
        {DateRange}
        {TimeZone}
      </FilterBox>
      <Table
        tableName={TABLE_NAME}
        columns={tColumns}
        data={result}
        totalCount={fullSize}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPagination={setPagination}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        sorting={sorting}
        onSorting={setSorting}
      />
    </>
  );
};
