import { isObject } from "./isObject";

export const deepMerge = (_target: any, source: any, isMergingArrays = false): any => {
  const target = { ..._target };

  if (!isObject(target) || !isObject(source)) return source;

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      if (isMergingArrays) {
        target[key] = targetValue.map((x, i) =>
          sourceValue.length <= i ? x : deepMerge(x, sourceValue[i], isMergingArrays),
        );
        if (sourceValue.length > targetValue.length)
          target[key] = [...(target[key] as any[])].concat(sourceValue.slice(targetValue.length));
      } else {
        target[key] = [...targetValue].concat(sourceValue);
      }
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMerge(targetValue, sourceValue, isMergingArrays);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
};
