import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { HeaderGroup, SortDirection, flexRender } from "@tanstack/react-table";
import { Fragment, Ref } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { getCellStyle, getColumnName } from "../helpers";

const SortIndicator: React.FC<{
  getIsSorted: () => false | SortDirection;
}> = ({ getIsSorted }) => {
  const sort = getIsSorted();
  const isSortedDesc = sort === "desc";

  return (
    <span tw="ml-2">
      <CaretUp weight="fill" opacity={sort && !isSortedDesc ? 1 : 0.2} />
      <CaretDown weight="fill" opacity={isSortedDesc ? 1 : 0.2} tw="-mt-1" />
    </span>
  );
};

export const TableHeader: React.FC<{ getHeaders: () => HeaderGroup<any>[]; headerRef?: Ref<HTMLDivElement> }> = ({
  getHeaders,
  headerRef,
}) => (
  <div className="tHead">
    {getHeaders().map((headerGroup) => (
      <Fragment key={headerGroup.id}>
        <div className="tr" ref={headerRef}>
          {headerGroup.headers.map((header) => (
            <div
              className="th"
              key={header.id}
              style={getCellStyle(header)}
              onClick={header.column.getToggleSortingHandler()}
              data-test={`sort_${getColumnName(header.column)}`}
            >
              <span>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </span>
              {header.column.getCanSort() && <SortIndicator getIsSorted={header.column.getIsSorted} />}
            </div>
          ))}
        </div>

        <div className="tr">
          {headerGroup.headers.map((header) => (
            <div key={header.id} className="th" style={getCellStyle(header)}>
              {header.column.getCanFilter() && flexRender(header.column.columnDef.meta?.filter, header.getContext())}
            </div>
          ))}
        </div>
      </Fragment>
    ))}
  </div>
);
