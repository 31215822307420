import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "common/guideline";

type CheckboxBtnProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  id: string;
  tKey: TKeys | number;
};

export const CheckboxBtnRaw: React.FC<CheckboxBtnProps> = ({ value, onChange, id, tKey }) => {
  return (
    <div tw="flex">
      <input
        onChange={(event) => onChange(event.target.checked)}
        checked={value}
        type="checkbox"
        className="peer"
        id={id}
        tw="hidden"
      />
      <label
        htmlFor={id}
        tw="flex items-center justify-center min-w-[4rem] select-none cursor-pointer rounded p-2 transition-all duration-300 border-2 bg-gray-2 border-primary-default text-primary-default hover:bg-primary-default hover:text-gray-2 hover:border-primary-default peer-checked:bg-primary-alt peer-checked:text-gray-2 peer-checked:border-primary-alt"
      >
        <Text tKey={tKey} />
      </label>
    </div>
  );
};
