import { HeaderGroup, SortingState, TableState, Updater, flexRender } from "@tanstack/react-table";
import { useState } from "react";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { ReactComponent as SortAscendingSVG } from "assets/icons/SortAscending.svg";
import { ReactComponent as SortDescendingSVG } from "assets/icons/SortDescending.svg";

import { Button } from "../../Button";
import { ComponentModalProps, DrawerBottom, useModalComponent } from "../../Modal";
import { Text } from "../../Text";
import { getColumnName } from "../helpers";

type Props = {
  getState: () => TableState;
  setSorting: (updater: Updater<SortingState>) => void;
  getHeaderGroups: () => HeaderGroup<any>[];
};

const getDrawer = (props: Props) =>
  function SortingDrawer({ onClose }: ComponentModalProps) {
    // we take first sort because multi sorting is not supported
    const [{ id, desc }, setSort] = useState(() => props.getState().sorting[0] || {});

    const updateSort = (sorting: SortingState = []) => {
      props.setSorting(sorting);
      onClose?.();
    };

    return (
      <div>
        <Text variant="paragraph" tKey="common.table.sort.sortBy" />

        <div tw="py-2">
          {props.getHeaderGroups().flatMap((h) =>
            h.headers.map((i) =>
              i.column.getCanSort() ? (
                <div
                  key={i.id}
                  css={css`
                    ${tw`grid text-gray-6 [grid-template-columns:24px_1fr] items-center pb-1`}
                    ${id === i.id && tw`text-gray-9 font-semibold`}
                  `}
                  onClick={() =>
                    setSort((p) => (p.id === i.id ? { id: i.id, desc: !p.desc } : { id: i.id, desc: false }))
                  }
                  data-test={`sort_${getColumnName(i.column)}`}
                >
                  <span>
                    {id === i.id ? (
                      desc ? (
                        <SortDescendingSVG width={18} height={18} />
                      ) : (
                        <SortAscendingSVG width={18} height={18} />
                      )
                    ) : null}
                  </span>

                  {flexRender(i.column.columnDef.header, i.getContext())}
                </div>
              ) : null,
            ),
          )}
        </div>

        <div tw="flex space-x-2">
          <Button tw="flex-1" variant={["side", "sm"]} onClick={() => updateSort()} data-test="resetSorting">
            <Text tKey="common.table.sort.reset" />
          </Button>
          <Button
            tw="flex-1"
            variant={["primary", "sm"]}
            onClick={() => updateSort([{ id, desc }])}
            data-test="confirmSorting"
          >
            <Text tKey="common.table.sort.done" />
          </Button>
        </div>
      </div>
    );
  };

export const Sorting: React.FC<Props> = (props) => {
  const [toggleModal] = useModalComponent({
    Component: getDrawer(props),
    modalProps: {
      variant: "drawerBottom",
      Modal: DrawerBottom,
    },
  });

  return (
    <Button variant={["light", "sm"]} tw="relative" onClick={() => toggleModal()} data-test="showSortingModal">
      <Text tKey="common.table.sort.sort" />
      {props.getState().sorting.length > 0 && (
        <span tw="absolute -top-1 -right-1 h-2 w-2 bg-primary-default rounded-full" />
      )}
    </Button>
  );
};
