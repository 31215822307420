export const de = {
  operations: {
    title: "Gesamtübersicht",
    cashOps: {
      title: "Betriebsübersicht",
      desc: "Verschaffen Sie sich einen Überblick über die verschiedenen Transaktionsarten. Sie können den Filter verwenden, um die Gruppe, den Standort oder die Maschine auszuwählen, für die Sie Transaktionsinformationen sehen möchten.",
    },
    techOps: {
      title: "Technischer Betrieb",
      desc: "Verwenden Sie das Dashboard für den Technologiebetrieb zur Überwachung von Maschinen",
    },
    retailOps: "Einzelhandlesübersicht",
    cashMetrics: "Cash-Metriken",
    recentTransactions: "Aktuelle Transaktionen",
    recentUsers: "Aktuelle Benutzer",
    selectMachine: "Wählen Sie eine Maschine aus der obigen Auswahl, um deren Details zu sehen",
    selectSiteMachine: "Wählen Sie den Standort und die Maschine aus der obigen Auswahl, um die Details zu sehen",
    openMap: "Karte öffnen",
    closeMap: "Karte schließen",
  },
};
