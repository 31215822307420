export const uniqueID = () => Math.random().toString(16).slice(-4);

export const getInitials = (fullName: string): string =>
  fullName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

export const emptyValue = "—";
export const withDefault = (value: any, defaultValue: string | number = emptyValue) => value ?? defaultValue;

export const strictString = (v: any, defaultStr = ""): string => (typeof v === "string" ? v : defaultStr);

export const stringToPossibleFloat = (v: string | undefined) => {
  const withDots = v?.replace(/,/g, ".");
  return !withDots || isNaN(withDots as any) ? withDots : Number(parseFloat(withDots).toFixed(2));
};

export const stringToPossibleInt = (v: string | undefined) => {
  const int = v?.split(/,|\./g)?.[0];
  return !int || isNaN(int as any) ? int : Number(int);
};

export const makeSorter = (name: string) => (a: any, b: any) =>
  String(a[name] ?? "").localeCompare(String(b[name] ?? ""));

export const isEmail = (email: string) =>
  /^[A-Za-z0-9]+(?:[+_.'’-][A-Za-z0-9]+)*@[_A-Za-z0-9-]+(?:\.[_A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/.test(email);

const numbersBetweenDots = /\.[0-9]+(\.)?/g;
export const getAllNumbersBetweenDotsFromString = (str: string) => {
  const matches = str.match(numbersBetweenDots);
  return matches ? matches.map((match) => match.replace(/\./g, "")) : [];
};

// <string>.<string> - e.g. `domain.com` is correct
export const isDomainString = (str: string) => /[a-zA-Z0-9]+[.][a-zA-Z0-9]+/.test(str);

// regexp to allow only alphanumeric characters and _ (underscore), - (dash) and . (dot) symbols
export const alphaNumericUnderscoreDashDotRegExp = /^[a-zA-Z0-9_.-]+$/;
