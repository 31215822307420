import { useCallback } from "react";

import { FormStore, useFormContext } from "../form";
import { getGetterByPath } from "../getGetterByPath";

import { InitialFn, ParametersOmitFirst } from "./types";

export const manipulateArray =
  <T, Fn extends InitialFn<T>>(useStore: FormStore<any>, name: string, fn: Fn) =>
  (...args: ParametersOmitFirst<Fn>) => {
    const state = useStore.getState();
    const getter = getGetterByPath(name);

    const arrayState = getter(state.values) as T[];
    const arrayErrors = getter(state.errors) as any[];
    const arrayTouched = getter(state.touched) as boolean[];

    const applyFn = (values: T[]) => fn(values, ...args);

    if (arrayState || arrayErrors || arrayTouched) {
      state.setField(name, {
        value: !arrayState ? undefined : applyFn(arrayState),
        error: !arrayErrors ? undefined : (applyFn(arrayErrors) as any),
        touched: !arrayTouched ? undefined : (applyFn(arrayTouched as any[]) as any),
      });
    }
  };

export const useManipulateArray = <T>(name: string) => {
  const { useStore } = useFormContext();
  return useCallback(<Fn extends InitialFn<T>>(fn: Fn) => manipulateArray<T, Fn>(useStore, name, fn), [useStore, name]);
};
