import { BaseOption } from "./Select";

export const findSelectValue = (
  options: BaseOption[],
  value: Array<string | number | undefined> | string | number | undefined,
  isMulti: boolean | undefined,
) => {
  if (isMulti) {
    return Array.isArray(value) && value?.length > 0
      ? options.reduce((acc, curr) => {
          const found = curr.options
            ? findSelectValue(curr.options, value, isMulti)
            : value.includes(curr.value)
            ? curr
            : null;
          return found ? acc.concat(found) : acc;
        }, [] as BaseOption[])
      : [];
  } else {
    let res: string | BaseOption = "";

    if (value !== null && value !== undefined) {
      for (const o of options) {
        const v = o.options ? findSelectValue(o.options, value, isMulti) : value === o.value ? o : null;
        if (v) {
          res = v;
          break;
        }
      }
    }

    return res;
  }
};
