import React from "react";
import { useTranslation } from "react-i18next";

import "twin.macro";
import "styled-components/macro";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllMachineGroupsFilteredDocument, useUpdateMachineGroupMutation } from "generated";

import { MultipleAdd } from "./MultipleAddDialog";

type Props = {
  nodeId: string;
  name: string;
  onClose?: () => void;
};

export const AddBulkMachinesToGroup: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const { t } = useTranslation();
  const [updateMachineGroup, { loading, error }] = useUpdateMachineGroupMutation();

  const handleSubmit = async (data: string[]) => {
    try {
      await updateMachineGroup({
        variables: {
          nodeId,
          input: {
            name,
            machineUuids: data,
          },
        },
        // ignoreResults: true,
        update: (cache) => {
          evictQuery(FindAllMachineGroupsFilteredDocument, cache);
        },
      });
      useToast.actions.show("administration.mg.updateSuccess", { variant: "success" });
      onClose?.();
    } catch (e) {
      useToast.actions.show("administration.mg.updateApiError", { variant: "error" });
    }
  };

  return (
    <MultipleAdd
      info={<span>{t("administration.mg.multipleAddToGroup", { name })}</span>}
      loading={loading}
      callToAction={t("administration.mg.multipleAdd")}
      onClose={onClose}
      onDataReady={handleSubmit}
      error={error?.message}
    />
  );
};
