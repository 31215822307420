import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as CopySVG } from "assets/icons/Copy.svg";
import { FullLine, Text, useToast } from "common/guideline";
import { copyToClipboard } from "common/helpers";

type LicenseProps = {
  edition: string;
  active: boolean;
  licenseKey?: TKeys;
};

export const LicenseInfo: React.FC<LicenseProps> = (current: LicenseProps) => {
  return current.edition !== "BASIC" ? (
    <div tw="break-words">
      <span>
        <b>{current.edition}</b>
        {" - "}
        <Text
          tw="opacity-70"
          tKey={current.active === true ? "machine.licensing.currentlyActive" : "machine.licensing.currentlyInactive"}
        />
      </span>
      <FullLine variant="transparent" />
      <span>
        <b>
          <Text tKey="machine.licensing.licenseKey" />
        </b>
        {" - "}
        <Text tKey={current.licenseKey} />
        <CopySVG
          tw="cursor-pointer"
          width={20}
          height={20}
          onClick={() => copyToClipboard(current.licenseKey || "", () => useToast.actions.show("common.clipboard"))}
        />
      </span>
    </div>
  ) : (
    <Text tKey="machine.licensing.edition.basic" />
  );
};
