import { TFunction } from "i18next";

import { useToast } from "../Toast";

export const csvLimitExceeded = (fullSize: number, limit: number, t: TFunction, showErrorModal = true) => {
  const isLimitExceeded = fullSize > limit;

  if (showErrorModal && isLimitExceeded) {
    useToast.actions.show(t("common.table.csvLimitExceeded", { value: limit, minimumFractionDigits: 0 }), {
      variant: "error",
    });
  }

  return isLimitExceeded;
};
