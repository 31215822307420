import { startOfDay } from "date-fns";
import { useState } from "react";

import { DateInputProps, DateInputRaw } from "common/form/renderFields";

export const useDateFilter = <T extends "" | Date = Date>(
  props?: Partial<DateInputProps & { initialDate: T; isClearable: boolean }>,
): [T | Date, JSX.Element] => {
  const [date, setDate] = useState(() => (props?.initialDate ?? startOfDay(new Date())) as T);

  return [
    date,
    <DateInputRaw
      key="dateFilter"
      name="date"
      type="date"
      value={date}
      onChange={(v) => (v || props?.isClearable) && setDate(v)}
      {...props}
    />,
  ];
};
