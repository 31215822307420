import { ColumnFiltersState, HeaderGroup, TableState, Updater, flexRender } from "@tanstack/react-table";
import tw from "twin.macro";
import "styled-components/macro";

import { Button } from "../../Button";
import { DrawerBottom, ModalComponent, useModalState } from "../../Modal";
import { Text } from "../../Text";

type Props = {
  getState: () => TableState;
  setColumnFilters: (updater: Updater<ColumnFiltersState>) => void;
  getHeaderGroups: () => HeaderGroup<any>[];
};

const FilteringDrawer: React.FC<Props> = (props) => (
  <div>
    <Text variant="paragraph" tKey="common.table.filter.filters" />

    <div tw="overflow-auto">
      {props.getHeaderGroups().flatMap((h) =>
        h.headers.map((i) =>
          i.column.getCanFilter() && i.column.columnDef.meta?.filter ? (
            <div key={i.id} tw="flex flex-col pt-2 space-y-1">
              {flexRender(i.column.columnDef.header, i.getContext())}
              {flexRender(i.column.columnDef.meta?.filter, i.getContext())}
            </div>
          ) : null,
        ),
      )}

      <div tw="flex justify-end pt-2">
        <Button variant={["side", "sm"]} onClick={() => props.setColumnFilters(() => [])} data-test="resetFilters">
          <Text tKey="common.table.filter.reset" />
        </Button>
      </div>
    </div>
  </div>
);

export const Filtering: React.FC<Props> = (props) => {
  const [open, id, toggleModal] = useModalState();

  return (
    <>
      <Button variant={["light", "sm"]} tw="relative" onClick={() => toggleModal()} data-test="showFiltersModal">
        <Text tKey="common.table.filter.filter" />
        {props.getState().columnFilters.length > 0 && (
          <span tw="absolute -top-1 -right-1 h-2 w-2 bg-primary-default rounded-full" />
        )}
      </Button>

      {open && (
        <ModalComponent id={id} requestClose={() => toggleModal()} variant="drawerBottom" Modal={DrawerBottom}>
          <FilteringDrawer {...props} />
        </ModalComponent>
      )}
    </>
  );
};
