import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as NetCashSVG } from "assets/icons/NetCash.svg";
import { PageLayout, useFilteredLocationNodeIds, useMappedSiteGroupContext, useTimeZoneFilter } from "base/components";
import {
  DateRangeInfo,
  Table,
  TableDownload,
  TableTypes,
  tableDownloadTitles,
  useDateFilter,
  useDateZoned,
  useNumberFilter,
  useSelectMultiFilter,
} from "common/guideline";
import { GenerateNetCashReportQuery, RmTransactionType, useGenerateNetCashReportQuery } from "generated";
import { FilterBox, useBooleanFilter } from "report/components";

import { breadcrumbs } from "./breadcrumbs";
import { getTotalAmountsNetCashRows } from "./totalAmountsNetCashRows";

type NetCashRow = NonNullable<
  NonNullable<NonNullable<GenerateNetCashReportQuery["generateNetCashReport"]>["netCashReportRowDtos"]>[0]
>;

const getColumns: TableTypes.TranslatedColumns<NetCashRow> = (t) => [
  {
    header: t("machine.machine_one"),
    accessorKey: "machineName",
  },
  {
    header: t("location.location_one"),
    accessorKey: "locationName",
  },
];

const toDate = new Date();
const initialDate = new Date();

const transactionTypeOptions: Array<{ label: string; value: RmTransactionType }> = [
  { label: "tranTypes.DISPENSE", value: "DISPENSE" },
  { label: "tranTypes.DEPOSIT", value: "DEPOSIT" },
  { label: "tranTypes.EMPTY_OUT", value: "EMPTY_OUT" },
  { label: "tranTypes.END_OF_SHIFT", value: "END_OF_SHIFT" },
  { label: "tranTypes.MANUAL_REFILL", value: "MANUAL_REFILL" },
  { label: "tranTypes.EXCHANGE", value: "EXCHANGE" },
  { label: "tranTypes.PURGE", value: "PURGE" },
  { label: "tranTypes.POSSIBLE_TAMPERING", value: "POSSIBLE_TAMPERING" },
  { label: "tranTypes.UNFINISHED_TRANSACTION", value: "UNFINISHED_TRANSACTION" },
  { label: "tranTypes.REFILL", value: "REFILL" },
];

const TABLE_NAME = "NetCashReport";

export const NetCash = () => {
  const { t, i18n } = useTranslation();
  const [durationInDays, NumberFilter] = useNumberFilter();
  const [date, DateFilter] = useDateFilter({
    label: "common.rDate",
    dateFormat: "datetime",
    toDate,
    initialDate,
  });
  const [zoneId, TimeZone] = useTimeZoneFilter(false);
  const zonedDate = useDateZoned(date, zoneId);
  const [{ machine }] = useMappedSiteGroupContext(true);

  const [transactionTypes, transactionTypesSelect] = useSelectMultiFilter({
    initial: [],
    options: transactionTypeOptions,
    name: "filterByTransactionTypes",
    label: "report.netCash.transactionType",
  });
  const [showTransactions, showTransactionsSelect] = useBooleanFilter(
    "showTransactions",
    "report.netCash.filterByTransactionTypes",
  );

  const filteredLocationNodeIds = useFilteredLocationNodeIds();

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateNetCashReportQuery({
    variables: {
      input: {
        reportName: "NetCashReport",
        referenceDate: zonedDate,
        machineNodeIds: machine,
        locationNodeIds: filteredLocationNodeIds,
        durationInDays,
        zoneId,
        transactionType: showTransactions ? transactionTypes : [],
      },
    },
  });

  const rows = data?.generateNetCashReport?.netCashReportRowDtos as NetCashRow[] | undefined;

  const columns = useMemo(
    () => [...getColumns(t, i18n.language), ...getTotalAmountsNetCashRows(rows || [], t, true)],
    [rows, t, i18n.language],
  );

  return (
    <PageLayout
      title="report.netCash.title"
      subtitle="report.netCash.desc"
      Icon={NetCashSVG}
      breadcrumbs={breadcrumbs}
      withPicker
    >
      <div tw="flex flex-row gap-2">
        <FilterBox>
          {DateFilter}
          {NumberFilter}
          {TimeZone}
        </FilterBox>
        <div tw="flex gap-2 h-16">
          {showTransactionsSelect}
          {showTransactions ? (
            <div tw="flex items-center">
              <div tw="w-72 z-[2]">{transactionTypesSelect}</div>
            </div>
          ) : null}
        </div>
      </div>
      <DateRangeInfo from={zonedDate} timeZone={zoneId} />
      <Table
        tableName={TABLE_NAME}
        data={rows || []}
        columns={columns}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDate(zonedDate, "report.netCash.title", t),
                page,
              )
            }
            disabled={!rows?.length}
            getCsv
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
