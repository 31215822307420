import tw from "twin.macro";
import "styled-components/macro";

import { ActionButton } from "base/components";
import { TableTypes, useModalComponent } from "common/guideline";

import { DeleteOTA } from "./DeleteOTA";
import { OTAData } from "./types";

export const OTATableRowActions: React.FC<TableTypes.VerticalHeaderProps<OTAData>> = ({ row }) => {
  const isInactive = row.original?.nodeStatus === "INACTIVE";
  const [show] = useModalComponent({
    Component: <DeleteOTA nodeId={row.original?.nodeId as string} />,
  });

  return isInactive ? null : (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
    </div>
  );
};
