import { useMemo, useRef, useState } from "react";

import {
  SelectLiveFieldUseConfig,
  SelectLiveFieldValueArg,
  TransferBoxLiveFieldUseConfig,
  TransferBoxLiveFieldValueArg,
  getSelectLiveField,
  getTransferBoxLiveField,
} from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useDebouncedValue, useMappedQuery } from "common/hooks";
import { FindAllMachinesQuery, MachineFiltersDto, useFindAllMachinesQuery } from "generated";

type MachineOptions = {
  label: string;
  value: string;
  data: FilteredMachineResult;
};

type FilteredMachineResult = NonNullable<NonNullable<FindAllMachinesQuery["findAllMachines"]>["result"]>[0] & {
  __typename: "MachineDtoOut";
};

export type UseCustomMachinesFilters = () => MachineFiltersDto;
type GetOptionValue = (value: FilteredMachineResult) => string;

const LIST_SIZE = 10;

const getUseConfig = (
  useFilters: UseCustomMachinesFilters,
  getOptionValue?: GetOptionValue,
): SelectLiveFieldUseConfig => {
  return function useConfig(previous) {
    const value = useFieldData<string | string[] | undefined>(previous.name, "values");
    const listSizeRef = useRef(LIST_SIZE);
    const [filterText, setFilterText] = useState("");
    const debouncedFilterText = useDebouncedValue(filterText);
    const currentValueNodeIds = Array.isArray(value) ? value : value ? [value] : [];

    const [pickedOptions = [], { loading: firstLoading }] = useMappedQuery(
      (data, previousData) => {
        const result = ((data ?? previousData)?.findAllMachines?.result ?? []) as FilteredMachineResult[];

        return result?.flatMap((p) =>
          p ? [{ label: p.name ?? "", value: getOptionValue ? getOptionValue(p) : p.nodeId ?? "", data: p }] : [],
        ) as MachineOptions[];
      },
      useFindAllMachinesQuery({
        skip: !value || !value.length,
        variables: {
          machineFilters: {
            nodeIds: currentValueNodeIds,
          },
          searchRequest: {
            page: 0,
            size: currentValueNodeIds.length,
            sort: [{ fieldName: "name", order: "ASC" }],
          },
        },
      }),
    );

    const filters = useFilters();

    const [machineData = previous, { loading, refetch }] = useMappedQuery(
      (data, previousData) => {
        const response = data ?? previousData;
        const result = (response?.findAllMachines?.result ?? []) as FilteredMachineResult[];
        const fullSize = response?.findAllMachines?.fullSize ?? 0;
        const options: MachineOptions[] =
          result.flatMap((p) =>
            p ? [{ label: p.name || "", value: getOptionValue ? getOptionValue(p) : p.nodeId ?? "", data: p }] : [],
          ) ?? [];

        return {
          ...previous,
          isLoading: false,
          options,
          onMenuScrollToBottom: () => {
            if (options.length >= fullSize) return;

            listSizeRef.current += LIST_SIZE;
            refetch({ searchRequest: { page: 0, size: listSizeRef.current } });
          },
        };
      },
      useFindAllMachinesQuery({
        variables: {
          machineFilters: {
            ...filters,
            name: debouncedFilterText || undefined,
          },
          searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
        },
      }),
      true,
    );

    const options = useMemo(
      () =>
        [
          ...pickedOptions,
          ...(
            (machineData?.options as MachineOptions[])?.filter(
              (o) => !pickedOptions.find((p) => p.value === (o as any).value),
            ) ?? []
          ).sort(makeSorter("label")),
        ] as MachineOptions[],
      [machineData, pickedOptions],
    );

    return [{ ...machineData, options, onInputChange: setFilterText, isLoading: firstLoading || loading }, true];
  };
};

export const getMachinesFilterFieldWithFilters = (
  value: SelectLiveFieldValueArg,
  useFilters: UseCustomMachinesFilters,
  getOptionValue?: GetOptionValue,
) =>
  getSelectLiveField(
    {
      label: "machine.machine",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    getUseConfig(useFilters, getOptionValue),
  );

export const getMachinesFilterField = (value: SelectLiveFieldValueArg, getOptionValue?: GetOptionValue) =>
  getMachinesFilterFieldWithFilters(value, () => ({}), getOptionValue);

export const useSingleMachineByNodeId = (nodeId?: string | null) => {
  const [machine] = useMappedQuery(
    (data, previousData) => {
      const result = ((data ?? previousData)?.findAllMachines?.result ?? []) as FilteredMachineResult[];
      return result[0];
    },
    useFindAllMachinesQuery({
      skip: !nodeId,
      variables: {
        machineFilters: {
          nodeIds: nodeId ? [nodeId] : [],
        },
        searchRequest: {
          page: 0,
          size: 1,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
    }),
  );

  return machine;
};

const TRANSFER_BOX_LIST_SIZE = 20;
export const useConfigGetMachinesTransferBox: TransferBoxLiveFieldUseConfig = (prev) => {
  const value = useFieldData<string | undefined>(prev.name, "values");
  const listSizeRef = useRef(TRANSFER_BOX_LIST_SIZE);
  const [filterText, setFilterText] = useState("");
  const debouncedFilterText = useDebouncedValue(filterText);
  const [pickedOptions = []] = useMappedQuery(
    (data, previousData) => {
      const result = ((data ?? previousData)?.findAllMachines?.result ?? []) as FilteredMachineResult[];

      return result?.flatMap((p) =>
        p ? [{ label: p.name ?? "", value: p.nodeId ?? "", data: p }] : [],
      ) as MachineOptions[];
    },
    useFindAllMachinesQuery({
      skip: !value,
      variables: {
        machineFilters: {
          nodeIds: value ? [value] : [],
        },
        searchRequest: {
          page: 0,
          size: 1,
          sort: [{ fieldName: "name", order: "ASC" }],
        },
      },
    }),
  );

  const [machineData = prev, { refetch }] = useMappedQuery(
    (data, previousData) => {
      const response = data ?? previousData;
      const result = (response?.findAllMachines?.result ?? []) as FilteredMachineResult[];
      const fullSize = response?.findAllMachines?.fullSize ?? 0;
      const options: MachineOptions[] =
        result.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "", data: p }] : [])) ?? [];

      return {
        ...prev,
        isLoading: false,
        options,
        fullSize,
        onScrollToBottom: () => {
          if (options.length < fullSize) {
            listSizeRef.current += TRANSFER_BOX_LIST_SIZE;
            refetch({
              searchRequest: {
                page: 0,
                size: listSizeRef.current,
                sort: [{ fieldName: "name", order: "ASC" }],
              },
            });
          }
        },
      };
    },
    useFindAllMachinesQuery({
      variables: {
        machineFilters: {
          name: debouncedFilterText || undefined,
        },
        searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
      },
    }),
    true,
  );

  const options = useMemo(
    () =>
      [
        ...pickedOptions,
        ...(
          (machineData?.options as MachineOptions[])?.filter(
            (o) => !pickedOptions.find((p) => p.value === (o as any).value),
          ) ?? []
        ).sort(makeSorter("label")),
      ] as MachineOptions[],
    [machineData, pickedOptions],
  );

  return [
    {
      ...machineData,
      isLoading: false,
      options,
      onSearch: setFilterText,
      fullSize: (machineData.fullSize ?? 0) - (value?.length ?? 0),
    },
    true,
  ];
};

export const getMachinesTransferBoxField = (value: TransferBoxLiveFieldValueArg) =>
  getTransferBoxLiveField(
    {
      label: "machine.machine",
      isLoading: true,
      ...value,
    },
    useConfigGetMachinesTransferBox,
  );
