import tw from "twin.macro";
import "styled-components/macro";

import { Badge } from "common/guideline";
import { PieChart } from "components/Charts";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

const data = [
  {
    x: "Bankable",
    y: 24,
  },
  {
    x: "Dispensable",
    y: 76,
  },
];

export const CashInventory = () => (
  <Box tw="col-span-2">
    <div tw="flex flex-col">
      <BoxHeading>Cash inventory</BoxHeading>
      <div tw="flex items-center flex-row">
        <div tw="hidden sm:(block w-1/3 p-5)">
          <div tw="mb-3">
            <h4 tw="font-semibold">Bankable</h4>
            <div tw="flex items-center">
              <span tw="inline-flex text-3xl font-semibold text-success-default">{data[0].y}</span>
              <Badge tw="ml-1.5">USD</Badge>
            </div>
          </div>
          <div>
            <h4 tw="font-semibold">Dispensable</h4>
            <div tw="flex items-center">
              <span tw="inline-flex text-3xl font-semibold text-error-default">{data[1].y}</span>
              <Badge tw="ml-1.5">USD</Badge>
            </div>
          </div>
        </div>
        <div tw="w-full h-52 sm:w-2/3">
          <PieChart data={data} />
        </div>
      </div>
    </div>
  </Box>
);
