import { CellContext } from "@tanstack/react-table";

import { Badge, Text } from "common/guideline";
import { ErrorsReportRowDto, RmSeverity } from "generated";

type Props = {
  value?: ErrorsReportRowDto["severity"];
  getValue?: CellContext<any, any>["getValue"];
};

const statusToBadgeVariant = {
  Error: "error",
  Warning: "warning",
} as const;

export const RmSeverityBadge = ({ value: _value, getValue }: Props) => {
  const value = (_value || getValue?.()) as ErrorsReportRowDto["severity"];
  return (
    <Badge variant={statusToBadgeVariant[value as RmSeverity] || "neutral"}>
      <Text tValue={{ defaultValue: value }}>{`machine.severityType.${value}`}</Text>
    </Badge>
  );
};
