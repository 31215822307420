import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "MACHINE_GROUPS",
    text: "administration.mg.title",
  },
  {
    route: "MACHINE_GROUP_CREATE",
    text: "administration.mg.create",
  },
];
