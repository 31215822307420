import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { TableTypes, useModalComponent } from "common/guideline";

import { UpdateConfigTemplate } from "./ConfigTemplateForm";
import { DeleteConfigTemplate } from "./DeleteConfigTemplate";
import { ConfigTemplateFetched } from "./types";

export const RowActions: React.FC<TableTypes.VerticalHeaderProps<ConfigTemplateFetched>> = ({ row }) => {
  const [showUpdate] = UpdateConfigTemplate(row.original as WithoutNullableKeys<ConfigTemplateFetched>);
  const [show] = useModalComponent({
    Component: (
      <DeleteConfigTemplate
        nodeId={row.original.nodeId as string}
        name={row.original.name as string}
        fileName={row.original.fileName as string}
      />
    ),
  });

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => show()} />
      <ActionButton.Edit onClick={() => showUpdate()} />
    </div>
  );
};
