import { Certificate } from "@phosphor-icons/react";
import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { NamesListTooltip, NodeStatusBadge } from "base/components";
import { Badge, CommonCells, Table, TableTypes, accessors } from "common/guideline";
import { useFindAllTenantsQuery } from "generated";
import { useTranslation } from "i18n";

import { RowActions } from "./RowActions";
import { TenantVerticalHeader } from "./TenantVerticalHeader";
import { TenantData } from "./types";

const getColumns: TableTypes.TranslatedColumns<TenantData> = (t) => [
  {
    header: t("tenant.list.tenantId"),
    id: "tenantId",
    accessorKey: "tenantId",
  },
  {
    header: t("tenant.list.name"),
    accessorKey: "name",
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("tenant.list.status"),
    accessorKey: "nodeStatus",
    cell: NodeStatusBadge,
  },
  {
    header: t("tenant.list.changed"),
    accessorFn: (d) => accessors.date(d.nodeChangeDate, t),
  },
  {
    header: t("tenant.create.contractNumber"),
    id: "contractNumber",
    accessorKey: "contractNumber",
  },
  {
    header: t("tenant.create.endOfDay"),
    id: "endOfDay",
    accessorKey: "endOfDay",
  },
  {
    header: t("machine.machineType_other"),
    accessorKey: "machineTypes",
    enableSorting: false,
    cell({ getValue }) {
      const value = getValue<TenantData["machineTypes"]>() ?? [];
      return (
        <NamesListTooltip
          count={value.length}
          names={value.map((v) => v?.name as string)}
          Icon={ComputerTowerSVG}
          data-test="showMachineTypes"
        >
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("machine.licensing.allowedLicenseFeatureKeys"),
    accessorKey: "allowedLicenseFeatureKeys",
    enableSorting: false,
    cell({ getValue }) {
      const value = getValue<TenantData["allowedLicenseFeatureKeys"]>() ?? [];
      return (
        <NamesListTooltip
          count={value.length}
          names={value as string[]}
          Icon={Certificate}
          data-test="allowedLicenseFeatureKeys"
        >
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("tenant.create.licenseAutoRenewalEnabled"),
    accessorKey: "licenseAutoRenewalEnabled",
    cell({ getValue }) {
      const value = getValue<TenantData["licenseAutoRenewalEnabled"]>() ?? false;
      return <Badge variant={value ? "success" : "error"}>{t(value ? "common.yes" : "common.no")}</Badge>;
    },
  },
  {
    header: t("tenant.create.licenseValidityIntervalDays"),
    accessorKey: "licenseValidityIntervalDays",
  },

  CommonCells.getActionsCell<TenantData>({ size: 120, cell: (c) => <RowActions row={c.row} /> }),
];

export const TenantTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const { previousData, data = previousData, loading, error } = useFindAllTenantsQuery();
  const tenants = (data?.findAllTenants as TenantData[]) || [];

  return (
    <Table
      tableName="tenants"
      columns={tColumns}
      data={tenants}
      VerticalHeader={TenantVerticalHeader}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
    />
  );
};
