import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Text } from "common/guideline";

export const CashMetrics = () => (
  <div tw="grid gap-4 mt-6 md:grid-cols-2 lg:grid-cols-3">
    <Box>
      <BoxHeading>
        <Text tKey="machine.availableForDispense" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
    <Box>
      <BoxHeading>
        <Text tKey="machine.notDispensable" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
    <Box>
      <BoxHeading>
        <Text tKey="machine.cashInCirculation" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
    <Box>
      <BoxHeading>
        <Text tKey="machine.netCashChange" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
    <Box>
      <BoxHeading>
        <Text tKey="machine.availableCashChange" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
    <Box>
      <BoxHeading>
        <Text tKey="machine.openShifts" />
      </BoxHeading>
      <BoxContent variant="iconWrapper">
        <Text variant="xl" tKey="common.notAvailable" />
      </BoxContent>
    </Box>
  </div>
);
