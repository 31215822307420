import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { Navigate, Outlet } from "appRouting";
import { useAuth } from "auth/hooks";
import { screen, useBreakpoints } from "common/guideline";

import { Logo, Navbar, Sidebar } from "../components";

const Wrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  main {
    grid-column: 1 / -1;
  }

  ${screen.min("md")} {
    grid-template-columns: auto 1fr;

    section {
      grid-column: 1 / -1;
    }

    main {
      grid-column: initial;
    }
  }
`;

export const Layout = () => {
  const displayNavbar = useBreakpoints().min.md;
  const user = useAuth(useAuth.actions.getUser);

  return !user ? (
    <Navigate route="LOGIN" />
  ) : (
    <Wrapper>
      <section tw="h-12 sm:h-20 inline-flex justify-between px-4 py-2 shadow-blue z-20 print:shadow-none">
        <Logo />
        {displayNavbar && <Navbar />}
      </section>
      <Sidebar />
      <main tw="flex flex-col h-full overflow-hidden">
        <Outlet />
      </main>
    </Wrapper>
  );
};
