import tw, { styled } from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllCorrectionReasonsDocument,
  useCreateCorrectionReasonMutation,
  useUpdateCorrectionReasonMutation,
} from "generated";

import { CorrectionReasonFetched } from "../types";

type FormData = { name: string; active: boolean };

type Props = {
  onSubmit: OnSubmit<FormData>;
  initialData?: Partial<FormData>;
};

const Wrapper = styled.div`
  ${tw`space-y-4`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      {
        type: "text",
        name: "name",
        label: "transport.corReasons.form.name",
        validate: { type: "string", required: true },
      },
      { type: "checkbox", name: "active", label: "transport.corReasons.form.active", defaultValue: true },
    ],
  },
];

const CorrectionReasonsForm: React.FC<Props> = ({ onSubmit, initialData }) => (
  <>
    <Text variant="heading" tw="pb-4 block" tKey={`transport.corReasons.form.${initialData ? "update" : "create"}`} />
    <SchemaForm<FormData>
      onSubmit={onSubmit}
      fields={fields}
      customRender={customRender}
      initial={initialData}
      SubmitComponent={() => (
        <div tw="flex justify-end mt-6">
          <Button type="submit" data-test="submitForm">
            <Text tKey={`transport.corReasons.form.${initialData ? "updateSubmit" : "createSubmit"}`} />
          </Button>
        </div>
      )}
    />
  </>
);

export const useEditCorrectionReasonModal = ({
  nodeId,
  name,
  active,
}: WithoutNullableKeys<CorrectionReasonFetched>) => {
  const [updateCorrection] = useUpdateCorrectionReasonMutation({ ignoreResults: true });

  return useModalComponent({
    Component: (
      <CorrectionReasonsForm
        initialData={{ name, active }}
        onSubmit={(correctionReason) =>
          updateCorrection({ variables: { nodeId, correctionReason } })
            .then(() => useToast.actions.show("transport.corReasons.updateSuccess", { variant: "success" }))
            .catch(() => useToast.actions.show("transport.corReasons.updateError", { variant: "error" }))
        }
      />
    ),
  });
};

export const useCreateCorrectionReasonModal = () => {
  const [createCorrection] = useCreateCorrectionReasonMutation({
    ignoreResults: true,
    update: (cache) => evictQuery(FindAllCorrectionReasonsDocument, cache),
  });

  const modalData = useModalComponent({
    Component: (
      <CorrectionReasonsForm
        onSubmit={(correctionReason) =>
          createCorrection({ variables: { correctionReason }, onCompleted: () => modalData[0]() })
            .then(() => useToast.actions.show("transport.corReasons.createSuccess", { variant: "success" }))
            .catch(() => useToast.actions.show("transport.corReasons.createError", { variant: "error" }))
        }
      />
    ),
  });

  return modalData;
};
