import { VisibilityState } from "@tanstack/react-table";
import { useEffect, useState } from "react";

import { getTableStorageData } from "../../common/guideline/components/Table/TableStorageConfig";

const calculateState = (columnNames: ReadonlyArray<string>, visible: boolean) =>
  columnNames.reduce((state, column) => ({ ...state, [column]: visible }), {});

export const useVisibilityColumnState = (tableName: string, columnNames: ReadonlyArray<string>, visible: boolean) => {
  const [columnsVisibility, setColumnsVisibility] = useState<VisibilityState>(calculateState(columnNames, visible));

  useEffect(() => {
    setColumnsVisibility((currentState) => ({
      ...currentState,
      ...calculateState(columnNames, visible),
    }));
  }, [visible, columnNames]);

  const { columnVisibility: storageColumnVisibility } = getTableStorageData(tableName) ?? {};

  return { columnsVisibility: { ...storageColumnVisibility, ...columnsVisibility }, setColumnsVisibility };
};
