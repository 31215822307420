import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { client } from "client";
import { Container, useToast } from "common/guideline";
import { useMappedQuery } from "common/hooks";
import {
  FindAllLocationGroupsFilteredQuery,
  FindAllMachinesDocument,
  FindAllMachinesQuery,
  FindAllMachinesQueryVariables,
  useFindAllLocationGroupsFilteredQuery,
  useUpsertSmartLocationGroupMutation,
} from "generated";

import { LocationGroupForm, LocationGroupFormData } from "../components/LocationGroupForm";

import { breadcrumbs } from "./breadcrumbs_update";
import { LocationGroupDataFull, MachineResult } from "./types";

export type LocationGroupUpdateParams = { nodeId: string };

type InitialValues = LocationGroupFormData | undefined;

const getInitialValues = ({
  findAllLocationGroupsFiltered,
}: FindAllLocationGroupsFilteredQuery | undefined = {}): InitialValues => {
  const locationGroup = findAllLocationGroupsFiltered?.result?.[0] as LocationGroupDataFull;
  const locationNodeIds = locationGroup?.locations?.map((l) => l?.nodeId).filter(Boolean) as string[] | undefined;

  if (locationGroup) {
    return {
      nodeId: locationGroup?.nodeId,
      name: locationGroup?.name ?? "",
      authorizedUsers: locationGroup?.authorizedUsers,
      countries: locationGroup?.countries,
      cities: locationGroup?.cities,
      locationNodeIds,
    };
  }
};

export const LocationGroupUpdate = () => {
  const { nodeId = "" } = useParams<LocationGroupUpdateParams>();
  const [updateLocationGroup] = useUpsertSmartLocationGroupMutation();

  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindAllLocationGroupsFilteredQuery({
      skip: !nodeId,
      variables: { searchRequest: { page: 0, size: 1 }, locationGroupFilters: { nodeIds: [nodeId] } },
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="administration.lg.update"
      subtitle="administration.lg.updateDescription"
    >
      <Container variant="pageWrapper">
        {loading ? null : (
          <LocationGroupForm
            submitLabel="administration.lg.update"
            initialData={mappedData}
            onSubmit={({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              timeOption,
              remoteMgmtOption,
              templateNodeId,
              softwarePackageNodeId,
              scheduleInfo,
              createdBy,
              ...smartGroup
            }) =>
              updateLocationGroup({
                variables: { smartGroup },
                ignoreResults: true,
              })
                .then(async (v) => {
                  if (!v.data?.upsertSmartLocationGroup?.nodeId) throw new Error();

                  const locations = v.data?.upsertSmartLocationGroup?.locations || [];
                  const machineNodeIds = locations.flatMap((l) => l?.machineNodeIds ?? []).filter(Boolean);
                  const machinesQuery = await client.query<FindAllMachinesQuery, FindAllMachinesQueryVariables>({
                    query: FindAllMachinesDocument,
                    variables: {
                      searchRequest: { page: 0, size: machineNodeIds.length },
                      machineFilters: { nodeIds: machineNodeIds },
                    },
                  });

                  const machineUuids = machinesQuery.data?.findAllMachines?.result?.map?.(
                    (m) => (m as MachineResult)?.uuid,
                  );

                  useToast.actions.show("administration.lg.updateSuccess", { variant: "success" });

                  performRemoteMgmtOperation(
                    remoteMgmtOption,
                    machineUuids,
                    templateNodeId,
                    softwarePackageNodeId,
                    scheduleInfo,
                    createdBy,
                  );

                  navigateTo({ route: "LOCATION_GROUPS" });
                })
                .catch(() => useToast.actions.show("administration.lg.error", { variant: "error" }))
            }
          />
        )}
      </Container>
    </PageLayout>
  );
};
