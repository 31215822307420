import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as TransactionsSVG } from "assets/icons/Transactions.svg";
import {
  PageLayout,
  SelectWrapper,
  useMappedSiteGroupContext,
  useQueryByDateFilter,
  useTimeZoneFilter,
} from "base/components";
import { client } from "client";
import {
  Badge,
  CommonCells,
  CustomGetCsvFn,
  DateRangeInfo,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  csvLimitExceeded,
  tableDownloadTitles,
  useDateRangeFilter,
  useDateRangeZoned,
  usePagination,
} from "common/guideline";
import { DateRangeDuration, withDefault } from "common/helpers";
import {
  GenerateTransactionsReportDocument,
  GenerateTransactionsReportQuery,
  GenerateTransactionsReportQueryVariables,
  TransactionType,
  TransactionsReportCriteriaDtoIn,
  TransactionsReportOrderColumn,
  useGenerateTransactionsReportQuery,
} from "generated";
import { FilterBox, getColumnFilter, useColumnFilters } from "report/components";
import { getDenominationRows, getTotalAmountsPairRows } from "report/helpers";
import { SortByColumnsData, useSorting, useVisibilityColumnState } from "report/hooks";
import { PreLineCell, TransactionSubType, TransactionTypeCell } from "transport/components";

import { breadcrumbs } from "./breadcrumbs";
import { useQueryByTransactionTypeFilter } from "./components/QueryByTransactionTypeFilter";
import { TransactionsSubRow } from "./TransactionsSubRow";

type TransactionRowsDefault = NonNullable<
  NonNullable<NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["rows"]>[0]
>;

type RowsReport = TransactionRowsDefault & { __typename: "TransactionsReportRowDto" };
type RowsCorrection = TransactionRowsDefault & { __typename: "CorrectionTransactionReportRowDto" };

export type TransactionRows = Omit<RowsReport, "__typename"> &
  Omit<RowsCorrection, "__typename"> & { __typename: "TransactionsReportRowDto" | "CorrectionTransactionReportRowDto" };

type Filters = NonNullable<GenerateTransactionsReportQuery["generateTransactionsReport"]>["columns"];

const getColumns: (
  filters: Filters,
) => TableTypes.TranslatedColumns<TransactionRows, SortByColumnsData<TransactionsReportOrderColumn>> =
  (filters) => (t) =>
    [
      CommonCells.expander,
      {
        header: t("tranReport.machine"),
        id: "MACHINE",
        accessorKey: "machineName",
      },
      {
        header: t("machine.machineType_one"),
        enableSorting: false,
        accessorKey: "machineType",
        cell({ getValue }) {
          return withDefault(getValue() ? <Badge>{getValue<string>()}</Badge> : undefined);
        },
      },
      {
        header: t("tranReport.subtype"),
        accessorKey: "subType",
        enableSorting: false,
        id: "SUB_TYPE",
        cell: TransactionSubType,
        meta: {
          filter: getColumnFilter(filters, "subType"),
        },
      },
      {
        header: t("tranReport.type"),
        accessorKey: "type",
        id: "TYPE",
        cell: TransactionTypeCell,
        meta: {
          filter: getColumnFilter(filters, "type"),
        },
      },
      {
        header: t("tranReport.accountingDate"),
        id: "ACCOUNTING_DATE",
        cell: PreLineCell,
        accessorFn: (v) => accessors.zonedDateTime(v.accountingDate, t),
        meta: {
          csv: {
            accessorFn: (v) => v.accountingDate,
          },
        },
      },
      {
        header: t("tranReport.formattedDateTime"),
        id: "FORMATTED_DATE",
        enableSorting: false,
        cell: PreLineCell,
        accessorFn: (v) => accessors.zonedDateTime(v.formattedDateTime, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedDateTime, t),
          },
        },
      },
      {
        header: t("tranReport.formattedAccountingDate"),
        id: "FORMATTED_ACCOUNTING_DATE_TIME",
        enableSorting: false,
        cell: PreLineCell,
        accessorFn: (v) => accessors.zonedDateTime(v.formattedAccountingDate, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedAccountingDate, t),
          },
        },
      },
      {
        header: t("tranReport.machineTime"),
        accessorFn: (v) => accessors.zonedDateTime(v.dateTime, t),
        enableSorting: false,
        cell: PreLineCell,
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.dateTime, t),
          },
        },
      },
      {
        header: t("tranReport.site"),
        accessorKey: "locationName",
        enableSorting: false,
        id: "LOCATION",
        meta: {
          filter: getColumnFilter(filters, "locationName"),
        },
      },
      {
        header: t("tranReport.tranId"),
        id: "TRANSACTION_SEQ",
        accessorKey: "transactionSeq",
        meta: {
          filter: getColumnFilter(filters, "transactionSeq"),
        },
      },
      {
        header: t("tranReport.sequence"),
        id: "SEQUENCE",
        accessorKey: "sequence",
        enableSorting: false,
        meta: {
          filter: getColumnFilter(filters, "sequence"),
        },
      },
      {
        header: t("tranReport.reference"),
        accessorKey: "reference",
        enableSorting: false,
      },
      {
        header: t("tranReport.machineUser"),
        id: "MACHINE_USER",
        accessorKey: "machineUserName",
        enableSorting: false,
        meta: {
          filter: getColumnFilter(filters, "machineUserName"),
        },
      },
      {
        header: t("tranReport.machineUserId"),
        accessorKey: "machineUserId",
        enableSorting: false,
      },
      {
        header: t("tranReport.userAccount"),
        id: "ACCOUNT",
        accessorKey: "cardIdentifier",
        size: 170,
        meta: {
          filter: getColumnFilter(filters, "account"),
        },
      },
      {
        header: t("tranReport.roles"),
        id: "ROLE",
        accessorKey: "roleName",
        cell: PreLineCell,
        meta: {
          filter: getColumnFilter(filters, "roleName"),
        },
      },
      {
        header: t("tranReport.workUnit"),
        id: "WORK_UNIT_NAME",
        accessorKey: "workUnitName",
        meta: {
          filter: getColumnFilter(filters, "workUnitName"),
        },
      },
      {
        header: t("tranReport.workUnitGroup"),
        accessorKey: "workUnitName",
        enableSorting: false,
      },
      {
        header: t("tranReport.originUser"),
        id: "ORIGIN_USER",
        accessorKey: "originUser",
      },
      {
        header: t("tranReport.originUserAccount"),
        id: "ORIGIN_USER_ACCOUNT",
        accessorKey: "originUserAccount",
      },
      {
        header: t("tranReport.customData"),
        id: "customData",
        accessorKey: "customData",
        cell: ({ getValue }) => {
          const value = getValue<TransactionRows["customData"]>();
          return value?.length ? (
            <div tw="flex flex-col space-y-1">
              {value.map((curr: any) => (
                <div key={curr.key}>
                  {curr.key}: {curr.value}
                </div>
              ))}
            </div>
          ) : null;
        },
        enableSorting: false,
        meta: {
          csv: {
            accessorFn: (v) => v?.customData?.map((curr: any) => `${curr.key}: ${curr.value}`)?.join(", ") || null,
          },
        },
      },
      {
        header: t("tranReport.valuesByDeno"),
        id: "valuesByDeno",
        accessorKey: "valuesByDenomination",
        enableSorting: false,
        meta: {
          csv: {
            hide: true,
          },
        },
        cell: ({ getValue }) => {
          const value = getValue<TransactionRows["valuesByDenomination"]>();
          const toDisplay =
            value?.reduce?.((acc, curr) => {
              if (curr) {
                acc += `${curr.denomination} ${curr.currency}: ${curr.count} (${curr.type}),\n`;
              }

              return acc;
            }, "") || null;
          return toDisplay ? <span tw="whitespace-pre-line">{toDisplay}</span> : null;
        },
      },
      {
        header: t("tranReport.tranCommissions"),
        // TODO - should be attached to row
        accessorFn: () => undefined,
        enableSorting: false,
      },

      // for correction transactions
      {
        header: t("tranCorr.corrReason"),
        accessorKey: "correctionReason",
        enableSorting: false,
      },
      {
        header: t("tranCorr.comment"),
        accessorKey: "comment",
        enableSorting: false,
      },
      {
        header: t("tranCorr.createdBy"),
        accessorKey: "createdBy",
        enableSorting: false,
      },
      {
        header: t("tranReport.receivedDate"),
        id: "RECEIVED_DATE_TIME",
        enableSorting: false,
        cell: PreLineCell,
        accessorFn: (v) => accessors.zonedDateTime(v.receivedDateTime, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.receivedDateTime, t),
          },
        },
      },
      {
        header: t("tranReport.formattedReceivedDate"),
        id: "FORMATTED_RECEIVED_DATE_TIME",
        enableSorting: false,
        cell: PreLineCell,
        accessorFn: (v) => accessors.zonedDateTime(v.formattedReceivedDate, t),
        meta: {
          csv: {
            accessorFn: (v) => accessors.zonedDateTimeCsv(v.formattedReceivedDate, t),
          },
        },
      },
    ];

const columnFiltersData = [
  ["transactionType", "TYPE"],
  ["transactionSubType", "SUB_TYPE"],
  ["machineUserNames", "MACHINE_USER"],
  ["account", "ACCOUNT"],
  ["transactionSeq", "TRANSACTION_SEQ"],
  ["sequence", "SEQUENCE"],
  ["locationName", "LOCATION"],
  ["acountingDate", "ACCOUNTING_DATE"],
  ["workUnit", "WORK_UNIT_NAME"],
  ["roles", "ROLE"],
] as const;

const zoneRelatedColumns = [
  "FORMATTED_DATE",
  "FORMATTED_ACCOUNTING_DATE_TIME",
  "FORMATTED_RECEIVED_DATE_TIME",
] as const;

const TABLE_NAME = "transactions";
const csvLimit = 80_000;

type TransactionsTableProps = {
  showOptions?: boolean;
  tableName?: string;
  defaultReportingPeriod?: DateRangeDuration;
};

export const TransactionsTable: React.FC<TransactionsTableProps> = ({
  showOptions = true,
  tableName = TABLE_NAME,
  defaultReportingPeriod = "currentDay",
}) => {
  const { t, i18n } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = usePagination(tableName);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<TransactionsReportOrderColumn>();
  const [dateFilter, DateRange] = useDateRangeFilter(defaultReportingPeriod, {
    label: "report.period",
    availableOptions: ["currentDay", "currentWeek", "currentMonth", "lastDay", "lastWeek", "lastMonth", "custom"],
    useTenantEndOfDay: true,
  });
  const [zoneId, TimeZone] = useTimeZoneFilter(false);
  const zonedDateRange = useDateRangeZoned(dateFilter, zoneId);
  const [filter, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const [{ location, machine, siteGroup }] = useMappedSiteGroupContext(true);
  const [queryTransactionBy, QueryByDateSelect] = useQueryByDateFilter("transactions");
  const [messageType, QueryByTransactionTypeFilter] = useQueryByTransactionTypeFilter();

  const { columnsVisibility, setColumnsVisibility } = useVisibilityColumnState(
    tableName,
    zoneRelatedColumns,
    Boolean(zoneId),
  );

  const queryVariables: TransactionsReportCriteriaDtoIn = {
    reportName: "TransactionsReport",
    messageType: messageType as TransactionType,
    limit: pageSize,
    skip: pageIndex * pageSize,
    order,
    orderColumn,
    fromDate: zonedDateRange.from,
    toDate: zonedDateRange.to,
    locationNodeIds: location,
    machineNodeIds: machine,
    siteGroupIds: siteGroup,
    queryTransactionBy,
    zoneId,
    ...filter,
  };
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGenerateTransactionsReportQuery({ variables: { input: queryVariables } });

  const rows = data?.generateTransactionsReport?.rows as TransactionRows[] | undefined;
  const filters = data?.generateTransactionsReport?.columns;
  const fullSize = data?.generateTransactionsReport?.fullSize || 0;

  const columns = useMemo<TableTypes.ColumnDef<TransactionRows>[]>(
    () =>
      !rows
        ? []
        : [
            ...getColumns(filters)(t, i18n.language),
            ...getTotalAmountsPairRows(rows, t, { enableHiding: true }),
            ...getDenominationRows(rows, t),
          ],
    [t, i18n.language, rows, filters],
  );

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) return getOptions({ data: rows ?? [] });
    if (!fullSize) return "";
    if (csvLimitExceeded(fullSize, csvLimit, t)) return false;

    const { data: { generateTransactionsReport } = {} } = await client.query<
      GenerateTransactionsReportQuery,
      GenerateTransactionsReportQueryVariables
    >({
      query: GenerateTransactionsReportDocument,
      variables: { input: { ...queryVariables, limit: fullSize || 0, skip: 0 } },
    });

    return getOptions({ data: (generateTransactionsReport?.rows || []) as TransactionRows[] });
  };

  return (
    <>
      {showOptions ? (
        <>
          <FilterBox variant="col">
            <FilterBox tw="pb-0">
              <SelectWrapper>{DateRange}</SelectWrapper>
              <SelectWrapper>{QueryByDateSelect}</SelectWrapper>
              <SelectWrapper>{QueryByTransactionTypeFilter}</SelectWrapper>
              {TimeZone}
            </FilterBox>
          </FilterBox>
          <DateRangeInfo from={zonedDateRange.from} to={zonedDateRange.to} timeZone={zoneId} />
        </>
      ) : null}
      <Table<TransactionRows>
        tableName={tableName}
        columns={columns}
        data={rows || []}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        onPagination={setPagination}
        sorting={sorting}
        onSorting={setSorting}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        columnVisibility={columnsVisibility}
        onColumnVisibilityChange={setColumnsVisibility}
        totalCount={fullSize}
        SubRows={TransactionsSubRow}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDateRange(zonedDateRange, "tranReport.title", t),
                page,
              )
            }
            disabled={!fullSize}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </>
  );
};

export const Transactions = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="tranReport.title"
    subtitle="tranReport.desc"
    Icon={TransactionsSVG}
    withPicker
  >
    <TransactionsTable />
  </PageLayout>
);
