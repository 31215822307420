import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { InputRaw } from "common/form/renderFields";

export const useNumberFilter = (): [number, JSX.Element] => {
  const [value, setValue] = useState(1);

  return [
    value,
    <InputRaw
      key="numberFilter"
      label="report.netCash.durationInDays"
      name="durationInDays"
      type="number"
      min={1}
      max={100}
      value={value}
      onChange={(e) => setValue(parseInt(e?.target?.value, 10))}
    />,
  ];
};
