import { SortingFn } from "@tanstack/react-table";
import { compareAsc, parseISO } from "date-fns";

export interface CustomSortingFns {
  boolean: SortingFn<any>;
  dateString: SortingFn<any>;
}

export const sortingFns: CustomSortingFns = {
  boolean: (a, b, id) => (a.original[id] === b.original[id] ? 0 : a.original[id] ? -1 : 1),
  dateString: (a, b, id) => compareAsc(parseISO(a.original[id] as string), parseISO(b.original[id] as string)) || 0,
};
