import { LiveField, UseLiveConfig } from "common/form";
import { CustomRenderFieldTypes, CustomRenderFields, TransferBoxRaw } from "common/form/renderFields";

export type TransferBoxLiveFieldValueArg = Partial<CustomRenderFieldTypes["transferBox"]> & { name: string };

// eslint-disable-next-line
// @ts-ignore
export const getTransferBoxField = (value: TransferBoxLiveFieldValueArg): CustomRenderFieldTypes["transferBox"] => ({
  type: "transferBox",
  options: [],
  labelCtx: { count: 0 },
  ...value,
});

export type TransferBoxLiveFieldUseConfig = UseLiveConfig<CustomRenderFieldTypes["transferBox"]>;

export const getTransferBoxLiveField = (
  value: TransferBoxLiveFieldValueArg,
  useConfig: TransferBoxLiveFieldUseConfig,
): LiveField<CustomRenderFields> => ({
  type: "live",
  useConfig: useConfig as UseLiveConfig<CustomRenderFields>,
  name: value.name,
  Fallback: TransferBoxRaw as React.FC,
  fieldConfig: getTransferBoxField(value),
});
