export const es = {
  tenant: {
    tenant_one: "Inquilino",
    tenant_other: "Inquilinos",
    goBack: "Volver",
    activeFetchErr: "No se han podido obtener los datos del inquilino",
    create: {
      title: "Crear inquilino",
      description: "Introduzca los datos del inquilino para crear un nuevo inquilino.",
      name: "Nombre del inquilino",
      contractNumber: "Numero de contrato",
      billingId: "ID de facturación",
      tenantId: "ID de inquilino",
      userName: "Nombre de usuario",
      mqttPassword: "Contraseña MQTT",
      submit: "Crear",
      apiError: "No se pudo crear el inquilino, por favor intentelo de nuevo",
      success: "Inquilino creado con exito",
      createDomain: "Crear nuevo dominio",
      createUser: "Crear nuevo usuario",
      newUser: "Nuevo usuario",
      endOfDay: "Fin del día",
    },
    update: {
      submit: "Actualizar",
      title: "Actualizar inquilino",
      description: "Por favor, cambia los detalles del inquilino para actualizarlos.",
      apiError: "No se pudo actualizar el tenant, por favor inténtelo de nuevo",
      success: "Inquilino actualizado correctamente",
    },
    activate: {
      title: "Activar inquilino",
      description: "Se activará el inquilino <0>{{name}}</0>.",
      close: "Descartar",
      submit: "Activar",
      error: "No se ha podido activar el inquilino, inténtelo de nuevo",
      success: "Inquilino activado correctamente",
    },
    deactivate: {
      title: "Desactivar inquilino",
      description: "El inquilino <0>{{name}}</0> sera desactivado.",
      close: "Discartar",
      submit: "Desactivar",
      error: "No se ha podido desactivar el inquilino, inténtelo de nuevo",
      success: "Inquilino desactivado correctamente",
    },
    features: {
      titleUpdate: "Actualizar características de {{name}}",
      userBalance: "Saldo de usuario",
      commonSoftwarePackageUploadEnabled: "Carga de paquetes de software habilitada",
      success: "Caracteristicas actualizadas correctamente",
      apiError: "No se han podido actualizar las características del inquilino, intentelo de nuevo",
      submit: "Guardar",
    },
    list: {
      tenantId: "ID del inquilino",
      name: "Nombre",
      title: "Inquilinos",
      desc: "Ver y gestionar inquilinos",
      create: "Crear inquilino",
      status: "Estado",
      changed: "Cambiado",
    },
  },
};
