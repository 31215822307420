import { createSuspenseComponent, getLazyComponent } from "common/helpers/lazy";

export const LeafletMap = createSuspenseComponent(getLazyComponent(() => import("./LeafletMap"), "LeafletMap"));

export const MarkerClusterGroup = createSuspenseComponent(
  getLazyComponent(() => import("./MarkerClusterGroup"), "MarkerClusterGroup"),
);

export const MapMarker = {
  Point: createSuspenseComponent(getLazyComponent(() => import("./Point"), "Point")),
};
