import { addDays, endOfWeek, set, startOfDay, startOfWeek } from "date-fns";

import { getLocalStorageData } from "common/hooks";

const USER_DAY_BREAK_ENABLED = "__userDayBreakEnabled__";
const WEEK_START = "__weekStart__";
const DAY_START = "__dayStart__";
const DAY_END = "__dayEnd__";
const START_OR_END = "__startOrEnd__";

const DEFAULT_USER_DAY_BREAK_ENABLED = false;
const DEFAULT_WEEK = 1;
const DEFAULT_DAY = "02:01";
const DEFAULT_DAY_END = "02:00";
const DEFAULT_START_OR_END = "end";

export const timeSettings = {
  get: {
    weekStartDay: () => {
      const fromStorage = Number(localStorage.getItem(WEEK_START) ?? undefined);
      return (isNaN(fromStorage) ? DEFAULT_WEEK : fromStorage) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
    },
    startOfWeekByDate: (date: Date) => startOfWeek(date, { weekStartsOn: timeSettings.get.weekStartDay() }),
    endOfWeekByDate: (date: Date) => endOfWeek(date, { weekStartsOn: timeSettings.get.weekStartDay() }),
    dayStartTime: () => localStorage.getItem(DAY_START)?.replace(/"/g, "") ?? DEFAULT_DAY,
    dayEndTime: () =>
      getLocalStorageData(USER_DAY_BREAK_ENABLED)
        ? localStorage.getItem(DAY_END)?.replace(/"/g, "") ?? DEFAULT_DAY_END
        : DEFAULT_DAY_END,
    startOfDayByDate: (date: Date) => {
      const dayStart = timeSettings.get.dayStartTime();

      if (dayStart === DEFAULT_DAY) return startOfDay(date);

      const [hours, minutes] = dayStart.split(":");

      return set(date, { hours: Number(hours), minutes: Number(minutes), seconds: 0 });
    },
    endOfDayByDate: (date: Date) => addDays(timeSettings.get.startOfDayByDate(date), 1),
  },
  keys: {
    USER_DAY_BREAK_ENABLED,
    WEEK_START,
    DAY_START,
    DAY_END,
    START_OR_END,
  },
  default: {
    userDayBreakEnabled: DEFAULT_USER_DAY_BREAK_ENABLED,
    week: DEFAULT_WEEK,
    day: DEFAULT_DAY,
    dayEnd: DEFAULT_DAY_END,
    startOrEnd: DEFAULT_START_OR_END,
  },
};
