export const fr = {
  queryBuilder: "Générateur de requêtes",
  scheduledReports: "Rapports planifiés",
  lossPrevention: "Prévention des pertes",
  machineManagement: "Gestion des machines",
  machineManagementOverview: "Aperçu de la gestion des machines",
  machineErrors: "Erreurs machine",
  machineErrorsSeverity: "Sévérité",
  machineReliability: "Fiabilité des machines",
  refillReport: "Rapport de remplissage",
  cashTotals: "Total de trésorerie",
  manualDeposits: "Dépôts manuels",
  utilization: "Utilisation",
  cashierManagement: "Gestion des utilisateurs machine",
  cashierManagementOverview: "Aperçu de la gestion des utilisateurs machine",
  cashierBalancing: "Solde des utilisateurs machine",
  cashierTransactions: "Transactions des utilisateurs machine",
  report: {
    title: "Rapports",
    overview: "Aperçu des rapports",
    desc: "Créez différents rapports en fonction des informations qui vous intéressent. Les rapports sont prédéfinis en fonction des normes du secteur du traitement des espèces.",
    queryBuilderDescription:
      "Utilisez le générateur de requêtes dynamiques pour créer de nouveaux rapports personnalisés à partir de données existantes",
    scheduledReportsDescription:
      "Planifiez un rapport lorsque vous souhaitez obtenir automatiquement des informations spécifiques à certains intervalles de temps et pour une période donnée",
    lossPreventionDescription:
      "Le rapport sur la prévention des pertes présente des informations qui ont trait à la protection des actifs de l'entreprise",
    machineManagementDescription:
      "Le rapport de gestion des machines présente des informations relatives aux erreurs, aux détails et à l'état des machines",
    cashTotalsDescription:
      "Le rapport montre les totaux d’espèces par emplacement. Il s'agit des espèces distribuables (recyclables) et qui peuvent être remis en banque pouvant être retirées pour être déposées à la banque ou récupérées par le convoyeur de fonds. Les valeurs déclarées, les services ouverts et le coffre-fort intelligent sont indiqués au niveau de la machine",
    manualDepositsDescription:
      "Le rapport présente des informations sur tous les dépôts ou valeurs déclarées saisis manuellement",
    netCashDescription:
      "Le rapport présente un résumé du montant des soldes d'ouverture et de clôture au cours d'un jour ouvrable complet",
    utilizationDescription:
      "Le rapport présente des données sur les flux d'encaissement et de décaissement pour la période sélectionnée",
    cashierManagementDescription:
      "Le rapport de gestion de la caisse montre les informations relatives aux transactions de la caisse",
    cashierBalancingDescription:
      "Le rapport montre les soldes quotidiens pour les utilisateurs ayant un certain rôle. Il permet l'équilibrage des soldes des caissiers par rapport aux données de la caisse",
    cashierTransactionsDescription:
      "Le rapport présente un résumé des transactions effectuées par les utilisateurs ayant le rôle de caissier. Les types de transaction sont le dépôt, la distribution et la fin de service",
    machineErrorsDescription:
      "Le rapport présente l'historique des erreurs de la machine. Vous pouvez filtrer par groupe, site, date, machine, gravité et type (le code d'erreur)",
    machineReliabilityDescription:
      "Le rapport montre l'historique du nombre total de sessions et le nombre de sessions réussies pendant la période sélectionnée. Le rapport montre toutes les erreurs qui se sont produites pendant une session de connexion. Vous pouvez filtrer le rapport par groupe et par site",
    refillReportDescription:
      "Le rapport indique quand une machine doit être vidée ou remplie. Il présente des informations sur le niveau actuel de conteneurs, les limites configurées par l'utilisateur (haute et basse) et la quantité nécessaire pour que le conteneur atteigne le niveau maximal prédéfini (nombre de recharges)",
    onlineStatusDescription: "Le rapport indique l'état en ligne de la machine pour la période sélectionnée",
    period: "Période de référence",
    totalAmount: "{{currency}} Montant",
    totalCount: "{{currency}} Quantité",
    openingTotalAmount: "{{currency}} Ouverture",
    closingTotalAmount: "{{currency}} clôture",
    totalIn: "{{currency}} Montant déposé",
    totalOut: "{{currency}} Montant distribué",
    denominationCount: "{{currency}} {{denomination}} {{type}} Quantité",
    totalDiff: "{{currency}} Différence",
    type: "Type",
    currency: "Devise",
    container: "Conteneur",
    denomination: "Dénomination",
    count: "Quantité",
    total: "Total",
    md: {
      title: "Détails machines",
      desc: "Le rapport montre les détails des machines",
      codVersion: "Version de COD",
      machineDate: "Date de la machine",
      accountingDate: "Date comptable",
      lastErrorDate: "Date de la dernière erreur",
      lastError: "Dernière erreur",
    },
    claimedValues: {
      title: "Valeurs déclarées ",
      desc: "Utilisez le rapport sur les valeurs déclarées pour obtenir des informations sur les totaux réels des coffres-forts de dépôt basés sur les valeurs déclarées par les utilisateurs à un instant donné",
    },
    cashSummary: {
      title: "Résumé de la trésorerie",
      desc: "Utilisez le rapport récapitulatif de l'argent pour obtenir des informations sur les vues actuelles et historiques, l’argent distribuable (recyclable) et l'argent pouvant être remis en banque disponible pour le retrait pour un dépôt bancaire ou un ramassage par les convoyeur de fonds",
      bankableAmount: "Disponible pour la banque",
      bankableCount: "Nombre de mise en banque",
      dispensableAmount: "Disponible pour la distribution",
      dispensableCount: "Nombre de  distribution",
      machineTotal: "Total machine",
      dropSafe: "Coffre",
    },
    openShifts: {
      title: "Services ouverts",
      desc: "Utilisez le rapport sur les services ouverts pour obtenir des informations sur les dernières transactions de caisse (distributions) qui n'ont pas de transaction de fin de services dans la plage de dates",
      cashierId: "Identifiant du caissier",
      cashierName: "Nom du caissier",
      role: "Rôle",
      dateTime: "Date et heure",
      machineDateTime: "Date et heure de la machine",
      transactionType: "Type de transaction",
    },
    netCash: {
      title: "Trésorerie nette",
      desc: "Le rapport présente un résumé du montant total machine à l'ouverture et à la clôture",
      noAmountsData: "Pas de donnée",
      transactionType: "Type de transaction",
      filterByTransactionTypes: "Filtrer par type de transaction",
      transactionIn: "{{currency}} Déposé",
      transactionOut: "{{currency}} Distribué",
      durationInDays: "Durée en jour",
    },
    sr: {
      create: "Créer un rapport programmé",
      createSuccess: "Rapport programmé créé avec succès",
      createError: "Échec de la création d'un rapport programmé",
      update: "Modifier le rapport programmé",
      stepReport: "Step 1 - Rapport",
      stepFrequency: "Step 2 - Fréquence",
      stepPeriod: "Step 3 - Période du rapport",
      stepParams: "Step 4 - Paramètres du rapport",
      stepRecipients: "Step 5 - Destinataires du rapport",
      report: "Rapport",
      name: "Nom",
      reportName: "Nom du rapport",
      receive: "Reçu",
      on: "Le",
      at: "à",
      timeZone: "Fuseau horaire",
      fileType: "Type de fichier",
      frequency: "Fréquence",
      period: "Période",
      customPeriod: "Période personnalisée",
      params: "Paramètres",
      recipients: "Destinataires",
      email: "Courriel",
      subject: "Sujet",
      daily: "Journalier",
      weekly: "Hebdomadaire",
      monthly: "Mensuel",
      transactions: "Transactions",
      netCash: "Trésorerie nette",
      machineErrors: "Erreurs machines",
      csv: "CSV",
      json: "JSON",
      delete: "Supprimer le rapport",
      success: "Rapport programmé avec succès",
      editSuccess: "Rapport mis à jour avec succès",
      deleteSuccess: "Rapport supprimé avec succès",
      error: "Échec de la programmation d'un rapport",
      paramsError: "Veuillez sélectionner les options souhaitées",
      prevDays: "Jour précédent",
      prevWeeks: "Semaine précédente",
      prevMonths: "Mois précédent",
      periodFrom: "Période du",
      periodTo: "Période jusqu'à",
      ago: "Ago",
      daysAgo: "Jour(s)",
      weeksAgo: "Semaine(s)",
      monthsAgo: "Mois",
      repeatOnWeekly: "Répéter chaque semaine",
      repeatOnDay: "Répéter chaque jour",
      parameter: "Paramètres",
      operation: "Fonctionnement",
      auxValue: "Valeur auxiliaire",
      executionTime: "Heure d'exécution",
      nextExecution: "Prochaine éxecution",
      once: "Une fois",
      repeat: "Répéter",
      biweekly: "Bihebdomadaire",
      repeatOnMonthly: "Répéter chaque mois",
      fromDate: "A partir de la date",
      toDate: "Jusqu'à la date",
      referenceDate: "Date de référence",
      executeFrom: "Exécuter à partir de",
      executeUntil: "Exécuter jusqu'à",
      specificDay: "Un jour spécifique",
      addOrSubstractDays: "Ajouter ou soustraire des jours",
      noUpcomingReports:
        "Pas de rapports à venir - modifier la répétition, l'heure d'exécution ou la plage de dates d'exécution",
      max5daysShown: "Un maximum de 5 dates est affiché",
      add: "Ajouter",
      sub: "Soustraire",
      op: "Opération",
      dateTime: "Date et heure",
      receivedDateTime: "Date et heure de réception",
      messageDateTime: "Date et heure du message",
      messageTypeTransactionType: "Type de message Type de transaction",
      queryTransactionByTransactionDateTimeSelect: "Chercher par date et heure de la transaction",
      manualDepositGroupFactorOptions: "Options de facteur de groupe des dépôts manuels",
      claimedValuesGroupFactorOptions: "Options de facteur de groupe des montants annoncés",
      machineNodeId: "Identifiant du noeud machine",
      cashInventoryReport: "Rapport d'inventaire de trésorerie",
      claimedValuesReport: "Rapport des montants annoncés",
      machineDetailsReport: "Rapport sur les détails machines",
      openShiftReport: "Rapport des services ouverts",
      cashSummaryReport: "Rapport de synthèse de trésorerie",
      cashOperationsReport: "Rapport sur les opérations de trésorerie",
      manualDepositsReport: "Rapport sur les dépôts manuels",
      mon: "Lun",
      tue: "Mar",
      wed: "Mer",
      thu: "Jeu",
      fri: "Ven",
      sat: "Sam",
      sun: "Dim",
      reportWillBeSent: "Le prochain rapport sera envoyé le :",
      status: "Statut",
      rName: {
        OpenShiftReport: "Open shift",
        ManualDepositsReport: "Dépôts manuels",
        TransactionsReport: "Transactions",
        MachineUserTransactionsReport: "Transactions par utilisateurs machines",
        BoxTotalsByCurrency: "Totaux des boîtes par devise",
        CashInventoryReport: "Inventaire de trésorerie",
        CashOperationsReport: "Opérations de trésorerie",
        NetCashReport: "Trésorerie nette",
        MachineErrorsReport: "Erreurs machine",
        ClaimedValuesReport: "Montants annoncés",
        MachineDetailsReport: "Détails machine",
        CashSummaryReport: "Synthèse de la trésorerie",
        RefillReport: "Remplissage",
      },
      repeatType: {
        DAILY: "Journalier",
        WEEKLY: "Hebdomadaire",
        MONTHLY: "Mensuel",
      },
      higherThanExecuteFrom: "Doit être supérieur à l'exécution de",
    },
  },
  cashMgmt: {
    title: "Gestion de la trésorerie",
    overview: "Aperçu de la gestion de la trésorerie",
    desc: "Le rapport de gestion de trésorerie montre les informations qui ont trait à l'inventaire et aux totaux de trésorerie",
  },
  tranReport: {
    title: "Transactions",
    desc: "Le rapport sur les transactions montre les informations relatives à toutes les transactions disponibles",
    subtype: "Sous-type",
    type: "Type",
    tranDate: "Date et heure de la transaction",
    accountingDate: "Date et heure comptable",
    machineTime: "Date et heure machine",
    machine: "Machine",
    site: "Site",
    tranId: "Identifiant de la transaction",
    sequence: "Numéro de séquence",
    reference: "Référence",
    machineUser: "Utilisateur machine",
    account: "Compte",
    roles: "Rôles",
    workUnit: "Unité de travail",
    workUnitGroup: "Groupe d’unités de travail",
    originUser: "Utilisateur d’origine",
    originUserAccount: "Compte de l’utilisateur d’origine",
    customData: "Données personnalisées",
    valuesByDeno: "Valeurs par dénomination",
    tranCommissions: "Transactions de commissions",
    useAccDate: "Utiliser la date comptable",
    datePeriod: "Période de référence",
    withTranCorr: "Inclure les transactions de correction",
    mixEdited: "Fond  de caisse édité",
    cardIdentifier: "Identifiant carte de l’utilisateur machine",
    UUID: "UUID machine",
    timestamp: "Horodatage",
    timezoneOffset: "Décalage horaire /UTC",
    currency: "Devise",
    total: "Total",
    formattedDateTime: "Heure machine",
    formattedReceivedDate: "Date de réception",
    formattedAccountingDate: "Date comptable",
    receivedDate: "Date de réception",
    machineUserId: "Identifiant utilisateur machine",
    messageType: "Type de message",
    messageTypes: {
      ALL: "Tout",
      CORRECTION_TRANSACTIONS: "Transactions de correction",
      MACHINE_TRANSACTIONS: "Transactions machine",
    },
    valBag: {
      title: "Montant valorisé",
      resultingContent: "Résultat du comptage",
      boxMovement: "Mouvement des conteneurs",
      deviceMovement: "Affectation dans les conteneurs",
      deviceMovementsResultingContent: "Résultat de l'affectation",
      nodeId: "Identifiant du noeud",
      totalAmount: "Montant total",
      type: "Type",
      rejects: "Rejetés",
      valNodeId: "Valeur de l’identifiant du noeud",
      total: "Total",
      count: "Nombre",
      decimals: "Décimales",
      currency: "Devise",
      denomination: "Dénomination",
      calculated: "Calculé",
      pieceValue: "Valeur faciale",
      exchangeRate: "Taux de change",
      exchangeRateDecimals: "Décimales du taux de change",
      reference: "Référence",
      valType: "Type de valeur",
      claimedValueType: "Type de valeur déclarée",
      customType: "Type personnalisé",
      c2Count: "Nombre de C2",
      c3Count: "Nombre de C3",
      c4bCount: "Nombre de C4b",
      creditCat2: "Crédit cat 2",
      creditCat3: "Crédit cat 3",
    },
  },
  cashInv: {
    title: "Inventaire des espèces",
    desc: "Le rapport indique le montant d’espèces dans chaque conteneur, par machine et à un instant donné. Le montant est indiqué par type d’espèce, par dénomination et par devise",
    machine: "Machine",
    machineType: "Type de machine",
    container: "Conteneur",
    denomination: "Dénomination",
    type: "Type",
    site: "Site",
  },
  mos: {
    title: "Statut état en ligne",
    desc: "Utilisez le rapport d'état en ligne pour obtenir des informations sur l'état en ligne d'une machine pendant la période sélectionnée",
    machine: "Machine",
    site: "Site",
    licenseId: "Identifiant de licence",
    machineStatus: "Statut machine",
    onlineStatus: "Statut de connexion",
    lastEvent: "Dernier événement",
    lastReceived: "Dernière communication",
    timeOfStatusChange: "Date du changement change",
    formattedReceivedDate: "Date de réception",
    formattedEventDate: "Date de l'événement",
  },
  mosCurrent: {
    title: "Statut état en ligne courant",
    desc: "Utilisez le rapport sur l'état en ligne actuel pour suivre l'état en ligne de la machine à une date donnée",
  },
  mDepo: {
    devices: "Périphériques",
    bagIds: "Identifiant sac",
    date: "Date et heure",
    type: "Type",
    machineDate: "Date et heure de la type",
    tranType: "Type de transactions",
  },
  mum: {
    title: "Gestion des utilisateurs machine",
    desc: "Ce rapport présente les informations relatives aux transactions des utilisateurs des machines.",
    transactions: {
      title: "Transactions par utilisateurs machine",
      desc: "Ce rapport présente un résumé des dépôts, des distributions et des transactions par utilisateur machine.",
    },
    balancing: {
      title: "Balance des utilisateurs machine",
      desc: "Ce rapport montre les balances quotidiennes des utilisateurs machine ayant un certain rôle. Il permet de comparer la balance des utilisateurs avec les données du point de vente.",
    },
  },
};
