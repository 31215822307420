import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { TableTypes } from "common/guideline";

import { useDeleteCorrectionReasonModal, useEditCorrectionReasonModal } from "../../components";
import { CorrectionReasonFetched } from "../../types";

export const RowActions: React.FC<TableTypes.VerticalHeaderProps<CorrectionReasonFetched>> = ({ row }) => {
  const [showUpdate] = useEditCorrectionReasonModal(row.original as WithoutNullableKeys<CorrectionReasonFetched>);
  const [showDelete] = useDeleteCorrectionReasonModal(row.original as WithoutNullableKeys<CorrectionReasonFetched>);

  return (
    <div tw="flex">
      <ActionButton.Delete onClick={() => showDelete()} />
      <ActionButton.Edit onClick={() => showUpdate()} />
    </div>
  );
};
