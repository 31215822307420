import tw from "twin.macro";

import { Confirm } from "base/components/Confirm";
import { useModalComponent } from "common/guideline";
import { Trans } from "i18n";
import { downloadLogArchive } from "zts/helpers/fileAccess";

type Props = {
  zipFileName: string | null | undefined;
  onClose?: () => void;
};

const ConfirmDownload: React.FC<Props> = ({ zipFileName, onClose }) => {
  const handleSubmit = async () => {
    downloadLogArchive(zipFileName);
  };

  return (
    <Confirm
      confirmText="common.yes"
      rejectText="common.no"
      desc="zts.commands.logs.fileTooLarge"
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Trans i18nKey="zts.commands.logs.fileTooLargeDesc">{[<b key="1" />]}</Trans>
    </Confirm>
  );
};

export const useConfirmDownloadModal = (zipFileName: string | null | undefined) =>
  useModalComponent({
    Component: <ConfirmDownload zipFileName={zipFileName} />,
  });
