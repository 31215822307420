import { useEffect, useMemo, useState } from "react";

import { useTimeZoneFilter } from "base/components";
import { defaultNumber } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { GenerateCashOperationsReportQuery, useGenerateCashOperationsReportQuery } from "generated";

const defaultSummary = {
  availableForDispense: 0,
  availableToBank: 0, // will be displayed as "Not dispensable" in UI
  NOTE: 0,
  COIN: 0,
  CLAIMED_VALUE: 0,
  COUNTERFEIT_NOTES: 0,
  SUSPECT_NOTES: 0,
  CHECK: 0,
  VOUCHER: 0,
};

export type CashOperationsSummaryData = typeof defaultSummary;

export type CashOperationsContentData = NonNullable<
  NonNullable<GenerateCashOperationsReportQuery["generateCashOperationsReport"]>["machineContentDtos"]
>[0];

const defaultDate = new Date().toISOString();
const defaultCurrency = { value: "EUR", label: "EUR" };

const mapResult = ({ generateCashOperationsReport }: GenerateCashOperationsReportQuery | undefined = {}) => {
  const contentPerCurrency = (generateCashOperationsReport?.machineContentDtos || []).reduce(
    (acc, curr) => Object.assign(acc || {}, { [curr?.currency || ""]: curr }),
    {} as { [currency: string]: CashOperationsContentData },
  );

  const currencies = Object.keys(contentPerCurrency).map((v) => ({ value: v, label: v }));

  return { contentPerCurrency, currencies: currencies.length ? currencies : [defaultCurrency] };
};

export const useCashOperationsData = (nodeId: string, date = defaultDate) => {
  const [currency, setCurrency] = useState<string>();
  const [zoneId, TimeZoneFilter] = useTimeZoneFilter();
  const [mapped, queryResult] = useMappedQuery(
    mapResult,
    useGenerateCashOperationsReportQuery({
      skip: !nodeId,
      variables: {
        cashOperationsReportCriteriaDto: {
          reportName: "CashOperationsReport",
          referenceDate: date,
          machineNodeId: nodeId,
          zoneId,
        },
      },
    }),
  );
  const content = currency ? mapped?.contentPerCurrency?.[currency] : undefined;
  const summary = useMemo<CashOperationsSummaryData>(
    () =>
      content?.totals?.reduce(
        (acc, curr) => {
          if (curr?.key) acc[curr.key] = defaultNumber(Number(curr.value || 0));
          return acc;
        },
        { ...defaultSummary },
      ) || defaultSummary,
    [content?.totals],
  );

  useEffect(() => {
    if (mapped?.currencies?.length && (!currency || !mapped.currencies.some((c) => c.value === currency))) {
      setCurrency(mapped.currencies[0].value);
    }
  }, [mapped?.currencies, currency]);

  return {
    content,
    summary,
    currencies: mapped?.currencies,
    currency,
    setCurrency,
    queryResult,
    zoneId,
    TimeZoneFilter,
  };
};
