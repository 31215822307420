import { useMemo } from "react";
import tw from "twin.macro";

import "styled-components/macro";
import { usePermissionAccess } from "auth/hooks";
import { Table, usePagination } from "common/guideline";
import { useFindListOfUsersByTenantQuery } from "generated";
import { useTranslation } from "i18n";

import { getColumns } from "./columns";
import { IsUserData } from "./types";

const TABLE_NAME = "users";

export const UsersTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const { t, i18n } = useTranslation();
  const canAdministerUsers = usePermissionAccess("canAdministerUsers");
  const tColumns = useMemo(
    () => getColumns(canAdministerUsers)(t, i18n.language),
    [t, i18n.language, canAdministerUsers],
  );

  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useFindListOfUsersByTenantQuery({
    variables: {
      offset: pageIndex * pageSize,
      limit: pageSize,
    },
  });

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={(data?.findListOfUsersByTenant?.isUserDtoOut || []) as IsUserData[]}
      initialLoading={previousData === undefined}
      loading={loading}
      error={error}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPagination={setPagination}
    />
  );
};
