// extend history to provide better blocker with surpass it by state value
import { BrowserHistory } from "history";

import { ExtendedBroweserHistoryState } from "./types";

export type HistoryBlockerState = { surpassBlocker?: boolean };
export type BlockerArg = string | (() => boolean);

export const withBlocker = <T extends BrowserHistory>(
  history: T,
): Omit<ExtendedBroweserHistoryState<T, HistoryBlockerState>, "go"> & {
  blockNavigation: (messageOrCheckFn: BlockerArg) => void;
  unBlock: () => void;
  go: (delta: number, surpassBlocker?: boolean) => void;
} => {
  const { push, replace, go } = history;
  let blocker: null | (() => void) = null;

  const unBlock = () => {
    blocker?.();
    blocker = null;
  };

  const shouldSurpassBlocker = (surpassBlocker?: boolean) => {
    if (surpassBlocker && blocker) {
      unBlock();
    }
  };

  // IMPORTANT Object.assign instead of spread - for history to get reference instead of new object
  return Object.assign(history, {
    push: (to, state) => {
      shouldSurpassBlocker(state?.surpassBlocker);
      push(to, state);
    },
    replace: (to, state) => {
      shouldSurpassBlocker(state?.surpassBlocker);
      replace(to, state);
    },
    go: (delta, surpassBlocker) => {
      shouldSurpassBlocker(surpassBlocker);
      go(delta);
    },
    blockNavigation: (messageOrCheckFn) => {
      unBlock();

      blocker = history.block((tx) => {
        const checker =
          typeof messageOrCheckFn === "string" ? () => window.confirm(messageOrCheckFn) : messageOrCheckFn;

        if (checker()) {
          unBlock();
          tx?.retry();
        }
      });
    },
    unBlock,
  });
};
