import { Badge, Text } from "common/guideline";
import { OnlineStatus } from "generated";

type StatusType = OnlineStatus | "Unknown";

type Props = React.PropsWithChildren<{
  value: StatusType | null | undefined;
  isSm?: boolean;
}>;

const statusToBadgeVariant: Record<StatusType, "success" | "error" | "warning" | "neutral"> = {
  CatchingUp: "warning",
  Offline: "error",
  Online: "success",
  Unknown: "neutral",
};

export const OnlineStatusBadgeEmpty: React.FC<Props> = ({ value = "Unknown", children, isSm }) => {
  const statusVariant = statusToBadgeVariant[value || "Unknown"];
  return <Badge variant={isSm ? [statusVariant, "sm"] : statusVariant}>{children}</Badge>;
};

export const OnlineStatusBadge: React.FC<Props> = ({ value = "Offline", children, isSm }) => (
  <OnlineStatusBadgeEmpty value={value || "Offline"} isSm={isSm}>
    <Text tKey={`base.onlineStatus.${value || "Offline"}`} />
    {children}
  </OnlineStatusBadgeEmpty>
);
