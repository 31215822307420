import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as UsersSVG } from "assets/icons/Users.svg";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";
import { UserCreate } from "./UserCreate/UserCreate";
import { DomainUsersTable } from "./UsersTable/DomainUsersTable";
import { UsersTable } from "./UsersTable/UsersTable";

export const Users = () => {
  const isGlobalAdmin = usePermissionAccess("isGlobalAdmin");

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="user.title_other"
      subtitle="user.desc"
      Icon={UsersSVG}
      actions={
        <div tw="flex justify-items-center gap-6">
          <UserCreate tw="mx-3" />
        </div>
      }
    >
      {isGlobalAdmin === true ? <DomainUsersTable /> : <UsersTable />}
    </PageLayout>
  );
};
