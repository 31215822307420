import { Row } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { cancelConfigurationDeployment, cancelSoftwareInstallation } from "administration/helpers";
import { ReactComponent as EyeSVG } from "assets/icons/Eye.svg";
import { NodeStatusBadge } from "base/components";
import {
  Badge,
  Button,
  CommonCells,
  FullLine,
  Spinner,
  Table,
  TableTypes,
  Text,
  Tooltip,
  accessors,
  localFilters,
  usePagination,
  useToast,
} from "common/guideline";
import { withDefault } from "common/helpers";
import { useDebouncedValue } from "common/hooks";
import { MachineViewOrderColumn, useGenerateMachineViewTabQuery } from "generated";
import { useTranslation } from "i18n";
import { LicenseInfo } from "machine/components/Licensing/LicenseInfo";
import { useColumnFiltersArray } from "report/components";
import { SortByColumnsData, useSorting } from "report/hooks";

import { MachineVerticalHeader } from "./MachineVerticalHeader";
import { RowActions } from "./RowActions";
import { MachineViewData } from "./types";

type OperationStatusProps = {
  nodeId: string;
  version?: string | null;
  name?: string | null;
  state?: string;
  operation: "installation" | "deployment";
};

const CurrentVersion: React.FC<{ data?: Pick<OperationStatusProps, "version" | "name"> | null }> = ({ data }) => (
  <div>
    {data?.name ? `${data.name} ` : ""}
    <Badge variant={["text", "neutral"]}>
      <span>
        <Text tKey="machine.remoteOperationMgmt.version" />
        {": "}
        <Text>{data?.version ?? "machine.remoteOperationMgmt.statuses.UNKNOWN"}</Text>
      </span>
    </Badge>
  </div>
);

const OngoingOperation: React.FC<OperationStatusProps> = ({
  version = "machine.remoteOperationMgmt.unknown",
  state = "UNKNOWN",
  nodeId,
  operation,
}) => {
  const stateLabel = `machine.remoteOperationMgmt.statuses.${state}`;
  const [disabled, setDisabled] = useState(false);

  return nodeId ? (
    <>
      <FullLine />
      <Badge variant={["text", "info"]} tw="mt-2">
        <span tw="mr-4">
          <Text>{version}</Text>
          <span tw="flex mt-2">
            <Text>{stateLabel}</Text> <Spinner />
          </span>
        </span>
        <span tw="align-top">
          <Button
            variant={["primary", "sm"]}
            tw="transition-all duration-300 hover:text-primary-default mt-1"
            disabled={disabled}
            onClick={async () => {
              const result =
                operation === "deployment"
                  ? await cancelConfigurationDeployment(nodeId)
                  : await cancelSoftwareInstallation(nodeId);
              if (result) {
                setDisabled(true);
              }
            }}
          >
            <Text tKey="machine.remoteOperationMgmt.cancel" />
          </Button>
        </span>
      </Badge>
    </>
  ) : null;
};

const columns: TableTypes.TranslatedColumns<MachineViewData, SortByColumnsData<MachineViewOrderColumn>> = (t) => [
  {
    header: t("machine.uuid"),
    id: "MACHINE_UUID",
    accessorFn: (r) => withDefault(r.machine?.uuid),
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("machine.machine_one"),
    id: "MACHINE_NAME",
    accessorFn: (r) => withDefault(r.machine?.name),
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("location.location_one"),
    id: "LOCATION_NAME",
    accessorFn: (r) => withDefault(r.location?.name),
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("machine.nodeStatus"),
    id: "MACHINE_STATUS",
    accessorFn: (r) => withDefault(r.machine?.nodeStatus),
    cell: NodeStatusBadge,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("machine.machineType_one"),
    id: "MACHINE_TYPE",
    accessorFn: (r) => withDefault(r.machine?.machineType),
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
  },
  {
    header: t("machine.changed"),
    id: "MACHINE_CREATED_DATE",
    accessorFn: (r) => accessors.date(r.machine?.nodeChangeDate, t),
  },
  {
    header: t("machine.licensing.title_singular"),
    id: "LICENSE_EDITION",
    accessorKey: "license",
    enableSorting: false,
    cell({ row }) {
      return row.original.license ? (
        <LicenseInfo
          edition={row.original.license.edition || ""}
          licenseKey={row.original.license.licenseKey as any}
          active={row.original.license.subscriptionActive || false}
        />
      ) : (
        <Text tKey="machine.licensing.edition.unknown" />
      );
    },
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    header: t("machine.licensing.subscriptionRenewalDate"),
    id: "LICENSE_EXPIRATION_DATE",
    accessorFn: (r) =>
      r.license?.expirationDate
        ? accessors.zonedDateTime(new Date(`${r.license.expirationDate}Z`).toISOString(), t)
        : "",
    minSize: 200,
    filterFn: localFilters.getDateFilter.filterFn(),
    meta: {
      filter: localFilters.getDateFilter.meta.filter("date", (d) =>
        d ? (typeof d !== "string" ? d.toISOString() : d).split("T")[0] : undefined,
      ),
    },
    cell: ({ getValue }) => (
      <div>
        {getValue<string>() || (
          <Badge variant="warning">
            <Text tKey="base.notApplicable" />
          </Badge>
        )}
      </div>
    ),
  },
  {
    header: t("machine.remoteOperationMgmt.configuration"),
    id: "TEMPLATE_DEPLOYMENT_CONFIGURATION_NAME",
    accessorKey: "templateDeployment",
    minSize: 270,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
    cell({ row }) {
      return (
        <div>
          <CurrentVersion data={row.original.templateDeployment?.template} />
          <OngoingOperation
            nodeId={row.original.ongoingDeployment?.nodeId ?? ""}
            state={row.original.ongoingDeployment?.state ?? ""}
            version={row.original.ongoingDeployment?.template?.version}
            name={row.original.ongoingDeployment?.template?.name}
            operation="deployment"
          />
        </div>
      );
    },
  },
  {
    header: t("machine.remoteOperationMgmt.software"),
    id: "INSTALLED_SOFTWARE_NAME",
    accessorKey: "softwareInstalled",
    minSize: 270,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
      hideVertical: true,
    },
    cell({ row }) {
      return (
        <div>
          <CurrentVersion data={row.original.softwareInstalled?.softwarePackage} />
          <OngoingOperation
            nodeId={row.original.ongoingSoftwareInstallation?.nodeId || ""}
            state={row.original.ongoingSoftwareInstallation?.state || ""}
            version={row.original.ongoingSoftwareInstallation?.softwarePackage?.version}
            name={row.original.ongoingSoftwareInstallation?.softwarePackage?.name}
            operation="installation"
          />
        </div>
      );
    },
  },
  {
    header: t("machine.preview"),
    id: "MACHINE_IMAGE_URL",
    accessorFn: (r) => r.machine?.machineImageUrl,
    enableSorting: false,
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      return (
        <div tw="inline-flex">
          <Tooltip
            content={
              <img
                tw="max-w-xs border-2 border-primary-default rounded-lg shadow-blue"
                src={getValue<string | undefined>() ?? "../../img/placeholder.png"}
                alt="PayComplete™"
              />
            }
          >
            <EyeSVG width={16} height={16} />
          </Tooltip>
        </div>
      );
    },
  },
  CommonCells.getActionsCell({ size: 140, cell: (c) => <RowActions row={c.row} /> }),
];

// check if machine has noOngoingDeployments and noOngoingInstallations
const noOngoingOperations = (rows) => rows?.every((r) => !r?.ongoingDeployment && !r?.ongoingSoftwareInstallation);

const TABLE_NAME = "machines";
const initialSorting = [{ id: "MACHINE_NAME", desc: false }];

export const MachineTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [columnFilters, setColumnFilters] = useColumnFiltersArray<MachineViewOrderColumn>();
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<MachineViewOrderColumn>(initialSorting);
  const columnFiltersDebounced = useDebouncedValue(columnFilters);

  const {
    previousData,
    data = previousData,
    loading,
    error,
    stopPolling,
    startPolling,
  } = useGenerateMachineViewTabQuery({
    variables: {
      input: {
        skip: pageIndex * pageSize,
        limit: pageSize,
        machineViewTabType: "ALL",
        order,
        orderColumn,
        machineViewFilteringField: columnFiltersDebounced.map((c) => ({
          filteringField: c.id,
          value: c.value as string,
        })),
      },
    },
    onCompleted: (result) => {
      const doStopPolling: boolean = noOngoingOperations(result.generateMachineViewTab?.rows);
      setTimeout(() => {
        startPolling(8000);
        if (doStopPolling) stopPolling();
      }, 200);
    },
  });

  const rows = useMemo(() => (data?.generateMachineViewTab?.rows as MachineViewData[]) || [], [data]);
  const fullSize = data?.generateMachineViewTab?.fullSize || 0;
  const lockedDueToFlipFloppingRows = useMemo(
    () =>
      rows.filter((row) => {
        return row.license?.lockedDueToFlipFlopping == true;
      }),
    [rows],
  );
  useEffect(() => {
    lockedDueToFlipFloppingRows.forEach((row) =>
      useToast.actions.show(
        `${t("machine.machine_one")}: ${row.machine?.name ?? "-"} ${t("machine.lockedDueToFlipFlopping")}`,
        {
          variant: "warning",
        },
      ),
    );
  }, [lockedDueToFlipFloppingRows, t]);

  return (
    <>
      {lockedDueToFlipFloppingRows.length != 0 && (
        <div tw="flex mb-2">
          <Badge tw="whitespace-pre" variant="warning">
            <Text tKey="machine.locked" tValue={{ colon: true }} /> {lockedDueToFlipFloppingRows.length}
          </Badge>
        </div>
      )}
      <Table
        tableName={TABLE_NAME}
        columns={tColumns}
        data={rows}
        VerticalHeader={MachineVerticalHeader}
        loading={loading}
        initialLoading={previousData === undefined}
        error={error}
        onPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={fullSize}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        sorting={sorting}
        onSorting={setSorting}
        isRowHighlighted={(row: Row<MachineViewData>) => {
          return row.original.license?.lockedDueToFlipFlopping ?? false;
        }}
      />
    </>
  );
};
