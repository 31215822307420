import tw from "twin.macro";
import "styled-components/macro";

import { performRemoteMgmtOperation } from "administration/helpers";
import { navigateTo } from "appRouting";
import { PageLayout } from "base/components";
import { useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllLocationsByNodeIdsDocument,
  FindAllLocationsFilteredDocument,
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindAllMachineUserGroupsFilteredDocument,
  GenerateMachineViewTabDocument,
  GetReportHeaderDocument,
  useCreateMachineMutation,
} from "generated";

import { MachineForm } from "../../components";

import { breadcrumbs } from "./breadcrumbs";

export const MachineCreate = () => {
  const [createMachine] = useCreateMachineMutation();

  return (
    <PageLayout breadcrumbs={breadcrumbs} title="machine.createMachine" subtitle="machine.createMachineDescription">
      <MachineForm
        submitLabel="machine.createMachine"
        onSubmit={({
          // ---- Configuration & software installation -----
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          timeOption,
          remoteMgmtOption,
          templateNodeId,
          softwarePackageNodeId,
          scheduleInfo,
          createdBy,
          // ----- Licensing -----
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          // codLicense,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          licensingOption,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          licenseId,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          comment,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          subscriptionActive,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          paymentIntervalType,
          ...input
        }) =>
          createMachine({
            variables: { input },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(GenerateMachineViewTabDocument, cache);
              evictQuery(GetReportHeaderDocument, cache);
              evictQuery(FindAllLocationsByNodeIdsDocument, cache);
              evictQuery(FindAllLocationsFilteredDocument, cache);
              evictQuery(FindAllMachineUserGroupsFilteredDocument, cache);
              evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache);
            },
          })
            .then(async (v) => {
              const machineNodeId = v.data?.createMachine?.nodeId;
              if (!machineNodeId) throw new Error();

              useToast.actions.show("machine.createMachineSuccess", { variant: "success" });

              await performRemoteMgmtOperation(
                remoteMgmtOption,
                [input.uuid],
                templateNodeId,
                softwarePackageNodeId,
                scheduleInfo,
                createdBy,
              );

              navigateTo({ route: "MACHINE_LIST" });
            })
            .catch(() => useToast.actions.show("machine.createMachineError", { variant: "error" }))
        }
      />
    </PageLayout>
  );
};
