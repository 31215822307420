export const copyToClipboard = async (
  text: string,
  onSuccess?: (text: string) => any,
  onError?: (error?: unknown) => any,
): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    onSuccess?.(text);
    return true;
  } catch (error) {
    onError?.(error);
    return false;
  }
};
