import { Languages } from "i18next";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import de from "react-phone-number-input/locale/de.json";
import en from "react-phone-number-input/locale/en.json";
import fr from "react-phone-number-input/locale/fr.json";
import { styled } from "twin.macro";
import "react-phone-number-input/style.css";

import { RawFieldData, fieldFactory } from "common/form/schema";

import { InputProps, InputRaw } from "./Input";

const StyledPhoneInput = styled(PhoneInput as any)`
  .PhoneInputCountry {
    align-self: end;
    height: 16px;
    margin-bottom: 12px;
  }
`;

const getLabels = (locale: Languages) => {
  switch (locale) {
    case "de-DE":
      return de;
    case "fr-FR":
      return fr;
    default:
      return en;
  }
};

export type PhoneNumberFieldProps = Omit<InputProps, "type"> & {
  type: "phone";
};

export const PhoneNumberFieldRaw: React.FC<RawFieldData<PhoneNumberFieldProps>> = (props) => {
  const { t, i18n } = useTranslation();
  const labels = getLabels(i18n.language as Languages);

  return (
    <StyledPhoneInput
      placeholder={t("common.form.enterPhoneNo")}
      {...props}
      labels={labels}
      inputComponent={InputRaw}
      type="text"
    />
  );
};

export const PhoneNumberField = fieldFactory(PhoneNumberFieldRaw);

export { isValidPhoneNumber } from "react-phone-number-input";
