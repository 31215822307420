import { Download } from "@phosphor-icons/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { downloadConfigurationFile } from "administration/helpers";
import { ReactComponent as ConfigTemplateSVG } from "assets/icons/ConfigTemplate.svg";
import { PageLayout } from "base/components";
import { Badge, Button, CommonCells, Table, TableTypes, usePagination } from "common/guideline";
import { useGetAllTemplatesFilteredQuery } from "generated";
import { useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";
import { CreateConfigTemplate } from "./ConfigTemplateForm/ConfigTemplateForm";
import { RowActions } from "./RowActions";
import { ConfigTemplateData } from "./types";

const getColumns: TableTypes.TranslatedColumns<ConfigTemplateData> = (t) => [
  {
    id: "name",
    header: t("administration.rc.name"),
    accessorKey: "name",
  },
  {
    id: "version",
    header: t("administration.rc.version"),
    accessorKey: "version",
    cell({ getValue }) {
      return getValue() ? <Badge>{getValue<ConfigTemplateData["version"]>()}</Badge> : "-";
    },
  },
  {
    id: "fileName",
    header: t("administration.rc.file"),
    accessorKey: "fileName",
    minSize: 300,
    cell({ getValue, row }) {
      return (
        <div tw="flex flex-nowrap items-center gap-2">
          <div>{(getValue() as any) || ""}</div>
          <Button variant={["primary", "sm"]} data-test={`downloadTemplate-${row.index}`}>
            <Download
              size={18}
              weight="duotone"
              onClick={() => downloadConfigurationFile(getValue<ConfigTemplateData["fileName"]>())}
            />
          </Button>
        </div>
      );
    },
  },
  {
    id: "description",
    header: t("administration.rc.description"),
    accessorKey: "description",
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "configTemplates";

const ConfigTemplatesTable = () => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useGetAllTemplatesFilteredQuery({
    variables: {
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const values = (data?.getAllTemplatesFiltered?.result || []) as ConfigTemplateData[];
  const totalCount = data?.getAllTemplatesFiltered?.fullSize ?? 0;

  return (
    <Table
      tableName={TABLE_NAME}
      columns={columns}
      data={values}
      loading={loading}
      initialLoading={previousData === undefined}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      error={error}
      sorting={sorting}
      onSorting={setSorting}
    />
  );
};

export const ConfigTemplates = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.rc.title"
    subtitle="administration.rc.desc"
    Icon={ConfigTemplateSVG}
    actions={<CreateConfigTemplate />}
  >
    <ConfigTemplatesTable />
  </PageLayout>
);
