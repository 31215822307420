import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as UserCircleSVG } from "assets/icons/UserCircle.svg";
import { NamesListTooltip } from "base/components";
import { Badge, CommonCells, TableTypes, accessors } from "common/guideline";

import { getAccessTranslationString, getTenantPermission } from "../helpers";

import { RowActions } from "./RowActions";
import { IsUserData } from "./types";

export const getColumns: (canAdministerUsers: boolean) => TableTypes.TranslatedColumns<IsUserData> =
  (canAdministerUsers) => (t) =>
    [
      {
        header: t("user.userName"),
        id: "USER_NAME",
        accessorKey: "userName",
      },
      {
        header: t("user.firstName"),
        id: "FIRST_NAME",
        accessorKey: "firstName",
      },
      {
        header: t("user.lastName"),
        id: "LAST_NAME",
        accessorKey: "lastName",
      },
      {
        header: t("user.email"),
        id: "EMAIL",
        minSize: 250,
        accessorKey: "email",
      },
      {
        header: t("user.createdDate"),
        id: "CREATED_DATE",
        accessorFn: (v) => accessors.date(v.createdDate, t),
      },
      {
        header: t("user.lastModified"),
        id: "LAST_MODIFIED",
        accessorFn: (v) => accessors.date(v.lastModifiedDate, t),
      },
      {
        header: t("user.accountStatus"),
        id: "ACCOUNT_LOCKED",
        accessorKey: "accountLocked",
        cell({ getValue }) {
          return getValue<IsUserData["accountLocked"]>() ? (
            <Badge variant="error">{t("user.locked")}</Badge>
          ) : (
            <Badge variant="success">{t("user.active")}</Badge>
          );
        },
      },
      {
        header: t("user.roles"),
        id: "ROLES",
        accessorKey: "userRoles",
        meta: {
          hideVertical: true,
        },
        cell({ getValue }) {
          const value = getValue<IsUserData["userRoles"]>() ?? [];

          return (
            <NamesListTooltip
              count={value.length}
              names={value.map((v) => t(getAccessTranslationString(v?.name) as any)) as string[]}
              Icon={UserCircleSVG}
              data-test="showUserRoles"
            />
          );
        },
      },
      {
        header: t("user.tenantPermissions"),
        id: "PERMISSIONS",
        accessorKey: "tenantPermissions",
        meta: {
          hideVertical: true,
        },
        cell({ getValue }) {
          const value = getValue<IsUserData["tenantPermissions"]>() ?? [];

          return (
            <NamesListTooltip
              count={value.length}
              names={value.map((v) => t(getTenantPermission(v?.name) as any)) as string[]}
              Icon={UserCircleSVG}
              data-test="showTenantPermissions"
            />
          );
        },
      },

      ...(canAdministerUsers
        ? [CommonCells.getActionsCell<IsUserData>({ cell: (c) => <RowActions row={c.row} /> })]
        : []),
    ];
