import tw from "twin.macro";
import "styled-components/macro";

import { PieChart } from "components/Charts";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

const data = [
  {
    x: "Ongoing shift",
    y: 180,
  },
  {
    x: "End of shifts",
    y: 72,
  },
  {
    x: "Overdue",
    y: 144,
  },
];

export const ShiftStatus = () => (
  <Box>
    <div tw="flex flex-col">
      <BoxHeading>Shift status</BoxHeading>
      <div tw="h-52">
        <PieChart data={data} />
      </div>
    </div>
  </Box>
);
