import { useFieldArray } from "../../fieldArray";
import { ArrayField as ArrayFieldType, FieldSchema, RenderInput } from "../types";

// array field get fn as children to pass own field name and render child fields with preffixed name
export const getFieldArrayChildrenFn = (field: ArrayFieldType, renderInput: RenderInput) => {
  const toRender = (name: string, fields: FieldSchema[], arrayIndex: number): React.ReactNode[] =>
    fields.map((field, index) =>
      renderInput({
        index,
        field: "name" in field ? { ...field, name: `${name}.${field.name}`, arrayIndex } : { ...field, arrayIndex },
        children: "fields" in field && field.fields ? toRender(name, field.fields, arrayIndex) : undefined,
      }),
    );

  return (name: string, arrayIndex: number) => toRender(name, field.fields, arrayIndex);
};

type ArrayFieldProps = ArrayFieldType & {
  children: (namePreffix: string, arrayIndex: number) => React.ReactNode;
};

export const ArrayField: React.FC<ArrayFieldProps> = ({ name, children, Component = "div", Row = "div", ...rest }) => {
  const { fields } = useFieldArray(name, rest);

  return (
    <Component fieldArrayName={name}>
      {fields.map((fieldName, index) => (
        <Row key={fieldName} index={index} fieldArrayName={name}>
          {children(fieldName, index)}
        </Row>
      ))}
    </Component>
  );
};
