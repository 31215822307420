import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "RETRIEVE_LOGS", label: "zts.commands.logs.retrieveLogs" },
  { value: "REBOOT", label: "zts.commands.reboot" },
  { value: "RESTART", label: "zts.commands.restart" },
];

export const getCommandTypesField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "zts.commands.selectCommand",
    options,
    ...values,
  });
