import { useMemo, useRef, useState } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { CustomOption } from "common/form/renderFields";
import { Text } from "common/guideline";
import { makeSorter } from "common/helpers";
import { useDebouncedValue, useMappedQuery } from "common/hooks";
import { FindAllLocationsFilteredQuery, LocationFiltersDto, useFindAllLocationsFilteredQuery } from "generated";

type LocationOption = {
  label: string;
  value: string;
  data: LocationResult;
};

type LocationResult = NonNullable<
  NonNullable<FindAllLocationsFilteredQuery["findAllLocationsFiltered"]>["result"]
>[0] & {
  __typename: "LocationDtoOut";
};

const LIST_SIZE = 20;

export type UseCustomLocationsFilters = () => LocationFiltersDto;

const getUseConfig = (useFilters: UseCustomLocationsFilters): SelectLiveFieldUseConfig => {
  return function useConfig(prev) {
    const value = useFieldData<string | string[] | undefined>(prev.name, "values");
    const [filterText, setFilterText] = useState("");
    const debouncedFilterText = useDebouncedValue(filterText);
    const currentValueNodeIds = Array.isArray(value) ? value : value ? [value] : [];

    const [pickedOptions = [], { loading: pickedLoading }] = useMappedQuery(
      (data, previousData) => {
        const result = data ?? previousData;

        return ((result?.findAllLocationsFiltered?.result as LocationResult[])?.flatMap((p) =>
          p ? [{ label: p.name ?? "", value: p.nodeId ?? "", data: p }] : [],
        ) || []) as LocationOption[];
      },
      useFindAllLocationsFilteredQuery({
        skip: !value || !value?.length,
        variables: {
          locationFilters: {
            nodeIds: currentValueNodeIds,
          },
          searchRequest: {
            page: 0,
            size: currentValueNodeIds.length,
          },
        },
      }),
    );

    const listSizeRef = useRef(LIST_SIZE);
    const filters = useFilters();
    const [data = prev, { loading, refetch }] = useMappedQuery(
      (data, previousData) => {
        const response = data ?? previousData;
        const result = (response?.findAllLocationsFiltered?.result ?? []) as LocationResult[];

        const fullSize = response?.findAllLocationsFiltered?.fullSize ?? 0;

        const options: LocationOption[] = result
          .flatMap((p) => (p ? [{ label: p.name ?? "", value: p.nodeId ?? "", data: p }] : []))
          .sort(makeSorter("label"));

        return {
          ...prev,
          isLoading: false,
          options,
          onMenuScrollToBottom: () => {
            if (options.length >= fullSize) return;

            listSizeRef.current += LIST_SIZE;
            refetch({ searchRequest: { page: 0, size: listSizeRef.current } });
          },
        };
      },
      useFindAllLocationsFilteredQuery({
        variables: {
          locationFilters: { ...filters, name: debouncedFilterText || undefined },
          searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
        },
      }),
      true,
    );

    const options = useMemo(
      () => [
        ...pickedOptions,
        ...(data.options ?? []).filter((o) => !pickedOptions.find((p) => p.value === (o as LocationOption).value)),
      ],
      [data, pickedOptions],
    );

    return [
      {
        ...data,
        options,
        onInputChange: setFilterText,
        isLoading: loading || pickedLoading,
      },
      true,
    ];
  };
};

const LocationOptionComponent: CustomOption<LocationOption> = (props) => (
  <>
    {props.data.label}
    {props.data.data.country && (
      <div>
        <Text variant="paragraph">
          {props.data.data.country}, {props.data.data.city}
        </Text>
      </div>
    )}
  </>
);

export const getSelectLocationsFieldWithFilters = (
  value: SelectLiveFieldValueArg,
  useFilters: UseCustomLocationsFilters,
) =>
  getSelectLiveField(
    {
      label: "location.location",
      isLoading: true,
      isMulti: true,
      CustomOption: LocationOptionComponent,
      ...value,
    },
    getUseConfig(useFilters),
  );

export const getSelectLocationsField = (value: SelectLiveFieldValueArg) =>
  getSelectLocationsFieldWithFilters(value, () => ({}));
