import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { ReactComponent as MachineUserSVG } from "assets/icons/MachineUser.svg";
import { NamesListTooltip, VerticalNameList } from "base/components";
import { Spinner, Table, TableTypes, localFilters, usePagination } from "common/guideline";
import {
  FindAllMachineUserGroupsFilteredCountsQuery,
  useFindAllMachineUserGroupsFilteredCountsQuery,
  useFindMachineUserGroupByNodeIdQuery,
} from "generated";
import { FilterBox, useBooleanFilter } from "report/components";

import {
  AddMachineUsers,
  AddMachines,
  DeleteGroup,
  RemoveMachineUsers,
  RemoveMachines,
  UpdateName,
} from "./MachineUserGroupForm";

type MachineUserGroupRowData = NonNullable<
  NonNullable<FindAllMachineUserGroupsFilteredCountsQuery["findAllMachineUserGroupsFiltered"]>["result"]
>[0] & {
  __typename: "MachineUserGroupDtoOut";
};

const CellUpdateAction = ({ value, children }) => (
  <div className="group" tw="flex space-x-1 justify-between">
    <span>{value}</span>
    <div tw="flex gap-2 flex-shrink-[0]">{children}</div>
  </div>
);

const ShowNamesLazy: React.FC<{ nodeId: string; type: "machines" | "machineUsers" }> = ({ nodeId, type }) => {
  const { data, loading } = useFindMachineUserGroupByNodeIdQuery({ variables: { nodeId }, fetchPolicy: "no-cache" });
  const names = data?.findMachineUserGroupByNodeId?.[type]?.map((v) => v?.name ?? "-") ?? [];

  return loading ? <Spinner /> : <VerticalNameList names={names} />;
};

const getColumns: TableTypes.TranslatedColumns<MachineUserGroupRowData> = (t) => [
  {
    header: t("machineUserGroup.name"),
    id: "name",
    accessorKey: "name",
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
    cell({ getValue, row }) {
      const value = getValue<string>();
      return (
        <CellUpdateAction value={value}>
          <UpdateName name={value} nodeId={row.original.nodeId ?? ""} />
          <DeleteGroup name={value} nodeId={row.original.nodeId ?? ""} />
        </CellUpdateAction>
      );
    },
  },
  {
    header: t("mu.title_other"),
    id: "machineUsersCount",
    accessorKey: "machineUsersCount",
    cell({ getValue, row }) {
      const value = getValue<MachineUserGroupRowData["machineUsersCount"]>() ?? 0;
      const machineUsers = (
        <NamesListTooltip
          count={value}
          names={[]}
          Icon={MachineUserSVG}
          data-test="machineUsersAll"
          content={<ShowNamesLazy nodeId={row.original.nodeId ?? ""} type="machineUsers" />}
        >
          -
        </NamesListTooltip>
      );

      return (
        <CellUpdateAction value={machineUsers}>
          <AddMachineUsers nodeId={row.original.nodeId ?? ""} />
          <RemoveMachineUsers nodeId={row.original.nodeId ?? ""} />
        </CellUpdateAction>
      );
    },
  },
  {
    header: t("machineUserGroup.authorizedMachines"),
    id: "machinesCount",
    accessorKey: "machinesCount",
    cell({ getValue, row }) {
      const value = getValue<MachineUserGroupRowData["machinesCount"]>() ?? 0;
      const machineUsers = (
        <NamesListTooltip
          count={value}
          names={[]}
          Icon={ComputerTowerSVG}
          data-test="machinesAll"
          content={<ShowNamesLazy nodeId={row.original.nodeId ?? ""} type="machines" />}
        >
          -
        </NamesListTooltip>
      );

      return (
        <CellUpdateAction value={machineUsers}>
          <AddMachines nodeId={row.original.nodeId ?? ""} />
          <RemoveMachines nodeId={row.original.nodeId ?? ""} />
        </CellUpdateAction>
      );
    },
  },
];

const TABLE_NAME = "machineUserGroups";

export const MachineUserGroupsTable = () => {
  const { t, i18n } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [showMachineExclusive, BooleanFilter] = useBooleanFilter(
    "showMachineExclusive",
    "machineUserGroup.showMachineExclusive",
  );
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllMachineUserGroupsFilteredCountsQuery({
    variables: { filters: { showMachineExclusive }, searchRequest: { page: pageIndex, size: pageSize } },
  });

  const total = data?.findAllMachineUserGroupsFiltered?.fullSize ?? 0;
  const result = (data?.findAllMachineUserGroupsFiltered?.result ?? []) as MachineUserGroupRowData[];

  return (
    <>
      <FilterBox>{BooleanFilter}</FilterBox>
      <Table
        tableName={TABLE_NAME}
        columns={columns}
        data={result}
        loading={loading}
        error={error}
        initialLoading={previousData === undefined}
        onPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={total}
      />
    </>
  );
};
