import { Children, createContext, useCallback, useContext, useMemo, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Button, LoadingButton, ProgressBar, Tabs, Text } from "common/guideline";
import { getPercentage } from "common/helpers";

import { AnyObject, FormData, FormProvider, useForm } from "../form";

import { FormPropsRaw } from "./types";

type WizardContextType = {
  next: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  previous: () => void;
  isLastPage: boolean;
  page: number;
  pages: number;
  visitedPage: number;
};

const WizardContext = createContext({} as WizardContextType);

export const useWizardContext = () => useContext(WizardContext);

export const WizardWrapper = ({ children, submitLabel, steps }) => {
  const { isLastPage, page, pages, visitedPage, previous } = useWizardContext();

  return (
    <div tw="flex flex-col gap-2">
      <ProgressBar progress={getPercentage(pages, page)} variant="success" />

      <div tw="grid grid-flow-col [grid-gap:12px] ">
        {steps.map((label, i) => (
          <Tabs.Tab key={i} blocked active={page === i + 1} visited={i + 1 <= visitedPage}>
            <Text tKey={label} />
          </Tabs.Tab>
        ))}
      </div>

      {children}

      <div tw="flex space-x-2 mt-6">
        <Button type="button" disabled={page === 1} onClick={previous} data-test="previousStep">
          <Text tKey="common.previous" />
        </Button>
        <FormData type="isSubmittig">
          {(isLoading) => (
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              disabled={isLoading}
              data-test={isLastPage ? "submitForm" : "nextStep"}
            >
              <Text tKey={isLastPage ? submitLabel : "common.next"} />
            </LoadingButton>
          )}
        </FormData>
      </div>
    </div>
  );
};

export function SchemaFormWizard<FORM_DATA = AnyObject>({
  dom,
  SubmitComponent,
  children,
  formProps,
  ...formData
}: FormPropsRaw<FORM_DATA>) {
  const form = useForm<FORM_DATA>(formData);
  const submit = form.useStore(useCallback((s) => s.submit, []));

  const [{ page, visitedPage }, setPage] = useState<{ page: number; visitedPage: number }>({ page: 1, visitedPage: 0 });
  const pages = useMemo(() => Children.count(dom), [dom]);
  const previous = useCallback(() => setPage((p) => ({ ...p, page: Math.max(p.page - 1, 1) })), []);
  const next = useCallback(
    () =>
      setPage((p) => {
        const page = Math.min(p.page + 1, pages);
        return {
          page,
          visitedPage: Math.max(p.visitedPage, page - 1),
        };
      }),
    [pages],
  );
  const activePage = Children.toArray(dom)[page - 1];
  const isLastPage = page === pages;
  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      if (isLastPage) {
        submit(e);
      } else {
        e.stopPropagation();
        e.preventDefault();
        // clear errors beacause there is possibility that user made error on next page and return to previous one. we want to cleare those errors and validate form once again
        form.useStore.setState({ errors: undefined });
        const valid = await form.useStore.getState().validateForm();

        if (valid) {
          next();
        }
      }
    },
    [form.useStore, submit, next, isLastPage],
  );
  const wizardValue: WizardContextType = {
    next: handleSubmit,
    previous,
    isLastPage,
    page,
    pages,
    visitedPage,
  };

  return (
    <FormProvider form={form}>
      <WizardContext.Provider value={wizardValue}>
        <form {...formProps} onSubmit={handleSubmit}>
          {typeof children === "function" ? (
            children(activePage, form)
          ) : (
            <>
              {activePage}
              <Button disabled={page === 0} onClick={previous} type="button">
                Previous
              </Button>{" "}
              {SubmitComponent ? <SubmitComponent /> : <Button>{isLastPage ? "Submit" : "Next"}</Button>}
            </>
          )}
        </form>
      </WizardContext.Provider>
    </FormProvider>
  );
}
