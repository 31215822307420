import { Breadcrumb } from "common/guideline";

export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "administration.title",
    route: "ADMINISTRATION",
  },
  {
    text: "user.title_other",
    route: "USERS",
  },
];
