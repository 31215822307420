import { Flag, Spinner } from "@phosphor-icons/react";
import { useMemo } from "react";

import { ReactComponent as HouseSVG } from "assets/icons/House.svg";
import { ReactComponent as MapPinSVG } from "assets/icons/MapPin.svg";
import { NamesListTooltip, VerticalNameList } from "base/components";
import { CommonCells, Table, TableTypes, usePagination } from "common/guideline";
import { useFindAllLocationGroupsFilteredCountsQuery, useFindAllLocationGroupsFilteredQuery } from "generated";
import { useTranslation } from "i18n";

import { RowActions } from "./RowActions";
import { LocationGroupData, LocationGroupDataFull } from "./types";

const ShowNamesLazy: React.FC<{ nodeId: string; type: "locations" | "cities" | "countries" }> = ({ nodeId, type }) => {
  const { data, loading } = useFindAllLocationGroupsFilteredQuery({
    variables: { locationGroupFilters: { nodeIds: [nodeId] }, searchRequest: { page: 0, size: 1 } },
    fetchPolicy: "no-cache",
  });
  const value = data?.findAllLocationGroupsFiltered?.result?.[0] as LocationGroupDataFull | undefined;

  const names =
    type === "locations" ? value?.[type]?.map((v) => v?.name ?? "-") ?? [] : ((value?.[type] ?? []) as string[]);

  return loading ? <Spinner /> : <VerticalNameList names={names} />;
};

const columns: TableTypes.TranslatedColumns<LocationGroupData> = (t) => [
  {
    header: t("administration.lg.name"),
    accessorKey: "name",
    aggregate: "uniqueCount",
    Aggregated: (d) => t("administration.lg.count", { count: d.value }),
  },
  {
    header: t("administration.lg.authorizedUsers"),
    accessorKey: "authorizedUsers",
    aggregate: "count",
    Aggregated: (d) => t("administration.lg.count", { count: d.value }),
    meta: {
      hideVertical: true,
    },
  },
  {
    header: t("administration.countries"),
    accessorKey: "countriesCount",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue, row }) {
      const value = getValue<LocationGroupData["countriesCount"]>() ?? 0;
      return (
        <NamesListTooltip
          count={value}
          names={[]}
          Icon={Flag}
          content={<ShowNamesLazy nodeId={row.original.nodeId ?? ""} type="countries" />}
        >
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("administration.cities"),
    accessorKey: "citiesCount",
    aggregate: (l) => l.flat(),
    meta: {
      hideVertical: true,
    },
    cell({ getValue, row }) {
      const value = getValue<LocationGroupData["citiesCount"]>() ?? 0;
      return (
        <NamesListTooltip
          count={value}
          names={[]}
          Icon={HouseSVG}
          content={<ShowNamesLazy nodeId={row.original.nodeId ?? ""} type="cities" />}
        >
          -
        </NamesListTooltip>
      );
    },
  },
  {
    header: t("administration.lg.includedSites"),
    accessorKey: "locationsCount",
    meta: {
      hideVertical: true,
    },
    cell({ getValue, row }) {
      const value = getValue<LocationGroupData["locationsCount"]>() ?? 0;
      return (
        <NamesListTooltip
          count={value}
          names={[]}
          Icon={MapPinSVG}
          content={<ShowNamesLazy nodeId={row.original.nodeId ?? ""} type="locations" />}
        >
          -
        </NamesListTooltip>
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "siteGroups";

export const LocationGroupTable = () => {
  const { t, i18n } = useTranslation();
  const tColumns = useMemo(() => columns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllLocationGroupsFilteredCountsQuery({
    variables: {
      searchRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },
  });

  const result = (data?.findAllLocationGroupsFiltered?.result ?? []) as LocationGroupData[];
  const fullSize = data?.findAllLocationGroupsFiltered?.fullSize ?? 0;

  return (
    <Table
      tableName={TABLE_NAME}
      columns={tColumns}
      data={result}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      totalCount={fullSize}
      pageIndex={pageIndex}
      pageSize={pageSize}
      onPagination={setPagination}
    />
  );
};
