import tw from "twin.macro";
import "styled-components/macro";

import { Badge } from "./Badge";
import { Text } from "./Text";
import { Tooltip } from "./Tooltip";

type Props<T> = {
  data: T[];
  getData?: (data: T) => string | number;
  "data-test"?: string;
  initialDisplayCount?: number;
  children?: React.ReactNode;
};

export function BadgeList<T>({ data, getData, initialDisplayCount = 5, "data-test": dataTest, children }: Props<T>) {
  return (
    <div>
      {data.length === 0 ? (
        children
      ) : (
        <>
          {data.slice(0, initialDisplayCount).map((value, i) => {
            const text = (getData ? getData(value) : value) as string | number;
            return (
              <Badge tw="ml-1 mb-1" key={i}>
                {text}
              </Badge>
            );
          })}
          {data.length > initialDisplayCount ? (
            <Tooltip
              tw="inline-block"
              data-test={dataTest}
              content={() => (
                <div tw="[max-width: 375]">
                  {data.slice(initialDisplayCount).map((value, i) => {
                    const text = (getData ? getData(value) : value) as string | number;
                    return (
                      <Badge tw="ml-1 mb-1" key={i}>
                        {text}
                      </Badge>
                    );
                  })}
                </div>
              )}
            >
              <Badge variant="info" tw="ml-1 mb-1">
                <Text tKey="common.moreTypes" tValue={{ count: data.length - initialDisplayCount }} />
              </Badge>
            </Tooltip>
          ) : null}
        </>
      )}
    </div>
  );
}
