export const es = {
  user: {
    title_one: "Usuario",
    title_other: "Usuarios",
    desc: "Gestion de usuarios - crear, editar y eliminar usuarios administradores",
    userId: "ID de usuario",
    name: "Nombre",
    lastLogin: "Ultimo acceso",
    created: "Creado",
    createdBy: "Creado por",
    userType: "Usuario tipo",
    groups: "Grupos",
    roles: "Funciones",
    noRoles: "No funciones",
    permissions: "Permisos",
    tenantPermissions: "Permisos de inquilino",
    createUser: "Crear usuario",
    createUserDesc: "Crear un nuevo usuario: asignar un nombre de usuario, un nombre y funciones.",
    deleteUser: "Borrar usuario",
    deleteUserDesc: 'El usuario sera eliminado, haga clic en "Eliminar" para continuar.',
    createdDate: "Creado",
    lastModified: "Ultima modificacion",
    firstName: "Nombre",
    lastName: "Apellido",
    createSuccess: "Usuario guardado con exito",
    createError: "Se ha producido un error al guardar el usuario",
    updateSuccess: "Usuario actualizado con exito",
    updatedPasswordSuccess: "Se ha actualizado correctamente la contraseña del usuario",
    updatedPasswordError: "Se ha producido un error al actualizar la contraseña del usuario",
    updateError: "Se ha producido un error al actualizar el usuario",
    deleteSuccess: "Usuario eliminado con exito",
    deleteError: "Se ha producido un error al eliminar el usuario",
    update: "Actualizar usuario",
    userName: "Nombre de usuario",
    password: "Contraseña",
    updatePassword: "Actualizar contraseña",
    passwordRequired: "Contraseña requerida",
    delete: "Borra",
    allMachines: "Todas las maquinas",
    allSites: "Todos los sitios",
    allSiteGroups: "Todos los grupos de sitios",
    allTenants: "Todos los inquilinos",
    email: "Correo electronico",
    accountStatus: "Estado de la cuenta",
    active: "Activo",
    locked: "Bloqueada",
    accountIsLocked: "La cuenta esta bloqueada",
    phoneNumber: "Número de telefono",
    activateDeactivateSuccess: "Estado activo actualizado con exito",
    activateDeactivateError: "Se ha producido un error al actualizar el estado activo",
    selectDomain: "Seleccionar dominio",
    settings: {
      appearance: "Apariencia",
      changeYour: "Cambie su",
      timeSettings: "Ajustes de tiempo",
      weekStart: "Inicio de semana",
      dayStart: "Comienza el dia",
      dayStartDescription: "La configuracion de inicio de dia sera anulada por la configuracion del inquilino",
      dayEnd: "El dia termina",
      dayEndDescription: "La configuracion del final del dia sera anulada por la configuracion del inquilino",
      dayStartOrEnd: "El dia comienza / termina",
      weekStartDay0: "Domingo",
      weekStartDay1: "Lunes",
    },
    identity: {
      title: "Identidad",
    },
    access: {
      accessLevel: "Nivel de acceso",
      admin: "Admin",
      domain: "Dominio",
      permissions: "Permisos",
      Application_admin: "Admin",
      Application_nonadmin: "Non admin",
      UserRole_global_admin: "Administrador global",
      UserRole_global_support: "Soporte global",
      UserRole_admin: "Usuario admin",
      UserRole_user: "Usuario",
      Permission_tenant_ALL: "Todos los inquilinos",
      Permission_machine_ALL: "Todas las maquinas",
      // Algun error ortografico en el backend o en la base de datos, por lo que necesitamos tanto Permission_machine_ALL como Permission_machine_AL
      Permission_machine_AL: "Todas las maquinas",
      Permission_site_ALL: "Todos los sitios",
      Permission_siteGroup_ALL: "Todos los grupos de sitios",
      everyone: "Todo el mundo",
    },
  },
};
