import { useFlags as useFlagsDarkly } from "launchdarkly-react-client-sdk";

import { isEmpty } from "common/helpers";

import { Flags } from "./types";

export const useFlags: () => Partial<Flags> = useFlagsDarkly;
export const useFlagsWithLoading = () => {
  const flags = useFlags();
  const isLoading = isEmpty(flags);

  return { flags, isLoading };
};
