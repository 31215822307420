export const es = {
  zts: {
    title: "Gestión remota de dispositivos",
    commands: {
      title: "Comandos",
      command: "Comando",
      desc: "Ejecutar un comando en la máquina seleccionada",
      executeCommand: "Ejecutar comando",
      execute: "Ejecutar",
      commandHistory: "Historial de comandos ejecutados en la maquina seleccionada",
      started: "Inicio",
      ended: "Finalizado",
      status: "Estado",
      parameters: "Parametros",
      executedBy: "Ejecutado por",
      deleteSuccess: "Eliminado con exito el comando",
      deleteError: "Error al intentar borrar el comando.",
      deleteTitle: "Eliminar comando del historial",
      deleteDesc: "El comando se borrara del historial, ¿proceder?",
      close: "Cerrar",
      submit: "Borrar",
      selectCommand: "Seleccionar comando",
      restart: "Reiniciar maquina",
      reboot: "Reiniciar maquina",
      temporarilyOffline: "La máquina estara temporalmente fuera de servicio, ¿de acuerdo?",
      restartSuccess: "Solicitud de reinicio de la maquina enviada, por favor espere unos minutos.",
      rebootSuccess: "Solicitud para reiniciar la maquina enviada, por favor espere unos minutos.",
      restartFailure:
        "La solicitud para reiniciar la maquina ha fallado, por favor intentelo de nuevo en unos minutos.",
      rebootFailure: "La solicitud de reinicio de la maquina ha fallado, por favor intentelo de nuevo en unos minutos.",
      noTypesSelected: "Ningun tipo seleccionado",
      statuses: {
        InProgress: "En progreso",
        Restarting: "Reiniciando",
        Rebooting: "Reiniciando",
        Failure: "Fallido",
        FailedToClaim: "Maquina esta ocupada",
        Canceled: "Cancelado",
        Ok: "OK",
        Timeout: "Tiempo de espera agotado",
      },
      logs: {
        title: "Registros",
        logsDesc: "Recuperar y ver los registros de la maquina seleccionada",
        retrieveLogs: "Recuperar registros",
        retrievedLogs: "Archivos de registro recuperados",
        retrieveLogsModalTitle: "Recuperar los registros de la maquina seleccionada",
        retrieveLogsDesc: "Seleccione el periodo y los tipos de registro.",
        viewLogs: "Ver registros",
        from: "Desde",
        to: "a",
        fromMustBeSmallerThanTo: "Debe ser antes de",
        toMustBeGreaterThanFrom: "Debe ser despues de",
        retrievalDate: "Fecha de recuperacion",
        file: "Archivo",
        fileSize: "Tamaño",
        noFiles: "Número de ficheros",
        retrievedBy: "Recuperado por",
        profiling: "Perfil",
        configuration: "Configuracion",
        reports: "Informes",
        history: "Historial",
        receipts: "Recibos",
        icx: "ICX",
        data: "Datos",
        devices: "Dispositivos",
        online: "En linea",
        trace: "Traza",
        logTypes: "Tipos de registro",
        fullSystemDump: "¿Volcado completo del sistema?",
        retrieveLogsSuccess: "Se ha enviado el comando Recuperar registros, actualice esta vista en unos minutos",
        retrieveLogsFailure: "Se ha producido un error al enviar el comando para recuperar registros",
        deleteSuccess: "Eliminado con éxito el archivo de registro",
        deleteError: "Se ha producido un error al intentar eliminar el archivo de registro",
        deleteTitle: "Eliminar archivo de registro",
        deleteDesc: "Se eliminarán los metadatos y el archivo de registro cargado, ¿proceder?",
        missingPreSignedUrl: "No se ha podido obtener la url prefirmada",
        selectLogFile: "Seleccionar archivo de registro",
        fileTooLarge: "¡El archivo es demasiado grande!",
        fileTooLargeDesc: "El archivo es demasiado grande, ¿desea descargarlo en su lugar?",
        noLogsToShow: "Se ha producido un error o no hay registros que mostrar",
        err: {
          _1: "Error desconocido. Por favor, intentelo mas tarde",
          _2: "Debe ser un archivo zip.",
        },
      },
    },
    remoteUpgrade: {
      upgradeHistory: "Historial de actualizaciones",
      upgradeHistoryDesc: "Historial de instalaciones de software en la maquina seleccionada",
      timeOfUpgrade: "Actualizacion iniciada",
      name: "Nombre",
      label: "Etiqueta",
      softwareVersion: "Version del software",
      revision: "Revision",
    },
    remoteConfiguration: {
      configurationHistory: "Historial de configuraciones",
      configurationHistoryDesc: "Historial de despliegues de configuración en la maquina seleccionada",
      scheduledAt: "Programado a las",
      description: "Descripcion",
    },
    result: "Estado",
    executedBy: "Ejecutado por",
    latestStatusChange: "Estado cambiado",
    machineNodeId: "ID de maquina",
    current: "Actual",
    scheduleInfo: "Informacion del programa",
    template: {
      name: "Nombre",
      createdBy: "Creado por",
      description: "Descripcion",
      fileName: "Nombre del archivo",
      parts: "Partes",
      version: "Version",
    },
  },
};
