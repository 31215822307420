import { useEffect, useState } from "react";

import { FormStore, useFormContext } from "../form";

import { getInitial, getSubscriberFns } from "./helpers";
import { UseFieldOptions, UseFieldResult } from "./types";

export const useFieldRegister = <T>(
  name: string,
  useStore: FormStore<any>,
  data: Pick<UseFieldOptions<T>, "validate" | "defaultValue" | "calculation" | "data"> = {},
) => {
  useEffect(
    () => useStore.getState().registerFieldMeta(name, data),
    // IMPORTANT validate and defaultValue excluded from dependency
    // eslint-disable-next-line
    [name, useStore],
  );
};

export function useField<T>(
  name: string,
  {
    valueSub = true,
    errorSub,
    touchSub,
    dataSub,
    activeSub,
    parse,
    parseOnBlur,
    type,
    value,
    format,
    multiple,
    ...rest
  }: UseFieldOptions<T> = {},
): UseFieldResult {
  const { useStore } = useFormContext();

  useFieldRegister<T>(name, useStore, rest);

  const [state, setState] = useState<UseFieldResult>(() =>
    getInitial(
      name,
      format,
      type,
      value,
      rest.defaultValue,
      multiple,
      dataSub,
      errorSub,
      touchSub,
      activeSub,
      valueSub,
      parse,
      parseOnBlur,
      useStore,
    ),
  );

  useEffect(() => {
    const [get, equalityFn, set] = getSubscriberFns(
      name,
      format,
      type,
      value,
      dataSub,
      errorSub,
      touchSub,
      activeSub,
      valueSub,
      multiple,
    );
    return useStore.subscribe(get, set(setState), { equalityFn });
    // format fn excluded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, useStore, dataSub, errorSub, touchSub, valueSub, activeSub, type, value, multiple]);

  return state;
}
