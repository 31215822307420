import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "./../../common/guideline/components/helpers";

type Props = WithVariants<typeof variants>;
const variants = {
  insightsWrapper: tw`h-52`,
  iconWrapper: tw`h-28 text-primary-default`,
};
export const BoxContent = styled.div<Pick<Props, "variant">>`
  ${tw`h-52 flex justify-center items-center`}
  ${applyVariant(variants)}
`;
