import { useCallback } from "react";

import { identity } from "common/helpers";

import { useFormContext } from "../form/index";
import { TForm } from "../form/types";
import { getGetterByPath } from "../getGetterByPath";

import { UseFieldData } from "./types";

// eslint-disable-next-line
// @ts-ignore
export const useFieldData: UseFieldData = (name, type, parse = identity) =>
  useFormContext().useStore(
    useCallback(
      (state: TForm<any>) =>
        parse(type === "data" || type === "touched" ? state[type][name] : (getGetterByPath(name)(state[type]) as any)),
      [name, parse, type],
    ),
  );
