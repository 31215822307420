export const en = {
  tenant: {
    tenant_one: "Tenant",
    tenant_other: "Tenants",
    goBack: "Back",
    activeFetchErr: "Couldn't fetch tenant data",
    create: {
      title: "Create tenant",
      description: "Enter the tenant details to create a new tenant.",
      name: "Tenant name",
      contractNumber: "Contract number",
      billingId: "Billing ID",
      tenantId: "Tenant ID",
      userName: "User name",
      mqttPassword: "MQTT password",
      submit: "Create",
      apiError: "Could not create tenant, please try again",
      success: "Tenant created successfully",
      createDomain: "Create new domain",
      createUser: "Create new user",
      newUser: "New user",
      endOfDay: "Daybreak",
      licenseValidityIntervalDays: "License validity interval (days)",
      licenseAutoRenewalEnabled: "License auto renewal enabled",
    },
    update: {
      submit: "Update",
      title: "Update tenant",
      description: "Please change tenant details to update them.",
      apiError: "Could not update tenant, please try again",
      success: "Tenant updated successfully",
    },
    activate: {
      title: "Activate tenant",
      description: "Tenant <0>{{name}}</0> will be activated.",
      close: "Discard",
      submit: "Activate",
      error: "Could not activate tenant, please try again",
      success: "Tenant successfully activated",
    },
    deactivate: {
      title: "Deactivate tenant",
      description: "Tenant <0>{{name}}</0> will be deactivated.",
      close: "Discard",
      submit: "Deactivate",
      error: "Could not deactivate tenant, please try again",
      success: "Tenant deactivated successfully",
    },
    features: {
      titleUpdate: "Update {{name}} features",
      userBalance: "User balance",
      commonSoftwarePackageUploadEnabled: "Software package upload enabled",
      success: "Features successfully updated",
      mumEnabled: "Machine user management enabled",
      mumXmlImportEnabled: "Machine user management XML import enabled",
      apiError: "Could not updated tenant features, please try again",
      submit: "Save",
    },
    list: {
      tenantId: "Tenant ID",
      name: "Name",
      title: "Tenants",
      desc: "View and manage tenants",
      create: "Create tenant",
      status: "Status",
      changed: "Changed",
    },
  },
};
