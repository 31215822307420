import tw from "twin.macro";
import "styled-components/macro";

import { getInitials } from "common/helpers";

type Props = {
  fullName: string;
  src?: string;
};

export const Avatar: React.FC<Props> = ({ fullName, src }) => (
  <div tw="relative h-12 w-12 rounded-full border-2 border-primary-default text-primary-default bg-gray-2 flex justify-center items-center print:hidden">
    {src ? <img src={src} alt="profile image" tw="object-cover" /> : getInitials(fullName)}
    <div tw="absolute flex justify-center items-center -top-1 -right-1 h-5 w-5 rounded-full ring-2 ring-gray-1 bg-success-default">
      {/* TODO notification amount placeholder */}
      {/* <span tw="text-tiny text-white">9+</span> */}
    </div>
  </div>
);
