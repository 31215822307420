import { SelectLiveFieldValueArg, getSelectField } from "base/fields";
import { NodeStatus } from "generated";

const options: { value: NodeStatus; label: string }[] = [
  { value: "ACTIVE", label: "base.nodeStatus.ACTIVE" },
  { value: "INACTIVE", label: "base.nodeStatus.INACTIVE" },
];

export const getActiveOrInActiveStatusField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "base.nodeStatus.label",
    options,
    ...values,
  });
