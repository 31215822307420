import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout, TooltipValueWitdget } from "base/components";
import { SelectRaw } from "common/form/renderFields";
import { createSuspenseComponent, getLazyComponent } from "common/helpers";

import { CashOperationsContentData, CashOperationsSummaryData, useCashOperationsData } from "../../../hooks";
import type { Machine } from "../MachineView";

const ContentsChart = createSuspenseComponent(getLazyComponent(() => import("./ContentsChart"), "ContentsChart"));

const MainWidgets: React.FC<
  { summary: CashOperationsSummaryData; currency: string | undefined } & Pick<
    NonNullable<CashOperationsContentData>,
    "dispenseAvailabilityRatio" | "refillStatus"
  >
> = ({ currency, summary, dispenseAvailabilityRatio, refillStatus }) => (
  <div tw="grid gap-4 grid-cols-2 xl:grid-cols-4">
    <TooltipValueWitdget
      tooltipText="machine.availableForDispenseTooltip"
      title="machine.availableForDispense"
      currency={currency}
      value={summary.availableForDispense}
    />
    <TooltipValueWitdget
      tooltipText="machine.notDispensableTooltip"
      title="machine.notDispensable"
      currency={currency}
      value={summary.availableToBank}
    />
    <TooltipValueWitdget
      tooltipText="machine.availabilityRatioTooltip"
      title="machine.availabilityRatio"
      text={dispenseAvailabilityRatio ? (`${dispenseAvailabilityRatio}%` as any) : "base.notApplicable"}
    />
    <TooltipValueWitdget
      tooltipText="machine.refillStatusTooltip"
      title="machine.refillStatus"
      text={refillStatus ? `machine.refillStatuses.${refillStatus}` : "base.notApplicable"}
    />
  </div>
);

const Summary: React.FC<{ summary: CashOperationsSummaryData; currency: string | undefined }> = ({
  currency,
  summary,
}) => (
  <div tw="grid gap-2 [grid-template-columns:repeat(auto-fit,_minmax(150px,_1fr))] my-6">
    <TooltipValueWitdget
      title="machine.notes"
      tooltipText="machine.notesTooltip"
      currency={currency}
      value={summary.NOTE}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.coins"
      tooltipText="machine.coinsTooltip"
      currency={currency}
      value={summary.COIN}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.claimedValue"
      tooltipText="machine.claimedValueTooltip"
      currency={currency}
      value={summary.CLAIMED_VALUE}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.counterfeitNotes"
      tooltipText="machine.counterfeitNotesTooltip"
      currency={currency}
      value={summary.COUNTERFEIT_NOTES}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.suspectNotes"
      tooltipText="machine.suspectNotesTooltip"
      currency={currency}
      value={summary.SUSPECT_NOTES}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.checks"
      tooltipText="machine.checksTooltip"
      currency={currency}
      value={summary.CHECK}
      inline
      isSm
    />
    <TooltipValueWitdget
      title="machine.vouchers"
      tooltipText="machine.vouchersTooltip"
      currency={currency}
      value={summary.VOUCHER}
      inline
      isSm
    />
  </div>
);

type Props = {
  nodeId: Machine["nodeId"];
};

export const MachineContents: React.FC<Props> = ({ nodeId }) => {
  const { currencies = [], currency, setCurrency, summary, content, queryResult } = useCashOperationsData(nodeId || "");

  return (
    <PageLayout title="machine.contents" subtitle="machine.machineContentsDescription">
      <div tw="flex justify-end mb-4 gap-2">
        <SelectRaw
          name="currency"
          value={currency}
          onChange={setCurrency}
          label="common.currency"
          variant="dropdown"
          options={currencies}
        />
      </div>
      {queryResult.loading ? null : (
        <div tw="flex flex-col">
          <MainWidgets
            currency={currency}
            summary={summary}
            dispenseAvailabilityRatio={content?.dispenseAvailabilityRatio}
            refillStatus={content?.refillStatus}
          />
          <Summary currency={currency} summary={summary} />
          <ContentsChart currency={currency} content={content} />
        </div>
      )}
    </PageLayout>
  );
};
