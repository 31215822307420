import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as RollingBalanceSVG } from "assets/icons/RollingBalance.svg";
import { PageLayout } from "base/components";
import {
  Badge,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  localFilters,
  tableDownloadTitles,
  usePagination,
} from "common/guideline";
import { isoDateWithoutTime } from "common/helpers";
import { FindUserBalancesFilteredQuery, useFindUserBalancesFilteredQuery } from "generated";
import { useColumnFilters } from "report/components";
import { useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";

export type RollingBalanceType = NonNullable<
  NonNullable<NonNullable<FindUserBalancesFilteredQuery["findUserBalancesFiltered"]>["result"]>["0"]
> & {
  __typename: "UserBalanceDtoOut";
};

const getColumns: TableTypes.TranslatedColumns<RollingBalanceType> = (t) => [
  {
    id: "userName",
    header: t("transport.rBalance.machineUser"),
    accessorFn: (v) => v.machineUser?.name,
    enableHiding: false,
    enableSorting: false,
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
  {
    id: "date",
    header: t("transport.rBalance.date"),
    accessorFn: (v) => accessors.date(v.date, t),
    meta: {
      filter: localFilters.getDateFilter.meta.filter("date", (d) => (d ? isoDateWithoutTime(d) : d)),
    },
  },
  {
    id: "balance",
    header: t("transport.rBalance.balance"),
    accessorFn: (d) => accessors.number(d.balance ?? 0, t),
  },
  {
    id: "currencyCode",
    header: t("transport.rBalance.currencyCode"),
    accessorKey: "currencyCode",
    cell({ getValue }) {
      return <Badge variant="neutral">{getValue<string>()}</Badge>;
    },
    meta: {
      ...localFilters.getTextBaseFilter.meta,
    },
  },
];

const TABLE_NAME = "rollingBalance";

const columnFiltersData = [
  ["userName", "userName"],
  ["currencyCode", "currencyCode"],
  ["date", "date"],
] as const;
const defaultDateFilter = isoDateWithoutTime(new Date());

export const RollingBalance = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const [filters, columnFilters, setColumnFilters] = useColumnFilters(columnFiltersData);
  const date: string = filters.date || defaultDateFilter;
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useFindUserBalancesFilteredQuery({
    variables: {
      userBalanceFiltersDto: { ...filters, date },
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });

  const rows = (data?.findUserBalancesFiltered?.result || []) as RollingBalanceType[];
  const totalCount = data?.findUserBalancesFiltered?.fullSize || 0;

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="transport.rBalance.title"
      subtitle="transport.rBalance.desc"
      Icon={RollingBalanceSVG}
    >
      <Table
        tableName={TABLE_NAME}
        columns={columns}
        data={rows}
        error={error}
        loading={loading}
        initialLoading={previousData === undefined}
        onPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        onSorting={setSorting}
        totalCount={totalCount}
        columnFilters={columnFilters}
        onFilter={setColumnFilters}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDate(new Date(date).toISOString(), "transport.rBalance.title", t),
                page,
              )
            }
            disabled={!totalCount}
            getCsv
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
