import { getDeep } from "./getDeep";
import { isEmpty } from "./isEmpty";

function parent(object: any, path: string[]) {
  return path.length < 2 ? object : getDeep(object, path.slice(0, -1));
}

export const unset = (object: any, path: string[]) => {
  const result = object;

  object = parent(object, path);

  if (object != null) {
    delete object[path[path.length - 1]];

    if (isEmpty(object)) {
      delete result[path[path.length - 1]];
    }
  }

  return isEmpty(result) ? undefined : result;
};
