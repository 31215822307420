import { SelectLiveFieldValueArg, getSelectField } from "base/fields";
import { NodeStatus } from "generated";

const options: { value: NodeStatus; label: string }[] = [
  { value: "DELETED", label: "base.nodeStatus.DELETED" },
  { value: "DETACHED", label: "base.nodeStatus.DETACHED" },
  { value: "INACTIVE", label: "base.nodeStatus.INACTIVE" },
  { value: "ACTIVE", label: "base.nodeStatus.ACTIVE" },
  { value: "UNKNOWN", label: "base.nodeStatus.UNKNOWN" },
];

export const getNodeStatusField = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "base.nodeStatus.label",
    options,
    ...values,
  });
