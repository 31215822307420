import { SmileyXEyes } from "@phosphor-icons/react";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { Breadcrumb, Breadcrumbs, Button, Container, Text } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.404",
    route: "404",
  },
];

export const PageNotFound = () => (
  <>
    <Breadcrumbs breadcrumbs={breadcrumbs} />
    <Container variant="pageWrapper">
      <div tw="flex justify-center my-2">
        <SmileyXEyes size={96} weight="duotone" tw="text-error-default" />
      </div>
      <div tw="flex items-center flex-col">
        <Text tKey="common.pageError" variant="heading" />
        <Text tKey="common.returnHome" variant="paragraph" />
        <Button tw="my-3" variant="primary">
          <Link route="HOME">
            <Text tKey="common.btnHome" />
          </Link>
        </Button>
      </div>
    </Container>
  </>
);
