import { TKeys } from "i18next";
import Select, {
  MenuListProps,
  MultiValueProps,
  OptionProps,
  Props,
  ValueContainerProps,
  components,
} from "react-select";
import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { VariantsByKeys, WithVariants, applyVariant } from "common/guideline/components/helpers";
import { Text } from "common/guideline/components/Text";
import { screen } from "common/guideline/theme";
import { isObject } from "common/helpers";
import { useTranslation } from "i18n";

import { RawFieldData, RawFieldProps, fieldFactory } from "../schema";

import { CheckboxRaw } from "./Checkbox";
import { TextLabel } from "./Common";
import { findSelectValue } from "./findSelectValue";

type SelectVariants = "sm" | "dropdown";

const variants: VariantsByKeys<SelectVariants, { value: SelectValue; isMulti?: boolean }> = {
  sm: ({ isMulti }) => css`
    .rSelect__control {
      ${isMulti ? tw`min-h-[2rem]` : tw`h-8 min-h-[2rem]`}
    }

    .rSelect__input-container {
      ${tw`my-0`}
    }

    .rSelect__multi-value {
      ${tw`my-0`}
    }

    .rSelect__multi-value__label {
      ${tw`py-0`}
    }
  `,
  dropdown: ({ value }) =>
    (Array.isArray(value) ? value.length > 0 : Boolean(isObject(value) ? value.value : value)) &&
    css`
      .rSelect__control {
        ${tw`bg-primary-default border-primary-default caret-white hover:(border-primary-default)`}
      }

      .rSelect__single-value,
      .rSelect__input-container,
      .rSelect__clear-indicator,
      .rSelect__clear-indicator:hover,
      .rSelect__dropdown-indicator,
      .rSelect__dropdown-indicator:hover {
        ${tw`text-white`}
      }

      .rSelect__clear-indicator:hover,
      .rSelect__dropdown-indicator:hover {
        ${tw`opacity-60`}
      }
    `,
};

const StyledSelect = styled(Select)`
  .rSelect__control {
    ${tw`rounded bg-gray-2 border border-gray-3 transition-none caret-primary-default text-xs hover:(border-gray-3 cursor-pointer) sm:text-sm`}
    ${({ isMulti }) => (isMulti ? tw`min-h-[2.5rem]` : tw`h-10`)}

    &:focus-within {
      ${tw`border-primary-default`}
    }
  }

  .rSelect__value-container {
    ${tw`pr-0`}
  }

  .rSelect__single-value {
    ${tw`text-gray-7`}
  }

  .rSelect__menu {
    ${tw`border border-gray-3 bg-gray-2 m-0 overflow-hidden shadow-blue`}
  }

  .rSelect__control--is-focused {
    ${tw`shadow-none`}
  }

  .rSelect__control--is-focused.rSelect__control:hover {
    ${tw`border-primary-default`}
  }

  .rSelect__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${tw`!border-primary-default !border-b-transparent`}

    & + .rSelect__menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      ${tw`border-primary-default border-t-gray-3`}

      .rSelect__menu-list {
        ${tw`p-0`}
      }
    }
  }

  .rSelect__option {
    ${tw`text-sm`}
  }

  .rSelect__option:not(:first-child) {
    ${tw`border border-transparent border-t-gray-3`}
  }

  .rSelect__option:active,
  .rSelect__option--is-focused {
    ${tw`bg-gray-3`}
  }

  .rSelect__option--is-selected {
    color: inherit;
    ${tw`bg-primary-accent`}
  }

  .rSelect__indicator {
    ${tw`p-0`}
  }

  .rSelect__dropdown-indicator {
    ${tw`pr-1 w-5`}
  }

  .rSelect__indicator-separator {
    ${tw`hidden`}
  }

  .rSelect__multi-value {
    ${tw`bg-gray-4 rounded`}
  }

  .rSelect__multi-value__label {
    ${tw`text-gray-8`}
  }

  .withoutRemove .rSelect__multi-value__remove {
    ${tw`hidden`}
  }

  .rSelect__multi-value__remove:hover {
    ${tw`bg-error-alt text-error-default`}
  }

  .rSelect__placeholder {
    ${tw`text-gray-5`}
  }

  .rSelect__input-container {
    .rSelect__input {
      ${tw`dark:!text-gray-7`}
    }
  }

  .rSelect__control--is-disabled {
    ${tw`!cursor-not-allowed pointer-events-auto`}
  }

  ${applyVariant(variants)}
`;

const labelVariants = {
  column: tw`flex flex-col`,
  row: css`
    ${tw`flex flex-row items-center gap-x-2`}
    ${TextLabel} {
      ${tw`p-0`}
    }

    ${StyledSelect} {
      ${tw`flex-1`}
    }
  `,
  // this variant is a function because it depends on the object it is declared inside
  responsive: () => css`
    ${labelVariants.column}
    ${screen.min("sm")} {
      ${labelVariants.row}
    }
  `,
};

const StyledLabel = styled.label<WithVariants<typeof labelVariants> & { error?: any }>`
  ${TextLabel} {
    ${({ error }) => (error ? tw`text-error-default` : tw`text-gray-7`)}
  }

  &:focus-within {
    ${TextLabel} {
      ${({ error }) => (error ? tw`text-error-default` : tw`text-primary-default`)}
    }
  }

  ${({ error }) =>
    error
      ? css`
          .rSelect__control {
            ${tw`border-error-default hover:border-error-default`}
          }
        `
      : ""}

  ${applyVariant(labelVariants, "column")}
`;

const Option: React.FC<
  OptionProps<unknown, boolean> & {
    selectProps: { CustomOption?: CustomOption<BaseOption>; optionCheckbox?: boolean };
  }
> = (props) =>
  props.selectProps?.CustomOption ? (
    <components.Option {...props}>
      {props.selectProps?.optionCheckbox ? (
        <div tw="flex space-x-2 items-start">
          <CheckboxRaw type="checkbox" name="option" checked={props.isSelected} variant="sm" onChange={() => null} />
          <div>
            <props.selectProps.CustomOption data={props.data as BaseOption} />
          </div>
        </div>
      ) : (
        <props.selectProps.CustomOption data={props.data as BaseOption} />
      )}
    </components.Option>
  ) : props.selectProps?.optionCheckbox ? (
    <components.Option {...props}>
      <div tw="flex space-x-2 items-start">
        <CheckboxRaw type="checkbox" name="option" checked={props.isSelected} variant="sm" onChange={() => null} />
        <div>{props.label}</div>
      </div>
    </components.Option>
  ) : (
    <components.Option {...props} />
  );

const ValueContainer: React.FC<ValueContainerProps<unknown, boolean> & { selectProps: MetaProps }> = (props) =>
  props.selectProps.allSelected || props.selectProps.showMultiValuesCount ? (
    <components.ValueContainer {...props} className="withoutRemove">
      {props.children ? [[props.children[0]?.[0]], props.children[1]] : null}
    </components.ValueContainer>
  ) : (
    <components.ValueContainer {...props} />
  );

const MultiValue: React.FC<
  MultiValueProps<unknown, boolean> & {
    selectProps: MetaProps & { value?: any[] };
  }
> = (props) =>
  props.selectProps.allSelected ? (
    <components.MultiValue {...props}>
      <Text tKey="common.form.allSelected" tValue={{ count: props.selectProps.options?.length }} />
    </components.MultiValue>
  ) : props.selectProps.showMultiValuesCount ? (
    <components.MultiValue {...props}>
      <Text tKey="common.form.selectedItems" tValue={{ count: props.selectProps.value?.length || 0 }} />
    </components.MultiValue>
  ) : (
    <components.MultiValue {...props} />
  );

const MenuList: React.FC<
  MenuListProps<unknown, boolean> & { selectProps: MetaProps & { selectAllOption?: boolean } }
> = (props) => (
  <components.MenuList {...props}>
    {props.selectProps?.selectAllOption && props.selectProps.options?.length > 0 && (
      <components.Option {...(props as any)}>
        <div
          tw="flex space-x-2"
          onClick={() =>
            props.selectProps.onChange(props.selectProps.allSelected ? [] : props.selectProps.options, {
              action: "select-option",
              option: {},
            })
          }
        >
          <CheckboxRaw
            type="checkbox"
            name="selectAll"
            variant="sm"
            checked={props.selectProps.allSelected}
            onChange={() => null}
          />
          <Text tKey={`common.form.${props.selectProps.allSelected ? "deselectAll" : "selectAll"}`} />
        </div>
      </components.Option>
    )}

    {props.children}
  </components.MenuList>
);

const selectComponents = {
  Option,
};

const selectComponentsMulti = {
  Option,
  MenuList,
  ValueContainer,
  MultiValue,
};

export type BaseOption<T = any> = {
  value: string | number | undefined;
  label: string | number | undefined;
  data?: T;
  options?: BaseOption<T>[];
};

export type CustomOption<T extends BaseOption> = React.FC<{ data: T }>;
type SelectValue = BaseOption | BaseOption[] | BaseOption["value"];

type MetaProps = {
  allSelected: boolean;
  showMultiValuesCount: boolean;
};

// TODO - better typings - especially for value and onChnage
export type SelectProps = RawFieldProps<
  Omit<Props, "onChange"> &
    WithVariants<typeof variants> &
    WithVariants<typeof labelVariants, "labelVariant"> & {
      type: "select";
      disabled?: boolean;
      label?: TKeys;
      placeholder?: TKeys;
      value: SelectValue;
      CustomOption?: CustomOption<any>;
      getByValue?: boolean;
      labelCtx?: any;
      selectAllOption?: boolean;
      optionCheckbox?: boolean;
      maxOptionsDisplay?: number | false;
    }
>;

const getOnlyValue =
  (onChange: (value: any) => void) =>
  (value: any): void =>
    onChange(Array.isArray(value) ? value.map((v) => v.value) : value?.value);

export const SelectRaw: React.FC<RawFieldData<Omit<SelectProps, "type">>> = ({
  options,
  label,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  multiple,
  isMulti,
  error,
  className,
  labelVariant,
  labelCtx,
  maxOptionsDisplay = 4,
  getByValue = true,
  selectAllOption,
  optionCheckbox = multiple || isMulti || selectAllOption,
  ...rest
}) => {
  const { t } = useTranslation();
  const allSelected = isMulti ? value?.length === (options?.length || 0) : false;
  const showMultiValuesCount = isMulti ? (maxOptionsDisplay ? value?.length > maxOptionsDisplay : false) : false;

  return (
    <StyledLabel className={className} variant={labelVariant} error={error} data-test={name}>
      {label && <TextLabel tKey={label} tValue={labelCtx} error={error} />}
      <StyledSelect
        options={options}
        value={getByValue ? findSelectValue(options as any, value, isMulti) : value}
        onChange={getByValue ? getOnlyValue(onChange) : onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        isMulti={isMulti || multiple}
        classNamePrefix="rSelect"
        placeholder={placeholder ? (t(placeholder) as string) : undefined}
        openMenuOnFocus
        components={isMulti ? selectComponentsMulti : selectComponents}
        noOptionsMessage={() => t("common.form.noOptions")}
        formatGroupLabel={(g) => t((g.label || "") as any)}
        getOptionLabel={(option: any) => t(option.label)}
        hideSelectedOptions={isMulti ? !optionCheckbox : false}
        closeMenuOnSelect={!optionCheckbox}
        // eslint-disable-next-line
        // @ts-ignore
        selectAllOption={selectAllOption}
        // eslint-disable-next-line
        // @ts-ignore
        optionCheckbox={optionCheckbox}
        // eslint-disable-next-line
        // @ts-ignore
        allSelected={allSelected}
        // eslint-disable-next-line
        // @ts-ignore
        showMultiValuesCount={showMultiValuesCount}
        {...rest}
      />
      {error && <TextLabel error={error}>{error}</TextLabel>}
    </StyledLabel>
  );
};

export const SelectField = fieldFactory(SelectRaw);
