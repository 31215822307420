import tw from "twin.macro";
import "styled-components/macro";

import { PageLayout } from "base/components";

import { breadcrumbs } from "./breadcrumbs";

export const PosTransactions = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.posTransactions"
    subtitle="administration.posTransactionsDescription"
  />
);
