import tw from "twin.macro";
import "styled-components/macro";

import { TableTypes } from "common/guideline";
import { AmountDto, Maybe, RmPairDto } from "generated";
import { TFunction } from "i18n";

type DataWithTotalPairAmounts = { totalAmounts?: Maybe<Array<Maybe<RmPairDto>>> | undefined };

type AmountsPairRowOptions<T extends DataWithTotalPairAmounts> = Pick<
  TableTypes.ColumnDef<T>,
  "enableHiding" | "enableSorting" | "enableColumnFilter" | "id" | "footer" | "filterFn"
>;

export const getTotalAmountsPairRows = <T extends DataWithTotalPairAmounts>(
  rows: T[],
  t: TFunction,
  options?: AmountsPairRowOptions<T>,
) =>
  Object.values(
    rows
      .flatMap((r) => r.totalAmounts)
      .reduce((acc, curr) => {
        if (curr?.key && !acc[curr.key])
          acc[curr.key] = {
            header: t("report.totalAmount", { currency: curr.key }),
            enableColumnFilter: false,
            enableSorting: false,
            enableHiding: false,
            accessorFn: (data) => data.totalAmounts?.find?.((t) => t?.key === curr.key)?.value,
            ...options,
          };
        return acc;
      }, {} as Record<string, TableTypes.ColumnDef<T>>),
  );

type DataWithTotalAmounts = { totalAmounts?: Maybe<Array<Maybe<AmountDto>>> | undefined };

type AmountsRowOptions<T extends DataWithTotalAmounts, SUB_DATA extends TableTypes.GenericRecord> = Pick<
  TableTypes.ColumnWithSubAccessor<T, SUB_DATA>,
  "enableHiding" | "enableSorting" | "enableColumnFilter" | "id" | "footer" | "filterFn" | "meta"
> & {
  subAccessor?: (data: SUB_DATA, currency: string, index: number) => any;
};

export const getTotalAmountsRows = <T extends DataWithTotalAmounts, SUB_DATA extends TableTypes.GenericRecord = any>(
  rows: T[],
  t: TFunction,
  options?: { countColumn?: AmountsRowOptions<T, SUB_DATA>; amountColumn?: AmountsRowOptions<T, SUB_DATA> },
) =>
  Object.values(
    rows
      .flatMap((r) => r.totalAmounts)
      .reduce((acc, curr) => {
        if (curr?.currency && !acc[curr.currency]) {
          acc[`${curr.currency}_count`] = {
            header: t("report.totalCount", { currency: curr.currency }),
            enableColumnFilter: false,
            enableSorting: false,
            enableHiding: false,
            accessorFn: (data) => data.totalAmounts?.find?.((t) => t?.currency === curr.currency)?.count,
            ...options?.countColumn,
            subAccessor: options?.countColumn?.subAccessor
              ? (d, i) => options?.countColumn?.subAccessor?.(d, curr.currency as string, i)
              : undefined,
          };
          acc[curr.currency] = {
            header: t("report.totalAmount", { currency: curr.currency }),
            enableColumnFilter: false,
            enableSorting: false,
            enableHiding: false,
            accessorFn: (data) => {
              const amount = data.totalAmounts?.find?.((t) => t?.currency === curr.currency)?.amount;
              return amount ? t("numberFormat", { value: Number(amount) }) : amount;
            },
            ...options?.amountColumn,
            subAccessor: options?.amountColumn?.subAccessor
              ? (d, i) => options?.amountColumn?.subAccessor?.(d, curr.currency as string, i)
              : undefined,
          };
        }
        return acc;
      }, {} as Record<string, TableTypes.ColumnWithSubAccessor<T, SUB_DATA>>),
  );
