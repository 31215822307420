export const fr = {
  operations: {
    title: "Tableau de bord",
    cashOps: {
      title: "Opérations de trésorerie",
      desc: "Obtenez une vue d'ensemble des transactions de différents types. Vous pouvez utiliser le filtre pour sélectionner le groupe, le site ou la machine dont vous voulez voir les informations sur les transactions.",
    },
    techOps: {
      title: "Opérations technologiques",
      desc: "Utilisez le tableau de bord des opérations technologiques pour surveiller les machines.",
    },
    retailOps: "Opérations de ventes au détail",
    cashMetrics: "Métriques de trésorerie",
    recentTransactions: "Transactions récentes",
    recentUsers: "Utilisateurs récents",
    selectMachine: "Sélectionnez une machine dans la liste ci-dessus, pour voir ses détails.",
    selectSiteMachine: "Sélectionnez un site et une machine dans les listes ci-dessus, pour voir leurs détails.",
    openMap: "Ouvrir la carte",
    closeMap: "Fermer la carte",
  },
};
