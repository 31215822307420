export const fr = {
  mu: {
    title_one: "Utilisateur machine",
    title_other: " Utilisateurs machine",
    name: "Nom",
    uploadUsers: "Télécharger des utilisateurs",
    description: "Visualiser, gérer et créer des utilisateurs machine",
    create: "Créer un utilisateur machine",
    createDescription:
      "Vous créez un utilisateur machine lorsque vous voulez ajouter un utilisateur sur une ou plusieurs machines",
    update: "Mettre à jour un utilisateur machine",
    updateDescription: "Lorsque vous voulez mettre à jour un utilisateur sur une ou plusieurs machines",
    delete: "Supprimer un utilisateur machine",
    deleteDescription: "Lorsque vous voulez supprimer un utilisateur sur une ou plusieurs machines",
    deleteClose: "Fermer",
    roleName: "Rôle",
    userId: "Id utilisateur",
    success: "Utilisateur machine crée avec succès",
    error: "La création de l’utilisateur machine a échoué",
    updateSuccess: "Utilisateur machine mis à jour avec succès",
    updateApiError: "La mise à jour de l’utilisateur machine a échoué",
    deleteSuccess: "Utilisateur machine supprimé",
    deleteError: "La suppression de l’utilisateur machine a échoué",
    goBack: "Retour",
    machine: "Machine",
    updateMachineUsers: "Mettre à jour des utilisateurs machine",
    usersXml: "users.xml",
    selectFile: "Sélectionner le fichier",
    noFileSelected: "Vous devez fourn,ir un fichier xml valide",
    replaceUsers: "Remplacer les utilisateurs existant ?",
    uploadSuccess: "Le téléchargement des utilisateurs de la machine depuis le fichier a réussi",
    uploadFailure: "Une erreur s'est produite en essayant de télécharger les utilisateurs à partir du fichier",
    additionalLogins: "Modes d’identification additionnels",
    noCardLogin: "Pas d’identification par carte",
    noDoorLogin: "Pas d’identification sur ouverture de porte",
    cardLogin: "Identification par carte",
    doorLogin: "Identification sur ouverture de porte ",
    status: "Statut",
    validFrom: "Valide de",
    validUntil: "Valide jusqu’au",
    userSettings: "Paramètres utilisateur",
    loginSettings: "Paramètres d’identification",
    accountSettings: "Paramètres du compte",
    role: "Rôle",
    confirmPassword: "Confirmer le mot de passe",
    noPasswordRequired: "Aucun mot de passe requis",
    changePinOnFirstLogin: "Changer le mot de passe à la première connexion ?",
    noAllowedLogins: "Nombre de connexions autorisées",
    deactivated: "Désactivé ?",
    passwordsDoNotMatch: "Le mot de passe et sa confirmation ne sont pas les mêmes",
    unlimited: "Illimité",
    incorrectTimespan: "La date de fin de validité doit être supérieure à la date de début de validité",
    cashier: "Utilisateur machine",
    cashierId: "Id utilisateur machine",
    cashierName: "Nom du utilisateur machine",
    cashierRole: "Rôle du utilisateur machine",
    cit: "Convoyeur de fonds",
    administrator: "Administrateur",
    dts: "DTS",
    manager: "Responsable",
    noPinLogin: "Id usager",
    noPinCard: "Carte",
    noPinBio: "Biométrique",
    noPinDoor: "Porte",
    loginWith: "Pas de mot de passe pour :",
    group_one: "Groupe d'utilisateurs",
    group_other: "Groupes d'utilisateurs",
    deleteBulk: "Supprimer des utilisateurs machines",
    deleteDescBulk_one:
      "<0>{{count}}</0> utilisateur(s) machine sera(ont) supprimé, cette action ne peut pas être annulée.",
    deleteDescBulk_other:
      "<0>{{count}}</0> utilisateur(s) machine sera(ont) supprimé, cette action ne peut pas être annulée.",
    deleteSuccessBulk: "Les utilisateurs machine ont été supprimés avec succès",
    deleteErrorBulk: "La suppression des utilisateurs machine a échoué",
    uploadInProgress: "Téléchargement d'utilisateurs machines à partir d'un fichier",
  },
};
