import { CellContext } from "@tanstack/react-table";
import tw from "twin.macro";
import "styled-components/macro";

import { ActiveDotIndicator } from "base/components";

import { Text } from "../Text";

export const BooleanCell = ({ getValue }: CellContext<any, any>) => {
  const value = getValue();
  const isTrue = value === true || value === "true";

  return (
    <div tw="flex items-baseline">
      <ActiveDotIndicator isActive={isTrue} />
      <Text tKey={isTrue ? "common.yes" : "common.no"} />
    </div>
  );
};
