import { Breadcrumb } from "common/guideline";

export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "transport.title",
    route: "TRANSPORT",
  },
  {
    text: "tranCorr.title",
    route: "CORRECTION_TRANSACTIONS",
  },
];
