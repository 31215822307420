import { getDeep } from "./getDeep";

export const setDeep = (o: any = {}, path: string[], value: any) =>
  path.reduceRight((v, k, i, ks) => {
    const parent = i === 0 ? o : getDeep(o, ks.slice(0, i));
    const keyNumber = Number(k);

    if (isNaN(keyNumber)) {
      return { ...parent, [k]: v };
    }

    const arrParent = parent ? [...parent] : [];
    arrParent[keyNumber] = v;
    return arrParent;
  }, value);
