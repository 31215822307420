export const fr = {
  user: {
    title_one: "Utilisateur",
    title_other: "Utilisateurs",
    desc: "Gestion des utilisateurs : création, modification et suppression des utilisateurs",
    userId: "Id utilisateur",
    name: "Nom",
    lastLogin: "Dernière connexion",
    created: "Créé",
    createdBy: "Créé par",
    userType: "Type d’utilisateur",
    groups: "Groupes",
    roles: "Rôles",
    noRoles: "Pas de rôle",
    permissions: "Permissions",
    createUser: "Créer un utilisateur",
    createUserDesc: "Créer un nouvel utilisateur : attribuer un nom d'utilisateur, un nom et des rôles",
    deleteUser: "Supprimer un utilisateur",
    deleteUserDesc: 'L’utilisateur sera supprimé, cliquer sur "Supprimer" pour continuer',
    createdDate: "Créé",
    lastModified: "Dernière modification",
    firstName: "Prénom",
    lastName: "Nom",
    createSuccess: "Utilisateur créé avec succès",
    createError: "La création de l’utilisateur a échoué",
    updateSuccess: "Utilisateur mis à jour avec succès",
    updatedPasswordSuccess: "Mot de passe mis à jour avec succès",
    updatedPasswordError: "Une erreur est survenue pendant la mise à jour du mot de passe",
    updateError: "Une erreur est survenue pendant la mise à jour de l’utilisateur",
    deleteSuccess: "Utilisateur supprimé avec succès",
    deleteError: "Une erreur est survenue durant la suppression de l’utilisateur",
    update: "Mettre à jour l’utilisateur",
    userName: "Nom d’utilisateur",
    password: "Mot de passe",
    updatePassword: "Mettre à jour le mot de passe",
    passwordRequired: "Mot de passe requis",
    delete: "Supprimer",
    allMachines: "Toutes les machines",
    allSites: "Tous les sites",
    allSiteGroups: "Tous les groupes de sites",
    allTenants: "Tous les tenants",
    email: "Courriel",
    accountStatus: "Statut du compte",
    active: "Actif",
    locked: "Vérouillé",
    accountIsLocked: "Le compte est vérouillé",
    phoneNumber: "Numéro de téléphone",
    activateDeactivateSuccess: "Statut actif mis à jour avec succès",
    activateDeactivateError: "Une erreur s'est produite lors de la mise à jour du statut actif",
    selectDomain: "Séléctionner le domaine",
    tenantPermissions: "Accès tenant",
    confirmPassword: "Confirmer le mot de passe",
    settings: {
      appearance: "Apparence",
      changeYour: "Changez votre",
      timeSettings: "Réglage des jours et heure",
      weekStart: "La semaine démarre le",
      dayStart: "Le jour démarre à",
      dayStartDescription: "Les paramètres de début de jour seront remplacés par les paramètres du tenant.",
      dayEnd: "Fin de journée",
      dayEndDescription: "Le réglage de la fin de journée sera remplacé par les réglages du tenant.",
      dayStartOrEnd: "Le jour commence / termine",
      weekStartDay0: "Dimanche",
      weekStartDay1: "Lundi",
      setDaybreak: "Définir la fin de journée",
    },
    identity: {
      title: "Identité",
    },
    access: {
      accessLevel: "Niveau d’accès",
      admin: "Administrateur",
      domain: "Domaine",
      permissions: "Permissions",
      Application_admin: "Administrateur",
      Application_nonadmin: "Non administrateur",
      UserRole_global_admin: "Administrateur global",
      UserRole_global_support: "Support global",
      UserRole_admin: "Utilisateur administrateur",
      UserRole_user: "Utilisateur",
      Permission_tenant_ALL: "Tous les tenants",
      Permission_machine_ALL: "Toutes les machines",
      // Some misspelling on the backend or in the database, so we need both Permission_machine_ALL and Permission_machine_AL
      Permission_machine_AL: "Toutes les machines",
      Permission_site_ALL: "Tous les sites",
      Permission_siteGroup_ALL: "Tous les groupes de sites",
      everyone: "Tout le monde",
    },
  },
};
