import { useMemo } from "react";

import { getIsArrayField } from "./helpers";
import { renderInput as renderInputFn } from "./renderInput";
import { CustomFieldsSchema, CustomRenderInput, FieldSchema, RenderInput, UseFormGeneratorResult } from "./types";

type ComputeFormResult = React.ReactNode[];

export const computeForm = <T extends CustomFieldsSchema>({
  fields,
  renderInput,
}: {
  fields: Array<FieldSchema | T>;
  renderInput: RenderInput;
}): ComputeFormResult =>
  fields.reduce((acc, field, index) => {
    // thaverse child inputs
    // we dont neet to get dom for children of array - it will be redered for each row in array field itself
    const children =
      !getIsArrayField(field) && "fields" in field && field.fields
        ? computeForm<T>({
            fields: field.fields as Array<FieldSchema | T>,
            renderInput,
          })
        : undefined;

    acc.push(renderInput({ field, index, children }));

    return acc;
  }, [] as ComputeFormResult);

export const useFormGenerator = <T extends CustomFieldsSchema>(
  fields: Array<FieldSchema | T>,
  customRender?: CustomRenderInput<T>,
): UseFormGeneratorResult =>
  useMemo(() => computeForm({ fields, renderInput: renderInputFn(customRender) }), [fields, customRender]);
