export const fr = {
  base: {
    pageTitle: "Connect - $t({{title}})",
    notApplicable: "N/A",
    country: "Pays",
    printPage: "Imprimer la page",
    selectTimeZone: "Sélectionner un fuseau horaire",
    timeZone: "Fuseau horaire",
    queryByDate: {
      label: "Interrogation par",
      dateTime: "Heure machine",
      received: "Date de réception",
      accounting: "Date comptable",
    },
    nodeStatus: {
      label: "Statut",
      DELETED: "Supprimé",
      DETACHED: "Détaché",
      INACTIVE: "Inactif",
      ACTIVE: "Actif",
      UNKNOWN: "Inconnu",
    },
    onlineStatus: {
      Offline: "Hors ligne",
      Online: "En ligne",
      CatchingUp: "Synchronisation",
      Unknown: "Inconnu",
    },
    terms: {
      definitions: "1. Definitions",
      useRights: "2. Use Rights",
      useRightsUnordered: "Use Rights",
      support: "3. Support",
      supportUnordered: "Support",
      financialTerms: "4. Financial Terms",
      termAndTermination: "5. Term and Termination",
      confidentiality: "6. Confidentiality",
      indemnification: "7. Indemnification",
      serviceLevel: "8. Service Level Commitments, Disclaimers and Limitations",
      general: "9. General",
      saas: "PayComplete Software as a Service ('SaaS') Agreement",
      disclaimer:
        "IMPORTANT - PLEASE READ CAREFULLY: BY UTILIZING THE PAYCOMPLETE SERVICE YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS.",
      agreement:
        "This software as a service subscription agreement (the 'Agreement') governs your use of the System (as defined below) and any related services provided by PayComplete AMERICAS LLC., Delaware, USA Company having its principal place of business at 1475 E Woodfield Rd Suite 104, Schaumburg, IL 60173, USA ('Company'). You are referred to as 'Customer' in this Agreement. If you use the System, or if you click 'I agree' or take any other affirmative action indicating your acceptance of this Agreement, then you have agreed to these terms. If you are an agent or employee of the intended subscriber or user, you individually represent and warrant to Company that you are authorized to bind that party to this Agreement. If you do not agree to this Agreement, then you are not authorized to use the System.",
      components: "Components",
      componentsDesc:
        "'Components' means the individual modules or products that make up the System. From time to time, new Components or features will be introduced to the System, and those Components or features may be restricted to specific Editions.",
      customerData: "Customer Data",
      customerDataDesc:
        "'Customer Data' means any of Customer’s information, documents, or electronic files that are provided to Company hereunder.",
      documentation: "Documentation",
      documentationDesc: "'Documentation' means the online documentation provided at ",
      error: "Error",
      errorDesc:
        "'Error' means any reproducible material failure of the System to function in accordance with its Documentation.",
      maintenance: "Maintenance Windows",
      maintenanceDesc:
        "'Maintenance Windows' means collectively, standard maintenance and emergency maintenance. Standard maintenance windows will be published in advance on Company's website at least 72 hours in advance of the start of the standard maintenance window. Emergency maintenance will occur as needed. Company will make reasonable efforts to publish emergency maintenance windows on Company's website in advance of the emergency maintenance window, but it is possible that advanced notification of an emergency window may not occur.",
      system: "System",
      systemDesc:
        "'System' means the software service for which Customer has paid, including any Updates relating thereto that may be provided hereunder or thereunder, and any derivative works of the foregoing. A System is made up of individual Components.",
      serviceAdministrator: "Service Administrator",
      serviceAdministratorDesc:
        "'Service Administrator' means the person(s) that Customer designate(s) to purchase on behalf of Customer, authorize Users under the Agreement, create accounts for additional Users and otherwise administer Customer's use of System.",
      supportDesc:
        "'Support' means the ongoing services by Company to support the System as defined in Section 3 below.",
      update: "Update",
      updateDesc: "'Update' means any patch, bug fix, release, version, modification or successor to the System.",
      user: "User",
      userDesc:
        "'User' means a named individual to whom Customer has granted access to use the System on Customer’s behalf, regardless of whether or not the User actually accesses the Software. Users may be Customer's employees, consultants, contractors or agents.",
      edition: "Edition",
      editionDesc:
        "'Edition' means the named configuration of the System that has been licensed to the Customer. An Edition defines what Components, features, limits, and/or usage restrictions are placed on the System licensed to the Customer. From time to time, new Components or features will be introduced to the System, and those Components or features may be restricted to specific Editions. New named Editions may also be introduced from time to time.",
      effectiveDate: "Effective Date",
      effectiveDateDesc:
        "'Effective Date' is the date on which the Customer’s subscription to the licensed Edition of the System starts. The beginning of the contract term.",
      useRightsDesc:
        "During the term and subject to the terms of this Agreement, Company hereby grants to Customer a non-exclusive, non-transferable, non-sublicensable right to permit Customer's Users to use the licensed Edition of the System for Customer's business purposes. The use right in the preceding sentence is limited to use by the number of Users for which Customer has paid. Said use rights are non-transferable, except in the event of a voluntary transfer of substantially all assets by Customer to a transferee which executes Company’s form of agreement agreeing to be bound by all of the terms and conditions of this Agreement. All rights in and to the System not expressly granted herein are reserved to Company.",
      license: "License",
      licenseDesc:
        "License and Use Restrictions. Customer shall not, unless permitted by mandatory, applicable law, directly, indirectly, alone, or with another party, (i) copy, disassemble, reverse engineer, or decompile the System; (ii) modify, create derivative works based upon, or translate the System; (iii) license, sell, rent, lease, transfer, grant any rights in or otherwise commercially exploit the System in any form to any other party, nor shall Customer attempt to do any of the foregoing or cause or permit any third party to do or attempt to do any of the foregoing, except as expressly permitted hereunder. You acknowledge and agree that Company shall own all right, title and interest in and to all intellectual property rights (including all derivatives or improvements thereof) in the System and any suggestions, enhancement requests, feedback, recommendations or other information provided by Customer or any of Customer's Users relating to the System.",
      blank: "INTENTIONALLY LEFT BLANK",
      systemUser: "System Administrator; User Access",
      systemUserDesc:
        "Customer shall designate one or more System Administrators. System Administrators shall be responsible for managing User access, including adding and subtracting Users. The System Administrator shall ensure that multiple Users do not share a password or user name. Customer acknowledges and agrees that it is prohibited from sharing passwords and/or user names with unauthorized users.",
      customerDataUseRightsDesc:
        "Customer owns all right, title and interest in the Customer Data. Customer hereby grants to Company, a non-exclusive, non-transferable (except as set forth in Section 9(d) below), non-sublicensable right and license to use, copy, transmit, modify and display the Customer Data solely for purposes of Customer’s use of the System.",
      dataCustomer: "No Sensitive Data; Customer Responsibilities",
      dataCustomerDesc:
        "Customer acknowledges that the System is not intended for use with protected customer information including but not limited to health records, credit card numbers, financial account numbers, or other similarly-sensitive personal information, and that Customer assumes all risk arising from use of any such sensitive information with the System, including the risk of any inadvertent disclosure or unauthorized access thereto. Customer is responsible for ensuring that Customer and Customer's Users' use of the System is in compliance with all applicable laws and governmental regulations and Customer acknowledges that Customer assumes all risk arising from any such use that is not compliant with applicable laws and regulations.",
      security: "Security",
      securityDesc:
        "Customer is solely responsible for maintaining the security of all user names and passwords granted to it, for the security of its information systems used to access the System, and for its Users’ compliance with the terms of this Agreement. Company will act as though any electronic communications it receives under Customer's user names have been sent by Customer. Customer will immediately notify Company if it becomes aware of any loss or theft or unauthorized use of any of Customer's passwords or user names. Company has the right at any time to terminate or suspend access to any User or to Customer if Company believes in good faith that such termination or suspension is necessary to preserve the security, integrity, or accessibility of the System or Company's network.",
      servicesGenerally: "Services Generally",
      servicesGenerallyDesc:
        "Subject to the terms of this agreement, Company shall use commercially reasonable efforts to make the System available to Customer.",
      updates: "Updates",
      updatesDesc:
        "Company shall deliver Updates to the System that apply to the Customer’s currently licensed Edition at no additional charge. From time to time, new Components or features may be released that are applied selectively to different Editions of the System. Only those Updates that apply to the Customer’s currently licensed Edition will be delivered automatically to the Customer at no additional charge.",
      supportOptions: "Support Options and Procedures",
      supportOptionsDesc:
        "Company shall provide general support to the Customer as set forth on the Support Section of the Company website for the Customer's currently licensed Edition. Different Editions of the System will be entitled to different levels of support. In addition, Company may offer premium support options to Customer at an additional charge.",
      errorCorrection: "Error Correction",
      errorCorrectionDesc:
        "Company shall use commercially reasonable efforts to correct all Errors or to provide a reasonable workaround as soon as is possible using its reasonable efforts during Company's normal business hours. Customer shall provide such access, information, and support as Company may reasonably require in the process of resolving any Error. This paragraph is Customer's sole and exclusive remedy for Errors.",
      supportExclusions: "Support Exclusions",
      supportExclusionsDesc:
        "Company is not obligated to correct any Errors or provide any other support to the extent such Errors or need for support was created in whole or in part by:",
      seNestOneDesc:
        "the acts, omissions, negligence or willful misconduct of Customer, including any unauthorized modifications of the System or its operating environment;",
      seNestTwoDesc:
        "any failure or defect of Customer’s or a third party’s equipment, software, facilities, third party applications, or internet connectivity (or other causes outside of Company's firewall);",
      seNestThreeDesc: "Customer’s use of the System other than in accordance with the System’s documentation; or",
      seNestFourDesc: "a Force Majeure Event.",
      supportFees: "Support Fees",
      supportFeesDesc:
        "Company has the right to bill Customer at its standard services rates for any support issues excluded by Section 3(e) above that have been pre-approved in writing (including in an email) by Customer.",
      limitationOfRemedies: "Limitation of Remedies",
      limitationOfRemediesDesc:
        "Correction of Errors as defined in this Agreement are Customer’s sole remedies for any Errors in the System.",
      fees: "Fees",
      feesDesc:
        "In return for the products, services and use rights provided by Company to Customer hereunder, Customer shall pay to Company the fees in the amount set forth. All dollar amounts refer to U.S. dollars.",
      paymentTerms: "Payment Terms",
      paymentTermsDesc:
        "Company shall invoice Customer monthly or yearly in advance for all recurring charges, which invoices will also include all non-recurring charges and expenses incurred since the previous invoice. Customer shall pay all Company invoices within 30 days of the invoice date. If Customer is delinquent in payment of any portion of an invoice, Company may, in addition to any other remedies it may have, including termination, suspend access to the",
      systemProvision: "System and/or provision of all services to Customer",
      systemProvisionDesc:
        "Customer agrees to pay interest on delinquent amounts at the rate of 1½% per month (or, if lower, the maximum amount permitted by law) that a payment is overdue.",
      taxes: "Taxes",
      taxesDesc:
        "Customer shall pay or shall reimburse Company for all sales taxes and other taxes, however characterized by the taxing authority, based upon the license fees or other charges under this Agreement or otherwise incurred on account of Customer’s use of the System, except for any taxes based upon Company's net income or gross receipts or for any franchise or excise taxes owed by Company. If Customer is a tax-exempt organization, then, upon Company's receipt of proof of such status, then Company shall not charge Customer for any taxes from which Customer is exempt.",
      pricingChanges: "Pricing Changes",
      pricingChangesDesc:
        "Customers selecting annual pricing will receive notice of changes in pricing at least 45 days before each anniversary of the Effective Date. Customers selecting monthly pricing will receive notice of changes in pricing at least 45 days before the month in which the change in pricing will take effect.",
      term: "Term",
      termDesc:
        "The term of this Agreement commences on the Effective Date hereof. If Customer has selected an annual pricing plan, then the term will continue until the one year anniversary of the Effective Date, and will automatically renew for additional terms of one year each unless either party gives the other party written notice of its intention not to renew at least 30 days in advance of the then current term. If Customer has elected a monthly pricing plan, this agreement will continue until a party notifies the other party at least 30 days in advance of its intention to terminate.",
      terminationforCause: "Termination for Cause",
      terminationforCauseDesc:
        "Either party can terminate this Agreement for cause upon written notice to the other party:",
      tfcNestOneDesc:
        "if a party fails to pay the other party any delinquent amounts owed to the other party hereunder within 10 days of written notice by the other party specifying the amounts owed;in the case of Company, immediately upon any breach by Customer of Section 2(b) and/or Section 2(e) above;",
      tfcNestTwoDesc:
        "immediately upon any breach of any confidentiality obligations owed to such party by the other party;",
      tfcNestThreeDesc:
        "if the other party has committed any other material breach of its obligations under this Agreement and has failed to cure such breach within 30 days of written notice by the non-breaching party specifying in reasonable detail the nature of the breach (or, if such breach is not reasonably curable within 30 days, has failed to begin and continue to work diligently and in good faith to cure such breach); or",
      tfcNestFourDesc:
        "upon the institution of bankruptcy or state law insolvency proceedings against the other party, if such proceedings are not dismissed within 30 days of commencement.",
      obligationsUponTermination: "Obligations Upon Termination",
      obligationsUponTerminationDesc: "Obligations Upon Termination. Upon termination of this Agreement:",
      outNestOneDesc:
        "provided that Customer has paid all amounts owed to Company hereunder, Company shall, upon written request received within 30 days of termination, provide any Customer who purchased access rights to an Edition requiring payment of a fee with access to the System for a period of 24 hours for the limited purpose of exporting Customer Data;",
      outNestTwoDesc: "Company shall immediately terminate access to the System by Customer; and",
      outNestThreeDesc:
        "Customer shall immediately pay Company any amounts payable or accrued but not yet payable to Company, including any deferred payments or payments originally to be made over time.",
      confidentialInformation: "Confidential Information",
      confidentialInformationDesc:
        "'Confidential Information' means any and all tangible and intangible information (whether written or otherwise recorded or oral) of a party that: (A) derives independent economic value, actual or potential, from not being generally known to, and not being readily ascertainable by proper means by, other persons who can obtain economic value from its disclosure or use and is the subject of efforts that are reasonable under the circumstances to maintain its secrecy; or (B) the disclosing party designates as confidential or, given the nature of the information or the circumstances surrounding its disclosure, reasonably should be considered as confidential. Confidential Information includes, without limitation: (i) nonpublic information relating to a party’s technology, customers, business plans, promotional and marketing activities, finances and other business affairs; (ii) third-party information that Customer or Company is obligated to keep confidential; (iii) the material terms and conditions of this Agreement; and (iv) any nonpublic information relating to any activities conducted hereunder.",
      exclusions: "Exclusions",
      exclusionsDesc:
        "Notwithstanding the above, the term 'Confidential Information' does not include any information that is either: readily discernible from publicly-available products or literature; or approved for disclosure by prior written permission of an executive officer of the disclosing party.",
      useOfCi: "Use of Confidential Information",
      useOfCiDesc:
        "Each party shall only use Confidential Information furnished to it hereunder in furtherance of the activities contemplated by this Agreement, and,",
      useOfCiExceptionDesc:
        "except as authorized in this Agreement, it shall not disclose the Confidential Information to any other persons without the disclosing party’s express written authorization.",
      requiredDisclosures: "Required Disclosures",
      requiredDisclosuresDesc:
        "A receiving party may disclose Confidential Information of the disclosing party as required to comply with binding orders of governmental entities that have jurisdiction over it or as otherwise required by law, provided that the receiving party (i) gives the disclosing party reasonable written notice to allow it to seek a protective order or other appropriate remedy (except to the extent compliance with the foregoing would cause the receiving party to violate a court order or other legal requirement), (ii) discloses only such information as is required by the governmental entity or otherwise required by law, and (iii) and uses its best efforts to obtain confidential treatment for any Confidential Information so disclosed.",
      returnOfInformation: "Return of Information",
      returnOfInformationDesc:
        "Except as set forth otherwise in the specific provisions concerning Customer Data set forth in Section 5(c) above, if a disclosing party so requests at any time, the receiving party shall return promptly all copies, extracts, or other reproductions in whole or in part of the Confidential Information in its possession.",
      survival: "Survival",
      survivalDesc:
        "The parties hereto covenant and agree that this Section 6 will survive the expiration, termination, or cancellation of this Agreement for a period of 3 years, except for Confidential Information constituting a trade secret, with respect to which this Section will survive the expiration, termination, or cancellation of this Agreement for so long as such Confidential Information remains a trade secret.",
      indemnificationByCompany: "Indemnification by Company",
      indemnificationByCompanyDesc:
        "FOR CUSTOMER USING ONLY A FREE EDITION OF THE SYSTEM, THIS SECTION 7(A) DOES NOT APPLY AND YOU ACKNOWLEDGE THAT YOU ARE NOT ENTITLED TO ANY INDEMNIFICATION FROM COMPANY. For Customers using an Edition of the System on a monthly or annual payment plan, Company shall defend, indemnify and hold harmless Customer from and against all damages, liabilities, losses and expenses, including reasonable attorneys’ fees and expenses, resulting from any third-party claim, suit or proceeding that arises from Customer and/or the Customer's Users' use of the System in accordance with this Agreement that, to Company's knowledge, infringes or misappropriates any U.S. trade secret, trademark, or copyright. Company will have no indemnity obligation to Customer if the alleged infringement or misappropriation is based on (i) any combination, operation, or use of the System with products, services, information, materials, technologies, business methods or processes not furnished by Company to the extent the infringement or misappropriation is based on such combination, operations or use; (ii) any modification (other than by Company) to the System to the extent the infringement or misappropriation is based on such modification; or (iii) the Customer's failure to promptly install any Update that is provided by Company that would have eliminated the actual or alleged infringement or misappropriation.",
      indemnificationByCustomer: "Indemnification by Customer",
      indemnificationByCustomerDesc:
        "Customer shall defend, indemnify and hold harmless Company from and against all damages, liabilities, losses and expenses, including reasonable attorneys’ fees and expenses, resulting from any third-party claim, suit or proceeding that arises from the Customer and/or the Customer's Users' use of the System (other than to the extent indemnified by Company under Section 7(a) or, in the case of a Customer using only a free Edition of the System, other than to the extent the third-party claim would have been subject to indemnification by Company under Section 7(a) if Section 7(a) applied to Customer).",
      indemnificationProcess: "Indemnification Process",
      indemnificationProcessDesc:
        "The indemnified party shall promptly notify the indemnifying party in writing of any third party claim, stating the nature and basis of the third party claim, to the extent known. The indemnifying party shall have sole control over the defense and settlement of any third party claim, provided that, within fifteen (15) days after receipt of the above-described notice, the indemnifying party notifies the Indemnified Party of its election to so assume full control. The foregoing notwithstanding, the indemnified party shall be entitled to participate in the defense of such third party claim and to employ counsel at its own expense to assist in the handling of such claim, except that the indemnified party’s legal expenses in exercising this right shall be deemed legal expenses subject to indemnification hereunder to the extent that (x) the indemnifying party fails or refuses to assume control over the defense of the third party claim within the time period set forth above; (y) the indemnified party deems it reasonably necessary to file an answer or take similar action to prevent the entry of a default judgment, temporary restraining order, or preliminary injunction against it; or (z) representation of both parties by the same counsel would, in the opinion of that counsel, constitute a conflict of interest. The indemnifying party shall not settle any such third party claim without the written consent of the indemnified party, except for a complete settlement requiring only the payment of money damages to be paid by the indemnifying party.",
      soleRemedy: "Sole Remedy",
      soleRemedyDesc:
        "Indemnification pursuant to this Section is the parties’ sole remedy for any third party claim against the other party in the nature of intellectual property infringement or misappropriation.",
      serviceLevelCommitmentsCredits: "Service Level Commitments; Credits",
      serviceLevelCommitmentsCreditsDesc:
        "Company guarantees that the System and all Services provided on the System will be accessible to Customer’s authorized Users 99.5% of the time in any given calendar month, excluding Maintenance Windows for all paid Editions. Notwithstanding the foregoing, Company does not guarantee network availability between Customer and the Company hosting servers, as such availability can involve numerous third parties and is beyond the control of Company. Company will not be liable for nor provide any service credits hereunder for any downtime caused in whole or part by a third-party data center provider nor for any downtime that Customer experiences as a result of Customer or Customer’s Users’ own network connectivity issues. If Customer experiences a System or Service outage and is unable to access the System or any Service, Customer must immediately contact Company’s help desk, providing any/all necessary information that may assist Company in determining the cause of the outage. Company will determine in good faith whether the outage was within Company’s reasonable control. If Company determines that a timely reported outage was attributable to Company, then Company will",
      creditCustomerDesc:
        "credit Customer 1-day of Service fees for every 2 hours of downtime Customer experienced, up to a maximum of half of that month’s Service fees. This shall be Customer’s sole remedy, and Company’s sole liability, for Company’s failure to provide the guaranteed availability set forth in this Section 8(a).",
      disclaimerOfWarranties: "Disclaimer of Warranties",
      disclaimerOfWarrantiesDesc:
        "EXCEPT FOR THE LIMITED SERVICE LEVEL COMMITMENTS SET FORTH IN SECTION 8(A) AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY MAKES NO, AND HEREBY DISCLAIMS ANY, REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SYSTEM, THE SERVICES PROVIDED OR THE AVAILABILITY, FUNCTIONALITY, PERFORMANCE OR RESULTS OF USE OF THE SYSTEM. WITHOUT LIMITING THE FOREGOING, EXCEPT AS SPECIFICALLY SET FORTH IN THE LIMITED SERVICE LEVEL COMMITMENTS IN SECTION 8(A), COMPANY DISCLAIMS ANY WARRANTY THAT THE SYSTEM, THE SERVICES PROVIDED BY COMPANY, OR THE OPERATION OF THE SYSTEM ARE OR WILL BE ACCURATE, ERROR-FREE OR UNINTERRUPTED. COMPANY MAKES NO, AND HEREBY DISCLAIMS ANY, IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF NON-INFRINGEMENT, MERCHANTABILITY, OF FITNESS FOR ANY PARTICULAR PURPOSE OR ARISING BY USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE.",
      disclaimerOfConsequentialDamages: "Disclaimer of Consequential Damages",
      disclaimerOfConsequentialDamagesDesc:
        "TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY HAS NO LIABILITY WITH RESPECT TO THE SYSTEM, SERVICES, OR ITS OTHER OBLIGATIONS UNDER THIS AGREEMENT OR OTHERWISE FOR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES (INCLUDING WITHOUT LIMITATION LOSS OF PROFITS AND THE COST OF COVER) EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.",
      limitationsOfRemediesAndLiability: "Limitations of Remedies and Liability",
      limitationsOfRemediesAndLiabilityDesc:
        "TO THE EXTENT PERMITTED BY APPLICABLE LAW, COMPANY'S TOTAL AGGREGATE LIABILITY TO CUSTOMER FOR ANY REASON AND UPON ANY CAUSE OF ACTION INCLUDING WITHOUT LIMITATION, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATIONS, AND OTHER TORTS, IS LIMITED TO (1) IN THE CASE OF CUSTOMER ON A MONTHLY PAYMENT PLAN, ALL FEES PAID TO COMPANY BY THE CUSTOMER IN RESPECT OF USER LICENSES FOR THE SYSTEM DURING THE THREE MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING RISE TO THE LIABILITY; (2) IN THE CASE OF CUSTOMER ON AN ANNUAL PAYMENT PLAN, ALL FEES PAID TO COMPANY BY THE CUSTOMER IN RESPECT OF USER LICENSES FOR THE SYSTEM DURING THE TWELVE MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING RISE TO THE LIABILITY; OR (3) IN THE CASE OF CUSTOMER USING ONLY A FREE EDITION OF THE SYSTEM, ONE HUNDRED UNITED STATES DOLLARS ($100.00).",
      notices: "Notices",
      noticesDesc:
        "Notices regarding this Agreement to Company shall be in writing and sent by first class mail or overnight courier at the address provided at that time on Company's website.",
      noticesDisclaimerDesc:
        "Company may give notice by means of posting notice on the System, by electronic mail to Customer's e-mail address on record with Company, or by written communication sent by first class mail or overnight courier to Customer's address on record in Company's account information. All notices shall be deemed to have been given three days after mailing or posting (if sent by first class mail), upon delivery in the case of courier, or 12 hours after either sending by e-mail or posting on the System.",
      forceMajeure: "Force Majeure",
      forceMajeureDesc:
        "'Force Majeure Event' means any act or event that (a) prevents a party (the 'Nonperforming Party') from performing its obligations or satisfying a condition to the other party’s (the 'Performing Party') obligations under this Agreement, (b) is beyond the reasonable control of and not the fault of the Nonperforming Party, and (c) the Nonperforming Party has not, through commercially reasonable efforts, been able to avoid or overcome. 'Force Majeure Event' does not include economic hardship, changes in market conditions, and insufficiency of funds. If a Force Majeure Event occurs, the Nonperforming Party is excused from the performance thereby prevented and from satisfying any conditions precedent to the other party’s performance that cannot be satisfied, in each case to the extent limited or prevented by the Force Majeure Event. When the Nonperforming Party is able to resume its performance or satisfy the conditions precedent to the other party’s obligations, the Nonperforming Party shall immediately resume performance under this Agreement. The relief offered by this paragraph is the exclusive remedy available to the Performing Party with respect to a Force Majeure Event.",
      assignment: "Assignment",
      assignmentDesc:
        "Company may assign any of its rights or obligations under this Agreement at any time; provided, however, that Company shall not assign the rights granted to Customer Data in Section 2(d) except in connection with the sale (whether by merger, asset sale, equity sale or otherwise) of (i) Company, (ii) the System or (iii) a portion of Company or the System that would reasonably require the acquirer of said portion to be assigned such rights to the Customer Data. Customer shall not assign any of its rights under this Agreement, except with the prior written approval of Company, which shall not be unreasonably withheld. The preceding sentence applies to all assignments of rights, except in the event of a voluntary transfer of substantially all assets by Customer to a transferee which executes Company’s form of agreement agreeing to be bound all of the terms and conditions of this Agreement. Any change of control transaction is deemed an assignment hereunder. Any purported assignment of rights in violation of this Section is void.",
      governingLawVenue: "Governing Law; Venue",
      governingLawVenueDesc:
        "The laws of the State of Illinois in the United States of America (without giving effect to its conflict of laws principles) govern all matters arising out of or relating to this Agreement and the transactions it contemplates, including, without limitation, its interpretation, construction, performance, and enforcement. Any claims or actions regarding or arising out of this Agreement must be brought exclusively in a court of competent jurisdiction sitting in Delaware, and each party to this Agreement submits to the jurisdiction of such courts for the purposes of all legal actions and proceedings arising out of or relating to this Agreement. Each party waives, to the fullest extent permitted by law, any objection that it may now or later have to (i) the laying of venue of any legal action or proceeding arising out of or relating to this Agreement brought in any state or federal court sitting in Delaware; and (ii) any",
      claimThatDesc:
        "claim that any action or proceeding brought in any such court has been brought in an inconvenient forum.",
      recoveryOfLitigationCosts: "Recovery of Litigation Costs",
      recoveryOfLitigationCostsDesc:
        "If any legal action or other proceeding is brought for the enforcement of this Agreement, or because of an alleged dispute, breach, default or misrepresentation in connection with any of the provisions of this Agreement, the unsuccessful party shall pay to the successful party its reasonable attorneys’ fees and other costs incurred in that action or proceeding, in addition to any other relief to which the successful party may be entitled.",
      entireAgreement: "Entire Agreement",
      entireAgreementDesc:
        "This Agreement constitutes the final agreement between the parties. It is the complete and exclusive expression of the parties’ agreement on the matters contained in this Agreement. All prior and contemporaneous negotiations and agreements between the parties on the matters contained in this Agreement are expressly merged into and superseded by this Agreement. The provisions of this Agreement cannot be explained, supplemented or qualified through evidence of trade usage or a prior course of dealings. In entering into this Agreement, neither party has relied upon any statement, representation, warranty or agreement of any other party except for those expressly contained in this Agreement. There are no conditions precedent to the effectiveness of this Agreement, other than any that are expressly stated in this Agreement.",
      amendments: "Amendments",
      amendmentsDesc:
        "The parties can amend this Agreement only by a written agreement of the parties that identifies itself as an amendment to this Agreement.",
      survivalOfCertainProvisions: "Survival of Certain Provisions",
      survivalOfCertainProvisionsDesc:
        "Each party hereto covenants and agrees that the provisions in Sections 1, 2(b), 5(c), 6, 7, 8, and 9 in addition to any other provision that, by its terms, is intended to survive the expiration or termination of this Agreement, shall survive the expiration or termination of this Agreement.",
    },
  },
};
