import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { ReactComponent as PrinterSVG } from "assets/icons/Printer.svg";
import { PageLayout } from "base/components";
import { Badge, Breadcrumb, Button, FullLine, Tabs, Text, Tooltip } from "common/guideline";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "common.terms",
    route: "TERMS",
  },
];

export const Terms = () => {
  const { t } = useTranslation();
  const scrollToElementId = (id: string) => () => document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });

  return (
    <PageLayout breadcrumbs={breadcrumbs} pageTitle="common.terms" title="common.terms" subtitle="common.termsDesc">
      <div tw="flex relative gap-8">
        <div tw="w-1/5">
          <Tabs>
            <Tabs.TabList element="nav" tw="flex flex-col sticky top-0 gap-1">
              <Tabs.Tab tw="flex-1" data-test="definitions">
                <Text tKey="base.terms.definitions" onClick={scrollToElementId("definitions")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="use-rights">
                <Text tKey="base.terms.useRights" onClick={scrollToElementId("use-rights")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="support">
                <Text tKey="base.terms.support" onClick={scrollToElementId("support")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="financial-terms">
                <Text tKey="base.terms.financialTerms" onClick={scrollToElementId("financial-terms")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="terms-and-termination">
                <Text tKey="base.terms.termAndTermination" onClick={scrollToElementId("terms-and-termination")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="confidentiality">
                <Text tKey="base.terms.confidentiality" onClick={scrollToElementId("confidentiality")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="indemnification">
                <Text tKey="base.terms.indemnification" onClick={scrollToElementId("indemnification")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="service-level">
                <Text tKey="base.terms.serviceLevel" onClick={scrollToElementId("service-level")} />
              </Tabs.Tab>
              <Tabs.Tab tw="flex-1" data-test="general">
                <Text tKey="base.terms.general" onClick={scrollToElementId("general")} />
              </Tabs.Tab>
            </Tabs.TabList>
          </Tabs>
        </div>
        <dl tw="w-3/5 text-gray-8 text-sm">
          <Text variant="heading" tKey="base.terms.saas" />
          <Text tw="text-gray-8 text-sm flex pb-1.5 my-1.5 font-semibold" tKey="base.terms.disclaimer" />
          <Text tw="text-gray-8 text-sm flex pb-1.5 my-1.5 font-semibold" tKey="base.terms.agreement" />
          {/* DEFINITIONS */}
          <dt>
            <Text id="definitions" variant="heading" tKey="base.terms.definitions" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.components" />
            <Text variant="content" tKey="base.terms.componentsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.customerData" />
            <Text variant="content" tKey="base.terms.customerDataDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.documentation" />
            <Text variant="content" tKey="base.terms.documentationDesc" />
            <Text variant="link">
              <a target="blank" href="https://docs.paycomplete.com">
                https://docs.paycomplete.com
              </a>
              .
            </Text>
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.error" />
            <Text variant="content" tKey="base.terms.errorDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.maintenance" />
            <Text variant="content" tKey="base.terms.maintenanceDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              F
            </Badge>
            <Text variant="subHeading" tKey="base.terms.system" />
            <Text variant="content" tKey="base.terms.systemDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              G
            </Badge>
            <Text variant="subHeading" tKey="base.terms.serviceAdministrator" />
            <Text variant="content" tKey="base.terms.serviceAdministratorDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              H
            </Badge>
            <Text variant="subHeading" tKey="base.terms.supportUnordered" />
            <Text variant="content" tKey="base.terms.supportDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              I
            </Badge>
            <Text variant="subHeading" tKey="base.terms.update" />
            <Text variant="content" tKey="base.terms.updateDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              J
            </Badge>
            <Text variant="subHeading" tKey="base.terms.user" />
            <Text variant="content" tKey="base.terms.userDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              K
            </Badge>
            <Text variant="subHeading" tKey="base.terms.edition" />
            <Text variant="content" tKey="base.terms.editionDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              L
            </Badge>
            <Text variant="subHeading" tKey="base.terms.effectiveDate" />
            <Text variant="content" tKey="base.terms.effectiveDateDesc" />
          </dd>
          {/* USE RIGHTS */}
          <dt>
            <Text id="use-rights" variant="heading" tKey="base.terms.useRights" />
          </dt>
          <FullLine tw="my-1.5 space-x-2" />
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.useRightsUnordered" />
            <Text variant="content" tKey="base.terms.useRightsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.license" />
            <Text variant="content" tKey="base.terms.licenseDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.blank" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.systemUser" />
            <Text variant="content" tKey="base.terms.systemUserDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.customerData" />
            <Text variant="content" tKey="base.terms.customerDataUseRightsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              F
            </Badge>
            <Text variant="subHeading" tKey="base.terms.dataCustomer" />
            <Text variant="content" tKey="base.terms.dataCustomerDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              G
            </Badge>
            <Text variant="subHeading" tKey="base.terms.security" />
            <Text variant="content" tKey="base.terms.securityDesc" />
          </dd>
          {/* SUPPORT */}
          <dt>
            <Text id="support" variant="heading" tKey="base.terms.support" />
          </dt>
          <FullLine tw="my-1.5 space-x-2" />
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.servicesGenerally" />
            <Text variant="content" tKey="base.terms.servicesGenerallyDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.updates" />
            <Text variant="content" tKey="base.terms.updatesDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.blank" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.supportOptions" />
            <Text variant="content" tKey="base.terms.supportOptionsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.errorCorrection" />
            <Text variant="content" tKey="base.terms.errorCorrectionDesc" />
          </dd>
          <div tw="ml-8">
            <dd tw="my-1.5 space-x-2">
              <Badge variant="info" tw="w-6">
                I
              </Badge>
              <Text variant="subHeading" tKey="base.terms.supportExclusions" />
              <Text variant="content" tKey="base.terms.supportExclusionsDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                II
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.seNestOneDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                III
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.seNestTwoDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                IV
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.seNestThreeDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                V
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.seNestFourDesc" />
            </dd>
          </div>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              F
            </Badge>
            <Text variant="subHeading" tKey="base.terms.supportFees" />
            <Text variant="content" tKey="base.terms.supportFeesDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              G
            </Badge>
            <Text variant="subHeading" tKey="base.terms.limitationOfRemedies" />
            <Text variant="content" tKey="base.terms.limitationOfRemediesDesc" />
          </dd>
          {/* FINANCIAL TERMS */}
          <dt>
            <Text id="financial-terms" variant="heading" tKey="base.terms.financialTerms" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.fees" />
            <Text variant="content" tKey="base.terms.feesDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.paymentTerms" />
            <Text variant="content" tKey="base.terms.paymentTermsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.systemProvision" />
            <Text variant="content" tKey="base.terms.systemProvisionDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.taxes" />
            <Text variant="content" tKey="base.terms.taxesDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.pricingChanges" />
            <Text variant="content" tKey="base.terms.pricingChangesDesc" />
          </dd>
          {/* TERMS AND TERMINATION */}
          <dt>
            <Text id="terms-and-termination" variant="heading" tKey="base.terms.termAndTermination" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.term" />
            <Text variant="content" tKey="base.terms.termDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.terminationforCause" />
            <Text variant="content" tKey="base.terms.terminationforCauseDesc" />
          </dd>
          <div tw="ml-8">
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                I
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.tfcNestOneDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                II
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.tfcNestTwoDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                III
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.tfcNestThreeDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                IV
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.tfcNestFourDesc" />
            </dd>
          </div>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.obligationsUponTermination" />
            <Text variant="content" tKey="base.terms.obligationsUponTerminationDesc" />
          </dd>
          <div tw="ml-8">
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                I
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.outNestOneDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                II
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.outNestTwoDesc" />
            </dd>
            <dd tw="my-1.5">
              <Badge variant="info" tw="w-6">
                III
              </Badge>
              <Text variant="content" tw="ml-2" tKey="base.terms.outNestThreeDesc" />
            </dd>
          </div>
          {/* CONFIDENTIALITY */}
          <dt>
            <Text id="confidentiality" variant="heading" tKey="base.terms.confidentiality" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.confidentialInformation" />
            <Text variant="content" tKey="base.terms.confidentialInformationDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.exclusions" />
            <Text variant="content" tKey="base.terms.exclusionsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.useOfCi" />
            <Text variant="content" tKey="base.terms.useOfCiDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.useOfCiExceptionDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.requiredDisclosures" />
            <Text variant="content" tKey="base.terms.requiredDisclosuresDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              F
            </Badge>
            <Text variant="subHeading" tKey="base.terms.returnOfInformation" />
            <Text variant="content" tKey="base.terms.returnOfInformationDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              G
            </Badge>
            <Text variant="subHeading" tKey="base.terms.survival" />
            <Text variant="content" tKey="base.terms.survivalDesc" />
          </dd>
          {/* INDEMNIFICATION */}
          <dt>
            <Text id="indemnification" variant="heading" tKey="base.terms.indemnification" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.indemnificationByCompany" />
            <Text variant="content" tKey="base.terms.indemnificationByCompanyDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text variant="subHeading" tKey="base.terms.indemnificationByCustomer" />
            <Text variant="content" tKey="base.terms.indemnificationByCustomerDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.indemnificationProcess" />
            <Text variant="content" tKey="base.terms.indemnificationProcessDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.soleRemedy" />
            <Text variant="content" tKey="base.terms.soleRemedyDesc" />
          </dd>
          {/* SERVICE LEVEL */}
          <dt>
            <Text id="service-level" variant="heading" tKey="base.terms.serviceLevel" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.serviceLevelCommitmentsCredits" />
            <Text variant="content" tKey="base.terms.serviceLevelCommitmentsCreditsDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.creditCustomerDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.disclaimerOfWarranties" />
            <Text variant="content" tKey="base.terms.disclaimerOfWarrantiesDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.disclaimerOfConsequentialDamages" />
            <Text variant="content" tKey="base.terms.disclaimerOfConsequentialDamagesDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.limitationsOfRemediesAndLiability" />
            <Text variant="content" tKey="base.terms.limitationsOfRemediesAndLiabilityDesc" />
          </dd>
          {/* GENERAL */}
          <dt>
            <Text id="general" variant="heading" tKey="base.terms.general" />
          </dt>
          <FullLine tw="my-1.5" />
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              A
            </Badge>
            <Text variant="subHeading" tKey="base.terms.notices" />
            <Text variant="content" tKey="base.terms.noticesDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              B
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.noticesDisclaimerDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              C
            </Badge>
            <Text variant="subHeading" tKey="base.terms.forceMajeure" />
            <Text variant="content" tKey="base.terms.forceMajeureDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              D
            </Badge>
            <Text variant="subHeading" tKey="base.terms.assignment" />
            <Text variant="content" tKey="base.terms.assignmentDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              E
            </Badge>
            <Text variant="subHeading" tKey="base.terms.governingLawVenue" />
            <Text variant="content" tKey="base.terms.governingLawVenueDesc" />
          </dd>
          <dd tw="my-1.5">
            <Badge variant="info" tw="w-6">
              F
            </Badge>
            <Text tw="ml-2" variant="content" tKey="base.terms.claimThatDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              G
            </Badge>
            <Text variant="subHeading" tKey="base.terms.recoveryOfLitigationCosts" />
            <Text variant="content" tKey="base.terms.recoveryOfLitigationCostsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              H
            </Badge>
            <Text variant="subHeading" tKey="base.terms.entireAgreement" />
            <Text variant="content" tKey="base.terms.entireAgreementDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              I
            </Badge>
            <Text variant="subHeading" tKey="base.terms.amendments" />
            <Text variant="content" tKey="base.terms.amendmentsDesc" />
          </dd>
          <dd tw="my-1.5 space-x-2">
            <Badge variant="info" tw="w-6">
              J
            </Badge>
            <Text variant="subHeading" tKey="base.terms.survivalOfCertainProvisions" />
            <Text variant="content" tKey="base.terms.survivalOfCertainProvisionsDesc" />
          </dd>
        </dl>
        <div tw="w-1/5 relative">
          <div tw="flex justify-end sticky top-0">
            <Tooltip content={t("base.printPage")} data-test="printPage">
              <Button variant={["mdSquare", "ghost"]} data-test="printPage" onClick={() => print()}>
                <PrinterSVG width={36} height={36} />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
