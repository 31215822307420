import { Download } from "@phosphor-icons/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { downloadSoftwarePackageFile } from "administration/helpers";
import { ReactComponent as InfoSVG } from "assets/icons/Info.svg";
import { ReactComponent as SoftwarePackageSVG } from "assets/icons/SoftwarePackage.svg";
import { PageLayout } from "base/components";
import { Badge, Button, CommonCells, Popover, Table, TableTypes, accessors, usePagination } from "common/guideline";
import { withDefault } from "common/helpers";
import { FindAllSoftwarePackagesFilteredQuery, useFindAllSoftwarePackagesFilteredQuery } from "generated";
import { useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";
import { RowActions } from "./RowActions";
import { CreateSoftwarePackage } from "./SoftwarePackagesForm";

export type SoftwarePackage = NonNullable<
  NonNullable<NonNullable<FindAllSoftwarePackagesFilteredQuery["findAllSoftwarePackagesFiltered"]>["result"]>["0"]
> & {
  __typename: "SoftwarePackageDtoOut";
};

const getColumns: TableTypes.TranslatedColumns<SoftwarePackage> = (t) => [
  {
    id: "name",
    header: t("administration.sp.name"),
    accessorKey: "name",
  },
  {
    id: "version",
    header: t("administration.sp.version"),
    accessorKey: "version",
    cell({ getValue }) {
      return withDefault(getValue() ? <Badge>{getValue<string>()}</Badge> : undefined);
    },
  },
  {
    id: "revision",
    header: t("administration.sp.revision"),
    accessorKey: "revision",
  },
  {
    id: "fileName",
    header: t("administration.sp.file"),
    accessorKey: "fileName",
    minSize: 270,
    cell({ getValue, row }) {
      return (
        <div>
          <span tw="inline-flex items-start">
            {getValue<string>()}
            {"    "}
            <Button variant={["primary", "sm"]} tw="ml-6" data-test={`downloadFile-${row.index}`}>
              <Download
                size={18}
                weight="duotone"
                onClick={() => row.original.fileName && downloadSoftwarePackageFile(row.original.fileName)}
              />
            </Button>
          </span>
        </div>
      );
    },
  },
  {
    id: "common",
    header: t("administration.sp.release"),
    accessorFn: (v) => t(v.common === true ? "administration.sp.official" : "administration.sp.custom"),
  },
  {
    id: "createdBy",
    header: t("administration.sp.createdBy"),
    accessorFn: (v) => withDefault(v.createdBy),
  },
  {
    id: "createdDate",
    header: t("administration.sp.createdDate"),
    accessorFn: (v) => accessors.date(v.createdDate, t),
    sortingFn: "dateString",
  },
  {
    id: "description",
    header: t("administration.sp.description"),
    accessorKey: "description",
  },
  {
    id: "releaseInfo",
    header: t("administration.sp.releaseInfo"),
    enableSorting: false,
    cell({ row }) {
      const { releaseInfo } = row.original;

      return releaseInfo ? (
        <Popover
          auto
          overflowContainer
          placement="left-start"
          possiblePlacements={["left-center", "left-end", "left-start"]}
          content={() => <pre tw="max-w-[70vw] overflow-auto text-xs p-2">{releaseInfo}</pre>}
        >
          <InfoSVG width={24} height={24} />
        </Popover>
      ) : (
        "-"
      );
    },
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const TABLE_NAME = "softwarePackages";

const SoftwarePackagesTable = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<string>();
  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useFindAllSoftwarePackagesFilteredQuery({
    variables: {
      searchRequest: {
        page: pageIndex,
        size: pageSize,
        sort: orderColumn
          ? [
              {
                fieldName: orderColumn,
                order: order,
              },
            ]
          : undefined,
      },
    },
  });
  const values = (data?.findAllSoftwarePackagesFiltered?.result || []) as SoftwarePackage[];
  const totalCount = data?.findAllSoftwarePackagesFiltered?.fullSize || 0;

  return (
    <Table<SoftwarePackage>
      tableName={TABLE_NAME}
      columns={columns}
      data={values}
      loading={loading}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      initialLoading={previousData === undefined}
      error={error}
      sorting={sorting}
      onSorting={setSorting}
    />
  );
};

export const SoftwarePackages = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.sp.title"
    subtitle="administration.sp.desc"
    Icon={SoftwarePackageSVG}
    actions={<CreateSoftwarePackage />}
  >
    <SoftwarePackagesTable />
  </PageLayout>
);
