import { CellContext } from "@tanstack/react-table";
import tw from "twin.macro";
import "styled-components/macro";

import { CheckboxRaw } from "common/form/renderFields";

export const SelectRowCell = ({ row }: CellContext<any, any>) =>
  row.getCanMultiSelect() ? (
    <span tw="flex items-center cursor-pointer">
      <CheckboxRaw
        name={`selectRow-${row.index}`}
        type="checkbox"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    </span>
  ) : null;
