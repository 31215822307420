import { LDSingleKindContext } from "launchdarkly-js-client-sdk";
import { AsyncProviderConfig } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

import { useAuth } from "auth/hooks";
import { CONFIG } from "common/config";
import { useTenant } from "tenant/context";

import { LDProvider as LDProviderCustom } from "./CustomProvider";

const config: AsyncProviderConfig = {
  clientSideID: CONFIG.darklyId,
  reactOptions: { useCamelCaseFlagKeys: true, sendEventsOnFlagRead: true },
} as const;

export const FeatureFlagsProvider = ({ children }) => {
  const tenantId = useTenant((s) => s.tenantId);
  const context = useAuth<LDSingleKindContext>(
    useCallback((s) => ({ kind: "user", key: s.user?.sub ?? "", name: tenantId } as LDSingleKindContext), [tenantId]),
    (a, b) => a.key === b.key && a.name === b.name,
  );

  return (
    <LDProviderCustom
      clientSideID={config.clientSideID}
      reactOptions={config.reactOptions}
      context={context.key ? context : undefined}
    >
      {children}
    </LDProviderCustom>
  );
};
