import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "administration.title",
    route: "ADMINISTRATION",
  },
  {
    text: "administration.machines",
    route: "MACHINE_LIST",
  },
  {
    text: "machine.licensing.editMachineLicenseContent",
    route: "MACHINE_LICENSE_CONTENT_EDIT",
  },
];
