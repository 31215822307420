import { GRAPHQL_MAX_INT } from "graphql";

import { CustomRenderFields } from "common/form/renderFields";
import { CashInventoryReportCriteriaDtoIn } from "generated";

import { getCommonFields } from "./parametersCommon";

export const cashInventoryReportDefaultParams: Partial<CashInventoryReportCriteriaDtoIn> = {
  reportName: "CashInventoryReport",
  skip: 0,
  // max value to be sure all reports are taken
  limit: GRAPHQL_MAX_INT,
};

export const cashInventoryReportFields: CustomRenderFields[] = [...getCommonFields()];
