import { useMemo, useRef, useState } from "react";

import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { useFieldData } from "common/form";
import { makeSorter } from "common/helpers";
import { useDebouncedValue, useMappedQuery } from "common/hooks";
import {
  FindAllLocationGroupsFilteredCountsQuery,
  LocationGroupFiltersDto,
  useFindAllLocationGroupsFilteredCountsQuery,
} from "generated";

type SiteGroupOptions = {
  label: string;
  value: string;
};

type FilteredMachineGroupResult = NonNullable<
  NonNullable<FindAllLocationGroupsFilteredCountsQuery["findAllLocationGroupsFiltered"]>["result"]
>[0] & {
  __typename: "SmartLocationGroupDtoOut";
};

export type UseCustomSiteGroupsFilters = () => LocationGroupFiltersDto;

const LIST_SIZE = 10;

const getUseConfig = (useFilters: UseCustomSiteGroupsFilters): SelectLiveFieldUseConfig => {
  return function useConfig(previous) {
    const value = useFieldData<string | undefined>(previous.name, "values");
    const listSizeRef = useRef(LIST_SIZE);
    const [filterText, setFilterText] = useState("");
    const debouncedFilterText = useDebouncedValue(filterText);

    const [pickedOptions = [], { loading: firstLoading }] = useMappedQuery(
      (data, previousData) => {
        const result = ((data ?? previousData)?.findAllLocationGroupsFiltered?.result ??
          []) as FilteredMachineGroupResult[];

        return result?.flatMap((p) =>
          p ? [{ label: p.name ?? "", value: p.nodeId ?? "" }] : [],
        ) as SiteGroupOptions[];
      },
      useFindAllLocationGroupsFilteredCountsQuery({
        skip: !value,
        variables: {
          locationGroupFilters: {
            nodeIds: value ? [value] : [],
          },
          searchRequest: {
            page: 0,
            size: 1,
            sort: [{ fieldName: "name", order: "ASC" }],
          },
        },
      }),
    );

    const filters = useFilters();

    const [groupsData = previous, { loading, refetch }] = useMappedQuery(
      (data, previousData) => {
        const response = data ?? previousData;
        const result = (response?.findAllLocationGroupsFiltered?.result ?? []) as FilteredMachineGroupResult[];
        const fullSize = response?.findAllLocationGroupsFiltered?.fullSize ?? 0;
        const options: SiteGroupOptions[] =
          result.flatMap((p) => (p ? [{ label: p.name || "", value: p.nodeId || "" }] : [])) ?? [];

        return {
          ...previous,
          isLoading: false,
          options,
          onMenuScrollToBottom: () => {
            if (options.length >= fullSize) return;

            listSizeRef.current += LIST_SIZE;
            refetch({ searchRequest: { page: 0, size: listSizeRef.current } });
          },
        };
      },
      useFindAllLocationGroupsFilteredCountsQuery({
        variables: {
          locationGroupFilters: {
            ...filters,
            name: debouncedFilterText || undefined,
          },
          searchRequest: { page: 0, size: listSizeRef.current, sort: [{ fieldName: "name", order: "ASC" }] },
        },
      }),
    );

    const options = useMemo(
      () => [
        ...pickedOptions,
        ...(groupsData?.options?.filter((o) => !pickedOptions.find((p) => p.value === (o as any).value)) ?? []).sort(
          makeSorter("label"),
        ),
      ],
      [groupsData, pickedOptions],
    );

    return [{ ...groupsData, options, onInputChange: setFilterText, isLoading: firstLoading || loading }, true];
  };
};

export const getSiteGroupsFilterFieldWithFilters = (
  value: SelectLiveFieldValueArg,
  useFilters: UseCustomSiteGroupsFilters,
) =>
  getSelectLiveField(
    {
      label: "administration.lg.title",
      isLoading: true,
      isMulti: false,
      ...value,
    },
    getUseConfig(useFilters),
  );

export const getSiteGroupsFilterField = (value: SelectLiveFieldValueArg) =>
  getSiteGroupsFilterFieldWithFilters(value, () => ({}));
