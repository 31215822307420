import { TKeys } from "i18next";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { Container, FullLine, Text, Tooltip } from "common/guideline";

type Props = React.PropsWithChildren<{
  title: TKeys;
  tValue?: any;
  value?: number;
  text?: TKeys;
  currency?: any;
  inline?: boolean;
  isSm?: boolean;
  className?: string;
  staticColor?: boolean;
  // props for Intl object - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  intlStyle?: string;
  minimumFractionDigits?: number;
}>;

export const ValueWidget: React.FC<Props> = ({
  currency,
  value = 0,
  text,
  title,
  tValue,
  inline,
  isSm,
  className,
  staticColor = inline,
  intlStyle,
  minimumFractionDigits,
  children,
}) => (
  <Container variant={inline ? undefined : "card"} className={className}>
    <Text variant={isSm ? "description" : "heading"} tKey={title} tValue={tValue} />
    <FullLine />
    {text ? (
      <Text variant="md" tKey={text} />
    ) : (
      <Text
        variant="md"
        tKey="numberFormat"
        tValue={{ value, currency, minimumFractionDigits, style: intlStyle }}
        css={css`
          ${isSm && tw`text-lg`}
          ${staticColor ? tw`text-gray-8` : value > 0 ? tw`text-success-default` : value < 0 && tw`text-error-default`}
        `}
      />
    )}
    {children}
  </Container>
);

export const TooltipValueWitdget: React.FC<Props & { tooltipText: TKeys }> = ({ tooltipText, ...props }) => (
  <Tooltip
    content={
      <div tw="[max-width: 200]">
        <Text tKey={tooltipText} />
      </div>
    }
    data-test={props.title}
  >
    <ValueWidget {...props} tw="h-full" />
  </Tooltip>
);
