// those are types writted by hand for alerts queries that uses `FilterChildFragment` fragment
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;

import {
  AbstractFilterDto,
  BiNodeFilterType,
  Exact,
  FilterType,
  Maybe,
  MessageType,
  OperationType,
  Scalars,
  TriggerType,
} from "./index";

export type GetAllRulesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRulesQuery = {
  __typename?: "Query";
  getAllRules?: Array<{
    __typename?: "Rules";
    name?: string | null;
    nodeId?: string | null;
    recipients?: Array<{
      __typename?: "RecipientData";
      nodeId?: string | null;
      contact?: string | null;
      triggerType?: TriggerType | null;
    } | null> | null;
    filters?: FilterChildFragmentFragmentWithChild;
  }>;
};

type FilterChildFragment_BiNodeFilter_Fragment = {
  __typename?: "BiNodeFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  biNodeFilterType?: BiNodeFilterType | null;
  otherChild?:
    | { __typename?: "BiNodeFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "CashAmountFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        cashAmountIn?: Array<{
          __typename?: "CashAmountIn";
          decimals?: number | null;
          currency?: string | null;
          nodeId?: string | null;
          amount?: number | null;
        } | null> | null;
      }
    | {
        __typename?: "CountOperationFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        countValue?: number | null;
      }
    | { __typename?: "DefaultFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "DurationFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        duration?: any | null;
      }
    | {
        __typename?: "MessageFieldFiltering";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        canFilterCustomValueInFilter?: boolean | null;
        filter?: Array<string | null> | null;
      }
    | {
        __typename?: "MessageTypeFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        messageType?: MessageType | null;
      }
    | { __typename?: "NoParamFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "RatioFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        ratio?: number | null;
      }
    | {
        __typename?: "TimeFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        fromTime?: any | null;
        toTime?: any | null;
      }
    | { __typename?: "WebhookFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | null;
};

type FilterChildFragment_CashAmountFilter_Fragment = {
  __typename?: "CashAmountFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  cashAmountIn?: Array<{
    __typename?: "CashAmountIn";
    decimals?: number | null;
    currency?: string | null;
    nodeId?: string | null;
    amount?: number | null;
  } | null> | null;
};

type FilterChildFragment_CountOperationFilter_Fragment = {
  __typename?: "CountOperationFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  countValue?: number | null;
};

type FilterChildFragment_DefaultFilter_Fragment = {
  __typename?: "DefaultFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
};

type FilterChildFragment_DurationFilter_Fragment = {
  __typename?: "DurationFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  duration?: any | null;
};

type FilterChildFragment_MessageFieldFiltering_Fragment = {
  __typename?: "MessageFieldFiltering";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  canFilterCustomValueInFilter?: boolean | null;
  filter?: Array<string | null> | null;
};

type FilterChildFragment_MessageTypeFilter_Fragment = {
  __typename?: "MessageTypeFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  messageType?: MessageType | null;
};

type FilterChildFragment_NoParamFilter_Fragment = {
  __typename?: "NoParamFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
};

type FilterChildFragment_RatioFilter_Fragment = {
  __typename?: "RatioFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  ratio?: number | null;
};

type FilterChildFragment_TimeFilter_Fragment = {
  __typename?: "TimeFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
  fromTime?: any | null;
  toTime?: any | null;
};

type FilterChildFragment_WebhookFilter_Fragment = {
  __typename?: "WebhookFilter";
  filterType?: FilterType | null;
  operationType?: OperationType | null;
};

export type FilterChildFragmentFragment =
  | FilterChildFragment_BiNodeFilter_Fragment
  | FilterChildFragment_CashAmountFilter_Fragment
  | FilterChildFragment_CountOperationFilter_Fragment
  | FilterChildFragment_DefaultFilter_Fragment
  | FilterChildFragment_DurationFilter_Fragment
  | FilterChildFragment_MessageFieldFiltering_Fragment
  | FilterChildFragment_MessageTypeFilter_Fragment
  | FilterChildFragment_NoParamFilter_Fragment
  | FilterChildFragment_RatioFilter_Fragment
  | FilterChildFragment_TimeFilter_Fragment
  | FilterChildFragment_WebhookFilter_Fragment;

type FilterChildFragmentFragmentWithChild = FilterChildFragmentFragment & {
  child: Maybe<FilterChildFragmentFragment> & {
    child: Maybe<FilterChildFragmentFragment> & { child: Maybe<FilterChildFragmentFragment> };
  };
};

type BiChildFragment_BiNodeFilter_Fragment = {
  __typename?: "BiNodeFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BiChildFragment_CashAmountFilter_Fragment = {
  __typename?: "CashAmountFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  cashAmountIn?: Array<{
    __typename?: "CashAmountIn";
    decimals?: number | null;
    currency?: string | null;
    nodeId?: string | null;
    amount?: number | null;
  } | null> | null;
};

type BiChildFragment_CountOperationFilter_Fragment = {
  __typename?: "CountOperationFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  countValue?: number | null;
};

type BiChildFragment_DefaultFilter_Fragment = {
  __typename?: "DefaultFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BiChildFragment_DurationFilter_Fragment = {
  __typename?: "DurationFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  duration?: any | null;
};

type BiChildFragment_MessageFieldFiltering_Fragment = {
  __typename?: "MessageFieldFiltering";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  canFilterCustomValueInFilter?: boolean | null;
  filter?: Array<string | null> | null;
};

type BiChildFragment_MessageTypeFilter_Fragment = {
  __typename?: "MessageTypeFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  messageType?: MessageType | null;
};

type BiChildFragment_NoParamFilter_Fragment = {
  __typename?: "NoParamFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BiChildFragment_RatioFilter_Fragment = {
  __typename?: "RatioFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  ratio?: number | null;
};

type BiChildFragment_TimeFilter_Fragment = {
  __typename?: "TimeFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  fromTime?: any | null;
  toTime?: any | null;
};

type BiChildFragment_WebhookFilter_Fragment = {
  __typename?: "WebhookFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

export type BiChildFragmentFragment =
  | BiChildFragment_BiNodeFilter_Fragment
  | BiChildFragment_CashAmountFilter_Fragment
  | BiChildFragment_CountOperationFilter_Fragment
  | BiChildFragment_DefaultFilter_Fragment
  | BiChildFragment_DurationFilter_Fragment
  | BiChildFragment_MessageFieldFiltering_Fragment
  | BiChildFragment_MessageTypeFilter_Fragment
  | BiChildFragment_NoParamFilter_Fragment
  | BiChildFragment_RatioFilter_Fragment
  | BiChildFragment_TimeFilter_Fragment
  | BiChildFragment_WebhookFilter_Fragment;

export type BiNodeFragmentFragment = {
  __typename?: "BiNodeFilter";
  biNodeFilterType?: BiNodeFilterType | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  otherChild?:
    | { __typename?: "BiNodeFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "CashAmountFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        cashAmountIn?: Array<{
          __typename?: "CashAmountIn";
          decimals?: number | null;
          currency?: string | null;
          nodeId?: string | null;
          amount?: number | null;
        } | null> | null;
      }
    | {
        __typename?: "CountOperationFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        countValue?: number | null;
      }
    | { __typename?: "DefaultFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "DurationFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        duration?: any | null;
      }
    | {
        __typename?: "MessageFieldFiltering";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        canFilterCustomValueInFilter?: boolean | null;
        filter?: Array<string | null> | null;
      }
    | {
        __typename?: "MessageTypeFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        messageType?: MessageType | null;
      }
    | { __typename?: "NoParamFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | {
        __typename?: "RatioFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        ratio?: number | null;
      }
    | {
        __typename?: "TimeFilter";
        operationType?: OperationType | null;
        filterType?: FilterType | null;
        fromTime?: any | null;
        toTime?: any | null;
      }
    | { __typename?: "WebhookFilter"; operationType?: OperationType | null; filterType?: FilterType | null }
    | null;
};

type BaseSupportFragment_BiNodeFilter_Fragment = { __typename?: "BiNodeFilter" };

type BaseSupportFragment_CashAmountFilter_Fragment = {
  __typename?: "CashAmountFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  cashAmountIn?: Array<{
    __typename?: "CashAmountIn";
    decimals?: number | null;
    currency?: string | null;
    nodeId?: string | null;
    amount?: number | null;
  } | null> | null;
};

type BaseSupportFragment_CountOperationFilter_Fragment = {
  __typename?: "CountOperationFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  countValue?: number | null;
};

type BaseSupportFragment_DefaultFilter_Fragment = {
  __typename?: "DefaultFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BaseSupportFragment_DurationFilter_Fragment = {
  __typename?: "DurationFilter";
  duration?: any | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BaseSupportFragment_MessageFieldFiltering_Fragment = {
  __typename?: "MessageFieldFiltering";
  canFilterCustomValueInFilter?: boolean | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  filter?: Array<string | null> | null;
};

type BaseSupportFragment_MessageTypeFilter_Fragment = {
  __typename?: "MessageTypeFilter";
  messageType?: MessageType | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BaseSupportFragment_NoParamFilter_Fragment = {
  __typename?: "NoParamFilter";
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BaseSupportFragment_RatioFilter_Fragment = {
  __typename?: "RatioFilter";
  ratio?: number | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
};

type BaseSupportFragment_TimeFilter_Fragment = {
  __typename?: "TimeFilter";
  fromTime?: any | null;
  operationType?: OperationType | null;
  filterType?: FilterType | null;
  toTime?: any | null;
};

type BaseSupportFragment_WebhookFilter_Fragment = { __typename?: "WebhookFilter" };

export type BaseSupportFragmentFragment =
  | BaseSupportFragment_BiNodeFilter_Fragment
  | BaseSupportFragment_CashAmountFilter_Fragment
  | BaseSupportFragment_CountOperationFilter_Fragment
  | BaseSupportFragment_DefaultFilter_Fragment
  | BaseSupportFragment_DurationFilter_Fragment
  | BaseSupportFragment_MessageFieldFiltering_Fragment
  | BaseSupportFragment_MessageTypeFilter_Fragment
  | BaseSupportFragment_NoParamFilter_Fragment
  | BaseSupportFragment_RatioFilter_Fragment
  | BaseSupportFragment_TimeFilter_Fragment
  | BaseSupportFragment_WebhookFilter_Fragment;

export type GetRulesByNodeIdQueryVariables = Exact<{
  nodeId: Scalars["String"];
}>;

export type GetRulesByNodeIdQuery = {
  __typename?: "Query";
  getRulesByNodeId?: {
    __typename?: "Rules";
    name?: string | null;
    nodeId?: string | null;
    recipients?: Array<{
      __typename?: "RecipientData";
      nodeId?: string | null;
      contact?: string | null;
      triggerType?: TriggerType | null;
    } | null> | null;
    filters?: FilterChildFragmentFragmentWithChild;
  };
};

export type GetSupportedFiltersQueryVariables = Exact<{
  input: AbstractFilterDto;
}>;

export type GetSupportedFiltersQuery = {
  __typename?: "Query";
  getSupportedFilters?: Array<Array<FilterChildFragmentFragmentWithChild>>;
};

export const BaseSupportFragmentFragmentDoc = gql`
  fragment BaseSupportFragment on AbstractFilter {
    ... on DefaultFilter {
      operationType
      filterType
    }
    ... on MessageTypeFilter {
      messageType
      operationType
      filterType
    }
    ... on MessageFieldFiltering {
      canFilterCustomValueInFilter
      operationType
      filterType
      filter
    }
    ... on TimeFilter {
      fromTime
      operationType
      filterType
      toTime
    }
    ... on DurationFilter {
      duration
      operationType
      filterType
    }
    ... on RatioFilter {
      ratio
      operationType
      filterType
    }
    ... on CountOperationFilter {
      operationType
      filterType
      countValue
    }
    ... on CashAmountFilter {
      operationType
      filterType
      cashAmountIn {
        decimals
        currency
        nodeId
        amount
      }
    }
    ... on NoParamFilter {
      operationType
      filterType
    }
  }
`;
export const BiChildFragmentFragmentDoc = gql`
  fragment BiChildFragment on AbstractFilter {
    operationType
    filterType
    ...BaseSupportFragment
  }
  ${BaseSupportFragmentFragmentDoc}
`;
export const BiNodeFragmentFragmentDoc = gql`
  fragment BiNodeFragment on BiNodeFilter {
    biNodeFilterType
    operationType
    filterType
    otherChild {
      ...BiChildFragment
    }
  }
  ${BiChildFragmentFragmentDoc}
`;
export const FilterChildFragmentFragmentDoc = gql`
  fragment FilterChildFragment on AbstractFilter {
    filterType
    operationType
    ...BaseSupportFragment
    ...BiNodeFragment
  }
  ${BaseSupportFragmentFragmentDoc}
  ${BiNodeFragmentFragmentDoc}
`;

export const GetAllRulesDocument = gql`
  query getAllRules {
    getAllRules {
      name
      nodeId
      recipients {
        nodeId
        contact
        triggerType
      }
      filters {
        ...FilterChildFragment
        child {
          ...FilterChildFragment
          child {
            ...FilterChildFragment
            child {
              ...FilterChildFragment
            }
          }
        }
      }
    }
  }
  ${FilterChildFragmentFragmentDoc}
`;

/**
 * __useGetAllRulesQuery__
 *
 * To run a query within a React component, call `useGetAllRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRulesQuery, GetAllRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRulesQuery, GetAllRulesQueryVariables>(GetAllRulesDocument, options);
}
export function useGetAllRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRulesQuery, GetAllRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRulesQuery, GetAllRulesQueryVariables>(GetAllRulesDocument, options);
}
export type GetAllRulesQueryHookResult = ReturnType<typeof useGetAllRulesQuery>;
export type GetAllRulesLazyQueryHookResult = ReturnType<typeof useGetAllRulesLazyQuery>;
export type GetAllRulesQueryResult = Apollo.QueryResult<GetAllRulesQuery, GetAllRulesQueryVariables>;
export function refetchGetAllRulesQuery(variables?: GetAllRulesQueryVariables) {
  return { query: GetAllRulesDocument, variables: variables };
}
export const GetRulesByNodeIdDocument = gql`
  query getRulesByNodeId($nodeId: String!) {
    getRulesByNodeId(nodeId: $nodeId) {
      name
      nodeId
      recipients {
        nodeId
        contact
        triggerType
      }
      filters {
        ...FilterChildFragment
        child {
          ...FilterChildFragment
          child {
            ...FilterChildFragment
            child {
              ...FilterChildFragment
            }
          }
        }
      }
    }
  }
  ${FilterChildFragmentFragmentDoc}
`;

/**
 * __useGetRulesByNodeIdQuery__
 *
 * To run a query within a React component, call `useGetRulesByNodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRulesByNodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRulesByNodeIdQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetRulesByNodeIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRulesByNodeIdQuery, GetRulesByNodeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRulesByNodeIdQuery, GetRulesByNodeIdQueryVariables>(GetRulesByNodeIdDocument, options);
}
export function useGetRulesByNodeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRulesByNodeIdQuery, GetRulesByNodeIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRulesByNodeIdQuery, GetRulesByNodeIdQueryVariables>(GetRulesByNodeIdDocument, options);
}
export type GetRulesByNodeIdQueryHookResult = ReturnType<typeof useGetRulesByNodeIdQuery>;
export type GetRulesByNodeIdLazyQueryHookResult = ReturnType<typeof useGetRulesByNodeIdLazyQuery>;
export type GetRulesByNodeIdQueryResult = Apollo.QueryResult<GetRulesByNodeIdQuery, GetRulesByNodeIdQueryVariables>;
export function refetchGetRulesByNodeIdQuery(variables: GetRulesByNodeIdQueryVariables) {
  return { query: GetRulesByNodeIdDocument, variables: variables };
}
export const GetSupportedFiltersDocument = gql`
  query getSupportedFilters($input: AbstractFilterDto!) {
    getSupportedFilters(input: $input) {
      ...FilterChildFragment
      child {
        ...FilterChildFragment
        child {
          ...FilterChildFragment
          child {
            ...FilterChildFragment
          }
        }
      }
    }
  }
  ${FilterChildFragmentFragmentDoc}
`;

/**
 * __useGetSupportedFiltersQuery__
 *
 * To run a query within a React component, call `useGetSupportedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedFiltersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSupportedFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<GetSupportedFiltersQuery, GetSupportedFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSupportedFiltersQuery, GetSupportedFiltersQueryVariables>(
    GetSupportedFiltersDocument,
    options,
  );
}
export function useGetSupportedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSupportedFiltersQuery, GetSupportedFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSupportedFiltersQuery, GetSupportedFiltersQueryVariables>(
    GetSupportedFiltersDocument,
    options,
  );
}
export type GetSupportedFiltersQueryHookResult = ReturnType<typeof useGetSupportedFiltersQuery>;
export type GetSupportedFiltersLazyQueryHookResult = ReturnType<typeof useGetSupportedFiltersLazyQuery>;
export type GetSupportedFiltersQueryResult = Apollo.QueryResult<
  GetSupportedFiltersQuery,
  GetSupportedFiltersQueryVariables
>;
export function refetchGetSupportedFiltersQuery(variables: GetSupportedFiltersQueryVariables) {
  return { query: GetSupportedFiltersDocument, variables: variables };
}
