import tw from "twin.macro";
import "styled-components/macro";

import { EntityDelete } from "base/components";
import { Text, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllOtaAuthorizationsDocument, useDeactivateOtaAuthorizationMutation } from "generated";

type Props = {
  nodeId: string;
  onClose?: () => void;
};

export const DeleteOTA: React.FC<Props> = ({ nodeId, onClose }) => {
  const [deactivateOta, { loading }] = useDeactivateOtaAuthorizationMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "ota.deactivateSuccess" : "ota.deactivateError", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deactivateOta({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => {
          evictQuery(FindAllOtaAuthorizationsDocument, cache);
        },
      });
      const success = Boolean(result.data?.deactivateOtaAuthorization);
      if (success) onClose?.();
      showToast(success);
    } catch (error) {
      showToast(false);
    }
  };

  return (
    <EntityDelete actionText="ota.deactivateTitle" loading={loading} onDelete={handleSubmit} onClose={onClose}>
      <Text tKey="ota.deactivateDescription" />
    </EntityDelete>
  );
};
