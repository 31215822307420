import { Breadcrumb } from "common/guideline";
export const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "operations.title",
    route: "DASHBOARD",
  },
  {
    text: "operations.cashOps.title",
    route: "CASH_OPERATIONS",
  },
];
