import { DotsThree } from "@phosphor-icons/react";
import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { NamesListTooltip, PageLayout } from "base/components";
import {
  Badge,
  Button,
  CommonCells,
  Spinner,
  Table,
  TableTypes,
  Text,
  accessors,
  usePagination,
} from "common/guideline";
import { useGetAllCommandsQuery } from "generated";
import { useTranslation } from "i18n";
import { Machine } from "machine/components/MachineView";
import { CommandFetched } from "zts/types";

import { useCommandModal } from "./components/CommandForm";
import { RowActions } from "./RowActions";

const commandStatusToVariant = {
  Received: "info",
  Restarting: "info",
  Rebooting: "info",
  NotSupported: "info",
  Failure: "error",
  FailedToClaim: "error",
  Canceled: "neutral",
  Ok: "success",
  InUse: "warning",
  Timeout: "warning",
};

const commandStatusToText = {
  Received: "zts.commands.statuses.InProgress",
  Restarting: "zts.commands.statuses.Restarting",
  Rebooting: "zts.commands.statuses.Rebooting",
  NotSupported: "zts.commands.statuses.InProgress",
  Failure: "zts.commands.statuses.Failure",
  FailedToClaim: "zts.commands.statuses.FailedToClaim",
  InUse: "zts.commands.statuses.FailedToClaim",
  Canceled: "zts.commands.statuses.Canceled",
  Ok: "zts.commands.statuses.Ok",
  Timeout: "zts.commands.statuses.Timeout",
};

const commandStatusToIcon = (status: string) => {
  return ["Received", "Restarting", "Rebooting", "NotSupported"].includes(status) ? <Spinner /> : null;
};

const getColumns: TableTypes.TranslatedColumns<CommandFetched> = (t) => [
  {
    header: t("zts.commands.command"),
    accessorKey: "name",
  },
  {
    header: t("zts.commands.started"),
    accessorFn: (v) => accessors.date(v.started, t),
    maxSize: 170,
  },
  {
    header: t("zts.commands.ended"),
    accessorFn: (v) => accessors.date(v.ended, t),
    maxSize: 170,
  },
  {
    header: t("zts.commands.status"),
    accessorKey: "status",
    cell({ getValue }) {
      const value = getValue<CommandFetched["status"]>();
      return value ? (
        <span tw="flex items-center flex-row">
          <Badge variant={[commandStatusToVariant[value], "text"]}>
            <Text tKey={commandStatusToText[value]} />
            {commandStatusToIcon(value)}
          </Badge>
        </span>
      ) : (
        "-"
      );
    },
  },
  {
    header: t("zts.commands.parameters"),
    accessorKey: "parameters",
    meta: {
      hideVertical: true,
    },
    cell({ getValue }) {
      const value = getValue<CommandFetched["parameters"]>()?.types ?? [];
      return <NamesListTooltip count={value.length} names={value as string[]} Icon={DotsThree} data-test="showTypes" />;
    },
  },
  {
    header: t("zts.commands.executedBy"),
    accessorKey: "executedBy",
    size: 120,
  },
  CommonCells.getActionsCell({ cell: (c) => <RowActions row={c.row} /> }),
];

const CommandForm: React.FC<Props> = ({ machine }) => {
  const [show] = useCommandModal(machine.nodeId ?? "", machine.name ?? "");

  return (
    <Button onClick={() => show()} data-test="showCommandModal">
      <Text tKey="zts.commands.executeCommand" />
    </Button>
  );
};

const isOngoing = (status) => {
  return ["Received", "Restarting", "Rebooting", "NotSupported"].includes(status);
};

const noCommandsRunning = (rows) => {
  return !rows?.some((r?) => isOngoing(r?.status));
};

const TABLE_NAME = "commands";

const CommandsTable: React.FC<Props> = ({ machine }) => {
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const {
    previousData,
    data = previousData,
    loading,
    error,
    stopPolling,
    startPolling,
  } = useGetAllCommandsQuery({
    variables: {
      machineNodeId: machine.nodeId ?? "",
      cpPageRequest: {
        page: pageIndex,
        size: pageSize,
      },
    },

    onCompleted: (result) => {
      const doStopPolling: boolean = noCommandsRunning(result.getAllCommands);
      setTimeout(() => {
        startPolling(8000);
        if (doStopPolling === true) {
          stopPolling();
        }
      }, 200);
    },
  });
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const rows = (data?.getAllCommands || []) as CommandFetched[];

  return (
    <Table<CommandFetched>
      tableName={TABLE_NAME}
      columns={columns}
      data={rows}
      loading={loading}
      initialLoading={previousData === undefined}
      error={error}
      onPagination={setPagination}
      pageIndex={pageIndex}
      pageSize={pageSize}
    />
  );
};

type Props = {
  machine: Machine;
};

export const Commands: React.FC<Props> = ({ machine }) => (
  <PageLayout
    title="zts.commands.title"
    subtitle="zts.commands.commandHistory"
    actions={<CommandForm machine={machine} />}
  >
    <CommandsTable machine={machine} />
  </PageLayout>
);
