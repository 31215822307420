import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { EntityDelete } from "base/components";
import { ComponentModalProps, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import { FindAllCorrectionReasonsDocument, useDeleteCorrectionReasonMutation } from "generated";
import { Trans } from "i18n";

import { CorrectionReasonFetched } from "../types";

type CorrectionData = WithoutNullableKeys<Pick<CorrectionReasonFetched, "name" | "nodeId">>;

type Props = CorrectionData & ComponentModalProps;

const DeleteCorrectionReason: React.FC<Props> = ({ nodeId, name, onClose }) => {
  const [deleteCorrection, { loading }] = useDeleteCorrectionReasonMutation();

  const handleSubmit = async () => {
    const showToast = (success: boolean) =>
      useToast.actions.show(success ? "transport.corReasons.remove.success" : "transport.corReasons.remove.error", {
        variant: success ? "success" : "error",
      });

    try {
      const result = await deleteCorrection({
        variables: { nodeId },
        ignoreResults: true,
        update: (cache) => evictQuery(FindAllCorrectionReasonsDocument, cache),
      });
      const deleted = !!result.data?.deleteCorrectionReason;
      if (deleted) onClose?.();
      showToast(deleted);
    } catch (error) {
      showToast(true);
    }
  };

  return (
    <EntityDelete
      actionText="transport.corReasons.remove.title"
      loading={loading}
      onDelete={handleSubmit}
      onClose={onClose}
    >
      <Trans i18nKey="transport.corReasons.remove.desc" values={{ name }}>
        {[<b key="1" />]}
      </Trans>
    </EntityDelete>
  );
};

export const useDeleteCorrectionReasonModal = ({ nodeId, name }: CorrectionData) =>
  useModalComponent({
    Component: <DeleteCorrectionReason nodeId={nodeId} name={name} />,
  });
