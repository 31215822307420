import { useEffect, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { CONFIG } from "common/config";
import { Text } from "common/guideline";

type Versions = {
  backend: string;
  frontend: string;
};

const getVersionsInfo = (onSuccess: (data: Versions) => void) =>
  fetch(CONFIG.deployInfoPath)
    .then<Versions>((r) => r.json())
    .then(onSuccess)
    .catch(() => null);

export const DeployedVersionInfo = () => {
  const [versions, setVersions] = useState<Versions | null>();

  useEffect(() => {
    getVersionsInfo(setVersions);
  }, []);

  return (
    <Text tw="text-xs text-gray-6" withoutI18n>
      API: {versions?.backend} UI: {versions?.frontend}
    </Text>
  );
};
