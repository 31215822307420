import { useCallback } from "react";

import { useFormContext } from "./FormContext";
import { TForm } from "./types";

export type FormDataTypes =
  | "isValid"
  | "isSubmittig"
  | "isValidating"
  | "isDirty"
  | "active"
  | "submit"
  | "reset"
  | "validateForm"
  | "validateField"
  | "setField"
  | "values"
  | "errors"
  | "touched"
  | "data";

export const useFormData = <T extends FormDataTypes, K = any>(type: T): TForm<K>[T] =>
  useFormContext<K>().useStore(useCallback((state) => state[type], [type]));
