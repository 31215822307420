import { useEffect, useMemo, useState } from "react";

import { append, insert, move, prepend, remove, swap } from "common/helpers";

import { UseFieldResult, useFieldRegister } from "../field";
import { TForm, useFormContext } from "../form";
import { getGetterByPath } from "../getGetterByPath";

import { UseArrayFieldOptions, UseArrayFieldStateOptions } from "./types";
import { manipulateArray } from "./useManipulateArray";

const fieldsEqual = (p: string[], c: string[]) => p.join() === c.join();

type FieldState = Pick<UseFieldResult, "data" | "error" | "touched" | "active" | "value">;

export const getSubscriberFns = (name, activeSub, dataSub, valueSub, errorSub, touchSub) => {
  const getter = getGetterByPath(name);

  const getTouched = (touched: any) => Object.entries(touched).some(([k, v]) => k.startsWith(name) && v === true);

  const get = new Function(
    "getIn",
    "getTouched",
    `return (state) => ({
        ${dataSub ? `data: state.data.${name},` : ""}
        ${errorSub ? `error: getIn(state.errors),` : ""}
        ${touchSub ? `touched: Boolean(getIn(state.touched)),` : ""}
        ${activeSub ? `active: state.active?.startsWith(${name}),` : ""}
        ${valueSub ? `value: getIn(state.values),` : ""}
      })`,
  ) as (getIn: (v: any) => any, getTouched: any) => (state: any) => FieldState;

  const check = new Function(
    "p",
    "c",
    `return (
        ${
          [
            valueSub ? `Object.is(p.value, c.value)` : "",
            activeSub ? "p.active === c.active" : "",
            touchSub ? "p.touched === c.touched" : "",
            errorSub ? "Object.is(p.error, c.error)" : "",
            dataSub ? "Object.is(p.data, c.data)" : "",
          ]
            .filter(Boolean)
            .join(" && ") || "false"
        }
      )`,
  ) as (p: FieldState, c: FieldState) => boolean;

  return [get(getter, getTouched), check] as [(state: any) => FieldState, (p: FieldState, c: FieldState) => boolean];
};

export const useFieldArrayState = <T>(
  name: string,
  { activeSub, dataSub, valueSub, errorSub, touchSub }: UseArrayFieldStateOptions<T> = {},
) => {
  const { useStore } = useFormContext();
  const [state, setState] = useState<FieldState>({});

  useEffect(() => {
    const [get, equalityFn] = getSubscriberFns(name, activeSub, dataSub, valueSub, errorSub, touchSub);
    return useStore.subscribe(get, setState, { equalityFn });
  }, [name, useStore, activeSub, dataSub, valueSub, errorSub, touchSub]);

  return state;
};

export const useFieldArray = <T>(name: string, options?: UseArrayFieldOptions<T>) => {
  const { useStore } = useFormContext();

  useFieldRegister(name, useStore, options);

  const { getFields, ...fns } = useMemo(() => {
    const getter = getGetterByPath(name);
    const getFields = (state: TForm<any>) =>
      Array.from({ length: getter<any[]>(state.values)?.length || 0 }, (_, i) => `${name}.${i}`);

    return {
      getFields,
      swap: manipulateArray(useStore, name, swap),
      append: manipulateArray(useStore, name, append),
      prepend: manipulateArray(useStore, name, prepend),
      remove: manipulateArray(useStore, name, remove),
      insert: manipulateArray(useStore, name, insert),
      move: manipulateArray(useStore, name, move),
    };
  }, [useStore, name]);

  return {
    fields: useStore(getFields, fieldsEqual),
    ...fns,
  };
};
