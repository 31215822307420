import { SelectLiveFieldUseConfig, SelectLiveFieldValueArg, getSelectLiveField } from "base/fields";
import { makeSorter } from "common/helpers";
import { useMappedQuery } from "common/hooks";
import { useFindAllCountriesQuery } from "generated";

type CountriesOption = {
  label: string;
  value: string;
};

const useConfig: SelectLiveFieldUseConfig = (prev) => {
  const [data = prev, { loading }] = useMappedQuery((d) => {
    const options: CountriesOption[] =
      d?.findAllCountries?.flatMap((p) => (p ? [{ label: p || "", value: p || "" }] : [])).sort(makeSorter("label")) ||
      [];

    return {
      ...prev,
      isLoading: false,
      options,
    };
  }, useFindAllCountriesQuery());

  return [data, !loading];
};

export const getCountriesField = (value: SelectLiveFieldValueArg) =>
  getSelectLiveField(
    {
      label: "administration.selectCountries",
      isLoading: true,
      isMulti: true,
      ...value,
    },
    useConfig,
  );
