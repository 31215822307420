import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "keepBasic", label: "machine.licensing.keepBasic" },
  { value: "upgrade", label: "machine.licensing.upgrade" },
];

export const getLicensingOptions = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "machine.licensing.option",
    options,
    ...values,
  });
