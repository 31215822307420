import { PostProcessorModule } from "i18next";

export const postProcessor: PostProcessorModule = {
  type: "postProcessor",
  name: "postProcessor",
  process(value, key, options) {
    if (value && options?.colon) {
      return `${value}:`;
    }

    return value;
  },
};
