export const en = {
  auth: {
    signIn: "Sign in",
    resetPassword: "Reset password",
    forgot: "Forgot",
    username: "Username",
    password: "Password",
    or: "or",
    err: {
      invalid_grant: "Invalid user name or password",
    },
  },
};
