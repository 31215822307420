export type ArrayHelperArgs<T, ARGS extends any[] = []> = [arr: T[], ...args: ARGS];

export const append: <T>(...a: ArrayHelperArgs<T, [element: T]>) => T[] = (arr, element) => [...arr, element];

export const prepend: <T>(...a: ArrayHelperArgs<T, [element: T]>) => T[] = (arr, element) => [element, ...arr];

export const remove: <T>(...a: ArrayHelperArgs<T, [index: number]>) => T[] = (arr, index) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

export const insert: <T>(...a: ArrayHelperArgs<T, [index: number, newItem: T]>) => T[] = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(1),
];

export const move: <T>(...a: ArrayHelperArgs<T, [from: number, to: number]>) => T[] = (array, from, to) => {
  const arr = [...array];
  const start = to >= 0 ? to : to + 1;
  arr.splice(start, 0, ...arr.splice(from, 1));
  return arr;
};

export const swap: <T>(...a: ArrayHelperArgs<T, [indexA: number, indexB: number]>) => T[] = (arr, indexA, indexB) => {
  const temp = arr[indexA];

  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};
