import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as MachineUserTransactionsSVG } from "assets/icons/MachineUserTransactions.svg";
import { PageLayout } from "base/components";
import { Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const MachineUserManagement = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="mum.title" subtitle="mum.desc">
    <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
      <Link route="MACHINE_USER_TRANSACTIONS">
        <Box>
          <BoxHeading>
            <Text tKey="mum.transactions.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineUserTransactionsSVG />
          </BoxContent>
        </Box>
      </Link>
    </div>
  </PageLayout>
);
