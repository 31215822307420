import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CashInventorySVG } from "assets/icons/CashInventory.svg";
import { PageLayout, useMappedSiteGroupContext, useTimeZoneFilter } from "base/components";
import { client } from "client";
import {
  CommonCells,
  CustomGetCsvFn,
  DateRangeInfo,
  SubRows,
  Table,
  TableDownload,
  TableTypes,
  accessors,
  csvLimitExceeded,
  tableDownloadTitles,
  useDateFilter,
  useDateZoned,
  usePagination,
} from "common/guideline";
import { numberWithDecimalCount } from "common/helpers";
import {
  CashInventoryReportCriteriaDtoIn,
  CashInventoryReportOrderColumn,
  FindRmValuesForRmBoxQuery,
  GenerateCashInventoryReportDocument,
  GenerateCashInventoryReportQuery,
  GenerateCashInventoryReportQueryVariables,
  useFindRmValuesForRmBoxQuery,
  useGenerateCashInventoryReportQuery,
} from "generated";
import { FilterBox } from "report/components";
import { getTotalAmountsRows } from "report/helpers";
import { SortByColumnsData, useSorting } from "report/hooks";

import { breadcrumbs } from "./breadcrumbs";
import { getCashInventoryCsvData } from "./getCashInventoryCsvData";
import { CashInventoryType } from "./types";

type RmBox = Partial<NonNullable<NonNullable<FindRmValuesForRmBoxQuery["findRmValuesForRmBox"]>[0]>>;

type ReportSubRow = Omit<RmBox, "values"> &
  Omit<NonNullable<NonNullable<RmBox["values"]>[0]>, "__typename"> & {
    actualDenomination: number;
    actualTotal: number;
  };

const getColumns: TableTypes.TranslatedColumns<
  CashInventoryType,
  SortByColumnsData<CashInventoryReportOrderColumn>,
  ReportSubRow
> = (t) => [
  CommonCells.expander,
  {
    header: t("cashInv.machine"),
    id: "MACHINE",
    accessorKey: "machineName",
    enableHiding: false,
  },
  {
    header: t("cashInv.site"),
    id: "SITE",
    accessorKey: "locationName",
    enableSorting: false,
  },
  {
    header: t("cashInv.machineType"),
    id: "MACHINE_TYPE",
    accessorKey: "machineTypeName",
  },
  {
    header: t("cashInv.container"),
    id: "CONTAINER",
    enableSorting: false,
    subAccessor: (v) => v.name,
  },
  {
    header: t("cashInv.denomination"),
    id: "DENOMINATION",
    enableSorting: false,
    subAccessor: (v) => accessors.number(v.actualDenomination || 0, t),
    sortingFn: "alphanumeric",
    meta: {
      csv: {
        subAccessor: (v) => accessors.numberCsv(String((v as ReportSubRow).actualDenomination)),
      },
    },
  },
  {
    header: t("cashInv.type"),
    id: "TYPE",
    accessorKey: "type",
    enableSorting: false,
    subAccessor: (v) => v.type,
  },
];

const SubRowsWrapper: TableTypes.SubRowsComponent<CashInventoryType> = ({ row }) => {
  const { data, loading } = useFindRmValuesForRmBoxQuery({
    skip: !(row.original as any).boxNodeIds?.length,
    variables: { nodeIds: (row.original.boxNodeIds as string[]) || [] },
  });

  const subRowsData = (data?.findRmValuesForRmBox?.flatMap((d) =>
    d?.values
      // we dont need empty denominations
      ?.filter((v) => !!v?.count)
      ?.map((v) => ({
        ...d,
        ...v,
        actualDenomination: numberWithDecimalCount.merge(v?.denomination || 0, v?.decimals || 0),
        actualTotal: numberWithDecimalCount.merge(v?.total || 0, v?.decimals || 0),
      })),
  ) || []) as ReportSubRow[];

  return <SubRows<CashInventoryType, ReportSubRow> row={row} data={subRowsData} loading={loading} />;
};

const toDate = new Date();
const initialDate = new Date();

const TABLE_NAME = "cashInventory";
const csvLimit = 30_000;

export const CashInventory = () => {
  const { t, i18n } = useTranslation();
  const [date, DateFilter] = useDateFilter({
    label: "common.rDate",
    dateFormat: "datetime",
    toDate,
    initialDate,
  });
  const [zoneId, TimeZone] = useTimeZoneFilter();
  const zonedDate = useDateZoned(date, zoneId);
  const [{ pageIndex, pageSize }, setPagination] = usePagination(TABLE_NAME);
  const [{ order, orderColumn }, sorting, setSorting] = useSorting<CashInventoryReportOrderColumn>();
  const [{ machine, location, siteGroup }] = useMappedSiteGroupContext(true);
  const input: CashInventoryReportCriteriaDtoIn = {
    reportName: "CashInventoryReport",
    referenceDate: zonedDate,
    limit: pageSize,
    skip: pageIndex * pageSize,
    orderColumn,
    order,
    locationNodeIds: location ? location : siteGroup,
    machineNodeIds: machine,
    zoneId,
  };
  const {
    previousData,
    data = previousData,
    error,
    loading,
  } = useGenerateCashInventoryReportQuery({ variables: { input } });

  const rows = data?.generateCashInventoryReport?.rows as CashInventoryType[] | undefined;
  const fullSize = data?.generateCashInventoryReport?.fullSize || 0;

  const columns = useMemo(
    () =>
      !rows
        ? []
        : [
            ...getColumns(t, i18n.language),
            ...getTotalAmountsRows<CashInventoryType, ReportSubRow>(rows, t, {
              countColumn: { subAccessor: (d, curr) => (d.currency === curr ? d.count : "") },
              amountColumn: {
                subAccessor: (d, curr) => (d.currency === curr ? t("numberFormat", { value: d.actualTotal }) : ""),
              },
            }),
          ],
    [t, i18n.language, rows],
  );

  const getCsv: CustomGetCsvFn = async (getOptions, currentPage) => {
    if (currentPage) {
      const data = await getCashInventoryCsvData((rows ?? []) as CashInventoryType[]);
      return getOptions({ data });
    }

    if (csvLimitExceeded(fullSize, csvLimit, t)) return false;

    const { data: { generateCashInventoryReport } = {} } = await client.query<
      GenerateCashInventoryReportQuery,
      GenerateCashInventoryReportQueryVariables
    >({
      query: GenerateCashInventoryReportDocument,
      variables: {
        input: {
          ...input,
          limit: fullSize || 0,
          skip: 0,
        },
      },
    });

    const data = await getCashInventoryCsvData((generateCashInventoryReport?.rows ?? []) as CashInventoryType[]);
    return getOptions({ data });
  };

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title="cashInv.title"
      subtitle="cashInv.desc"
      Icon={CashInventorySVG}
      withPicker
    >
      <FilterBox>
        {DateFilter}
        {TimeZone}
      </FilterBox>
      <DateRangeInfo from={zonedDate} timeZone={zoneId} />
      <Table
        tableName={TABLE_NAME}
        columns={columns}
        data={rows || []}
        onPagination={setPagination}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={fullSize}
        sorting={sorting}
        onSorting={setSorting}
        error={error}
        loading={loading}
        initialLoading={previousData === undefined}
        SubRows={SubRowsWrapper}
        actions={
          <TableDownload
            title={(t, page) =>
              tableDownloadTitles.withPageInfo(
                t,
                tableDownloadTitles.withRequestedDate(zonedDate, "cashInv.title", t),
                page,
              )
            }
            disabled={!fullSize}
            getCsv={getCsv}
            getCsvCurrentPage
          />
        }
      />
    </PageLayout>
  );
};
