import { Navigate } from "appRouting";
import { RouteConfig, RouteValue } from "routing";

import { Home, Layout, PageNotFound, ReleaseNotes, SearchResults, Terms } from "./pages";

export type BasePaths = {
  HOME: RouteValue;
  SEARCH: RouteValue;
  TERMS: RouteValue;
  RELEASE_NOTES: RouteValue;
  "404": RouteValue;
};

export const BASE: RouteConfig<BasePaths> = {
  LAYOUT: {
    path: "",
    Component: Layout,
  },
  HOME: {
    path: "/",
    Component: Home,
  },
  SEARCH: {
    path: "/search",
    Component: SearchResults,
  },
  TERMS: {
    path: "/terms",
    Component: Terms,
  },
  RELEASE_NOTES: {
    path: "/release-notes",
    Component: ReleaseNotes,
  },
  "404": {
    path: "/404",
    Component: PageNotFound,
  },
  "*": {
    path: "*",
    middleware: [() => <Navigate route="404" replace />],
    Component: () => null,
  },
};
