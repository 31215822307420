import { RouteConfig, RouteValue } from "routing";

import { UserProfile } from "./pages";
import { Users } from "./pages/User/Users";

export type UserPaths = {
  USER_PROFILE: RouteValue;
  USERS: RouteValue;
};

export const USER: RouteConfig<UserPaths> = {
  USER_PROFILE: {
    path: "/user-profile",
    Component: UserProfile,
  },
  USERS: {
    path: "/administration/users",
    Component: Users,
  },
};
