export const countryCodes = [
  { countryCode: "AD", currencyCode: "EUR" },
  { countryCode: "AF", currencyCode: "AFN" },
  { countryCode: "AG", currencyCode: "XCD" },
  { countryCode: "AI", currencyCode: "XCD" },
  { countryCode: "AL", currencyCode: "ALL" },
  { countryCode: "AM", currencyCode: "AMD" },
  { countryCode: "AO", currencyCode: "AOA" },
  { countryCode: "AQ", currencyCode: "" },
  { countryCode: "AR", currencyCode: "ARS" },
  { countryCode: "AS", currencyCode: "USD" },
  { countryCode: "AT", currencyCode: "EUR" },
  { countryCode: "AU", currencyCode: "AUD" },
  { countryCode: "AW", currencyCode: "AWG" },
  { countryCode: "AX", currencyCode: "EUR" },
  { countryCode: "AZ", currencyCode: "AZN" },
  { countryCode: "BA", currencyCode: "BAM" },
  { countryCode: "BB", currencyCode: "BBD" },
  { countryCode: "BD", currencyCode: "BDT" },
  { countryCode: "BE", currencyCode: "EUR" },
  { countryCode: "BF", currencyCode: "XOF" },
  { countryCode: "BG", currencyCode: "BGN" },
  { countryCode: "BH", currencyCode: "BHD" },
  { countryCode: "BI", currencyCode: "BIF" },
  { countryCode: "BJ", currencyCode: "XOF" },
  { countryCode: "BL", currencyCode: "EUR" },
  { countryCode: "BM", currencyCode: "BMD" },
  { countryCode: "BN", currencyCode: "BND" },
  { countryCode: "BO", currencyCode: "BOP" },
  { countryCode: "BQ", currencyCode: "USD" },
  { countryCode: "BR", currencyCode: "BRL" },
  { countryCode: "BT", currencyCode: "BTN" },
  { countryCode: "BV", currencyCode: "NOK" },
  { countryCode: "BW", currencyCode: "BWP" },
  { countryCode: "BY", currencyCode: "BYR" },
  { countryCode: "BZ", currencyCode: "BZD" },
  { countryCode: "CA", currencyCode: "CAD" },
  { countryCode: "CH", currencyCode: "CHE" },
  { countryCode: "CI", currencyCode: "XOF" },
  { countryCode: "CL", currencyCode: "CLP" },
  { countryCode: "CM", currencyCode: "XAF" },
  { countryCode: "CN", currencyCode: "CNY" },
  { countryCode: "CO", currencyCode: "COP" },
  { countryCode: "CR", currencyCode: "CRC" },
  { countryCode: "CU", currencyCode: "CUC" },
  { countryCode: "CV", currencyCode: "CVE" },
  { countryCode: "CW", currencyCode: "ANG" },
  { countryCode: "CX", currencyCode: "AUD" },
  { countryCode: "CY", currencyCode: "EUR" },
  { countryCode: "DE", currencyCode: "EUR" },
  { countryCode: "DJ", currencyCode: "DJF" },
  { countryCode: "DK", currencyCode: "DKK" },
  { countryCode: "DM", currencyCode: "XCD" },
  { countryCode: "DZ", currencyCode: "DZD" },
  { countryCode: "EC", currencyCode: "USD" },
  { countryCode: "EE", currencyCode: "EUR" },
  { countryCode: "EG", currencyCode: "EGP" },
  { countryCode: "EH", currencyCode: "MAD" },
  { countryCode: "ER", currencyCode: "ERN" },
  { countryCode: "ES", currencyCode: "EUR" },
  { countryCode: "ET", currencyCode: "ETB" },
  { countryCode: "FI", currencyCode: "EUR" },
  { countryCode: "FJ", currencyCode: "FJD" },
  { countryCode: "FM", currencyCode: "USD" },
  { countryCode: "FR", currencyCode: "EUR" },
  { countryCode: "GA", currencyCode: "XAF" },
  { countryCode: "GD", currencyCode: "XCD" },
  { countryCode: "GE", currencyCode: "GEL" },
  { countryCode: "GF", currencyCode: "EUR" },
  { countryCode: "GG", currencyCode: "GBP" },
  { countryCode: "GH", currencyCode: "GHS" },
  { countryCode: "GI", currencyCode: "GIP" },
  { countryCode: "GL", currencyCode: "DKK" },
  { countryCode: "GN", currencyCode: "GNF" },
  { countryCode: "GP", currencyCode: "EUR" },
  { countryCode: "GQ", currencyCode: "XAF" },
  { countryCode: "GR", currencyCode: "EUR" },
  { countryCode: "GS", currencyCode: "" },
  { countryCode: "GT", currencyCode: "GTQ" },
  { countryCode: "GU", currencyCode: "USD" },
  { countryCode: "GW", currencyCode: "XOF" },
  { countryCode: "GY", currencyCode: "GYD" },
  { countryCode: "HK", currencyCode: "HKD" },
  { countryCode: "HN", currencyCode: "HNL" },
  { countryCode: "HR", currencyCode: "HRK" },
  { countryCode: "HT", currencyCode: "HTG" },
  { countryCode: "HU", currencyCode: "HUF" },
  { countryCode: "ID", currencyCode: "IDR" },
  { countryCode: "IE", currencyCode: "EUR" },
  { countryCode: "IL", currencyCode: "ILS" },
  { countryCode: "IM", currencyCode: "GBP" },
  { countryCode: "IN", currencyCode: "INR" },
  { countryCode: "IQ", currencyCode: "IQD" },
  { countryCode: "IR", currencyCode: "IRR" },
  { countryCode: "IS", currencyCode: "ISK" },
  { countryCode: "IT", currencyCode: "EUR" },
  { countryCode: "JE", currencyCode: "GBP" },
  { countryCode: "JM", currencyCode: "JMD" },
  { countryCode: "JO", currencyCode: "JOD" },
  { countryCode: "JP", currencyCode: "JPY" },
  { countryCode: "KE", currencyCode: "KES" },
  { countryCode: "KG", currencyCode: "KGS" },
  { countryCode: "KH", currencyCode: "KHR" },
  { countryCode: "KP", currencyCode: "KPW" },
  { countryCode: "KR", currencyCode: "KRW" },
  { countryCode: "KI", currencyCode: "AUD" },
  { countryCode: "KN", currencyCode: "XCD" },
  { countryCode: "KW", currencyCode: "KWD" },
  { countryCode: "KZ", currencyCode: "KZT" },
  { countryCode: "LB", currencyCode: "LBP" },
  { countryCode: "LC", currencyCode: "XCD" },
  { countryCode: "LI", currencyCode: "CHF" },
  { countryCode: "LK", currencyCode: "LKR" },
  { countryCode: "LR", currencyCode: "LRD" },
  { countryCode: "LS", currencyCode: "LSL" },
  { countryCode: "LT", currencyCode: "EUR" },
  { countryCode: "LU", currencyCode: "EUR" },
  { countryCode: "LV", currencyCode: "EUR" },
  { countryCode: "LY", currencyCode: "LYD" },
  { countryCode: "MA", currencyCode: "MAD" },
  { countryCode: "MC", currencyCode: "EUR" },
  { countryCode: "ME", currencyCode: "EUR" },
  { countryCode: "MF", currencyCode: "EUR" },
  { countryCode: "MG", currencyCode: "MGA" },
  { countryCode: "ML", currencyCode: "XOF" },
  { countryCode: "MM", currencyCode: "MMK" },
  { countryCode: "MN", currencyCode: "MNT" },
  { countryCode: "MO", currencyCode: "MOP" },
  { countryCode: "MQ", currencyCode: "EUR" },
  { countryCode: "MR", currencyCode: "MRU" },
  { countryCode: "MS", currencyCode: "XCD" },
  { countryCode: "MT", currencyCode: "EUR" },
  { countryCode: "MU", currencyCode: "MUR" },
  { countryCode: "MV", currencyCode: "MVR" },
  { countryCode: "MW", currencyCode: "MWK" },
  { countryCode: "MX", currencyCode: "MXN" },
  { countryCode: "MY", currencyCode: "MYR" },
  { countryCode: "MZ", currencyCode: "MZN" },
  { countryCode: "NA", currencyCode: "NAD" },
  { countryCode: "NC", currencyCode: "XPF" },
  { countryCode: "NF", currencyCode: "AUD" },
  { countryCode: "NG", currencyCode: "NGN" },
  { countryCode: "NI", currencyCode: "NIO" },
  { countryCode: "NO", currencyCode: "NOK" },
  { countryCode: "NP", currencyCode: "NPR" },
  { countryCode: "NR", currencyCode: "AUD" },
  { countryCode: "NU", currencyCode: "NZD" },
  { countryCode: "NZ", currencyCode: "NZD" },
  { countryCode: "OM", currencyCode: "OMR" },
  { countryCode: "PA", currencyCode: "PAB" },
  { countryCode: "PE", currencyCode: "PEN" },
  { countryCode: "PF", currencyCode: "XPF" },
  { countryCode: "PG", currencyCode: "PGK" },
  { countryCode: "PK", currencyCode: "PKR" },
  { countryCode: "PL", currencyCode: "PLN" },
  { countryCode: "PM", currencyCode: "EUR" },
  { countryCode: "PN", currencyCode: "NZD" },
  { countryCode: "PR", currencyCode: "USD" },
  { countryCode: "PS", currencyCode: "" },
  { countryCode: "PT", currencyCode: "EUR" },
  { countryCode: "PW", currencyCode: "USD" },
  { countryCode: "PY", currencyCode: "PYG" },
  { countryCode: "QA", currencyCode: "QAR" },
  { countryCode: "RE", currencyCode: "EUR" },
  { countryCode: "RO", currencyCode: "RON" },
  { countryCode: "RS", currencyCode: "RSD" },
  { countryCode: "RU", currencyCode: "RUB" },
  { countryCode: "RW", currencyCode: "RWF" },
  { countryCode: "SA", currencyCode: "SAR" },
  { countryCode: "SB", currencyCode: "SBD" },
  { countryCode: "SC", currencyCode: "SCR" },
  { countryCode: "SE", currencyCode: "SEK" },
  { countryCode: "SG", currencyCode: "SGD" },
  { countryCode: "SH", currencyCode: "SHP" },
  { countryCode: "SI", currencyCode: "EUR" },
  { countryCode: "SJ", currencyCode: "NOK" },
  { countryCode: "SK", currencyCode: "EUR" },
  { countryCode: "SL", currencyCode: "SLL" },
  { countryCode: "SM", currencyCode: "EUR" },
  { countryCode: "SN", currencyCode: "XOF" },
  { countryCode: "SO", currencyCode: "SOS" },
  { countryCode: "SR", currencyCode: "SRD" },
  { countryCode: "SS", currencyCode: "SSP" },
  { countryCode: "ST", currencyCode: "STN" },
  { countryCode: "SV", currencyCode: "SVC" },
  { countryCode: "SX", currencyCode: "ANG" },
  { countryCode: "SY", currencyCode: "SYP" },
  { countryCode: "TD", currencyCode: "XAF" },
  { countryCode: "TG", currencyCode: "XOF" },
  { countryCode: "TH", currencyCode: "THB" },
  { countryCode: "TJ", currencyCode: "TJS" },
  { countryCode: "TK", currencyCode: "NZD" },
  { countryCode: "TL", currencyCode: "USD" },
  { countryCode: "TM", currencyCode: "TMT" },
  { countryCode: "TN", currencyCode: "TND" },
  { countryCode: "TO", currencyCode: "TOP" },
  { countryCode: "TR", currencyCode: "TRY" },
  { countryCode: "TT", currencyCode: "TTD" },
  { countryCode: "TV", currencyCode: "AUD" },
  { countryCode: "TZ", currencyCode: "TZS" },
  { countryCode: "UA", currencyCode: "UAH" },
  { countryCode: "UG", currencyCode: "UGX" },
  { countryCode: "US", currencyCode: "USD" },
  { countryCode: "UY", currencyCode: "UYU" },
  { countryCode: "UZ", currencyCode: "UZS" },
  { countryCode: "VC", currencyCode: "XCD" },
  { countryCode: "VE", currencyCode: "VES" },
  { countryCode: "VG", currencyCode: "USD" },
  { countryCode: "VI", currencyCode: "USD" },
  { countryCode: "VN", currencyCode: "VND" },
  { countryCode: "VU", currencyCode: "VUV" },
  { countryCode: "WF", currencyCode: "XPF" },
  { countryCode: "WS", currencyCode: "WST" },
  { countryCode: "YE", currencyCode: "YER" },
  { countryCode: "YT", currencyCode: "EUR" },
  { countryCode: "ZA", currencyCode: "ZAR" },
  { countryCode: "ZM", currencyCode: "ZMW" },
  { countryCode: "ZW", currencyCode: "ZWL" },
  { countryCode: "SZ", currencyCode: "SZL" },
  { countryCode: "MK", currencyCode: "MKD" },
  { countryCode: "PH", currencyCode: "PHP" },
  { countryCode: "NL", currencyCode: "EUR" },
  { countryCode: "AE", currencyCode: "AED" },
  { countryCode: "MD", currencyCode: "MDL" },
  { countryCode: "GM", currencyCode: "GMD" },
  { countryCode: "DO", currencyCode: "DOP" },
  { countryCode: "SD", currencyCode: "SDG" },
  { countryCode: "LA", currencyCode: "LAK" },
  { countryCode: "TW", currencyCode: "TWD" },
  { countryCode: "CG", currencyCode: "XAF" },
  { countryCode: "CZ", currencyCode: "CZK" },
  { countryCode: "GB", currencyCode: "GBP" },
  { countryCode: "NE", currencyCode: "XOF" },
  { countryCode: "CD", currencyCode: "CDF" },
  { countryCode: "BS", currencyCode: "BSD" },
  { countryCode: "CC", currencyCode: "AUD" },
  { countryCode: "CF", currencyCode: "XAF" },
  { countryCode: "CK", currencyCode: "NZD" },
  { countryCode: "FK", currencyCode: "FKP" },
  { countryCode: "FO", currencyCode: "DKK" },
  { countryCode: "HM", currencyCode: "AUD" },
  { countryCode: "IO", currencyCode: "USD" },
  { countryCode: "KM", currencyCode: "KMF" },
  { countryCode: "KY", currencyCode: "KYD" },
  { countryCode: "MH", currencyCode: "USD" },
  { countryCode: "MP", currencyCode: "USD" },
  { countryCode: "TC", currencyCode: "USD" },
  { countryCode: "TF", currencyCode: "EUR" },
  { countryCode: "UM", currencyCode: "USD" },
  { countryCode: "VA", currencyCode: "EUR" },
  { countryCode: "XK", currencyCode: "EUR" },
] as const;
