import { Plus, Trash } from "@phosphor-icons/react";
import { TKeys } from "i18next";
import { useState } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { history, navigateTo } from "appRouting";
import { getFormTitle } from "base/fields";
import { FormData, OnSubmit, SchemaForm, useFieldArray } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text } from "common/guideline";
import { LicenseContentDtoIn } from "generated";
import { getAllowedLicenseFeatureKeysByTenant } from "machine/fields/availabeLicenseFeatureKeysField";

export type MachineLicenseFormContentData = LicenseContentDtoIn;

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const FeaturesArrayComponent: React.FC<React.PropsWithChildren<{ fieldArrayName: string }>> = ({
  fieldArrayName,
  children,
}) => {
  const { append } = useFieldArray(fieldArrayName);

  return (
    <div tw="flex flex-col gap-2">
      <div>{children}</div>

      <Button variant={["primary", "smSquare"]} onClick={() => append({})}>
        <Plus />
      </Button>
    </div>
  );
};

const Row: React.FC<React.PropsWithChildren<{ fieldArrayName: string; index: number }>> = ({
  children,
  fieldArrayName,
  index,
}) => {
  const { remove } = useFieldArray(fieldArrayName);

  return (
    <div tw="flex gap-2">
      <TwoColsWrapper tw="flex-1">{children}</TwoColsWrapper>

      <div tw="flex flex-col gap-2 pt-5">
        <Button variant={["error", "smSquare"]} onClick={() => remove(index)} data-test={`removeRecipient-${index}`}>
          <Trash weight="duotone" />
        </Button>
      </div>
    </div>
  );
};

const getFields: () => CustomRenderFields[] = () => [
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      {
        type: "text",
        name: "uuid",
        label: "machine.uuid",
        disabled: true,
        validate: { type: "string", required: true },
      },
    ],
  },
  {
    type: "container",
    Component: getFormTitle("machine.licensing.features"),
  },
  {
    type: "array",
    name: "features",
    Component: FeaturesArrayComponent,
    Row,
    defaultValue: [{ warningBeforeExpirationDays: 0, disableFeatureWhenExpired: false }],
    fields: [
      getAllowedLicenseFeatureKeysByTenant({ name: "key", validate: { type: "string", required: true } }),
      {
        type: "number",
        name: "warningBeforeExpirationDays",
        label: "machine.licensing.warningBeforeExpirationDays",
        parse: (v) => (v ? Number(v) : v),
        validate: { type: "number", required: true },
      },
      {
        type: "checkbox",
        name: "disableFeatureWhenExpired",
        label: "machine.licensing.disableFeatureWhenExpired",
        defaultValue: false,
        validate: { type: "boolean", required: true },
      },
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<MachineLicenseFormContentData>;
  submitLabel: TKeys;
  initial?: MachineLicenseFormContentData;
};

export const MachineLicenseContentForm: React.FC<Props> = ({ onSubmit, submitLabel, initial }) => {
  const [fields] = useState(getFields);

  return (
    <SchemaForm<MachineLicenseFormContentData>
      fields={fields}
      onSubmit={onSubmit}
      initial={initial}
      customRender={customRender}
      SubmitComponent={() => (
        <FormData type="isSubmittig">
          {(isLoading) => (
            <div tw="flex justify-between">
              <Button
                tw="mt-6"
                variant="side"
                onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_LIST" })}
                data-test="goBack"
              >
                <Text tKey="machine.goBack" />
              </Button>

              <LoadingButton type="submit" tw="mt-6" isLoading={isLoading} data-test="submitForm">
                <Text tKey={submitLabel} />
              </LoadingButton>
            </div>
          )}
        </FormData>
      )}
    />
  );
};
