import tw, { css, styled } from "twin.macro";
import "styled-components/macro";

import { WithVariants, applyVariant } from "./helpers";
import { Spinner } from "./Spinner";

const variants = {
  primary: tw`bg-primary-default text-white not-disabled:hover:bg-primary-alt`,
  sm: tw`px-2 py-1 text-xs`,
  smSquare: tw`w-8 h-8 p-0`,
  mdSquare: tw`h-12 w-12 p-2`,
  light: tw`text-gray-8 bg-gray-4 not-disabled:hover:bg-gray-6`,
  ghost: tw`not-disabled:hover:bg-gray-3`,
  side: tw`text-primary-default border-2 border-primary-default bg-gray-2 not-disabled:hover:bg-gray-3`,
  error: tw`bg-error-default text-error-alt not-disabled:hover:[filter:brightness(1.1)]`,
  withIcon: css`
    & > svg:first-child {
      ${tw`mr-2`}
    }
    & > svg:last-child {
      ${tw`ml-2`}
    }
  `,
};

type Props = Omit<JSX.IntrinsicElements["button"], "ref"> & WithVariants<typeof variants>;

const StyledButton = styled.button<Props>`
  ${tw`flex items-center justify-center transition-all duration-300 text-sm rounded px-4 py-3 font-semibold disabled:(cursor-not-allowed opacity-80)`}
  ${applyVariant(variants, "primary")}
`;

export const Button: React.FC<Props> = (props) => <StyledButton {...props} type={props.type || "button"} />;

const StyledLoadingButton = styled(Button)<{ isLoading?: boolean }>`
  ${tw`relative`}

  .spinnerIcon {
    ${tw`absolute top-[calc(50% - 7px)] left-[calc(50% - 7px)]`}
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      > :not(.spinnerIcon) {
        ${tw`opacity-0`}
      }
    `}
`;

// uses Button component - while loading - content is invisible and spinner is shown
export const LoadingButton: React.FC<Props & { isLoading?: boolean }> = ({ children, ...props }) => {
  return (
    <StyledLoadingButton {...props}>
      {props.isLoading ? <Spinner className="spinnerIcon" /> : null}
      {children}
    </StyledLoadingButton>
  );
};
