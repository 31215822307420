import { MapTrifold } from "@phosphor-icons/react";
import countryList from "country-list/data.json";
import { LatLng } from "leaflet";
import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { FormStore, changeFormData, useFormContext } from "common/form";
import { CustomRenderFields } from "common/form/renderFields";
import { Button, ComponentModalProps, LeafletMap, MapMarker, Text, useModalComponent } from "common/guideline";
import { getLocationFromCoordinates } from "common/helpers/geocoding";

type FillProps = { fillCityAndCountry: boolean };

const Picker: React.FC<ComponentModalProps & FillProps & { form: FormStore<any> }> = ({
  onClose,
  form,
  fillCityAndCountry,
}) => {
  const [coords, setCoords] = useState<Pick<LatLng, "lat" | "lng"> | null>(() => {
    const { latitude, longitude } = form.getState().values;
    return latitude && longitude ? { lat: latitude, lng: longitude } : null;
  });

  return (
    <div>
      <div tw="h-80">
        <LeafletMap
          ready
          zoom={15}
          center={coords ? [coords.lat, coords.lng] : undefined}
          // wrap is getting real longitude instead of overflowed one from a map
          onClick={(e) => setCoords(e.latlng.wrap())}
        >
          {coords && <MapMarker.Point position={coords} />}
        </LeafletMap>
      </div>

      <div tw="flex justify-between pt-2">
        <Button variant={["sm", "side"]} onClick={onClose} data-test="closeCoordinatesModal">
          <Text tKey="location.cancel" />
        </Button>

        <Button
          variant={["sm", "primary"]}
          disabled={!coords}
          data-test="submitCoordinates"
          onClick={(e) => {
            e.preventDefault();

            if (coords) {
              changeFormData(
                form,
                { latitude: String(coords.lat), longitude: String(coords.lng) },
                { revalidate: true },
              );

              if (fillCityAndCountry) {
                getLocationFromCoordinates(coords.lat, coords.lng).then((location) => {
                  const countryCode = countryList.find(
                    (country) => country.code.toLowerCase() === location.countryShortCode?.toLowerCase(),
                  )?.code;
                  changeFormData(form, { city: location.city, country: countryCode }, { revalidate: true });
                });
              }

              onClose?.();
            }
          }}
        >
          <Text tKey="location.useLocation" />
        </Button>
      </div>
    </div>
  );
};

const CoordinatesField: React.FC<React.PropsWithChildren<FillProps>> = ({ children, fillCityAndCountry }) => {
  const form = useFormContext().useStore;
  const [show] = useModalComponent({
    Component: <Picker form={form} fillCityAndCountry={fillCityAndCountry} />,
    modalProps: { variant: ["modal", "md"] },
  });

  return (
    <div>
      {children}

      <Button tw="mt-2" variant={["withIcon", "primary", "sm"]} onClick={() => show()} data-test="showCoordinatesModal">
        <MapTrifold weight="duotone" />
        <Text tKey="location.selectOnMap" />
      </Button>
    </div>
  );
};

export const getCoordinatesField = (Wrapper?: React.FC, fillCityAndCountry = true): CustomRenderFields => ({
  type: "custom",
  name: "coordinates",
  Component: (props) => <CoordinatesField {...props} fillCityAndCountry={fillCityAndCountry} />,
  ...(fillCityAndCountry && {
    calculation: {
      field: ["latitude", "longitude"],
      updates: async (_, __, all) =>
        all.latitude && all.longitude
          ? getLocationFromCoordinates(all.latitude, all.longitude).then((res) => ({
              city: res.city,
              country: countryList.find((country) => country.code.toLowerCase() === res.countryShortCode?.toLowerCase())
                ?.code,
            }))
          : {},
    },
  }),
  fields: [
    {
      type: "container",
      Component: Wrapper,
      fields: [
        {
          type: "text",
          name: "latitude",
          label: "location.latitude",
          validate: { type: "string", required: true, custom: (v) => (isNaN(v) ? "location.mustBeNumeric" : null) },
        },
        {
          type: "text",
          name: "longitude",
          label: "location.longitude",
          validate: { type: "string", required: true, custom: (v) => (isNaN(v) ? "location.mustBeNumeric" : null) },
        },
      ],
    },
  ],
});
