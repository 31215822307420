import { CellContext } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import { Badge } from "common/guideline";
import { RmTransactionSubType } from "generated";

const transactionSubType: Record<RmTransactionSubType, "success" | "error" | "warning" | "neutral" | "info"> = {
  CHANGE: "info",
  DISPENSE_SURPLUS: "warning",
  EXPECTED_AMOUNT: "info",
  IN: "success",
  OUT: "info",
  REQUESTED_AMOUNT: "info",
  SALE: "info",
  DISPENSE_RETURN: "info",
};

export const TransactionSubType = ({ getValue }: CellContext<any, any>) => {
  const { t } = useTranslation();
  const value = getValue<RmTransactionSubType | undefined>();
  const values = t("tranSubTypes", { returnObjects: true });

  return value ? <Badge variant={transactionSubType[value]}>{values[value]}</Badge> : <span>-</span>;
};
