import { WithoutNullableKeys } from "types";

import { useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindListOfUsersByTenantDocument,
  FindListOfUsersDocument,
  useActiveDeactivateUserMutation,
  useFindAllDomainNamesQuery,
  useUpdateUserPasswordMutation,
  useUpdateUserPermissionOrRoleMutation,
} from "generated";

import { UserUpdateForm } from "../components/UserUpdateForm";
import { getActivateOrDeactivatePayload, getUpdatePasswordPayload, modifyUpdatePayload } from "../helpers";
import { IsUserData } from "../UsersTable/types";

export const useUserUpdate = ({
  userName,
  tenantPermissions,
  userRoles,
  domainName,
  locationGroupPermissions,
  locationPermissions,
  machinePermissions,
  accountLocked,
}: // Comment: Below are the props I would expect to be present in each record. There is an ongoing backend work for this.
// domainName,
WithoutNullableKeys<IsUserData>) => {
  const [updateUser] = useUpdateUserPermissionOrRoleMutation();
  const [updateUserPassword] = useUpdateUserPasswordMutation();
  const [activeDeactivateUser] = useActiveDeactivateUserMutation();

  const prevAccountLocked = accountLocked;
  const domainNameFromQuery = useFindAllDomainNamesQuery({ skip: !!domainName }).data?.findAllDomainNames?.[0];

  const modal = useModalComponent({
    modalProps: { variant: ["modal", "md"], backdropClose: false },
    Component: (
      <UserUpdateForm
        initial={{
          userName,
          domainName: domainName || domainNameFromQuery || "",
          userPermissions: (tenantPermissions?.map((tp) => tp.name) || [])
            .concat(locationGroupPermissions?.map((lgp) => lgp.name) || [])
            .concat(locationPermissions?.map((lp) => lp.name) || [])
            .concat(machinePermissions?.map((mp) => mp.name) || []),
          userRoleNames: userRoles?.map((tp) => tp.name) || [],
          accountLocked,
        }}
        onBack={() => modal[0]()}
        onSubmit={(input) => {
          const updatePasswordPayload = getUpdatePasswordPayload(input);

          if (updatePasswordPayload) {
            updateUserPassword({
              variables: updatePasswordPayload,
              ignoreResults: true,
            }).catch(() => useToast.actions.show("user.updatedPasswordError", { variant: "error" }));
          }
          if (prevAccountLocked !== input.accountLocked) {
            const activeOrDeactivatePayload = getActivateOrDeactivatePayload(input);
            activeDeactivateUser({
              variables: activeOrDeactivatePayload,
              ignoreResults: true,
            }).catch(() => useToast.actions.show("user.activateDeactivateError", { variant: "error" }));
          }

          return updateUser({
            variables: { isUserUpdateDtoIn: modifyUpdatePayload(input) },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindListOfUsersDocument, cache);
              evictQuery(FindListOfUsersByTenantDocument, cache);
            },
          })
            .then(() => {
              useToast.actions.show("user.updateSuccess", { variant: "success" });
            })
            .catch(() => useToast.actions.show("user.updateError", { variant: "error" }));
        }}
        submitLabel={"user.update"}
      />
    ),
  });

  return modal;
};
