import { IconProps } from "@phosphor-icons/react";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { Badge, Tooltip } from "common/guideline";

type VerticalNameListProps = {
  names: string[];
};

export const VerticalNameList: React.FC<VerticalNameListProps> = ({ names }) => (
  <div
    css={css`
      ${tw`flex flex-wrap overflow-auto max-w-2xl max-h-80`}
      & > * {
        ${tw`mr-1 mb-1 opacity-90`}
      }
    `}
  >
    {names.map((n, i) =>
      n ? (
        <Badge key={i} variant={["outlineNeutral", "text"]}>
          {n}
        </Badge>
      ) : null,
    )}
  </div>
);

type Props = VerticalNameListProps &
  React.PropsWithChildren<{
    count: number;
    Icon: React.FC<Pick<React.SVGProps<SVGSVGElement>, "width" | "height"> & Omit<IconProps, "ref">>;
    content?: React.ReactNode;
    "data-test"?: string;
  }>;

export const NamesListTooltip: React.FC<Props> = ({
  count,
  Icon,
  names,
  children = null,
  content,
  "data-test": dataTest,
}) =>
  count > 0 ? (
    <div tw="inline-block">
      <Tooltip hideOnScroll={false} content={content ?? <VerticalNameList names={names} />} data-test={dataTest}>
        <div tw="flex items-center space-x-1 text-primary-default">
          <Icon weight="duotone" size={18} width={18} height={18} />
          <span>{count}</span>
        </div>
      </Tooltip>
    </div>
  ) : (
    <>{children}</>
  );
