import { BuiltInFilterFn, FilterFn, HeaderContext } from "@tanstack/react-table";
import { isAfter, isBefore, isEqual, parseISO } from "date-fns";
import { useEffect, useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { InputRaw, SelectRaw } from "common/form/renderFields";
import { DateInputFormatType } from "common/form/renderFields/DateInput";

import { GenericRecord } from "../types";

import { useDateFilter } from "./DateFilter";
import { useDateRangeFilter } from "./DateRangeFilter";
import { useNumberRangeFilter } from "./NumberRangeFilter";

type FilterComponent<T extends GenericRecord = GenericRecord> = (props: HeaderContext<T, any>) => JSX.Element;

const getSelectBaseFilter: (
  isMulti?: boolean,
  customOptions?: Array<{ value: string; label: string }>,
) => FilterComponent = (isMulti, customOptions) =>
  function SelectBaseFilter({ column: { getFilterValue, setFilterValue, getFacetedUniqueValues } }) {
    // NOTE: when custom options is present, values and options should be not computed
    const values = getFacetedUniqueValues();
    const options = useMemo(
      () =>
        values.size > 0
          ? Array.from(values.keys())
              .filter((v) => v !== undefined)
              .sort()
              .map((value) => ({ value, label: value }))
          : [],
      [values],
    );

    return (
      <SelectRaw
        tw="w-full"
        variant="sm"
        options={customOptions ? customOptions : options}
        value={getFilterValue() as any}
        onChange={(v) => setFilterValue(v || undefined)}
        name="select"
        isClearable
        maxMenuHeight={200}
        closeMenuOnSelect
        placeholder="common.table.filter.filter"
        isMulti={isMulti}
      />
    );
  };

const TextFilter: FilterComponent = ({ column: { getFilterValue, setFilterValue } }) => (
  <InputRaw
    tw="w-full"
    type="text"
    variant="sm"
    onChange={(v) => setFilterValue(v.target.value)}
    name="select"
    value={(getFilterValue() as any) ?? ""}
    placeholder="common.table.filter.filter"
  />
);

const SelectBooleanFilter: FilterComponent = ({ column: { getFilterValue, setFilterValue } }) => (
  <SelectRaw
    variant="sm"
    options={[
      { value: "true", label: "common.yes" },
      { value: "false", label: "common.no" },
    ]}
    onChange={(v) => setFilterValue(v?.toString())}
    name="select"
    value={getFilterValue() as any}
    isClearable
    maxMenuHeight={200}
    closeMenuOnSelect
    placeholder="common.table.filter.filter"
  />
);

const DateRangeFilter: FilterComponent = ({ column: { setFilterValue } }) => {
  const [{ from, to }, DateRange] = useDateRangeFilter("empty", {
    isClearable: true,
    allowSingleDate: true,
    placeholder: "common.table.filter.filter",
    variant: "sm",
  });

  useEffect(() => {
    setFilterValue({ from, to });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  return DateRange;
};

const getDateFilter: (
  dateFormat: DateInputFormatType,
  formatFilter?: (filter: "" | Date) => string | undefined,
) => FilterComponent = (dateFormat, formatFilter) =>
  function DateFilter({ column: { setFilterValue } }) {
    const [date, Date] = useDateFilter({
      isClearable: true,
      initialDate: "",
      placeholder: "common.table.filter.filter",
      variant: "sm",
      dateFormat,
    });

    useEffect(() => {
      setFilterValue(formatFilter ? formatFilter(date) : date);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, formatFilter]);

    return Date;
  };

const NumberRangeFilter: FilterComponent = ({ column: { setFilterValue } }) => {
  const [data, DateRange] = useNumberRangeFilter("empty", {
    isClearable: true,
    placeholder: "common.table.filter.filter",
    variant: "sm",
  });

  useEffect(() => {
    setFilterValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return DateRange;
};

export const localFilters = {
  selectBooleanFilter: {
    meta: { filter: SelectBooleanFilter },
    filterFn: (row, columnId, filterValue) =>
      !filterValue ? true : (row.original[columnId] as any)?.toString() === filterValue,
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
  getSelectBaseFilter: ((isMulti, customOptions) => ({
    meta: { filter: getSelectBaseFilter(isMulti, customOptions) },
    filterFn: isMulti ? "arrIncludesSome" : "equals",
  })) as (
    isMulti?: boolean,
    customOptions?: Array<{ value: string; label: string }>,
  ) => { meta: { filter: FilterComponent }; filterFn?: BuiltInFilterFn },
  getTextBaseFilter: {
    meta: { filter: TextFilter },
  } as { meta: { filter: FilterComponent } },
  getDateFilter: {
    meta: { filter: getDateFilter },
    filterFn:
      (filterType = "after") =>
      (row, columnId, filterValue) => {
        if (!filterValue) return true;

        const date = new Date(filterValue);
        const rowValue = parseISO(row.original[columnId] as any);
        const compareFn = filterType === "after" ? isAfter : filterType === "before" ? isBefore : isEqual;

        return date ? compareFn(rowValue, date) || isEqual(rowValue, date) : true;
      },
  } as {
    meta: { filter: typeof getDateFilter };
    filterFn: (filterType?: "before" | "after" | "equal") => FilterFn<GenericRecord>;
  },
  getDateRangeFilter: {
    meta: { filter: DateRangeFilter },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const { from, to } = filterValue;

      if (!from && !to) return true;

      const _from = new Date(from);
      const _to = new Date(to);

      const rowValue = parseISO(row.original[columnId] as any);

      return (
        (from ? isAfter(rowValue, _from) || isEqual(rowValue, _from) : true) &&
        (to ? isBefore(rowValue, _to) || isEqual(rowValue, _to) : true)
      );
    },
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
  getNumberRangeFilter: {
    meta: { filter: NumberRangeFilter },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const { type, from, to } = filterValue;

      if (!from && !to) return true;

      const _from = from || -Infinity;
      const _to = to || Infinity;

      const rowValue = row.original[columnId] as any;

      return type === "equal" ? from === Number(rowValue) : rowValue >= _from && rowValue <= _to;
    },
  } as { meta: { filter: FilterComponent }; filterFn?: FilterFn<GenericRecord> },
};
