import { HeaderGroup, flexRender } from "@tanstack/react-table";

import { getCellStyle } from "../helpers";
import { TableProps } from "../types";

export const TableFooter: React.FC<{
  columns: TableProps<any>["columns"];
  getFooterGroups: () => HeaderGroup<any>[];
}> = ({ columns, getFooterGroups }) =>
  columns.some((c) => c.footer) ? (
    <div className=" tfoot" role="tfoot">
      {getFooterGroups().map((group) => (
        <div key={group.id} className="tr">
          {group.headers.map((column) => (
            <div key={column.id} className="th" style={getCellStyle(column)}>
              {column.isPlaceholder ? null : flexRender(column.column.columnDef.footer, column.getContext())}
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : null;
