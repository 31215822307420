import { TKeys } from "i18next";

import { RmTransactionSubType } from "generated";

export const transactionSubTypesOptions: Array<{ label: TKeys; value: RmTransactionSubType }> = [
  { label: "tranSubTypes.CHANGE", value: "CHANGE" },
  { label: "tranSubTypes.DISPENSE_SURPLUS", value: "DISPENSE_SURPLUS" },
  { label: "tranSubTypes.EXPECTED_AMOUNT", value: "EXPECTED_AMOUNT" },
  { label: "tranSubTypes.IN", value: "IN" },
  { label: "tranSubTypes.OUT", value: "OUT" },
  { label: "tranSubTypes.REQUESTED_AMOUNT", value: "REQUESTED_AMOUNT" },
  { label: "tranSubTypes.SALE", value: "SALE" },
  { label: "tranSubTypes.DISPENSE_RETURN", value: "DISPENSE_RETURN" },
];
