export const es = {
  operations: {
    title: "Tablero de mandos",
    cashOps: {
      title: "Operaciones de caja",
      desc: "Obtenga una vision general de las transacciones de diferentes tipos. Puede utilizar el filtro para seleccionar de que grupo, centro o maquina desea ver la informacion de las transacciones.",
    },
    techOps: {
      title: "Operaciones tecnologicas",
      desc: "Utilice el panel de control de Operaciones tecnologicas para supervisar las maquinas",
    },
    retailOps: "Operaciones de comercio",
    cashMetrics: "Metricas de efectivo",
    recentTransactions: "Transacciones recientes",
    recentUsers: "Usuarios recientes",
    selectMachine: "Seleccione la maquina del selector de arriba, para ver sus detalles",
    selectSiteMachine: "Selecciona sitio y maquina del selector de arriba, para ver sus detalles",
    openMap: "Abrir mapa",
    closeMap: "Cerrar mapa",
  },
};
