import tw from "twin.macro";
import "styled-components/macro";

import { ProgressBar, Text } from "common/guideline";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

export const Capacity = () => (
  <Box>
    <div tw="flex flex-col">
      <BoxHeading>Capacity</BoxHeading>
      <div tw="h-52 px-8 flex flex-col justify-center">
        <div tw="flex">
          <div tw="w-16 flex items-center">
            <Text variant="label">JM-17</Text>
          </div>
          <ProgressBar progress={55} />
        </div>
        <div tw="flex">
          <div tw="w-16 flex items-center">
            <Text variant="label">SD-23</Text>
          </div>
          <ProgressBar progress={27} />
        </div>
        <div tw="flex">
          <div tw="w-16 flex items-center">
            <Text variant="label">JM-24</Text>
          </div>
          <ProgressBar progress={96} />
        </div>
      </div>
    </div>
  </Box>
);
