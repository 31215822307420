import { useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { FormStore, changeFormData, useFieldData, useFormContext } from "common/form";
import { Button, Text } from "common/guideline";

const readXmlFile = (
  evt: FileList | null,
  form: FormStore<any>,
  setData: React.Dispatch<React.SetStateAction<Array<any> | null>>,
) => {
  if (evt) {
    const file = evt[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      changeFormData(form, { usersXml: null }, { revalidate: true });
      const data = (e as any).target.result;
      const xmlParser = await import("fast-xml-parser");
      if (xmlParser.XMLValidator.validate(data) === true) {
        const parser = new xmlParser.XMLParser({
          ignorePiTags: true,
          ignoreAttributes: true,
          attributeNamePrefix: "",
        });

        const parsedData = parser.parse(data);
        const {
          users: { user: users },
        } = parsedData;
        if (users) {
          changeFormData(form, { usersXml: data }, { revalidate: true });
          setData(users);
        } else {
          changeFormData(form, { usersXml: "mu.noFileSelected" }, { key: "errors" });
        }
      } else {
        changeFormData(form, { usersXml: "mu.noFileSelected" }, { key: "errors" });
      }
    };
    reader.readAsText(file, "UTF-8");
  }
};

export const UsersFileSelection: React.FC = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [data, setData] = useState<Array<any> | null>(null);
  const form = useFormContext().useStore;
  const error = useFieldData("usersXml", "errors") as string;

  return (
    <div tw="pt-4 pb-1 overflow-x-auto overflow-y-auto">
      <input
        ref={ref}
        tw="h-0 w-0 opacity-0 fixed top-[-100px] left-[-100px]"
        type="file"
        onChange={(e) => readXmlFile(e.target.files, form, setData)}
        accept="application/xml"
      />
      <div tw="flex items-baseline gap-2 h-[32px]">
        <Button onClick={() => ref.current?.click()} variant={["sm", "side"]} data-test="uploadFile">
          <Text tKey="mu.selectFile" />
        </Button>
        {error && !data && <Text variant={["content", "error"]}>{error}</Text>}
        {data && <Text tKey="mu.usersToAdd" tValue={{ count: data?.length }} variant="content" />}
      </div>
    </div>
  );
};
