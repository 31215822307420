import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as CashInventorySVG } from "assets/icons/CashInventory.svg";
import { ReactComponent as CashManagementSVG } from "assets/icons/CashManagement.svg";
import { ReactComponent as CashSummarySVG } from "assets/icons/CashSummary.svg";
import { ReactComponent as FileXlsSVG } from "assets/icons/FileXls.svg";
import { ReactComponent as MachineDetailsSVG } from "assets/icons/MachineDetails.svg";
import { ReactComponent as MachineErrorsSVG } from "assets/icons/MachineErrors.svg";
import { ReactComponent as MachineManagementSVG } from "assets/icons/MachineManagement.svg";
import { ReactComponent as MachineUserSVG } from "assets/icons/MachineUser.svg";
import { ReactComponent as ManualDepositSVG } from "assets/icons/ManualDeposit.svg";
import { ReactComponent as NetCashSVG } from "assets/icons/NetCash.svg";
import { ReactComponent as OnlineStatusSVG } from "assets/icons/OnlineStatus.svg";
import { ReactComponent as RollingBalanceSVG } from "assets/icons/RollingBalance.svg";
import { ReactComponent as TransactionsSVG } from "assets/icons/Transactions.svg";
import { PageLayout } from "base/components";
import { Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const Reports = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="report.overview" subtitle="report.desc" Icon={FileXlsSVG}>
    <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
      <Link route="MACHINE_MANAGEMENT">
        <Box>
          <BoxHeading>
            <Text tKey="machineManagement" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineManagementSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="CASH_MANAGEMENT">
        <Box>
          <BoxHeading>
            <Text tKey="cashMgmt.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <CashManagementSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_USER_MANAGEMENT">
        <Box>
          <BoxHeading>
            <Text tKey="mum.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineUserSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="TRANSACTIONS">
        <Box>
          <BoxHeading>
            <Text tKey="tranReport.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <TransactionsSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_ERRORS">
        <Box>
          <BoxHeading>
            <Text tKey="machineErrors" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineErrorsSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_DETAILS">
        <Box>
          <BoxHeading>
            <Text tKey="report.md.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineDetailsSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="ONLINE_STATUS">
        <Box>
          <BoxHeading>
            <Text tKey="mos.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <OnlineStatusSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="CASH_INVENTORY">
        <Box>
          <BoxHeading>
            <Text tKey="cashInv.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <CashInventorySVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="ROLLING_BALANCE">
        <Box>
          <BoxHeading>
            <Text tKey="transport.rBalance.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <RollingBalanceSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="CASH_SUMMARY">
        <Box>
          <BoxHeading>
            <Text tKey="report.cashSummary.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <CashSummarySVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="NET_CASH">
        <Box>
          <BoxHeading>
            <Text tKey="report.netCash.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <NetCashSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MANUAL_DEPOSITS">
        <Box>
          <BoxHeading>
            <Text tKey="manualDeposits" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <ManualDepositSVG />
          </BoxContent>
        </Box>
      </Link>
    </div>
  </PageLayout>
);
