import { Warning } from "@phosphor-icons/react";
import { TKeys } from "i18next";
import { useMemo } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getMachineUserGroupsTransferBoxField } from "administration/components/getSelectMachineUserGroupField";
import { history, navigateTo } from "appRouting";
import { FormData, OnSubmit, SchemaForm, useFormContext } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text, useModalComponent } from "common/guideline";
import { getRemoteMgmtSection } from "common/remoteMgmt";
import { MachineDtoIn } from "generated";
import { getSelectLocationsField } from "location/components";
import { getLicensingSection } from "machine/fields/licensingSection";

import { getActiveTenantMachineTypeField } from "../fields";

export type MachineFormData = Pick<
  MachineDtoIn,
  "name" | "machineTypeNodeId" | "uuid" | "locationNodeId" | "machineUserGroupNodeIds"
> & {
  licenseId?: string;
  // Configuration deployment & software installation
  timeOption?: string;
  remoteMgmtOption?: string;
  templateNodeId?: string;
  softwarePackageNodeId?: string;
  scheduleInfo?: string;
  createdBy?: string;
  // CoD licensing
  licensingOption?: string;
  acknowledgement?: boolean;
  licenseNodeId?: string;
  edition?: string;
  invoiceManager?: string;
  comment?: string;
  licenseKey?: string;
  subscriptionActive?: boolean;
  paymentIntervalType?: string;
  deactivatedDate?: Date;
};

const TwoColsWrapper = styled.div`
  ${tw`grid gap-4 pb-3 sm:grid-cols-2`}
`;

const getFields: (updateForm?: boolean) => CustomRenderFields[] = (updateForm) => [
  {
    type: "container",
    Component: TwoColsWrapper,
    fields: [
      {
        type: "text",
        name: "uuid",
        label: "machine.uuid",
        disabled: !!updateForm,
        validate: { type: "string", required: true },
      },
      {
        type: "text",
        name: "name",
        label: "machine.machineName",
        validate: { type: "string", required: true },
      },
      getActiveTenantMachineTypeField({ name: "machineTypeNodeId", validate: { type: "string", required: true } }),
      getSelectLocationsField({ name: "locationNodeId", isMulti: false }),
      updateForm ? { type: "container" } : getMachineUserGroupsTransferBoxField({ name: "machineUserGroupNodeIds" }),
    ],
  },
];

type Props = {
  onSubmit: OnSubmit<MachineFormData>;
  submitLabel: TKeys;
  initial?: MachineFormData;
  updateForm?: boolean;
};

type SiteChangeWarningProps = {
  onClose?: () => void;
  onSubmit: () => void;
  submitLabel: TKeys;
};

const SiteChangeWarning: React.FC<SiteChangeWarningProps> = ({ onClose, onSubmit, submitLabel }) => (
  <div>
    <div tw="flex items-center flex-col pb-4">
      <Warning weight="duotone" size={48} tw="text-warning-default" />
      <Text tKey="machine.movingBetweenSites" variant="heading" />
    </div>
    <div tw="flex flex-col gap-4">
      <Text tKey="machine.movingBetweenSitesDesc" />
      <div tw="flex gap-4 justify-end">
        {onClose && (
          <Button onClick={onClose} variant="ghost">
            <Text tKey="common.close" />
          </Button>
        )}
        <Button onClick={onSubmit}>
          <Text tKey={submitLabel} />
        </Button>
      </div>
    </div>
  </div>
);

const SubmitButton: React.FC<Pick<Props, "initial" | "submitLabel" | "updateForm"> & { isLoading: boolean }> = ({
  initial,
  isLoading,
  updateForm,
  submitLabel,
}) => {
  const { useStore } = useFormContext();
  const form = useStore();

  const [show] = useModalComponent({
    Component: (
      <SiteChangeWarning
        submitLabel={submitLabel}
        onSubmit={() => {
          show();
          form.submit();
        }}
      />
    ),
  });

  return (
    <LoadingButton
      type="submit"
      tw="mt-6"
      isLoading={isLoading}
      data-test="submitForm"
      onClick={(e) => {
        if (updateForm && initial?.locationNodeId !== form.values.locationNodeId) {
          e.preventDefault();
          show();
        }
      }}
    >
      <Text tKey={submitLabel} />
    </LoadingButton>
  );
};

export const MachineForm: React.FC<Props> = ({ onSubmit, submitLabel, initial, updateForm }) => {
  const fields = useMemo(
    () =>
      getFields(updateForm)
        .concat(
          updateForm
            ? getLicensingSection(
                initial?.licenseNodeId,
                initial?.edition,
                initial?.subscriptionActive,
                initial?.deactivatedDate,
              )
            : [],
        )
        .concat(getRemoteMgmtSection()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateForm],
  );

  return (
    <SchemaForm<MachineFormData>
      fields={fields}
      onSubmit={onSubmit}
      initial={initial}
      customRender={customRender}
      SubmitComponent={() => (
        <FormData type="isSubmittig">
          {(isLoading) => (
            <div tw="flex justify-between">
              <Button
                tw="mt-6"
                variant="side"
                onClick={() => navigateTo(history.getPreviousRoute() ? [-1] : { route: "MACHINE_LIST" })}
                data-test="goBack"
              >
                <Text tKey="machine.goBack" />
              </Button>
              <SubmitButton isLoading={isLoading} submitLabel={submitLabel} updateForm={updateForm} initial={initial} />
            </div>
          )}
        </FormData>
      )}
    />
  );
};
