import tw from "twin.macro";
import "styled-components/macro";
import { WithoutNullableKeys } from "types";

import { ActionButton } from "base/components";
import { TableTypes } from "common/guideline";
import { CommandFetched } from "zts/types";

import { useDeleteCommandModal } from "./components/DeleteCommand";

export const RowActions: React.FC<TableTypes.VerticalHeaderProps<CommandFetched>> = ({ row }) => {
  const [showDelete] = useDeleteCommandModal(row.original as WithoutNullableKeys<CommandFetched>);

  return (
    <div tw="overflow-x-auto inline-flex items-start">
      <ActionButton.Delete onClick={() => showDelete()} />
    </div>
  );
};
