import { useAuth } from "auth/hooks";
import { omitByKey } from "common/helpers";

import {
  ApiPermissions,
  ApiRoleNames,
  FormPermissions,
  FormRoleNames,
  UserCreateFormData,
  UserUpdateFormData,
} from "../types";

export const getAccessTranslationString = (word: string | undefined | null) =>
  `user.access.${word?.replace("/", "_").replace(":", "_")}`;

export const getTenantPermission = (word: string | undefined | null) => word?.split(":")[1];

const modifyPermissions = <T extends FormPermissions>({
  userPermissions,
  ...payload
}: T): Omit<T, "userPermissions"> & ApiPermissions => ({
  ...payload,
  tenantIdsPermission: userPermissions?.filter((p) => p.includes("tenant:ALL")) || [],
  locationGroupNodeIdsPermission: userPermissions?.filter((p) => p.includes("siteGroup:ALL")) || [],
  locationNodeIdsPermission: userPermissions?.filter((p) => p.includes("site:ALL")) || [],
  machineNodeIdsPermission: userPermissions?.filter((p) => p.includes("machine:AL")) || [],
});

const modifyUserRoleNames = <T extends FormRoleNames>(payload: T): Omit<T, "userRoleNames"> & ApiRoleNames => ({
  ...payload,
  userRoleNames: payload.userRoleNames ? payload.userRoleNames : undefined,
});

export const modifyCreatePayload = (payload: UserCreateFormData) =>
  omitByKey("confirmPassword")(modifyPermissions(modifyUserRoleNames(payload)));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const modifyUpdatePayload = ({ updatePassword, password, accountLocked, ...payload }: UserUpdateFormData) =>
  modifyPermissions(modifyUserRoleNames(payload));

export const getUpdatePasswordPayload = (payload: UserUpdateFormData) => {
  return payload.updatePassword !== true || !payload?.password
    ? null
    : {
        domainName: payload.domainName,
        userName: payload.userName,
        password: payload.password,
      };
};

export const getActivateOrDeactivatePayload = (payload: UserUpdateFormData) => {
  return {
    domainName: payload.domainName,
    userName: payload.userName,
    activeOrDeactivate: payload.accountLocked,
  };
};

export const getIsCarbonUser = (domain?: string) => domain === "carbon.super";
export const useUserDomain = () => useAuth(useAuth.actions.getUser)?.sub?.split("@")?.[1];
export const useIsCarbonUser = () => {
  const domain = useUserDomain();
  return getIsCarbonUser(domain);
};
