import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo, useParams } from "appRouting";
import { usePermissionAccess } from "auth/hooks";
import { PageLayout } from "base/components";
import { Breadcrumb, useToast } from "common/guideline";
import { useMappedQuery } from "common/hooks";
import { FindTenantByTenantIdQuery, useConfigureTenantMutation, useFindTenantByTenantIdQuery } from "generated";
import { timeSettings } from "user/helpers/timeSettings";

import { TenantForm, TenantFormData } from "../components";

export type TenantConfigureParams = { tenantId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "TENANT_LIST",
    text: "tenant.list.title",
  },
  {
    route: "TENANT_CONFIGURE",
    text: "tenant.update.title",
  },
];

type InitialValues = TenantFormData | undefined;

const getInitialValues = ({
  findTenantByTenantId: tenant,
}: FindTenantByTenantIdQuery | undefined = {}): InitialValues =>
  tenant
    ? {
        tenantId: tenant.tenantId as string,
        name: tenant.name as string,
        billingId: tenant.billingId as string,
        contractNumber: tenant.contractNumber as string,
        endOfDay: tenant.endOfDay ?? timeSettings.get.dayEndTime(),
        machineTypes: tenant.machineTypes?.map((m) => ({ label: m?.name, value: m?.name, data: m })) ?? [],
        licenseAutoRenewalEnabled: tenant.licenseAutoRenewalEnabled,
        licenseValidityIntervalDays: tenant.licenseValidityIntervalDays,
        allowedLicenseFeatureKeys: tenant.allowedLicenseFeatureKeys,
      }
    : undefined;

export const ConfigureTenantScreen = () => {
  const { tenantId = "" } = useParams<TenantConfigureParams>();
  const [updateTenant] = useConfigureTenantMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindTenantByTenantIdQuery({
      variables: { tenantId },
      skip: !tenantId,
      returnPartialData: true,
    }),
  );

  if (!usePermissionAccess("canConfigureTenant")) {
    navigateTo({ route: "TENANT_LIST", replace: true });
  }

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout title="tenant.update.title" subtitle="tenant.update.description" breadcrumbs={breadcrumbs}>
      {loading ? null : (
        <div tw="max-w-screen-md">
          <TenantForm
            submitLabel="tenant.update.submit"
            initial={mappedData}
            onSubmit={(input) =>
              updateTenant({
                variables: {
                  input: {
                    ...input,
                    machineTypes: input.machineTypes.flatMap((t) => ({
                      name: t.value as string,
                      table: t.data?.table || "",
                    })),
                  },
                },
                ignoreResults: true,
              })
                .then(() => {
                  useToast.actions.show("tenant.update.success", { variant: "success" });
                  navigateTo({ route: "TENANT_LIST" });
                })
                .catch(() => useToast.actions.show("tenant.update.apiError", { variant: "error" }))
            }
          />
        </div>
      )}
    </PageLayout>
  );
};
