import { TKeys } from "i18next";
import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { Badge, CommonCells, Table, TableTypes, accessors } from "common/guideline";
import { withDefault } from "common/helpers";
import {
  Repeat,
  ScheduledReportEmailOut,
  ScheduledReportName,
  ScheduledReportStatus,
  useGetAllScheduledReportsQuery,
} from "generated";
import { useTranslation } from "i18n";
import { FilterBox, useBooleanFilter } from "report/components";

import { RowActions } from "./RowActions";
import { ScheduledReportsDataOut } from "./types";

export const tDays: TKeys[] = [
  "report.sr.mon",
  "report.sr.tue",
  "report.sr.wed",
  "report.sr.thu",
  "report.sr.fri",
  "report.sr.sat",
  "report.sr.sun",
];

const getColumns: TableTypes.TranslatedColumns<ScheduledReportsDataOut> = (t) => [
  {
    header: t("report.sr.name"),
    accessorKey: "instanceName",
    id: "INSTANCE_NAME",
  },
  {
    header: t("report.sr.reportName"),
    accessorKey: "reportName",
    id: "REPORT_NAME",
    minSize: 200,
    cell({ getValue }) {
      const name = getValue<ScheduledReportName | undefined>();
      return withDefault(name ? t(`report.sr.rName.${name}`) : undefined);
    },
  },
  {
    header: t("report.sr.status"),
    accessorKey: "status",
    id: "STATUS",
    cell({ getValue }) {
      const status = getValue<ScheduledReportStatus | undefined>();
      return withDefault(
        status ? (
          <Badge variant={status === "ACTIVE" ? "success" : status === "EXPIRED" ? "error" : "neutral"}>{status}</Badge>
        ) : undefined,
      );
    },
  },
  {
    header: t("report.sr.fileType"),
    accessorKey: "fileType",
    id: "FILE_TYPE",
    cell({ getValue }) {
      const fileType = getValue<string | undefined>();
      return withDefault(fileType ? <Badge>{fileType}</Badge> : undefined);
    },
  },
  {
    header: t("report.sr.email"),
    accessorKey: "reportEmails",
    id: "EMAIL",
    minSize: 250,
    enableSorting: false,
    cell: ({ getValue }) => {
      const value = getValue<Array<ScheduledReportEmailOut>>();
      return value?.length > 0 ? <div tw="whitespace-pre-line">{value.map((r) => r.email).join("\n")}</div> : "-";
    },
  },
  {
    header: t("report.sr.nextExecution"),
    accessorKey: "nextExecution",
    accessorFn: (d) => accessors.date(d.nextExecution, t) || "-",
    id: "NEXT_EXECUTION",
  },
  {
    header: t("report.sr.executionTime"),
    accessorKey: "metadata.executionTime",
    id: "EXECUTION_TIME",
  },
  {
    header: t("base.timeZone"),
    accessorKey: "metadata.zoneName",
    id: "TIME_ZONE",
  },
  {
    header: t("report.sr.repeat"),
    accessorKey: "metadata.repeat",
    id: "REPEAT",
    cell({ getValue }) {
      const repeat = getValue<Repeat | undefined>();
      return withDefault(repeat ? <Badge>{t(`report.sr.repeatType.${repeat}`)}</Badge> : undefined);
    },
  },
  {
    header: t("report.sr.repeatOnDay"),
    accessorKey: "metadata.repeatOnDay",
    id: "REPEAT_ON_DAY",
    enableSorting: false,
    cell({ getValue, row }) {
      const value = getValue<number | undefined>();
      if (value === undefined) return withDefault(value);
      const repeat = row.original?.metadata?.repeat;
      const show = repeat === "WEEKLY" ? (t(tDays[value]) as string) : repeat === "MONTHLY" ? value : undefined;
      return withDefault(show ? <Badge>{show}</Badge> : undefined);
    },
  },
  {
    header: t("report.sr.executeFrom"),
    accessorKey: "metadata.executeFrom",
    id: "EXECUTE_FROM",
    accessorFn: (d) => accessors.date(d.metadata?.executeFrom, t, "PP") || "-",
  },
  {
    header: t("report.sr.executeUntil"),
    accessorKey: "metadata.executeUntil",
    id: "EXECUTE_TO",
    accessorFn: (d) => accessors.date(d.metadata?.executeUntil, t, "PP") || "-",
  },
  CommonCells.getActionsCell({ cell: RowActions }),
];

const TABLE_NAME = "scheduledReports";

const activeFilter = (d: ScheduledReportsDataOut) => d.status === "ACTIVE";
const nodeStatusNotDeleted = (d: ScheduledReportsDataOut) => d.nodeStatus !== "DELETED";

export const ScheduledReportsTable = () => {
  const { previousData, data = previousData, loading, error } = useGetAllScheduledReportsQuery();
  const { t, i18n } = useTranslation();
  const columns = useMemo(() => getColumns(t, i18n.language), [t, i18n.language]);
  const reports = data?.getAllScheduledReports as ScheduledReportsDataOut[] | undefined;
  const [hideExpired, HideExpiredFilter] = useBooleanFilter("showActiveOnly", "report.sr.hideExpired", true);
  const filteredReports = useMemo(
    () =>
      (hideExpired
        ? reports?.filter((r) => activeFilter(r) && nodeStatusNotDeleted(r))
        : reports?.filter(nodeStatusNotDeleted)) ?? [],
    [hideExpired, reports],
  );

  return (
    <>
      <FilterBox>{HideExpiredFilter}</FilterBox>
      <Table
        tableName={TABLE_NAME}
        columns={columns}
        data={filteredReports}
        initialLoading={previousData === undefined}
        loading={loading}
        error={error}
        pageIndex={0}
        pageSize={filteredReports.length}
      />
    </>
  );
};
