// extend history to provide query params preservation ability
import { BrowserHistory, PartialPath, State, To } from "history";

import { ExtendedBroweserHistoryState } from "./types";

type PreserveQuery = true | ((currentSearch: string) => string);
export type PreserveQueryState = { preserveQuery?: PreserveQuery };

const preserveQueryParameters = (
  history: BrowserHistory,
  preserveQuery: PreserveQuery | undefined,
  location: PartialPath,
) => {
  if (preserveQuery) {
    location.search =
      typeof preserveQuery === "boolean" ? history.location.search : preserveQuery(history.location.search);
  } else {
    location.search = location.search || "";
  }

  return location;
};

export const withPreserveQuery = <T extends BrowserHistory>(
  history: T,
): ExtendedBroweserHistoryState<T, PreserveQueryState> => {
  const get =
    (fn: BrowserHistory["push"] | BrowserHistory["replace"]) => (path: To, state?: State & PreserveQueryState) =>
      fn(
        preserveQueryParameters(history, state?.preserveQuery, typeof path === "string" ? { pathname: path } : path),
        state,
      );

  // IMPORTANT Object.assign instead of spread - for history to get reference instead of new object
  return Object.assign(history, {
    push: get(history.push),
    replace: get(history.replace),
  });
};
