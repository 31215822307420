import tw, { css, styled, theme } from "twin.macro";
import "styled-components/macro";

import { VariantsByKeys, WithVariants, applyVariant } from "common/guideline/components/helpers";

import { FieldCommonsData, RawFieldData, fieldFactory } from "../schema";

import { TextLabel } from "./Common";

type CheckboxVariants = "sm";

export type CheckboxProps = FieldCommonsData &
  WithVariants<typeof variants> & {
    id?: string;
    type: "checkbox";
    disabled?: boolean;
    label?: string;
    indeterminate?: boolean;
  };

const variants: VariantsByKeys<CheckboxVariants> = {
  sm: css`
    & > div,
    svg {
      ${tw`w-4 h-4`}
    }
  `,
};

const StyledCheckbox = styled.label`
  ${tw`text-gray-6`}

  & > div {
    ${tw`bg-gray-2 border-gray-3 border w-6 h-6 rounded relative`}
  }

  &:focus-within {
    ${tw`text-primary-default`}

    & > div {
      ${tw`border-primary-default`}
    }
  }

  svg {
    ${tw`text-primary-default absolute rounded w-6 h-6 top-1/2 left-1/2`}
    transform: translate(-50%, -50%);
  }

  input[type="checkbox"] {
    appearance: none;

    &:focus {
      ${tw`border-primary-default outline-none`}
    }
  }

  ${applyVariant(variants)}
`;

export const CheckboxRaw: React.FC<RawFieldData<CheckboxProps>> = ({
  id,
  label,
  name,
  type = "checkbox",
  onChange,
  onBlur,
  onFocus,
  checked,
  error,
  indeterminate = false,
  ...rest
}) => (
  <StyledCheckbox tw="flex items-center" data-test={name} {...rest}>
    <div>
      <input
        id={id}
        checked={checked}
        name={name}
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        // eslint-disable-next-line
        // @ts-ignore
        indeterminate={indeterminate.toString()}
      />
      {checked ? (
        <svg viewBox="0 0 1000 1000">
          <path
            fill="currentColor"
            d="M990,89.5c0-43.9-35.6-79.5-79.5-79.5h-821C45.6,10,10,45.6,10,89.5v821c0,43.9,35.6,79.5,79.5,79.5h821c43.9,0,79.5-35.6,79.5-79.5V89.5L990,89.5z M792.4,423.5L499.8,716.2c-9.9,9.9-23.4,15.5-37.5,15.5s-27.5-5.6-37.5-15.5L240.6,531.9c-20.7-20.7-20.7-54.2,0-74.9c20.7-20.7,54.3-20.7,74.9,0l146.8,146.8l255.2-255.2c20.7-20.7,54.3-20.7,74.9,0C813.1,369.3,813.1,402.8,792.4,423.5L792.4,423.5z"
          />
        </svg>
      ) : indeterminate ? (
        <svg viewBox="0 0 1000 1000">
          <line
            x1="250"
            x2="750"
            y1="500"
            y2="500"
            strokeWidth="120"
            stroke={theme`colors.gray.7`}
            strokeLinejoin="round"
            strokeLinecap="round"
          />
        </svg>
      ) : null}
    </div>
    {label && (
      <TextLabel tw="p-0 pl-2" error={error}>
        {label}
      </TextLabel>
    )}
  </StyledCheckbox>
);

export const CheckboxField = fieldFactory(CheckboxRaw);
