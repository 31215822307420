export const es = {
  common: {
    dark: "Tema oscuro",
    light: "Tema claro",
    system: "Sistema",
    submit: "Enviar",
    next: "Siguiente",
    previous: "Previo",
    dismiss: "Descartar",
    pin: "Anclar este widget",
    unpin: "Desanclar este widget",
    nodeId: "ID de nodo",
    status: "Estado",
    default: "Por defecto",
    home: "Inicio",
    btnHome: "Llevame a casa",
    emptyTitle: "No se han encontrado resultados",
    emptyDescription: "Intenta ajustar tu busqueda o filtro para encontrar lo que buscas",
    pageError: "Ooops",
    returnHome:
      "Como has llegado aqui es un misterio. Pero puedes hacer clic en el boton de abajo para volver a la pagina de inicio.",
    all: "Todos",
    starting: "Empezando",
    currency: "Moneda",
    site: "Sitio",
    siteGroup: "Grupo de sitios",
    searchResults: "Resultados de la busqueda",
    personalized: "Personalizado",
    insights: "Perpectivas",
    machines: "Maquinas",
    edit: "Editar",
    viewDetails: "Ver detalles",
    title: "Titulo",
    department: "Departamento",
    role: "Funcion",
    myProfile: "Mi perfil",
    alerts: "Alertas",
    language: "Idioma",
    signOut: "Cerrar sesion",
    helloPopover: "Hola <0>{{name}}</0>",
    helloProfile: "Hola, {{name}}",
    actionMessage: "¿Que quieres hacer?",
    profileDesc: "Aqui puedes editar tu configuracion",
    verified: "Verificado",
    terms: "Terminos y condiciones",
    termsDesc: "Estos son los Terminos y Condiciones de uso de los productos PayCompleteâ„¢.",
    releaseNotes: "Notas de la version",
    releaseNotesDesc: "Funciones, mejoras, correcciones y otras actualizaciones de PayCompleteâ„¢ Connect.",
    help: "Ayuda",
    to: "Para:",
    from: "De:",
    yes: "Si",
    no: "No",
    confirm: "Confirmar",
    select: "Seleccionar",
    rPeriod: "Periodo del informe",
    rDate: "Fecha del informe",
    notGrouped: "No agrupado",
    overview: "Vision general",
    "404": "404",
    clipboard: "Texto copiado en el portapapeles",
    moreTypes: "{{count}} mas",
    close: "Cerrar",
    notAvailable: "N/A",
    popular: "Popular",
    table: {
      recentTransactions: "Transacciones recientes",
      pagination: {
        previous: "Anterior",
        next: "Siguiente",
        results_zero: "No se han encontrado resultados",
        results: "{{from}} - {{to}} de {{count}} resultados",
        perPage: "Resultados por pagina:",
        outOf: "fuera de",
        toggleAll: "Alternar todos",
      },
      filter: {
        filter: "Filtro",
        filters: "Filtros",
        reset: "Reiniciar",
        pastHour: "Hora pasada",
        pastDay: "Dia pasado",
        past7Days: "Ultimos 7 dias",
        past30Days: "Ultimos 30 dias",
        currentDay: "Dia Actual",
        currentWeek: "Semana actual",
        currentMonth: "Mes actual",
        previousDay: "Dia anterior",
        previousWeek: "Semana anterior",
        previousMonth: "Mes anterior",
        customRange: "Rango personalizado",
        equal: "Igual",
        between: "Entre",
        equalValue: "Igual <0>{{value}}</0>",
        fromValue: "Desde <0>{{from}}</0>",
        toValue: "a <0>{{to}}</0>",
        betweenValue: "Entre <0>{{from}}</0> y <0>{{to}}</0>",
        lowerTo: "Debe ser inferior a",
        higherFrom: "Debe ser mayor que desde",
        cannotBeEmpty: "No puede estar vacio",
      },
      sort: {
        sort: "Ordenar",
        sortBy: "Ordenar por",
        done: "Hecho",
        reset: "Reiniciar",
      },
      aggr: {
        sum: "{{value}} (total)",
        avg: "{{value}} (avg)",
      },
      selectedDays: "Seleccionar dias",
      noData: "Sin datos",
      nothingShow: "Nada que mostrar",
      error: "Error",
      failedToFetch: "Fallo en la obtencion de datos",
      groupBy: "Agrupar por",
      downloadCsv: "Descargar como CSV",
      printReport: "Imprimir informe",
      export: "Exportar",
      inTimeZone: "en zona horaria{{value}}",
      inLocalTime: "en la hora local de la maquina",
      dayStartsAt: "El dia empieza a las: {{value}}",
      dayEndsAt: "El dia termina en: {{value}}",
      weekStartsAt: "La semana empieza en: {{value}}",
    },
    form: {
      noOptions: "Sin datos",
      allSelected: "Todos los {{count}} selected",
      selectedItems: "{{count}} seleccionados",
      selectAll: "Seleccionar todo",
      deselectAll: "Deseleccionar todo",
      enterPhoneNo: "Introducir numero de telefono",
      err: {
        required: "Campo requerido",
        invalidDate: "Fecha no valida",
        invalidEmail: "Correo electronico no valido",
        invalidPhone: "Numero de telefono no valido",
        invalidDomain: "Dominio Invalido (e.j. dominio.com)",
      },
      upload: {
        image_one: "Subir imagen",
        image_other: "Subir imagenes",
        dropHere: "Suelta tus archivos aqui o",
        browse: "Examinar",
        removeAll: "Quitar todos",
        maxNumber: "No puede añadir mas archivos",
        maxFileSize: "El tamaño del archivo seleccionado supera el tamaño máximo",
        acceptType: "El tipo de archivo seleccionado no esta permitido",
        resolution: "El archivo seleccionado no coincide con la resolucion deseada",
      },
      transferBox: {
        included: "Icluido",
        notIncluded: "No incluido",
        emptyList: "Lista vacia",
        showFiltered: "Filtrado {{tamaño}} of {{tamaño completo}}",
        showAll: "Mostrando {{tamaño}} of {{tamaño completo}}",
        loading: "Cargando",
      },
    },
    remoteMgmt: {
      title: "Gestion remota (opcional)",
      selectOperation: "Seleccionar operacion",
      skip: "Omitir este paso",
      applyConfiguration: "Aplicar configuracion",
      upgradeSoftware: "Actualizar software",
      now: "Ahora",
      specifyDateAndHour: "Especificar fecha y hora",
      selectWhen: "Seleccionar cuando",
      dateAndTime: "Fecha y hora",
    },
    paymentIntervals: {
      monthly: "Mensual",
      quarterly: "Trimestral",
      annually: "Anual",
      selectPaymenInterval: "Seleccionar intervalo de pago",
    },
  },
};
