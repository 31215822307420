import { PartialPath, createPath } from "history";
import { forwardRef, useCallback, useMemo } from "react";
import { NavigateFunction, generatePath, matchRoutes, useLocation, useNavigate, useRoutes } from "react-router";
import { Link as LinkRouter, Navigate as NavigateRouter, matchPath } from "react-router-dom";

import { history } from "./history";
import { qs } from "./queryString";
import { RouteMiddleware } from "./RouteMiddleware";
import {
  LinkProps,
  NavigateArgs,
  NavigateProps,
  RouteConfig,
  RouteObjectExtended,
  RoutesDefault,
  UseNavigateToArgs,
} from "./types";

export const createRouting = <Routes extends RoutesDefault>() => {
  type RouteKeys = keyof Routes;

  let configRoutes = [] as RouteObjectExtended[];
  // paths joined with parent route paths
  const routesGlobalPaths = {} as Record<RouteKeys, { parsed: string; config: string }>;

  const getExtendedRouting = (routes: RouteObjectExtended[], prev = ""): RouteObjectExtended[] =>
    routes.map((r) => {
      const path = `${prev}${r.path}`;
      routesGlobalPaths[r.routeName as RouteKeys] = {
        parsed: path.replace("/*", ""),
        config: `${prev.replace("/*", "")}${r.path}`,
      };

      return {
        ...r,
        path,
        children: r.children ? getExtendedRouting(r.children, path) : r.children,
      };
    });

  const getPath = <K extends RouteKeys>(
    data: { route: K } & Pick<Routes[K], "params" | "search">,
  ): string | PartialPath => {
    let path = data.params
      ? generatePath(routesGlobalPaths[data.route].parsed, data.params)
      : routesGlobalPaths[data.route].parsed;

    if (data.search) {
      path = createPath({
        pathname: path,
        search: `?${qs.serialize(data.search)}`,
      });
    }

    return path;
  };

  const getPathGlobal = <K extends RouteKeys>(
    data: { route: K } & Pick<Routes[K], "params" | "search">,
    originPath = window.location.origin,
  ): string => `${originPath}${getPath(data)}`;

  const Link: <K extends RouteKeys>(props: LinkProps<Routes, K>) => JSX.Element | null = forwardRef(function _Link(
    props,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) {
    return <LinkRouter {...props} ref={ref} to={getPath(props)} />;
  });

  const NavLink: <K extends RouteKeys>(props: LinkProps<Routes, K>) => JSX.Element | null = forwardRef(function _Link(
    props,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) {
    const isActive = useIsRoute(props.route);
    const className = useMemo(
      () => (isActive ? `active ${props.className || ""}` : props.className),
      [isActive, props.className],
    );
    return <LinkRouter {...props} ref={ref} to={getPath(props)} className={className} />;
  });

  const Navigate: <K extends RouteKeys>(props: NavigateProps<Routes, K>) => JSX.Element = (props) => (
    <NavigateRouter to={getPath(props)} replace={props.replace} state={{ surpassBlocker: true, ...props.state }} />
  );

  const navigateToDelta = (delta: number, surpassBlocker?: boolean) => {
    if (surpassBlocker) history.unBlock();
    history.go(delta);
  };

  const navigateTo = <K extends RouteKeys>(
    args: NavigateArgs<Routes, K> | Parameters<typeof navigateToDelta>,
    navigateFN?: NavigateFunction,
  ): void => {
    if (Array.isArray(args)) {
      navigateToDelta(...args);
      return;
    }

    const path = getPath(args);

    if (navigateFN) {
      navigateFN(path, args);
    } else {
      history[args.replace ? "replace" : "push"](path, args.state);
    }
  };

  const useNavigateTo = () => {
    const navigate = useNavigate();

    return useCallback(
      <K extends RouteKeys>(...[args, surpassBlocker]: Parameters<UseNavigateToArgs<Routes, K>>) => {
        if (typeof args === "number") {
          if (surpassBlocker) history.unBlock();

          navigate(args);
        } else {
          navigateTo(args, navigate);
        }
      },
      [navigate],
    );
  };

  const getMatchRoutes = ({
    previousRoute,
    pathname = previousRoute ? history.getPreviousRoute()?.location.pathname : history.location.pathname,
  }: { pathname?: string; previousRoute?: boolean } = {}) =>
    pathname === undefined ? null : matchRoutes(configRoutes, pathname);

  const isRoute = <T extends RouteKeys>(route: T | T[], previousRoute?: boolean): boolean => {
    const pathname = previousRoute ? history.getPreviousRoute()?.location.pathname : history.location.pathname;
    return pathname === undefined
      ? false
      : Array.isArray(route)
      ? route.some((r) => !!matchPath(routesGlobalPaths[r].config, pathname))
      : !!matchPath(routesGlobalPaths[route].config, pathname);
  };

  const useIsRoute = <T extends RouteKeys>(route: T | T[], previousRoute?: boolean): boolean => {
    const location = useLocation();
    // location.pathname is dep to refresh isRoute check
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => isRoute(route, previousRoute), [location.pathname, route, previousRoute]);
  };

  const getRoutingComponent = <K,>(PATHS: RouteConfig<K>) => {
    const getConfig = (route: keyof K, children?: RouteObjectExtended[]): RouteObjectExtended => {
      const { path, Component, middleware, caseSensitive } = PATHS[route];

      return {
        routeName: route as string,
        children,
        path,
        caseSensitive,
        element: middleware ? <RouteMiddleware middlewares={middleware} Component={Component} /> : <Component />,
      };
    };

    return (
      fn: (
        getConfig: (route: keyof K, children?: RouteObjectExtended[]) => RouteObjectExtended,
      ) => RouteObjectExtended[],
    ) => {
      configRoutes = getExtendedRouting(fn(getConfig));
      return () => useRoutes(configRoutes);
    };
  };

  return {
    navigateTo,
    useNavigateTo,
    isRoute,
    useIsRoute,
    Navigate,
    Link,
    NavLink,
    getPathGlobal,
    getPath,
    getMatchRoutes,
    getRoutingComponent,
  };
};
