export const fr = {
  administration: {
    title: "Administration",
    overview: "Aperçu de l'administration",
    description:
      " Effectuer des tâches administratives, telles que la gestion des utilisateurs, la modification de votre code PIN, l'accès à la vue de l'opérateur et la modification des unités de travail ",
    allocations: "Affectations",
    allocationsDescription: "Associer une caisse à une unité de travail, telle qu'une caisse enregistreuse",
    machines: "Machines",
    machinesDescription: "Visualiser, gérer et créer des machines ",
    locations: "Sites",
    locationsDescription: "Visualiser, gérer et créer des sites",
    roles: "Rôles",
    rolesDescription: "Visualiser, gérer et créer des rôles",
    permissions: "Permissions",
    permissionsDescription: "Visualiser, gérer et créer des permissions",
    dispenseLimits: "Limites de distribution",
    dispenseLimitsDescription: "Limiter la quantité d'argent qui peut être distribuée par une machine",
    posTransactions: "Transactions des TPV",
    posTransactionsDescription:
      "Le terminal point de vente (TPV) est le moment et le lieu où s'effectue une transaction de vente au détail",
    configTemplates: "Modèles de configuration",
    configTemplatesDescription:
      "Exporter les paquets de Connect on-Device™ Studio vers le système de fichiers de votre ordinateur, et importer les comme modèles de configuration dans Connect",
    countries: "Pays",
    country: "Pays",
    selectCountries: "Sélectionner le pays",
    cities: "Villes",
    city: "Ville",
    selectCities: "Sélectionner la ville",
    mg: {
      title: "Groupes de machines",
      name: "Nom",
      description: "Visualiser, gérer et créer des groupes de machines",
      create: "Créer un groupe de machines",
      createDescription: "Créer un groupe de machines pour rassembler les machines en une entité logique",
      update: "Mettre à jour le groupe de machines",
      updateDescription: "Mise à jour un groupe de machines pour y apporter des modifications",
      delete: "Supprimer le groupe de machines",
      deleteDescription: "Supprimer un groupe de machines quand vous n’avez plus besoin de ce groupe de machines",
      deleteClose: "Fermer",
      nodeChangeDate: "Mise à jour",
      nodeStatus: "Statut",
      nodeStatusCount: "Nombre de statuts",
      machines: "Machines",
      availableMachines: "Machines disponibles",
      nodeId: "Identifiant de Noeud",
      machineNodeIds: "Machine node IDs",
      machineNodeId: "Machine node ID",
      success: "Groupe de machines créé avec succès",
      error: "La création du groupe de machine a échoué",
      updateSuccess: "Groupe de machines mis à jour avec succès",
      updateApiError: "La mise à jour du groupe de machines a échoué",
      deleteSuccess: "Groupe de machines supprimé avec succès",
      deleteError: "La suppression du groupe de machines a échoué",
      goBack: "Retour",
      namesCount: "Quantité de noms",
    },
    lg: {
      title_one: "Groupe de sites",
      title_other: "Groupes de sites",
      desc: "Aperçu des groupes de sites - vous pouvez visualiser, créer, mettre à jour et supprimer des groupes de sites",
      create: "Créer un groupe de sites",
      createDescription: "Créer un groupe de sites pour gérer les sites connexes",
      update: "Mise à jour d’une groupe de sites",
      updateDescription: "Mise à jour un groupe de sites pour y apporter des modifications",
      delete: "Delete site group",
      deleteDescription: "Supprimer un groupe de sites quand vous n’avez plus besoin de ce groupe de sites",
      deleteClose: "Fermer",
      name: "Nom du groupe de sites",
      siteGroupId: "Identifiant du groupe de sites",
      sites: "Sites",
      includedSites: "Sites inclus",
      nodeStatus: "Statut",
      nodeStatusCount: "Nombre de Statuts",
      nodeChangeDate: "Modifié",
      success: "Groupe de sites créé avec succès",
      error: "La création du groupe de sites a échoué",
      updateSuccess: "Groupe de sites mis à jour avec succès",
      updateApiError: "La mise à jour du groupe de sites a échoué",
      deleteSuccess: "Groupe de sites supprimé avec succès",
      deleteError: "La mise à jour du groupe de sites a échoué",
      goBack: "Retour",
      list: {
        noData: "Aucun groupe de sites trouvé",
      },
      namesCount: "Nombre de noms",
      authorizedUsers: "Utilisateurs autorisés",
      count: "Quantité",
      noCountriesFound: "Pas de pays trouvés",
      noCitiesFound: "Pas de villes trouvées",
    },
    sp: {
      title: "Paquets logiciels machine",
      selectSoftwarePackage: "Sélectionner le paquet logiciel machine",
      desc: "Vue d'ensemble des paquets logiciels machine disponibles dans Connect et lancement de l'assistant de création de paquets logiciels à partir duquel vous pouvez télécharger de nouveaux logiciels vers les machines",
      name: "Nom",
      placeholder: "Entre le nom du logiciel machine",
      tooltip:
        "Utilisez le nom pour donner au fichier .zip du logiciel un nom facile à utiliser. Le nom peut comporter au maximum 30 caractères, espaces compris",
      info: "Info",
      version: "Version",
      revision: "Révision",
      file: "Fichier",
      release: "Livraison",
      createdBy: "Créé par",
      createdDate: "Date de création",
      description: "Description",
      releaseInfo: "Information de version",
      official: "Faire de ce logiciel une livraison officielle",
      custom: "Spécifique",
      label: "Libellé",
      fileRequired: "Vous devez choisir un fichier",
      addSp: "Créer un paquet logiciel",
      submit: "Sauvegarder et télécharger",
      update: "Mettre à jour le paquet",
      import: "Sélectionner le fichier",
      changeFile: "Changer de fichier",
      uploadSuccess: "Paquet logiciel téléchargé avec succès ",
      uploadFailure: "Une erreur est survenu pendant le téléchargement du fichier ",
      addSuccess: "Paquet logiciel créé avec succès ",
      addApiError: "La création du paquet logiciel a échoué ",
      err: {
        _1: "Erreur inconnue. Veuillez réessayer plus tard",
        _2: "Doit être un fichier .zip",
        _3: "Le fichier sélectionné semble ne pas être un paquet logiciel",
        _4: "Informations manquantes ou invalides dans le fichier sélectionné",
      },
      missingPreSignedUrl: "Could not obtain presigned url",
      delete: "Suppression du paquet logiciel",
      deleteDescription: "Le paquet logiciel et le fichier téléchargé seront supprimés ",
      deleteError: "La suppression du paquet logiciel a échoué ",
      deleteSuccess: "Paquet logiciel supprimé avec succès",
      deleteClose: "Fermer",
    },
    rc: {
      title: "Modèles de configuration",
      selectTemplate: "Sélectionner le modèle",
      desc: "Vue d'ensemble des modèles de configuration. Vous pouvez afficher, créer, télécharger, et mettre à jour des modèles de configuration.",
      name: "Nom",
      info: "Info",
      version: "Version",
      createdBy: "Créé par",
      createdDate: "Date de création",
      description: "Description",
      file: "Fichier",
      fileRequired: "Vous devez choisir un fichier",
      configurationFile: "Fichier de configuration",
      addRc: "Créer un modèle de configuration",
      submit: "Sauvegarder et télécharger",
      update: "Mettre à jour un modèle",
      import: "Importer un modèle",
      changeFile: "Changer de fichier",
      included: "Inclus",
      addSuccess: "Modèle de configuration créé avec succès",
      addApiError: "La création du modèle de configuration a échoué",
      saveTameplateFailure: "Une erreur est survenue pendant la sauvegarde du modèle",
      uploadSuccess: "Modèle de configuration téléchargé avec succès",
      uploadFailure: "Une erreur est survenue pendant le téléchargement du modèle",
      missingPreSignedUrl: "Could not obtain presigned url",
      updateSuccess: "Modèle de configuration mis à jour avec succès",
      updateError: "Un erreur est survenue pendant la mise à jour du modèle de configuration",
      configuration: "Configuration",
      delete: "Suppression du modèle de configuration",
      deleteDescription: "Le modèle de configuration et le fichier téléchargé seront supprimés ",
      deleteError: "La suppression du modèle de configuration a échoué",
      deleteSuccess: "Modèle de configuration supprimé avec succès",
      deleteClose: "Fermer",
      receipts: "Reçus",
      i18n: "i18n",
      themes: "Thèmes",
      sdk: "Sdk",
      scripts: "Scripts",
      help: "Aide",
      err: {
        _1: "Erreur inconnue. Veuillez réessayer plus tard",
        _2: "Doit être un fichier .zip",
        _3: "Le fichier sélectionné semble ne pas être un modèle de configuration",
        _4: "Informations manquantes ou invalides dans le fichier sélectionné",
      },
    },
    la: {
      title: "Allocation des lignes",
      description:
        "Permet de bloquer des unités de travail pour distribuer plus d'un MoneyMix à la fois sur une sélection de machines.",
      allocationId: "ID d'Allocation",
      workUnit: "Unité de travail",
      allocationTime: "Heure d'allocation",
      expiration: "Expiration",
      deactivate: "Désactiver",
      deactivateDescription: "Désactiver l'allocation des lignes?",
      deactivateSuccess: "Allocation des lignes désactivée avec succès",
      deactivateError: "Échec de la désactivation de l'allocation des lignes",
    },
  },
  machineUserGroup: {
    title_one: "Groupe d’utilisateurs machine",
    title_other: " Groupes d’utilisateurs machine ",
    desc: "Fournir un moyen dynamique de gérer les utilisateurs, en fonction de l'accès et de la fonctionnalité.",
    name: "Nom",
    authorizedMachines: "Machines autorisées",
    deleteGroup: "Supprimer le groupe",
    deleteDesc: "Le groupe <0>{{name}}</0> sera supprimé, cette action ne peut être annulée.",
    addMachineUserGroups: "Créer un groupe d’utilisateurs machine",
    addSuccess: "Groupe d’utilisateurs machine supprimé avec succès",
    addError: "La suppression du groupe d’utilisateurs machine a échoué",
    updateSuccess: "Groupe d’utilisateurs machine mis à jour avec succès",
    updateError: "La suppression du groupe d’utilisateurs machine a échoué",
    create: "Créer",
    update: "Mettre à jour",
    updateName: "Mise à jour du nom du groupe",
    deleteSuccess: "Le groupe d'utilisateurs machine a été supprimé avec succès",
    deleteError: "La suppression du groupe d'utilisateurs machine a échoué",
    addMachineUsers: "Ajouter des utilisateurs machine",
    removeMachineUsers: "Supprimer des utilisateurs machine",
    addMachines: "Ajouter des machines",
    removeMachines: "Supprimer des machines",
    showMachineExclusive: "Affichier les groupes de machines exclusifs",
  },
};
