import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { getSelectMachineUserGroupField } from "administration/components/getSelectMachineUserGroupField";
import { FormData, OnSubmit, SchemaForm } from "common/form";
import { CustomRenderFields, customRender } from "common/form/renderFields";
import { Button, LoadingButton, Text, useModalComponent, useToast } from "common/guideline";
import { evictQuery } from "common/helpers";
import {
  FindAllMachineUserGroupsFilteredCountsDocument,
  FindAllMachineUsersFilteredDocument,
  FindUserBalancesFilteredDocument,
  MachineUserXmlImport,
  useImportMachineUsersMutation,
} from "generated";

import { UsersFileSelection } from "./UsersFileSelection";

export type UploadUsersFormData = MachineUserXmlImport;

const Wrapper = styled.div`
  ${tw`grid gap-4 grid-flow-row pt-4`}
`;

const fields: CustomRenderFields[] = [
  {
    type: "container",
    Component: Wrapper,
    fields: [
      getSelectMachineUserGroupField({ name: "machineUserGroupNodeId" }),
      {
        name: "usersXml",
        type: "custom",
        Component: UsersFileSelection,
        validate: { type: "string", required: [true, "mu.noFileSelected"] },
      },
      {
        name: "replace",
        type: "checkbox",
        defaultValue: false,
        label: "mu.replaceUsers",
      },
    ],
  },
];

type Props = { onSubmit: OnSubmit<UploadUsersFormData> };

const UploadForm: React.FC<Props> = ({ onSubmit }) => (
  <>
    <Text variant="heading" tKey="mu.uploadUsers" tw="pb-4 block" />
    <div tw="h-full overflow-x-hidden">
      <SchemaForm<UploadUsersFormData>
        fields={fields}
        onSubmit={onSubmit}
        customRender={customRender}
        SubmitComponent={() => (
          <div tw="flex justify-end">
            <FormData type="isSubmittig">
              {(isLoading) => (
                <LoadingButton
                  type="submit"
                  data-test="submitForm"
                  tw="mt-6"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  <Text tKey="mu.uploadUsers" />
                </LoadingButton>
              )}
            </FormData>
          </div>
        )}
      />
    </div>
  </>
);

export const UploadUsersForm = () => {
  const showToast = (error: boolean) =>
    useToast.actions.show(error ? "mu.uploadFailure" : "mu.uploadSuccess", {
      variant: error ? "error" : "success",
    });

  const [uploadUsers] = useImportMachineUsersMutation();
  const [toggle] = useModalComponent({
    modalProps: { variant: ["modal", "md"] },
    Component: (
      <UploadForm
        onSubmit={(machineUserImport) => {
          const progressToastId = useToast.actions.show("mu.uploadInProgress", {
            variant: "info",
            timeout: 0,
          });

          return uploadUsers({
            variables: { machineUserImport },
            ignoreResults: true,
            update: (cache) => {
              evictQuery(FindUserBalancesFilteredDocument, cache);
              evictQuery(FindAllMachineUsersFilteredDocument, cache);
              evictQuery(FindAllMachineUserGroupsFilteredCountsDocument, cache, {
                getInnerOperationName: true,
              });
            },
          })
            .then((v) => {
              useToast.actions.delete(progressToastId);
              showToast(!(v.data?.importMachineUsers === true));
              toggle();
            })
            .catch(() => {
              useToast.actions.delete(progressToastId);
              showToast(true);
            });
        }}
      />
    ),
  });

  return (
    <Button onClick={() => toggle()} data-test="showFormModal">
      <Text tKey="mu.uploadUsers" />
    </Button>
  );
};
