import tw from "twin.macro";
import "styled-components/macro";

import { PieChart } from "components/Charts";

import { Box } from "./Box";
import { BoxHeading } from "./BoxHeading";

const data = [
  {
    x: "Online",
    y: 105,
  },
  {
    x: "Offline",
    y: 229,
  },
];

export const ConnectionStatus = () => (
  <Box>
    <div tw="flex flex-col">
      <BoxHeading>Connection status</BoxHeading>
      <div tw="h-52">
        <PieChart data={data} />
      </div>
    </div>
  </Box>
);
