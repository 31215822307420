export const es = {
  auth: {
    signIn: "Iniciar sesion",
    resetPassword: "Restablecer contraseña",
    forgot: "se olvido",
    username: "Usuario",
    password: "Contraseña",
    or: "o",
    err: {
      invalid_grant: "Usuario o contraseña incorrecto",
    },
  },
};
