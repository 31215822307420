import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as MachineDetailsSVG } from "assets/icons/MachineDetails.svg";
import { ReactComponent as MachineErrorsSVG } from "assets/icons/MachineErrors.svg";
import { ReactComponent as MachineManagementSVG } from "assets/icons/MachineManagement.svg";
// import { ReactComponent as RefillReportSVG } from "assets/icons/MachineRefill.svg";
// import { ReactComponent as MachineReliabilitySVG } from "assets/icons/MachineReliability.svg";
import { ReactComponent as OnlineStatusSVG } from "assets/icons/OnlineStatus.svg";
import { PageLayout } from "base/components";
import { Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const MachineManagement = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="machineManagementOverview"
    subtitle="report.machineManagementDescription"
    Icon={MachineManagementSVG}
  >
    <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
      <Link route="MACHINE_ERRORS">
        <Box>
          <BoxHeading>
            <Text tKey="machineErrors" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineErrorsSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MACHINE_DETAILS">
        <Box>
          <BoxHeading>
            <Text tKey="report.md.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <MachineDetailsSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="ONLINE_STATUS">
        <Box>
          <BoxHeading>
            <Text tKey="mos.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <OnlineStatusSVG />
          </BoxContent>
        </Box>
      </Link>
      {/* TODO apply report link to boxes below, once report will be done
      <Box>
        <BoxHeading>
          <Text tKey="machineReliability" />
        </BoxHeading>
        <BoxContent variant="iconWrapper">
          <MachineReliabilitySVG />
        </BoxContent>
      </Box>
      <Box>
        <BoxHeading>
          <Text tKey="refillReport" />
        </BoxHeading>
        <BoxContent variant="iconWrapper">
          <RefillReportSVG />
        </BoxContent>
      </Box> */}
    </div>
  </PageLayout>
);
