import { CellContext, DisplayColumnDef } from "@tanstack/react-table";

import { BooleanCell } from "./BooleanCell";
import { ExpandRowCell } from "./ExpandRowCell";
import { SelectRowCell } from "./SelectRowCell";

// enableSorting must be specified as false in type - for custom sorting types
const expander: DisplayColumnDef<any> & { enableSorting: false } = {
  id: "expander",
  enableSorting: false,
  enableColumnFilter: false,
  enableHiding: false,
  cell: ExpandRowCell,
  size: 60,
  minSize: 60,
  maxSize: 60,
  meta: {
    hideVertical: true,
    pin: "left",
  },
};

const getActionsCell: <T>(options: {
  cell: (ctx: CellContext<T, unknown>) => any;
  size?: number;
}) => DisplayColumnDef<T> & { enableSorting: false } = ({ cell, size = 100 }) => ({
  header: "",
  id: "__actions__",
  enableHiding: false,
  enableSorting: false,
  size,
  meta: {
    hideVertical: true,
    pin: "right",
  },
  cell(ctx) {
    return ctx.row.getIsGrouped() ? null : cell(ctx);
  },
});

const selector: DisplayColumnDef<any> & { enableSorting: false } = {
  id: "expander",
  enableSorting: false,
  enableColumnFilter: false,
  enableHiding: false,
  cell: SelectRowCell,
  size: 60,
  minSize: 60,
  maxSize: 60,
  meta: {
    hideVertical: true,
    pin: "left",
  },
};

export const CommonCells = {
  expander,
  getActionsCell,
  BooleanCell,
  selector,
};
