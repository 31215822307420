import { SelectLiveFieldValueArg, getSelectField } from "base/fields";

const options: { value: string; label: string }[] = [
  { value: "configuration", label: "common.remoteMgmt.applyConfiguration" },
  { value: "skip", label: "common.remoteMgmt.skip" },
  { value: "software", label: "common.remoteMgmt.upgradeSoftware" },
];

export const getRemoteMgmtOptions = (values: SelectLiveFieldValueArg) =>
  getSelectField({
    label: "common.remoteMgmt.selectOperation",
    options,
    ...values,
  });
