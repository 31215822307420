import { CaretDoubleLeft, CaretDoubleRight, CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { throttle } from "common/helpers";

import { Button } from "../../Button";

export const TableArrowsScroll = ({
  children,
}: {
  children: (
    showButtons: boolean,
    tableRef: React.Ref<HTMLDivElement>,
    headerRef: React.Ref<HTMLDivElement>,
  ) => React.ReactNode;
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const [disabled, setDisabled] = useState({
    showButtons: true,
    leftButtons: true,
    rightButtons: false,
  });

  const scrollToLeft = () => {
    const left = 0;
    tableRef.current?.scrollTo({ left, behavior: "smooth" });
  };
  const scrollToRight = () => {
    if (!tableRef.current) return;
    const left = tableRef.current.offsetWidth + tableRef.current.scrollWidth;
    tableRef.current?.scrollTo({ left, behavior: "smooth" });
  };

  const getScrollOffsets = () => {
    if (!headerRef.current) return [];

    return Array.from(headerRef.current.children).reduce(
      (acc, curr) => {
        acc.push(acc[acc.length - 1] + curr.clientWidth);
        return acc;
      },
      [0],
    );
  };

  const scrollOneToLeft = () => {
    if (!tableRef.current) return;

    const offsets = getScrollOffsets().reverse();
    const scrollLeft = tableRef.current.scrollLeft;

    for (const offset of offsets) {
      if (offset < scrollLeft) {
        tableRef.current.scrollTo({ left: offset, behavior: "smooth" });
        break;
      }
    }
  };
  const scrollOneToRight = () => {
    if (!tableRef.current) return;

    const offsets = getScrollOffsets();
    const scrollLeft = tableRef.current.scrollLeft;

    for (const offset of offsets) {
      if (offset > scrollLeft) {
        tableRef.current.scrollTo({ left: offset, behavior: "smooth" });
        break;
      }
    }
  };

  useEffect(() => {
    const minOverScroll = 128; // in px
    const tableRefElement = tableRef.current;

    const _onScroll = () => {
      if (!tableRefElement) return;
      const leftOffset = tableRef.current.scrollLeft;
      const rightOffset = tableRef.current.scrollWidth - tableRef.current.offsetWidth - leftOffset;

      const showButtons = leftOffset + rightOffset > minOverScroll;
      const nextState = {
        showButtons,
        leftButtons: leftOffset === 0,
        rightButtons: rightOffset === 0,
      } as const;

      setDisabled((prev) =>
        prev.showButtons === showButtons &&
        prev.leftButtons === nextState.leftButtons &&
        prev.rightButtons === nextState.rightButtons
          ? prev
          : nextState,
      );
    };

    _onScroll();

    const onScroll = throttle(_onScroll, 100);

    window.addEventListener("resize", onScroll);
    tableRefElement?.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("resize", onScroll);
      tableRefElement?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div tw="flex flex-row overflow-y-hidden flex-1">
      {disabled.showButtons ? (
        <div tw="flex z-[1] backdrop-blur-[1px]">
          <Button
            tw="w-8 opacity-50 disabled:opacity-20 rounded-none"
            onClick={scrollToLeft}
            disabled={disabled.leftButtons}
          >
            <span>
              <CaretDoubleLeft size={24} weight="bold" />
            </span>
          </Button>
          <Button
            tw="w-8 opacity-50 disabled:opacity-20 rounded-none"
            onClick={scrollOneToLeft}
            disabled={disabled.leftButtons}
          >
            <span>
              <CaretLeft size={24} weight="bold" />
            </span>
          </Button>
        </div>
      ) : null}
      {children(disabled.showButtons, tableRef, headerRef)}
      {disabled.showButtons ? (
        <div tw="flex z-[1] backdrop-blur-[1px]">
          <Button
            tw="w-8 opacity-50 disabled:opacity-20 rounded-none"
            onClick={scrollOneToRight}
            disabled={disabled.rightButtons}
          >
            <span>
              <CaretRight size={24} weight="bold" />
            </span>
          </Button>
          <Button
            tw="w-8 opacity-50 disabled:opacity-20 rounded-none"
            onClick={scrollToRight}
            disabled={disabled.rightButtons}
          >
            <span>
              <CaretDoubleRight size={24} weight="bold" />
            </span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};
