import { Eye } from "@phosphor-icons/react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { Button, Container, Text } from "common/guideline";

const WrapperOpacity = styled.div`
  ${tw`h-full transition-opacity duration-300`}
`;

const Wrapper = styled(Container)`
  ${tw`relative`}
  &:hover ${WrapperOpacity} {
    opacity: 0.35;
    filter: blur(1px);
  }
`;

export const Box: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Wrapper variant="card">
    <WrapperOpacity>{children}</WrapperOpacity>
    <div tw="flex items-center justify-center transition-all duration-300 absolute top-0 bottom-0 left-0 right-0 h-full w-full opacity-0 hover:opacity-100">
      <Button variant={["primary", "withIcon"]}>
        <Eye size={16} weight="duotone" />
        <Text tKey="common.viewDetails" />
      </Button>
    </div>
  </Wrapper>
);
