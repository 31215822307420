import { Field, FieldProps } from "../../field";

type FactoryProps<T> = Omit<T, "value" | "checked" | "onChange" | "onFocus" | "onBlur"> &
  FieldProps<any, Record<string, unknown>>;

export const fieldFactory = <T, K extends FactoryProps<T>>(
  Component: React.FC<T> | FieldProps<any, Record<string, unknown>>["as"],
) =>
  function FieldWrapper(props: K) {
    return <Field as={Component as React.FC<any>} {...props} />;
  };

export const CommonField = fieldFactory("input");
