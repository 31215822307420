import { useState } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { SelectProps, SelectRaw } from "common/form/renderFields";

export const useSelectFilter = <T extends string>(
  props?: Partial<
    Omit<SelectProps, "options"> & { initial: T; options: { value: T; label: string }[]; isMulti?: boolean }
  >,
): [T | undefined, JSX.Element] => {
  const [value, setValue] = useState(props?.initial);

  return [
    value,
    <SelectRaw key={`selectFilter_${props?.name ?? ""}`} name="select" value={value} onChange={setValue} {...props} />,
  ];
};

export const useSelectMultiFilter: <T extends string>(
  props?: Partial<Omit<SelectProps, "options"> & { initial: T[]; options: { value: T; label: string }[]; tw: string }>,
) => [T[] | undefined, JSX.Element] = (props) => useSelectFilter({ ...props, isMulti: true } as any) as any;
