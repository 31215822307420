import { RouteConfig, RouteValue } from "routing";

import { Insights } from "./pages/Insights/Insights";
import { Personalized } from "./pages/Personalized/Personalized";

export type PersonalizedPaths = {
  PERSONALIZED: RouteValue;
  INSIGHTS: RouteValue;
};

export const PERSONALIZED: RouteConfig<PersonalizedPaths> = {
  INSIGHTS: {
    path: "/personalized/insights",
    Component: Insights,
  },
  PERSONALIZED: {
    path: "/personalized",
    Component: Personalized,
  },
};
