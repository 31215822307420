import { useMemo } from "react";
import tw from "twin.macro";
import "styled-components/macro";

import { useField, useFormContext } from "common/form";
import { TextLabel } from "common/form/renderFields";
import { Button, Text } from "common/guideline";
import { numberRange } from "common/helpers";

const monthDays = numberRange(1, 31);
const leapDays = [29, 30, 31];

type FieldMonthlyProps = React.PropsWithChildren<{
  name: string;
}>;

const LeapDaysWarnText: React.FC<{ value: number | undefined }> = ({ value }) => {
  const warn = useMemo(() => (value ? leapDays.includes(value) : false), [value]);
  return warn ? <Text tw="pb-3" variant="warning" tKey="common.form.daysWarning" /> : null;
};

export const LastDayOfMonthToggle: React.FC<{ name: string; monthDaysFieldName: string }> = ({
  name,
  monthDaysFieldName,
}) => {
  const { useStore } = useFormContext();
  const field = useField<boolean>(name);
  const isSelected = field.value === true;

  return (
    <Button
      variant={isSelected ? "primary" : "side"}
      aria-selected={isSelected}
      onClick={() => {
        field.onChange(!isSelected);
        useStore.getState().onChangeField(monthDaysFieldName, "onChange", []);
      }}
    >
      <Text tKey="common.form.lastMonthDay" />
    </Button>
  );
};

export const FieldMonthly: React.FC<FieldMonthlyProps> = ({ name }) => {
  const field = useField<number | undefined>(name, { errorSub: true });

  return (
    <div>
      <div tw="grid grid-cols-7 gap-2">
        {monthDays.map((day) => {
          const selected = field.value === day;

          return (
            <Button
              key={day}
              variant={selected ? "primary" : "side"}
              aria-selected={selected}
              onClick={() => field.onChange(day)}
            >
              {day}
            </Button>
          );
        })}
      </div>

      <div tw="flex flex-col pt-3">
        <LeapDaysWarnText value={field.value} />
      </div>
      <div tw="flex flex-col pt-3">
        {field.error && <TextLabel error variant="label" tKey="common.form.err.required" />}
      </div>
    </div>
  );
};
