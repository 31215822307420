import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as ComputerTowerSVG } from "assets/icons/ComputerTower.svg";
import { PageLayout } from "base/components";
import { Breadcrumb, Button, Text } from "common/guideline";

import { MachineTable } from "./MachineTable/MachineTable";

const breadcrumbs: Breadcrumb[] = [
  {
    text: "common.home",
    route: "HOME",
  },
  {
    text: "administration.title",
    route: "ADMINISTRATION",
  },
  {
    text: "administration.machines",
    route: "MACHINE_LIST",
  },
];

export const MachineList = () => (
  <PageLayout
    breadcrumbs={breadcrumbs}
    title="administration.machines"
    subtitle="administration.machinesDescription"
    Icon={ComputerTowerSVG}
    actions={
      <Link route="MACHINE_CREATE">
        <Button>
          <Text tKey="machine.createMachine" />
        </Button>
      </Link>
    }
  >
    <MachineTable />
  </PageLayout>
);
