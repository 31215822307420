import { Locale, format, formatDuration, intervalToDuration, parse } from "date-fns";
import { de, enUS, es, fr } from "date-fns/locale";
import { FormatFunction, KeyPrefix, Languages, Namespace } from "i18next";
import { useMemo } from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";

import { CONFIG } from "common/config";
import { customFormatters } from "common/helpers";

import { useDateFormatsStore } from "./dateFormatsStore";

type DefaultNamespace = "translation";

const getDateLocale = (lang: Languages): Locale => {
  switch (lang) {
    case "de-DE":
      return de;
    case "en-US":
      return enUS;
    case "es-ES":
      return es;
    case "fr-FR":
      return fr;
  }
};

type Options = {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
  lang: Languages | string;
  value?: number;
};

type DateI18n = {
  format: (date: Date | number, formatString: string, options: Options) => string;
  parse: (dateString: string, formatString: string, referenceDate: Date | number, options: Options) => Date;
};

const dateI18n: DateI18n = {
  format: (date, formatString, options) =>
    format(date, formatString, { ...options, locale: getDateLocale(options.lang as Languages) }),
  parse: (dateString, formatString, referenceDate, options) =>
    parse(dateString, formatString, referenceDate, { ...options, locale: getDateLocale(options.lang as Languages) }),
};

export const useTranslationWithLocale = <
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>(): [Locale, UseTranslationResponse<N, TKPrefix>] => {
  const translation = useTranslation<N, TKPrefix>();
  const locale = useMemo(() => getDateLocale(translation.i18n.language as Languages), [translation.i18n.language]);

  return [locale, translation];
};

const FORMAT_START = "dateFormat";

const formatDurationFromSeconds = (seconds: number, lang: Languages) =>
  formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), { locale: getDateLocale(lang) });

export const dateFormat: FormatFunction = (value, formatter, lang, options) => {
  if (formatter === FORMAT_START) {
    const _format = options?.formatString;

    if (!CONFIG.isProd && !_format) {
      throw new Error("must provide format string");
    }

    if (_format === "duration") {
      return formatDurationFromSeconds(options?.value ?? 0, lang as Languages);
    }
    if (_format === "PPp") {
      return dateI18n.format(value, useDateFormatsStore.getState().localDateTime, { lang: lang as Languages });
    }
    if (_format === "PPpp") {
      return dateI18n.format(value, useDateFormatsStore.getState().localDateTimeWithSeconds, {
        lang: lang as Languages,
      });
    }
    if (_format === "PPperiod") {
      return customFormatters.PPperiod(value, getDateLocale(lang as Languages), useDateFormatsStore.getState().hour12);
    }
    if (_format === "PPp_period") {
      return customFormatters.PPp_period(
        value,
        getDateLocale(lang as Languages),
        useDateFormatsStore.getState().hour12,
        useDateFormatsStore.getState().localDateTime,
      );
    }

    return dateI18n.format(value, _format, { lang: lang as Languages });
  }

  return value;
};
