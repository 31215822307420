import { createContext, useContext, useEffect, useState } from "react";

import { isDeepEqual } from "common/helpers";

type Breakpoints = {
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  "2xl": boolean;
};

type BreakpointState = {
  min: Breakpoints;
  max: Breakpoints;
};

type BreakpointKeys = keyof Breakpoints;

const SIZES = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

const SCREENS = Object.entries(SIZES) as [BreakpointKeys, number][];

const getBreakpointState = (width: number): BreakpointState => {
  const min = Object.fromEntries(SCREENS.map(([name, value]) => [name, width >= value])) as Breakpoints;
  const max = Object.fromEntries(SCREENS.map(([name, value]) => [name, width <= value])) as Breakpoints;

  return {
    min,
    max,
  };
};

const initial = getBreakpointState(window.screen.width);

const BreakpointsContext = createContext(initial);

export const useBreakpoints = () => useContext(BreakpointsContext);

export const breakpointBetween = (min: keyof Breakpoints, max: keyof Breakpoints, breakpoints: BreakpointState) =>
  breakpoints.min[min] && breakpoints.max[max];

export const screen = {
  min: (name: BreakpointKeys) => `@media (min-width: ${SIZES[name]}px)`,
  max: (name: BreakpointKeys) => `@media (max-width: ${SIZES[name]}px)`,
};

export const BreakpointsProvider = ({ children }) => {
  const [breakpoints, setBreakpoints] = useState<BreakpointState>(initial);

  useEffect(() => {
    const handleResize = () => {
      const newState = getBreakpointState(window.screen.width);
      setBreakpoints((p) => (isDeepEqual(p, newState) ? p : newState));
    };

    window.addEventListener("resize", handleResize, { passive: true });

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <BreakpointsContext.Provider value={breakpoints}>{children}</BreakpointsContext.Provider>;
};
