import { Warning, WarningOctagon } from "@phosphor-icons/react";
import { subYears } from "date-fns";
import tw from "twin.macro";
import "styled-components/macro";

import { InfoState, PageLayout, useQueryByDateFilter, useTimeZoneFilter } from "base/components";
import { Line, Text, useDateRangeZoned } from "common/guideline";
import { withDefault } from "common/helpers";
import { GenerateMachineErrorsReportQuery, useGenerateMachineErrorsReportQuery } from "generated";

import { RmSeverityBadge } from "../RmSeverityBadge";

import type { Machine } from "./MachineView";

type MachineRows = NonNullable<
  NonNullable<NonNullable<GenerateMachineErrorsReportQuery["generateMachineErrorsReport"]>["rows"]>[0]
> & { __typename: "ErrorsReportRowDto" };

type Props = {
  nodeId: Machine["nodeId"];
};

const fromDate = subYears(new Date(), 1).toISOString();
const toDate = new Date().toISOString();
const dateRange = { from: fromDate, to: toDate, duration: "30days" } as const;

const ErrorList: React.FC<Props> = ({ nodeId }) => {
  const [zoneId] = useTimeZoneFilter();
  const zonedDateRange = useDateRangeZoned(dateRange, zoneId);
  const [queryBy] = useQueryByDateFilter("message");
  const { data, loading } = useGenerateMachineErrorsReportQuery({
    skip: !nodeId,
    variables: {
      input: {
        reportName: "MachineErrorsReport",
        skip: 0,
        limit: 10,
        order: "DESC",
        fromDate: zonedDateRange.from,
        toDate: zonedDateRange.to,
        machineNodeIds: [nodeId || ""],
        queryBy,
        zoneId,
      },
    },
  });

  const errors = data?.generateMachineErrorsReport?.rows as MachineRows[] | undefined;

  return (
    <div>
      {errors ? (
        <div>
          <ul tw="space-y-4">
            {errors.map((error, i) => (
              <li key={error.nodeId}>
                {i !== 0 && <Line tw="mb-4" />}

                <div tw="flex">
                  {error.severity === "Error" ? (
                    <WarningOctagon size={28} weight="duotone" tw="text-error-default" />
                  ) : (
                    <Warning size={28} weight="duotone" tw="text-warning-default" />
                  )}

                  <div tw="flex flex-col flex-1 pl-2">
                    <div tw="flex justify-between pb-2">
                      <span>
                        <RmSeverityBadge value={error.severity} /> {error.kind}
                      </span>
                      <div tw="text-sm whitespace-nowrap text-gray-8">
                        <Text
                          tKey="dateFormat"
                          tValue={{ date: error.dateTime ? new Date(error.dateTime) : new Date(), formatString: "PPp" }}
                        />
                      </div>
                    </div>

                    <Text variant="paragraph" tKey={withDefault(error.information)} />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : loading ? null : (
        <InfoState state="info" tKey="machine.noErrorsFound" />
      )}
    </div>
  );
};

export const MachineHealth: React.FC<Props> = (props) => (
  <PageLayout title="machine.health" subtitle="machine.machineHealthDescription">
    <ErrorList {...props} />
  </PageLayout>
);
