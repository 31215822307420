import { useState } from "react";
import { Arrow, UseLayerOptions, useLayer } from "react-laag";
import tw, { styled, theme } from "twin.macro";
import "styled-components/macro";

type Options = Omit<UseLayerOptions, "isOpen" | "onOutsideClick">;

type Props = Options & {
  className?: string;
  content: React.ReactNode | string | ((close: () => void) => React.ReactNode | string);
  onOpen?: () => void;
  "data-test"?: string;
  children?: React.ReactNode | ((isOpen: boolean) => React.ReactNode) | undefined;
};

const StyledArrow = styled(Arrow)`
  & > path {
    fill: ${theme<string>`colors.gray.1`};
  }
`;

export const Popover: React.FC<Props> = ({ className, content, children, onOpen, "data-test": dataTest, ...rest }) => {
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close,
    onDisappear: (disappearType) => {
      if (disappearType === "full") close();
    },
    overflowContainer: false,
    placement: "bottom-center",
    triggerOffset: 12,
    onParentClose: close,
    ...rest,
  });

  return (
    <>
      <div
        {...triggerProps}
        tw="cursor-pointer"
        className={className}
        data-test={dataTest}
        onClick={() => {
          setOpen(!isOpen);
          onOpen?.();
        }}
      >
        {typeof children === "function" ? children(isOpen) : children}
      </div>

      {isOpen &&
        renderLayer(
          <div tw="bg-gray-1 rounded-md border border-gray-3 z-100 px-3 py-2 shadow-blue" {...layerProps}>
            <div tw="[max-height: 90vh] overflow-auto">{typeof content === "function" ? content(close) : content}</div>
            <StyledArrow {...arrowProps} borderWidth={1} borderColor={theme<string>`colors.gray.3`} />
          </div>,
        )}
    </>
  );
};
