import { useMemo } from "react";

import { createForm } from "./createForm";
import { FormArgs } from "./types";

export const useForm = <T>(data: FormArgs<T>) =>
  useMemo(
    () => createForm<T>(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
