const splitPath = (path: string) => path.split(/\[|\].|\./);

export const unflattenObject = <T = any>(obj: any, parseValue?: (v: any) => any): T =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    const property = splitPath(key);
    let currentNode = acc;

    for (let i = 0; i < property.length; i++) {
      const currentProperty = property[i];

      if (typeof currentNode[currentProperty] === "undefined") {
        if (i === property.length - 1) {
          currentNode[currentProperty] = parseValue ? parseValue(value) : value;
        } else {
          if (/^\+?(0|[1-9]\d*)$/.test(property[i + 1])) {
            currentNode[currentProperty] = [];
          } else {
            currentNode[currentProperty] = {};
          }
        }
      }

      currentNode = currentNode[currentProperty];
    }

    return acc;
  }, {} as T);
