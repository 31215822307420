export const de = {
  common: {
    dark: "Dunkles Theme",
    light: "Leichtes Theme",
    system: "System Theme",
    submit: "Einreichen",
    next: "Weiter",
    previous: "Zurück",
    dismiss: "Ablehnen",
    pin: "Dieses Widget anheften",
    unpin: "Dieses Widget loslösen",
    nodeId: "Node ID",
    status: "Status",
    default: "Standard",
    home: "Home",
    btnHome: "Bring mich zurück",
    emptyTitle: "Keine Ergebnisse gefunden",
    emptyDescription: "Versuchen Sie, Ihre Suche oder Ihren Filter anzupassen, um das zu finden, was Sie suchen",
    pageError: "Ooops",
    returnHome:
      "Wie Sie hierher gekommen sind, ist ein Rätsel. Aber Sie können auf die Schaltfläche unten klicken, um zur Homepage zurückzukehren.",
    all: "Alle",
    starting: "Starten",
    currency: "Währung",
    site: "Standort",
    siteGroup: "Standortgruppe",
    searchResults: "Suchergebnisse",
    personalized: "Personalisierte",
    insights: "Einblicke",
    machines: "Maschinen",
    edit: "Bearbeiten",
    viewDetails: "Details anzeigen",
    title: "Überschrift",
    department: "Abteilung",
    role: "Rolle",
    myProfile: "Mein Profil",
    alerts: "Alarmierung",
    language: "Sprache",
    signOut: "Ausloggen",
    helloPopover: "Hallo <0>{{name}}</0>",
    helloProfile: "Hallo, {{name}}",
    actionMessage: "was würden Sie gerne tun??",
    profileDesc: "Hier können Sie Ihre Einstellungen bearbeiten",
    verified: "Geprüft",
    terms: "Bedingungen und Konditionen",
    termsDesc: "Dies sind die Allgemeinen Geschäftsbedingungen für die Nutzung von PayComplete™-Produkten.",
    releaseNotes: "Anmerkungen zur Veröffentlichung",
    releaseNotesDesc: "PayComplete™ Connect-Funktionen, -Erweiterungen, -Korrekturen und andere Aktualisierungen.",
    help: "Hilfe",
    to: "bis:",
    from: "von:",
    yes: "Ja",
    no: "Nein",
    confirm: "Bestätigen",
    select: "Auswählen",
    rPeriod: "Berichtszeitraum",
    rDate: "Berichtsdatum",
    notGrouped: "Nicht gruppiert",
    overview: "Übersicht",
    "404": "404",
    clipboard: "Text in die Zwischenablage kopiert",
    moreTypes: "{{count}} mehr",
    close: "geschlossen",
    notAvailable: "N/A",
    table: {
      pagination: {
        previous: "Vorherige",
        next: "Nächste",
        results_zero: "Keine Ergebnisse gefunden",
        results: "{{from}} - {{to}} von {{count}} Ergebniss",
        perPage: "Ergebnisse pro Seite:",
        outOf: "aus",
        toggleAll: "Alle umschalten",
        page: "seite",
      },
      filter: {
        filter: "Filter",
        filters: "Filter",
        reset: "Reset",
        pastHour: "Vergangene Stunde",
        pastDay: "Vergangener Tag",
        past7Days: "Letzte 7 Tage",
        past30Days: "Letzte 30 Tage",
        customRange: "Benutzerdefinierter Bereich",
        equal: "Gleich",
        between: "Zwischen",
        equalValue: "Gleich <0>{{value}}</0>",
        fromValue: "von <0>{{from}}</0>",
        toValue: "bis <0>{{to}}</0>",
        betweenValue: "Zwischen <0>{{from}}</0> und <0>{{to}}</0>",
        lowerTo: "Muss kleiner sein als",
        higherFrom: "Muss größer sein als",
        cannotBeEmpty: "Kann nicht leer sein",
      },
      sort: {
        sort: "Sortieren",
        sortBy: "Sortiert bei",
        done: "Erledigt",
        reset: "Reset",
      },
      aggr: {
        sum: "{{value}} (total)",
        avg: "{{value}} (avg)",
      },

      noData: "Keine Daten",
      nothingShow: "Nichts anzuzeigen",
      error: "Error",
      failedToFetch: "Daten können nicht abgerufen werden",
      groupBy: "Gruppieren nach",
      downloadCsv: "Als CSV herunterladen",
      printReport: "Bericht drucken",
      export: "Export",
    },
    form: {
      noOptions: "Keine Daten",
      allSelected: "Alle {{count}} ausgewählt",
      selectedItems: "{{count}} ausgewählt",
      selectAll: "Alle auswählen",
      deselectAll: "Auswahl aufheben",
      err: {
        required: "Feld erforderlich",
        invalidDate: "Ungültiges Datum",
      },
      upload: {
        image_one: "Bild hochladen",
        image_other: "Bilder hochladen",
        dropHere: "Legen Sie Ihre Dateien hier ab",
        browse: "Durchsuchen",
        removeAll: "Alle entfernen",
        maxNumber: "Sie können keine weiteren Dateien hinzufügen",
        maxFileSize: "Ausgewählte Dateigröße überschreitet maximale Dateigröße",
        acceptType: "Ausgewählter Dateityp ist nicht erlaubt",
        resolution: "Die ausgewählte Datei entspricht nicht der gewünschten Auflösung",
      },
    },
    remoteMgmt: {
      title: "Fernsteuerung (optional)",
      selectOperation: "Betrieb auswählen",
      skip: "Diesen Schritt überspringen",
      applyConfiguration: "Konfiguration anwenden",
      upgradeSoftware: "Software aktualisieren",
      now: "Jetzt",
      specifyDateAndHour: "Datum und Uhrzeit angeben",
      selectWhen: "Wählen Sie, wenn",
      dateAndTime: "Datum und Uhrzeit",
    },
    paymentIntervals: {
      monthly: "Monatlich",
      quarterly: "Vierteljährlich",
      annually: "Jährlich",
      selectPaymenInterval: "Zahlungsintervall wählen",
    },
  },
};
