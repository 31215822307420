import { Box, BoxContent, BoxHeading } from "components";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";
import { ReactComponent as CashInventorySVG } from "assets/icons/CashInventory.svg";
import { ReactComponent as CashManagementSVG } from "assets/icons/CashManagement.svg";
import { ReactComponent as CashSummarySVG } from "assets/icons/CashSummary.svg";
// import { ReactComponent as CashTotalsSVG } from "assets/icons/CashTotals.svg";
import { ReactComponent as ManualDepositSVG } from "assets/icons/ManualDeposit.svg";
import { ReactComponent as NetCashSVG } from "assets/icons/NetCash.svg";
// import { ReactComponent as UtilizationSVG } from "assets/icons/Utilization.svg";
import { PageLayout } from "base/components";
import { Text } from "common/guideline";

import { breadcrumbs } from "./breadcrumbs";

export const CashManagement = () => (
  <PageLayout breadcrumbs={breadcrumbs} title="cashMgmt.overview" subtitle="cashMgmt.desc" Icon={CashManagementSVG}>
    <div tw="gap-4 md:grid-cols-2 lg:grid-cols-3 grid">
      <Link route="CASH_INVENTORY">
        <Box>
          <BoxHeading>
            <Text tKey="cashInv.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <CashInventorySVG />
          </BoxContent>
        </Box>
      </Link>
      {/* TODO apply report link to boxes below, once report will be done */}
      <Link route="CASH_SUMMARY">
        <Box>
          <BoxHeading>
            <Text tKey="report.cashSummary.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <CashSummarySVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="NET_CASH">
        <Box>
          <BoxHeading>
            <Text tKey="report.netCash.title" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <NetCashSVG />
          </BoxContent>
        </Box>
      </Link>
      <Link route="MANUAL_DEPOSITS">
        <Box>
          <BoxHeading>
            <Text tKey="manualDeposits" />
          </BoxHeading>
          <BoxContent variant="iconWrapper">
            <ManualDepositSVG />
          </BoxContent>
        </Box>
      </Link>
      {/* TODO apply report link to boxes below, once report will be done
      <Box>
        <BoxHeading>
          <Text tKey="cashTotals" />
        </BoxHeading>
        <BoxContent variant="iconWrapper">
          <CashTotalsSVG />
        </BoxContent>
      </Box>
      <Box>
        <BoxHeading>
          <Text tKey="utilization" />
        </BoxHeading>
        <BoxContent variant="iconWrapper">
          <UtilizationSVG />
        </BoxContent>
      </Box> */}
    </div>
  </PageLayout>
);
