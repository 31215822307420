export const de = {
  mu: {
    title_one: "Benutzer der Maschine",
    title_other: "Benutzer der Maschine",
    name: "Name",
    uploadUsers: "Benutzer hochladen",
    description: "Anzeigen, Verwalten und Erstellen von Maschinenbenutzern",
    create: "Maschinenbenutzer anlegen",
    createDescription:
      "Sie erstellen einen Maschinenbenutzer, wenn Sie einen neuen Connect-Gerätebenutzer erstellen möchten",
    update: "Maschinenbenutzer aktualisieren",
    updateDescription:
      "Sie aktualisieren einen Maschinenbenutzer, wenn Sie einen vorhandenen Benutzertyp Maschine aktualisieren möchten",
    delete: "Maschinenbenutzer löschen",
    deleteDescription:
      "Sie löschen einen Maschinenbenutzer, wenn Sie einen vorhandenen Benutzertyp Maschine löschen möchten",
    deleteClose: "Schließen",
    roleName: "Rolle",
    userId: "Benutzer ID",
    success: "Maschinenbenutzer erfolgreich erstellt",
    error: "Erstellung eines Maschinenbenutzers fehlgeschlagen",
    updateSuccess: "Maschinenbenutzer erfolgreich aktualisiert",
    updateApiError: "Benutzeraktualisierung der Maschine fehlgeschlagen",
    deleteSuccess: "Maschinenbenutzer erfolgreich gelöscht",
    deleteError: "Löschen des Maschinenbenutzers fehlgeschlagen",
    goBack: "Zurück",
    machine: "Maschine",
    updateMachineUsers: "Maschinenbenutzer aktualisieren",
    usersXml: "users.xml",
    selectFile: "Datei auswählen",
    noFileSelected: "Sie müssen eine gültige xml-Datei bereitstellen",
    replaceUsers: "Bestehende Nutzer ersetzen?",
    uploadSuccess: "Erfolgreicher Upload der Maschinenbenutzer aus der Datei",
    uploadFailure: "Beim Versuch, Benutzer aus einer Datei hochzuladen, ist ein Fehler aufgetreten",
    additionalLogins: "Zusätzliche Anmeldungen",
    noCardLogin: "Keine Kartenanmeldung",
    noDoorLogin: "Keine Türanmeldung",
    cardLogin: "Anmeldung mit Karte",
    doorLogin: "Tür-Anmeldung",
    status: "Status",
    validFrom: "Gültig ab",
    validUntil: "Gültig bis",
    userSettings: "Benutzereinstellungen",
    loginSettings: "Logineinstellungen",
    accountSettings: "Kontoeinstellungen",
    role: "Rolle",
    confirmPassword: "Passwort bestätigen",
    noPasswordRequired: "Kein Passwort erforderlich",
    changePinOnFirstLogin: "Passwort bei der ersten Anmeldung ändern?",
    noAllowedLogins: "Anzahl der zulässigen Anmeldungen",
    deactivated: "Deaktiviert?",
    passwordsDoNotMatch: "Das Passwort und seine Bestätigung sind nicht dasselbe",
    unlimited: "Unbegrenzt",
    incorrectTimespan: "Gültig bis muss größer sein als Gültig ab",
    cashier: "Kassierer",
    cashierId: "Kassierer-ID",
    cashierName: "Kassierername",
    cashierRole: "Kassiererrolle",
    cit: "CIT",
    administrator: "Administrator",
    dts: "DTS",
    manager: "Manager",
    noPinLogin: "Benutzer ID",
    noPinCard: "Karte",
    noPinBio: "Biometrics",
    noPinDoor: "Tür",
    loginWith: "Kein Passwort für:",
  },
};
