import { ArrowCircleDown } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";
import "styled-components/macro";

import { useFormData } from "common/form";
import { Button, Line, Text, accessors } from "common/guideline";
import { isoDateWithoutTime } from "common/helpers";
import { useGetUpcomingExecutionDatesQuery } from "generated";

import { ScheduledReportsMetadataIn } from "../../ScheduledReportsTable/types";

export const NextReportDatesDetails: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();

  const formData = useFormData<
    "values",
    {
      metadata?: Partial<
        Pick<ScheduledReportsMetadataIn, "executionTime" | "zoneName" | "repeatOnDay" | "repeat" | "executeUntil">
      >;
    }
  >("values");

  const metadata = formData.metadata;

  const executeDates = useMemo(
    () => ({
      executeFrom: isoDateWithoutTime(new Date()),
      executeUntil: metadata?.executeUntil ? isoDateWithoutTime(new Date(metadata.executeUntil)) : null,
    }),
    [metadata?.executeUntil],
  );

  const shouldSkip =
    !metadata?.executionTime ||
    !metadata?.repeat ||
    !metadata?.zoneName ||
    executeDates.executeUntil === null ||
    (metadata?.repeat !== "DAILY" && metadata?.repeatOnDay === undefined);

  const {
    error,
    previousData,
    data = error ? undefined : previousData,
    loading,
  } = useGetUpcomingExecutionDatesQuery({
    skip: shouldSkip,
    variables: {
      metadataDtoIn: {
        ...executeDates,
        executeUntil: executeDates.executeUntil === null ? "" : executeDates.executeUntil,
        executionTime: metadata?.executionTime ?? "",
        repeat: metadata?.repeat ?? "WEEKLY",
        zoneName: metadata?.zoneName ?? "",
        repeatOnDay: metadata?.repeatOnDay ?? 0,
      },
    },
  });

  const futureDates = (data?.getUpcomingExecutionDates ?? []).slice(0, 5);

  return shouldSkip ? null : futureDates.length > 0 ? (
    <div tw="py-2 flex flex-col gap-4">
      <Line />
      <div tw="flex items-center gap-2">
        <div>
          <Text tKey="report.sr.reportWillBeSent" variant="description" />{" "}
          <Text variant="subHeading">
            {accessors.zonedDateTime(futureDates[0]?.execution, t)} {metadata?.zoneName}
          </Text>
        </div>

        <Button variant={[showMore ? "light" : "ghost", "smSquare"]} onClick={() => setShowMore((p) => !p)}>
          <ArrowCircleDown
            tw="w-6 h-6 transition-transform"
            css={css`
              ${showMore ? tw`rotate-180` : tw`text-primary-default`}
            `}
          />
        </Button>
      </div>

      {showMore && (
        <div tw="flex gap-2 flex-col">
          <Text variant="content" tKey="report.sr.max5daysShown" />

          {futureDates.map((date, i) => (
            <div key={i} tw="flex flex-col">
              <Text variant="description">
                {accessors.zonedDateTime(date?.execution, t)} {metadata?.zoneName}
              </Text>
              <div>
                <Text variant="content" tKey="report.sr.period" tValue={{ colon: true }} />{" "}
                <Text variant="subtitle">
                  {accessors.zonedDateTime(date?.startDate, t, "PP")} -{" "}
                  {accessors.zonedDateTime(date?.endDate, t, "PP")}
                </Text>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : loading ? null : (
    <div tw="py-2 flex flex-col gap-4">
      <Line />
      <Text variant="description" tw="text-warning-default" tKey="report.sr.noUpcomingReports" />
    </div>
  );
};
