const cache: Record<string, <T>(state: any) => T> = {};

export const getGetterByPath = (path: string): (<T>(state: any) => T) => {
  if (!cache[path]) {
    cache[path] = path
      ? (new Function(
          "object",
          `return object?.${path
            .replace(/\(|\)/g, "")
            .replace(/\.(\d+)(\.)?/g, "[$1]$2")
            .replace(/\.|(\[\d+\])/g, "?.$1")}`,
        ) as (object: any) => any)
      : () => undefined;
  }

  return cache[path];
};
