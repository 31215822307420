import { CaretRight } from "@phosphor-icons/react";
import { TKeys } from "i18next";
import tw from "twin.macro";
import "styled-components/macro";

import { Link } from "appRouting";

import { useBreakpoints } from "../theme";

import { Container } from "./Container";
import { Text } from "./Text";

export type Breadcrumb = {
  text: TKeys;
} & React.ComponentProps<typeof Link>;

type Props = {
  breadcrumbs: Breadcrumb[];
};

export const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) =>
  useBreakpoints().min.md ? (
    <Container variant="shadow" tw="text-gray-8 text-xs py-4 z-[5] print:hidden" as="nav">
      <ol tw="inline-flex list-none">
        {breadcrumbs.map((el, index) => (
          <li tw="flex items-center" key={index}>
            {index === breadcrumbs.length - 1 ? (
              <Text tw="font-semibold" tKey={el.text} />
            ) : (
              <>
                <Link {...el}>
                  <Text tKey={el.text} variant="link" />
                </Link>
                <CaretRight tw="mx-1" weight="bold" size={12} />
              </>
            )}
          </li>
        ))}
      </ol>
    </Container>
  ) : null;
