import tw from "twin.macro";
import "styled-components/macro";

import { navigateTo, useParams } from "appRouting";
import { PageLayout } from "base/components";
import { CONFIG } from "common/config";
import { Breadcrumb, useToast } from "common/guideline";
import { useMappedQuery } from "common/hooks";
import { FindTenantByTenantIdQuery, useFindTenantByTenantIdQuery, useUpdateTenantMutation } from "generated";
import { timeSettings } from "user/helpers/timeSettings";

import { TenantUpdateForm, TenantUpdateFormData } from "../components";

export type TenantUpdateParams = { tenantId: string };

const breadcrumbs: Breadcrumb[] = [
  {
    route: "HOME",
    text: "common.home",
  },
  {
    route: "ADMINISTRATION",
    text: "administration.title",
  },
  {
    route: "TENANT_LIST",
    text: "tenant.list.title",
  },
  {
    route: "TENANT_CONFIGURE",
    text: "tenant.update.title",
  },
];

type InitialValues = TenantUpdateFormData | undefined;

const getInitialValues = ({
  findTenantByTenantId: tenant,
}: FindTenantByTenantIdQuery | undefined = {}): InitialValues =>
  tenant
    ? {
        name: tenant.name as string,
        endOfDay: tenant.endOfDay ?? timeSettings.get.dayEndTime(),
      }
    : undefined;

export const UpdateTenantScreen = () => {
  const { tenantId = "" } = useParams<TenantUpdateParams>();
  const [updateTenant] = useUpdateTenantMutation();
  const [mappedData, { loading, error }] = useMappedQuery(
    getInitialValues,
    useFindTenantByTenantIdQuery({
      variables: { tenantId },
      skip: !tenantId,
      returnPartialData: true,
    }),
  );

  if (error) {
    navigateTo({ route: "404", replace: true });
  }

  return (
    <PageLayout title="tenant.update.title" subtitle="tenant.update.description" breadcrumbs={breadcrumbs}>
      {loading ? null : (
        <div tw="max-w-screen-md">
          <TenantUpdateForm
            submitLabel="tenant.update.submit"
            initial={mappedData}
            onSubmit={(tenant) =>
              updateTenant({
                variables: { tenant },
                context: { headers: { [CONFIG.tenantHeaderName]: tenantId } },
                ignoreResults: true,
              })
                .then(() => {
                  useToast.actions.show("tenant.update.success", { variant: "success" });
                  navigateTo({ route: "TENANT_LIST" });
                })
                .catch(() => useToast.actions.show("tenant.update.apiError", { variant: "error" }))
            }
          />
        </div>
      )}
    </PageLayout>
  );
};
