export const es = {
  queryBuilder: "Constructor de consultas",
  scheduledReports: "Informes programados",
  lossPrevention: "Prevencion de perdidas",
  machineManagement: "Gestión de máquinas",
  machineManagementOverview: "Visión general de la gestión de la maquina",
  machineErrors: "Errores de la máquina",
  machineErrorsSeverity: "Severidad",
  machineReliability: "Fiabilidad de la máquina",
  refillReport: "Informe de recarga",
  cashTotals: "Totales de caja",
  manualDeposits: "Depositos manuales",
  utilization: "Utilizacion",
  report: {
    title: "Informes",
    overview: "Visión general de los informes",
    desc: "Cree diferentes informes en funcion de la informacion que le interese. Los informes estan predefinidos en función de las normas del sector de gestion de efectivo.",
    queryBuilderDescription:
      "Utilice el generador de consultas dinamicas para crear nuevos informes personalizados a partir de datos existentes.",
    scheduledReportsDescription:
      "Programe un informe cuando desee obtener automaticamente informacion especifica en determinados intervalos de tiempo y para un periodo concreto en el tiempo",
    lossPreventionDescription:
      "El informe de prevencion de perdidas muestra informacion que tiene que ver con la protección de los activos de la empresa.",
    machineManagementDescription:
      "El informe de gestion de máquinas muestra informacion que tiene que ver con errores, detalles y estado de las máquinas.",
    cashTotalsDescription:
      "El informe muestra los totales de efectivo por ubicacion. Es decir, efectivo dispensable (reciclable) y disponible para retirar para deposito bancario o recogida ETF. Los valores reclamados, los turnos abiertos y Baul inteligente se informan a nivel de maquina.",
    manualDepositsDescription:
      "El informe muestra informacion sobre todos los depositos introducidos manualmente o valores reclamados.",
    netCashDescription:
      "El informe muestra un resumen del importe del saldo de apertura y cierre en el transcurso de un dia laborable completo.",
    utilizationDescription:
      "El informe muestra datos sobre el flujo de entrada y salida de efectivo para el peroodo seleccionado.",
    machineErrorsDescription:
      "El informe muestra el historial de errores de maquina. Se puede filtrar por grupo, sitio, fecha, maquina, gravedad y tipo (el codigo de error).",
    machineReliabilityDescription:
      "El informe muestra el historial del numero total de sesiones y el numero de sesiones satisfactorias durante el periodo de tiempo seleccionado. El informe muestra todos los errores que se han producido durante una sesion iniciada. Puede filtrar el informe por grupo y sitio.",
    refillReportDescription:
      "El informe muestra cuando es necesario vaciar o rellenar una maquina. Muestra informacion sobre el recuento actual de contenedores, los límites configurados por el usuario (alto y bajo) y la cantidad necesaria para que el contenedor alcance el nivel maximo preestablecido (recuento de rellenado).",
    onlineStatusDescription:
      "El informe muestra el estado en linea de la maquina durante el periodo de tiempo seleccionado.",
    period: "Periodo del informe",
    totalAmount: "Importe{{currency}}",
    totalCount: "Contado {{currency}}",
    openingTotalAmount: "Apertura{{currency}}",
    closingTotalAmount: "Cierre{{currency}}",
    totalIn: "Importe entrada{{currency}}",
    totalOut: "Importe salida{{currency}}",
    denominationCount: "Recuento {{currency}} {{denomination}} {{type}}",
    totalDiff: "Diferencia{{currency}}",
    type: "Tipo",
    currency: "Moneda",
    container: "Contenedor",
    denomination: "Denominacion",
    count: "Recuento",
    total: "Total",
    md: {
      title: "Detalles de la maquina",
      desc: "El informe muestra detalles de la maquina",
      codVersion: "Versión de CoD",
      machineDate: "Fecha de la maquina",
      accountingDate: "Fecha de contabilidad",
      lastErrorDate: "Fecha del ultimo error",
      lastError: "El ultimo error",
    },
    claimedValues: {
      title: "Valores reclamados",
      desc: "Utilice el informe de valores reclamados para obtener informacion sobre los totales reales de cajas fuertes basados en los valores reclamados por el usuario en un momento dado",
    },
    cashSummary: {
      title: "Resumen de efectivo",
      desc: "Utilice el informe de resumen de efectivo para obtener informacion sobre las vistas actuales e historicas, el efectivo dispensable (reciclable) y financiable disponible para retirar para deposito bancario o recogida CIT.",
      bankableAmount: "Importe del deposito bancario",
      bankableCount: "Recuento de depositos bancarios",
      dispensableAmount: "Importe dispensable",
      dispensableCount: "Recuento dispensable",
      machineTotal: "Total de la máquina",
      dropSafe: "Caja fuerte",
    },
    openShifts: {
      title: "Turnos abiertos",
      desc: "Utilice el informe Turnos abiertos para obtener informacion sobre las ultimas transacciones de usuarios de maquinas (dispensaciones) que no tengan una siguiente transaccion de fin de turno dentro del intervalo de fechas.",
      cashierId: "ID de usuario de maquina",
      cashierName: "Nombre de usuario de maquina",
      role: "Rol",
      dateTime: "Fecha hora",
      machineDateTime: "Fecha-hora de la maquina",
      transactionType: "Tipo de transaccion",
    },
    netCash: {
      title: "Efectivo neto",
      desc: "El informe muestra un resumen del importe del saldo inicial y final a lo largo de un dia laborable completo.",
      noAmountsData: "No hay datos de importes",
    },
    floatReport: {
      title: "Informe flotante",
      transactionType: "Tipo de transaccion",
      filterByTransactionTypes: "Filtrar por tipos de transaccion",
      transactionIn: "Transaccion Entrada{{currency}}",
      transactionOut: "Transaccion Salida{{currency}}",
      durationInDays: "Duracion en dias",
    },
  },
  cashMgmt: {
    title: "Gestion de caja",
    overview: "Vision general de la gestion de caja",
    desc: "El informe de gestion de efectivo muestra informacion que tiene que ver con el inventario de efectivo y los totales.",
  },
  tranReport: {
    title: "Transacciones",
    desc: "El informe de transacciones muestra informacion que tiene que ver con todas las transacciones disponibles.",
    subtype: "Subtipo",
    type: "Tipo",
    tranDate: "Fecha de la transaccion",
    accountingDate: "Fecha contable",
    receivedDate: "Fecha de recepcion",
    machineTime: "Hora de la máquina",
    machine: "Maquina",
    site: "Sitio",
    tranId: "ID de transaccion",
    sequence: "Secuencia",
    reference: "Referencia",
    machineUser: "Usuario de la maquina",
    account: "Cuenta",
    roles: "Roles",
    workUnit: "Unidad de trabajo",
    workUnitGroup: "Grupo de unidad de trabajo",
    originUser: "Usuario de origen",
    originUserAccount: "Cuenta de usuario de origen",
    customData: "Datos personalizados",
    valuesByDeno: "Valores por denominacion",
    tranCommissions: "Comisiones por transaccion",
    useAccDate: "Fecha contable de uso",
    datePeriod: "Periodo contable",
    withTranCorr: "Incluir transacciones de correccion",
    mixEdited: "Mix editado",
    cardIdentifier: "Identificador de tarjeta de usuario de maquina",
    UUID: "UUID de la maquina",
    timestamp: "Marca de tiempo",
    timezoneOffset: "Desplazamiento UTC de la zona horaria",
    currency: "Moneda",
    total: "Total",
    formattedDateTime: "Hora de la transaccion",
    formattedReceivedDate: "Fecha de recepción formateada",
    formattedAccountingDate: "Fecha contable formateada",
    valBag: {
      nodeId: "ID de nodo",
      totalAmount: "Importe total",
      type: "Tipo",
      rejects: "Rechazos",
      valNodeId: "ID del nodo de valor",
      total: "Total",
      count: "Recuento",
      decimals: "Decimales",
      currency: "Moneda",
      denomination: "Denominacion",
      pieceValue: "Valor",
      exchangeRate: "Tipo de cambio",
      exchangeRateDecimals: "Decimales del tipo de cambio",
      reference: "Referencia",
      valType: "Tipo de valor",
      claimedValueType: "Tipo de valor reclamado",
      customType: "Tipo personalizado",
      c2Count: "Recuento C2",
      c3Count: "Recuento C3",
      c4bCount: "Recuento C4b",
      creditCat2: "Credito cat 2",
      creditCat3: "Credito cat 3",
    },
  },
  cashInv: {
    title: "Inventario de efectivo",
    desc: "El informe muestra la cantidad de efectivo que hay en cada contenedor, por maquina y en un momento determinado. La cantidad se muestra por tipo de efectivo, denominacion y moneda.",
    machine: "Maquina",
    machineType: "Tipo de maquina",
    container: "Contenedor",
    denomination: "Denominacion",
    type: "Tipo",
    site: "Sitio",
  },
  mos: {
    title: "Estado de linea",
    desc: "Utilice el informe para obtener informacion sobre el estado en linea de una maquina durante el periodo de tiempo seleccionado.",
    machine: "Maquina",
    site: "Sitio",
    licenseId: "ID de licencia",
    machineStatus: "Estado de la maquina",
    onlineStatus: "Estado en línea",
    lastEvent: "Ultimo evento",
    lastReceived: "Ultimo recibido",
    timeOfStatusChange: "Hora del cambio de estado",
    formattedReceivedDate: "Fecha de recepción",
    formattedEventDate: "Fecha del evento",
  },
  mosCurrent: {
    title: "Estado actual",
    desc: "Utilice el informe para realizar un seguimiento del estado en linea de la maquina en el momento actual.",
  },
  mDepo: {
    devices: "Dispositivos",
    bagIds: "IDs de bolsa",
    date: "Fecha / hora",
    type: "Tipo",
    machineDate: "Fecha / hora de la maquina",
    tranType: "Tipo de transaccion",
  },
  mum: {
    title: "Gestión de usuarios de maquina",
    desc: "Este informe muestra informacion relacionada con las transacciones de los usuarios de maquinas.",
    transactions: {
      title: "Transacciones de usuarios de maquinas",
      desc: "El informe muestra un resumen de los depositos, dispensaciones y transacciones de fin de turno por usuarios de maquinas.",
    },
    balancing: {
      title: "Balance de usuarios de maquinas",
      desc: "El informe muestra los saldos diarios de los usuarios con un rol determinado. Permite el balanceo de usuarios de maquina contra datos de TPV.",
    },
  },
};
