import { TFunction } from "i18next";

import { TableTypes, accessors } from "common/guideline";
import { floatToComa } from "common/helpers";

import type { MachineUserTransactionsTotals } from "./MachineUserTransactions";

type RowsDataResult = {
  currencies: string[];
  totalIn: Record<string, number>[];
  totalOut: Record<string, number>[];
  totalDiff: Record<string, number>[];
};

const getRowsData = (rows: MachineUserTransactionsTotals[]): RowsDataResult => {
  const currencies = new Set<string>();

  const mapRow = (type: keyof MachineUserTransactionsTotals) => (row: MachineUserTransactionsTotals) =>
    (row[type] ?? [])?.reduce((acc, curr) => {
      const key = curr?.key;
      if (!key) return acc;

      currencies.add(key);

      acc[key] = curr.value ? Number(curr.value) : 0;

      return acc;
    }, {} as Record<string, number>) || {};

  const result = rows.reduce(
    (acc, curr) => {
      acc.totalIn.push(mapRow("totalIn")(curr));
      acc.totalOut.push(mapRow("totalOut")(curr));
      acc.totalDiff.push(mapRow("totalDiff")(curr));
      return acc;
    },
    {
      totalIn: [],
      totalOut: [],
      totalDiff: [],
    } as Omit<RowsDataResult, "currencies">,
  );

  return { currencies: Array.from(currencies), ...result };
};

export const totalAmountsMachineUserTranRows = <T extends MachineUserTransactionsTotals>(
  rows: T[],
  t: TFunction,
  totalAmounts: MachineUserTransactionsTotals,
) => {
  const { currencies, totalIn, totalOut, totalDiff } = getRowsData(rows);

  return Object.values(
    currencies.reduce((acc, currency) => {
      acc[`${currency}_totalIn`] = {
        id: `${currency}_totalIn`,
        header: t("report.totalIn", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => accessors.number(totalIn[i][currency] || 0, t),
        subAccessor: (v) => (v.totalIn?.key === currency ? accessors.number(v.totalIn.value ?? 0, t) : ""),
        footer: () => accessors.number(totalAmounts.totalIn?.find((k) => k?.key === currency)?.value ?? 0, t),
        meta: {
          csv: {
            subAccessor: (_: any) => floatToComa(_.totalIn?.key === currency ? _.totalIn.value ?? "" : ""),
          },
        },
      };

      acc[`${currency}_totalOut`] = {
        id: `${currency}_totalOut`,
        header: t("report.totalOut", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => accessors.number(totalOut[i][currency] || 0, t),
        subAccessor: (v) => (v.totalOut?.key === currency ? accessors.number(v.totalOut.value ?? 0, t) : ""),
        footer: () => accessors.number(totalAmounts.totalOut?.find((k) => k?.key === currency)?.value ?? 0, t),
        meta: {
          csv: {
            subAccessor: (r: any) => floatToComa(r.totalOut?.key === currency ? r.totalOut.value ?? "" : ""),
          },
        },
      };

      acc[`${currency}_totalDiff`] = {
        id: `${currency}_totalDiff`,
        header: t("report.totalDiff", { currency }),
        enableColumnFilter: false,
        enableHiding: false,
        accessorFn: (_, i) => accessors.number(totalDiff[i][currency] || 0, t),
        subAccessor: (v) => (v.totalDiff?.key === currency ? accessors.number(v.totalDiff.value ?? 0, t) : ""),
        footer: () => accessors.number(totalAmounts.totalDiff?.find((k) => k?.key === currency)?.value ?? 0, t),
        meta: {
          csv: {
            subAccessor: (_: any) => floatToComa(_.totalDiff?.key === currency ? _.totalDiff.value ?? "" : ""),
          },
        },
      };

      return acc;
    }, {} as Record<string, TableTypes.ColumnWithSubAccessor<T>>),
  );
};
